import classNames from 'classnames';
import { InputLabel } from 'components';
import { ClientRole, AssignToInput } from 'components/Transactions';
import { TaskEditableFormValuesType, TaskEditableFormValuesKeys } from 'types/transactionTasks';

import styles from './styles.module.scss';
import { Button } from 'antd';
import { TitleInput } from 'components/ClientsDrawer/InviteTeamMember/TitleInput';

interface AssignedToProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  isTemplate: boolean;
  className?: string | null;
  label?: string | null;
  isAssignTask?: boolean | null;
  isViewMode?: boolean | null;
  hideAsterisk?: boolean | null;
  showRole?: boolean;
  showCC?: boolean;
  setShowCC?: (value: boolean) => void;
  isNew?: boolean;
  valueClassName?: string;
  isCampaignTask?: boolean;
}

export const AssignedTo = ({
  isTemplate,
  values,
  onChangeField,
  isAssignTask,
  className,
  isViewMode,
  label,
  hideAsterisk,
  showRole,
  setShowCC,
  showCC,
  isNew = false,
  valueClassName,
  isCampaignTask,
}: AssignedToProps) => {
  return (
    <div className={classNames(styles.assignedTo, className)}>
      <div className={styles.fieldWrapper}>
        {isTemplate ? (
          <>
            <InputLabel
              label={label ?? 'Assign to'}
              className={styles.label}
              showAsterisk={!hideAsterisk}
            />
            {isCampaignTask ? (
              <TitleInput
                hideTitle
                values={values?.AssigneeRoles}
                setFieldValue={(key, val) => {
                  if (key === 'title' && Array.isArray(val)) {
                    onChangeField(
                      val?.map((v) => v.name),
                      'AssigneeRoles',
                    );
                  }
                }}
                multiple={true}
              />
            ) : (
              <ClientRole
                isSearchIcon={false}
                onChange={(e) => {
                  onChangeField(
                    e.target.value.map((v) => v.value),
                    'AssigneeRoles',
                  );
                }}
                multiselect={true}
                value={values?.AssigneeRoles}
                closeOnSelect={false}
                isViewMode={isViewMode}
                isCampaignTask={isCampaignTask}
              />
            )}
          </>
        ) : (
          <AssignToInput
            isCampaignTask={isCampaignTask}
            className={styles.assignToInputLabel}
            wrapperClassName={styles.assignToWrapper}
            label={isNew ? 'Assign To' : 'Assigned To'}
            showIcon={false}
            placeholder="Select participant(s)"
            values={values?.AssigneeList}
            onChange={(e, val) => onChangeField(val, 'AssigneeList')}
            isAssignTask={isAssignTask}
            showAsterisk
            isArrowIcon
            showRole={showRole}
            valueClassName={valueClassName}
          />
        )}
      </div>
      {!showCC && (
        <div className={styles.taskCC}>
          <Button onClick={() => setShowCC && setShowCC(true)} type="text">
            + CC
          </Button>
        </div>
      )}
    </div>
  );
};
