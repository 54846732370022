import { Categories } from './types';
import { Icons, IconVariant } from '../../../icons';

export const icons = {
  [Categories.NEW_LISTING]: {
    icon: <Icons variant={IconVariant.STATUS_NEW} />,
    bgColor: '#4673D114',
  },
  [Categories.PRICE_REDUCTION]: {
    icon: <Icons variant={IconVariant.STATUS_PRICE_REDUCTIONS} />,
    bgColor: '#04A45114',
  },
  [Categories.PRICE_INCREASE]: {
    icon: <Icons variant={IconVariant.STATUS_PRICE_INCREASE} />,
    bgColor: '#EC455E14',
  },
  [Categories.BACK_ON_THE_MARKET]: {
    icon: <Icons variant={IconVariant.STATUS_CHANGE} />,
    bgColor: '#FB913A14',
  },
  [Categories.WITHDRAWN]: {
    icon: <Icons variant={IconVariant.STATUS_CHANGE} />,
    bgColor: '#FB913A14',
  },
  [Categories.UNDER_CONTRACT]: {
    icon: <Icons variant={IconVariant.STATUS_CHANGE} />,
    bgColor: '#FB913A14',
  },
  [Categories.EXPIRED]: {
    icon: <Icons variant={IconVariant.STATUS_CHANGE} />,
    bgColor: '#FB913A14',
  },
  [Categories.SOLD]: { icon: <Icons variant={IconVariant.STATUS_SOLD} />, bgColor: '#928CDA14' },
  [Categories.AGENT_UPDATES]: {
    icon: <Icons variant={IconVariant.AGENT} />,
    bgColor: '#4673D114',
  },
};
