import { createSelector } from 'reselect';
import { PENDING } from 'settings/constants/apiState';
import { IntelInitialState } from 'store/reducers/intel/types';

const localState = ({ intel }: { intel: IntelInitialState }) => intel;

export const getValuationDataSelector = createSelector(localState, ({ valuationDetails }) => ({
  data: valuationDetails.data,
  isPending: valuationDetails.state === PENDING,
}));

export const getRemindersByIdSelector = createSelector(localState, ({ reminders }) => ({
  data: reminders.data,
  isPending: reminders.state === PENDING,
}));

export const getNeighborhoodActivityDataSelector = createSelector(
  localState,
  ({ neighborhoodActivity }) => neighborhoodActivity.data,
);

export const getNeighborhoodActivityStateSelector = createSelector(
  localState,
  ({ neighborhoodActivity }) => neighborhoodActivity.state,
);
