import moment from 'moment';
import { DateTimeColumn, DocumentNameColumn, AvatarColumn } from './TableColumns';
import { Options } from '../Options';
import { AgentDocumentTypes } from 'types';
import { sorterWithCompletedOnTop } from 'pages/Workshop/Forms/components/FormsTable/sortingHelpers';
import { CompareFn } from 'antd/lib/table/interface';

const docType = AgentDocumentTypes.MessageAttachments;

export const messageAttachmentsColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 475,
    render: (title) => <DocumentNameColumn name={title} docIconType="Ungrouped" />,
    sorter: (a, b) => a.title?.localeCompare(b.title),
  },
  {
    key: 'uploadedBy',
    title: 'Shared By',
    width: 350,
    render: (row) => <AvatarColumn name={row?.messageAttachmentMeta?.uploadedBy} imgSrc={null} />,
    sorter: (a, b) =>
      a?.messageAttachmentMeta?.uploadedBy?.localeCompare(b?.messageAttachmentMeta?.uploadedBy),
  },
  {
    key: 'uploaded',
    title: 'Date',
    dataIndex: 'messageAttachmentMeta',
    render: (row) => <DateTimeColumn date={row?.uploadedAt} hideTime />,
    sorter: sorterWithCompletedOnTop('messageAttachmentMeta.uploadedAt') as CompareFn<any>,
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docType} optionUtils={optionUtils} />,
  },
];
