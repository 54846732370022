import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { get, omit, cloneDeep } from 'lodash-es';

import {
  requestGetUserDataAction,
  signUpAction,
  requestPutUserDataAction,
  requestUpdateBuyerImportantFeaturesAction,
  requestUpdateBuyerCommutesAction,
  requestNotificationsSettingsAction,
  requestPutUserInsightAction,
  requestPutUserIsMfaEnabledAction,
  updateUserProfileWithVerfiedPhoneNumberAction,
  setVerifiedEmailFlagAction,
  setAgentSelectedMlsServiceAction,
  requestGetMarkAgentProfileAsCompleteAction,
} from 'store/actions/user';
import { appLoginAction, appLogoutAction } from 'store/actions/app';
import {
  onBoardingSignUpAction,
  onBoardingWizardAction,
  markWizardFinishedAction,
} from 'store/actions/onBoarding';
import { requestUpdateSearchCriteriaAction } from 'store/actions/feed';
import {
  requestFetchActiveSubscriptionDataAction,
  requestConvertToTeamPlanAction,
  requestDowngradeFromTeamPlanAction,
} from 'store/actions/subscription';
import { AGENT_TYPE } from 'settings/constants/drawers';

const initialData = {
  state: IDLE,
  data: {},
  meta: {},
};

export default handleActions(
  {
    [requestGetUserDataAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [appLoginAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'user']),
      meta: omit(get(payload, 'meta'), ['user', 'password', 'code']),
    }),
    [signUpAction]: (state, { payload }) => ({
      state: payload.state,
      data: get(payload, ['data', 'loginResult', 'user']),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestPutUserDataAction]: (state, { payload }) => {
      const result = get(payload, ['data', 'result']);
      return {
        ...state,
        state: payload.state,
        data: result || state.data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [onBoardingSignUpAction]: (state, { payload }) => {
      const result = get(payload, ['data', 'result']);
      return {
        ...state,
        state: payload.state,
        data: result || state.data,
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestUpdateSearchCriteriaAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result', state.data),
    }),
    [requestUpdateBuyerImportantFeaturesAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result', state.data),
    }),
    [requestUpdateBuyerCommutesAction]: (state, { payload }) => ({
      ...state,
      data: get(payload, 'data.result', state.data),
    }),
    [onBoardingWizardAction]: (state, { payload }) => ({
      data: get(payload, 'data.result', state.data),
    }),
    [requestNotificationsSettingsAction]: (state, { payload }) => {
      const result = get(payload, 'data.result', state.data);
      return {
        ...state,
        data: {
          ...state.data,
          NotificationPreferences: result.NotificationPreferences,
          UpdatedDate: result.UpdatedDate,
        },
      };
    },
    [markWizardFinishedAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, ['data', 'result']),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestFetchActiveSubscriptionDataAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: {
        ...state.data,
        SubscriptionCustomer: get(payload, ['data', 'result']),
      },
      meta: get(payload, 'meta', initialData.meta),
    }),
    [requestPutUserInsightAction]: (state, { payload }) => {
      const value = get(payload, ['data', 'user', 'Insight']);
      return {
        ...state,
        state: payload.state,
        data: { ...state.data, Insight: value },
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [requestPutUserIsMfaEnabledAction]: (state, { payload }) => {
      const value = get(payload, ['data', 'user', 'IsMfaEnabled']);
      return {
        ...state,
        state: payload.state,
        data: { ...state.data, IsMfaEnabled: value },
        meta: get(payload, 'meta', initialData.meta),
      };
    },
    [updateUserProfileWithVerfiedPhoneNumberAction]: (state, { payload }) => {
      const value = get(payload, ['data', 'Phones']);
      return {
        ...state,
        data: { ...state.data, Phones: value },
      };
    },
    [setVerifiedEmailFlagAction]: (state) => {
      return {
        ...state,
        data: { ...state.data, IsEmailVerified: true },
      };
    },
    [requestGetMarkAgentProfileAsCompleteAction]: (state) => {
      return {
        ...state,
        data: {
          ...state.data,
          CompletedProfile: true,
        },
      };
    },
    [setAgentSelectedMlsServiceAction]: (state, { payload }) => {
      return {
        ...state,
        data: {
          ...state.data,
          Agent: {
            ...state.data.Agent,
            SelectedMlsServices: payload,
          },
        },
      };
    },
    [requestConvertToTeamPlanAction]: (state, { payload }) => {
      return {
        ...state,
        data: {
          ...state.data,
          Agent: {
            ...state.data.Agent,
            AgentType: AGENT_TYPE.Team,
          },
        },
      };
    },
    [requestDowngradeFromTeamPlanAction]: (state, { payload }) => {
      return {
        ...state,
        data: {
          ...state.data,
          Agent: {
            ...state.data.Agent,
            AgentType: AGENT_TYPE.Individual,
          },
        },
      };
    },
    [appLogoutAction]: () => cloneDeep(initialData),
  },
  initialData,
);
