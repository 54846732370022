import React, { useEffect, useState } from 'react';

import { Collapse, Panel } from 'components-antd';
import { Checkbox } from 'components';
import { ProfileIcons } from 'components/ClientsDrawer/ProfileClient/Icons';

import styles from './../styles.module.scss';
import { ClientItem } from './ClientItem';

type TeamCollapsePanelType = {
  name: string;
  clients: any[];
  selected: Number[];
  onChange: Function;
  allCheckedHandler: Function;
};

export const TeamCollapsePanel = ({
  name,
  clients,
  selected,
  onChange,
  allCheckedHandler,
}: TeamCollapsePanelType) => {
  const [allOptionChecked, setAllOptionChecked] = useState(false);
  if (clients?.length === 0) return null;

  // useEffect(() => {
  //   if (allOptionChecked) {
  //     // Uncheck the all option if all the team's clients are not checked true
  //     const clientsIds = clients?.map((item) => item?.Id);
  //     clientsIds.forEach((item) => {
  //       if (!selected.includes(item)) {
  //         setAllOptionChecked(false);
  //         return;
  //       }
  //     });
  //   }
  // }, []);

  const AllOption = () => (
    <div className={styles.allOption}>
      <div className={styles.left}>
        <ProfileIcons name="user2" />
        <span>All of {name}&apos;s Clients</span>
      </div>

      <Checkbox
        checked={selected.length === clients.length}
        className={styles.checkbox}
        onChange={(e) => {
          setAllOptionChecked(e.target.checked);
          allCheckedHandler(
            e,
            clients.map((item) => item?.Id),
          );
        }}
      />
    </div>
  );

  const headerText = () => (
    <div>
      <span>{name}&apos;s Clients</span>
      <span className={styles.count}>({clients?.length})</span>
    </div>
  );

  return (
    <Collapse
      className={styles.teamCollapse}
      bordered={false}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <div>
          <ProfileIcons name={isActive ? 'minusCircle' : 'plusCircle'} />
        </div>
      )}
    >
      <Panel key="" header={headerText()}>
        <div className={styles.panelContent}>
          <AllOption />
          {clients.map((item, idx) => (
            <ClientItem key={idx} client={item} onChange={onChange} selected={selected} />
          ))}
        </div>
      </Panel>
    </Collapse>
  );
};
