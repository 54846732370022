import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import Inner from './Inner';

import styles from './styles.module.scss';

const DropzoneWithFileInput = (props) => {
  const {
    className,
    onDrop,
    activeDragText,
    inner,
    noClick,
    onChooseFile,
    onChange,
    error,
    accept,
  } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick,
    accept,
  });

  return (
    <div testid="dropzone" className={classNames(styles.dropzone, className)}>
      <div
        {...getRootProps()}
        className={classNames(styles.wrapper, { [styles.noClick]: noClick })}
      >
        <div className={styles.innerHolder}>
          {isDragActive ? (
            <div className={styles.text}>{activeDragText}</div>
          ) : (
            <div>
              {inner || <Inner onChooseFile={onChooseFile} onChange={onChange} accept={accept} />}
            </div>
          )}
        </div>
        <input testid="upload_input" {...getInputProps()} />
      </div>
      {error && (
        <div testid="validation" className={styles.error}>
          {error}
        </div>
      )}
    </div>
  );
};

DropzoneWithFileInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  onDrop: PropTypes.func,
  activeDragText: PropTypes.string,
  inner: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.node]),
  noClick: PropTypes.bool,
  onChooseFile: PropTypes.func,
  onChange: PropTypes.func,
  accept: PropTypes.string,
};

DropzoneWithFileInput.defaultProps = {
  className: '',
  error: '',
  onDrop: () => {},
  activeDragText: 'Drop the files here ...',
  inner: undefined,
  noClick: true,
  onChooseFile: () => {},
  onChange: () => {},
};

export default DropzoneWithFileInput;
