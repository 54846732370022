import { checkUrl } from 'api/checkUrl';
import { useState, useEffect } from 'react';

interface UrlValidityResult {
  isValid: boolean | null;
  isLoading: boolean;
}

const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes

/**
 * Custom hook to check if a URL is valid using a server-side API.
 *
 * @param url - The URL to check.
 * @returns An object containing the validity status and loading state.
 */
function useUrlValidity(url: string): UrlValidityResult {
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!url) {
      setIsValid(false);
      return;
    }

    const cacheKey = `urlValidityCache_${url}`;
    const cachedData = localStorage.getItem(cacheKey);
    const now = Date.now();

    if (cachedData) {
      try {
        const { isValid: cachedIsValid, timestamp } = JSON.parse(cachedData);
        if (now - timestamp < CACHE_DURATION) {
          // Cache is valid
          setIsValid(cachedIsValid);
          return;
        }
      } catch (e) {
        // Invalid cache, proceed to check URL
      }
    }

    let didCancel = false;

    setIsLoading(true);
    setIsValid(null);

    // Function to check URL validity
    const checkUrlFromAPI = async () => {
      try {
        const response = await checkUrl({ params: { url } });
        const data = response.data as { isValid: boolean; timestamp: number };

        if (!didCancel) {
          setIsValid(data.isValid);
          // Save to cache
          localStorage.setItem(
            cacheKey,
            JSON.stringify({
              isValid: data.isValid,
              timestamp: data.timestamp,
            }),
          );
        }
      } catch (error) {
        if (!didCancel) {
          setIsValid(false);
          // Save to cache
          localStorage.setItem(cacheKey, JSON.stringify({ isValid: false, timestamp: Date.now() }));
        }
      } finally {
        if (!didCancel) {
          setIsLoading(false);
        }
      }
    };

    checkUrlFromAPI();

    // Clean up function
    return () => {
      didCancel = true;
    };
  }, [url]);

  return { isValid, isLoading };
}

export default useUrlValidity;
