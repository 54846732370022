import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';

const prefsIds = {
  // OutdoorSpace types
  outdoorPrefs: 'OutdoorPrefs', //Needed to launch the modal
  fencedYard: 'FencedYard',
  balcony: 'Balcony',
  deck: 'Deck',
  patio: 'Patio',
  roofDeck: 'RoofDeck',
  terrace: 'Terrace',
  pool: 'Pool',
  waterFront: 'Waterfront',
  dock: 'Dock',

  // homePrefs
  view: 'View',
  moveInReady: 'MoveinReady',
  largeKitchen: 'LargeKitchen',
  amenities: 'AmenitiesPrefs',
  newConstruction: 'NewConstruction',
  fixerUpper: 'FixerUpper',
  rvParking: 'RVParking',
  HeatSourceElectric: 'HeatSourceElectric',
  HeatSourceNaturalGas: 'HeatSourceNaturalGas',
  WaterSourceCity: 'WaterSourceCity',
  WaterSourceWell: 'WaterSourceWell',
  SewageSeptic: 'SewageSeptic',
  SewageSewer: 'SewageSewer',
  SolarPanel: 'SolarPanel',

  // parking
  parking: 'Parking',

  // amenities
  elevator: 'Elevator',
  gym: 'Gym',
  packageReceiving: 'PackageReceiving',
  rooftop: 'Rooftop',
  dryCleaning: 'DryCleaning',
  onSiteManagement: 'OnSiteManagement',
  dogRun: 'DogRun',
  doorman: 'Doorman',
  gatedCommunity: 'GatedCommunity',
  communityPool: 'CommunityPool',
  playground: 'Playground',

  // neighborhoods
  // shortCommute: 'ShortCommute', // This field is commented because won't be neecesary (check in spring 2)
  walkability: 'Walkability',
  publicTransit: 'PublicTransit',
  artAndEntertainment: 'ArtAndEntertainment',
  parksAndNature: 'ParksAndNature',
  fitness: 'Fitness',
  coffeeShops: 'CoffeeShops',
  restaurants: 'RestaurantsBars',
  goodSchools: 'GoodSchools',
  nightlife: 'Nightlife',

  style: 'InteriorStylesPrefs',
  commutes: 'Commutes',
  proximityToImportantLocations: 'ProximityToImportantLocations',
  // Layout types
  layoutPrefs: 'LayoutPrefs', //Needed to launch the modal
  largeLot: 'LargeLot',
  openFloorPlan: 'OpenFloorPlan',
  singleFloorPlan: 'SingleStory',
  multiFloorPlan: 'MultiStory',
  firstFloorBedroom: 'FirstFloorBedroom',
  flexSpaces: 'FlexSpaces',
  homeOffice: 'HomeOffice',
  aduGuestHouse: 'GuestHouse',
  laundryRoom: 'LaundryRoom',
  basementRoom: 'BasementRoom',
  outdoorKitchen: 'OutdoorKitchen',

  // Kitchen types
  kitchenFeatures: 'KitchenFeatures', //Needed to launch the modal
  kitchenFeaturesLargerThanAverage: 'KitchenFeaturesLargerThanAverage',
  kitchenBreakfastNook: 'KitchenBreakfastNook',
  kitchenDoubleOven: 'KitchenDoubleOven',
  kitchenFeaturesEatIn: 'KitchenFeaturesEatIn',
  kitchenFeaturesIsland: 'KitchenFeaturesIsland',
  kitchenFeaturesStainlessSteelAppliances: 'KitchenFeaturesStainlessSteelAppliances',
  kitchenGasCooktop: 'KitchenGasCooktop',
  kitchenElectricCooktop: 'KitchenElectricCooktop',
  KitchenCabinetWhite: 'KitchenCabinetWhite',
  KitchenCabinetGray: 'KitchenCabinetGray',
  KitchenCabinetBrown: 'KitchenCabinetBrown',
  KitchenCabinetBlack: 'KitchenCabinetBlack',

  // Flooring types
  flooring: 'Flooring', //Needed to launch the modal
  flooringHardwood: 'FlooringHardwood',
  flooringCarpet: 'FlooringCarpet',
  flooringTile: 'FlooringTile',
  flooringParquet: 'FlooringParquet',
  flooringConcrete: 'FlooringConcrete',
  flooringStone: 'FlooringStone',
  flooringOther: 'FlooringOther',

  // Interior Features
  interior: 'Interior',
  fireplace: 'Fireplace',
  ceilingFans: 'CeilingFan',
  highCeilings: 'HighCeilings',
  naturalLight: 'NaturalLight',
  floorToCeilingWindows: 'FloortoCeilingWindows',
  frenchDoors: 'FrenchDoors',
  crownMolding: 'CrownMolding',
  builtinShelves: 'BuiltInShelves',
  walkinClosets: 'WalkInCloset',

  // View Features
  ViewWater: 'ViewWater',
  ViewMountain: 'ViewMountain',
  ViewCity: 'ViewCity',
  ViewGolfCourse: 'ViewGolfCourse',
  ViewGreenspace: 'ViewGreenspace',

  //extras
  heaterSource: 'heaterSource',
  waterSource: 'waterSource',
  waste: 'waste',
  kitchenCabinetColor: 'KitchenCabinetColor',
  school: 'Schools',
};

const PREF_TYPE = {
  keywordPrefs: 'KeywordPrefs',
  homePrefs: 'HomePrefs',
  amenitiesPrefs: 'AmenitiesPrefs',
  commutePrefs: 'CommutePrefs',
  neighborhoodPrefs: 'NeighborhoodPrefs',
  outdoorPrefs: 'OutdoorPrefs',
  ViewPrefs: 'ViewPrefs',
  interiorStylesPrefs: 'InteriorStylesPrefs',
  parking: 'Parking',
  school: 'Schools',
};

export const LAYOUT_TYPES = [
  { id: prefsIds.singleFloorPlan, label: 'Single Story', type: PREF_TYPE.homePrefs },
  { id: prefsIds.multiFloorPlan, label: 'Multi-Story', type: PREF_TYPE.homePrefs },
  { id: prefsIds.openFloorPlan, label: 'Open Floor Plan', type: PREF_TYPE.homePrefs },
  { id: prefsIds.firstFloorBedroom, label: 'First-Floor Bedroom', type: PREF_TYPE.homePrefs },
  { id: prefsIds.flexSpaces, label: 'Flex Spaces', type: PREF_TYPE.homePrefs },
  { id: prefsIds.homeOffice, label: 'Home Office', type: PREF_TYPE.homePrefs },
  { id: prefsIds.aduGuestHouse, label: 'ADU/Guest House', type: PREF_TYPE.homePrefs },
  { id: prefsIds.laundryRoom, label: 'Laundry Room', type: PREF_TYPE.homePrefs },
  { id: prefsIds.basementRoom, label: 'Basement', type: PREF_TYPE.homePrefs },
];

export const RVParking = prefsIds.rvParking;

export const OUTDOOR_TYPES = [
  { id: prefsIds.largeLot, label: 'Large Lot', type: PREF_TYPE.homePrefs },
  { id: prefsIds.outdoorKitchen, label: 'Outdoor Kitchen', type: PREF_TYPE.homePrefs },
  { id: prefsIds.fencedYard, label: 'Fenced Yard', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.balcony, label: 'Balcony', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.deck, label: 'Deck', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.patio, label: 'Patio', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.pool, label: 'Pool', type: PREF_TYPE.outdoorPrefs },
  { id: prefsIds.waterFront, label: 'Waterfront', type: PREF_TYPE.outdoorPrefs },
];

export const PROPERTY_CONDITION_TYPES = [
  { id: prefsIds.newConstruction, label: 'New Construction', type: PREF_TYPE.homePrefs },
  { id: prefsIds.moveInReady, label: 'Move-In Ready' },
  // { id: prefsIds.fixerUpper, label: 'Fixer Upper' },
];

export const FLOORING_TYPES = [
  { id: prefsIds.flooringHardwood, label: 'Wood', type: PREF_TYPE.homePrefs },
  { id: prefsIds.flooringCarpet, label: 'Carpet', type: PREF_TYPE.homePrefs },
  { id: prefsIds.flooringTile, label: 'Tile', type: PREF_TYPE.homePrefs },
  { id: prefsIds.flooringParquet, label: 'Parquet', type: PREF_TYPE.homePrefs },
  { id: prefsIds.flooringStone, label: 'Concrete', type: PREF_TYPE.homePrefs },
];

export const KITCHEN_FEATURES_TYPES = [
  {
    id: prefsIds.kitchenFeaturesLargerThanAverage,
    label: 'Large Kitchen',
    type: PREF_TYPE.homePrefs,
  },
  { id: prefsIds.kitchenBreakfastNook, label: 'Breakfast Nook', type: PREF_TYPE.homePrefs },
  { id: prefsIds.kitchenFeaturesIsland, label: 'Island', type: PREF_TYPE.homePrefs },
  {
    id: prefsIds.kitchenFeaturesStainlessSteelAppliances,
    label: 'Stainless Steel Appliances',
    type: PREF_TYPE.homePrefs,
  },
  { id: prefsIds.kitchenDoubleOven, label: 'Double Oven', type: PREF_TYPE.homePrefs },
  { id: prefsIds.kitchenGasCooktop, label: 'Gas Cooktop', type: PREF_TYPE.homePrefs },
  { id: prefsIds.kitchenElectricCooktop, label: 'Electric Cooktop', type: PREF_TYPE.homePrefs },
];

export const KITCHEN_CABINET_TYPES = [
  { id: prefsIds.KitchenCabinetWhite, label: 'White', type: PREF_TYPE.homePrefs },
  { id: prefsIds.KitchenCabinetGray, label: 'Gray', type: PREF_TYPE.homePrefs },
  { id: prefsIds.KitchenCabinetBrown, label: 'Brown', type: PREF_TYPE.homePrefs },
  { id: prefsIds.KitchenCabinetBlack, label: 'Black', type: PREF_TYPE.homePrefs },
];

export const VIEW_TYPES = [
  { id: prefsIds.ViewWater, label: 'Water', type: PREF_TYPE.ViewPrefs },
  { id: prefsIds.ViewMountain, label: 'Mountain', type: PREF_TYPE.ViewPrefs },
  { id: prefsIds.ViewCity, label: 'City', type: PREF_TYPE.ViewPrefs },
  { id: prefsIds.ViewGolfCourse, label: 'Golf Course', type: PREF_TYPE.ViewPrefs },
  { id: prefsIds.ViewGreenspace, label: 'Greenspace', type: PREF_TYPE.ViewPrefs },
];

export const INTERIOR_TYPES = [
  { id: prefsIds.fireplace, label: 'Fireplace', type: PREF_TYPE.interiorStylesPrefs },
  { id: prefsIds.ceilingFans, label: 'Ceiling Fans', type: PREF_TYPE.interiorStylesPrefs },
  { id: prefsIds.highCeilings, label: 'High Ceilings', type: PREF_TYPE.interiorStylesPrefs },
  { id: prefsIds.naturalLight, label: 'Natural Light', type: PREF_TYPE.interiorStylesPrefs },
  {
    id: prefsIds.floorToCeilingWindows,
    label: 'Floor-to-Ceiling Windows',
    type: PREF_TYPE.interiorStylesPrefs,
  },
  { id: prefsIds.frenchDoors, label: 'French Doors', type: PREF_TYPE.interiorStylesPrefs },
  { id: prefsIds.crownMolding, label: 'Crown Molding', type: PREF_TYPE.interiorStylesPrefs },
  { id: prefsIds.builtinShelves, label: 'Built-In Shelves', type: PREF_TYPE.interiorStylesPrefs },
  { id: prefsIds.walkinClosets, label: 'Walk-In Closet(s)', type: PREF_TYPE.interiorStylesPrefs },
];

export const AMENITIES_TYPES = [
  { id: prefsIds.elevator, label: 'Elevator', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.gym, label: 'Gym', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.packageReceiving, label: 'Package Receiving', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.rooftop, label: 'Rooftop', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.doorman, label: 'Doorman/ Security', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.onSiteManagement, label: 'On-site management', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.dogRun, label: 'Dog Run', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.dryCleaning, label: 'Dry Cleaning', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.gatedCommunity, label: 'Gated Community', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.communityPool, label: 'Community Pool', type: PREF_TYPE.amenitiesPrefs },
  { id: prefsIds.playground, label: 'Playground', type: PREF_TYPE.amenitiesPrefs },
];

export const MECHANICAL_HEAT_SOURCE = [
  { id: prefsIds.HeatSourceElectric, label: 'Electric Heating', type: PREF_TYPE.homePrefs },
  { id: prefsIds.HeatSourceNaturalGas, label: 'Gas Heating', type: PREF_TYPE.homePrefs },
];

export const MECHANICAL_WATER_SOURCE = [
  { id: prefsIds.WaterSourceCity, label: 'City Water', type: PREF_TYPE.homePrefs },
  { id: prefsIds.WaterSourceWell, label: 'Well Water', type: PREF_TYPE.homePrefs },
];

export const MECHANICAL_WASTE = [
  { id: prefsIds.SewageSeptic, label: 'Septic System', type: PREF_TYPE.homePrefs },
  { id: prefsIds.SewageSewer, label: 'Sewer System', type: PREF_TYPE.homePrefs },
];

export const MECHANICAL_ENERGY = [
  { id: prefsIds.SolarPanel, label: 'Solar Panels', type: PREF_TYPE.homePrefs },
];

export const MECHANIC_ALL = [
  ...MECHANICAL_HEAT_SOURCE,
  ...MECHANICAL_WATER_SOURCE,
  ...MECHANICAL_WASTE,
  ...MECHANICAL_ENERGY,
];

export const NEIGHBORHOOD_TYPE = [
  { id: prefsIds.walkability, label: 'Walkability', type: PREF_TYPE.neighborhoodPrefs },
  { id: prefsIds.publicTransit, label: 'Public Transit', type: PREF_TYPE.neighborhoodPrefs },
  {
    id: prefsIds.artAndEntertainment,
    label: 'Arts & Entertainment',
    type: PREF_TYPE.neighborhoodPrefs,
  },
  { id: prefsIds.parksAndNature, label: 'Parks & Nature', type: PREF_TYPE.neighborhoodPrefs },
  { id: prefsIds.fitness, label: 'Fitness', type: PREF_TYPE.neighborhoodPrefs },
  { id: prefsIds.coffeeShops, label: 'Coffee Shops', type: PREF_TYPE.neighborhoodPrefs },
  { id: prefsIds.restaurants, label: 'Restaurants', type: PREF_TYPE.neighborhoodPrefs },
  { id: prefsIds.nightlife, label: 'Nightlife', type: PREF_TYPE.neighborhoodPrefs },
];

export const IMPORTANCE = {
  not: SEARCH_CRITERIA_IMPORTANCE.NOT,
  someWhat: SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT,
  very: SEARCH_CRITERIA_IMPORTANCE.VERY,
  must: SEARCH_CRITERIA_IMPORTANCE.MUST,
};

export const getDefaultValues = (defaultValue, entities) => {
  if (!defaultValue || defaultValue?.length < 1) return [];
  const defaultEntitiesMap = entities.map(({ id }) => id);
  const defaultValuesMap = defaultValue.map(({ Preference }) => Preference);
  return defaultEntitiesMap?.filter((id) => defaultValuesMap.includes(id));
};

export const ALL_PREFS = [
  ...LAYOUT_TYPES,
  ...OUTDOOR_TYPES,
  ...PROPERTY_CONDITION_TYPES,
  ...FLOORING_TYPES.map((obj) => ({ ...obj, label: obj.label + ' Flooring' })),
  ...KITCHEN_FEATURES_TYPES,
  ...KITCHEN_CABINET_TYPES.map((obj) => ({ ...obj, label: obj.label + ' Cabinets' })),
  ...VIEW_TYPES.map((obj) => ({ ...obj, label: obj.label + ' View' })),
  ...INTERIOR_TYPES,
  ...AMENITIES_TYPES,
  ...MECHANICAL_HEAT_SOURCE,
  ...MECHANICAL_WATER_SOURCE,
  ...MECHANICAL_WASTE,
  ...MECHANICAL_ENERGY,
  ...NEIGHBORHOOD_TYPE,
  { id: RVParking, label: 'RV Parking' },
];
