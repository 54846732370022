import type { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import moment from 'moment';

import { ArrowDownFull } from 'components/Icons';

import { transactionRoles, Role } from 'settings/constants/roles';
import { Icons } from 'pages/Workshop/Icons';
import {
  displayListedStatus,
  listedStatusesArray,
  transactionStatuses,
} from 'settings/constants/transaction';

import styles from './styles.module.scss';

import { NamesContainer } from 'pages/Workshop/Transactions/components/NamesContainer';
import Notifications from 'pages/Workshop/Transactions/components/Notifications/Notifications';
import { getDateOnly, isBeforeToday } from 'helpers';
import { Status } from 'pages/Workshop/Transactions/components/Status';

interface DataType {
  Id: number;
  Property: {
    Address: {
      City?: string;
      Line1: string;
      PlaceName: string;
      ProviderPlaceId?: string;
      State?: string;
      Zip?: string;
    };
  };
  AgentsOrOwners: string[];
  Coordinators: string[];
  TransactionClients: string[];
  Name: string;
  Status: string;
  ProjectKickoffDate: string;
  ProjectCompletionDate: string;
  ProjectCategory: string;
  address: string;
  price: number;
  closingDate: string;
  isAlertVisible: boolean;
}

function inferStatus(row): string {
  return listedStatusesArray.includes(row?.status) ? displayListedStatus[row?.status] : row?.status;
}

const sortableColumnTitle =
  (titleLabel: string, key: string) =>
  ({ sortColumns }) => {
    const sortedColumn = sortColumns?.find(({ column }) => column.key === key);
    const sortAsc = sortedColumn?.order === 'ascend';
    return (
      <div className={styles.sortedColumnTitle}>
        {titleLabel}
        {sortedColumn?.order && (
          <ArrowDownFull
            className={classNames(styles.sorterIcon, { [styles.sorterUp]: sortAsc })}
          />
        )}
      </div>
    );
  };

export function tableColumns(isArchive?: boolean, userRole?: string): ColumnsType<DataType> {
  return [
    {
      title: sortableColumnTitle('Project', 'project'),
      key: 'project',
      dataIndex: 'name',
      render: (_, { Name }) => {
        return (
          <div className={styles.project}>
            <Icons
              variant={Icons.PROJECT_DOCUMENT}
              className={classNames(styles.icon, styles.pIcon)}
            />
            <div className={styles.name}>
              <span className={styles.line1}>{Name}</span>
            </div>
          </div>
        );
      },
      sorter: (a, b) => a.Name?.localeCompare(b.Name),
      defaultSortOrder: 'descend',
    },
    {
      title: sortableColumnTitle('Status', 'status'),
      key: 'status',
      dataIndex: 'status',
      width: 200,
      render: (status, row) => <Status status={status} />,
      sorter: (a, b) => inferStatus(a)?.localeCompare(inferStatus(b)),
    },
    {
      title: 'Owner(s)',
      key: 'owner',
      width: 200,
      dataIndex: 'owner',
      render: (title, { AgentsOrOwners }) => {
        if (!AgentsOrOwners || !AgentsOrOwners?.length) return <span>-</span>;

        return (
          <div className={styles.clientField}>
            <div>
              <NamesContainer names={AgentsOrOwners} maxDisplayCount={1} />
            </div>
          </div>
        );
      },
    },
    {
      title: sortableColumnTitle('Category', 'transactionCategory'),
      key: 'transactionCategory',
      sorter: (a, b) => a.ProjectCategory?.localeCompare(b.ProjectCategory),
      dataIndex: 'category',
      render: (title, { ProjectCategory }) => {
        return <span>{ProjectCategory}</span>;
      },
    },
    {
      title: sortableColumnTitle(isArchive ? 'Dispositioned' : 'Date', 'closingDate'),
      key: 'closingDate',
      dataIndex: 'closingDate',
      render: (_, { Status, ProjectKickoffDate, ProjectCompletionDate }) => {
        if (Status === transactionStatuses.Upcoming) {
          return (
            <div className={styles.projectDate}>
              <span>{getDateOnly(ProjectKickoffDate, 'M/D/YYYY')}</span>
              <span className={styles.projectDateLabel}>Kickoff</span>
            </div>
          );
        } else {
          return (
            <div className={styles.projectDate}>
              <span
                className={classNames({
                  [styles.closeClosingDate]: isBeforeToday(ProjectCompletionDate),
                })}
              >
                {ProjectCompletionDate ? getDateOnly(ProjectCompletionDate, 'M/D/YYYY') : 'N/A'}
              </span>
              <span className={styles.projectDateLabel}>Deadline</span>
            </div>
          );
        }
      },
      sorter: (a, b) => {
        const endDate1 = a?.ProjectCompletionDate;
        const endDate2 = b?.ProjectCompletionDate;
        if (endDate1 === 'N/A') return 1;
        if (endDate2 === 'N/A') return -1;

        return moment(endDate1).diff(moment(endDate2), 'days');
      },
    },
    {
      title: '',
      key: 'notifications',
      dataIndex: 'notifications',
      render: (_, row) => <Notifications transactionData={row} userRole={userRole} />,
    },
  ];
}
