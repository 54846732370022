import axios from 'axios';
import classNames from 'classnames';
import fileDownload from 'js-file-download';
import * as _ from 'lodash';
import { isNumber, isString, keys, keysIn, values, isArray } from 'lodash';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Role } from 'app-constants';
import { Badge, Space } from 'components-antd';
import { Col, Row } from 'components-antd/Grid';
import { getAddressOrProject, getPropertyAddress, getType } from 'helpers';
import { link } from 'settings/navigation/link';
import { routes } from 'settings/navigation/routes';
import { openMessagesDrawerAction } from 'store/actions/drawers/messages';
import { openQuoteActivityDrawerAction } from 'store/actions/drawers/viewQuoteActivity';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { SearchCriteriaFilterTypes } from 'types/searchCriterias';
import { ActivityLogsTypesValue } from '../../types/activityLogs';

import styles from './styles.module.scss';

type AuditLogItemConfigType = {
  [key in ActivityLogsTypesValue]: {
    isExpandable: Boolean | ((props: any) => boolean);
    TaglineMarkup: React.ReactNode;
    ExpandedMarkup: React.ReactNode;
  };
};

const feedbackStyles = {
  borderBottom: '1.5px solid transparent',
  transition: 'all 0.2s',
};

const mouseEnterHandler = (e) => {
  e.target.style.borderBottom = '1.5px solid #262626';
};

const mouseLeaveHandler = (e) => {
  e.target.style.borderBottom = '1.5px solid transparent';
};

const getProfileViewName = ({
  Name,
  UserId,
  Role,
  isYou,
  isSmallCase,
  handleProfileView,
  spacer = ' ',
}: {
  Name: string;
  UserId?: number;
  Role?: Role;
  isYou: boolean;
  isSmallCase?: boolean;
  handleProfileView: (userId: number, role: Role) => void;
  spacer?: string;
}) => {
  const ownName = isSmallCase ? 'you' : 'You';
  return (
    <span
      onClick={() => !isYou && UserId && Role && handleProfileView(UserId, Role)}
      style={
        !isYou && UserId && Role
          ? { cursor: 'pointer', borderBottom: '1.5px solid transparent', transition: 'all 0.2s' }
          : undefined
      }
      onMouseEnter={(e) => {
        if (!isYou && UserId && Role) mouseEnterHandler(e);
      }}
      onMouseLeave={(e) => {
        if (!isYou && UserId && Role) mouseLeaveHandler(e);
      }}
    >
      <StrongTitle title={isYou ? ownName : Name} />
      {spacer}
    </span>
  );
};

const getLink = (content: string, pathname: string, state?: {}) => {
  const history = useHistory();
  return (
    <strong>
      <a
        style={feedbackStyles}
        onMouseEnter={(e) => mouseEnterHandler(e)}
        onMouseLeave={(e) => mouseLeaveHandler(e)}
        onClick={() =>
          history.push({
            pathname,
            state,
          })
        }
        rel="noreferrer"
      >
        {content}
      </a>
    </strong>
  );
};

const showTransactionAddress = (transactionId: number) => {
  const history = useHistory();
  return history.location?.pathname !== link.toTransactionActivity(transactionId);
};

export const AuditLogItemConfig: AuditLogItemConfigType = {
  [ActivityLogsTypesValue.TransactionActiveListing]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.ChangedBy?.Id === currentUserId;
      const changedBy = getProfileViewName({
        Name: `${props?.ChangedBy?.FirstName}${
          props?.ChangedBy?.LastName ? ` ${props?.ChangedBy?.LastName}` : ''
        }`,
        UserId: props?.UserId,
        Role: props?.Role,
        isYou,
        handleProfileView,
      });
      return (
        <>
          The transaction status was changed from <strong>{` ${props?.OldStatus} `}</strong> to
          cancelled. The listing was restored to Active Listing status.
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TransactionPriceHistory]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.ChangedBy?.Id === currentUserId;
      const changedBy = getProfileViewName({
        Name: `${props?.ChangedBy?.FirstName}${
          props?.ChangedBy?.LastName ? ` ${props?.ChangedBy?.LastName}` : ''
        }`,
        UserId: props?.UserId,
        Role: props?.Role,
        isYou,
        handleProfileView,
      });
      const formattedOldPrice = parseFloat(props?.OldPrice).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      const formattedPrice = parseFloat(props?.Price).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });

      return (
        <>
          The transaction price was changed from <strong>{formattedOldPrice}</strong> to
          <strong> {formattedPrice}</strong> by {changedBy}.
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TransactionCanceled]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.ChangedBy?.Id === currentUserId;
      const changedBy = getProfileViewName({
        Name: `${props?.ChangedBy?.FirstName}${
          props?.ChangedBy?.LastName ? ` ${props?.ChangedBy?.LastName}` : ''
        }`,
        UserId: props?.UserId,
        Role: props?.Role,
        isYou,
        handleProfileView,
      });
      return (
        <>
          The transaction status was changed from <strong>{` ${props?.OldStatus} `}</strong> to
          cancelled. The listing was restored to Active Listing status.
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.PropertyRemovedFromFavorites]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        removed{' '}
        <strong>
          <a
            style={feedbackStyles}
            onMouseEnter={(e) => mouseEnterHandler(e)}
            onMouseLeave={(e) => mouseLeaveHandler(e)}
            href={`/properties/feed/listing-detail/${props.MlsId?.replace('}', '')}`}
            rel="noreferrer"
          >
            {' '}
            {props.Address}
          </a>
        </strong>{' '}
        from their favorites.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.ShowingAnnouncement]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        sent an announcement to <StrongTitle title={props?.SendTo} />.
      </>
    ),
    ExpandedMarkup: (props) => (
      <>
        <strong>It said: </strong>
        <p>{props?.Message}</p>
        {props?.DisplayOnBooking && <p>It was also published to the booking page.</p>}
      </>
    ),
  },
  [ActivityLogsTypesValue.InviteAccepted]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        accepted the invitation to join Mosaik and has finished setting up their account.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.InviteRejected]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        declined the invitation to join Mosaik.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.CriteriaWizardCompleted]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        entered their search preferences.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.CriteriaWizardSkipped]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        skipped entering their search preferences.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.PreApprovalRequested]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        requested a pre-approval from {props?.LenderName}.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.PreApprovalUploaded]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        uploaded a pre-approval letter.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.NewSearchInstanceCreated]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        created a new search: <StrongTitle title={props?.SearchName} />.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.SearchInstanceRenamed]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        changed the name of their search from <StrongTitle title={props?.OldName} /> to{' '}
        <StrongTitle title={props?.NewName} />.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.SearchInstanceDeactivated]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        deactivated {props?.IsAgent ? `${props?.ClientName}'s` : 'their'} search:{' '}
        <StrongTitle title={props?.SearchName} />.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.SearchInstanceActivated]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        activated {props?.IsAgent ? `${props?.ClientName}'s` : 'their'} search:{' '}
        <StrongTitle title={props?.SearchName} />.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.FreeSearchTriggered]: {
    isExpandable: false,
    TaglineMarkup: () => <></>,
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.SearchInstanceCriteriaEdited]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        changed the criteria on their search: <StrongTitle title={props?.SearchName} />.
      </>
    ),
    ExpandedMarkup: (props) => (
      <>
        {props?.ChangesMake?.length > 0 &&
          props?.ChangesMake.map((change, idx) => {
            return <ChangeFromTo key={idx} change={change} />;
          })}
      </>
    ),
  },
  [ActivityLogsTypesValue.NewAdHocSearch]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        created a new ad-hoc search.
      </>
    ),
    ExpandedMarkup: (props) => (
      <>
        <strong>Criteria: </strong>
        {keysIn(props?.SearchPrefs).length > 0
          ? keysIn(props?.SearchPrefs).map((criteriaTitle, idx) => {
              return (
                <Row key={idx}>
                  <Col xs={24}>
                    {SearchCriteriaFilterTypes[criteriaTitle] && (
                      <StrongListTitle title={`${SearchCriteriaFilterTypes[criteriaTitle]}:`} />
                    )}
                    {SearchCriteriaFilterTypes[criteriaTitle] &&
                      getSearchCriteriaFilterValues(
                        props?.SearchPrefs[criteriaTitle],
                        SearchCriteriaFilterTypes[criteriaTitle],
                      )}
                  </Col>
                </Row>
              );
            })
          : ''}
      </>
    ),
  },
  [ActivityLogsTypesValue.PropertyAddedToFavorites]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        added a new favorite.{' '}
        <strong>
          <a
            style={feedbackStyles}
            onMouseEnter={(e) => mouseEnterHandler(e)}
            onMouseLeave={(e) => mouseLeaveHandler(e)}
            href={`/properties/feed/listing-detail/${props.MlsId?.replace('}', '')}`}
            rel="noreferrer"
          >
            {' '}
            {props.Address}
          </a>
        </strong>{' '}
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.PropertyViewed]: {
    isExpandable: false,
    TaglineMarkup: () => <></>,
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TransactionStatusUpdated]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.ChangedBy?.Id === currentUserId;
      const changedBy = getProfileViewName({
        Name: `${props?.ChangedBy?.FirstName}${
          props?.ChangedBy?.LastName ? ` ${props?.ChangedBy?.LastName}` : ''
        }`,
        UserId: props?.UserId,
        Role: props?.Role,
        isYou,
        handleProfileView,
      });

      return (
        <>
          The {getType(props?.Transaction)?.toLowerCase()} status was changed from{' '}
          <StrongTitle title={formatTransactionStatus(props?.PreviousStatus)} /> to{' '}
          <StrongTitle title={formatTransactionStatus(props?.NewStatus)} /> by {changedBy}.
          {showTransactionAddress(props?.Transaction?.Id) ? (
            <>
              {' '}
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <></>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TransactionTemplateApplied]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId }) => {
      const isTransactionRoom = !showTransactionAddress(props?.Transaction?.Id);
      if (isTransactionRoom) {
        return (
          <>
            The template <strong>{props.Template?.Name}</strong> was applied to this transaction
            {props.Milestones?.length ? (
              <>
                {'. '}
                {props.Milestones?.length}
                {getLink(` milestones`, link.toTransactionOverview(props?.Transaction?.Id))} were
                added
              </>
            ) : (
              '.'
            )}
            {props.Milestones?.length && props.Tasks?.length ? ` and` : ''}
            {props.Tasks?.length ? (
              <>
                {' '}
                {props.Tasks?.length}{' '}
                {getLink(` tasks`, link.toTransactionTasks(props.Transaction.Id), {
                  transactionId: props.Transaction.Id,
                  taskIds: props.Tasks.map((task) => task.Id).filter(Boolean),
                })}{' '}
                were added.
              </>
            ) : (
              ''
            )}
          </>
        );
      } else {
        const isYou = props?.CreatedBy.Id === currentUserId;
        const createdBy = isYou
          ? 'You'
          : `${props?.CreatedBy?.FirstName || props?.CreatedBy?.Email || ''}${
              props?.CreatedBy?.LastName ? ` ${props?.CreatedBy?.LastName}` : ''
            }`;

        return (
          <>
            <strong>{createdBy}</strong> created a{' '}
            {props.Transaction?.IsProject ? 'Project' : 'Transaction'}:{' '}
            {getLink(
              props.Transaction?.IsProject
                ? props?.Transaction?.Name
                : getPropertyAddress(props?.Transaction?.Address),
              props.Transaction?.IsProject
                ? link.toProjectOverview(props?.Transaction?.Id)
                : link.toTransactionOverview(props?.Transaction?.Id),
            )}
            .
          </>
        );
      }
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TransactionParticipantInvited]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isInviteeYou = currentUserId === props?.Invitee?.Id;
      const invitedBy = getProfileViewName({
        Name: `${props?.Inviter?.FirstName}${
          props?.Inviter?.LastName ? ` ${props?.Inviter?.LastName}` : ''
        }`,
        UserId: props?.Inviter?.Id,
        Role: props.Inviter.Role,
        isYou: currentUserId === props?.Inviter?.Id,
        handleProfileView,
        spacer: '',
      });
      const invitee = getProfileViewName({
        Name: `${props?.Invitee?.FirstName}${
          props?.Invitee?.LastName ? ` ${props?.Invitee?.LastName}` : ''
        }`,
        UserId: props?.Invitee?.Id,
        Role: props.Invitee.Role,
        isYou: isInviteeYou,
        handleProfileView,
      });
      return (
        <>
          {invitedBy} invited {invitee} to the {getType(props?.Transaction)?.toLowerCase()}.
          {showTransactionAddress(props?.Transaction?.Id) ? (
            <>
              {' '}
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <></>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TransactionParticipantRemoved]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isRemovedYou = currentUserId === props?.Removed?.Id;
      const removedUser = getProfileViewName({
        Name: `${props?.Removed?.FirstName}${
          props?.Removed?.LastName ? ` ${props?.Removed?.LastName}` : ''
        }`,
        UserId: props?.Removed?.Id,
        Role: props.Removed.Role,
        isYou: isRemovedYou,
        handleProfileView,
      });
      const removedBy = getProfileViewName({
        Name: `${props?.RemovedBy?.FirstName}${
          props?.RemovedBy?.LastName ? ` ${props?.RemovedBy?.LastName}` : ''
        }`,
        UserId: props?.RemovedBy?.Id,
        Role: props.RemovedBy?.Role,
        isYou: currentUserId === props?.RemovedBy?.Id,
        handleProfileView,
      });

      return (
        <>
          {removedBy} removed {removedUser} from the {getType(props?.Transaction)?.toLowerCase()}.
          {showTransactionAddress(props?.Transaction?.Id) ? (
            <>
              {' '}
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <></>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.DetailAdded]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        updated <strong>{props.fieldName}</strong> to &apos;{props.fieldValue}&apos;.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.DetailChanged]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        changed <strong>{props.fieldName}</strong> from &apos;{props.oldFieldValue}&apos; to &apos;
        {props.newFieldValue}&apos;.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.ContactAdded]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        added the contact {props.contactName}, ({props.contactRole}).
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.ContactChanged]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        edited the contact information for <strong>{props.contactName}</strong> from &apos;
        {props.oldContactValue}&apos; to &apos;
        {props.newContactValue}&apos;.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.ContactDeleted]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.Name,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        deleted the contact <strong>{props.contactName}</strong>
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.NewTask]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const { userName, isYou, isSelfAssigned } = TaskUser({
        AssigneeList: props?.AssigneeList,
        Assignor: props?.Assignor,
        currentUserId,
      });
      return (
        <>
          {isSelfAssigned ? (
            <>
              You assigned the task{' '}
              {getLink(props.TaskName, link.toTransactionTasks(props.Transaction.Id), {
                transactionId: props.Transaction.Id,
                taskId: props.TaskId,
              })}{' '}
              to yourself.
            </>
          ) : (
            <>
              {getProfileViewName({
                Name: userName,
                UserId: props.UserId,
                Role: props.Role,
                isYou,
                handleProfileView,
              })}{' '}
              {isYou ? 'have' : 'has'} created a task:{' '}
              {getLink(props.TaskName, link.toTransactionTasks(props.Transaction.Id), {
                transactionId: props.Transaction.Id,
                taskId: props.TaskId,
              })}
              .
            </>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.NewTaskSummary]: {
    isExpandable: (props) => props?.Highlights?.length <= 5,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const { userName, isYou } = TaskUser({
        AssigneeList: props?.AssigneeList,
        Assignor: props?.Assignor,
        currentUserId,
      });
      const isSelfAssigned =
        props?.Highlights?.every(
          (highlight) =>
            highlight.assignees?.some((assignee) => assignee?.AssigneeId === currentUserId) ??
            false,
        ) ?? false;

      let tasksUrl: string | ReactElement = ` task${props.Highlights.length !== 1 ? 's' : ''}`;
      if (props?.Highlights?.length > 5) {
        tasksUrl = getLink(tasksUrl, link.toTransactionTasks(props.Transaction.Id), {
          transactionId: props.Transaction.Id,
          assignor: props?.Assignor?.Id,
          taskIds: props.Highlights.map((task) => task.taskId).filter(Boolean),
        });
      }
      return (
        <>
          {getProfileViewName({
            Name: userName,
            UserId: props.UserId,
            Role: props.Role,
            isYou,
            handleProfileView,
          })}{' '}
          {isSelfAssigned ? 'assigned' : 'created'} {props.Highlights.length} {tasksUrl}{' '}
          {isSelfAssigned ? 'to yourself' : ''}.
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {renderValues('taskSummary', props?.Highlights, 'created')}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.TaskAssigned]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      return (
        <>
          {getProfileViewName({
            Name: `${props?.Assignee?.FirstName}${
              props?.Assignee?.LastName ? ` ${props?.Assignee?.LastName}` : ''
            }`,
            UserId: props.Id,
            Role: props.Assignee?.Role,
            isYou: currentUserId === props.Assignee?.Id,
            handleProfileView,
          })}{' '}
          {currentUserId === props.Assignee?.Id ? 'have' : 'has'} a new task:{' '}
          {getLink(props.TaskName, link.toTransactionTasks(props.Transaction.Id), {
            transactionId: props.Transaction.Id,
            taskId: props.TaskId,
          })}
          .
          {showTransactionAddress(props?.Transaction?.Id) ? (
            <>
              {' '}
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <></>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TaskAssignedSummary]: {
    isExpandable: (props) => props?.Highlights?.length <= 5,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      let tasksUrl: string | ReactElement = ` tasks`;
      if (props?.Highlights?.length > 5) {
        tasksUrl = getLink(tasksUrl, link.toTransactionTasks(props.Transaction.Id), {
          transactionId: props.Transaction.Id,
          assignor: props?.Assignor?.Id,
          taskIds: props.Highlights.map((task) => task.taskId).filter(Boolean),
        });
      }

      return (
        <>
          {getProfileViewName({
            Name: `${props?.Assignee?.FirstName}${
              props?.Assignee?.LastName ? ` ${props?.Assignee?.LastName}` : ''
            }`,
            UserId: props.Id,
            Role: props.Assignee?.Role,
            isYou: currentUserId === props.Assignee?.Id,
            handleProfileView,
          })}{' '}
          {currentUserId === props.Assignee?.Id ? ' have' : ' has'} {props.Highlights.length} new
          {tasksUrl}
          {showTransactionAddress(props?.Transaction?.Id) ? (
            props.transactionCount == 1 ? (
              <>
                {' '}
                {getType(props?.Transaction)}:{' '}
                {getLink(
                  getAddressOrProject(props?.Transaction),
                  link.toTransactionOverview(props?.Transaction?.Id),
                )}
                .
              </>
            ) : (
              <> across {props.transactionCount} transactions</>
            )
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {renderValues('taskSummary', props?.Highlights, 'new', props.transactionCount)}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.TaskCompleted]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const completedBy = props?.CompletedBy?.FirstName
        ? `${props?.CompletedBy?.FirstName} ${props?.CompletedBy?.LastName}`
        : props?.CompletedBy?.Email || '';
      return (
        <>
          {getProfileViewName({
            Name: completedBy,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          completed the task:{' '}
          {getLink(props.Title, link.toTransactionTasks(props.Transaction.Id), {
            transactionId: props.Transaction.Id,
            taskId: props.TaskId,
          })}
          .
          {showTransactionAddress(props?.Transaction?.Id) ? (
            <>
              {' '}
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <></>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TaskCompletedSummary]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const completedBy = props?.CompletedBy?.FirstName
        ? `${props?.CompletedBy?.FirstName} ${props?.CompletedBy?.LastName}`
        : props?.CompletedBy?.Email || '';
      return (
        <>
          {' '}
          {getProfileViewName({
            Name: completedBy,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          completed {props.Highlights.length} tasks
          {showTransactionAddress(props?.Transaction?.Id) ? (
            props.transactionCount == 1 ? (
              <>
                {' '}
                {getType(props?.Transaction)}:{' '}
                {getLink(
                  getAddressOrProject(props?.Transaction),
                  link.toTransactionOverview(props?.Transaction?.Id),
                )}
                .
              </>
            ) : (
              <> across {props.transactionCount} transactions</>
            )
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {renderValues('taskSummary', props?.Highlights, `completed `, props.transactionCount)}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.TaskUpcoming]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const { assignees } = TaskUser({
        AssigneeList: props?.Assignee,
        currentUserId,
      });
      return (
        <>
          {assignees.map((assign, index) => (
            <>
              {getProfileViewName({
                Name: assign.name,
                UserId: assign.id,
                Role: assign.role,
                isYou: assign.isYou,
                handleProfileView,
              })}
              {index < assignees.length - 1 ? ', ' : ''}
            </>
          ))}{' '}
          {assignees?.length === 1 && assignees?.[0]?.name !== 'You' ? 'has' : 'have'} a task which
          is due <OverDueText text={'tomorrow'} />:{' '}
          {getLink(props.TaskName, link.toTransactionTasks(props.Transaction.Id), {
            transactionId: props.Transaction.Id,
            taskId: props.TaskId,
          })}
          .
          {showTransactionAddress(props?.Transaction?.Id) ? (
            <>
              {' '}
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <></>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TaskOverdue]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const { assignees } = TaskUser({
        AssigneeList: props?.Assignee,
        currentUserId,
      });
      return (
        <>
          {assignees.map((assign, index) => (
            <>
              {getProfileViewName({
                Name: assign.name,
                UserId: assign.id,
                Role: assign.role,
                isYou: assign.isYou,
                handleProfileView,
              })}
              {index < assignees.length - 1 ? ', ' : ''}
            </>
          ))}{' '}
          {assignees?.length === 1 && assignees?.[0]?.name !== 'You' ? 'has' : 'have'} a task which
          is <OverDueText text={'overdue'} />:{' '}
          {getLink(props.TaskName, link.toTransactionTasks(props.Transaction.Id), {
            transactionId: props.Transaction.Id,
            taskId: props.TaskId,
          })}
          .
          {showTransactionAddress(props?.Transaction?.Id) ? (
            <>
              {' '}
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <></>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TaskOverdueSummary]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      let assigne = props?.Assignee[0].Assignee;
      const { assignees } = TaskUser({
        AssigneeList: props?.Assignee,
        currentUserId,
      });
      return (
        <>
          {getProfileViewName({
            Name: `${assigne.FirstName} ${assigne.LastName}`,
            UserId: assigne.Id,
            Role: assigne.Roles[0],
            isYou: currentUserId === assigne.Id,
            handleProfileView,
          })}{' '}
          {assignees?.length === 1 && assignees?.[0]?.name !== 'You' ? ' has' : ' have'}{' '}
          {props.Highlights.length} <OverDueText text={'overdue'} /> tasks{' '}
          {showTransactionAddress(props?.Transaction?.Id) ? (
            props.transactionCount == 1 ? (
              <>
                {' '}
                {getType(props?.Transaction)}:{' '}
                {getLink(
                  getAddressOrProject(props?.Transaction),
                  link.toTransactionOverview(props?.Transaction?.Id),
                )}
                .
              </>
            ) : (
              <> across {props.transactionCount} transactions</>
            )
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {renderValues('taskOverDueSummary', props?.Highlights, ` `, props.transactionCount)}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.UserTaggedInTask]: {
    isExpandable: false,
    TaglineMarkup: () => <></>,
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TaskTagged]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        <div>
          {getProfileViewName({
            Name: `${props?.TaggedUser?.FirstName}${
              props?.TaggedUser?.LastName ? ` ${props?.TaggedUser?.LastName}` : ''
            }`,
            UserId: props.TaggedUser?.Id,
            Role: props.TaggedUser?.Role,
            isYou: props.TaggedUser?.Id === currentUserId,
            handleProfileView,
          })}{' '}
          were tagged in a{' '}
          {getLink('comment', link.toTransactionTasks(props.Transaction?.Id), {
            transactionId: props.Transaction?.Id,
            taskId: props.TaskId,
            showComments: true,
            commentId: props?.CommentId,
          })}{' '}
          on the following task:{' '}
          {getLink(props.TaskName, link.toTransactionTasks(props.Transaction?.Id), {
            transactionId: props.Transaction?.Id,
            taskId: props.TaskId,
          })}
          .{' '}
          {showTransactionAddress(props?.Transaction?.Id) ? (
            <>
              {' '}
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TaskStuck]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.ChangedBy?.Id === currentUserId;
      const changedBy = isYou
        ? 'you'
        : `${props?.ChangedBy?.FirstName || props?.ChangedBy?.Email || ''}${
            props?.ChangedBy?.LastName ? ` ${props?.ChangedBy?.LastName}` : ''
          }`;
      return (
        <>
          The following task was marked stuck by{' '}
          {getProfileViewName({
            Name: changedBy,
            UserId: props.UserId,
            Role: props.Role,
            isYou,
            handleProfileView,
          })}
          :{' '}
          {getLink(props.Title, link.toTransactionTasks(props.Transaction.Id), {
            transactionId: props.Transaction.Id,
            taskId: props.TaskId,
          })}
          .
          {showTransactionAddress(props?.Transaction?.Id) ? (
            <>
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <></>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TaskStuckSummary]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.ChangedBy?.Id === currentUserId;
      const changedBy = isYou
        ? 'you'
        : `${props?.ChangedBy?.FirstName || props?.ChangedBy?.Email || ''}${
            props?.ChangedBy?.LastName ? ` ${props?.ChangedBy?.LastName}` : ''
          }`;
      return (
        <>
          {' '}
          {getProfileViewName({
            Name: changedBy,
            UserId: props.UserId,
            Role: props.Role,
            isYou: isYou,
            handleProfileView,
          })}{' '}
          {`marked ${props.Highlights.length} tasks as "stuck"`}
          {showTransactionAddress(props?.Transaction?.Id) ? (
            props.transactionCount == 1 ? (
              <>
                {' '}
                {getType(props?.Transaction)}:{' '}
                {getLink(
                  getAddressOrProject(props?.Transaction),
                  link.toTransactionOverview(props?.Transaction?.Id),
                )}
                .
              </>
            ) : (
              <> across {props.transactionCount} transactions</>
            )
          ) : (
            <></>
          )}
          .
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {renderValues('taskSummary', props?.Highlights, `stuck `, props.transactionCount)}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.MilestoneAdded]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          New milestone added:{' '}
          <strong>
            <a
              href={`/workshop/transactions/${props?.Transaction?.Id}/overview`}
              rel="noreferrer"
              style={feedbackStyles}
              onMouseEnter={(e) => mouseEnterHandler(e)}
              onMouseLeave={(e) => mouseLeaveHandler(e)}
            >
              {props?.MilestoneName}
            </a>
          </strong>
          .
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.MilestoneAddedSummary]: {
    isExpandable: true,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          {props.Highlights.length} milestones were added
          {showTransactionAddress(props?.Transaction?.Id) ? (
            props.transactionCount == 1 ? (
              <>
                {' '}
                {getType(props?.Transaction)}:{' '}
                {getLink(
                  getAddressOrProject(props?.Transaction),
                  link.toTransactionOverview(props?.Transaction?.Id),
                )}
                .
              </>
            ) : (
              <> across {props.transactionCount} transactions</>
            )
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {renderValues(
            'mileStoneAddedSummary',
            props?.Highlights,
            `assigned `,
            props.transactionCount,
          )}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.MilestoneEdited]: {
    isExpandable: true,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          A milestone was updated:{' '}
          <strong>
            <a
              href={`/workshop/transactions/${props?.Transaction?.Id}/overview`}
              rel="noreferrer"
              style={feedbackStyles}
              onMouseEnter={(e) => mouseEnterHandler(e)}
              onMouseLeave={(e) => mouseLeaveHandler(e)}
            >
              {props?.MilestoneName}
            </a>
          </strong>
          .
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          <span>
            <StrongListTitle title="Old Setting:" />
            {renderMilestoneUpdatedHighlight(props?.Highlights, 'oldVal')}
          </span>
          <span>
            <StrongListTitle title="New Setting:" />
            {renderMilestoneUpdatedHighlight(props?.Highlights, 'newVal')}
          </span>
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.MilestoneCompleted]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId }) => {
      const isYou = props?.UserId === currentUserId;
      const changedBy = isYou
        ? 'you'
        : `${props?.UserFirstName || props?.UserEmail || ''}${
            props?.UserLastName ? ` ${props?.UserLastName}` : ''
          }`;
      return (
        <>
          Milestone marked complete by <strong>{changedBy}</strong>:{' '}
          <strong>
            <a
              href={`/workshop/transactions/${props?.Transaction?.Id}/overview`}
              rel="noreferrer"
              style={feedbackStyles}
              onMouseEnter={(e) => mouseEnterHandler(e)}
              onMouseLeave={(e) => mouseLeaveHandler(e)}
            >
              {props?.MilestoneName}
            </a>
          </strong>
          .
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.MilestoneMissed]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          Milestone was <OverDueText text="missed" />:{' '}
          <strong>
            <a
              href={`/workshop/transactions/${props?.Transaction?.Id}/overview`}
              rel="noreferrer"
              style={feedbackStyles}
              onMouseEnter={(e) => mouseEnterHandler(e)}
              onMouseLeave={(e) => mouseLeaveHandler(e)}
            >
              {props?.MilestoneName}
            </a>
          </strong>
          {showTransactionAddress(props?.Transaction?.Id) ? (
            <>
              {' '}
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.MilestoneMissedSummary]: {
    isExpandable: true,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          {props.Highlights.length} milestones were <OverDueText text=" missed " />{' '}
          {showTransactionAddress(props?.Transaction?.Id) ? (
            props.transactionCount == 1 ? (
              <>
                {' '}
                {getType(props?.Transaction)}:{' '}
                {getLink(
                  getAddressOrProject(props?.Transaction),
                  link.toTransactionOverview(props?.Transaction?.Id),
                )}
                .
              </>
            ) : (
              <> across {props.transactionCount} transactions</>
            )
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {renderValues(
            'mileStoneAddedSummary',
            props?.Highlights,
            `assigned `,
            props.transactionCount,
          )}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.MilestoneDeadlineUpcoming]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          Milestone {props.MilestoneName} is due on {moment(props.DueDate).format('MM/DD/YYYY')}.{' '}
          {showTransactionAddress(props?.Transaction?.Id) ? (
            <>
              {' '}
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <></>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.MilestoneDeadlineUpcomingSummary]: {
    isExpandable: true,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          {props?.Highlights?.length} Milestones are due on{' '}
          {moment(props.DueDate).format('MM/DD/YYYY')}
          {showTransactionAddress(props?.Transaction?.Id) ? (
            props.transactionCount == 1 ? (
              <>
                {' '}
                {getType(props?.Transaction)}:{' '}
                {getLink(
                  getAddressOrProject(props?.Transaction),
                  link.toTransactionOverview(props?.Transaction?.Id),
                )}
                .
              </>
            ) : (
              <> across {props.transactionCount} transactions</>
            )
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {renderValues(
            'mileStoneAddedSummary',
            props?.Highlights,
            `assigned `,
            props.transactionCount,
          )}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.MilestoneOverdue]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          <OverDueText text="Overdue" /> milestone:{' '}
          <strong>
            <a
              href={`/workshop/transactions/${props?.Transaction?.Id}/overview`}
              rel="noreferrer"
              style={feedbackStyles}
              onMouseEnter={(e) => mouseEnterHandler(e)}
              onMouseLeave={(e) => mouseLeaveHandler(e)}
            >
              {props?.MilestoneName}
            </a>
          </strong>
          .
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.MilestoneOverdueSummary]: {
    isExpandable: true,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          {props.Highlights.length} {' milestones '} <OverDueText text=" overdue " />
          {showTransactionAddress(props?.Transaction?.Id) ? (
            props.transactionCount == 1 ? (
              <>
                {' '}
                {getType(props?.Transaction)}:{' '}
                {getLink(
                  getAddressOrProject(props?.Transaction),
                  link.toTransactionOverview(props?.Transaction?.Id),
                )}
                .
              </>
            ) : (
              <> across {props.transactionCount} transactions</>
            )
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {renderValues(
            'mileStoneAddedSummary',
            props?.Highlights,
            `assigned `,
            props.transactionCount,
          )}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.FormAssigned]: {
    isExpandable: false,
    TaglineMarkup: () => <></>,
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.FormCompleted]: {
    isExpandable: false,
    TaglineMarkup: () => <></>,
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.MessageReceived]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        You have a new message from{' '}
        {getProfileViewName({
          Name: `${props?.SenderFirstName} ${props?.SenderLastName}`,
          UserId: props.SenderId,
          Role: props.SenderRole,
          isYou: props.SenderId === currentUserId,
          handleProfileView,
        })}
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.DocumentUploadedInTransaction]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: `${props?.UploaderFirstName} ${props?.UploaderLastName}`,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        uploaded the following document <StrongTitle title={props?.DocumentName} />.{' '}
        {showTransactionAddress(props?.TransactionId) ? (
          <>
            {' '}
            {getType(props)}:{' '}
            {getLink(getAddressOrProject(props), link.toTransactionDocuments(props?.TransactionId))}
            .
          </>
        ) : (
          <></>
        )}
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.UserReceivedNotification]: {
    isExpandable: false,
    TaglineMarkup: () => <></>,
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.ShowingAppointmentRequested]: {
    isExpandable: true,
    TaglineMarkup: ({ props }) => {
      return props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
        <>
          A showing was requested at{' '}
          {getLink(
            getAddressOrProject(props?.Transaction),
            link.toTransactionOverview(props?.Transaction?.Id),
          )}
          .
        </>
      ) : (
        <>A showing was requested.</>
      );
    },
    ExpandedMarkup: (props) => (
      <Row>
        <Col xs={24}>
          <StrongListTitle title="Request:" />
          {moment(props?.NewDate).format('MM/DD/YYYY')} at {props?.NewTime}
        </Col>
        <Col xs={24}>
          <StrongListTitle title="Requested By:" />
          {props?.RequestorName} with {props?.Brokerage}
        </Col>
        {/* // How to show these approvers on front-end */}
        {!props?.AutomaticApproval && props?.ApproverUser && (
          <Col xs={24}>
            <StrongListTitle title="Approval Required:" />
            {props?.ApproverUser}
          </Col>
        )}
      </Row>
    ),
  },
  [ActivityLogsTypesValue.ShowingAppointmentCancelled]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      return (
        <>
          {getProfileViewName({
            Name: props.Name,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          canceled a showing scheduled for{' '}
          <StrongTitle title={moment(props?.NewDate).format('MM/DD/YYYY')} /> at{' '}
          <StrongTitle title={props?.NewTime} />
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.ShowingAppointmentRescheduled]: {
    // A showing appointment was rescheduled. Original Appointment: (insert original date and time). New Appointment: (insert new date and time). Requested By: (requestor name) with (requestor brokerage).
    isExpandable: true,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          A showing was rescheduled
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return (
        <Row>
          <Col xs={24}>
            <StrongListTitle title="Original Appointment:" />
            {moment(props?.OldDate).format('MM/DD/YYYY')} at {props?.OldTime}
          </Col>
          <Col xs={24}>
            <StrongListTitle title="New Appointment:" />
            {moment(props?.NewDate).format('MM/DD/YYYY')} at {props?.NewTime}
          </Col>
          <Col xs={24}>
            <StrongListTitle title="Requested By:" />
            {props?.Name} with {props?.Brokerage}
          </Col>
        </Row>
      );
    },
  },
  [ActivityLogsTypesValue.ShowingAppointmentApproved]: {
    isExpandable: true,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          A showing was approved {props?.AutomaticApproval ? 'automatically' : 'by all parties'}
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      // *** Required discussion on this one. Approver user get when action status AhowigAppointmentApprovalApproved.
      return (
        <Row>
          <Col xs={24}>
            <StrongListTitle title="Appointment Details:" />
            {moment(props?.NewDate).format('MM/DD/YYYY')} at {props?.NewTime}
          </Col>
          {!props?.AutomaticApproval && props?.ApproverUser && (
            <Col xs={24}>
              <StrongListTitle title="Approved By:" />
              {props?.ApproverUser}
            </Col>
          )}
          {!props?.AutomaticApproval && (
            <Col xs={24}>
              <StrongListTitle title="Pending For:" />
              {props?.PendingApprovers}
            </Col>
          )}
          <Col xs={24}>
            <StrongListTitle title="Requested By:" />
            {props?.RequestorName}
          </Col>
        </Row>
      );
    },
  },
  [ActivityLogsTypesValue.ShowingAppointmentFeedback]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      return (
        <>
          {getProfileViewName({
            Name: props.Name,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          with {props?.Brokerage} provided new showing feedback
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.ShowingAppointmentLapsed]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          <StrongTitle title={props?.PendingApprovers} /> did not approve a showing in time and the
          request lapsed
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}{' '}
          <StrongTitle title={'Request:'} /> {props?.ShowingAppointmentDateTime}{' '}
          <StrongTitle title={'Pending For:'} /> {props?.PendingForTime}{' '}
          <StrongTitle title={'Requested By:'} /> {props?.RequesterName} with{' '}
          {props?.RequesterBrokerageName}.
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.ShowingAppointmentApprovalApproved]: {
    //***  Date Time calculation for pending approvals needed
    isExpandable: true,
    TaglineMarkup: ({ props }) => {
      return (
        <>
          A showing was approved
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return (
        <Row>
          <Col xs={24}>
            <StrongListTitle title="Appointment Details:" />
            {moment(props?.NewDate).format('MM/DD/YYYY')} at {props?.NewTime}
          </Col>
          {!props?.AutomaticApproval && props?.ApproverUser && (
            <Col xs={24}>
              <StrongListTitle title="Approved By:" />
              {props?.ApproverUser}
              {` ,${moment(props?.UpdatedDate).format('MM/DD/YYYY')} at ${props?.UpdatedTime}`}
            </Col>
          )}
          {!props?.AutomaticApproval && (
            <Col xs={24}>
              <StrongListTitle title="Pending For:" />
              {props?.DaysAndHours}
            </Col>
          )}
          <Col xs={24}>
            <StrongListTitle title="Requested By:" />
            {`${props?.RequestorName} with ${props?.Brokerage}`}
          </Col>
        </Row>
      );
    },
  },
  [ActivityLogsTypesValue.ShowingAppointmentApprovalDeclined]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      return (
        <>
          {getProfileViewName({
            Name: props.ApproverUser,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          declined a showing request
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => (
      <Row>
        <Col xs={24}>
          <StrongListTitle title="Requested: " />
          {moment(props?.NewDate).format('MM/DD/YYYY')}
          {' at '}
          {props?.NewTime}
          <StrongListTitle title="Requested By: " />
          {props?.RequestorName} with {props?.Brokerage}
          <StrongListTitle title="Declined: " />
          {moment(props?.UpdatedDate).format('MM/DD/YYYY')}
          {' at '}
          {props?.UpdatedTime}
        </Col>
      </Row>
    ),
  },
  [ActivityLogsTypesValue.ShowingAppointmentApprovalCancelled]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      return (
        <>
          {getProfileViewName({
            Name: props.ApproverUser,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          cancelled a showing request
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getType(props?.Transaction)}:{' '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => (
      <Row>
        <Col xs={24}>
          <StrongListTitle title="Requested: " />
          {moment(props?.NewDate).format('MM/DD/YYYY')}
          {' at '}
          {props?.NewTime}
          <StrongListTitle title="Requested By:" />
          {props?.RequestorName} with {props?.Brokerage}
          <StrongListTitle title="Cancelled: " />
          {moment(props?.UpdatedDate).format('MM/DD/YYYY')}
          {' at '}
          {props?.UpdatedTime}
        </Col>
      </Row>
    ),
  },
  [ActivityLogsTypesValue.ShowingLinkCreated]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      return (
        <>
          {getProfileViewName({
            Name: props.Name,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          created the booking link for showings
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.ShowingLinkEnabled]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      return (
        <>
          {getProfileViewName({
            Name: props.Name,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          activated the booking link for showings
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.ShowingLinkDisabled]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      return (
        <>
          {getProfileViewName({
            Name: props.Name,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          deactivated the booking link for showings
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.OfferSubmitted]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: props.SubmittedByName,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
          spacer: ` `,
        })}
        submitted{' '}
        {getLink(`Offer #${props?.OfferCount}`, link.toTransactionOffers(props.TransactionId), {
          offerId: props.OfferId,
        })}
        {!(props.showAddress && showTransactionAddress(props?.TransactionId)) && <>.</>}{' '}
        {props.showAddress && showTransactionAddress(props?.TransactionId) ? (
          <>
            {' '}
            Transaction:{' '}
            {getLink(props.TransactionAddress, link.toTransactionOverview(props.TransactionId))}.
          </>
        ) : (
          <></>
        )}
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.OfferCounterSent]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.SentBy?.UserId === currentUserId;
      return isYou ? (
        <>
          <StrongTitle title={`Counter #${props.IterationCount}`} /> was sent{' '}
          {`${isYou ? 'by ' : 'to '}`}
          {getProfileViewName({
            Name: props.BuyerName,
            UserId: props.UserId,
            Role: props.Role,
            isYou,
            handleProfileView,
            spacer: `. `,
          })}
        </>
      ) : (
        <>
          {getProfileViewName({
            Name: props?.SentBy?.FirstName
              ? `${props?.SentBy?.FirstName} ${props?.SentBy?.LastName}`
              : props?.SentBy?.BuyerName,
            UserId: props.UserId,
            Role: props.Role,
            isYou,
            handleProfileView,
            spacer: `. `,
          })}{' '}
          submitted <StrongTitle title={`Counter #${props?.IterationCount}`} />
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.OfferAccepted]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => (
      <>
        {getLink(`Offer #${props.OfferCount}`, link.toTransactionOffers(props.TransactionId), {
          offerId: props.OfferId,
        })}{' '}
        was accepted.{' '}
        {props.showAddress && showTransactionAddress(props?.TransactionId) ? (
          <>
            <br />
            Transaction:{' '}
            {getLink(
              getPropertyAddress(props?.TransactionAddress),
              link.toTransactionOverview(props?.TransactionId),
            )}
            .
          </>
        ) : (
          <></>
        )}
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.OfferRejected]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => (
      <>
        {getLink(`Offer #${props?.OfferCount}`, link.toTransactionOffers(props.TransactionId), {
          offerId: props.OfferId,
        })}{' '}
        was rejected
        {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
          <>
            {' '}
            Transaction:{' '}
            {getLink(props.TransactionAddress, link.toTransactionOverview(props.TransactionId))}.
          </>
        ) : (
          <>.</>
        )}
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.OfferWithdrawn]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => (
      <>
        {getLink(`Offer #${props?.OfferCount}`, link.toTransactionOffers(props.TransactionId), {
          offerId: props.OfferId,
        })}{' '}
        was withdrawn
        {Boolean(props?.TransactionId || props?.Transaction?.Id) &&
        showTransactionAddress(props.TransactionId || props?.Transaction?.Id) ? (
          <>
            {' '}
            Transaction:{' '}
            {getLink(props.TransactionAddress, link.toTransactionOverview(props.TransactionId))}.
          </>
        ) : (
          <>.</>
        )}
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.OfferUpdated]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getLink(`Offer #${props?.OfferCount}`, link.toTransactionOffers(props.TransactionId), {
          offerId: props.OfferId,
        })}{' '}
        was edited by{' '}
        {getProfileViewName({
          Name: props.SubmittedByName,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
          spacer: `. `,
        })}
        {props.showAddress && props?.Transaction?.Id ? (
          <>
            Transaction:{' '}
            {getLink(props.TransactionAddress, link.toTransactionOverview(props.TransactionId))}.
          </>
        ) : (
          <></>
        )}
      </>
    ),
    ExpandedMarkup: (props) => {
      if (props?.Highlights && props?.Highlights?.length > 0) {
        props.Highlights = props.Highlights.filter(
          ({ key }) => key !== 'OfferLink' && key !== 'isInvited',
        );
        props.Highlights.forEach((item) => {
          if (item.key === 'LastEditedBy' && (item.oldVal || item.newVal)) {
            if (item.oldVal) {
              item.oldVal =
                typeof item.oldVal === 'object'
                  ? `${item.oldVal.FirstName} ${item.oldVal.LastName || ''}`
                  : item.oldVal;
            }

            if (item.newVal) {
              item.newVal =
                typeof item.newVal === 'object'
                  ? `${item.newVal.FirstName} ${item.newVal.LastName || ''}`
                  : item.newVal;
            }
          } else if (
            ['OfferExpiryEndDate', 'LastEditedDate'].includes(item.key) &&
            (item.oldVal || item.newVal)
          ) {
            if (item.oldVal && moment(item.oldVal).isValid()) {
              item.oldVal = `${moment(item.oldVal).format('MM/DD/YYYY')} at ${moment(
                item.oldVal,
              ).format('hh:mm A')}`;
            }

            if (item.newVal && moment(item.newVal).isValid()) {
              item.newVal = `${moment(item.newVal).format('MM/DD/YYYY')} at ${moment(
                item.newVal,
              ).format('hh:mm A')}`;
            }
          }
        });

        return (
          <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
            <strong>Before:</strong>
            <span>{renderValues('old', props?.Highlights)}</span>
            <div />
            <strong>After: </strong>
            <span>{renderValues('new', props?.Highlights)}</span>
          </Space>
        );
      }

      return <>Nothing Updated</>;
    },
  },
  [ActivityLogsTypesValue.OfferAnnouncement]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.SendBy === currentUserId;
      return (
        <>
          {getProfileViewName({
            Name: isYou ? 'You' : props.SendByName,
            UserId: props.UserId,
            Role: props.Role,
            isYou,
            handleProfileView,
          })}{' '}
          sent an announcement to <StrongTitle title={props?.SendTo?.join(', ')} />.
        </>
      );
    },
    ExpandedMarkup: (props) => (
      <>
        <strong>It said: </strong>
        <p>{props?.Message}</p>
      </>
    ),
  },
  [ActivityLogsTypesValue.OfferExpired]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => (
      <>
        {getLink(`Offer #${props?.OfferCount}`, link.toTransactionOffers(props.TransactionId), {
          offerId: props.OfferId,
        })}{' '}
        Expired.
        {Boolean(props.showAddress || showTransactionAddress(props.TransactionId)) && (
          <>
            <br />
            Transaction:{' '}
            {getLink(props.TransactionAddress, link.toTransactionOverview(props.TransactionId))}.
          </>
        )}
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.OfferCounterUpdated]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        <StrongTitle title={`Counter #${props?.IterationCount}`} /> was edited by{' '}
        {getProfileViewName({
          Name: props.SubmittedByName,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
          spacer: `. `,
        })}
      </>
    ),
    ExpandedMarkup: (props) => {
      if (props?.Highlights && props?.Highlights?.length > 0) {
        props.Highlights = props.Highlights.filter(
          ({ key }) => key !== 'OfferLink' && key !== 'isInvited',
        );
        props.Highlights.forEach((item) => {
          if (item.key === 'LastEditedBy' && (item.oldVal || item.newVal)) {
            if (item.oldVal) {
              item.oldVal =
                typeof item.oldVal === 'object'
                  ? `${item.oldVal.FirstName} ${item.oldVal.LastName || ''}`
                  : item.oldVal;
            }

            if (item.newVal) {
              item.newVal =
                typeof item.newVal === 'object'
                  ? `${item.newVal.FirstName} ${item.newVal.LastName || ''}`
                  : item.newVal;
            }
          } else if (
            ['LastEditedDate', 'OfferExpiryEndDate'].includes(item.key) &&
            (item.oldVal || item.newVal)
          ) {
            if (item.oldVal && moment(item.oldVal).isValid()) {
              item.oldVal = `${moment(item.oldVal).format('MM/DD/YYYY')} at ${moment(
                item.oldVal,
              ).format('hh:mm A')}`;
            }

            if (item.newVal && moment(item.newVal).isValid()) {
              item.newVal = `${moment(item.newVal).format('MM/DD/YYYY')} at ${moment(
                item.newVal,
              ).format('hh:mm A')}`;
            }
          }
        });

        return (
          <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
            <strong>Before:</strong>
            <span>{renderValues('old', props?.Highlights)}</span>
            <div />
            <strong>After: </strong>
            <span>{renderValues('new', props?.Highlights)}</span>
          </Space>
        );
      }

      return <>Nothing Updated</>;
    },
  },
  [ActivityLogsTypesValue.CounterRejected]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => (
      <>
        <StrongTitle title={`Counter #${props?.IterationCount}`} /> was rejected.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.CounterAccepted]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => (
      <>
        <StrongTitle title={`Counter #${props?.IterationCount}`} /> was accepted.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.CounterWithdrawn]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => (
      <>
        <StrongTitle title={`Counter #${props?.IterationCount}`} /> was withdrawn.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.CounterExpired]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => (
      <>
        <StrongTitle title={`Counter #${props?.IterationCount}`} /> Expired.
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.OfferLinkCreated]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.ActionBy?.Id === currentUserId;
      return (
        <>
          {getProfileViewName({
            Name: `${props?.ActionBy?.FirstName} ${props?.ActionBy?.LastName}`,
            UserId: props.UserId,
            Role: props.Role,
            isYou,
            handleProfileView,
          })}{' '}
          created an offer management link.
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.OfferLinkUpdated]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.ActionBy?.Id === currentUserId;
      return (
        <>
          {getProfileViewName({
            Name: `${props?.ActionBy?.FirstName} ${props?.ActionBy?.LastName}`,
            UserId: props.UserId,
            Role: props.Role,
            isYou,
            handleProfileView,
          })}{' '}
          changed the offer link configuration,
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          <span>Old Setting: {renderValues('old-setting', props?.Highlights)}</span>
          <span>New Setting: {renderValues('new-setting', props?.Highlights)}</span>
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.OfferLinkEnabled]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.ActionBy?.Id === currentUserId;
      return (
        <>
          {getProfileViewName({
            Name: `${props?.ActionBy?.FirstName} ${props?.ActionBy?.LastName}`,
            UserId: props.UserId,
            Role: props.Role,
            isYou,
            handleProfileView,
          })}{' '}
          activated the offer management link.
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.OfferLinkDisabled]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.ActionBy?.Id === currentUserId;
      return (
        <>
          {getProfileViewName({
            Name: `${props?.ActionBy?.FirstName} ${props?.ActionBy?.LastName}`,
            UserId: props.UserId,
            Role: props.Role,
            isYou,
            handleProfileView,
          })}{' '}
          deactivated the offer management link.
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.QuoteRequested]: {
    isExpandable: false,
    TaglineMarkup: ({ props, IsQuoteDrawer, currentUserId, handleProfileView }) => {
      if (IsQuoteDrawer) {
        const { QuoteRequester, QuoteRequestId } = props;
        const dispatch = useDispatch();
        const history = useHistory();

        const onClickHandler = () => {
          dispatch(openCommentsDrawerEffect({ open: false }));
          dispatch(openMessagesDrawerAction(false));
          dispatch(openQuoteActivityDrawerAction({ open: false }));

          history.replace(`${routes.servicesQuotes}?quoteId=${QuoteRequestId}`);
        };

        return (
          <>
            <StrongTitle title={`${QuoteRequester?.FirstName} ${QuoteRequester?.LastName}`} />
            {' sent a quote '}

            <span className={styles.linkStyle} onClick={onClickHandler}>
              request
            </span>
          </>
        );
      } else {
        return (
          <>
            {getProfileViewName({
              Name: props.Name,
              UserId: props.UserId,
              Role: props.Role,
              isYou: props.UserId === currentUserId,
              handleProfileView,
            })}{' '}
            {props.UserId === currentUserId ? 'are' : 'is'} requesting quotes for{' '}
            <StrongTitle title={props?.CategoryTitle} />.
          </>
        );
      }
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.QuoteResponseReceived]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const onDownloadFile = () => {
        axios.get(props.UploadedDocument?.Url, { responseType: 'blob' }).then((response) => {
          fileDownload(response.data, props.UploadedDocument?.Filename);
        });
      };

      const isYou = props.QuoteRequester?.Id === currentUserId;

      return (
        <>
          <StrongTitle
            title={`${props.QuotePartner?.PartnerUserFirstName} ${props.QuotePartner?.PartnerUserLastName} (${props.QuotePartner?.BusinessName})`}
          />{' '}
          sent{' '}
          {getProfileViewName({
            Name: `${props.QuoteRequester?.FirstName || ''}${
              props.QuoteRequester?.LastName ? ` ${props.QuoteRequester?.LastName}` : ''
            }`,
            UserId: props.QuoteRequester.Id,
            Role: props.QuoteRequester.Role,
            isYou,
            isSmallCase: true,
            handleProfileView,
          })}{' '}
          a <StrongTitle title={props.CategoryTitle || ''} />{' '}
          <a
            className={styles.linkStyle}
            onClick={onDownloadFile}
            style={feedbackStyles}
            onMouseEnter={(e) => mouseEnterHandler(e)}
            onMouseLeave={(e) => mouseLeaveHandler(e)}
          >
            quote
          </a>
          .
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.QuoteResponseNewAttachment]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => {
      const onDownloadFile = () => {
        axios.get(props.UploadedDocument?.Url, { responseType: 'blob' }).then((response) => {
          fileDownload(response.data, props.UploadedDocument?.Filename);
        });
      };
      return (
        <>
          <StrongTitle
            title={`${props.QuotePartner?.PartnerUserFirstName} ${props.QuotePartner?.PartnerUserLastName} (${props.QuotePartner?.BusinessName})`}
          />{' '}
          updated their <StrongTitle title={props.CategoryTitle || ''} />{' '}
          <a
            className={styles.linkStyle}
            onClick={onDownloadFile}
            style={feedbackStyles}
            onMouseEnter={(e) => mouseEnterHandler(e)}
            onMouseLeave={(e) => mouseLeaveHandler(e)}
          >
            quote
          </a>
          .
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.PropertyDetailsViewTimeSpent]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      return (
        <>
          {getProfileViewName({
            Name: `${props?.FirstName} ${props?.LastName}`,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          viewed{' '}
          <strong>
            <a
              href={`/properties/feed/listing-detail/${props?.mlsId?.replace('}', '')}`}
              rel="noreferrer"
              style={feedbackStyles}
              onMouseEnter={(e) => mouseEnterHandler(e)}
              onMouseLeave={(e) => mouseLeaveHandler(e)}
            >
              {' '}
              {`${props?.Address?.Line1 ? props?.Address?.Line1 + ',' : '(private address),'} ${
                props?.Address?.City ? props?.Address?.City + ',' : ''
              } ${props?.Address?.State ? props?.Address?.State : ''}`}
            </a>
          </strong>{' '}
          for <StrongTitle title={GetTimeString(props?.TimeSpent?.Seconds)} />.{' '}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TourRequested]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      return (
        <>
          {getProfileViewName({
            Name: `${props?.FirstName} ${props?.LastName}`,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          requested a tour of{' '}
          <strong>
            <a
              href={`/properties/feed/listing-detail/${props?.PropertyId?.replace('}', '')}`}
              rel="noreferrer"
              style={feedbackStyles}
              onMouseEnter={(e) => mouseEnterHandler(e)}
              onMouseLeave={(e) => mouseLeaveHandler(e)}
            >
              {' '}
              {`${
                props?.PropertyAddress?.Line1
                  ? props?.PropertyAddress?.Line1 + ','
                  : '(private address),'
              } ${props?.PropertyAddress?.City ? props?.PropertyAddress?.City + ',' : ''} ${
                props?.PropertyAddress?.State ? props?.PropertyAddress?.State : ''
              }`}
            </a>
          </strong>
          .
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.PropertyComment]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      return (
        <>
          {getProfileViewName({
            Name: `${props?.SenderFirstName} ${props?.SenderLastName}`,
            UserId: props.UserId,
            Role: props.Role,
            isYou: props.UserId === currentUserId,
            handleProfileView,
          })}{' '}
          left a comment on{' '}
          <strong>
            <a
              href={`/properties/feed/listing-detail/${props?.PropertyId?.replace('}', '')}`}
              rel="noreferrer"
              style={feedbackStyles}
              onMouseEnter={(e) => mouseEnterHandler(e)}
              onMouseLeave={(e) => mouseLeaveHandler(e)}
            >
              {' '}
              {`${props?.PropertyAddress ? props?.PropertyAddress : '(private address)'}`}
            </a>
          </strong>
          .
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.ShowingLinkUpdated]: {
    isExpandable: true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.ActionBy?.Id === currentUserId;
      return (
        <>
          {getProfileViewName({
            Name: `${props?.ActionBy?.FirstName} ${props?.ActionBy?.LastName}`,
            UserId: props.UserId,
            Role: props.Role,
            isYou,
            handleProfileView,
          })}{' '}
          changed the showing link configuration
          {props?.Transaction?.Id && showTransactionAddress(props.Transaction.Id) ? (
            <>
              {' at '}
              {getLink(
                getAddressOrProject(props?.Transaction),
                link.toTransactionOverview(props?.Transaction?.Id),
              )}
              .
            </>
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          <span>Old Setting: {renderValues('old-setting', props?.Highlights)}</span>
          <span>New Setting: {renderValues('new-setting', props?.Highlights)}</span>
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.CollaborateDocument]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.CollaboratorUserId === currentUserId;
      return (
        <>
          <StrongTitle title={props?.FormName} /> was assigned to{' '}
          {getProfileViewName({
            Name: `${props.CollaboratorName || 'unknown'}`,
            UserId: props.CollaboratorUserId,
            Role: props.CollaboratorRole,
            isYou,
            handleProfileView,
          })}{' '}
          for editing.{' '}
          {!!props?.Clients && (
            <>
              <StrongTitle title="Client: " />
              {props?.Clients}.{' '}
            </>
          )}
          {!!props?.Transaction && (
            <>
              <StrongTitle title={`${getType(props?.Transaction)}: `} />
              {props?.Transaction}.
            </>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.DocumentReadyForSignature]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => (
      <>
        <StrongTitle title={props?.FormName} /> is ready for your signature.{' '}
        {!!props?.Clients && (
          <>
            <StrongTitle title="Client: " />
            {props?.Clients}.{' '}
          </>
        )}
        {!!props?.Transaction && (
          <>
            <StrongTitle title={`${getType(props?.Transaction)}: `} />
            {props?.Transaction}.
          </>
        )}
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.FormDocumentSignedByEveryone]: {
    isExpandable: false,
    TaglineMarkup: ({ props }) => (
      <>
        <StrongTitle title={props?.FormName} /> was completed and signed by all parties.{' '}
        {!!props?.Clients && (
          <>
            <StrongTitle title="Client: " />
            {props?.Clients}.{' '}
          </>
        )}
        {!!props?.Transaction && (
          <>
            <StrongTitle title={`${getType(props?.Transaction)}: `} />
            {props?.Transaction}.
          </>
        )}
      </>
    ),
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.FormSigned]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const isYou = props?.SignatoryId === currentUserId;
      return (
        <>
          {getProfileViewName({
            Name: `${props?.SignatoryName || 'unknown'}`,
            UserId: props.UserId,
            Role: props.Role,
            isYou,
            handleProfileView,
          })}{' '}
          signed the document <StrongTitle title={props?.FormName} />.{' '}
          {!!props?.Clients && (
            <>
              <StrongTitle title="Client: " />
              {props?.Clients}.{' '}
            </>
          )}
          {!!props?.Transaction && (
            <>
              <StrongTitle title={`${getType(props?.Transaction)}:`} />
              {props?.Transaction}.
            </>
          )}
        </>
      );
    },
    ExpandedMarkup: () => <></>,
  },
  [ActivityLogsTypesValue.TaskUpcomingSummary]: {
    isExpandable: true,

    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      let assign = props?.Assignee[0].Assignee;
      const { userName, isYou } = TaskUser({
        AssigneeList: props?.AssigneeList,
        Assignor: props?.Assignor,
        currentUserId,
      });
      return (
        <>
          {getProfileViewName({
            Name: `${assign.FirstName} ${assign.LastName} `,
            UserId: assign.Id,
            Role: assign.Roles[0],
            isYou: currentUserId === assign.Id,
            handleProfileView,
          })}{' '}
          {currentUserId === assign.Id ? 'have' : 'has'} {props.Highlights.length} {' tasks due '}
          <OverDueText text={'tomorrow '}></OverDueText>{' '}
          {showTransactionAddress(props?.Transaction?.Id) ? (
            props.transactionCount == 1 ? (
              <>
                {' '}
                {getType(props?.Transaction)}:{' '}
                {getLink(
                  getAddressOrProject(props?.Transaction),
                  link.toTransactionOverview(props?.Transaction?.Id),
                )}
                .
              </>
            ) : (
              <> across {props.transactionCount} transactions</>
            )
          ) : (
            <>.</>
          )}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {renderValues('taskUpcommingSummary', props?.Highlights, `due `, props.transactionCount)}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.TaskDeleted]: {
    isExpandable: false,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const { userName, isYou, isSelfAssigned } = TaskUser({
        AssigneeList: props?.AssigneeList,
        Assignor: props?.Assignor,
        currentUserId,
      });
      return (
        <>
          {getProfileViewName({
            Name: userName,
            UserId: props.userId,
            Role: props.Role,
            isYou,
            handleProfileView,
          })}{' '}
          {`deleted the task`} <StrongTitle title={props.TaskName} />.
        </>
      );
    },
    ExpandedMarkup: <></>,
  },
  [ActivityLogsTypesValue.TaskDeletedSummary]: {
    isExpandable: (props) => props?.Highlights?.length > 1,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => {
      const { userName, isYou, isSelfAssigned } = TaskUser({
        AssigneeList: props?.AssigneeList,
        Assignor: props?.Assignor,
        currentUserId,
      });
      return (
        <>
          {getProfileViewName({
            Name: userName,
            UserId: props.userId,
            Role: props.Role,
            isYou,
            handleProfileView,
          })}{' '}
          {` deleted ${props?.Highlights?.length} task${
            props?.Highlights?.length > 1 ? 's.' : '.'
          }`}
        </>
      );
    },
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {props?.Highlights.map(({ key, taskId }) => (
            <StrongTitle key={taskId} title={`${key}.`} />
          ))}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
  [ActivityLogsTypesValue.DocumentUpdated]: {
    isExpandable: (props) => true,
    TaglineMarkup: ({ props, currentUserId, handleProfileView }) => (
      <>
        {getProfileViewName({
          Name: `${props?.FirstName} ${props?.LastName}`,
          UserId: props.UserId,
          Role: props.Role,
          isYou: props.UserId === currentUserId,
          handleProfileView,
        })}{' '}
        updated a document <StrongTitle title={props?.UpdatedDocument?.DocumentName} />.{' '}
        {showTransactionAddress(props?.TransactionId) ? (
          <>
            {' '}
            {getType(props)}:{' '}
            {getLink(getAddressOrProject(props), link.toTransactionOverview(props?.TransactionId))}.
          </>
        ) : (
          <></>
        )}
      </>
    ),
    ExpandedMarkup: (props) => {
      return props?.Highlights && props?.Highlights?.length > 0 ? (
        <Space size="small" direction="vertical" className={styles.beforeAfterSpacing}>
          {props?.Highlights.map(
            (
              { documentHighlightKey, documentHighlightOldValue, documentHighlightNewValue },
              index,
            ) => (
              <div key={index}>
                {`${documentHighlightKey}: `}
                <strong>
                  <del>{documentHighlightOldValue}</del>
                </strong>
                {' to '}
                <strong>{documentHighlightNewValue}</strong>
              </div>
            ),
          )}
        </Space>
      ) : (
        <>Nothing Updated</>
      );
    },
  },
};
const StrongTitle: React.FC<{ title: string; children?: React.ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <strong>
      {title}
      {children}
    </strong>
  );
};

const StrongListTitle: React.FC<{ title: string; children?: React.ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <strong className={styles.strongListtitle}>
      {title}
      {children}
    </strong>
  );
};
const OverDueText = ({ text }) => {
  return <span className={classNames(styles.overDueText)}>{text}</span>;
};

const TaskUser = ({
  AssigneeList,
  Assignor,
  currentUserId,
}: {
  AssigneeList: any[];
  Assignor?: any;
  currentUserId?: number;
}) => {
  let userName = '';
  let isYou = false;
  let assignees: { id: number; name: string; isYou: boolean; role: Role }[] = [];

  if (Array.isArray(AssigneeList) && AssigneeList[0]?.Assignee) {
    AssigneeList?.forEach((assign) => {
      assignees.push({
        id: assign?.Assignee?.Id,
        name:
          assign?.Assignee?.Id === currentUserId
            ? 'You'
            : assign?.Assignee?.FirstName
            ? `${assign?.Assignee?.FirstName} ${assign?.Assignee?.LastName}`
            : assign?.Assignee?.Email,
        isYou: assign?.Assignee?.Id === currentUserId,
        role: assign?.Assignee?.Roles?.[0],
      });
    });
  }
  if (Assignor && Assignor.Id) {
    if (Assignor.Id === currentUserId) {
      userName = 'You';
      isYou = true;
    } else {
      userName = `${Assignor?.FirstName} ${Assignor?.LastName}`;
      isYou = false;
    }
  }
  const isSelfAssigned = isYou && assignees.some((assignee) => assignee.isYou);
  return { userName, isYou, assignees, isSelfAssigned };
};

const ChangeFromTo = ({ change }) => {
  const getFormattedChangeWithOldValues = (data, fieldName) => {
    if (data instanceof Object && !(data instanceof Array)) {
      if (keys(data).length > 0) {
        return keys(data).map((key, idx) => {
          return (
            <>
              {SearchCriteriaFilterFields.includes(fieldName) && (
                <div key={idx}>
                  {`${key}: `}
                  <strong>
                    <del>{change?.oldValues[key]}</del>
                  </strong>
                  {' to '}
                  <strong>{`${change?.updatedValues[key]}`}</strong>
                </div>
              )}
            </>
          );
        });
      }
      return <div>{`${keys(data)}: ${values(data)}`}</div>;
    }
    if (data instanceof Object && data instanceof Array) {
      if (data?.length > 0) {
        if (fieldName === 'HomeType') {
          return (
            <>
              <del>
                {change?.oldValues?.length > 0 &&
                  change?.oldValues
                    ?.map((homeType) => SearchCriteriaFilterPropertyHomeType[homeType])
                    .join(', ')}
              </del>
              <br />
              {change?.updatedValues?.length > 0 &&
                change?.updatedValues
                  ?.map((homeType) => SearchCriteriaFilterPropertyHomeType[homeType])
                  .join(', ')}
            </>
          );
        }
        if (fieldName === 'Locations') {
          return <span>{data[0]?.PlaceName}</span>;
        }
        return data?.map((status) => SearchCriteriaFilterPropertyStatus[status]).join(', ');
      }
      return (
        <div>
          {`${fieldName}: `}
          <strong>
            <del>{change?.oldValues}</del>
          </strong>
          {' to '}
          <strong>{`${change?.updatedValues}`}</strong>
        </div>
      );
    }
    if (isNumber(data)) {
      return (
        <div>
          {`${fieldName}: `}
          <strong>
            <del>{change?.oldValues}</del>
          </strong>
          {' to '}
          <strong>{`${change?.updatedValues}`}</strong>
        </div>
      );
    }
    if (isString(data)) {
      return (
        <div>
          {`${fieldName}: `}
          <strong>
            <del>{change?.oldValues}</del>
          </strong>
          {' to '}
          <strong>{`${change?.updatedValues}`}</strong>
        </div>
      );
    }
    return '';
  };

  const getFormattedChangeWithUpdatedValues = (data, fieldName) => {
    if (data instanceof Object && !(data instanceof Array)) {
      if (keys(data).length > 0) {
        return keys(data).map((key, idx) => {
          return (
            <div key={idx}>
              {`${key}: `}
              <strong> {change?.updatedValues[key]}</strong>
            </div>
          );
        });
      }
      return (
        <>
          {`${keys(data)}: `} <strong>{values(data)}</strong>
        </>
      );
    }
    if (data instanceof Object && data instanceof Array) {
      if (data?.length > 0) {
        if (fieldName === 'HomeType') {
          return data?.map((homeType) => SearchCriteriaFilterPropertyHomeType[homeType]).join(', ');
        }
        if (fieldName === 'Locations') {
          return <span>{data[0]?.PlaceName}</span>;
        }
        return data?.map((status) => SearchCriteriaFilterPropertyStatus[status]).join(', ');
      }
      return <>{data}</>;
    }
    if (isNumber(data)) {
      return <strong>{data}</strong>;
    }
    if (isString(data)) {
      return <strong>{data}</strong>;
    }
    return '';
  };
  return (
    <>
      {SearchCriteriaFilterFields.includes(change?.filterName) && (
        <Row>
          <Col xs={24}>
            <StrongTitle title={`${SearchCriteriaFilterTypes[change?.filterName]}:`} />{' '}
            {change?.oldValues
              ? getFormattedChangeWithOldValues(change?.oldValues, change?.filterName)
              : change?.updatedValues &&
                getFormattedChangeWithUpdatedValues(change?.updatedValues, change?.filterName)}
          </Col>
        </Row>
      )}
    </>
  );
};

function getSearchCriteriaFilterValues(data, criteriaType) {
  if (data instanceof Object && !(data instanceof Array)) {
    if (keys(data).length > 0) {
      return keys(data).map((key, idx) => {
        return (
          <span key={idx}>
            {`${key}: `}
            {data?.[key]}
            {idx != keys(data).length - 1 ? `${', '}` : `${''}`}
          </span>
        );
      });
    }
    return (
      <>
        {`${keys(data)}: `} <>{values(data)}</>
      </>
    );
  }
  if (data instanceof Object && data instanceof Array) {
    if (data?.length > 0) {
      if (data[0]?.Type === 'City') {
        return <span>{data[0]?.PlaceName}</span>;
      }
      if (criteriaType === 'Property Type') {
        return data?.map((homeType) => SearchCriteriaFilterPropertyHomeType[homeType]).join(', ');
      }
      if (criteriaType === 'Location') {
        return <span>{data[0]?.PlaceName}</span>;
      }
      return data?.map((status) => SearchCriteriaFilterPropertyStatus[status]).join(', ');
    }
    return <>{data}</>;
  }
  if (isNumber(data)) {
    return <>{data}</>;
  }
  if (isString(data)) {
    return <>{data}</>;
  }
  return '';
}

const getObjectDiff = (obj1 = {}, obj2 = {}, compareRef = false) => {
  return Object.keys(obj1).reduce((result, key) => {
    if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && obj1[key] !== obj2[key]) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
};

function GetTimeString(seconds) {
  // multiply by 1000 because Date() requires miliseconds
  let date = new Date(seconds * 1000);
  let hh = date.getUTCHours() || 0;
  let mm = date.getUTCMinutes() || 0;
  let ss = date.getSeconds() || 0;
  // This formats your string to HH:MM:SS
  let t =
    `${mm >= 1 ? (hh >= 1 ? +hh * 60 + +mm + 'm, ' : mm + 'm, ') : ''}` +
    `${ss >= 1 ? ss + 's' : ''}`;
  return t;
}
const addSpaceBeforeCapitals = (str: string) => {
  return str.replace(/([A-Z])(?=[A-Z][a-z])|(?<=[a-z0-9])([A-Z])/g, '$1 $2').trim();
};

const formatCurrency = (value: string | number, curencySign: string = '$') => {
  return (
    curencySign +
    Number(value).toLocaleString('en-US', {
      currency: 'USD',
      maximumFractionDigits: 0,
    })
  );
};

const renderMilestoneUpdatedHighlight = (
  highlights: { [z: string]: any }[],
  selector: 'oldVal' | 'newVal',
) =>
  highlights.map((item) => {
    let value;
    if (item.key === 'DueDate') {
      let str = item[selector];
      // Keeping backward compatibilty, Previously we were receiving formated date as [MM/DD/YYYY at HH:mm]
      // which caused mismatched date in QA env because of multizone. Now passing timestamptz from BE and formatting on FE.
      value = str.includes('at') ? str.split('at')[0] : moment(str).format('MM/DD/YYYY');
    } else if (item.key === 'DateLastEdited') {
      value = item[selector]
        ? moment(item[selector]).format('MM/DD/YYYY [at] hh:mm A')
        : item[selector];
    } else {
      value = item[selector];
    }
    return (
      <Row key={`${item.key}-${selector}`}>
        <Col xs={40}>
          {addSpaceBeforeCapitals(item?.key)}: <span>{value}</span>
        </Col>
      </Row>
    );
  });

const renderValues = (state: string, highlights, summaryHelperText = '', transactionCount = 0) => {
  if (state.includes('old')) {
    return highlights?.map((item) => {
      if (
        isArray(item?.oldVal) &&
        (item?.oldVal?.length === 0 ||
          item?.oldVal?.every((item) => typeof item === 'object' && item !== null))
      ) {
        return <></>;
      }

      let value = '';
      switch (item?.key) {
        case 'OfferIterationDocument':
          value = renderDocuments(item.oldVal);
          break;
        case 'DaysOfWeek':
          value = item?.oldVal?.join(', ');
          break;
        case 'StartTime':
        case 'EndTime':
          value = moment(item?.newVal).format('LT');
          break;
        default:
          value = item?.oldVal?.toString();
          break;
      }

      return (
        <Row key={`${item?.key}-${state}`}>
          <Col xs={40}>
            {item?.key === 'EarnestMoneyDeposit' || item?.key === 'DownPayment' ? (
              <span>
                {addSpaceBeforeCapitals(item?.key)} : {formatCurrency(value || 0)}
              </span>
            ) : (
              <>
                <StrongListTitle title={addSpaceBeforeCapitals(item?.key)} /> : <span>{value}</span>
              </>
            )}
          </Col>
        </Row>
      );
    });
  } else if (
    state.includes('taskSummary') ||
    state.includes('taskUpcommingSummary') ||
    state.includes('taskOverDueSummary')
  ) {
    const showTags = () => {
      const currentUrl = window.location.pathname;

      // Define the desired route pattern
      const routePattern = /^\/workshop\/transactions\/\d+\/activity$/;

      // Check if the current URL matches the desired route pattern
      return routePattern.test(currentUrl);
    };
    return highlights?.map((item) => {
      return (
        <Row key={`${item?.key}-${state}`} justify="space-between">
          <Col xs={{ span: 16, order: 4 }} sm={{ span: 16, order: 4 }} lg={{ span: 18, order: 3 }}>
            {/* Task <StrongListTitle title={item?.key} /> is {summaryHelperText}.{' '} */}

            {/* <span className={classNames(styles.dot)}></span> */}
            {getLink(item?.key, link.toTransactionTasks(item.transactionId), {
              transactionId: item.transactionId,
              taskId: item.taskId,
            })}
            {showTransactionAddress(item.transactionId) && transactionCount > 1 ? (
              <>
                {' '}
                {getType(item?.transactionDetails)}
                {getLink(
                  getAddressOrProject(item.transactionDetails),
                  link.toTransactionOverview(item.transactionDetails?.Id),
                )}
                .
              </>
            ) : (
              <>.</>
            )}
          </Col>
          {item?.CategoryId && showTags() && (
            <Col
              xs={{ span: 16, order: 4 }}
              sm={{ span: 16, order: 4 }}
              lg={{ span: 5, order: 4 }}
              className={styles.tagColumn}
            >
              <Badge className={styles.tag_without_round}>{item.categoryName}</Badge>
            </Col>
          )}
        </Row>
      );
    });
  } else if (state.includes('mileStoneAddedSummary')) {
    return highlights?.map((item) => {
      const showTransaction = showTransactionAddress(item.transactionId) && transactionCount > 1;
      return (
        <Row key={`${item?.key}-${state}`}>
          <Col xs={40}>
            Milestones:{' '}
            <strong>
              <a
                href={`/workshop/transactions/${item?.transactionId}/overview`}
                rel="noreferrer"
                style={feedbackStyles}
                onMouseEnter={(e) => mouseEnterHandler(e)}
                onMouseLeave={(e) => mouseLeaveHandler(e)}
              >
                {`${addSpaceBeforeCapitals(item?.key)}${!showTransaction ? '.' : ''}`}
              </a>
            </strong>{' '}
            {showTransaction && (
              <>
                {' '}
                {getType(item?.transactionDetails)}{' '}
                {getLink(
                  getAddressOrProject(item.transactionDetails),
                  link.toTransactionOverview(item.transactionDetails?.Id),
                )}
                .
              </>
            )}
          </Col>
        </Row>
      );
    });
  } else {
    return highlights?.map((item) => {
      if (
        isArray(item?.newVal) &&
        (item?.newVal?.length === 0 ||
          item?.newVal?.every((item) => typeof item === 'object' && item !== null))
      ) {
        return <></>;
      }
      let value = '';

      switch (item?.key) {
        case 'OfferIterationDocument':
          value = renderDocuments(item.newVal);
          break;
        case 'DaysOfWeek':
          value = item?.newVal?.join(', ');
          break;
        case 'StartTime':
        case 'EndTime':
          value = moment(item?.newVal).format('LT');
          break;
        default:
          value = item?.newVal?.toString();
          break;
      }

      return (
        <Row key={`${item?.key}-${state}`}>
          <Col xs={40}>
            {item?.key === 'EarnestMoneyDeposit' || item?.key === 'DownPayment' ? (
              <span>
                {addSpaceBeforeCapitals(item?.key)} : {formatCurrency(value || 0)}
              </span>
            ) : (
              <>
                <StrongListTitle title={addSpaceBeforeCapitals(item?.key)} /> : <span>{value}</span>
              </>
            )}
          </Col>
        </Row>
      );
    });
  }
};
const renderDocuments = (documents) => {
  if (documents?.length > 0) {
    return documents.map((item) => {
      return <span className={styles.document}>{item.DocumentName}</span>;
    });
  } else {
    return '-';
  }
};

const formatTransactionStatus = (status) => {
  const statusMap = {
    PreListing: 'Pre-listing',
    OnHold: 'On Hold',
  };

  return statusMap[status] || status?.replace(/([A-Z])/g, ' $1') || status;
};

enum SearchCriteriaFilterPropertyStatus {
  Active = 'Active',
  ActiveUnderContract = 'Active Under Contract',
  Pending = 'Pending',
  Closed = 'Closed',
}
enum SearchCriteriaFilterPropertyHomeType {
  Other = 'Other',
  Single = 'Single',
  Townhouse = 'Townhouse',
  Condo = 'Condo',
  MultiFamily = 'MultiFamily',
  NewConstruction = 'NewConstruction',
  Land = 'Land',
}

const SearchCriteriaFilterFields = [
  'HomeType',
  'LotSizeRange',
  'MaxDaysOnMarket',
  'MaximumHOA',
  'MinParkingSpaces',
  'PriceRange',
  'SquareFeetRange',
  'Status',
  'StoriesRange',
  'YearBuiltRange',
  'NumBathroomsRange',
  'NumBedroomsRange',
  'Locations',
];
