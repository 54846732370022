import { useHistory, useLocation, useParams } from 'react-router-dom';

import { FormProcessDataType } from 'types';
import { DynamicFormManager } from '../DynamicFormManager';
import { useLayoutEffect } from 'react';

export const AuthDynamicForm = () => {
  const { formDocumentPublicId, formProcessPublicId } = useParams<FormProcessDataType>();
  const history = useHistory();
  const location = useLocation();

  useLayoutEffect(() => {
    const windowState = window.history.state?.state;
    if (windowState?.isWebView && !location.state) {
      history.replace(location.pathname, windowState);
      window.location.reload();
    }
  }, []);

  return formDocumentPublicId && formProcessPublicId ? (
    <DynamicFormManager
      authData={{
        formDocumentPublicId,
        formProcessPublicId,
      }}
    />
  ) : (
    <></>
  );
};
