import React, { useMemo, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash-es';
import ImageGallery from 'react-image-gallery';

import { ArrowLeft, ArrowRight, Photos } from 'components/Icons';

import Close from '../components/Close';
import Download from '../components/Download';

import styles from './styles.module.scss';

const renderRightNav = (onClick, disabled) => (
  <button className={styles.rightNav} disabled={disabled} onClick={onClick}>
    <ArrowRight className={styles.navIcon} />
  </button>
);

const renderLeftNav = (onClick, disabled) => (
  <button className={styles.leftNav} disabled={disabled} onClick={onClick}>
    <ArrowLeft className={styles.navIconLeft} />
  </button>
);

const PhotosViewModal = ({ isOpen, className, onClose, photos, imageId }) => {
  const [index, setIndex] = useState(null);

  useEffect(() => {
    if (imageId !== null) {
      setIndex(imageId);
    }
  }, [imageId]);

  const images = useMemo(
    () =>
      map(photos, (photo) => ({
        original: photo?.Url,
        thumbnail: photo?.Url,
        originalClass: styles.imageOriginalClass,
        thumbnailClass: styles.thumbnailClass,
      })),
    [photos],
  );

  const currentPhoto = photos?.[index];

  return createPortal(
    <div
      testid="photos_view_modal"
      className={classNames(styles.modal, { [styles.isOpen]: isOpen }, className)}
    >
      <div className={styles.opacityLayer} />
      {isOpen && (
        <div className={styles.content}>
          <div className={styles.head}>
            <div className={styles.counter}>
              <Photos className={styles.counterIcon} />
              <span testid="count" className={styles.count}>{`${index + 1} / ${
                images?.length
              }`}</span>
            </div>
            <div testid="file_name">{currentPhoto?.Filename}</div>
            <div className={styles.actions}>
              {currentPhoto && (
                <Download url={currentPhoto?.Url} filename={currentPhoto?.Filename} />
              )}
              <Close onClose={onClose} />
            </div>
          </div>
          <div testid="image_gallery" className={styles.innerHolder}>
            <ImageGallery
              startIndex={index}
              onSlide={setIndex}
              items={images}
              showPlayButton={false}
              showFullscreenButton={false}
              renderLeftNav={renderLeftNav}
              renderRightNav={renderRightNav}
            />
          </div>
        </div>
      )}
    </div>,
    document.body,
  );
};

PhotosViewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      Url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  imageId: PropTypes.number,
};

export default PhotosViewModal;
