import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'settings/navigation/routes';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { Popover } from 'components-antd';
import { Icons as Icon } from './Icons';
import { useOutsideClick } from 'hooks';

import styles from './styles.module.scss';
import { reassignShareClientsEffect, setProfileExpandedSection } from 'store/effects';

import { getClientAssignedToList } from 'api/agentTeamDetail';
import { showSuccessMessage } from 'helpers';
import { ReassignShareModal } from 'pages/ClientProfile/SideBar/components/AssignedTo/ReassignShareModal';

export const Options = (props) => {
  const { className, optionDotsClassName, clientId, onChange, setShowModal, clientInfo } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [assignedTo, setAssignedTo] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [openReassign, setOpenReassign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchLising, setFetchListing] = useState(false);

  const buttonRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef<HTMLInputElement>(null);

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  const fetchClientAssignedToList = async () => {
    const stats = await getClientAssignedToList({ id: clientInfo.Id });
    setAssignedTo(stats?.data || []);
    setFetchListing(false);
  };

  useEffect(() => {
    if (openReassign) {
      setFetchListing(true);
      fetchClientAssignedToList();
    }
  }, [openReassign]);

  const onClickOptions = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onViewProfile = (e) => {
    e.stopPropagation();
    history.push(`${routes.clientProfile}/${clientId}`);
    setOpen(false);
    setShowModal(false);
    dispatch(setProfileExpandedSection(false));
  };

  const openReassignModal = (e) => {
    e.stopPropagation();
    setOpen(false);
    setOpenReassign((prev) => !prev);
  };

  const onReassign = (agentIds) => {
    setLoading(true);

    dispatch(
      reassignShareClientsEffect(
        {
          clientIds: [clientInfo.Id],
          agentIds,
        },
        { type: 'reassign' },
        (err) => {
          if (!err) {
            showSuccessMessage(`Client(s) reassign successful.`);
            setOpenReassign(false);
          }

          setLoading(false);
        },
      ),
    );
  };

  return (
    <div className={classNames(styles.optionsWrapper, className)}>
      <div
        testid="options"
        ref={buttonRef}
        onClick={onClickOptions}
        className={classNames(styles.optionsDots, optionDotsClassName)}
      >
        <Icon variant={Icon.DOTS} />
      </div>

      <Popover
        open={open}
        overlayClassName={styles.teamClientOptionsPopover}
        content={
          <div ref={optionsRef} className={styles.options}>
            <ul>
              <li testid="view_profile_action" className={styles.item} onClick={onViewProfile}>
                <div className={styles.icon}>
                  <Icon variant={Icon.PROFILE} />
                </div>
                <span>View Profile</span>
              </li>

              <li testid="reassign_action" className={styles.item} onClick={openReassignModal}>
                <div className={styles.icon}>
                  <Icon variant={Icon.REASSIGN} />
                </div>
                <span>Reassign</span>
              </li>
            </ul>
          </div>
        }
      ></Popover>

      <ReassignShareModal
        isOpen={openReassign}
        modalType={'Reassign'}
        onReassignShare={onReassign}
        onClose={() => setOpenReassign(false)}
        clients={[
          {
            Id: clientId,
            FirstName: clientInfo.FirstName,
            LastName: clientInfo.LastName,
            AvatarUrl: clientInfo.AvatarUrl,
            AssignedAgentIds: assignedTo.map((item) => item.Id),
          },
        ]}
        loading={loading}
        loadingAssignees={fetchLising}
      />
    </div>
  );
};

Options.propTypes = {
  className: PropTypes.string,
  optionDotsClassName: PropTypes.string,
  clientId: PropTypes.number,
  onChange: PropTypes.func,
  setShowModal: PropTypes.func,
  clientInfo: PropTypes.any,
};

Options.defaultProps = {
  className: '',
  optionDotsClassName: '',
  clientId: null,
  onChange: () => {},
  setShowModal: () => {},
  clientInfo: {},
};
