import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import {
  setTransactionFiltersAppliedEffect,
  setTransactionsFilterEffect,
} from 'store/effects/transactions/all';
import { getTransactionsIsArchiveSelector } from 'store/selectors/transactions';
import { TEAM_OWNER, TEAM_ADMIN, transactionsFilters } from 'settings/constants/roles';
import { LocationService } from 'services';

import styles from './styles.module.scss';

export const TransactionsFilter = (props) => {
  const { agentRole } = props ?? {};
  const [isFiltersApplied, setIsFilterApplied] = useState(false);
  const [value, setValue] = useState(transactionsFilters.allTransactions);
  const dispatch = useDispatch();
  const isArchive = useSelector(getTransactionsIsArchiveSelector);
  const [firstRender, setFirstRender] = useState(true);
  const locationSrv = new LocationService();
  const location = useLocation();
  locationSrv.setLocation(location);

  useEffect(() => {
    if (!firstRender) {
      setIsFilterApplied(false);
      setValue(transactionsFilters.allTransactions);
    }
  }, [isArchive]);

  useEffect(() => {
    let filterStatus;
    const query = locationSrv.getQuery();
    if ('teamStats' in query) {
      filterStatus = query.teamStats
        ? transactionsFilters.allTransactions
        : transactionsFilters.myTransactions;
    } else {
      filterStatus = transactionsFilters.allTransactions;
    }
    // Reset Filters whenever archive button is clicked.

    setValue(filterStatus);
    dispatch(setTransactionsFilterEffect(filterStatus));
    setFirstRender(false);
  }, []);

  const onChange = (e) => {
    const val = e.target.value;
    const isFiltersApplied = val !== transactionsFilters.allTransactions;

    setIsFilterApplied(isFiltersApplied);
    dispatch(setTransactionsFilterEffect(val));
    setValue(val);
  };

  // Hide Team Transactions option for Team members.
  const teamAdminAndOwnerOption = [TEAM_OWNER, TEAM_ADMIN].includes(agentRole)
    ? [transactionsFilters.teamTransactions]
    : [];

  const dropdownFields = [
    transactionsFilters.myTransactions,
    ...teamAdminAndOwnerOption,
    transactionsFilters.externalTransactions,
    transactionsFilters.allTransactions,
  ];

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => (
          <MenuItem key={idx}>
            <Radio value={item} key={idx}>
              <div className={styles.option}>
                <span className={styles.optionValue}>{item}</span>
              </div>
              {value === item && <Check className={styles.checkIcon} />}
            </Radio>
          </MenuItem>
        ))}
      </Menu>
    </Radio.Group>
  );

  return (
    <div
      className={classNames(
        styles.filterWrap,
        {
          [styles.filtersAppliedWrapper]: isFiltersApplied,
        },
        props.className,
      )}
    >
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.selectedValue}>{value}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} />
        </a>
      </Dropdown>
    </div>
  );
};
