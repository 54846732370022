import { useSelector, useDispatch } from 'react-redux';

import { Modal } from 'components';
import Form from './Form';
import { appOpenModalEffect } from 'store/effects';
import styles from './styles.module.scss';
import { LocationService } from 'services';
import { getModalSelector } from 'store/selectors/app';
import { setFilterAction } from 'store/actions/feedv3';
import { getFeedCriteriaSelectorV3 } from 'store/selectors/feedv3';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { omitBy } from 'lodash-es';

const locationSrv = new LocationService();

const FilterModal = () => {
  locationSrv.setLocation(location);
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();
  const { filter } = useSelector(getFeedCriteriaSelectorV3);

  const onSubmit = (values) => {
    const cleanedCriteria = omitBy(
      cleanSearchQueryObj(values),
      (value) => value === null || value === undefined,
    );
    dispatch(setFilterAction({ filter: cleanedCriteria }));
    onClose();
    const ref = document.getElementById('searchProperties');
    if (ref) {
      document.getElementById('searchProperties').scrollTop = 0;
    }
  };

  const onReset = () => {
    const ref = document.getElementById('searchProperties');
    if (ref) {
      document.getElementById('searchProperties').scrollTop = 0;
    }
  };

  const onClose = () => {
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  if (modal.id !== FilterModal.id) {
    return null;
  }

  if (modal?.id !== FilterModal?.id) return null;

  return (
    <Modal
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      innerHolderClassName={styles.innerHolder}
      label="Filter Results"
    >
      <div className={styles.drawerInner}>
        <Form onSubmit={onSubmit} search={filter} onReset={onReset} />
      </div>
    </Modal>
  );
};

export default FilterModal;

FilterModal.id = 'filterModal';
