import { FC } from 'react';
import { RadarHeader } from '../components/RadarHeader';
import { Table } from '../components';
import styles from './styles.module.scss';

export const RadarSignals: FC = () => {
  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: () => <></>,
    },
    {
      title: 'Agent',
      dataIndex: 'agent',
      key: 'agent',
      render: () => <></>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: () => <></>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: () => <></>,
    },
  ];

  return (
    <div className={styles.pageWrapper}>
      <RadarHeader title="Signals" />
      <div className={styles.pageContent}>
        <Table
          data={[]}
          columns={columns}
          disableHeader={true}
          emptyText="No new signals identified (yet)."
        />
      </div>
    </div>
  );
};
