import { Avatar } from 'components';
import { getInitials } from 'utils';
import { DocumentIcons } from 'components/ClientsDrawer/ProfileClient/Icons';
import { formatDate } from 'helpers';

import styles from './styles.module.scss';

export const AvatarColumn = ({ name, imgSrc }) => {
  return (
    <div className={styles.avatarTableColumn}>
      <Avatar avatarClassName={styles.avatar} src={imgSrc} placeholder={getInitials(name)} />
      <span>{name || '-'}</span>
    </div>
  );
};

export const DocumentNameColumn = ({ name, docIconType }) => {
  const nameColumnProps = {
    ...(name && name.length >= 30 && { title: name }),
  };

  return (
    <div className={styles.documentTableColumn}>
      <DocumentIcons type={docIconType} />
      <span className={styles.name} {...nameColumnProps}>
        {name || '-'}
      </span>
    </div>
  );
};

export const DateTimeColumn = ({ date, hideTime = false }) => {
  return (
    <div className={styles.dateTimeColumn}>
      <p>{date ? formatDate(date, 'M/D/YYYY') : '-'}</p>
      {!hideTime && <p className={styles.time}>{date ? formatDate(date, 'hh:mm A') : ''}</p>}
    </div>
  );
};
