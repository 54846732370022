import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { useHistory, useLocation } from 'react-router-dom';

import { Collapse, Panel } from 'components-antd';
import { ArrowDown, ArrowRight } from 'components/Icons';
import { DocumentIcons } from './Icons';
import DocumentsModal from 'components/Transactions/UploadDocuments/DocumentsModal';

import { CategoryIds, New } from './components/New';
import {
  AgentDocumentSearchCriteria,
  AgentDocumentType,
  AgentDocumentTypes,
  DocumentMetaKey,
  DocumentOptionUtils,
  DocumentVaultResponseType,
} from 'types';
import {
  getAgentDocumentsEffect,
  getAgentTeamDocumentsEffect,
  getVaultDocumentsMetaEffect,
  updateDocumentVaultEffect,
} from 'store/effects';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import Spinner from 'components/Spinner';
import {
  editTransactionDocumentClientProfileEffect,
  previewDocumentEffect,
  requestGetTransactionsEffect,
} from 'store/effects/transactions';
import { PdfViewModal } from 'components';
import { FormDetails } from 'pages/Workshop/Forms/components/FormDetails';
import { handleViewForm } from 'utils';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';

import styles from './styles.module.scss';
import { DeleteDocument } from './components/DocumentSections/DeleteDocument';
import { getUserId } from 'store/selectors/user';
import { useDeleteDocuments } from './hooks/useDeleteDocument';
import { FormsTableStatus } from 'app-constants';
import { DocumentsModal as DocumentsDetailsModal } from './components/DocumentsModal';
import { CategoryOptions } from 'components/Transactions/UploadDocuments/Uploaded/ItemClientProfile';

export const Documents = ({ userId, contactName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [openDetailsModal, setDetailsModal] = useState(false);
  const [detailsDocuments, setDetailsDocuments] = useState({
    title: '',
    data: [],
    count: 0,
  });

  const details = useSelector(getAgentDetailsSelector);
  const agentId = details?.data?.Id;
  const loggedInUserId = useSelector(getUserId);

  const [openDeleteDocumentModal, setOpenDeleteDocumentModal] = useState(false);
  const [documentToBeDeletedId, setDocumentToBeDeletedId] = useState<number>();

  const [documentDetails, setDocumentDetails] = useState<AgentDocumentType>();

  const [documentType, setDocumentType] = useState('');
  const [deleteDocumentLoading, setDeleteDocumentLoading] = useState(false);
  const [documents, setDocuments] = useState<any>({});
  const [searchCriteria, setSearchCriteria] = useState<AgentDocumentSearchCriteria>({});
  const [isLoading, setIsLoading] = useState(false);

  const [attachment, setAttachment] = useState<DocumentVaultResponseType>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [modalUpload, setModalUpload] = useState({ open: false });
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [updateDocumentLoading, setUpdateDocumentLoading] = useState(false);

  const [selectedFormProcess, setSelectedFormProcess] = useState<number>();

  const { deleteProfileDocument } = useDeleteDocuments();

  useEffect(() => {
    dispatch(
      requestGetTransactionsEffect(
        {
          'participantIds[]': userId,
        },
        { silent: true },
      ),
    );
  }, []);

  const fetchAgentDocuments = (updatedSearch?: AgentDocumentSearchCriteria) => {
    setIsLoading(true);
    const search = { ...(updatedSearch ?? searchCriteria) };
    if (search.name) {
      search.name = search.name.trim();
    }
    dispatch(
      getAgentDocumentsEffect({ agentId, searchCriteria: search }, (err, resp) => {
        setIsLoading(false);

        if (!err) {
          setDocuments(resp.data);
        } else {
          setDocuments({});
        }
      }),
    );
  };

  const fetchClientDocuments = (updatedSearch?: AgentDocumentSearchCriteria) => {
    setIsLoading(true);
    const search = { ...(updatedSearch ?? searchCriteria) };
    if (search.name) {
      search.name = search.name.trim();
    }
    dispatch(
      getAgentTeamDocumentsEffect({ id: userId, searchCriteria: search }, {}, (err, resp) => {
        setIsLoading(false);
        if (!err) {
          const {
            Transactions,
            ['Electronic Signatures']: ElectronicSignatures,
            Uploads,
            ...otherData
          } = resp.data;
          setDocuments({
            ...otherData,
            ...(ElectronicSignatures?.length || Uploads?.length
              ? {
                  Miscellaneous: {
                    ElectronicSignatures,
                    Uploads,
                  },
                }
              : {}),
            ...(Transactions ? { ...Transactions } : {}),
          });

          if (openDetailsModal) {
            setDetailsDocuments({
              ...detailsDocuments,
              data: resp?.data?.[detailsDocuments?.title],
              count: resp?.data?.[detailsDocuments?.title]?.length,
            });
          }
        } else {
          setDocuments({});
        }
      }),
    );
  };

  const fetchDocumentsMeta = () => {
    setIsLoading(true);
    dispatch(
      getVaultDocumentsMetaEffect({
        id: loggedInUserId,
        clientId: userId,
      }),
    );
  };

  const fetchDocuments = () => {
    if (agentId) {
      fetchAgentDocuments();
    } else {
      fetchClientDocuments();
      fetchDocumentsMeta();
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [agentId]);

  const handleSearchCriteria = (updatedSearch?: AgentDocumentSearchCriteria) => {
    if (updatedSearch) {
      setSearchCriteria(updatedSearch);
    }
    fetchAgentDocuments(updatedSearch);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setAttachment(undefined);
  };

  const onDownload = (documentVaultUUID) => {
    if (documentVaultUUID) {
      dispatch(
        previewDocumentEffect({ DocumentVaultUUID: documentVaultUUID }, {}, (err, response) => {
          if (err) {
            return;
          }
          fileDownload(response.data, response.headers['file-name']);
        }),
      );
    }
  };

  const onPreview = (documentVaultUUID, fileName) => {
    if (documentVaultUUID) {
      setPreviewOpen(true);
      dispatch(
        previewDocumentEffect({ DocumentVaultUUID: documentVaultUUID }, {}, (err, response) => {
          if (!err) {
            setAttachment({
              DocumentBuffer: response,
              Filename: fileName,
            });
          }
        }),
      );
    }
  };

  const handleOpenDeleteDocumentModal = (id, type, document) => {
    setDocumentToBeDeletedId(id);
    setDocumentType(type);
    setOpenDeleteDocumentModal(true);
    document && setDocumentDetails(document);
  };

  const handleOpenEditDocumentModal = (document, type) => {
    setDocumentDetails(document);
    const fileType = {
      [AgentDocumentTypes.Transactions]: CategoryOptions.TRANSACTION,
      [AgentDocumentTypes.Uploads]: CategoryOptions.MISC,
      [AgentDocumentTypes.PreApprovals]: CategoryOptions.PREAPPROVALS,
    };

    const fileMetaType = {
      [AgentDocumentTypes.Transactions]: DocumentMetaKey.Transactions,
      [AgentDocumentTypes.Uploads]: DocumentMetaKey.Uploads,
      [AgentDocumentTypes.PreApprovals]: DocumentMetaKey['Pre-Approvals'],
    };

    const docDetails = document?.[fileMetaType?.[type]];

    setUploadedFiles([
      {
        ...(type == AgentDocumentTypes.Transactions
          ? { TransactionOrFolder: document?.transactionMeta?.transactionId }
          : {}),
        type: fileType?.[type],
        contentType: 'application/pdf',
        data: '',
        documentName: document?.title,
        filename: docDetails?.fileName,
        size: Number(docDetails?.originalContentLength || 0),
        ...(type === AgentDocumentTypes.Transactions
          ? {
              permittedUsers_: docDetails?.accessToEveryone
                ? []
                : [
                    '!',
                    ...(docDetails && docDetails.permissions
                      ? docDetails.permissions.map((item) => item?.Id)
                      : []),
                  ],
            }
          : {
              shareWithClient: docDetails?.sharedWithClient,
            }),
      },
    ]);

    setModalUpload({
      open: true,
    });
  };

  const handleCloseDeleteDocumentModal = () => {
    setDocumentToBeDeletedId(undefined);
    setOpenDeleteDocumentModal(false);
    setDocumentDetails(undefined);
    setDocumentType('');
  };

  const deleteDocument = () => {
    setDeleteDocumentLoading(true);

    deleteProfileDocument(documentDetails, documentType, () => {
      setDeleteDocumentLoading(false);
      setOpenDeleteDocumentModal(false);
      setDetailsModal(false);
      fetchDocuments();
    });
  };

  const openFormDetails = (formProcessId: number, type?: string) => {
    if (formProcessId) {
      setSelectedFormProcess(formProcessId);
      type && setDocumentType(type);
    }
  };

  const onCloseFormDetailModal = () => {
    setSelectedFormProcess(undefined);
    setDocumentType('');
  };

  const handleShareDocument = (documentVaultUUID) => {
    if (documentVaultUUID) {
      dispatch(openMessagesDrawerAction(true));
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
          params: {
            threadId: null,
            documentVaultUUID,
          },
        }),
      );
    }
  };

  const handleOpenThread = (documentVaultUUID, threadId) => {
    if (documentVaultUUID) {
      dispatch(openMessagesDrawerAction(true));
      dispatch(
        changeMessagesDrawerTypeAction({
          type: DRAWER_MESSAGES_TYPES.CHAT,
          params: {
            threadId,
            documentVaultUUID,
          },
        }),
      );
    }
  };

  const handleEditTransactionFile = (files) => {
    const { TransactionOrFolder, permittedUsers } = files?.[0] || {};
    const document = files?.[0] || {};

    const transactionDocumentsPayload = {
      transactionId: TransactionOrFolder,
      documentId: documentDetails?.transactionMeta?.documentId,
      AccessToEveryone: permittedUsers?.includes('*'),
      Title: document?.documentName,
      UsersWithAccess: permittedUsers?.filter((item) => item !== '*'),
      File: {
        ...(document?.data
          ? { ContentType: document?.contentType }
          : { MimeType: document?.contentType }),
        Filename: document?.filename,
        Size: document?.size,
        ...(document?.data !== ''
          ? { Data: btoa(document?.data) }
          : { DocumentVaultUUID: documentDetails?.documentVaultUUID }),
      },
    };
    setUpdateDocumentLoading(true);

    dispatch(
      editTransactionDocumentClientProfileEffect(transactionDocumentsPayload, {}, (err) => {
        if (!err) {
          setDetailsModal(false);
          fetchDocuments();
        }
        setModalUpload({
          open: false,
        });
        setUpdateDocumentLoading(false);
      }),
    );
  };

  const handleEditVaultFile = (files) => {
    setUpdateDocumentLoading(true);

    const document = files?.[0] || {};
    const payload = {
      id: documentDetails?.documentVaultUUID,
      Title: document?.documentName,
      CategoryId:
        document?.type === CategoryOptions.MISC ? CategoryIds.MISC : CategoryIds?.PREAPPROVALS,
      ...(document?.data === ''
        ? { DocumentVaultUUID: documentDetails?.documentVaultUUID }
        : {
            File: {
              ContentType: document?.contentType,
              Filename: document?.filename,
              Size: document?.size,
              Data: btoa(document?.data),
            },
          }),
      ShareWithClient: document?.shareWithClient,
    };

    dispatch(
      updateDocumentVaultEffect(payload, {}, (err) => {
        if (!err) {
          setDetailsModal(false);
          setModalUpload({
            open: false,
          });
          fetchDocuments();
        }

        setUpdateDocumentLoading(false);
      }),
    );
  };

  const optionUtils: DocumentOptionUtils['optionUtils'] = {
    previewDocument: onPreview,
    downloadDocument: onDownload,
    openFormDetailsModal: openFormDetails,
    handleOpenDeleteDocumentModal,
    handleOpenEditDocumentModal,
    shareDocument: handleShareDocument,
    openMessageThread: handleOpenThread,
  };

  const totalDocuments = Object.values(documents).reduce((total: number, items) => {
    if (Array.isArray(items)) {
      return total + items.length;
    }

    if (typeof items === 'object' && items !== null) {
      const miscTotal = Object.values(items).reduce((subTotal, subItems) => {
        return subTotal + (Array.isArray(subItems) ? subItems.length : 0);
      }, 0);
      return total + miscTotal;
    }

    return total;
  }, 0);

  return (
    <div className={styles.documents}>
      <div className={styles.content}>
        <PdfViewModal
          isOpen={previewOpen}
          file={attachment}
          onClose={handlePreviewClose}
          showInCohesiveFlow
        />

        <DeleteDocument
          isMoldaOpen={openDeleteDocumentModal}
          onCancel={handleCloseDeleteDocumentModal}
          deleteDocument={deleteDocument}
          deleteDocumentLoading={deleteDocumentLoading}
        />

        {selectedFormProcess && (
          <FormDetails
            agentId={agentId}
            selectedFormProcess={selectedFormProcess}
            selectedFormStatus={
              documentType === AgentDocumentTypes.Drafts ? FormsTableStatus.Draft : documentType
            }
            onClose={onCloseFormDetailModal}
            onHandleViewForm={(url, type) =>
              handleViewForm(history, url, type, { redirectionLink: location.pathname })
            }
            refetchForms={fetchDocuments}
            onDeleteFormSuccess={onCloseFormDetailModal}
            clientProfileId={+userId}
          />
        )}

        <div className={styles.header}>
          <p className={styles.heading}>
            Files <span>({totalDocuments})</span>
          </p>
          <New
            clientId={userId}
            clientName={contactName}
            fetchClientDocuments={fetchClientDocuments}
          />
        </div>

        <div className={styles.documentsTypes}>
          {isLoading && documents ? (
            <Spinner />
          ) : Object.keys(documents).length ? (
            <div className={styles.documentsContainer}>
              {Object.keys(documents).map((item: AgentDocumentTypes | string, idx) => {
                const isLastItem = Object.keys(documents).length - 1 === idx;
                const count =
                  item !== AgentDocumentTypes.Misc
                    ? documents[item]?.length
                    : (documents[item]?.ElectronicSignatures?.length || 0) +
                      (documents[item]?.Uploads?.length || 0);
                const isNotTransactionType = Object.values(AgentDocumentTypes).includes(
                  item as AgentDocumentTypes,
                );
                const title = isNotTransactionType
                  ? item
                  : documents?.[item]?.[0]?.transactionMeta?.address;

                return (
                  <Fragment key={idx}>
                    <div
                      className={styles.documentTypeHeader}
                      onClick={() => {
                        setDetailsDocuments({
                          title: title,
                          data: documents?.[item],
                          count,
                        });
                        setDetailsModal(true);
                      }}
                    >
                      <div className={styles.leftSide}>
                        {isNotTransactionType ? (
                          <DocumentIcons type={item} />
                        ) : (
                          <DocumentIcons type={AgentDocumentTypes.Transactions} />
                        )}
                        <h3>
                          {title} <span>({count})</span>
                        </h3>
                      </div>
                      <ArrowRight className={styles.arrowRight} />
                    </div>
                    {!isLastItem && <div className={styles.separator} />}
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      </div>
      {/* Details Modal */}
      {openDetailsModal && (
        <DocumentsDetailsModal
          onClose={() => setDetailsModal(false)}
          details={detailsDocuments}
          optionUtils={optionUtils}
        />
      )}

      {/* Edit Document Modal */}
      <DocumentsModal
        modal={modalUpload}
        multiple={false}
        files={uploadedFiles}
        isPending={updateDocumentLoading}
        onSave={documentDetails?.transactionMeta ? handleEditTransactionFile : handleEditVaultFile}
        onCloseModal={() => setModalUpload({ open: false })}
        isClientProfile
        isEdit
        dropzone
        filesRequired
        fileNameRequired
        addMore={false}
      />
    </div>
  );
};

const NoData = () => <div className={styles.noData}>Documents not found</div>;
