import { SVGProps } from 'react';

export const ExportArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M13 3.98284V8C6.87072 8 3.409 11.5567 3.03406 18.251C3.0123 18.6395 3.51327 18.8103 3.76359 18.5124C6.11306 15.7158 8.94861 14.5 13 14.5V19.0172C13 19.1953 13.2154 19.2846 13.3414 19.1586L20.7172 11.7828C20.8734 11.6266 20.8734 11.3734 20.7172 11.2172L13.3414 3.84142C13.2154 3.71543 13 3.80466 13 3.98284Z"
        stroke="#515151"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
