import { createAction } from 'redux-actions';

export const requestGetAllTransactionsAction = createAction('REQUEST/GET_ALL_TRANSACTIONS');
export const setTransactionsArchiveAction = createAction('SET_TRANSACTIONS_ARCHIVE');
export const setTransactionsFilterAction = createAction('SET_TRANSACTIONS_FILTER');
export const setTransactionsFiltersAction = createAction('SET_TRANSACTIONS_FILTERS');
export const setProjectsFilterAction = createAction('SET_PROJECTS_FILTER');
export const setTransactionsSearchAction = createAction('SET_TRANSACTIONS_SEARCH');
export const requestGetAllTransactionContactsAction = createAction(
  'REQUEST/GET_ALL_TRANSACTION_CONTACTS',
);
export const transactionsResetAction = createAction('RESET_TRANSACTIONS');
export const setTeamTransactionsAction = createAction('SET_TEAM_TRANSACTIONS');
export const setTransactionFiltersAppliedAction = createAction('SET_TRANSACTIONS_FILTER_APPLIED');
export const requestGetAllProjectsAction = createAction('REQUEST/GET_ALL_PROJECTS');
