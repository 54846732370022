import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDataSelector } from 'store/selectors/user';
import { Popover } from 'components-antd';
import { Wrapper } from 'components';
import {
  getNeighborhoodActivityDataSelector,
  getRemindersByIdSelector,
  getValuationDataSelector,
} from 'store/selectors/intel';
import {
  requestGetAddressNeighborhoodActivityEffect,
  requestGetAddressValuationDetailsEffect,
  requestGetRemindersClientEffect,
} from 'store/effects/intel';
import { Categories, FormValues } from './components/NeighborhoodTab/components/Filter/types';
import { tabRoutes, TabRoutesLinks } from './constants';
import { NeighborhoodTab, PropertyTab } from './components';
import { PageHeader } from '../../components';
import { Icons, IconVariant } from './components/icons';
import { map } from 'lodash-es';

export const getAddressFormattedCassAddress = (
  cassAddress: any,
): { address1: string; address2: string } => {
  // Extract values from the components of the Cass address
  const streetNumber = cassAddress.components.primary_number;
  const streetName = cassAddress.components.street_name;
  const streetPostDirection = cassAddress.components.street_postdirection
    ? ` ${cassAddress.components.street_postdirection}`
    : '';
  const streetSuffix = cassAddress.components.street_suffix
    ? ` ${cassAddress.components.street_suffix}`
    : '';
  const city = cassAddress.components.city_name;
  const state = cassAddress.components.state_abbreviation;
  const { zipcode } = cassAddress.components;

  // Construct address1 (StreetNumber + StreetName + optional StreetPostDirection + optional StreetSuffix)
  const address1 = `${streetNumber} ${streetName}${streetPostDirection}${streetSuffix}`.trim();

  // Construct address2 (City, State Zipcode)
  const address2 = `${city}, ${state} ${zipcode}`;

  // Return the formatted addresses
  return { address1, address2 };
};

interface IAddressDropdown {
  address1: string;
  address2: string;
  search: string;
  setAddress1: any;
  setAddress2: any;
  setSearch: any;
}

const AddressDropdown: FC<IAddressDropdown> = ({
  address1,
  address2,
  setAddress1,
  setAddress2,
  search,
  setSearch,
}) => {
  const user = useSelector(getUserDataSelector);
  const [visible, setVisible] = useState(false);
  const addresses = user?.Client?.Address;
  const filteredAddresses = search?.length
    ? addresses.filter(({ address }) => {
        const { address1, address2 } = getAddressFormattedCassAddress(address);
        return (address1 + address2).includes(search);
      })
    : addresses;
  return (
    <div className={styles.dropdownWrapper}>
      <Popover
        open={visible}
        onOpenChange={setVisible}
        trigger="click"
        placement="bottom"
        content={
          <div className={styles.content}>
            <div className={styles.search}>
              <span>
                <Icons variant={IconVariant.GEO_TAG} />
              </span>
              <div className={styles.input}>
                <input
                  placeholder="Enter an address, neighborhood, city, or zip code..."
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              <div className={styles.searchButton}>
                <Icons variant={IconVariant.SEARCH} />
              </div>
            </div>
            <div className={styles.divider} />
            <p className={styles.title}>Properties</p>
            <div>
              {map(filteredAddresses, ({ address }) => {
                const { address1: address1Line, address2: address2Line } =
                  getAddressFormattedCassAddress(address);
                const isSelected = address1Line === address1 && address2Line === address2;
                return (
                  <div
                    className={styles.item}
                    onClick={() => {
                      setAddress1(address1Line);
                      setAddress2(address2Line);
                      setVisible(false);
                    }}
                  >
                    <span>
                      <Icons variant={IconVariant.GEO_TAG_GREY} />
                    </span>
                    <div className={styles.addressText}>
                      <p className={styles.line1}>{address1Line}</p>
                      <p className={styles.line2}>{address2Line}</p>
                    </div>
                    {isSelected && (
                      <span>
                        <Icons variant={IconVariant.TICK} />
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        }
      >
        <div className={styles.address}>
          {address1} <Icons variant={IconVariant.ARROW_DOWN} />
        </div>
      </Popover>
    </div>
  );
};

export const IntelClient: FC = () => {
  const dispatch = useDispatch();
  const valuationData = useSelector(getValuationDataSelector);
  const remindersData = useSelector(getRemindersByIdSelector);
  const neighborhoodActivityData = useSelector(getNeighborhoodActivityDataSelector);
  const user = useSelector(getUserDataSelector);
  const addresses = user?.Client?.Address;
  const { address1: initialAddress1, address2: initialAddress2 } = getAddressFormattedCassAddress(
    addresses?.[0]?.address,
  );
  const [activeTab, setActiveTab] = useState<string>(TabRoutesLinks[0].value);
  const [address1, setAddress1] = useState<string>(initialAddress1);
  const [address2, setAddress2] = useState<string>(initialAddress2);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    dispatch(
      requestGetAddressValuationDetailsEffect({
        address1,
        address2,
        clientId: user?.Client?.Id,
      }),
    );

    dispatch(
      requestGetRemindersClientEffect({
        Address1: address1,
        Address2: address2,
        UserId: user?.Client?.Id,
      }),
    );

    dispatch(
      requestGetAddressNeighborhoodActivityEffect({
        address: {
          long: user?.Client?.Address?.[0]?.address?.metadata?.longitude,
          lat: user?.Client?.Address?.[0]?.address?.metadata?.latitude,
          radius: user?.Client?.Address?.[0]?.neighbourhoodRadius ?? 10,
        },
      }),
    );
  }, [address1, address2]);

  const handleFiltersChange = useCallback(
    (values: FormValues) => {
      const { categories } = values;

      let category: string[] | undefined = undefined;
      let activityStatus: string[] | undefined;

      if (categories[Categories.NEW_LISTING]) {
        category = [...(category ?? []), 'New'];
      } else if (categories[Categories.PRICE_REDUCTION]) {
        category = [...(category ?? []), 'Price Reductions'];
      } else if (categories[Categories.PRICE_INCREASE]) {
        category = [...(category ?? []), 'Price Increase'];
      } else if (categories[Categories.BACK_ON_THE_MARKET]) {
        category = [...(category ?? []), 'Back on Market'];
      } else if (
        categories[Categories.WITHDRAWN] ||
        categories[Categories.UNDER_CONTRACT] ||
        categories[Categories.EXPIRED] ||
        categories[Categories.SOLD]
      ) {
        category = [...(category ?? []), 'Status Changes'];
        activityStatus = [
          categories[Categories.WITHDRAWN] && 'Withdrawn',
          categories[Categories.UNDER_CONTRACT] && 'ActiveUnderContract',
          categories[Categories.EXPIRED] && 'Expired',
          categories[Categories.SOLD] && 'Sold',
        ].filter(Boolean) as string[];
      } else if (categories[Categories.AGENT_UPDATES]) {
        category = [...(category ?? []), 'Agent Updates'];
      }

      dispatch(
        requestGetAddressNeighborhoodActivityEffect(
          {
            category,
            activityStatus,
            address: {
              long: user?.Client?.Address?.[0]?.address?.metadata?.longitude,
              lat: user?.Client?.Address?.[0]?.address?.metadata?.latitude,
              radius: user?.Client?.Address?.[0]?.neighbourhoodRadius ?? 10,
            },
          },
          {
            silent: neighborhoodActivityData !== null,
          },
        ),
      );
    },
    [user, dispatch, neighborhoodActivityData],
  );

  const propertyTabParams = useMemo(
    () => ({
      address1,
      address2,
      clientId: user?.Client?.Id,
    }),
    [address1, address2, user],
  );

  const renderActiveTab = useMemo(() => {
    switch (activeTab) {
      case tabRoutes.property:
        return (
          <PropertyTab
            params={propertyTabParams}
            states={{
              Address1: address1,
              Address2: address2,
              UserId: user?.Client?.Id,
            }}
          />
        );
      default:
      case tabRoutes.neighborhood:
        return <NeighborhoodTab onFilterChange={handleFiltersChange} />;
    }
  }, [activeTab]);

  return (
    <div className={styles.pageWrapper}>
      <PageHeader links={TabRoutesLinks} activeKey={activeTab} setActiveTab={setActiveTab}>
        <AddressDropdown
          address1={address1}
          address2={address2}
          setAddress1={setAddress1}
          setAddress2={setAddress2}
          search={search}
          setSearch={setSearch}
        />
      </PageHeader>
      <Wrapper isPending={valuationData.isPending || remindersData.isPending}>
        {renderActiveTab}
      </Wrapper>
    </div>
  );
};
