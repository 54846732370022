import { useState, useEffect, ReactNode, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getTransactionTaskParamsSelector } from 'store/selectors/transactionTask';
import { TagInput } from 'components/Transactions';
import { TaskModalHeader } from './TaskModalHeader';
import { Transaction } from './Transaction';
import { TaskName } from './TaskName';
import { AssignedTo } from './AssignedTo';
import { CcTo } from './CcTo';
import { DueDate } from './DueDate';
import { Description } from './Description';
import { Links } from './Links';
import { Checklists } from './Checklists';
import { Files } from './Files';
import { Permissions } from './Permissions';
import { Options } from './Options';
import { Footer } from './Footer';
import { TaskEditableFormValuesType } from 'types/transactionTasks';
import { ValueObject } from 'components/Transactions/ClientInput/ClientInputView';
import { getUserSelector } from 'store/selectors/user';

import styles from './styles.module.scss';
import { MultiSelect, Option } from 'components-antd';
import useIsTranOrProFilterVisible from 'hooks/use-is-tran-pro-filter-visible';
import { ArrowDown } from 'components/Icons';
import { Button } from 'antd';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import { useOutsideClick } from 'hooks';
import { MoreOptionItem } from './MoreOptionItem/MoreOptionItem';
import { getTransactionRolesEffect, requestGetTeamListEffect } from 'store/effects';
import { AssignedToMilestone } from './AssignedToMilestone';
import { getMilestoneDataSelector } from 'store/selectors/milestoneAggregate';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getMilestoneById } from 'helpers';
import { getCampaignTaskMembersEffect } from 'store/effects/radarKits';

export const TaskModalForm = (props) => {
  const {
    isNew,
    showSubheading,
    isTemplate,
    editData,
    templateId,
    isAssignTask,
    assignTask,
    onCancelModal,
    isTransactionTask,
    onUpdate,
    className,
    onRemove,
    taskLength,
    isAggregatedTasks,
    isViewMode,
    isFormTask,
    isCampaignTask,
    campaignId,
    onSaveTaskAsTemplate,
    isTranactionRoom,
  } = props;

  const { formValues } = useSelector(getTransactionTaskParamsSelector);
  const { data } = useSelector(getUserSelector);
  const [updatedFormValues, setUpdatedFormValues] = useState(formValues);
  const [isProFilterVisible, isTranFilterVisible] = useIsTranOrProFilterVisible();
  const [addDescription, setAddDescription] = useState(false);
  const [showCC, setShowCC] = useState(false);
  const [open, setOpen] = useState(false);
  const [addChecklists, setAddChecklists] = useState(false);
  const [addLink, setAddLinks] = useState(false);
  const [addFiles, setAddFiles] = useState(false);
  const [addTag, setAddTag] = useState(false);
  const [showMdDropdown, setShowMdDropdown] = useState(false);
  const { transaction } = useSelector(getTransactionSelector);
  const [milestoneDueDate, setMilestoneDueDate] = useState(null);
  const [changedFormvalues, setNewFormValues] = useState({
    taskId: formValues?.Id,
  } as TaskEditableFormValuesType);

  const [isProjectTask, setIsProjectTask] = useState(false);

  const buttonRef = useRef<any>();
  const optionsRef = useRef<any>();

  const dispatch = useDispatch();

  useOutsideClick([buttonRef, optionsRef], () => setOpen(false));

  useEffect(() => {
    if (
      ((isTemplate && templateId) || assignTask || isTransactionTask || isCampaignTask) &&
      editData
    ) {
      setNewFormValues({ ...editData });
      setUpdatedFormValues({ ...editData });
      if (editData?.Description) {
        setAddDescription(true);
      }
      if (editData?.Links?.length) {
        setAddLinks(true);
      }
      if (editData?.Checklists?.length) {
        setAddChecklists(true);
      }
      if (editData?.Documents?.length) {
        setAddFiles(true);
      }
      if (editData?.Category) {
        setAddTag(true);
      }
    } else if (isCampaignTask && campaignId) {
      onChangeField(campaignId, 'CampaignId');
    }
  }, [isTemplate, templateId, assignTask, editData, isTransactionTask, isCampaignTask, campaignId]);

  useEffect(() => {
    dispatch(getTransactionRolesEffect());
  }, []);

  useEffect(() => {
    if (formValues?.Description) {
      setAddDescription(true);
    }
    if (formValues?.Links?.length) {
      setAddLinks(true);
    }
    if (formValues?.Checklists?.length) {
      setAddChecklists(true);
    }
    if (formValues?.Documents?.length) {
      setAddFiles(true);
    }
    if (formValues?.Category) {
      setAddTag(true);
    }
  }, [formValues]);

  const onFormValueChange = (values) => {
    setNewFormValues(values);
    setUpdatedFormValues({ ...(updatedFormValues || {}), ...values });
    onUpdate(updatedFormValues);
  };

  const onChangeField = (val, fieldName) => {
    if (fieldName === 'MilestoneId') {
      setMilestoneDueDate(getMilestoneById(transaction?.Milestones, val)?.DueDate);
    }
    onFormValueChange({ ...(changedFormvalues || {}), [fieldName]: val });
  };

  const onChangeManyFields = (data) => {
    onFormValueChange({ ...(changedFormvalues || {}), ...data });
  };
  useEffect(() => {
    if (changedFormvalues?.ControlOperatorOffset?.startsWith('MD') && !changedFormvalues?.DueDate) {
      onChangeField(null, 'MilestoneId');
      setUpdatedFormValues({ ...updatedFormValues, DueDate: null, MilestoneId: null });
      setShowMdDropdown(true);
    } else {
      setShowMdDropdown(false);
    }
  }, [changedFormvalues?.ControlOperatorOffset, changedFormvalues?.DueDate]);

  useEffect(() => {
    if (isCampaignTask) {
      dispatch(getCampaignTaskMembersEffect());
    }
  }, [isCampaignTask]);

  const setCustomTag = (val) => {
    if (val?.trim()) {
      onChangeField(val, 'customTag');
    }
  };

  const showMoreOptions = () =>
    isFormTask ? !(addDescription && addFiles) : !(addDescription && addLink && addTag && addFiles);

  return (
    <div
      className={classNames(styles.taskModalForm, {
        [styles.transactionTaskForm]: isTransactionTask,
      })}
    >
      <TaskModalHeader
        isTransactionTask={isTransactionTask}
        isTemplate={isTemplate}
        isNew={isNew}
        showSubheading={showSubheading}
      />

      <div className={classNames(styles.content, className)}>
        {isTransactionTask && taskLength > 1 ? (
          <Options isTransactionTask={isTransactionTask} onRemove={onRemove} />
        ) : null}

        {isAggregatedTasks ? (
          <>
            {isTranFilterVisible ? (
              <Transaction
                values={updatedFormValues}
                setValues={({ isProject, ...values }) => {
                  setNewFormValues(values);
                  setIsProjectTask(isProject);
                }}
              />
            ) : (
              <></>
            )}
          </>
        ) : null}

        <TaskName
          isTemplate={isTemplate}
          values={updatedFormValues}
          onChangeField={onChangeField}
          isViewMode={isViewMode}
        />
        <div className={classNames({ [styles.rolesWrapper]: isTransactionTask })}>
          <AssignedTo
            isCampaignTask={isCampaignTask}
            isTemplate={isTemplate}
            values={updatedFormValues}
            onChangeField={onChangeField}
            isAssignTask={isAssignTask}
            className={isTransactionTask && styles.fieldHalf}
            isViewMode={isViewMode}
            showRole={true}
            setShowCC={setShowCC}
            showCC={showCC}
            isNew={isNew}
          />
          {showCC && (
            <CcTo
              isCampaignTask={isCampaignTask}
              isTemplate={isTemplate}
              values={updatedFormValues}
              onChangeField={onChangeField}
              isAssignTask={isAssignTask}
              className={isTransactionTask && styles.fieldHalf}
              isViewMode={isViewMode}
            />
          )}
        </div>
        <DueDate
          isTemplate={isTemplate}
          isCampaignTask={isCampaignTask}
          values={updatedFormValues}
          onChangeManyFields={onChangeManyFields}
          isViewMode={isViewMode}
          scrollToBottom={true}
          customDueDate={milestoneDueDate}
          isProjectTask={isProjectTask}
        />
        {showMdDropdown && (
          <div className={styles.mdDropdownContainer}>
            <AssignedToMilestone
              isTemplate={false}
              values={updatedFormValues}
              onChangeField={onChangeField}
              className={''}
              isAssignTask={false}
              hideAsterisk={true}
              label="Milestone"
              isViewMode={isViewMode}
              data={transaction?.Milestones}
            />
          </div>
        )}
        <TagInput
          onSelect={(val) =>
            onFormValueChange({ ...(changedFormvalues || {}), Category: val, customTag: '' })
          }
          onSelectCustomTag={setCustomTag}
          value={updatedFormValues as ValueObject}
          showOptional={true}
          disabled={isViewMode}
          className={styles.tagSelectorContainer}
          addTag={addTag}
        />

        <Description
          values={updatedFormValues}
          onChangeField={onChangeField}
          isViewMode={isViewMode}
          addDescription={addDescription}
        />

        <Checklists
          values={updatedFormValues}
          editData={editData}
          onChangeField={onChangeField}
          isViewMode={isViewMode || !isTemplate}
          addChecklists={addChecklists}
        />

        <Links
          values={updatedFormValues}
          editData={editData}
          onChangeField={onChangeField}
          isViewMode={isViewMode}
          addLink={addLink}
        />

        <Files
          values={updatedFormValues}
          isAssignTask={isAssignTask}
          isTemplate={isTemplate}
          onChangeField={onChangeField}
          isViewMode={isViewMode}
          addFiles={addFiles}
        />

        <div className={styles.moreOptionsContainer}>
          {showMoreOptions() && (
            <Button
              onClick={() => setOpen(!open)}
              className={styles.moreOptionsBtn}
              icon={<Icon variant={Icon.ADD} />}
              type="text"
              ref={buttonRef}
            >
              More Options
            </Button>
          )}

          {open && (
            <div ref={optionsRef} className={styles.options}>
              <ul>
                {!addDescription && (
                  <MoreOptionItem
                    onClick={() => {
                      setAddDescription(true);
                      setOpen(false);
                    }}
                    title="Add Description"
                  />
                )}
                {!addTag && !isFormTask && (
                  <MoreOptionItem
                    onClick={() => {
                      setAddTag(true);
                      setOpen(false);
                    }}
                    title="Add Tags"
                  />
                )}
                {isTemplate && !addChecklists && (
                  <MoreOptionItem
                    onClick={() => {
                      setAddChecklists(true);
                      setOpen(false);
                    }}
                    title="Add Checklists"
                  />
                )}
                {!addLink && !isFormTask && (
                  <MoreOptionItem
                    onClick={() => {
                      setAddLinks(true);
                      setOpen(false);
                    }}
                    title="Add Links"
                  />
                )}
                {!addFiles && (
                  <MoreOptionItem
                    onClick={() => {
                      setAddFiles(true);
                      setOpen(false);
                    }}
                    title="Add Files"
                  />
                )}
              </ul>
            </div>
          )}
        </div>

        <Permissions
          values={updatedFormValues}
          templateId={templateId}
          isTemplate={isTemplate}
          onChangeField={onChangeField}
          isViewMode={isViewMode}
        />
      </div>
      <Footer
        isCampaignTask={isCampaignTask}
        isTransactionTask={isTransactionTask}
        isNew={isNew}
        isTemplate={isTemplate}
        isAssignTask={isAssignTask}
        templateId={templateId}
        values={updatedFormValues}
        changedValues={changedFormvalues}
        setNewFormValues={setNewFormValues}
        onCancelModal={onCancelModal}
        assignTask={assignTask}
        isAggregatedTasks={isAggregatedTasks}
        isViewMode={isViewMode}
        onSaveTaskAsTemplate={onSaveTaskAsTemplate}
        isTranactionRoom={isTranactionRoom}
      />
    </div>
  );
};

TaskModalForm.propTypes = {
  isOpen: PropTypes.bool,
  isNew: PropTypes.bool,
  showSubheading: PropTypes.bool,
  isTemplate: PropTypes.bool,
  templateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editData: PropTypes.object,
  isAssignTask: PropTypes.bool,
  assignTask: PropTypes.func,
  onCancelModal: PropTypes.func,
  isTransactionTask: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onFieldChange: PropTypes.func,
  className: PropTypes.string,
  taskLength: PropTypes.number,
  taskData: PropTypes.object,
  isAggregatedTasks: PropTypes.bool,
  isViewMode: PropTypes.bool,
  isFormTask: PropTypes.bool,
  isCampaignTask: PropTypes.bool,
  campaignId: PropTypes.number,
  onSaveTaskAsTemplate: PropTypes.func,
  isTranactionRoom: PropTypes.bool,
};

TaskModalForm.defaultProps = {
  isOpen: false,
  isNew: false,
  showSubheading: false,
  isTemplate: false,
  templateId: null,
  editData: null,
  isAssignTask: false,
  assignTask: () => {},
  onCancelModal: () => {},
  isTransactionTask: false,
  onUpdate: () => {},
  onRemove: () => {},
  onFieldChange: () => {},
  className: '',
  taskLength: null,
  taskData: {},
  isAggregatedTasks: false,
  isViewMode: false,
  isFormTask: false,
  isCampaignTask: false,
  campaignId: null,
  onSaveTaskAsTemplate: () => {},
  isTranactionRoom: false,
};
