import { handleActions } from 'redux-actions';
import { IntelInitialState } from './types';
import {
  getAddressNeighborhoodActivityAction,
  getAddressValuationDetailsAction,
  getAddValuationValueAction,
  getReminderByIdAction,
} from 'store/actions/intel';
import { IDLE } from 'settings/constants/apiState';
import { get } from 'lodash-es';

const initialState: IntelInitialState = {
  selectedAddress: null,
  valuationDetails: {
    state: IDLE,
    data: null,
    meta: {},
  },
  neighborhoodActivity: {
    state: IDLE,
    data: null,
    meta: {},
  },
  reminders: {
    state: IDLE,
    data: null,
    meta: {},
  },
};

export default handleActions(
  {
    [getAddValuationValueAction.toString()]: (state, { payload }) => {
      const newEntry = get(payload, 'data');
      return {
        ...state,
        valuationDetails: {
          ...state.valuationDetails,
          data: [
            ...(state.valuationDetails.data ? state.valuationDetails.data : []),
            ...(newEntry ? [newEntry] : []),
          ],
        },
      };
    },
    [getAddressValuationDetailsAction.toString()]: (state, { payload }) => {
      // sort payload.data.result in desc by month and year
      const data = get(payload, 'data', null);
      if (Array.isArray(data)) {
        data.sort((a, b) => {
          if (a.year === b.year) {
            return b.month - a.month;
          }
          return b.year - a.year;
        });
      }
      return {
        ...state,
        valuationDetails: {
          ...state.valuationDetails,
          state: get(payload, 'state'),
          data,
          meta: get(payload, 'meta'),
        },
      };
    },
    [getAddressNeighborhoodActivityAction.toString()]: (state, { payload }) => ({
      ...state,
      neighborhoodActivity: {
        ...state.neighborhoodActivity,
        state: get(payload, 'state'),
        data: get(payload, 'data'),
        meta: get(payload, 'meta'),
      },
    }),
    [getReminderByIdAction.toString()]: (state, { payload }) => ({
      ...state,
      reminders: {
        ...state.reminders,
        state: get(payload, 'state'),
        data: get(payload, 'data'),
        meta: get(payload, 'meta'),
      },
    }),
  },
  initialState,
);
