import { useHistory, useLocation, useParams } from 'react-router-dom';

import { FormTemplateWizardParamType, FormTemplateWizardType } from 'types';
import { DynamicFormManager } from '../DynamicFormManager';
import { useLayoutEffect, useMemo } from 'react';
import { sortDocuments } from '../helper';

export const TemplateDynamicForm = () => {
  const { templateId } = useParams<FormTemplateWizardParamType>();
  const { state: formState } = useLocation<FormTemplateWizardType>();
  const state = useMemo(() => formState, []);
  const history = useHistory();

  useLayoutEffect(() => {
    const windowState = window.history.state?.state;
    if (windowState?.isWebView && !state) {
      history.replace(location.pathname, windowState);
      window.location.reload();
    }
  }, []);

  if (state?.allTemplates) {
    state.allTemplates = sortDocuments(state?.allTemplates, 'Order');
  }

  return templateId || state?.allTemplates ? (
    <DynamicFormManager
      templateData={{
        ...state,
        templateId,
        editMode: state?.editMode,
      }}
    />
  ) : (
    <></>
  );
};
