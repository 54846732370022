import { useCallback, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { AGENT } from 'settings/constants/roles';
import { routes } from 'settings/navigation/routes';
import { Icons } from 'pages/Workshop/Icons';
import {
  setTransactionsIsArchiveEffect,
  setTransactionFiltersAppliedEffect,
  requestGetTransactionsEffect,
} from 'store/effects/transactions/all';
import {
  getTransactionsIsArchiveSelector,
  getTransactionsIsFilterSelector,
} from 'store/selectors/transactions';
import { getUserRoleSelector } from 'store/selectors/user';
import { Filter } from '../../Filter';
import { getTransactionsSelector } from 'store/selectors/transactions';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { addIsProjectFilterForProjectRoutes } from 'utils/addIsProjectFilterForProjectRoutes';
import { getAgentTeamDetailSelector } from 'store/selectors/agentTeamDetail';

import styles from './styles.module.scss';

const Actions = () => {
  const { pathname } = useLocation();
  const userRole = useSelector(getUserRoleSelector);
  const isArchive = useSelector(getTransactionsIsArchiveSelector);
  const isFiltersApplied = useSelector(getTransactionsIsFilterSelector);
  const { isIdle } = useSelector(getTransactionsSelector);

  const { data } = useSelector(getAgentTeamDetailSelector);

  const [isMobile, setIsMobile] = useState(false);
  const isProject = useIsProjectRoute();

  useEffect(() => {
    function handleWindowSizeChange() {
      if (window.innerWidth <= 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    if (document.readyState === 'complete') {
      handleWindowSizeChange();
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.addEventListener('load', handleWindowSizeChange);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  const dispatch = useDispatch();
  const history = useHistory();

  const onArchiveModeChange = useCallback(() => {
    dispatch(setTransactionsIsArchiveEffect(!isArchive));

    // If filters are already applied, remove them on archive clicked.
    if (isFiltersApplied) {
      dispatch(setTransactionFiltersAppliedEffect(false));
      dispatch(
        requestGetTransactionsEffect(
          { filters: addIsProjectFilterForProjectRoutes(isProject, {}) },
          { silent: true },
        ),
      );
    }
  }, [dispatch, isArchive, isProject, isIdle, isFiltersApplied]);

  const redirectTo = () => {
    if (isProject) history.push(routes.projectCreate);
    else history.push(routes.transactionCreate);
  };

  return (
    <div className={styles.actionsWrapper}>
      <div className="seperator" />
      {userRole === AGENT && (pathname === routes.projects || data?.CanCreateTransaction) && (
        <div
          className={classNames(styles.btn, styles.newBtn, { [styles.btnText]: !isMobile })}
          onClick={redirectTo}
        >
          <Icons className={styles.addIcon} variant={Icons.ADD} />
          {isMobile ? '' : <span className={styles.iconText}>New</span>}
        </div>
      )}
      <Filter
        isArchive={isArchive}
        className={`${styles.btn} ${styles.filterBtn}`}
        userRole={userRole}
      />
      <div
        className={classnames(styles.btn, { [styles.selected]: isArchive })}
        onClick={onArchiveModeChange}
      >
        <Icons
          className={styles.btnIcon}
          variant={Icons.ARCHIVE}
          color={isArchive ? '#FFFFFF' : ''}
        />
      </div>
    </div>
  );
};

Actions.propTypes = {};
Actions.defaultProps = {};

export default Actions;
