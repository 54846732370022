import { DateRangePicker } from 'components/Form/DateRangePicker';
import { Moment } from 'moment';
import { FilterDrawer, FilterSection } from 'pages/Workshop/common';
import { GroupCheckBoxesSection } from 'pages/Workshop/common/GroupCheckBoxesSection';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LocationService } from 'services';
import {
  projectStatusesOptions,
  transactionStatuses as transactionStatusesData,
} from 'settings/constants/transaction';
import {
  taskFilters,
  taskStatusesExcludingDone,
  tasksStatusesIds,
  tasksStatusesOptions,
} from 'settings/constants/transactionTasks';
import { setTaskFiltersAppliedEffect, setTaskFiltersEffect } from 'store/effects';
import { getTaskAggregateEffect } from 'store/effects/taskAggregate';
import { getProjectsSelector } from 'store/selectors/projects';
import { getTransactionTaskCategoriesSelector } from 'store/selectors/transactionTaskCategories';
import { getAllTransactionsSelector } from 'store/selectors/transactions';
import { getTransactionSelector } from 'store/selectors/transaction';
import { getTransactionsTasksFilterSelector } from 'store/selectors/transactionTasks';
import { getUserId } from 'store/selectors/user';
import { getTransactionsTasksIsArchiveSelector } from 'store/selectors/taskAggregate';
import moment from 'moment';

import styles from './styles.module.scss';

let defaultFilters = {};

interface FilterSectionProps {
  isAggregatedTasks?: boolean;
  participants: any[];
}

export const FilterSections = ({ isAggregatedTasks, participants }: FilterSectionProps) => {
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const loggedInUserID = useSelector(getUserId);
  const { transactionsTasksFilter } = useSelector(getTransactionsTasksFilterSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const projectsData = useSelector(getProjectsSelector);
  const { categories } = useSelector(getTransactionTaskCategoriesSelector);
  const transactionsData = useSelector(getAllTransactionsSelector);
  const isArchive = useSelector(getTransactionsTasksIsArchiveSelector);
  const users = participants.map((p) => ({
    label: `${p.FirstName} ${p.LastName}`,
    value: p.ContactUserId || p.Id,
  }));

  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  const defaultStatuses = isArchive
    ? [tasksStatusesIds.done, tasksStatusesIds.na]
    : taskStatusesExcludingDone();

  const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);
  const [resetDates, setResetDates] = useState(false);
  const [previousFilters, setPreviousFilters] = useState({});

  const [dueFrom, setDueFrom] = useState<Moment | undefined>(
    'dueToday' in query ? moment() : undefined,
  );
  const [dueTo, setDueTo] = useState<Moment | undefined>(
    'dueToday' in query ? moment() : undefined,
  );
  const [transactions, setTransactions] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>(
    'status' in query ? [query.status] : defaultStatuses,
  );
  const [transactionStatuses, setTransactionStatuses] = useState<string[]>([]);
  const [projectStatuses, setProjectStatuses] = useState<string[]>([]);
  const [assignedByIds, setAssignedByIds] = useState<string[]>([]);
  const [assignedToIds, setAssignedToIds] = useState<string[]>([]);
  const [projects, setProjects] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [statusUpdated, setStatusUpdated] = useState<boolean>(false);

  const getFilters = (extraFilters = {}) => ({
    transactions,
    statuses,
    transactionStatuses,
    projectStatuses,
    dueTo: dueTo ? moment(dueTo).format('YYYY-MM-DD') : null,
    dueFrom: dueFrom ? moment(dueFrom).format('YYYY-MM-DD') : null,
    projects,
    assignedByIds,
    assignedToIds,
    tags,
    ...extraFilters,
  });

  useEffect(() => {
    defaultFilters = getFilters();
    setPreviousFilters(defaultFilters);
  }, []);

  useEffect(() => {
    if (!firstRender) resetHandler();
  }, [isArchive]);

  useEffect(() => {
    // Handle dashboard redirect
    if ('status' in query) setIsFiltersApplied(true);
    setFirstRender(false);

    const filters = {};
    switch (transactionsTasksFilter) {
      case taskFilters.myTasks:
        filters['assignee'] = 'me';
        break;
      case taskFilters.tasksIAssigned:
        filters['assignedByIds'] = [loggedInUserID];
        break;
      case taskFilters.clientTasks:
        filters['isClientTask'] = true;
        break;
      case taskFilters.collaboratorTasks:
        filters['isCollaboratorTask'] = true;
        break;
      case taskFilters.teamTasks:
        filters['isTeamTask'] = true;
        break;
      case taskFilters.campaignOnly:
        filters['campaignOnly'] = true;
        break;
    }

    submitHandler(filters);
  }, [transactionsTasksFilter]);

  const handleIsFiltersApplied = (filtersApplied: boolean) => {
    setIsFiltersApplied(filtersApplied);
    dispatch(setTaskFiltersAppliedEffect(filtersApplied));
  };

  useEffect(() => {
    setStatusUpdated(true);
  }, [statuses]);

  useEffect(() => {
    setIsFiltersApplied(
      Boolean(
        transactions.length ||
          JSON.stringify(statuses) != JSON.stringify(defaultStatuses) ||
          statusUpdated ||
          transactionStatuses.length ||
          projectStatuses.length ||
          dueTo ||
          dueFrom ||
          projects.length ||
          assignedByIds.length ||
          assignedToIds.length ||
          tags.length,
      ),
    );
  }, [
    dueFrom,
    dueTo,
    transactions,
    statuses,
    transactionStatuses,
    projectStatuses,
    statuses,
    projects,
    assignedToIds,
    assignedByIds,
    tags,
  ]);

  const filtersApplied = () =>
    Boolean(
      transactions.length ||
        JSON.stringify(statuses) != JSON.stringify(defaultStatuses) ||
        statusUpdated ||
        transactionStatuses.length ||
        projectStatuses.length ||
        dueTo ||
        dueFrom ||
        projects.length ||
        assignedByIds.length ||
        assignedToIds.length ||
        tags.length,
    );

  const submitHandler = (extraFilters = {}) => {
    const filters = getFilters(extraFilters);

    dispatch(setTaskFiltersEffect(filters));

    if (!isAggregatedTasks) {
      filters['transactionRoomId'] = params.id;
      filters['isProject'] = transaction.IsProject;
    }
    if (isAggregatedTasks) {
      filters['campaignOnly'] = filters['campaignOnly'] || false;
    }

    dispatch(
      getTaskAggregateEffect({
        filters,
      }),
    );

    setPreviousFilters(getFilters());

    handleIsFiltersApplied(filtersApplied());
  };

  const resetHandler = () => {
    setTransactions([]);
    setStatuses(defaultStatuses);
    setTransactionStatuses([]);
    setProjectStatuses([]);
    setDueTo(undefined);
    setDueFrom(undefined);
    setProjects([]);
    setAssignedByIds([]);
    setAssignedToIds([]);
    setTags([]);
    setStatusUpdated(false);
    const filters = {
      statuses: defaultStatuses,
    };

    if (!isAggregatedTasks) {
      filters['transactionRoomId'] = params.id;
      filters['isProject'] = transaction.IsProject;
    }
    if (isAggregatedTasks) {
      filters['campaignOnly'] = false;
    }

    dispatch(setTaskFiltersEffect(filters));

    dispatch(
      getTaskAggregateEffect({
        filters,
      }),
    );

    setPreviousFilters(defaultFilters);
    handleIsFiltersApplied(false);
  };

  const discardHandler = () => {
    if (JSON.stringify(previousFilters) === JSON.stringify(getFilters())) {
      return;
    }

    const {
      transactions,
      statuses,
      transactionStatuses,
      projectStatuses,
      dueTo,
      dueFrom,
      projects,
      assignedByIds,
      assignedToIds,
      tags,
    } = previousFilters as any;

    if (transactions) setTransactions(transactions);
    if (statuses) setStatuses(statuses);
    if (transactionStatuses) setTransactionStatuses(transactionStatuses);
    if (projectStatuses) setProjectStatuses(projectStatuses);
    if (dueTo) setDueTo(dueTo);
    if (dueFrom) setDueFrom(dueFrom);
    if (projects) setProjects(projects);
    if (assignedByIds) setAssignedByIds(assignedByIds);
    if (assignedToIds) setAssignedToIds(assignedToIds);
    if (tags) setTags(tags);
  };
  return (
    <FilterDrawer
      className={styles.filterBtn}
      isFiltersApplied={isFiltersApplied}
      onDone={submitHandler}
      onReset={resetHandler}
      onDiscard={discardHandler}
    >
      <div className={styles.filterSections}>
        <FilterSection title="Task Status" count={statuses?.length}>
          <GroupCheckBoxesSection
            title=""
            entityList={tasksStatusesOptions.map((status) => ({
              name: status.label,
              value: status.value,
            }))}
            entityValues={statuses}
            setEntity={setStatuses}
          />
        </FilterSection>

        <FilterSection title="Due Date">
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                resetDates && setResetDates(false);
                setDueFrom(from);
                setDueTo(to);
              }}
              fromDateValue={dueFrom}
              toDateValue={dueTo}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              resetDates={resetDates}
              futureDisabled={false}
            />
          </div>
        </FilterSection>

        <FilterSection title="Assigned To" count={assignedToIds?.length}>
          <MultiSelectSection
            placeholder={'Search by name...'}
            entityList={users}
            entity={assignedToIds}
            setEntity={setAssignedToIds}
            avatarPrefix
          />
        </FilterSection>

        <FilterSection title="Assigned By" count={assignedByIds?.length}>
          <MultiSelectSection
            placeholder={'Search by name...'}
            entityList={users}
            entity={assignedByIds}
            setEntity={setAssignedByIds}
            avatarPrefix
          />
        </FilterSection>

        {isAggregatedTasks && (
          <>
            <FilterSection
              title="Transactions"
              count={transactions?.length + transactionStatuses?.length}
            >
              <MultiSelectSection
                placeholder={'Search by transactions...'}
                entityList={transactionsData?.map(({ address, id }) => ({
                  value: id,
                  label: address,
                }))}
                entity={transactions}
                setEntity={setTransactions}
                locationPrefix
              />
              <GroupCheckBoxesSection
                title={'Statuses'}
                entityList={Object.values(transactionStatusesData).map((s) => ({
                  name: s,
                  value: s,
                }))}
                entityValues={transactionStatuses}
                setEntity={setTransactionStatuses}
              />
            </FilterSection>

            <FilterSection title="Projects" count={projects?.length + projectStatuses?.length}>
              <MultiSelectSection
                placeholder={'Search by project...'}
                entityList={projectsData.map((p) => ({ label: p.Name, value: p.Id }))}
                entity={projects}
                setEntity={setProjects}
              />
              <GroupCheckBoxesSection
                title={'Statuses'}
                entityList={projectStatusesOptions}
                entityValues={projectStatuses}
                setEntity={setProjectStatuses}
              />
            </FilterSection>
          </>
        )}

        <FilterSection title="Tags" count={tags?.length}>
          <MultiSelectSection
            placeholder={'Search by tags...'}
            entityList={categories?.map((c) => ({ label: c.Name, value: c.Id }))}
            entity={tags}
            setEntity={setTags}
          />
        </FilterSection>
      </div>
    </FilterDrawer>
  );
};
