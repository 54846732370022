import { createSelector } from 'reselect';
import {
  get,
  trim,
  flow,
  map,
  filter,
  includes,
  groupBy,
  cloneDeep,
  uniq,
  upperCase,
} from 'lodash-es';
import { AGENT, CLIENT, THIRD_PARTY, SUPER_USER } from 'settings/constants/roles';
import { PENDING } from 'settings/constants/apiState';
import { prefsIds } from 'settings/constants/preferences';
import { SEARCH_CRITERIA_IMPORTANCE } from 'settings/constants/searchCriterias';
import { getSearchCriteriaFormattedString } from 'helpers/formatters';
import { sortLocations, sortStrings } from 'helpers';
import { preparedAttachment } from '../effects/adminPanel/helpers';
import { getClientSearchInstancesSelector } from './clientInstances';
import { getClientInstancesSelector } from 'store/selectors/clientInstances';
import { SubscriptionPlanLevel } from 'api/subscription';

const localState = ({ user }) => user;

export const getUserSelector = createSelector(localState, (user) => user);

export const getUserAreaOfOperationSelector = createSelector(
  localState,
  (user) => user?.data?.Agent?.AreasOfOperation,
);

export const isUserPending = createSelector(getUserSelector, ({ state }) => state === PENDING);

export const getUserDataSelector = createSelector(localState, (user) => get(user, 'data'));

export const getClientDefaultSearchSelector = createSelector(
  getClientSearchInstancesSelector,
  (searchInstances) => searchInstances?.[0]?.DefaultPropertySearchPreferences,
  //TODO Support Context Switching
);

export const getUserId = createSelector(getUserDataSelector, (data) => get(data, 'Id'));

export const getUserDefaultRoleIdForTransaction = createSelector(getUserDataSelector, (data) =>
  get(data, 'Agent.DefaultRoleIdForTransaction'),
);

export const getAgentBrokerageId = createSelector(getUserDataSelector, (data) =>
  get(data, 'Agent.BrokerageId'),
);

export const getAgentId = createSelector(getUserDataSelector, (data) => get(data, 'Agent.Id'));

export const getUserRolesSelector = createSelector(getUserDataSelector, (user) =>
  get(user, 'Roles'),
);

export const getUserRoleSelector = createSelector(getUserDataSelector, (user) =>
  get(user, 'Roles.0'),
);

// This selector give current user loggedIn ID not the Agent.
export const getClientAgentId = createSelector(
  getUserDataSelector,
  getUserRolesSelector,
  (data, [role]) => get(data, `${role}.Id`),
);

export const getClientThreadAgent = createSelector(
  getUserDataSelector,
  getUserRolesSelector,
  (data, [role]) => get(data, `${role}.Inviter`),
);

export const getUserRolesMapSelector = createSelector(getUserRolesSelector, (roles) => ({
  isAgent: includes(roles, AGENT),
  isClient: includes(roles, CLIENT),
  isThirdParty: includes(roles, THIRD_PARTY),
  isSuperUser: includes(roles, SUPER_USER),
}));

export const isPartnerSelector = createSelector(
  getUserRoleSelector,
  getUserDataSelector,
  (role, data) => {
    if (role !== THIRD_PARTY) return false;
    return get(data, 'ThirdParty.IsPartner', false);
  },
);

export const isBuyerCompletedPreApproval = createSelector(getUserDataSelector, (data) => {
  const preApproval = get(data, 'Buyer.PreApproval');
  return !!preApproval; // TODO: probably need better check?
});

export const getUserFirstName = createSelector(
  getUserDataSelector,
  (user) => get(user, 'FirstName', 'Unknown') || '',
);

export const getUserLastName = createSelector(
  getUserDataSelector,
  (user) => get(user, 'LastName', 'Unknown') || '',
);

export const getUserCompletedProfile = createSelector(getUserDataSelector, (user) =>
  get(user, 'CompletedProfile'),
);

export const getUserFullName = createSelector(
  getUserFirstName,
  getUserLastName,
  (firstName, lastName) => trim(`${firstName} ${lastName}`),
);

export const getUserAvatar = createSelector(getUserDataSelector, (user) => get(user, 'AvatarUrl'));

export const getUserAvatarPlaceholder = createSelector(
  getUserFirstName,
  getUserLastName,
  (firstName, lastName) => upperCase(`${firstName?.[0] || ''}${lastName?.[0] || ''}`),
);

export const getUserEmail = createSelector(getUserDataSelector, (user) => get(user, 'Email'));

export const getUserPhones = createSelector(getUserDataSelector, (user) => get(user, 'Phones'));

export const getUserIsMfaEnabled = createSelector(getUserDataSelector, (user) =>
  get(user, 'IsMfaEnabled'),
);

export const getUserTimeZone = createSelector(
  getUserDataSelector,
  (user) => get(user, 'TimeZone') || '',
);

export const getCommonProfileData = createSelector(
  getUserFullName,
  getUserEmail,
  getUserPhones,
  getUserAvatar,
  getUserAvatarPlaceholder,
  getUserTimeZone,
  (name, email, phones, avatar, avatarPlaceholder, timezone) => ({
    name,
    email,
    phones,
    avatar,
    avatarPlaceholder,
    timezone,
    isMosaikUser: isMosaikUser(email),
  }),
);

function isMosaikUser(email) {
  return email?.endsWith('@mosaik.io');
}

export const getPreparedPhonesForForm = createSelector(getUserPhones, (phones) => {
  if (!phones) {
    return [];
  }

  return map(phones, ({ PhoneNumber }) => PhoneNumber);
});

export const getCommonEditProfileFields = createSelector(
  getUserFirstName,
  getUserLastName,
  getUserEmail,
  getPreparedPhonesForForm,
  getUserAvatar,
  getUserAvatarPlaceholder,
  getUserTimeZone,
  (firstName, lastName, email, phones, avatar, avatarPlaceholder, timezone) => ({
    firstName,
    lastName,
    email,
    phones,
    avatar,
    avatarPlaceholder,
    timezone,
  }),
);

export const getBuyerUserImportantFeaturesSelector = createSelector(
  getClientInstancesSelector,
  (clientInstances) => {
    const userPreferences =
      cloneDeep(get(clientInstances, 'searchInstances[0].DefaultPropertySearchPreferences')) || {};
    //TODO Support Context Switching
    const allBuyerPrefs = [];

    Object.entries(userPreferences).forEach(([key, value]) => {
      let prefs = !Array.isArray(value) ? [value] : value;

      if (key === prefsIds.parking) {
        prefs[0].Preference = prefsIds.parking;
        prefs = [...prefs];
      }

      allBuyerPrefs.push(...prefs);
    });

    const allBuyerPrefsNames = allBuyerPrefs.map((pref) => pref.Preference);
    const noPreferences = cloneDeep(
      Object.values(prefsIds).filter((prefId) => !allBuyerPrefsNames.includes(prefId)),
    );
    const groupedPrefs = cloneDeep(groupBy(allBuyerPrefs, 'ImportanceAndWeight.Importance'));

    const handleItems = (items) =>
      flow(
        (list) => map(list, (pref) => pref?.Preference),
        (list) => filter(list, (item) => item),
        uniq,
      )(items);

    return {
      [SEARCH_CRITERIA_IMPORTANCE.MUST]: handleItems(groupedPrefs?.Must).sort(sortStrings),
      [SEARCH_CRITERIA_IMPORTANCE.SOMEWHAT]: handleItems(groupedPrefs?.Somewhat).sort(sortStrings),
      [SEARCH_CRITERIA_IMPORTANCE.UNDEFINED]: noPreferences
        .filter((prefId) => prefId !== prefsIds.amenities)
        .sort(sortStrings),
    };
  },
);

// prettier-ignore
export const getBuyerCommutesSelector = createSelector(
  getClientInstancesSelector,
  (clientInstances) => cloneDeep(get(clientInstances, 'searchInstances[0].DefaultPropertySearchPreferences.CommutePrefs')) || [],
  //TODO Support Context Switching
);

// TODO: add fields for ThirdParty
export const getSpecificRoleProfileData = createSelector(
  getUserRolesSelector,
  getUserDataSelector,
  ([role], userData) => ({
    bio: get(userData, [role, 'Bio']) || '',
    links: get(userData, [role, 'Websites']) || [],
    website: get(userData, [role, 'Website']) || '',
    address: get(userData, [role, 'Address']) || '',
    suiteUnit: get(userData, [role, 'SuiteUnit']) || '',
    companyBusinessName: get(userData, [role, 'Company', 'BusinessName']) || '',
    companyJobTitle: get(userData, [role, 'Company', 'JobTitle']) || '',
    companyAddress: get(userData, [role, 'Company', 'Address']) || '',
    companySuiteUnit: get(userData, [role, 'Company', 'SuiteUnit']) || '',
    isPartner: get(userData, [role, 'IsPartner'], false),
    title: get(userData, [role, 'Title'], ''),
  }),
);

export const getAgentBrokerageSettingsData = createSelector(
  getUserRolesSelector,
  getUserDataSelector,
  ([role], userData) => {
    const brokerage = {
      id: get(userData, [role, 'Brokerage', 'Id']) || '',
      name: get(userData, [role, 'Brokerage', 'Name']) || '',
      address: {
        city: get(userData, [role, 'Brokerage', 'Address', 'City']) || '',
        state: get(userData, [role, 'Brokerage', 'Address', 'State']) || '',
        zip: get(userData, [role, 'Brokerage', 'Address', 'Zip']) || '',
        line1: get(userData, [role, 'Brokerage', 'Address', 'Line1']) || '',
        line2: get(userData, [role, 'Brokerage', 'Address', 'Line2']) || '',
      },
      suiteUnit:
        get(userData, [role, 'Brokerage', 'SuiteUnit']) ||
        get(userData, [role, 'Brokerage', 'Address', 'Line2']) ||
        '',
      officeSuiteUnit:
        get(userData, [role, 'Brokerage', 'SuiteUnit']) ||
        get(userData, [role, 'Brokerage', 'Address', 'Line2']) ||
        '',
    };

    const areasOfOperation = get(userData, [role, 'AreasOfOperation']) || [];
    return {
      brokerage,
      areasOfOperation,
    };
  },
);

export const getClientFormattedSearchCriterias = createSelector(
  getClientInstancesSelector,
  (clientInstances) => {
    const searchCriteria = get(
      clientInstances,
      'searchInstances[0].DefaultPropertySearchPreferences',
    );
    //TODO Support Context Switching
    return {
      searchCriteria,
      description: getSearchCriteriaFormattedString(searchCriteria)?.info,
    };
  },
);

export const getEditProfileAgentSendData = (store, values) => ({
  Values: {
    LastName: values.lastName,
    FirstName: values.firstName,
    Email: values.email?.toLowerCase(),
    Phones: map(get(values, 'phones', []), (phone, index) => ({
      PhoneNumber: phone,
      PhoneType: 'Mobile',
      IsPrimary: index === 0,
    })),
    TimeZone: get(values, 'timezone', undefined),
    Agent: {
      Bio: values.bio,
      Websites: get(values, 'links', []),
    },
  },
});

export const getEditBrokerageAgentSendData = (store, values) => ({
  Values: {
    Brokerage: {
      Name: values.brokerageName || '',
      Address: {
        Line1: values.officeAddress?.line1,
        Line2: undefined,
        City: values.officeAddress?.city,
        State: values.officeAddress?.state,
        Zip: values.officeAddress?.zip,
      },
      SuiteUnit: values.officeSuiteUnit,
      Phones: [
        {
          PhoneNumber: values.phone || '',
          PhoneType: 'Mobile',
          IsPrimary: true,
        },
      ],
    },
    AreasOfOperation: values.areasOfOperation,
  },
});

export const getEditProfileClientSendData = (store, values) => ({
  Values: {
    LastName: values.lastName,
    FirstName: values.firstName,
    Email: values.email?.toLowerCase(),
    Phones: map(get(values, 'phones', []), (phone, index) => ({
      PhoneNumber: phone,
      PhoneType: 'Mobile',
      IsPrimary: index === 0,
    })),
    TimeZone: get(values, 'timezone', undefined),

    Client: {
      Address: values.address,
    },
  },
});

export const getEditProfileThirdPartySendData = (store, values) => {
  return {
    Values: {
      LastName: values.lastName,
      FirstName: values.firstName,
      Email: values.email?.toLowerCase(),
      Phones: map(get(values, 'phones', []), (phone, index) => ({
        PhoneNumber: phone,
        PhoneType: 'Mobile',
        IsPrimary: index === 0,
      })),
      TimeZone: get(values, 'timezone', undefined),
      ThirdParty: {
        Website: values.website,
        Bio: values.bio,
        Company: {
          BusinessName: values.companyBusinessName,
          JobTitle: values.companyJobTitle,
          Address: values.companyAddress,
          SuiteUnit: values.companySuiteUnit,
        },
      },
    },
  };
};

export const getEditProfilePartnerSendData = (store, values) => {
  const Bio = get(values, 'bio', undefined)?.trim() || undefined;

  const BusinessName = get(values, 'companyBusinessName', undefined)?.trim() || undefined;
  const JobTitle = get(values, 'companyJobTitle', undefined)?.trim() || undefined;
  const Address = get(values, 'companyAddress', undefined)?.trim() || undefined;
  const SuiteUnit = get(values, 'companySuiteUnit', undefined)?.trim() || undefined;
  const Website = get(values, 'website', undefined)?.trim() || undefined;

  const isCompanyData = !!BusinessName || !!JobTitle || !!Address || !!SuiteUnit || !!Website;
  return {
    Values: {
      LastName: values.lastName,
      FirstName: values.firstName,
      Email: values.email?.toLowerCase(),
      Phones: map(get(values, 'phones', []), (phone, index) => ({
        PhoneNumber: phone,
        PhoneType: 'Mobile',
        IsPrimary: index === 0,
      })),
      TimeZone: get(values, 'timezone', undefined),
      ThirdParty: {
        ...(Bio && { Bio }),
        ...(isCompanyData && {
          Company: {
            BusinessName,
            JobTitle,
            Address,
            SuiteUnit,
            Website,
          },
        }),
      },
    },
  };
};

export const getEditPartnerProfileSendData = (store, values) => {
  const address = get(values, 'Address');
  const geolocation =
    address?.Geolocation?.Lat && address?.Geolocation?.Long
      ? {
          Geolocation: {
            Lat: get(address, 'Geolocation.Lat'),
            Long: get(address, 'Geolocation.Long'),
          },
        }
      : {};
  const logo = get(values, 'LogoUrl');
  const logoAttachment = logo && typeof logo === 'object' ? preparedAttachment(logo) : null;
  const locations = get(values, 'locations') || undefined;

  return {
    Values: {
      PartnerServices: get(values, 'PartnerServices'),
      ThirdParty: {
        Partner: {
          BusinessName: get(values, 'BusinessName'),
          AdditionalBusinessName: get(values, 'AdditionalBusinessName') || '',
          Email: get(values, 'Email'),
          PhoneNumber: get(values, 'PhoneNumber'),
          DirectoryPreference: get(values, 'DirectoryPreference') || false,
          Address: {
            Line1: get(values, 'Address.Line1') || undefined,
            Line2: get(values, 'Address.Line2') || undefined,
            City: get(values, 'Address.City') || undefined,
            State: get(values, 'Address.State') || undefined,
            Zip: get(values, 'Address.Zip') || undefined,
            ...geolocation,
          },
          AreasOfOperation: locations?.length
            ? locations.map((areas) => {
                const { Radius, AreasOfOperation: location } = areas;
                return {
                  Type: get(location, 'Type') || undefined,
                  Box: get(location, 'Box') || undefined,
                  ProviderPlaceId: get(location, 'ProviderPlaceId') || undefined,
                  NeighborhoodName: get(location, 'NeighborhoodName') || undefined,
                  LocationId:
                    get(location, 'NeighborhoodId') || get(location, 'LocationId') || undefined,
                  PlaceName: get(location, 'PlaceName') || undefined,
                  City: get(location, 'City') || undefined,
                  State: get(location, 'State') || undefined,
                  Street: get(location, 'Street') || undefined,
                  Zipcode: get(location, 'Zipcode') || undefined,
                  Radius: Radius,
                };
              })
            : undefined,
          SuiteUnit: get(values, 'SuiteUnit'),
          Description: get(values, 'Description') || '',
          Website: get(values, 'Website'),
          ...(logoAttachment ? { LogoUrl: logoAttachment } : {}),
        },
      },
    },
  };
};

export const getUserAgentsSelector = createSelector(
  getUserDataSelector,
  getUserRolesSelector,
  (user, userRoles) => {
    if (userRoles.includes(CLIENT)) {
      const userRole = userRoles?.[0];
      return get(user, [`${userRole}`, 'Agents'], null);
    }
    return null;
  },
);

export const getUserNotificationSettingsSelector = createSelector(
  getUserDataSelector,
  (userData) => {
    const userNotificationPreferences = get(userData, 'NotificationPreferences') || {};

    return userNotificationPreferences;
  },
);

export const getNotificationEnableStatus = createSelector(getUserDataSelector, (userData) => {
  const userNotificationPreferences = get(userData, 'NotificationPreferences') || {};
  const isNotificationsEnable =
    Array.isArray(userNotificationPreferences) &&
    userNotificationPreferences.some((group) =>
      group?.NotificationItems.some((item) =>
        Object.values(item?.Variants).some((option) => option),
      ),
    );
  return isNotificationsEnable;
});

export const getUserSearchCriteriaPrefsSelector = createSelector(
  getUserSelector,
  getUserRolesMapSelector,
  getClientInstancesSelector,
  (user, rolesMap, clientInstances) => {
    let prefs = {};
    if (rolesMap?.isAgent) {
      prefs = get(user, 'data.Agent.FeedPrefs');
    }

    if (rolesMap?.isClient) {
      prefs = get(clientInstances, 'searchInstances[0].DefaultPropertySearchPreferences');
      //TODO Support Context Switching
    }

    if (prefs?.HomeType?.length) prefs.HomeType.sort(sortStrings);
    if (prefs?.Locations?.length) {
      prefs.Locations = prefs.Locations.map((location) => {
        const formatted = { ...location };
        delete formatted.LocationId;
        return formatted;
      });
      prefs.Locations.sort(sortLocations);
    }

    return prefs;
  },
);

export const getPartnerSettingsViewData = createSelector(getUserDataSelector, (data) => {
  const partner = get(data, 'ThirdParty.Partner');
  const address = get(partner, 'Address', {});
  const line1 = get(address, 'Line1', '');
  const line2 = get(address, 'Line2', '');
  const city = get(address, 'City', '');
  const state = get(address, 'State', '');
  const zip = get(address, 'Zip', '');
  const SuiteUnit = get(partner, 'SuiteUnit', '');
  const DirectoryPreference = get(partner, 'DirectoryPreference', false);

  const addressInfo = line1
    ? {
        Address: {
          Line1: line1,
          Line2: line2,
          State: state,
          City: city,
          Zip: zip,
          PlaceName: [line1, city, state].filter((i) => !!i).join(', '),
          ProviderPlaceId: line1, // Just set to the address line1, because we do not save ProviderPlaceId for Brokerage Address
        },
      }
    : {};

  return {
    BusinessName: get(partner, 'BusinessName', ''),
    AdditionalBusinessName: get(partner, 'AdditionalBusinessName', ''),
    Email: get(partner, 'Email', ''),
    PhoneNumber: get(partner, 'PhoneNumber', ''),
    ...addressInfo,
    SuiteUnit,
    Description: get(partner, 'Description', ''),
    Website: get(partner, 'Website', ''),
    LogoUrl: get(partner, 'LogoUrl', ''),
    AreasOfOperation: get(partner, 'AreasOfOperation', []),
    DirectoryPreference,
  };
});

export const getClientContextSelector = createSelector(getUserDataSelector, (data) => {
  const client = get(data, 'Client', {});
  const context = get(client, 'Agents', []);
  return context;
});

export const getActiveSubscriptionData = createSelector(getUserDataSelector, (data) => {
  const subscriptionCustomer = get(data, 'SubscriptionCustomer');

  // Simplifying statuses to "active"/"not active" for now. Can be extended later to make UI more informative
  if (
    subscriptionCustomer?.SubscriptionStatus &&
    !['canceled', 'unpaid'].includes(subscriptionCustomer?.SubscriptionStatus)
  ) {
    return {
      isSubscriptionActive: true,
      isOverdue: subscriptionCustomer?.SubscriptionStatus === 'past_due',
      planLevel: subscriptionCustomer?.PlanLevel,
      planName: subscriptionCustomer?.PlanName,
      willBeCanceledTimestamp: subscriptionCustomer?.WillBeCanceledTimestamp,
      isStandardSoloPlan:
        subscriptionCustomer.InternalPlanName === 'standard' &&
        subscriptionCustomer?.RequestedSeatsCount <= 2,
      isStandardTeamPlan:
        subscriptionCustomer.InternalPlanName === 'standard' &&
        subscriptionCustomer?.RequestedSeatsCount > 2,
      isLegacyPlan: subscriptionCustomer.InternalPlanName === 'paid',
      RequestedSeatsCount: subscriptionCustomer?.RequestedSeatsCount,
      Interval: subscriptionCustomer?.Interval,
      ActualSeatsCount: subscriptionCustomer?.ActualSeatsCount,
      isComplimentary: subscriptionCustomer?.IsComplimentaryPlan,
      isSubscriptionCanceled: typeof subscriptionCustomer?.WillBeCanceledTimestamp === 'string',
    };
  }

  return {
    isSubscriptionActive: false,
    isComplimentary: subscriptionCustomer?.IsComplimentaryPlan,
    isInImplementation:
      !subscriptionCustomer || subscriptionCustomer.PlanLevel === SubscriptionPlanLevel.Premium,
  };
});

export const getUserInsight = createSelector(getUserDataSelector, (user) =>
  get(user, 'Insight', false),
);

export const getAgentTypeSelector = createSelector(localState, (user) =>
  get(user, 'data.Agent.AgentType', null),
);

export const getAgentAreasOfOperationSelector = createSelector(getUserDataSelector, (user) =>
  (user?.Agent?.AreasOfOperation || [])
    .filter((loc) => loc.State)
    .map((loc) => ({
      ...loc,
      locationSate: loc.State,
    })),
);
