import moment from 'moment';
import { renderStatus } from 'pages/Workshop/Forms/components/FormsTable';
import { Options } from '../Options';
import { DocumentNameColumn, AvatarColumn, DateTimeColumn } from './TableColumns';
import { AgentDocumentTypes } from 'types';
import { FormsTableStatus } from 'app-constants';
import { formatDate } from 'helpers';

const docTypeMisc = AgentDocumentTypes.Misc;
const docTypeUploads = AgentDocumentTypes.Uploads;

export const miscElectronicSignColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 375,
    render: (title) => <DocumentNameColumn name={title} docIconType="Ungrouped" />,
    sorter: (a, b) => a.title?.localeCompare(b.title),
  },
  {
    key: 'status',
    title: 'Status',
    width: 200,
    render: () => renderStatus(FormsTableStatus.Complete),
  },
  {
    key: 'client',
    title: 'Client',
    width: 250,
    render: (row) => <AvatarColumn name={`${row?.clientMeta?.[0]?.UserName}`} imgSrc={null} />,
    sorter: (a, b) => a?.clientMeta?.[0]?.UserName?.localeCompare(b?.clientMeta?.[0]?.UserName),
  },
  {
    title: '',
    render: (row) =>
      row?.miscellaneousMeta?.sealedDate ? (
        <span>Completed: {formatDate(row?.miscellaneousMeta?.sealedDate, 'M/D/YYYY')}</span>
      ) : (
        ''
      ),
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docTypeMisc} optionUtils={optionUtils} />,
  },
];

export const miscUploadsColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 475,
    render: (title) => <DocumentNameColumn name={title} docIconType="Ungrouped" />,
    sorter: (a, b) => a.title?.localeCompare(b.title),
  },
  {
    key: 'uploadedBy',
    title: 'Uploaded By',
    width: 320,
    render: (row) => (
      <AvatarColumn
        name={row?.uploadDocumentMeta?.uploadedBy}
        imgSrc={row?.uploadDocumentMeta?.AvatarUrl}
      />
    ),
    sorter: (a, b) =>
      a?.uploadDocumentMeta?.uploadedBy?.localeCompare(b?.uploadDocumentMeta?.uploadedBy),
  },
  {
    key: 'uploadedAt',
    title: 'Created',
    render: (row) => <DateTimeColumn date={row?.uploadDocumentMeta?.uploadedAt} />,
    sorter: (a, b) => {
      if (!a?.uploadDocumentMeta?.uploadedAt) return 1;
      if (!b?.uploadDocumentMeta?.uploadedAt) return -1;

      return moment(a?.uploadDocumentMeta?.uploadedAt).diff(
        moment(b?.uploadDocumentMeta?.uploadedAt),
        'minutes',
      );
    },
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docTypeUploads} optionUtils={optionUtils} />,
  },
];
