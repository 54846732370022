import React from 'react';

const PropertyIcon = ({ color = '#747475' }: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M5.75 11V20.75H19.25V11"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.248 20.75V15.5H14.748V20.75"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.25 11.75L12.5 5L16.2482 8.06668M20.75 11.75L19.2482 10.5212M16.2482 8.06668V5H19.2482V10.5212M16.2482 8.06668L19.2482 10.5212"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PropertyIcon;
