import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Moment } from 'moment';
import { keys } from 'lodash-es';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AuditLogCard } from 'components-antd/AuditLogCard';
import { formatLogsData, getFormattedFilterObject } from 'utils/activityLogsHelper';
import { activityLogsPageSize } from 'app-constants/activityLogs';
import { Icons } from '../../Icons';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { getClientDetailsSelector } from 'store/selectors/clientDetail';
import { FilterPopover } from '../FilterPopover';
import { getActivityLogs } from 'api/activityLog';

import styles from './styles.module.scss';
import { SearchFieldWithDebounce, Spinner } from 'components';
import useScrollPagination from 'hooks/use-scroll-pagination';

export const ClientActivityLogSection = ({ isAgent }) => {
  const details = useSelector(isAgent ? getAgentDetailsSelector : getClientDetailsSelector);
  const contact = details?.data || {};
  const users = [contact?.Id];
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [prevY, setPrevY] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [logItems, setLogItems]: any[] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [formatted, setFormatted] = useState([]);
  const initializeFilter = { users };
  const [fieldsPayload, setFieldsPayload] = useState<any>(initializeFilter);
  const logsRef = useRef([]);
  const prevYRef = useRef({});
  const totalItemsRef: any = useRef({});
  const fieldsRef = useRef({});
  const searchTermRef = useRef('');

  logsRef.current = [];
  prevYRef.current = prevY;
  totalItemsRef.current = totalItems;
  fieldsRef.current = fieldsPayload;

  useEffect(() => {
    getLogs();
  }, []);

  useEffect(() => {
    setFormatted(formatLogsData(logItems));
  }, [logItems]);

  const getLogs = async (page = 1) => {
    setIsLoading(true);
    try {
      const { queryParams, ...formattedObj } = getFormattedFilterObject(
        fieldsRef.current,
        page || 1,
        activityLogsPageSize,
        searchTermRef.current,
      );

      const { data } = await getActivityLogs(formattedObj, queryParams);
      if (data?.result) {
        let items = data?.result?.items || [];
        if (page === 1) {
          setLogItems([...items]);
        } else {
          setLogItems((prevList) => {
            return [...prevList, ...items];
          });
        }
        setPageNumber(page || 1);
        setTotalItems(data?.result?.total);
        setTotalPages(Math.ceil(data?.result?.total / activityLogsPageSize));
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    const trimmedValue = value.trim();
    if (trimmedValue !== searchTermRef.current) {
      searchTermRef.current = trimmedValue;
      setPageNumber(1);
      getLogs();
    }
  };

  const onDateChangeHandler = (_from?: Moment, _to?: Moment) => {
    setFieldsPayload({ ...fieldsPayload, startDate: _to && !_from ? _to : _from, endDate: _to });
  };

  const dateString = `${
    fieldsPayload?.startDate &&
    fieldsPayload?.endDate &&
    fieldsPayload?.startDate !== fieldsPayload?.endDate
      ? fieldsPayload.startDate.format('MM/DD/YY') + ' - '
      : fieldsPayload?.startDate && fieldsPayload?.startDate !== fieldsPayload?.endDate
      ? fieldsPayload.startDate.format('MM/DD/YY')
      : ''
  }${fieldsPayload?.endDate ? fieldsPayload.endDate.format('MM/DD/YY') : ''}`;

  const renderFilterIcon = (
    <div
      className={classNames(styles.btn, {
        [styles.iconOnly]: dateString === '',
      })}
    >
      <Icons variant={'filterSecondary'} className={styles.filterIcon} size={'24'} />
      <p>{dateString}</p>
    </div>
  );

  const onScrollEnd = () => {
    getLogs(pageNumber + 1);
  };

  useScrollPagination({
    container: 'scrollContent',
    isFetching: isLoading,
    pageNumber,
    onScrollEnd,
    totalPages,
  });

  return (
    <div className={styles.clientActivityLogSection}>
      <div className={styles.row}>
        {/* <SearchFieldWithDebounce
          className={styles.wrapper}
          resetAction={() => handleSearch('')}
          doNotDispatch
          sendAction={handleSearch}
          isLoading={isLoading}
          placeholder="Search"
          isExpandedVersion
        /> */}
        <p className={styles.heading}>Activity</p>
        <FilterPopover
          filterIcon={renderFilterIcon}
          onDateChangeHandler={onDateChangeHandler}
          onActivityTypeSelectHandler={() => {}}
          from={fieldsPayload?.startDate}
          to={fieldsPayload?.endDate}
          onDone={() => {
            setPageNumber(1);
            getLogs();
          }}
          onReset={() => {
            setFieldsPayload(initializeFilter);
            fieldsRef.current = initializeFilter;
            setPageNumber(1);
            getLogs();
          }}
        />
      </div>

      <div className={styles.clientActivityLogWrap}>
        {!!formatted?.length &&
          formatted.map((item, idx) => {
            return (
              <AuditLogCard
                key={idx}
                activityLogs={item[`${keys(item)}`]}
                DayAndDate={keys(item)[0]}
                activityLogCardClassNameSecondary={styles.clientActivityCard}
                timelineItemWrapperClassName={classNames(styles.timelineItemWrapper)}
                avatarWrapperClassName={styles.avatarWrapper}
              />
            );
          })}
        {!isLoading && !formatted?.length ? (
          <div className={styles.noActivityFound}>
            <span>No Activity</span>
          </div>
        ) : null}
        {isLoading && <Spinner />}
      </div>
    </div>
  );
};

ClientActivityLogSection.propTypes = {
  isAgent: PropTypes.bool,
};

ClientActivityLogSection.defaultProps = {
  isAgent: false,
};
