import { isString } from 'lodash-es';
import { RefObject, useEffect } from 'react';

interface IScrollHookProps {
  container: RefObject<HTMLDivElement> | RefObject<HTMLUListElement> | string;
  isFetching: boolean;
  pageNumber: number;
  totalPages: number;
  isReverse?: boolean;
  onScrollEnd: () => void;
}

const useScrollPagination = ({
  container,
  isFetching,
  pageNumber,
  totalPages,
  onScrollEnd,
  isReverse = false,
}: IScrollHookProps) => {
  const containerElm = isString(container) ? document.getElementById(container) : container.current;

  useEffect(() => {
    function handleScroll() {
      if (!containerElm || isFetching || pageNumber >= totalPages) return;

      const { scrollTop, scrollHeight, clientHeight } = containerElm;

      if (isReverse) {
        if (scrollTop === 0) {
          onScrollEnd();
        }
      } else {
        if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
          onScrollEnd();
        }
      }
    }

    if (containerElm) {
      containerElm.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerElm) {
        containerElm.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isFetching, totalPages, pageNumber, isReverse]);
};

export default useScrollPagination;
