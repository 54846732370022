import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { cloneDeep, orderBy } from 'lodash-es';
import classNames from 'classnames';

import { ArrowLeftFull } from 'components/Icons';
import { getTimelineTemplateEffect } from 'store/effects/template';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import {
  getEmptyMilestone,
  timelineDateControls,
  transactionPreFormQuestionsIds,
} from 'settings/constants/transaction';
import { Question } from 'pages/RequestQuote/components';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { Button, Wrapper } from 'components';
import { EditTimeline } from 'components/Transactions';
import { Modal } from 'pages/Workshop/Transactions/TransactionCreate/components';
import Answers from '../../Answers';
import { Continue } from '../../Continue';
import Templates from './Templates';
import TemplatePreview from './TemplatePreview';
import { DateControls } from './DateControls';

import styles from './styles.module.scss';
import { AnswersContainer } from '../../AnswersContainer';
import { ButtonsContainer } from '../../ButtonsContainer';
import useIsProjectRoute from 'hooks/use-is-project-route';

const sortTimeline = (values) =>
  orderBy(values || [], (item) => moment(item.DueDate).valueOf(), ['asc']);

const Timeline = (props) => {
  const { className, onNext, currentQuestionId, setStage } = props;
  const dispatch = useDispatch();
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const timelineValues = cloneDeep(preForm?.[transactionPreFormQuestionsIds.timeline]) || [];
  const isProject = useIsProjectRoute();
  const [modalOpen, setModalOpen] = useState(false);
  const [templateToPreviewId, setTemplateToPreviewId] = useState(null);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [dateControls, setDateControls] = useState({
    T: new Date(),
    CD: undefined,
    CL: undefined,
    LE: undefined,
    O: undefined,
    TL: undefined,
  });

  const dateControlsLabels = {
    CD: isProject ? 'Kickoff Date' : 'Contract Date',
    CL: isProject ? 'Deadline' : 'Closing Date',
    LE: 'Listing Expiration Date',
    TL: 'Target Live Date',
  };

  const [dateControlsMilestones, setDateControlsMilestones] = useState([]);
  const listRef = useRef();

  const [itemRef, setItemRef] = useState();

  useEffect(() => {
    if (!templateToPreviewId && listRef.current && itemRef) {
      listRef.current.scrollTo({
        top: itemRef.scrollTop,
        behavior: 'smooth',
      });

      setItemRef(null);
    }
  }, [templateToPreviewId]);

  useEffect(() => {
    if (dateControls) {
      // Pushing date control milestones for viewing only
      const dateControlitems = Object.keys(dateControls)
        .filter((key) => key !== 'T' && key !== 'O' && dateControls[key] !== undefined)
        .map((key) => ({
          Title: dateControlsLabels[key],
          DueDate: dateControls[key],
          isDateControlMilestone: true,
        }));
      setDateControlsMilestones(dateControlitems);
    }
  }, [dateControls]);

  useEffect(() => {
    onControlDateChange(null, {
      ...dateControls,
      CD: preForm[transactionPreFormQuestionsIds.effectiveDate] || undefined,
      CL: preForm[transactionPreFormQuestionsIds.closingDate] || undefined,
      LE: preForm[transactionPreFormQuestionsIds.listingExpireDate] || undefined,
      TL: preForm[transactionPreFormQuestionsIds.liveDate] || undefined,
    });
  }, [
    preForm[transactionPreFormQuestionsIds.effectiveDate],
    preForm[transactionPreFormQuestionsIds.closingDate],
    preForm[transactionPreFormQuestionsIds.listingExpireDate],
    preForm[transactionPreFormQuestionsIds.liveDate],
  ]);

  const onControlDateChange = (e, controls) => {
    setDateControls({
      ...controls,
    });
    updateValue(controls, transactionPreFormQuestionsIds.dateControls);
  };

  const updateValue = useCallback(
    (val, questionId = transactionPreFormQuestionsIds.timeline) => {
      dispatch(
        setTransactionPreFormQuestionsEffect({
          [questionId]: val,
        }),
      );
    },
    [dispatch],
  );

  const onAdd = () => {
    if (currentQuestionId === transactionPreFormQuestionsIds.timeline || setStage) {
      timelineValues.push(getEmptyMilestone());
      updateValue(timelineValues);
    }
  };

  const onFieldsChange = (data, index) => {
    timelineValues[index] = {
      ...timelineValues[index],
      ...data,
    };
    updateValue(sortTimeline(timelineValues));
  };

  const onRemove = (index) => {
    timelineValues.splice(index, 1);
    updateValue(sortTimeline(timelineValues));
  };

  const canContinue = () => {
    let flag = true;
    for (let index in timelineValues) {
      const timeline = timelineValues[index];
      if ((!!timeline.Title && !timeline.DueDate) || (!!timeline.DueDate && !timeline.Title)) {
        flag = false;
        break;
      }
    }
    return flag;
  };

  const canAddMilestone = () => {
    let flag = true;
    for (let index in timelineValues) {
      const timeline = timelineValues[index];
      if (!timeline.Title || !timeline.DueDate) {
        flag = false;
        break;
      }
    }
    return flag;
  };

  // const onPreviewTemplate = useCallback(
  //   (id) => {
  //     setTemplateToPreviewId(id);
  //   },
  //   [setTemplateToPreviewId],
  // );

  const onPreviewTemplate = (id, ref) => {
    if (ref.current) {
      const { offsetTop, scrollTop, scrollHeight } = ref.current;
      setItemRef({ offsetTop, scrollTop, scrollHeight });
    }
    setTemplateToPreviewId(id);
  };

  const getDateControlFromInitial = (initial) => {
    switch (initial) {
      case timelineDateControls.A: {
        return new Date();
      }
      case timelineDateControls.CD: {
        return preForm[transactionPreFormQuestionsIds.effectiveDate];
      }
      case timelineDateControls.CL: {
        return preForm[transactionPreFormQuestionsIds.closingDate];
      }
      case timelineDateControls.LE: {
        return preForm[transactionPreFormQuestionsIds.listingExpireDate];
      }
      case timelineDateControls.TL: {
        return preForm[transactionPreFormQuestionsIds.liveDate] || new Date();
      }
      default:
        return null;
    }
  };

  const onSelectTemplate = useCallback(
    (templateId) => {
      setIsTemplateLoading(true);
      setModalOpen(false);
      dispatch(
        getTimelineTemplateEffect({ id: templateId }, {}, (err, res) => {
          if (!err) {
            const milestones = res?.data?.result?.Milestones || [];
            const formattedMilestones = milestones.map(
              ({
                Title,
                Offset,
                Initial,
                Operator,
                ControlOperatorOffset,
                IsPrivate,
                PublicId,
              }) => ({
                Title,
                DueDate: getDateControlFromInitial(Initial)
                  ? Operator === '-'
                    ? moment(getDateControlFromInitial(Initial)).subtract(Offset, 'days').toDate()
                    : moment(getDateControlFromInitial(Initial)).add(Offset, 'days').toDate()
                  : null,
                ControlOperatorOffset,
                IsPrivate,
                PublicId,
              }),
            );
            const sortedMilestones = orderBy(
              [...formattedMilestones, ...dateControlsMilestones],
              'DueDate',
              ['asc'],
            );
            updateValue(sortedMilestones);
          }
          setIsTemplateLoading(false);
        }),
      );
    },
    [updateValue, dispatch, dateControls],
  );

  const getModalTitle = () => {
    if (templateToPreviewId) {
      return (
        <ArrowLeftFull
          className={styles.backBtn}
          testid="back_button"
          onClick={() => setTemplateToPreviewId(null)}
        />
      );
    }
    return 'Timeline Template';
  };

  const removeEmptyTimelineItems = (timeline) => {
    return timeline.filter((item) => {
      return item.Title && item.DueDate;
    });
  };

  const onNextHandler = (event) => {
    const filteredTimeline = removeEmptyTimelineItems(timelineValues);
    updateValue(sortTimeline(filteredTimeline));
    if (setStage) {
      setStage(transactionPreFormQuestionsIds.assignParticipantsToRoles);
    }
    if (currentQuestionId === transactionPreFormQuestionsIds.timeline) {
      onNext(event);
    }
  };

  return (
    <div testid="edit_timeline" className={classNames(styles.container, className)}>
      <Question>Timeline</Question>
      <AnswersContainer className={styles.answerContainer}>
        <Wrapper isPending={isTemplateLoading}>
          <Answers className={styles.answers}>
            <DateControls dateControls={dateControls} onDateChange={onControlDateChange} />
            <EditTimeline
              listItems={timelineValues}
              onAdd={onAdd}
              onRemove={onRemove}
              onFieldsChange={onFieldsChange}
              dateControls={dateControls}
              disableAddMilestone={!canAddMilestone()}
              allowFirstDelete
            />
          </Answers>
        </Wrapper>
      </AnswersContainer>
      <ButtonsContainer>
        <Button
          testid="templates_button"
          onClick={() => {
            setModalOpen(true);
            setTemplateToPreviewId(null);
          }}
          title="Templates"
          className={styles.templatesBtn}
        />
        <Continue onClick={onNextHandler} disabled={!canContinue()} />
      </ButtonsContainer>
      <Modal
        title={getModalTitle()}
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setTemplateToPreviewId(null);
        }}
        contentClassName={styles.modalContent}
        testid="timeline_templates"
      >
        {templateToPreviewId ? (
          <TemplatePreview isProject={isProject} templateId={templateToPreviewId} />
        ) : (
          <Templates onPreview={onPreviewTemplate} onSelect={onSelectTemplate} listRef={listRef} />
        )}
      </Modal>
    </div>
  );
};

Timeline.propTypes = {
  className: PropTypes.string,
  onNext: PropTypes.func,
  setStage: PropTypes.func,
  currentQuestionId: PropTypes.string,
};

Timeline.defaultProps = {
  className: '',
  onNext: () => {},
  currentQuestionId: null,
};

export default Timeline;
