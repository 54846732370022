import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PDF_FIELD_TYPE } from 'app-constants';
import { FormStateManagerProps } from 'pages/FormProcess/FormBuilder/FormStateManager';
import {
  AddFormCommentType,
  CommentUsersType,
  EditFormCommentType,
  FormCommentType,
  FormDocumentType,
  ResponseType,
  FieldInfoType,
  FormCommentThread,
} from 'types';
import { updateFormProcessDocumentResponseEffect } from 'store/effects/formProcess';
import {
  declineSignatureAnonEffect,
  declineSignatureAuthEffect,
  fillSmartFormAnonEffect,
  fillSmartFormAuthEffect,
  getSignatureAnonEffect,
  getSignatureAuthEffect,
  saveSignatureAnonEffect,
  saveSignatureAuthEffect,
} from 'store/effects/formProcess/editForm';
import { SignForm } from 'components/SmartForm/SignForm';
import { SignatureResult } from 'pages/FormBuilder/components';
import { successNotification } from 'components-antd';

interface SignFormManagerProps extends FormStateManagerProps {
  formDocument: FormDocumentType;
  declined?: boolean;
  handleSignFormFinish: () => void;
  handleUnlock?: () => void;
  handleExit?: () => void;
  handleEdit?: () => void;
  isEditSign?: boolean;
  isCreatorEdit?: boolean;
  refresh: () => void;
  commentUsers?: CommentUsersType;
  formComment?: FormCommentType;
  setFieldInfo: (fieldInfo: FieldInfoType) => void;
  handleGetFormComments: () => void;
  handleAddFormComment: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
  setCommentPanelOpen: Dispatch<SetStateAction<boolean>> | null;
  formComments: FormCommentThread[];
}

export const SignFormManager = ({
  formDocument,
  authData,
  anonData,
  handleSignFormFinish,
  declined,
  handleUnlock,
  handleExit,
  handleEdit,
  isEditSign,
  isCreatorEdit,
  refresh,
  commentUsers,
  formComment,
  setFieldInfo,
  handleGetFormComments,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  setCommentPanelOpen,
  formComments,
}: SignFormManagerProps) => {
  const dispatch = useDispatch();

  const [declineBtnLoading, setDeclineBtnLoading] = useState<boolean>(false);
  const [declineMessage, setDeclineMessage] = useState<string>();

  const {
    questions = [],
    link,
    documentName,
    clientName,
    answers,
    address,
    strikeThrough = {},
  } = formDocument;

  const getSignQuestions = () =>
    !isEditSign
      ? questions?.filter((ques) => ques.FieldType === PDF_FIELD_TYPE.PDFSignature)
      : questions;

  const handleDecline = () => {
    setDeclineBtnLoading(true);

    if (authData) {
      dispatch(
        declineSignatureAuthEffect({ ...authData, message: declineMessage }, (err) => {
          if (!err) {
            successNotification({
              message: 'Form has been declined.',
              placement: 'top',
            });
            handleExit?.();
          }
        }),
      );
    } else if (anonData) {
      dispatch(
        declineSignatureAnonEffect(
          { type: anonData.type, token: anonData.token, message: declineMessage },
          (err) => {
            if (!err) {
              refresh();
            }
          },
        ),
      );
    }
  };

  const handleResponse = (updatedResponse: Partial<ResponseType>) => {
    dispatch(updateFormProcessDocumentResponseEffect(updatedResponse));

    const response = {
      UUID: updatedResponse.UUID,
      answer: updatedResponse.Answer,
    };

    if (authData) {
      dispatch(
        fillSmartFormAuthEffect({
          formProcessPublicId: authData.formProcessPublicId,
          formDocumentPublicId: authData.formDocumentPublicId,
          ...response,
        }),
      );
    } else if (anonData) {
      dispatch(
        fillSmartFormAnonEffect({
          Token: anonData.token,
          type: anonData.type,
          ...response,
        }),
      );
    }
  };

  const handleSaveSignature = (signature: SignatureResult) => {
    if (authData) {
      dispatch(
        saveSignatureAuthEffect(signature, (err) => {
          if (!err) {
            dispatch(getSignatureAuthEffect());
          }
        }),
      );
    } else if (anonData) {
      const { token } = anonData;
      dispatch(
        saveSignatureAnonEffect({ token, ...signature }, (err) => {
          if (!err) {
            dispatch(getSignatureAnonEffect({ token }));
          }
        }),
      );
    }
  };

  useEffect(() => {
    if (authData) {
      dispatch(getSignatureAuthEffect());
    } else if (anonData) {
      const { token } = anonData;
      dispatch(getSignatureAnonEffect({ token }));
    }
  }, []);

  return (
    <SignForm
      declined={declined}
      handleResponse={handleResponse}
      questions={getSignQuestions()}
      responses={answers}
      handleDone={handleSignFormFinish}
      link={link}
      clientName={clientName}
      address={address}
      pdfName={documentName}
      declineLoading={declineBtnLoading}
      setDeclineMessage={setDeclineMessage}
      handleDecline={handleDecline}
      handleUnlock={handleUnlock}
      handleExit={handleExit}
      handleSaveSignature={handleSaveSignature}
      handleEdit={handleEdit}
      isCreatorEdit={isCreatorEdit}
      commentUsers={commentUsers}
      formComment={formComment}
      setFieldInfo={setFieldInfo}
      handleGetFormComments={handleGetFormComments}
      handleAddFormComment={handleAddFormComment}
      handleEditFormComment={handleEditFormComment}
      handleDeleteFormComment={handleDeleteFormComment}
      handleToggleResolveThread={handleToggleResolveThread}
      setCommentPanelOpen={setCommentPanelOpen}
      strikeThrough={strikeThrough}
      formCommentThreads={formComments}
    />
  );
};
