import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash-es';

import { openTransactionParticipantDrawerEffect } from 'store/effects/drawers/transactionParticipant';
import { Avatar, ConfirmModal } from 'components';
import Icons from 'pages/Workshop/Transactions/TransactionOverview/Icons';
import { Button } from 'pages/Workshop/Transactions/components/Button';

import { convertNameToAvatarPlaceholder } from 'helpers';
import { TransactionUserRoleMap } from 'settings/constants/transaction';

import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { getUserId, getUserRoleSelector } from 'store/selectors/user';
import { Role as UserRole } from 'app-constants';
import { useOutsideClick } from 'hooks';
import { updateTransactionTaskAndOfferThreadsEffect } from 'store/effects/sockets/threads';
import {
  removeTransactionParticipantEffect,
  resendParticipantInviteEffect,
} from 'store/effects/transactions';
import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { useParams } from 'react-router-dom';
import { TransactionAccess } from '../TransactionAccess';
import { Resend } from 'components/Icons';
import { showSuccessMessage } from 'helpers/success';

const Participant = (props) => {
  const {
    className,
    Id,
    AvatarUrl,
    FirstName,
    LastName,
    Role,
    Email,
    InvitationStatus,
    participantsInContacts,
  } = props;
  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRoleSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const dispatch = useDispatch();
  const [openOptions, setOpenOptions] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [isPendingDelete, setIsPendingDelete] = useState(false);
  const buttonRef = useRef(null);
  const optionsRef = useRef(null);
  const isProject = useIsProjectRoute();
  const params = useParams();

  const menuClick = (e) => {
    e.stopPropagation();
    setOpenOptions(!openOptions);
  };

  const onSubmitConfirmModal = () => {
    const config = { transactionId: params?.id, participantId: Id };
    const options = { silent: true };
    setIsPendingDelete(true);
    dispatch(
      removeTransactionParticipantEffect(config, options, (err, response) => {
        if (!err) {
          const participants = get(response, 'data.result.Participants');
          dispatch(updateTransactionTaskAndOfferThreadsEffect({ participants }));
        }
        setIsPendingDelete(false);
      }),
    );
  };

  const onClick = () => {
    dispatch(
      openTransactionParticipantDrawerEffect({
        open: true,
        params: { participantId: Id },
      }),
    );
  };

  const onDeleteClick = () => {
    setOpenConfirmModal(true);
    setOpenOptions(false);
  };

  const name = `${FirstName} ${LastName}`;
  useOutsideClick([buttonRef, optionsRef], () => setOpenOptions(false));

  const onResendInvite = () => {
    const transactionId = params.id;
    const participantId = Id;
    const cfg = {
      transactionId,
      participantId,
      Email,
    };
    dispatch(
      resendParticipantInviteEffect(cfg, {}, () => {
        setOpenOptions(false);
        showSuccessMessage('Invite Resent');
      }),
    );
  };

  return (
    <div
      testid="participant_item"
      className={classNames(styles.participant, { [styles.hoverEffect]: openOptions }, className)}
    >
      <Avatar
        avatarClassName={styles.avatar}
        src={AvatarUrl}
        placeholder={convertNameToAvatarPlaceholder(name)}
      />
      <div className={styles.content}>
        <div className={styles.names}>
          <div testid="name" className={styles.name}>
            {name}
          </div>
          <div testid="title" className={styles.role}>
            {TransactionUserRoleMap[Role?.Title] || Role?.Title}
          </div>
          <div testid="title" className={styles.email}>
            {Email}
          </div>
        </div>
        <div className={styles.rightContainer}>
          {fullAccess && (
            <TransactionAccess
              participant={{ ...props }}
              participantsInContacts={participantsInContacts}
            />
          )}
          {userRole == UserRole.Agent && (
            <div className={styles.options}>
              <div className={styles.optionBtn}>
                <Button
                  className={classNames(styles.btn, { [styles.hoverEffect]: openOptions })}
                  variant="link"
                  icon={<Icons variant={Icons.OPTIONS} />}
                  onClick={menuClick}
                  ref={buttonRef}
                />
                {openOptions && (
                  <div ref={optionsRef} className={styles.menuOptions}>
                    <ul>
                      <li className={styles.item} onClick={onClick}>
                        <div className={styles.icon}>
                          <Icons variant={Icons.USER} />
                        </div>
                        View Profile
                      </li>

                      {fullAccess && InvitationStatus === 'Pending' ? (
                        <li className={styles.item} onClick={onResendInvite}>
                          <div className={styles.icon}>
                            <Resend className={styles.participantResend} />
                          </div>
                          Resend Invite
                        </li>
                      ) : (
                        <></>
                      )}

                      {fullAccess && Id !== userId && (
                        <li className={styles.item} onClick={onDeleteClick}>
                          <div className={styles.icon}>
                            <Icons variant={Icons.DELETE} />
                          </div>
                          Remove from {isProject ? 'Project' : 'Transaction'}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Delete Confirmation Modal */}
      <ConfirmModal
        title={`Are you sure you want to remove ${name} from this transaction?`}
        isOpen={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onSubmit={onSubmitConfirmModal}
        isPending={isPendingDelete}
        icon
      />
    </div>
  );
};

Participant.propTypes = {
  className: PropTypes.string,
  Role: PropTypes.shape({
    Title: PropTypes.string,
  }),
  AvatarUrl: PropTypes.string,
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  participantsInContacts: PropTypes.bool,
};

Participant.defaultProps = {
  className: '',
  Role: {},
  FirstName: '',
  LastName: '',
  AvatarUrl: null,
  participantsInContacts: false,
};

export default Participant;
