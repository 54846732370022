import { TEAM_BASIC } from 'settings/constants/roles';
import { LISTED_STATUS, transactionStatuses } from 'settings/constants/transactionStatus';

const listedDropdownList = [
  LISTED_STATUS.PreListing,
  LISTED_STATUS.ComingSoon,
  LISTED_STATUS.ActiveListing,
  LISTED_STATUS.OnHold,
  transactionStatuses.ClearToClose,
];

const underContactDropdownList = ['Listings', 'Purchases', 'Dual'];

export enum GeneralLedgerKeys {
  GCI = 'Commission Income',
  AgentPayment = 'Agent Payment',
  TeamPayment = 'Team Payment',
  BrokeragePayment = 'Brokerage Payment',
  Fees = 'Fees',
  FeeSummary = 'FeeSummary',
  OtherSplits = 'Other Splits',
  OtherNetIncomes = 'Other Net Income',
  CustomAccountPayment = 'Custom Account Payment',
}

export const hideBreakdownMenu = (agentRole, teamStats) =>
  agentRole !== TEAM_BASIC && !teamStats?.userId;
