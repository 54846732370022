import classNames from 'classnames';
import { InputLabel } from 'components';
import { ClientRole, AssignToInput } from 'components/Transactions';
import { TaskEditableFormValuesType, TaskEditableFormValuesKeys } from 'types/transactionTasks';

import styles from './styles.module.scss';
import { TitleInput } from 'components/ClientsDrawer/InviteTeamMember/TitleInput';

interface CcToProps {
  values: TaskEditableFormValuesType;
  onChangeField: (val: any, fieldName: keyof typeof TaskEditableFormValuesKeys) => void;
  isTemplate: boolean;
  className?: string | null;
  isAssignTask?: boolean | null;
  isViewMode?: boolean | null;
  showOptional?: boolean | null;
  isCampaignTask?: boolean;
}

export const CcTo = ({
  isTemplate,
  values,
  onChangeField,
  isAssignTask,
  className,
  isViewMode,
  showOptional,
  isCampaignTask,
}: CcToProps) => {
  return (
    <div className={classNames(styles.ccTo, className)}>
      <div className={styles.fieldWrapper}>
        {isTemplate ? (
          <>
            <InputLabel label="CC" className={styles.label} showOptional={showOptional} />
            {isCampaignTask ? (
              <TitleInput
                hideTitle
                values={values?.CcRoles}
                setFieldValue={(key, val) => {
                  if (key === 'title' && Array.isArray(val)) {
                    onChangeField(
                      val.map((v) => v.name),
                      'CcRoles',
                    );
                  }
                }}
                multiple={true}
              />
            ) : (
              <ClientRole
                isSearchIcon={false}
                multiselect={true}
                onChange={(e) => {
                  onChangeField(
                    e.target.value.map((v) => v.value),
                    'CcRoles',
                  );
                }}
                value={values?.CcRoles}
                closeOnSelect={false}
                isViewMode={isViewMode}
              />
            )}
          </>
        ) : (
          <AssignToInput
            isCampaignTask={isCampaignTask}
            className={styles.ccToInputLabel}
            wrapperClassName={styles.ccToWrapper}
            label="CC"
            placeholder="Select participant(s)"
            showIcon={false}
            values={values?.CcList}
            onChange={(e, val) => onChangeField(val, 'CcList')}
            isAssignTask={isAssignTask}
            isArrowIcon
            showRole
          />
        )}
      </div>
    </div>
  );
};
