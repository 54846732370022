import { MapDrawControls } from 'pages/Properties/components/MapDrawControls/MapDrawControls';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { MAP_VIEWS } from 'settings/constants/mapView';
import { getFeedViewTypeSelector } from 'store/selectors/feed';
import {
  toggleFeedMapDrawingModeAction,
  cleanFeedDrawnPolygonAction,
  setFeedDrawnPolygonAction,
} from 'store/actions/feed';
import { getFeedDrawnPolygonSelector, getFeedIsMapDrawingModeSelector } from 'store/selectors/feed';

interface IMapDrawControlsFeedWrapper {
  multipleDraw: boolean;
  setPolygonLocations?: any;
  polygonLocations?: any;
  showDrawHint?: boolean;
  setShowDrawHint?: any;
}
export const MapDrawControlsFeedWrapper: FC<IMapDrawControlsFeedWrapper> = ({
  multipleDraw,
  setPolygonLocations,
  polygonLocations,
  showDrawHint,
  setShowDrawHint,
}) => {
  const viewType = useSelector(getFeedViewTypeSelector);

  if (viewType.view === MAP_VIEWS.LIST) {
    return null;
  }

  return (
    <MapDrawControls
      getDrawnPolygonSelectorFn={getFeedDrawnPolygonSelector}
      getIsMapDrawingModeSelectorFn={getFeedIsMapDrawingModeSelector}
      setDrawnPolygonDispatchFn={setFeedDrawnPolygonAction}
      toggleMapDrawingModeDispatchFn={toggleFeedMapDrawingModeAction}
      cleanDrawnPolygonDispatchFn={cleanFeedDrawnPolygonAction}
      multipleDraw={multipleDraw}
      setPolygonLocations={setPolygonLocations}
      polygonLocations={polygonLocations}
      showDrawHint={showDrawHint}
      setShowDrawHint={setShowDrawHint}
    />
  );
};
