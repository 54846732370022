import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

import classNames from 'classnames';
import Suggestion from './Suggestion';
import Input from './Input';
import Values from '../Values';

import styles from './styles.module.scss';
import { getAddressAutocomplete, getAddressVerification } from 'api/address';
import { CASS_ADDRESS } from 'settings/constants/locations';
import { isState } from 'helpers';

const autocompleteRequestSupportedTypes = [
  'geocode',
  'address',
  'establishment',
  '(regions)',
  '(cities)',
  'administrative_area_level_1',
  'administrative_area_level_2',
];

const GeoCoderComponent = forwardRef((props, ref) => {
  const {
    className,
    onResult,
    placeholder,
    countries,
    types,
    currentValue,
    onDeleteValue,
    inputClassName,
    inputIconClassName,
    onChange,
    variant,
    showPrefixIcon,
    dropdownClassName,
    searchWrapperClassName,
    menuTop,
    multiple,
    shouldFilterStateLevelResults,
    shouldFilterPlaces,
    shouldFilterCityLevelResults,
    shouldFilterStreetLevelResults,
    updateInput,
    resetUpdate,
    allowedSmartyAddress,
    loaderClassName,
    showOnlyCassAddresses,
    showOnlyCityAndState,
  } = props;
  const [localValue, setLocalValue] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [cassAddressSuggestions, setcassAddressSuggestion] = useState([]);
  const [keydownPressed, setKeyDownPressed] = useState(false);

  useEffect(() => {
    if (ref.current && updateInput?.length) {
      const input = ref.current.querySelector('input');
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      ).set;
      nativeInputValueSetter.call(input, updateInput);
      const event = new Event('input', { bubbles: true });
      input.dispatchEvent(event);
    }
  }, [updateInput]);

  const onSelectHandler = useCallback(
    (placeName, placeId, placeInfo) => {
      const placeNameExists = currentValue.some(
        (currentVal) => currentVal?.PlaceName && currentVal.PlaceName === placeInfo?.street_line,
      );
      if (placeNameExists) {
        setcassAddressSuggestion([]);
        return;
      }
      if (placeId) {
        geocodeByPlaceId(placeId).then((res) => {
          getLatLng(res[0]).then((coordinates) => {
            onResult({ result: res, placeName, coordinates, placeInfo, type: 'google' });
          });
        });
        setcassAddressSuggestion([]);
      } else {
        getAddressVerification(placeInfo).then((res) => {
          let coordinates = {
            lat: res?.data?.result[0]?.metadata?.latitude,
            lng: res?.data?.result[0]?.metadata?.longitude,
          };
          onResult({
            result: res?.data?.result[0],
            placeName,
            coordinates,
            placeInfo,
            type: CASS_ADDRESS,
          });
        });
        setcassAddressSuggestion([]);
      }
      setLocalValue('');
      setIsPending(false);
    },
    [setIsPending, onResult, setLocalValue, cassAddressSuggestions],
  );

  const selectFirst = useCallback(
    ({ placeName, placeId, placeInfo }) => {
      setIsPending(true);
      geocodeByPlaceId(placeId).then((res) => {
        getLatLng(res[0]).then((coordinates) => {
          onResult({ result: res, placeName, coordinates, placeInfo });
          setLocalValue('');
          setIsPending(false);
        });
      });
    },
    [setIsPending, onResult, setLocalValue],
  );

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      setKeyDownPressed(true);
    } else {
      setKeyDownPressed(false);
    }
  };

  const onChangeHandler = useCallback(
    async (val) => {
      onChange(val);
      setLocalValue(val);
    },
    [onChange, setLocalValue],
  );

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (!localValue) {
        setcassAddressSuggestion([]);
        return;
      }
      if (allowedSmartyAddress && !keydownPressed) {
        try {
          let cassAddress = await getAddressAutocomplete(localValue);
          setcassAddressSuggestion(cassAddress?.result?.suggestions);
        } catch (error) {
          setcassAddressSuggestion([]);
        }
      }
    }, 1000);
    return () => clearTimeout(getData);
  }, [localValue]);

  const searchTypes = useMemo(
    () => (types || []).filter((type) => autocompleteRequestSupportedTypes.includes(type)),
    [types],
  );
  const searchOptions = useMemo(
    () => ({
      componentRestrictions: { country: countries },
      // Google Autocomplete allows to specify only one type
      language: 'en',
      types: searchTypes?.length ? [searchTypes[0]] : undefined,
    }),
    [countries, searchTypes],
  );

  const getSuggestions = (cassS, googleS) => {
    let values = [];
    values = googleS.filter((suggestion) => suggestion.types.includes('postal_code'));
    if (cassS.length) {
      values = [...values, ...cassS];
    } else {
      values = [...values, ...googleS];
    }
    return values;
  };

  const renderSuggestions = (values, getSuggestionItemProps) => {
    if (!values?.length) return;
    let suggestions = values;
    if (!cassAddressSuggestions?.length) {
      if (shouldFilterStreetLevelResults) {
        suggestions = suggestions.filter((suggestion) => !suggestion.types.includes('premise'));
      }

      if (shouldFilterPlaces) {
        suggestions = suggestions.filter(
          (suggestion) => !suggestion.types.includes('point_of_interest'),
        );
      }

      if (shouldFilterCityLevelResults || shouldFilterStateLevelResults) {
        suggestions = suggestions.filter(
          (suggestion) =>
            !suggestion.types.includes('point_of_interest') &&
            (shouldFilterCityLevelResults
              ? !(
                  suggestion.types.includes('administrative_area_level_1') ||
                  ['geocode', 'political', 'locality'].every((type) =>
                    suggestion.types.includes(type),
                  )
                )
              : !suggestion.types.includes('administrative_area_level_1')),
        );
      }

      //If State ONLY Request, explicitly filter.  Other languages are included otherwise.
      if (types && types.length === 1 && types[0] === 'administrative_area_level_1') {
        suggestions = suggestions.filter((suggestion) => isState(suggestion.terms[0]?.value));
      }

      if (showOnlyCityAndState) {
        suggestions = suggestions.filter(
          (suggestion) =>
            (suggestion.types.includes('administrative_area_level_1') &&
              isState(suggestion?.terms?.[0]?.value)) ||
            (suggestion.types.includes('locality') &&
              !suggestion?.terms?.[0]?.value?.includes?.('City')),
        );
      }
    }

    return (
      <div testid="suggestions" className={classNames('suggestions', styles.suggestions)}>
        <div testid="searchResults" className={classNames('searchResults', styles.searchResults)}>
          Search Results
        </div>
        {suggestions.map((suggestion) => (
          <Suggestion
            key={suggestion.placeId}
            className={classNames('suggestionItem', styles.suggestion)}
            getSuggestionItemProps={getSuggestionItemProps}
            suggestion={suggestion}
            formatStateSuggestion={
              types?.includes('administrative_area_level_1') ||
              suggestion.types?.includes('postal_code')
            }
          />
        ))}
      </div>
    );
  };

  return (
    <div className={classNames(styles.placesAutocompleteWrapper, searchWrapperClassName)}>
      <div
        testid="geocoder"
        ref={ref}
        className={classNames(styles.wrapper, styles[variant], className)}
      >
        <PlacesAutocomplete
          value={localValue}
          debounce={1000}
          onChange={onChangeHandler}
          onSelect={onSelectHandler}
          searchOptions={searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
            if (suggestions?.length && updateInput?.length) {
              onSelectHandler(suggestions[0]?.description, suggestions[0]?.placeId, suggestions[0]);
              resetUpdate();
            }

            return (
              <>
                <Input
                  getInputProps={getInputProps}
                  className={classNames(styles.baseLocationInput, inputClassName)}
                  iconClassName={classNames(inputIconClassName)}
                  placeholder={placeholder}
                  loading={loading || isPending}
                  variant={variant}
                  showPrefixIcon={showPrefixIcon}
                  onKeyDown={handleKeyDown}
                  loaderClassName={loaderClassName}
                />
                <div
                  className={classNames(
                    styles.dropdownWrapper,
                    { [styles.menuTop]: menuTop },
                    dropdownClassName,
                  )}
                >
                  <Values
                    values={currentValue}
                    onDelete={onDeleteValue}
                    className={styles.values}
                    displayedValuesLimit={11}
                    multiple={false}
                    groupSize={2}
                  />
                  {cassAddressSuggestions?.length || showOnlyCassAddresses
                    ? renderSuggestions(
                        showOnlyCassAddresses
                          ? cassAddressSuggestions
                          : getSuggestions(cassAddressSuggestions, suggestions),
                        getSuggestionItemProps,
                      )
                    : renderSuggestions(suggestions, getSuggestionItemProps)}
                </div>
              </>
            );
          }}
        </PlacesAutocomplete>
      </div>
    </div>
  );
});

GeoCoderComponent.propTypes = {
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  searchWrapperClassName: PropTypes.string,
  onResult: PropTypes.func,
  placeholder: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  variant: PropTypes.string,
  showPrefixIcon: PropTypes.bool,
  types: PropTypes.arrayOf(PropTypes.string),
  currentValue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      placeName: PropTypes.string,
    }),
  ).isRequired,
  onDeleteValue: PropTypes.func.isRequired,
  menuTop: PropTypes.bool,
  inputClassName: PropTypes.string,
  inputIconClassName: PropTypes.string,
  multiple: PropTypes.bool,
  allowedSmartyAddress: PropTypes.bool,
  loaderClassName: PropTypes.string,
  showOnlyCassAddresses: PropTypes.bool,
};

GeoCoderComponent.defaultProps = {
  className: '',
  dropdownClassName: '',
  searchWrapperClassName: '',
  onResult: () => {},
  placeholder: 'Enter address or location name',
  onChange: () => {},
  countries: ['us'],
  variant: 'lightFull',
  showPrefixIcon: false,
  types: [],
  menuTop: false,
  inputClassName: '',
  inputIconClassName: '',
  multiple: false,
  allowedSmartyAddress: false,
  loaderClassName: '',
  showOnlyCassAddresses: false,
};

export default GeoCoderComponent;
