import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, reduce, trim } from 'lodash-es';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Col } from 'antd';

import { setMultipleRequestQuotesEffect } from 'store/effects/quotes';
import { convertNameToAvatarPlaceholder } from 'helpers/formatters';
import { Avatar, Button, Checkbox } from 'components';
import { PartnerProfile } from 'pages/ServicesCategoryPartner/components/PartnerProfile';
import { getRequestMultipleQuotesModeSelector } from 'store/selectors/requestQuote';
import { GreenCheckMark } from 'components/Icons';
import { MessageIcon } from '../AgentPartnersList/icons';
import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { getIfExistThread } from 'helpers';
import { getUserId } from 'store/selectors/user';
import { getThreadsListSelector } from 'store/selectors/sockets/threads';
import { getPartnerBusinessNameAndId } from 'store/selectors/singlePartner';
import { SOCKET_THREAD_TYPES } from 'settings/constants/sockets';
import {
  changeMessagesDrawerTypeAction,
  openMessagesDrawerAction,
} from 'store/actions/drawers/messages';
import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { THIRD_PARTY } from 'settings/constants/roles';

import styles from './styles.module.scss';

export const getFormattedLocationList = (area) =>
  `${area?.PlaceName.replace(', USA', '').replace(', Canada', '') || ''}; `;

export const renderPartnerLocationsList = (partner) => {
  const cutOffValue = 5;
  if (partner.AreasOfOperation.length > cutOffValue) {
    const locationList = partner.AreasOfOperation.slice(0, cutOffValue - 1).map((area) =>
      getFormattedLocationList(area),
    );
    locationList.push(`+${partner.AreasOfOperation.length - cutOffValue} more`);
    return locationList;
  } else if (partner.AreasOfOperation.length === 1) {
    return partner.AreasOfOperation.map((area) => getFormattedLocationList(area));
  } else {
    return partner.AreasOfOperation.map((area) => getFormattedLocationList(area));
  }
};

const PartnerListItem = ({ partner, agentId, isClient, onSelectPartnerFromClient }) => {
  const multiple = useSelector(getRequestMultipleQuotesModeSelector);
  const dispatch = useDispatch();

  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [viewPartnerDetails, setViewPartnerDetails] = useState(false);

  const { value: recommendedBy } = useMemo(
    () =>
      reduce(
        partner?.RecommendingAgents || [],
        (acc, { Id, FirstName, LastName, AvatarUrl }) => {
          if (Id === agentId) {
            acc.value = {
              name: trim(`${FirstName} ${LastName}`),
              avatar: AvatarUrl,
              placeholder: convertNameToAvatarPlaceholder(trim(`${FirstName} ${LastName}`)),
            };
          }
          return acc;
        },
        {},
      ),
    [partner?.RecommendingAgents, agentId],
  );

  const onSelectPartner = (event, val, checked) => {
    const newPartners = cloneDeep(multiple?.partners) || [];
    if (checked) {
      newPartners.push(partner);
    } else {
      const removedPartnerIndex = newPartners.findIndex((part) => part?.Id === partner?.Id);

      if (removedPartnerIndex !== -1) {
        newPartners.splice(removedPartnerIndex, 1);
      }
    }
    dispatch(setMultipleRequestQuotesEffect({ partners: newPartners }));
  };

  const getIsPartnerChecked = () =>
    (multiple?.partners || []).some((part) => part?.Id === partner?.Id);

  const { id } = useSelector(getPartnerBusinessNameAndId);

  const isHint = !multiple?.modeEnable ? { 'data-rh': true } : {};

  const { categoryId: partnerCategoryId } = useParams();
  const userId = useSelector(getUserId);
  const threads = useSelector(getThreadsListSelector);
  const initiateSendingMessageToPartner = useCallback(
    (partner) => {
      const thread = getIfExistThread(
        threads,
        [id, userId],
        ({ Type }) => Type === SOCKET_THREAD_TYPES.CHAT,
      );

      dispatch(openMessagesDrawerAction(true));

      if (!thread) {
        const firstName = partner?.ThirdParty?.User?.FirstName;
        const lastName = partner?.ThirdParty?.User?.LastName;

        dispatch(
          changeMessagesDrawerTypeAction({
            type: DRAWER_MESSAGES_TYPES.NEW_MESSAGE,
            params: {
              threadId: thread?.Id || null,
              participants: [
                {
                  id: partner?.Id,
                  name: `${firstName} ${lastName || ''} (${partner?.BusinessName || ''})`,
                  value: partner?.Id,
                  role: THIRD_PARTY,
                  avatarUrl: partner?.LogoUrl,
                  firstName: partner?.FirstName,
                  lastName: partner?.LastName,
                },
              ],
            },
          }),
        );
      } else {
        dispatch(
          changeMessagesDrawerTypeAction({
            type: DRAWER_MESSAGES_TYPES.CHAT,
            params: {
              threadId: thread.Id,
            },
          }),
        );
      }
    },
    [dispatch, threads, id, userId],
  );

  const handleMessageButtonClick = useEmailVerificationWall(
    ActionToInterrupt.MESSAGE_TO_PARTNER,
    initiateSendingMessageToPartner,
    {
      categoryId: partnerCategoryId,
    },
    isClient,
  );

  return (
    <>
      <PartnerProfile
        partnerId={selectedPartnerId}
        open={viewPartnerDetails}
        setModalVisible={setViewPartnerDetails}
        onCancel={() => {
          setViewPartnerDetails(false);
          setSelectedPartnerId(null);
        }}
      />
      <Col
        span={24}
        testid="partner_item"
        className={styles.card}
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
          paddingBottom: '10px',
        }}
      >
        <div className={styles.cardContent}>
          {recommendedBy ? (
            <div
              {...isHint}
              data-servicerecommend={JSON.stringify(recommendedBy)}
              className={styles.recommendStar}
              testid="recommended_star"
            >
              {!multiple?.modeEnable ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0016 17.7505L5.82963 20.9955L7.00863 14.1225L2.01562 9.25549L8.91563 8.25349L12.0016 2.00049L15.0876 8.25349L21.9876 9.25549L16.9946 14.1225L18.1736 20.9955L12.0016 17.7505Z"
                    fill="#FF576D"
                    stroke="#FF576D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : !isClient ? (
                <Checkbox
                  testid="check_partner"
                  onChange={onSelectPartner}
                  checked={getIsPartnerChecked()}
                />
              ) : null}
            </div>
          ) : (
            <div className={styles.multipleCheckbox}>
              {multiple?.modeEnable && !isClient && (
                <Checkbox
                  testid="check_partner"
                  onChange={onSelectPartner}
                  checked={getIsPartnerChecked()}
                />
              )}
            </div>
          )}
          <div
            className={styles.infoWrapper}
            onClick={() => {
              setSelectedPartnerId(partner?.Id);
              setViewPartnerDetails(true);
            }}
          >
            <div testid="avatar" className={styles.cardImage}>
              <Avatar
                src={partner?.LogoUrl}
                placeholder={convertNameToAvatarPlaceholder(trim(`${partner?.BusinessName}`))}
              />
            </div>
            <div className={styles.details}>
              <div testid="business_name" className={styles.cardTitle}>
                {partner?.BusinessName || 'Unknown name'}
              </div>
              <div className={styles.cardDescription}>
                {partner?.AreasOfOperation?.length > 0 ? (
                  <div className={styles.partnerInfoDesc}>
                    <div className={styles.descTitle}>
                      <GreenCheckMark width={16} height={16} /> Serves:
                    </div>
                    <div className={styles.itemsWrapper}>
                      <span>{renderPartnerLocationsList(partner)}</span>
                    </div>
                  </div>
                ) : null}
                <div testid="description" className={styles.partnerInfoDesc}>
                  <div className={styles.descTitle}>
                    <GreenCheckMark width={16} height={16} /> Offers:
                  </div>
                  <div className={classNames(styles.itemsWrapper, styles.servicesOffered)}>
                    {partner?.PartnerTags?.map(({ Name }) => Name).join(', ') || 'General Services'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.cardButtons}>
            {!multiple.modeEnable && (
              <Button
                className={styles.messages}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMessageButtonClick(partner);
                }}
                title="Message"
                iconLeft={<MessageIcon />}
                testid="message_button"
              />
            )}
            {isClient && multiple.modeEnable && (
              <Checkbox
                testid="check_partner"
                onChange={() => onSelectPartnerFromClient(partner)}
                checked={getIsPartnerChecked()}
              />
            )}
          </div>
        </div>
      </Col>
    </>
  );
};

PartnerListItem.propTypes = {
  partner: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    BusinessName: PropTypes.string,
    LogoUrl: PropTypes.string,
    RecommendingAgents: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  agentId: PropTypes.number,
  isClient: PropTypes.bool,
};

PartnerListItem.defaultProps = {
  agentId: null,
  isClient: false,
};

export default PartnerListItem;
