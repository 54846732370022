import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  appOpenModalEffect,
  getAgentClientsWithFavoritesCountEffect,
  getClientFavoritesPropsEffect,
  resetClientFavoritesPropsEffect,
} from 'store/effects';
import { getAgentClientsSelector } from 'store/selectors/feed';

import { Avatar, Input, Spinner } from 'components';
import { ArrowRight } from 'components/Icons';
import { getInitials } from 'utils';

import styles from './styles.module.scss';

const ClientFavItem = ({ name = '', favortiesCount = 0, avatarUrl, onClick }) => (
  <div className={styles.clientFavItemWrapper} onClick={onClick}>
    <div className={styles.container}>
      <Avatar avatarClassName={styles.avatar} placeholder={getInitials(name)} src={avatarUrl} />
      <div className={styles.textInfo}>
        <span className={styles.name}>{name}</span>
        <span className={styles.count}>
          <span className={styles.bold}>{favortiesCount}&nbsp;</span>Favorites
        </span>
      </div>
    </div>
    <ArrowRight className={styles.arrowRight} />
  </div>
);

export const ClientFavorites = ({ setIsOpenSearchModal }) => {
  const dispatch = useDispatch();
  const agentClients = useSelector(getAgentClientsSelector);
  const isPending = agentClients.state === 'pending';
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    dispatch(getAgentClientsWithFavoritesCountEffect({}));
    dispatch(resetClientFavoritesPropsEffect());
  }, []);

  const fetchClientFavorites = (id) => {
    dispatch(getClientFavoritesPropsEffect({ userId: id }));
    dispatch(appOpenModalEffect({ id: null, open: false }));
    setIsOpenSearchModal(false);
  };

  const agentClientsWithSearch = useMemo(() => {
    return agentClients?.data?.filter((client) => {
      const fullName = `${client.FirstName} ${client.LastName}`.toLowerCase();
      return fullName.includes(searchValue.toLowerCase());
    });
  }, [searchValue, agentClients?.data]);

  return (
    <div className={styles.clientFavorites}>
      {isPending ? (
        <div className={styles.loaderContainer}>
          <Spinner loaderClassName={styles.loader} />
        </div>
      ) : (
        <div className={styles.clientFavoritesContainer}>
          <Input
            className={styles.searchInput}
            placeholder="Search clients..."
            type="search"
            variant={Input.LIGHT_ROUNDED}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {agentClients?.data &&
            agentClientsWithSearch?.map(
              ({ Id, TotalFavorites, FirstName, LastName, AvatarUrl }, idx) =>
                searchValue !== '' || TotalFavorites >= 1 ? (
                  <ClientFavItem
                    key={idx}
                    name={`${FirstName} ${LastName}`}
                    favortiesCount={TotalFavorites}
                    avatarUrl={AvatarUrl}
                    onClick={() => fetchClientFavorites(Id)}
                  />
                ) : null,
            )}
        </div>
      )}
    </div>
  );
};
