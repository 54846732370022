import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import Inner from './Inner';

import styles from './styles.module.scss';

const Dropzone = (props) => {
  const { className, onDrop, activeDragText, inner, noClick, onChooseFile, accept, multiple } =
    props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick,
    accept,
    multiple: multiple,
  });

  return (
    <div testid="dropzone" className={classNames(styles.dropzone, className)}>
      <div
        {...getRootProps()}
        className={classNames(styles.wrapper, { [styles.noClick]: noClick })}
      >
        <div className={styles.innerHolder}>
          {isDragActive ? (
            <div className={styles.text}>{activeDragText}</div>
          ) : (
            <div>{inner || <Inner multiple={multiple} onChooseFile={onChooseFile} />}</div>
          )}
        </div>
        <input testid="upload_input" {...getInputProps()} />
      </div>
    </div>
  );
};

Dropzone.propTypes = {
  className: PropTypes.string,
  onDrop: PropTypes.func,
  activeDragText: PropTypes.string,
  inner: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.node]),
  noClick: PropTypes.bool,
  multiple: PropTypes.bool,
  onChooseFile: PropTypes.func,
};

Dropzone.defaultProps = {
  className: '',
  onDrop: () => {},
  activeDragText: 'Drop the files here ...',
  inner: undefined,
  noClick: true,
  multiple: true,
  onChooseFile: () => {},
};

export default Dropzone;
