import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Checkbox, Avatar } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { Options } from './Options';
import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';

import styles from './styles.module.scss';

export const Client = (props) => {
  const {
    className,
    value,
    selected,
    onChange,
    setShowModal,
    avatarPlaceholder,
    label,
    isChecked,
    multiSelect,
  } = props;
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);

  const name = `${value?.FirstName}${value?.LastName ? ' ' + value.LastName : ''}`;

  const renderCount = (value, type) => {
    if (!value) return;

    return (
      <>
        <div className={styles.divider}></div>
        <p>
          <span>{value}</span> {type}
        </p>
      </>
    );
  };

  return (
    <div
      testid="client_item"
      className={classNames(styles.client, { [styles.multiSelect]: multiSelect }, className)}
    >
      <div className={styles.name}>
        <Avatar
          src={value?.AvatarUrl}
          placeholder={
            avatarPlaceholder || (
              <div className={styles.avatarPlaceholder}>{convertNameToAvatarPlaceholder(name)}</div>
            )
          }
          avatarClassName={styles.avatar}
        />
        <div testid="label" className={styles.label}>
          {label || name}
          <div className={styles.info}>
            <p>
              <span>{value?.ActiveSearches}</span> Active Searches
            </p>
            {renderCount(value?.PurchaseCount, 'Purchase')}
            {renderCount(value?.ListingCount, 'Listing')}
          </div>
        </div>
      </div>
      {isTeamAgentActive && (
        <>
          {multiSelect ? (
            <Checkbox
              onChange={onChange}
              checked={isChecked || !selected || selected.includes(value?.Id)}
              testid="client_checkbox"
            />
          ) : (
            <Options
              clientId={value?.Id}
              onChange={onChange}
              setShowModal={setShowModal}
              clientInfo={value}
            />
          )}
        </>
      )}
    </div>
  );
};

Client.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    AvatarUrl: PropTypes.string,
    Role: PropTypes.shape({
      Title: PropTypes.string,
    }),
    ActiveSearches: PropTypes.number,
  }),
  selected: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  setShowModal: PropTypes.func,
  avatarPlaceholder: PropTypes.node,
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  multiSelect: PropTypes.bool,
};

Client.defaultProps = {
  className: '',
  value: {},
  selected: undefined,
  onChange: () => {},
  setShowModal: () => {},
  avatarPlaceholder: undefined,
  label: undefined,
  isChecked: false,
  multiSelect: false,
};
