import { ExportArrow } from 'components/Icons/ExportArrow';
import { useState } from 'react';

import styles from './styles.module.scss';

export const ExportCSV = ({ data }) => {
  const [encodedUri, setEncodedUri] = useState<string>();

  const handleClick = () => {
    const dataValuesArrays = data.map((item) => Object.values(item));
    dataValuesArrays.unshift(Object.keys(data[0]));

    const csvContent =
      'data:text/csv;charset=utf-8,' + dataValuesArrays.map((row) => row.join(',')).join('\n');

    setEncodedUri(csvContent);
  };

  return (
    <a
      href={encodedUri}
      download={'clarityReport.csv'}
      onClick={handleClick}
      className={styles.exportBtn}
    >
      <ExportArrow />
    </a>
  );
};
