import moment from 'moment';
import { priceConverter } from 'helpers';

export type AreaConfigData = { day: string; value: number };

export type TransactionsSerialized = {
  serializedTransactionsStats: {
    numberOfTransactionsInDay: number;
    respectivedate: Date;
    amount: number;
  }[];
  totalTransactions: number;
  totalAmount: number;
};

export type statPoint = {
  date: string;
  count: number;
  sum: number;
  transactionIds: number[];
};

export type underContractStats = {
  total: statPoint[];
  listings: statPoint[];
  purchases: statPoint[];
};

export type expiringStats = {
  dueToday: statPoint[];
  dueThisWeek: statPoint[];
  dueThisMonth: statPoint[];
};

export type closedStats = {
  MTD: statPoint[];
  QTD: statPoint[];
  YTD: statPoint[];
};

export type clientStats = {
  date: string;
  Active: number;
  Retention: number;
};

export const setStats = (currentStats, value, setData, setTotalAmount, setTotalRequests) => {
  if (!currentStats) return;
  const data = currentStats[value];

  const stats =
    data.map(({ date, sum, count }) => ({
      day: moment(date.slice(0, 10)).format('DD MMM YYYY'),
      value: sum,
      count,
    })) || [];
  if (data.length === 1) setData([stats, stats]);
  else setData(stats);

  const latestStat = data[data.length - 1];
  setTotalAmount(priceConverter(latestStat?.sum || 0, 2));
  setTotalRequests(latestStat?.count || 0);
};
