import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Badge, Button, Col, Row } from 'components-antd';
import { ArrowLeft } from 'components/Icons';
import Slider from 'react-slick';
import { Icons } from '../../../common/Icons';
import { FavourtiesHeader } from '../../../common/FavourtiesHeader';
import { SavedSearches } from './components/SavedSearches';

import { propertyStatus } from 'settings/constants/properties';
import { routes } from 'settings/navigation/routes';
import { setFeedPrefsAction, setSubFilterTypeAction } from 'store/actions/feed';
import { appOpenModalEffect, setActiveTabEffect, sortFeedEffect } from 'store/effects';
import { getIsFeedFilterDrawerOpenSelector, getSubFilterTypeSelector } from 'store/selectors/feed';

import Close from 'components/Close';
import { LocationSearch } from 'pages/Properties/ClientSearch/LocationSearch';
import CriteriaModal from 'pages/Properties/CriteriaModal';
import { getFeedCriteriaSelectorV3, getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { SaveSearchBtn } from './components/SaveSearchBtn';
import styles from './styles.module.scss';
import { getSearchInstancesFormattedSelector } from 'store/selectors/mySearches';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { setCriteriaAction } from 'store/actions/feedv3';

// NOTE: This is a temp fix, will look at it later!
const subFilterTypes = {
  ALL: 'All',
  NEW: 'New',
  RECOMMENDED: 'Recommended',
  OPEN_HOUSES: 'OpenHouses',
  PRICE_REDUCTIONS: 'PriceReductions',
  BACK_ON_THE_MARKET: 'BackOnTheMarket',
  STATUS_CHANGES: 'StatusChanges',
  SUGGESTIONS: 'Suggestions',
};

const TabBtnType = {
  subFilter: 'subFilter',
  tabFilter: 'tabFilter',
};

const CenterTabSections = [
  {
    id: subFilterTypes.ALL,
    label: 'All',
    icon: Icons.HOUSE,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.RECOMMENDED,
    label: 'Recommended',
    icon: Icons.RECOMMENDED,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.NEW,
    label: 'New',
    icon: Icons.STAR,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.OPEN_HOUSES,
    label: 'Open Houses',
    icon: Icons.OPEN_HOUSES,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.PRICE_REDUCTIONS,
    label: 'Price Reductions',
    icon: Icons.PRICE_REDUCTIONS,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.BACK_ON_THE_MARKET,
    label: 'Back on Market',
    icon: Icons.BACK_ON_MARKET,
    type: TabBtnType.subFilter,
  },
  {
    id: subFilterTypes.STATUS_CHANGES,
    label: 'Status Changes',
    icon: Icons.STATUS_CHANGES,
    type: TabBtnType.subFilter,
  },
];

const RightTabSections = [
  {
    label: 'Tours',
    icon: Icons.TOURS,
    route: routes.clientTours,
  },
  {
    label: 'Favorites',
    id: 'favourites',
    icon: Icons.FAVOURITES,
    type: TabBtnType.tabFilter,
  },
  {
    label: 'New Search',
    icon: Icons.NEW_SEARCH,
    route: routes.search,
  },
];

export const TabButton = ({
  text,
  icon = '',
  onClick = () => {},
  isActive = false,
  className = '',
  count = 0,
  disabled = false,
}) => (
  <Button
    variant="text-only"
    className={classNames(styles.tabButton, { [styles.active]: isActive }, className)}
    onClick={onClick}
    disabled={disabled}
  >
    <Badge count={count}>
      <Icons variant={icon} />
    </Badge>
    <span className={styles.btnText}>{text}</span>
  </Button>
);

type Props = {
  isSavedSearchResult?: boolean;
  isFavortiesSectionVisible: boolean;
  setIsFavortiesSectionVisible: Function;
  setIsOpenSearchModal?: Function;
  setShowSavedSearches?: Function;
  setShowBlurBg: Function;
  isPending?: boolean;
};

export const ClientHeader: React.FC<Props> = ({
  isSavedSearchResult = false,
  isFavortiesSectionVisible,
  setIsFavortiesSectionVisible,
  setIsOpenSearchModal = (flag: boolean) => {},
  setShowSavedSearches = (flag: boolean) => {},
  setShowBlurBg,
  isPending = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isCriteriaOpen = useSelector(getIsFeedFilterDrawerOpenSelector);
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const { selectedSearch } = useSelector(getFeedMetaSelectorV3);
  const subFilterType = useSelector(getSubFilterTypeSelector);

  const onChangeSubFilter = (value) => {
    dispatch(setSubFilterTypeAction(value));
  };

  const onChangeTab = (tabId) => {
    dispatch(setFeedPrefsAction({ Status: [propertyStatus.active] }));
    dispatch(setActiveTabEffect(tabId));
  };

  const CarouselNext = (props) => {
    const { onClick, className } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft className={styles.icon} />
      </div>
    );
  };
  const CarouselPrev = (props) => {
    const { onClick, className } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowLeft className={styles.icon} />
      </div>
    );
  };

  const settings = {
    draggable: true,
    accessibility: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isSavedSearchResult ? 4 : 5,
    slidesToScroll: 2,
    nextArrow: <CarouselNext className={styles.arrows} />,
    prevArrow: <CarouselPrev className={styles.arrows} />,
    responsive: [
      {
        breakpoint: 1880,
        settings: {
          slidesToShow: isSavedSearchResult ? 4 : 5,
        },
      },
      {
        breakpoint: 1525,
        settings: {
          slidesToShow: isSavedSearchResult ? 4 : 5,
        },
      },
      {
        breakpoint: 1165,
        settings: {
          slidesToShow: isSavedSearchResult ? 4 : 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: isSavedSearchResult ? 4 : 5,
        },
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (isFavortiesSectionVisible) {
    return (
      <FavourtiesHeader onClose={() => setIsFavortiesSectionVisible(false)} text="Favorites" />
    );
  }
  const openModal = (id) => {
    dispatch(appOpenModalEffect({ id, open: true }));
  };

  const onOpenSearch = (showSavedSearches: boolean = true) => {
    setIsOpenSearchModal(true);
    showSavedSearches && setShowSavedSearches(true);
  };

  const CloseButton = () => {
    return (
      <div className={styles.closeBtn}>
        <Close onClick={() => {}} />
      </div>
    );
  };

  return (
    <div className={classNames(styles.clientHeader)}>
      <CriteriaModal />
      {!isSavedSearchResult && (
        <>
          <div className={classNames(styles.tabSection, styles.flexEnd)}>
            <SavedSearches handleOpenSearch={onOpenSearch} setShowBlurBg={setShowBlurBg} />
            <TabButton
              text="Criteria"
              icon={Icons.SETTINGS}
              onClick={() => {
                dispatch(setCriteriaAction({ criteria: selectedSearch?.criterias }));
                openModal(CriteriaModal.id);
              }}
              isActive={isCriteriaOpen}
              count={
                criteria
                  ? Object.values(cleanSearchQueryObj(criteria))?.filter((item) => Boolean(item))
                      ?.length
                  : 0
              }
              disabled={isPending}
            />
          </div>
          <div className={classNames(styles.tabSection, styles.scrollable)}>
            <Slider {...settings} className={classNames(styles.subfeedSlider)}>
              {CenterTabSections.map((item, idx) => (
                <TabButton
                  key={idx}
                  text={item.label}
                  icon={item.icon}
                  onClick={() =>
                    item.type === TabBtnType.subFilter
                      ? onChangeSubFilter(item.id)
                      : onChangeTab(item.id)
                  }
                  isActive={subFilterType === item?.id}
                />
              ))}
            </Slider>
          </div>
          <div className={classNames(styles.tabSection, styles.noBorder)}>
            {RightTabSections.map((item, idx) => (
              <TabButton
                key={idx}
                text={item.label}
                icon={item.icon}
                className={styles.rightTabSectionBtn}
                onClick={() => {
                  if (item.label === 'New Search') {
                    return setIsOpenSearchModal(true);
                  }
                  if (item.route) history.push(item?.route);
                  if (item.id == 'favourites') {
                    dispatch(sortFeedEffect({ fields: [] }));
                    setIsFavortiesSectionVisible(true);
                  }
                }}
              />
            ))}
          </div>
        </>
      )}
      {isSavedSearchResult && (
        <>
          <Col
            lg={7}
            className={classNames(styles.tabSection, styles.searchInputSection, styles.noBorder)}
          >
            <div className={styles.searchContainer}>
              <LocationSearch
                formClassname={classNames({ [styles.formWrapper]: isSavedSearchResult })}
                valuesWrapperClassName={styles.valuesWrapper}
                valueClassName={styles.locationValue}
                isSearchPage={true}
                disabled={isPending}
              />
            </div>
          </Col>
          <Col className={classNames(styles.tabSectionSearch)}>
            <SaveSearchBtn disabled={false} />
            <TabButton
              text="Criteria"
              icon={Icons.SETTINGS}
              onClick={() => openModal(CriteriaModal.id)}
              isActive={isCriteriaOpen}
            />
          </Col>
          <Col lg={9} className={classNames(styles.tabSectionSearch, styles.scrollable)}>
            <Slider {...settings} className={classNames(styles.subfeedSlider, styles.arrowSpacing)}>
              {CenterTabSections.map((item, idx) => (
                <div key={idx} className={styles.tabButtonContainer}>
                  <TabButton
                    key={idx}
                    text={item.label}
                    icon={item.icon}
                    onClick={() =>
                      item.type === TabBtnType.subFilter
                        ? onChangeSubFilter(item.id)
                        : onChangeTab(item.id)
                    }
                    isActive={subFilterType === item?.id}
                  />
                </div>
              ))}
            </Slider>
          </Col>
          <Col lg={2} className={classNames(styles.tabSectionSearch, styles.noBorder)}>
            <SaveSearchBtn
              disabled={false}
              button={CloseButton}
              onClose={() => {
                dispatch(setSubFilterTypeAction(subFilterTypes.ALL));
                history.push(routes.feed);
              }}
            />
          </Col>
        </>
      )}
    </div>
  );
};
