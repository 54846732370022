import classNames from 'classnames';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';

import { convertNameToAvatarPlaceholder } from 'helpers';
import { getTransactionTaskSelector } from 'store/selectors/transactionTask';

import styles from './styles.module.scss';
import { normalizeAddress } from 'components/ClientsDrawer/ProfileClient/Contact/components/Address/helpers';

const TaskClient = (props) => {
  const { className } = props;
  const { task } = useSelector(getTransactionTaskSelector);
  const { Address } = task || {};
  const { Client } = Address || {};
  const { User } = Client || {};
  const clientAddress = normalizeAddress(Address);
  const clientName = `${Client?.User?.FirstName} ${Client?.User?.LastName || ''}`;

  const getIcon = () => {
    return (
      <Avatar.Group
        maxCount={2}
        maxPopoverTrigger="click"
        size="large"
        maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
      >
        {User?.AvatarUrl ? (
          <Avatar key={User?.Id} className={styles.avatar} src={User?.AvatarUrl} />
        ) : (
          <Avatar key={User?.Id} className={styles.avatar}>
            {convertNameToAvatarPlaceholder(`${User?.FirstName} ${User?.LastName}`)}
          </Avatar>
        )}
      </Avatar.Group>
    );
  };
  return (
    <div testid="" className={classNames(styles.clientSection, className)}>
      <>
        {getIcon()}
        <p className={styles.nameWrapper}>
          <span className={styles.name}>{clientName}</span> <br />
          <span className={styles.address}>{clientAddress?.line1}</span>
        </p>
      </>
    </div>
  );
};

export default TaskClient;
