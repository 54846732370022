import React from 'react';

export const notificationTypes = {
  InvitationAccepted: 'InvitationAccepted',
  NewListing: 'NewListing',
  NewAgentRecommendation: 'NewAgentRecommendation',
  PropertyStatusChange: 'PropertyStatusChange',
  NewComment: 'NewComment',
  TourRequested: 'TourRequested',
  TourCancelledClient: 'TourCancelledClient',
  TourRequestCancelledClient: 'TourRequestCancelledClient',
  TourCancelledAgent: 'TourCancelledAgent',
  TourRequestCancelledAgent: 'TourRequestCancelledAgent',
  TourConfirmed: 'TourConfirmed',
  TourAssigned: 'TourAssigned',
  TourUpcomingAgent: 'TourUpcomingAgent',
  TourUpcomingClient: 'TourUpcomingClient',
  ClientPreferencesUpdated: 'ClientPreferencesUpdated',
  ClientSearchReactivated: 'ClientSearchReactivated',
  ClientSearchDeactivated: 'ClientSearchDeactivated',
  ClientSearchCriteriaChange: 'ClientSearchCriteriaChange',
  ClientSearchNew: 'ClientSearchNew',
  NewMessage: 'NewMessage',
  NewTaskCC: 'NewTaskCC',
  NewTask: 'NewTask',
  NewTaskSelf: 'NewTaskSelf',
  NewTaskComment: 'NewTaskComment',
  NewTaskCommentTagged: 'NewTaskCommentTagged',
  TaskCompleted: 'TaskCompleted',
  TaskCompletedSelfAssignee: 'TaskCompletedSelfAssignee',
  TaskCompletedSelfAssignor: 'TaskCompletedSelfAssignor',
  TaskCompletedCC: 'TaskCompletedCC',
  UpcomingDueDate: 'UpcomingDueDate',
  UpcomingDueDateCC: 'UpcomingDueDateCC',
  UpcomingDueDateSelfAssignee: 'UpcomingDueDateSelfAssignee',
  UpcomingDueDateSelfAssignor: 'UpcomingDueDateSelfAssignor',
  OverdueTask: 'OverdueTask',
  OverdueTaskCC: 'OverdueTaskCC',
  OverdueTaskSelfAssignee: 'OverdueTaskSelfAssignee',
  OverdueTaskSelfAssignor: 'OverdueTaskSelfAssignor',
  TaskStuck: 'TaskStuck',
  TaskStuckCC: 'TaskStuckCC',
  TaskStuckSelfAssignee: 'TaskStuckSelfAssignee',
  TaskStuckSelfAssignor: 'TaskStuckSelfAssignor',
  MilestoneAdded: 'MilestoneAdded',
  MilestoneCompleted: 'MilestoneCompleted',
  MilestoneMissed: 'MilestoneMissed',
  MilestoneDeadlineUpcoming: 'MilestoneDeadlineUpcoming',
  TransactionStatusUpdated: 'TransactionStatusUpdated',
  DocumentUploaded: 'DocumentUploaded',
  DocumentUpdated: 'DocumentUpdated',
  NewClientFavorite: 'NewClientFavorite',
  NewClientFavoriteSummary: 'NewClientFavoriteSummary',
  NewListingAgent: 'NewListingAgent',
  NewListingClient: 'NewListingClient',
  NewListingWithHighMatchscoreAgent: 'NewListingWithHighMatchscoreAgent',
  NewListingWithCustomMatchscoreClient: 'NewListingWithCustomMatchscoreClient',
  NewPreApproval: 'NewPreApproval',

  QuoteRequest: 'QuoteRequest',
  QuoteResponse: 'QuoteResponse',
  QuoteResponseNewAttachment: 'QuoteResponseNewAttachment',
  TransactionInvitation: 'TransactionInvitation',
  ShowingAppointmentApprovalSelf: 'ShowingAppointmentApprovalSelf',
  ShowingAppointmentApprovalOther: 'ShowingAppointmentApprovalOther',
  ConfirmedShowingAppointmentNotification: 'ConfirmedShowingAppointmentNotification',
  ConfirmedShowingAppointmentExternalNotification:
    'ConfirmedShowingAppointmentExternalNotification',
  DeclinedShowingAppointmentNotification: 'DeclinedShowingAppointmentNotification',
  DeclinedShowingAppointmentExternalNotification: 'DeclinedShowingAppointmentExternalNotification',
  CancelledShowingAppointmentNotification: 'CancelledShowingAppointmentNotification',
  CancelledShowingAppointmentExternalNotification:
    'CancelledShowingAppointmentExternalNotification',
  RescheduledShowingAppointmentNotification: 'RescheduledShowingAppointmentNotification',
  RescheduledShowingAppointmentExternalNotification:
    'RescheduledShowingAppointmentExternalNotification',
  ShowingFeedbackSubmittedNotification: 'ShowingFeedbackSubmittedNotification',
  UpcomingShowingAppointmentReminderNotification: 'UpcomingShowingAppointmentReminderNotification',
  UpcomingShowingAppointmentExternalReminderNotification:
    'UpcomingShowingAppointmentExternalReminderNotification',
  ShowingAppointmentApprovalReminderSelf: 'ShowingAppointmentApprovalReminderSelf',
  ShowingAppointmentApprovalReminderOther: 'ShowingAppointmentApprovalReminderOther',
  PendingShowingAppointmentNotification: 'PendingShowingAppointmentNotification',
  PendingShowingAppointmentExternalNotification: 'PendingShowingAppointmentExternalNotification',

  OfferStatusUpdated: 'OfferStatusUpdated',
  OfferReceived: 'OfferReceived',
  OfferWithdrawn: 'OfferWithdrawn',
  OfferEdited: 'OfferEdited',
  CounterReceived: 'CounterReceived',
  CounterViewed: 'CounterViewed',
  CounterRejected: 'CounterRejected',
  CounterAccepted: 'CounterAccepted',
  CounterWithdrawn: 'CounterWithdrawn',
  CounterEdited: 'CounterEdited',
  OfferExpirationUpcoming: 'OfferExpirationUpcoming',
  CounterExpirationUpcoming: 'CounterExpirationUpcoming',
  OfferExpired: 'OfferExpired',
  CounterExpired: 'CounterExpired',
  OfferDeadlineUpcoming: 'OfferDeadlineUpcoming',
  OfferDeadlinePassed: 'OfferDeadlinePassed',
  OfferSubmittedExternal: 'OfferSubmittedExternal',
  OfferViewedExternal: 'OfferViewedExternal',
  OfferRejectedExternal: 'OfferRejectedExternal',
  OfferAcceptedExternal: 'OfferAcceptedExternal',
  OfferEditsSubmittedExternal: 'OfferEditsSubmittedExternal',
  OfferExpirationUpcomingExternal: 'OfferExpirationUpcomingExternal',
  OfferExpiredExternal: 'OfferExpiredExternal',
  OfferWithdrawnExternal: 'OfferWithdrawnExternal',
  OfferDeadlineUpcomingExternal: 'OfferDeadlineUpcomingExternal',
  OfferDeadlinePassedExternal: 'OfferDeadlinePassedExternal',
  CounterSubmittedExternal: 'CounterSubmittedExternal',
  CounterViewedExternal: 'CounterViewedExternal',
  CounterReceivedExternal: 'CounterReceivedExternal',
  CounterRejectedExternal: 'CounterRejectedExternal',
  CounterAcceptedExternal: 'CounterAcceptedExternal',
  CounterEditsSubmittedExternal: 'CounterEditsSubmittedExternal',
  CounterExpirationUpcomingExternal: 'CounterExpirationUpcomingExternal',
  CounterExpiredExternal: 'CounterExpiredExternal',
  CounterWithdrawnExternal: 'CounterWithdrawnExternal',

  MegaphoneOffersNotification: 'MegaphoneOffersNotification',

  CollaborateDocument: 'CollaborateDocument',
  FormDocumentSignedByEveryone: 'FormDocumentSignedByEveryone',
  CancelledMessage: 'CancelledMessage',
  GenericMessage: 'GenericMessage',
  SignDocumentRequest: 'SignDocumentRequest',
  DocumentEditingComplete: 'DocumentEditingComplete',
  DocumentReadyForSignature: 'DocumentReadyForSignature',
  DocumentSigned: 'DocumentSigned',
  SignatureRequestDeclined: 'SignatureRequestDeclined',
  CollaborateRequestOpened: 'CollaborateRequestOpened',
  SignDocumentRequestOpened: 'SignDocumentRequestOpened',
  CollaborateRequestPending: 'CollaborateRequestPending',
  CollaborateRequestSent: 'CollaborateRequestSent',
  SignDocumentRequestPending: 'SignDocumentRequestPending',
  CommentAdded: 'CommentAdded',
  CommentReplied: 'CommentReplied',
  CommentResolved: 'CommentResolved',
  CommentTagged: 'CommentTagged',
  TaskNotificationSummary: 'TaskNotificationSummary',
  TransactionInvitationOther: 'TransactionInvitationOther',
  AgentTransactionInvitation: 'AgentTransactionInvitation',
  VoidDocument: 'VoidDocument',
};

export const userNotificationSettingGroups = {
  General: 'General',
  Properties: 'Properties',
  Transactions: 'Transactions',
  Offers: 'Offers',
};

export const notificationVariants = {
  Email: 'Email',
  InApp: 'InApp',
  Sms: 'Sms',
};

export enum NotificationSubVariants {
  All = 'All',
  ByClient = 'ByClient',
  ByCollaborator = 'ByCollaborator',
  ByTeamMember = 'ByTeamMember',
  IAmTagged = 'IAmTagged',
  Realtime = 'Realtime',
  OnceDay = 'OnceDay',
  OnceWeek = 'OnceWeek',
  OnlySaved = 'OnlySaved',
  ChangingCriteria = 'ChangingCriteria',
  DeactivatingSearch = 'DeactivatingSearch',
  ReactivatingSearch = 'ReactivatingSearch',
  AreaOfOperation = 'AreaOfOperation',
  CoincidesWithActiveClientSearch = 'CoincidesWithActiveClientSearch',
  NeedsMyApproval = 'NeedsMyApproval',
  SomeonesApproval = 'SomeonesApproval',
  ApprovalAndAboutToLapse = 'ApprovalAndAboutToLapse',
  ApprovalLapsed = 'ApprovalLapsed',
  SuccessfullyScheduled = 'SuccessfullyScheduled',
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
  Declined = 'Declined',
  ForMyListings = 'ForMyListings',
  ForBuyerTours = 'ForBuyerTours',
  Edited = 'Edited',
  AboutToExpire = 'AboutToExpire',
  Expires = 'Expires',
  Submitted = 'Submitted',
  Viewed = 'Viewed',
  Received = 'Received',
  AboutToExpireExternal = 'AboutToExpireExternal',
  Rejected = 'Rejected',
  Accepted = 'Accepted',
  Withdrawn = 'Withdrawn',
  HasOfferDeadlineComingUp = 'HasOfferDeadlineComingUp',
  HasOfferDeadlineThatPassed = 'HasOfferDeadlineThatPassed',
  Expired = 'Expired',
  ComingUp = 'ComingUp',
  Lapsed = 'Lapsed',
  MilestoneAdded = 'MilestoneAdded',
  MilestoneEdited = 'MilestoneEdited',
  MilestoneComingUp = 'MilestoneComingUp',
  MilestonePastDue = 'MilestonePastDue',
  MilestoneCompleted = 'MilestoneCompleted',
  AllTasks = 'AllTasks',
  TaskSelf = 'TaskSelf',
  TaskCC = 'TaskCC',
  TaskSelfAssignor = 'TaskSelfAssignor',
  TheDayOf = 'TheDayOf',
  DaysInAdvance = 'DaysInAdvance',
  HasMatchScore = 'HasMatchScore',
  NeedsMyApprovalClient = 'NeedsMyApprovalClient',
  ShowingScheduled = 'ShowingScheduled',
  ShowingRescheduled = 'ShowingRescheduled',
  ShowingCancelled = 'ShowingCancelled',
  NewOffer = 'NewOffer',
  QuoteRequestRemind = 'QuoteRequestRemind',
  TourUpcoming = 'TourUpcoming',
  TourItinerary = 'TourItinerary',
}

export const notificationsAdjustedVariants = {
  SendingTime: 'SendingTime',
  Matches: 'Matches',
};

export const notificationsTuples = Object.values(notificationVariants);

export const groupedNotifications = {
  [userNotificationSettingGroups.General]: [
    notificationTypes.NewMessage,
    notificationTypes.InvitationAccepted,
    notificationTypes.QuoteRequest,
    notificationTypes.QuoteResponse,
  ],
  [userNotificationSettingGroups.Properties]: [
    notificationTypes.NewListing,
    notificationTypes.NewClientFavorite,
    notificationTypes.NewAgentRecommendation,
    notificationTypes.PropertyStatusChange,
    notificationTypes.ClientPreferencesUpdated,
    notificationTypes.ClientSearchReactivated,
    notificationTypes.ClientSearchDeactivated,
    notificationTypes.ClientSearchCriteriaChange,
    notificationTypes.TourRequested,
    notificationTypes.TourAssigned,
    notificationTypes.TourConfirmed,
    notificationTypes.TourUpcomingAgent,
    notificationTypes.TourUpcomingClient,
    notificationTypes.TourCancelledClient,
    notificationTypes.TourRequestCancelledClient,
    notificationTypes.TourCancelledAgent,
    notificationTypes.TourRequestCancelledAgent,
  ],
  [userNotificationSettingGroups.Transactions]: [
    notificationTypes.OfferStatusUpdated,
    notificationTypes.NewComment,
    notificationTypes.NewTask,
    notificationTypes.NewTaskComment,
    notificationTypes.NewTaskCommentTagged,
    notificationTypes.NewTaskCC,
    notificationTypes.NewTaskSelf,
    notificationTypes.TaskCompleted,
    notificationTypes.TaskCompletedCC,
    notificationTypes.TaskCompletedSelfAssignee,
    notificationTypes.TaskCompletedSelfAssignor,
    notificationTypes.UpcomingDueDate,
    notificationTypes.UpcomingDueDateCC,
    notificationTypes.UpcomingDueDateSelfAssignee,
    notificationTypes.UpcomingDueDateSelfAssignor,
    notificationTypes.OverdueTask,
    notificationTypes.OverdueTaskCC,
    notificationTypes.OverdueTaskSelfAssignee,
    notificationTypes.OverdueTaskSelfAssignor,
    notificationTypes.TaskStuck,
    notificationTypes.TaskStuckCC,
    notificationTypes.TaskStuckSelfAssignee,
    notificationTypes.TaskStuckSelfAssignor,
    notificationTypes.MilestoneCompleted,
    notificationTypes.MilestoneMissed,
    notificationTypes.TransactionStatusUpdated,
    notificationTypes.DocumentUploaded,
    notificationTypes.DocumentUpdated,
    notificationTypes.ConfirmedShowingAppointmentNotification,
    notificationTypes.MilestoneDeadlineUpcoming,
  ],
  [userNotificationSettingGroups.Offers]: [
    notificationTypes.OfferStatusUpdated,
    notificationTypes.OfferReceived,
    notificationTypes.OfferWithdrawn,
    notificationTypes.OfferEdited,
    notificationTypes.CounterReceived,
    notificationTypes.CounterViewed,
    notificationTypes.CounterRejected,
    notificationTypes.CounterAccepted,
    notificationTypes.CounterWithdrawn,
    notificationTypes.CounterEdited,
    notificationTypes.OfferExpirationUpcoming,
    notificationTypes.CounterExpirationUpcoming,
    notificationTypes.OfferExpired,
    notificationTypes.CounterExpired,
    notificationTypes.OfferDeadlineUpcoming,
    notificationTypes.OfferDeadlinePassed,
    notificationTypes.MegaphoneOffersNotification,
  ],
};

export const orderGroupNotifications = (type) =>
  groupedNotifications[type].reduce((acc, item, index) => ({ ...acc, [item]: index }), {});

export const orderNotificationTypes = [
  notificationVariants.InApp,
  notificationVariants.Sms,
  notificationVariants.Email,
  notificationsAdjustedVariants.SendingTime,
  notificationsAdjustedVariants.Matches,
].reduce((acc, item, index) => ({ ...acc, [item]: index }), {});

export const groupNotificationsTypes = {
  Unread: 'Unread',
  Today: 'Today',
  Earlier: 'Earlier',
};

export const mapNotificationsTitles = {
  [notificationTypes.NewMessage]: {
    Title: 'New Message',
    Subtitle: "We'll notify you when someone sends you a message.",
  },
  [notificationTypes.InvitationAccepted]: {
    Title: 'Invitation Accepted',
    Subtitle: "We'll notify you when a collaborator accepts your invitation.",
  },
  [notificationTypes.NewListing]: {
    Title: 'New Listing',
    Subtitle: 'Will notify you when a new listing that matches your criteria comes on the market.',
  },
  [notificationTypes.NewClientFavorite]: {
    Title: 'New Client Favorite',
    Subtitle: "We'll notify you when your clients add a property to their Favorites.",
  },
  [notificationTypes.NewAgentRecommendation]: {
    Title: 'New Agent Suggestion',
    Subtitle: "We'll notify you when your agent suggests a listing.",
  },
  [notificationTypes.PropertyStatusChange]: {
    Title: 'Property Status Change',
    Subtitle: "We'll notify you when a property in your favorites has a status change.",
  },
  [notificationTypes.ClientPreferencesUpdated]: {
    Title: 'Client Preferences Updated',
    Subtitle:
      "We'll notify you when one of your clients updates their preferences or search criteria. ",
  },
  [notificationTypes.ClientSearchReactivated]: {
    Title: 'Client Search Reactivated',
    Subtitle: "We'll notify you when one of your clients activates search criteria. ",
  },
  [notificationTypes.ClientSearchDeactivated]: {
    Title: 'Client Search Deactivated',
    Subtitle: "We'll notify you when one of your clients de-activates search criteria. ",
  },
  [notificationTypes.ClientSearchCriteriaChange]: {
    Title: 'Client Search Changed',
    Subtitle: "We'll notify you when one of your clients changes search criteria. ",
  },
  [notificationTypes.TourRequested]: {
    Title: 'Tour Requested',
    Subtitle: "We'll notify you when a client wants to schedule a tour of a property.",
  },
  [notificationTypes.NewComment]: {
    Title: 'New Comment',
    Subtitle:
      "We'll notify you when there is a new comment on a task that is assigned to you or that you've assigned.",
  },
  [notificationTypes.NewTask]: {
    Title: 'New Task',
    Subtitle:
      "We'll notify you when a task is assigned to you or when a task is assigned to anyone in your transaction, depending on you preference.",
  },
  [notificationTypes.NewTaskCC]: {
    Title: 'New Task',
    Subtitle: "We'll notify you when you are cc'd on a new task.",
  },
  [notificationTypes.TaskCompleted]: {
    Title: 'Task Completed',
    Subtitle:
      "We'll notify you when a task you've assigned is completed or when any task in your transaction is completed, depending on your preference.",
  },
  [notificationTypes.UpcomingDueDate]: {
    Title: 'Upcoming Due Date',
    Subtitle:
      "We'll notify you of any upcoming due dates on tasks assigned to your or any task in your transaction, depending on your preference. ",
  },
  [notificationTypes.OverdueTask]: {
    Title: 'Overdue Task',
    Subtitle:
      "We'll notify you if a task assigned to you is overdue or if any task in your transaction is overdue, depending on your preference. ",
  },
  [notificationTypes.MilestoneCompleted]: {
    Title: 'Milestone Completed',
    Subtitle: "We'll notify you when a milestone in your transaction is completed. ",
  },
  [notificationTypes.MilestoneMissed]: {
    Title: 'Milestone Missed',
    Subtitle: "We'll notify you when a milestone in your transaction is missed. ",
  },
  [notificationTypes.TransactionStatusUpdated]: {
    Title: 'Transaction Status Updated',
    Subtitle: "We'll notify you when the status of your transaction is updated.",
  },
  [notificationTypes.DocumentUploaded]: {
    Title: 'Document Uploaded',
    Subtitle: "We'll notify you when a document that you have access to is uploaded. ",
  },
  [notificationTypes.QuoteResponse]: {
    Title: 'Quote Response',
    Subtitle: "We'll notify you when quote will be responded. ",
  },
  [notificationTypes.QuoteRequest]: {
    Title: 'Quote Request',
    Subtitle: "We'll notify you when new quote will be requested. ",
  },
  [notificationTypes.OfferStatusUpdated]: {
    Title: 'Offer Status Updated',
    Subtitle:
      'This notification will be triggered anytime an offer status is updated via a manual action or change.',
  },
  [notificationTypes.OfferReceived]: {
    Title: 'Offer Received',
    Subtitle: 'This notification will be triggered when a new offer is received.',
  },
  [notificationTypes.OfferWithdrawn]: {
    Title: 'Offer Withdrawn',
    Subtitle:
      'This notification will be triggered when an offer is withdrawn by the external submitting agent.',
  },
  [notificationTypes.OfferEdited]: {
    Title: 'Offer Edited',
    Subtitle:
      'This notification will be triggered when an offer is edited by the external submitting agent.',
  },
  [notificationTypes.CounterReceived]: {
    Title: 'Counter Received',
    Subtitle:
      'This notification will be triggered when a counter offer is submitted by an external submitting agent.',
  },
  [notificationTypes.CounterViewed]: {
    Title: 'Counter Viewed',
    Subtitle:
      'This notification will be triggered when a counter offer is viewed by the external agent.',
  },
  [notificationTypes.CounterRejected]: {
    Title: 'Counter Rejected',
    Subtitle:
      'This notification will be triggered when a counter offer is rejected by the external agent. ',
  },
  [notificationTypes.CounterAccepted]: {
    Title: 'Counter Accepted ',
    Subtitle:
      'This notification will be triggered when a counter offer is accepted by the external agent. ',
  },
  [notificationTypes.CounterWithdrawn]: {
    Title: 'Counter Withdrawn',
    Subtitle:
      'This notification will be triggered when a counter offer is withdrawn by the external submitting agent.',
  },
  [notificationTypes.CounterEdited]: {
    Title: 'Counter Edited',
    Subtitle:
      'This notification will be triggered when a counter offer is edited by the external submitting agent.',
  },
  [notificationTypes.OfferExpirationUpcoming]: {
    Title: 'Offer Expiration Upcoming',
    Subtitle:
      'This notification will be triggered at specific intervals prior to the expiration of an offer. ',
  },
  [notificationTypes.CounterExpirationUpcoming]: {
    Title: 'Counter Expiration Upcoming',
    Subtitle:
      'This notification will be triggered at specific intervals prior to the expiration of a counter. ',
  },
  [notificationTypes.OfferExpired]: {
    Title: 'Offer Expired',
    Subtitle:
      'This notification will be triggered 60 seconds after an offer expires if the status is not changed to accepted, rejected or a counter is not issued.',
  },
  [notificationTypes.CounterExpired]: {
    Title: 'Counter Expired',
    Subtitle:
      'This notification will be triggered 60 seconds after a counter offer expires if the status is not changed to accepted, rejected or a new counter is not issued.',
  },
  [notificationTypes.OfferDeadlineUpcoming]: {
    Title: 'Offer Deadline Upcoming',
    Subtitle:
      'This notification will be triggered at specific intervals prior to the offer deadline set on the offer link configuration, if applicable and if the link is not disabled or an offer is not accepted prior to the notification.',
  },
  [notificationTypes.OfferDeadlinePassed]: {
    Title: 'Offer Deadline Passed',
    Subtitle:
      'This notification will be triggered 60 seconds after the deadline defined in the offer link configuration, if applicable if the link is not disabled or an offer is not accepted prior to the notification.',
  },
  [notificationTypes.MegaphoneOffersNotification]: {
    Title: 'Megaphone Offers Notification',
    Subtitle:
      'This notification will be triggered when an agent uses the megaphone functionality to broadcast a message to external parties.',
  },
  [notificationTypes.ConfirmedShowingAppointmentNotification]: {
    Title: 'Showing Confirmed',
    Subtitle: "We'll notify you when your approved showing is confirmed.",
  },
};

export const mapItemTitles = {
  Global: {
    [notificationVariants.InApp]: () => ({
      Title: 'Enable All Push Notifications',
      Subtitle: 'Notifications will be sent via push messages to your device.',
    }),
    [notificationVariants.Sms]: ({ phoneNumber }) => ({
      Title: 'Enable All Text Notifications',
      Subtitle: (
        <>
          Notifications will be sent to
          {phoneNumber}
        </>
      ),
    }),
    [notificationVariants.Email]: ({ email }) => ({
      Title: 'Enable All Email Notifications',
      Subtitle: `Notifications will be sent to ${email}`,
    }),
  },
  PerType: {
    [notificationVariants.InApp]: () => ({
      Title: 'In-App',
    }),
    [notificationVariants.Sms]: () => ({
      Title: 'Text',
    }),
    [notificationVariants.Email]: () => ({
      Title: 'Email',
    }),
  },
  SubVariants: {
    [NotificationSubVariants.All]: 'All',
    [NotificationSubVariants.ByClient]: 'By a client',
    [NotificationSubVariants.ByCollaborator]: 'By a collaborator',
    [NotificationSubVariants.ByTeamMember]: 'By a team member',
    [NotificationSubVariants.IAmTagged]: 'Only if I’m tagged',
    [NotificationSubVariants.Realtime]: 'Real-time',
    [NotificationSubVariants.OnceDay]: 'Once a day',
    [NotificationSubVariants.OnceWeek]: 'Once a week',
    [NotificationSubVariants.OnlySaved]: 'Only if it’s saved',
    [NotificationSubVariants.ChangingCriteria]: 'Changing criteria',
    [NotificationSubVariants.DeactivatingSearch]: 'Deactivating a search',
    [NotificationSubVariants.ReactivatingSearch]: 'Reactivating a search',
    [NotificationSubVariants.AreaOfOperation]: 'In my area(s) of operation',
    [NotificationSubVariants.CoincidesWithActiveClientSearch]:
      'Only if it coincides with active client searches',
    [NotificationSubVariants.NeedsMyApproval]: 'Needs my approval',
    [NotificationSubVariants.SomeonesApproval]: 'Is waiting on someone else’s approval',
    [NotificationSubVariants.ApprovalAndAboutToLapse]: 'Needs approval and is about to lapse',
    [NotificationSubVariants.ApprovalLapsed]: 'Needed approval and lapsed',
    [NotificationSubVariants.SuccessfullyScheduled]: 'Was successfully scheduled',
    [NotificationSubVariants.Confirmed]: 'Is confirmed',
    [NotificationSubVariants.Cancelled]: 'Is declined',
    [NotificationSubVariants.Declined]: 'Is canceled',
    [NotificationSubVariants.ForMyListings]: 'Only for my listings.',
    [NotificationSubVariants.ForBuyerTours]: 'Only for buyer tours.',
    [NotificationSubVariants.Edited]: 'Edited',
    [NotificationSubVariants.AboutToExpire]: 'Is about to expire',
    [NotificationSubVariants.Expires]: 'Expires',
    [NotificationSubVariants.Submitted]: 'Submitted',
    [NotificationSubVariants.Viewed]: 'Viewed',
    [NotificationSubVariants.Received]: 'Counter received',
    [NotificationSubVariants.AboutToExpireExternal]: 'About to expire',
    [NotificationSubVariants.Expired]: 'Expired',
    [NotificationSubVariants.Rejected]: 'Rejected',
    [NotificationSubVariants.Accepted]: 'Accepted',
    [NotificationSubVariants.Withdrawn]: 'Withdrawn',
    [NotificationSubVariants.HasOfferDeadlineComingUp]: 'Has an offer deadline coming up',
    [NotificationSubVariants.HasOfferDeadlineThatPassed]: 'Has an offer deadline that passed',
    [NotificationSubVariants.ComingUp]: 'Is coming up',
    [NotificationSubVariants.Lapsed]: 'Lapsed',
    [NotificationSubVariants.MilestoneAdded]: 'A new milestone is added',
    [NotificationSubVariants.MilestoneEdited]: 'An existing milestone is edited',
    [NotificationSubVariants.MilestoneComingUp]: 'A milestone is coming up',
    [NotificationSubVariants.MilestonePastDue]: 'A milestone is past due',
    [NotificationSubVariants.MilestoneCompleted]: 'A milestone is complete',
    [NotificationSubVariants.AllTasks]: 'All tasks',
    [NotificationSubVariants.TaskSelf]: 'If it’s assigned to me',
    [NotificationSubVariants.TaskCC]: 'If I’m cc’d',
    [NotificationSubVariants.TaskSelfAssignor]: 'If I assigned it',
    [NotificationSubVariants.TheDayOf]: 'The day of',
    [NotificationSubVariants.DaysInAdvance]: 'days in advance',
    [NotificationSubVariants.HasMatchScore]: 'Only if it has a match score of - or greater',
    [NotificationSubVariants.NeedsMyApprovalClient]: 'A showing request needs my approval',
    [NotificationSubVariants.ShowingScheduled]: 'A showing is scheduled',
    [NotificationSubVariants.ShowingRescheduled]: 'A showing is rescheduled',
    [NotificationSubVariants.ShowingCancelled]: 'A showing is canceled',
    [NotificationSubVariants.NewOffer]: 'I have a new offer',
    [NotificationSubVariants.QuoteRequestRemind]: 'Remind me every:',
    [NotificationSubVariants.TourUpcoming]: '- days before',
    [NotificationSubVariants.TourItinerary]: 'The morning of',
  },
};

export enum TimeType {
  Day = 'Day',
  Hour = 'Hour',
  Minute = 'Minute',
  Second = 'Second',
}

export const TimeOptions = [
  {
    label: `${TimeType.Day}(s)`,
    value: TimeType.Day,
  },
  {
    label: `${TimeType.Hour}(s)`,
    value: TimeType.Hour,
  },
];

export const NewListingNotificationsTypes = {
  Agent: [notificationTypes.NewListingAgent, notificationTypes.NewListingWithHighMatchscoreAgent],
  Client: [
    notificationTypes.NewListingClient,
    notificationTypes.NewListingWithCustomMatchscoreClient,
  ],
};

export const NotificationTypesForGrouping = {
  NewMessage: [notificationTypes.NewMessage],
  Tasks: [
    notificationTypes.NewMessage,
    notificationTypes.NewTaskCC,
    notificationTypes.NewTaskSelf,
    notificationTypes.TaskCompletedCC,
    notificationTypes.TaskCompletedSelfAssignee,
    notificationTypes.TaskCompletedSelfAssignor,
    notificationTypes.UpcomingDueDateCC,
    notificationTypes.UpcomingDueDateSelfAssignee,
    notificationTypes.UpcomingDueDateSelfAssignor,
    notificationTypes.OverdueTaskCC,
    notificationTypes.OverdueTaskSelfAssignee,
    notificationTypes.OverdueTaskSelfAssignor,
    notificationTypes.TaskStuckCC,
    notificationTypes.TaskStuckSelfAssignee,
    notificationTypes.TaskStuckSelfAssignor,
    notificationTypes.NewTaskComment,
    notificationTypes.NewTaskCommentTagged,
  ],
  NewListing: [
    notificationTypes.NewListingAgent,
    notificationTypes.NewListingWithHighMatchscoreAgent,
    notificationTypes.NewListingClient,
    notificationTypes.NewListingWithCustomMatchscoreClient,
  ],
};

export enum SeverityLevel {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export const SeverityTagsMapping = {
  All: 'All',
  [SeverityLevel.High]: 'Urgent',
  [SeverityLevel.Medium]: 'Alert',
  [SeverityLevel.Low]: 'FYI',
};

export const severityLevelOptions = Object.keys(SeverityTagsMapping).map((key) => ({
  label: SeverityTagsMapping[key],
  value: false,
  level: key,
}));
