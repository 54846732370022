import { Area, AreaConfig } from '@ant-design/plots';
import { AreaGraphTooltip } from '../AreaGraphTooltip';

import styles from './styles.module.scss';

export const PulseAreaGraph = ({
  xField,
  yField,
  data,
  color,
  areaFillColor,
  valueAsCurrency = true,
}) => {
  const config = {
    data,
    xField,
    yField,
    color,
    responsive: true,
    autoFit: true,
    height: 80,
    padding: [20, 0],
    areaStyle: {
      fill: areaFillColor,
      line: {
        style: {
          strokeWidth: 10,
        },
      },
    },
    xAxis: false,
    yAxis: false,
    className: styles.areaGraph,
    tooltip: false,
  } as AreaConfig;

  return <Area className={styles.areaGraphPadding} {...config}></Area>;
};
