import { useState, useEffect } from 'react';

import { Row, Col } from 'components-antd';
import { StatCard } from './StatCard';
import { getSymphonySummary } from 'api/symphony';

import styles from './styles.module.scss';

export enum SymphonyStats {
  ActiveClients = 'Active Clients',
  Listed = 'Listed',
  UnderContract = 'Under Contract',
  Closed = 'Closed',
}

const statTypes = [
  {
    label: SymphonyStats.ActiveClients,
    variant: 'indigo',
  },
  {
    label: SymphonyStats.Listed,
    variant: 'lightBlue',
  },
  {
    label: SymphonyStats.UnderContract,
    variant: 'green',
  },
  {
    label: SymphonyStats.Closed,
    variant: 'yellow',
  },
];

const initSate = {
  ActiveClients: 0,
  ListedVolume: 0,
  ListedCount: 0,
  ListedTransactionIds: [] as number[],
  UnderContractVolume: 0,
  UnderContractCount: 0,
  UnderContractTransactionIds: [] as number[],
  ClosedVolume: 0,
  ClosedCount: 0,
  ClosedTransactionIds: [] as number[],
};

export const HeaderStats = () => {
  const [stats, setStats] = useState(initSate);

  const fetchSymphonySummary = async () => {
    const summary = await getSymphonySummary();
    setStats(summary?.data || initSate);
  };

  useEffect(() => {
    fetchSymphonySummary();
  }, []);

  const getData = (key) => {
    switch (key) {
      case SymphonyStats.Listed:
        return {
          count: stats.ListedCount,
          volume: stats.ListedVolume,
          transactionIds: stats.ListedTransactionIds,
        };
      case SymphonyStats.UnderContract:
        return {
          count: stats.UnderContractCount,
          volume: stats.UnderContractVolume,
          transactionIds: stats.UnderContractTransactionIds,
        };
      case SymphonyStats.Closed:
        return {
          count: stats.ClosedCount,
          volume: stats.ClosedVolume,
          transactionIds: stats.ClosedTransactionIds,
        };
      default:
        return { count: stats.ActiveClients };
    }
  };

  return (
    <div className={styles.statistics}>
      <Row gutter={[24, 16]}>
        {statTypes.map((item, idx) => {
          return (
            <Col xs={24} sm={12} lg={6} key={idx}>
              <StatCard label={item.label} variant={item.variant} data={getData(item.label)} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
