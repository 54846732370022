import React, { useMemo } from 'react';

import styles from './styles.module.scss';
import { PlusIcon } from '../icons';
import { CardItem } from '../SequenceCardItem';
import { KitItem } from './types';
import { DropdownMenuOptions } from '../DropdownMenu';
import {
  CampaignEntityType,
  CampaignTask,
  CampaignTaskTemplate,
  PublishedKit,
} from 'store/reducers/radarKits/types';
import { SelectedKit } from '../../ViewSequence';

type CardProps = {
  type: CampaignEntityType;
  title: string;
  handleAdd: () => void;
  placeholder: string;
  items?: SelectedKit[] | CampaignTaskTemplate[];
  icon: React.ReactNode;
  editMode?: boolean;
  dropdownOptions: DropdownMenuOptions[];
  dropdownOnSelect: (
    kitId: number,
    frequencyId: string,
    newFrequencyName: string,
    prevFrequenceName: string,
  ) => void;
};

export const SequenceCard: React.FC<CardProps> = ({
  type,
  title,
  handleAdd,
  placeholder,
  items,
  icon,
  editMode = true,
  dropdownOptions,
  dropdownOnSelect,
}) => {
  const hasItems = useMemo(() => (items ? items?.length > 0 : false), [items]);
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div>{title}</div>
        {editMode && (
          <div className={styles.addBtn} onClick={handleAdd}>
            <div className={styles.iconWrapper}>
              <PlusIcon />
            </div>
            <div>Add</div>
          </div>
        )}
      </div>
      <div className={styles.cardContent}>
        {hasItems &&
          items?.map((item, index: number) => (
            <CardItem
              key={`${index}-${item.KitId}`}
              type={type}
              item={item}
              icon={icon}
              editMode={editMode}
              options={dropdownOptions}
              dropdownOnSelect={dropdownOnSelect}
            />
          ))}
        {!hasItems && <div className={styles.emptyCard}>{placeholder}</div>}
      </div>
    </div>
  );
};
