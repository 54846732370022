import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Select } from 'components';
import { TransactionUserRole, TransactionUserRoleMap } from 'settings/constants/transaction';
import { getTransactionRolesSelector } from 'store/selectors/transactionRoles';

import styles from './styles.module.scss';

const ClientRole = (props) => {
  const {
    className,
    onChange,
    wrapperClassName,
    arrowIconClassName,
    valueClassName,
    inputClassName,
    value,
    isSearchIcon,
    multiselect,
    isClientInfo = false,
    isCoordinatorInfo = false,
    isAssignRoleInfo = false,
    isWhoIsRepresentingBuyerInfo = false,
    isParInfo = false,
    isAgentInfo = false,
    isBuyerTransaction = false,
    isSellerTransaction = false,
    closeOnSelect,
    isViewMode,
    showFullAccessRoles,
    variant,
    placeholder,
    isCampaignTask,
  } = props;
  const { onKeyPress } = props;
  const [sortedTransactionRoles, setSortedTransactionRoles] = useState([]);
  const { transactionRoles } = useSelector(getTransactionRolesSelector);
  function sortTransactionRoles() {
    // Modify the order of Roles:
    // Seller's Attorney & Buyer's Attorney should be under the role Attorney.

    function sortData(data) {
      const sortedData = [];
      let attorneyIndex = -1;
      let attorneyObject = null;

      data.forEach((item, index) => {
        if (item.Title === 'Attorney' && attorneyIndex === -1) {
          attorneyIndex = index;
          attorneyObject = item;
        }
      });

      if (attorneyIndex !== -1) {
        sortedData.push(...data.slice(0, attorneyIndex));
      }

      if (attorneyObject) {
        sortedData.push(attorneyObject);
      }

      if (attorneyObject) {
        data.forEach((item) => {
          if (item.Title === "Buyer's Attorney" || item.Title === "Seller's Attorney") {
            sortedData.push(item);
          }
        });
      }

      if (attorneyIndex !== -1) {
        sortedData.push(...data.slice(attorneyIndex + 1));
      }

      return sortedData;
    }

    setSortedTransactionRoles(sortData(transactionRoles));
  }

  useEffect(() => {
    if (transactionRoles) sortTransactionRoles();
  }, [transactionRoles]);

  const getRoles = () => {
    const roles = sortedTransactionRoles || [];
    if (showFullAccessRoles) {
      return roles.filter((r) =>
        [
          TransactionUserRole.BuyerAgent,
          TransactionUserRole.ListingAgent,
          TransactionUserRole.TransactionCoordinator,
          TransactionUserRole.AdministrativeAssistant,
          TransactionUserRole.ListingCoordinator,
          TransactionUserRole.TCII,
          TransactionUserRole.TCIII,
          TransactionUserRole.LCII,
          TransactionUserRole.LCIII,
          TransactionUserRole.Operations,
          TransactionUserRole.Marketing,
          TransactionUserRole.Brokerage,
          TransactionUserRole.Finance,
        ].includes(r.Title),
      );
    }
    if (isClientInfo) {
      return roles.filter((r) =>
        [TransactionUserRole.Buyer, TransactionUserRole.Seller].includes(r.Title),
      );
    }
    if (isCoordinatorInfo) {
      return roles.filter((r) =>
        [
          TransactionUserRole.TransactionCoordinator,
          TransactionUserRole.ListingCoordinator,
          TransactionUserRole.TCII,
          TransactionUserRole.TCIII,
          TransactionUserRole.LCII,
          TransactionUserRole.LCIII,
        ].includes(r.Title),
      );
    }
    if (isAgentInfo) {
      return roles.filter((r) =>
        [TransactionUserRole.ListingAgent, TransactionUserRole.BuyerAgent].includes(r.Title),
      );
    }

    if (isWhoIsRepresentingBuyerInfo) {
      return roles.filter((r) => [TransactionUserRole.BuyerAgent].includes(r.Title));
    }

    if (isBuyerTransaction) {
      return roles.filter((r) => [TransactionUserRole.BuyerAgent].includes(r.Title));
    }
    if (isSellerTransaction) {
      return roles.filter((r) => [TransactionUserRole.ListingAgent].includes(r.Title));
    }

    return roles;
  };

  const getOptions = () => {
    const rolesOptions = getRoles().map((role) => ({
      name: TransactionUserRoleMap[role?.Title] || role?.Title,
      value: role?.Title,
      roleId: role?.Id,
    }));
    let response = [...rolesOptions];
    if (!multiselect && value?.tagRole) {
      response = [...rolesOptions, { name: value.tagRole, value: value.tagRole, isNewRole: true }];
    } else if (multiselect && value?.length && value[0].tagRole) {
      response = [
        ...rolesOptions,
        ...value.map((v) => ({ name: v.tagRole, value: v.tagRole, isNewRole: true })),
      ];
    }

    if (
      isAgentInfo ||
      isCoordinatorInfo ||
      isParInfo ||
      isAssignRoleInfo ||
      isBuyerTransaction ||
      isSellerTransaction
    ) {
      return [...new Map(response?.map((item) => [item['name'], item])).values()];
    } else {
      return [...new Map(response?.map((item) => [item['value'], item])).values()];
    }
  };

  const getValue = () => {
    if (value?.tagRole) {
      const options = getOptions();
      const option = options.find((opt) => opt?.value === value?.tagRole);

      if (option) {
        return option;
      }
    }

    return value;
  };

  const getValues = () => {
    if (value?.length) {
      return getOptions().filter((o) => value.includes(o?.value));
    }
    return [];
  };

  const renderOption = ({ optionProps, optionData, optionSnapshot }) => {
    const optProps = { ...optionProps, value: optionData.value, disabled: optionData.disabled };

    if (optionData?.isNewRole) {
      return null;
    }

    return (
      <button
        type="button"
        className={classNames(styles.option, {
          [styles['is-highlighted']]: optionSnapshot.highlighted,
        })}
        {...optProps}
      >
        <div className={styles.optionInnerHolder}>
          <div>{optionData.name}</div>
        </div>
      </button>
    );
  };

  return (
    <div className={classNames(styles.clientRole, className)}>
      <Select
        multiple={multiselect}
        isSearchIcon={isSearchIcon}
        isTrackInputValue={false}
        testid="role_input_select"
        search={false}
        className={{
          wrapper: classNames(styles.search, wrapperClassName),
          value: classNames(styles.value, valueClassName),
          input: inputClassName,
        }}
        variant={variant}
        options={getOptions()}
        placeholder={placeholder || `Choose role${multiselect ? '(s)' : ''}`}
        isEmptyValue
        onSelect={onChange}
        value={multiselect ? getValues() : getValue()}
        renderOptionHandler={renderOption}
        arrowIconClassName={arrowIconClassName}
        searchIconClassName={styles.searchIcon}
        onKeyPress={onKeyPress}
        emptyMessage={() => null}
        isArrowIcon
        closeOnSelect={closeOnSelect}
        disabled={isViewMode}
      />
    </div>
  );
};

ClientRole.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  arrowIconClassName: PropTypes.string,
  onChange: PropTypes.func,
  valueClassName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      tagRole: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
    }),
    PropTypes.array,
  ]),
  onKeyPress: PropTypes.func,
  isSearchIcon: PropTypes.bool,
  multiselect: PropTypes.bool,
  isClientInfo: PropTypes.bool,
  isBuyerTransaction: PropTypes.bool,
  isSellerTransaction: PropTypes.bool,
  isCoordinatorInfo: PropTypes.bool,
  isParInfo: PropTypes.bool,
  isAssignRoleInfo: PropTypes.bool,
  isWhoIsRepresentingBuyerInfo: PropTypes.bool,
  isAgentInfo: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  isViewMode: PropTypes.bool,
  showFullAccessRoles: PropTypes.bool,
  isCampaignTask: PropTypes.bool,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
};

ClientRole.defaultProps = {
  className: '',
  inputClassName: '',
  wrapperClassName: '',
  onChange: () => {},
  onKeyPress: () => {},
  valueClassName: '',
  isSearchIcon: true,
  multiselect: false,
  isClientInfo: false,
  closeOnSelect: true,
  isViewMode: false,
  showFullAccessRoles: false,
  variant: 'light',
  placeholder: '',
  isCampaignTask: false,
};

export default ClientRole;
