export enum AgentDocumentTypes {
  Drafts = 'Drafts',
  PendingForms = 'Pending Forms',
  PreApprovals = 'Pre-Approvals',
  MessageAttachments = 'Message Attachments',
  Transactions = 'Transactions',
  Ungrouped = 'Ungrouped',
  ShowingAppointment = 'ShowingAppointment',
  OfferIteration = 'OfferIteration',
  Misc = 'Miscellaneous',
  Uploads = 'Uploads',
}

export interface AgentDocumentType {
  title: string;
  documentVaultUUID?: string;
  DocumentVaultUUID?: string;
  uploaderId?: number;
  id?: number;
  draftMeta?: {
    lastEdit: Date;
    formProcessId: number;
  };
  pendingMeta?: {
    isEdit?: boolean;
    isSign?: boolean;
    waitingOn?: string;
    formProcessId: number;
  };
  transactionMeta: {
    uploadedBy: string;
    uploadedAt: Date;
    transactionId: number;
    documentId?: string;
  };
  preApprovalMeta: {
    uploadedBy: string;
    uploadedAt: Date;
  };
  messageAttachmentMeta: {
    uploadedBy: string;
    uploadedAt: Date;
    messageThreadId: number;
  };
  offerMeta: {
    uploadedBy: string;
    uploadedAt: Date;
    OfferId: number;
  };
  showingMeta: {
    uploadedBy: string;
    uploadedAt: Date;
    showingId: number;
  };
  miscellaneousMeta?: {
    status: string;
    sealedDate: Date;
    formProcessId: number;
  };
}

export type DocumentResponseType = AgentDocumentType[];

export interface TransactionDocumentResponseType {
  [propertyAddress: string]: AgentDocumentType[];
}

export type AgentDocumentResponse = TransactionDocumentResponseType | DocumentResponseType;

export interface AgentDocumentSearchCriteria {
  name?: string;
  dateRange?: {
    startDate: Date;
    endDate: Date;
  };
}

export interface DocumentOptionUtils {
  optionUtils: {
    previewDocument: (documentVaultUUID: string, fileName: string) => void;
    downloadDocument: (documentVaultUUID: string) => void;
    shareDocument: (documentVaultUUID: string) => void;
    openMessageThread: (documentVaultUUID: string, threadId: number) => void;
    previewDraftForm?: () => void;
    handleOpenDeleteDocumentModal: (
      id: number | undefined,
      type: string,
      document?: AgentDocumentType,
    ) => void;
    handleOpenEditDocumentModal: (document: AgentDocumentType, type: string) => void;
    openFormDetailsModal: (formProcessId: number, type?: string) => void;
  };
}

export enum DocumentMetaKey {
  Drafts = 'draftMeta',
  'Pending Forms' = 'pendingMeta',
  Transactions = 'transactionMeta',
  'Message Attachments' = 'messageAttachmentMeta',
  Miscellaneous = 'miscellaneousMeta',
  Uploads = 'uploadDocumentMeta',
  'Pre-Approvals' = 'preApprovalMeta',
}

export enum TransactionFormTitle {
  Done = 'Completed',
  Editor = 'Drafts',
  Initiated = 'Drafts',
  Sign = 'Pending',
  Declined = 'Declined',
}
