/**
 * Validates an object against a predefined schema and returns a list of validation results.
 *
 * The function checks whether the object contains all required keys as defined in the schema,
 * verifies the type of each value, and returns a list of results indicating the validation status
 * for each key. The statuses are:
 * - `'correct'`: The key exists, and the value matches the expected type.
 * - `'mismatch'`: The key exists, but the value does not match the expected type.
 * - `'missing'`: The key is missing or the value is `null` or `undefined`.
 *
 * Supported types in the schema:
 * - Primitive types: `'string'`, `'number'`, `'boolean'`
 * - Special types: `'ISODateString'`, `'DateObject'` (validated via regex patterns)
 * - Complex types: `'object'`, `'array'`
 *
 * @param obj - The object to be validated.
 * @returns An array of validation results, each containing:
 * - `title`: The key name from the schema.
 * - `value`: The value from the object (formatted if necessary), or `'Missing'`.
 * - `status`: The validation status (`'correct'`, `'mismatch'`, or `'missing'`).
 *
 * @example
 * ```typescript
 * const obj = { Id: '123', NumBeds: 3, ListingDate: '2024-11-21' };
 * const results = validatePropertyObject(obj);
 * // results will be an array of validation results
 * ```
 */
export function validatePropertyObject(obj: any): ValidationResult[] {
  if (!obj) {
    return [];
  }

  const results: ValidationResult[] = [];
  const specialTypes: { [key: string]: (value: string) => boolean } = {
    DateObject: isValidDate,
    ISODateString: isValidISODateString,
  };

  // Define the required keys and their expected types
  const schema: { [key: string]: SchemaType } = {
    Id: 'string',
    ListingId: 'string',
    ListingKey: 'string',
    Market: 'string',
    MlsId: 'string',
    MosVersion: 'string',
    MosRelease: 'string',
    HomeType: 'string',
    PropertyType: 'string',
    PropertySubType: 'string',
    ActivityStatus: 'string',
    PreviousStatus: 'string',
    MlsStatus: 'string',
    NumBeds: 'number',
    NumBathsTotal: 'number',
    NumBathsFull: 'number',
    NumBathsHalf: 'number',
    NumBathOneQuarter: 'number',
    NumBathThreeQuarter: 'number',
    NumOfStories: 'number',
    SquareFeet: 'number',
    LotSizeSquareFeet: 'number',
    LotSizeAcres: 'number',
    LotSizeCalculatedFromRange: 'boolean',
    ListingDate: 'DateObject',
    SellingPrice: 'number',
    ListPrice: 'number',
    PreviousListPrice: 'number',
    ListPriceLow: 'number',
    PriceChangeTimeStamp: 'DateObject',
    PropertyListingStatusChangeTimeStamp: 'DateObject',
    ParkingTotal: 'number',
    GarageSpaces: 'number',
    AssociationMonthlyFee: 'number',
    YearBuilt: 'number',
    CASSAddress: 'object',
    Updated: 'DateObject',
  };

  // Iterate over the schema keys and validate the object
  for (const key in schema) {
    const title = key;
    let value: any;
    let status: ValidationStatus = 'correct';

    // If the key is not in the object or the value is null or undefined, it is missing
    if (!(key in obj) || [null, undefined].includes(obj[key])) {
      value = 'Missing';
      status = 'missing';
    }
    // For the special types (e.g., date fields), use the appropriate validation function
    else if (specialTypes[schema[key]] && !specialTypes[schema[key]](obj[key])) {
      value = `${obj[key]} (expected ${schema[key]})`;
      status = 'mismatch';
    }
    // For standard types, check the type directly
    else if (!specialTypes[schema[key]] && typeof obj[key] !== schema[key]) {
      value = applyFormatter(obj[key], schema[key]);
      value = `${value} (expected ${schema[key]})`;
      status = 'mismatch';
    } else {
      value = applyFormatter(obj[key], schema[key]);
    }

    results.push({ title, value, status });
  }

  return results;
}

/**
 * Type representing the expected types in the schema.
 */
type SchemaType =
  | 'string'
  | 'number'
  | 'boolean'
  | 'ISODateString'
  | 'object'
  | 'array'
  | 'DateObject';

/**
 * Type representing the validation status.
 */
type ValidationStatus = 'correct' | 'mismatch' | 'missing';

/**
 * Interface representing a validation result for a single key.
 */
interface ValidationResult {
  /** The key name from the schema */
  title: string;
  /** The value from the object, formatted if necessary, or 'Missing' */
  value: string | string[] | null;
  /** The validation status: 'correct', 'mismatch', or 'missing' */
  status: ValidationStatus;
}

const formatters: { [key: string]: (value: any) => any } = {
  array: formatArrayValue,
  object: formatObjectValue,
};

/**
 * Checks if a given string is a valid DateObject string in the format "YYYY-MM-DDTHH:mm:ss.sssZ".
 *
 * @param value - The string to validate.
 * @returns `true` if the string matches the DateObject format, `false` otherwise.
 *
 * @example
 * ```typescript
 * isValidDate("2024-11-21T11:24:25.545Z"); // returns true
 * ```
 */
function isValidDate(value: string): boolean {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value);
}

/**
 * Checks if a given string is a valid ISODateString in the format "YYYY-MM-DD".
 *
 * @param value - The string to validate.
 * @returns `true` if the string matches the ISODateString format, `false` otherwise.
 *
 * @example
 * ```typescript
 * isValidISODateString("2024-11-21"); // returns true
 * ```
 */
function isValidISODateString(value: string): boolean {
  return /^\d{4}-\d{2}-\d{2}$/.test(value);
}

/**
 * Applies a formatter function to the value based on its type, if a formatter is defined.
 *
 * @param value - The value to format.
 * @param type - The type of the value (e.g., 'array', 'object').
 * @returns The formatted value if a formatter exists for the type; otherwise, returns the original value.
 *
 * @example
 * ```typescript
 * const formattedValue = applyFormatter([1, 2, 3], 'array'); // returns "1, 2, 3"
 * ```
 */
function applyFormatter(value: any, type: string): any {
  if (type in formatters) {
    return formatters[type](value);
  }
  return value;
}

/**
 * Formats an array of strings into a single string with values separated by commas.
 *
 * @param value - The array of strings to format.
 * @returns A string with array elements joined by commas.
 *
 * @example
 * ```typescript
 * formatArrayValue(['apple', 'banana', 'cherry']); // returns "apple, banana, cherry"
 * ```
 */
export function formatArrayValue(value: string[]): string {
  return value.join(', ');
}

/**
 * Formats an object into an array of strings, where each string represents a key-value pair in the format "Key: Value".
 *
 * If a value is `null` or `undefined`, it will display as `'N/A'`.
 *
 * @param value - The object to format.
 * @returns An array of strings representing the object's key-value pairs, or `null` if the input is not an object.
 *
 * @example
 * ```typescript
 * formatObjectValue({ name: 'Alice', age: 30 });
 * // returns ["name: Alice", "age: 30"]
 * ```
 */
export function formatObjectValue(value: object): string[] | null {
  if (value == null || typeof value !== 'object') {
    return null;
  }

  return Object.entries(value).map(([key, val]) => `${key}: ${val ?? 'N/A'}`); // Handle null/undefined values
}
