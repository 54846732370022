import { apiServer } from 'settings/web-services/api';

export function postClarityFinancialData(cfg) {
  return apiServer.post('/ledgerReporting/getAggregatedGLData', cfg);
}

export function postClarityVolumeData(cfg) {
  return apiServer.post('/ledgerReporting/getAggregatedVolumeData', cfg);
}

export function postClarityLeadSourceData(cfg) {
  return apiServer.post('/ledgerReporting/getAggregatedTransactionData', cfg);
}
