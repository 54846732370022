import moment from 'moment';

export const isBeforeToday = (date) => moment(date).isBefore(new Date(), 'day');
export const isToday = (date) => moment(date).isSame(new Date(), 'day');
export const isYesterday = (date) => moment(date).isSame(moment().subtract(1, 'day'), 'day');
export const isTomorrow = (date) => moment(date).isSame(moment().add(1, 'day'), 'day');
export const isAfterToday = (date) => moment(date).isAfter(new Date(), 'day');
export const isThisWeek = (date) => {
  const startOfWeek = moment().startOf('week');
  const endOfWeek = moment().endOf('week');

  return moment(date).isBetween(startOfWeek, endOfWeek, null, '[]');
};
export const isAfterThisWeek = (date) => {
  const endOfWeek = moment().endOf('week');
  return moment(date).isAfter(endOfWeek, 'day');
};
export const formatDate = (date, format) => moment(date).format(format);
export const currentYear = new Date().getFullYear();

export const getTimeAgo = (datetime) => {
  // Calculate the difference in mins/hours/days from the current time
  var hoursDiff = moment().diff(datetime, 'hours');

  if (hoursDiff <= 0) {
    return moment().diff(datetime, 'minutes') + 'm ago';
  } else if (hoursDiff > 24) {
    return moment().diff(datetime, 'days') + 'd ago';
  } else {
    return hoursDiff + 'h ago';
  }
};

export const timeDifferenceFromNow = (receivedDate) => {
  const now = new Date();
  const date = new Date(receivedDate);

  const diffTime = date - now; // Positive for future, negative for past
  const absDiffTime = Math.abs(diffTime);

  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30.44;

  const getTimeMessage = (value, unit) => {
    const plural = value !== 1 ? 's' : '';
    return `${value} ${unit}${plural}`;
  };

  if (absDiffTime >= month) {
    const months = Math.floor(absDiffTime / month);
    return diffTime < 0
      ? `${getTimeMessage(months, 'month')} ago`
      : `in ${getTimeMessage(months, 'month')}`;
  } else if (absDiffTime >= week) {
    const weeks = Math.floor(absDiffTime / week);
    return diffTime < 0
      ? `${getTimeMessage(weeks, 'week')} ago`
      : `in ${getTimeMessage(weeks, 'week')}`;
  } else if (absDiffTime >= day) {
    const days = Math.floor(absDiffTime / day);
    return diffTime < 0
      ? `${getTimeMessage(days, 'day')} ago`
      : `in ${getTimeMessage(days, 'day')}`;
  } else if (absDiffTime >= hour) {
    const hours = Math.floor(absDiffTime / hour);
    return diffTime < 0
      ? `${getTimeMessage(hours, 'hour')} ago`
      : `in ${getTimeMessage(hours, 'hour')}`;
  } else if (absDiffTime >= minute) {
    const minutes = Math.floor(absDiffTime / minute);
    return diffTime < 0
      ? `${getTimeMessage(minutes, 'minute')} ago`
      : `in ${getTimeMessage(minutes, 'minute')}`;
  } else {
    const seconds = Math.floor(absDiffTime / second);
    return diffTime < 0
      ? `${getTimeMessage(seconds, 'second')} ago`
      : `in ${getTimeMessage(seconds, 'second')}`;
  }
};
