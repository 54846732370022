import Api from 'store/effects/core/api';
import {
  deleteTransactionDocumentAction,
  setTransactionDocumentsFilterAction,
  setTransactionDocumentsSortAction,
  resetTransactionDocumentsFiltersAction,
  editTransactionDocumentAction,
  setTransactionDocumentsPageAction,
  resetTransactionDocumentsAction,
  setTransactionDocumentsFilterPendingAction,
} from 'store/actions/transactions';
import { getState } from 'store';
import {
  deleteTransactionDocument,
  editTransactionDocument,
} from 'api/transactions/documents/update';
import { get } from 'lodash-es';

export const setTransactionDocumentsFilterEffect = (cfg, options) => (dispatch) => {
  dispatch(setTransactionDocumentsFilterAction({ ...cfg, options }));
};

export const sortTransactionDocumentsEffect = (cfg) => (dispatch) => {
  dispatch(setTransactionDocumentsSortAction(cfg));
};

export const setTransactionDocumentsFilterLoadingEffect = (cfg) => (dispatch) => {
  dispatch(setTransactionDocumentsFilterPendingAction(cfg));
};

export const setTransactionDocumentsPageEffect = (cfg) => (dispatch) => {
  dispatch(setTransactionDocumentsPageAction(cfg));
};

export const deleteTransactionDocumentEffect = (cfg, options, cb) => {
  const transactionData = get(getState(), 'transaction.data');
  const sendRequest = Api.execResult({
    action: deleteTransactionDocumentAction,
    method: deleteTransactionDocument,
  });

  const config = {
    transactionId: transactionData?.Id,
    documentId: cfg?.id,
  };

  return sendRequest(config, options, cb);
};

export const resetTransactionDocumentsFiltersEffect = () => (dispatch) => {
  dispatch(resetTransactionDocumentsFiltersAction());
};

export const resetTransactionDocumentsEffect = () => (dispatch) => {
  dispatch(resetTransactionDocumentsAction());
};

export const editTransactionDocumentEffect = (accessToEveryone, cfg, options, cb) => {
  const transactionData = get(getState(), 'transaction.data');
  const sendRequest = Api.execResult({
    action: editTransactionDocumentAction,
    method: editTransactionDocument,
  });

  const config = {
    transactionId: transactionData?.Id,
    documentId: cfg?.Id,
    Title: cfg?.Title,
    Category: cfg?.customTag || cfg?.Category || '',
    File: {
      ...(cfg?.Meta || {}),
      Data: cfg?.Meta?.Data ? btoa(cfg?.Meta?.Data) : undefined,
    },
    UsersWithAccess: cfg?.UsersWithAccess,
    AccessToEveryone: accessToEveryone,
  };

  return sendRequest(config, options, cb);
};

export const deleteClientTransactionDocumentEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: deleteTransactionDocumentAction,
    method: deleteTransactionDocument,
  });

  return sendRequest(cfg, options, cb);
};

export const editTransactionDocumentClientProfileEffect = (cfg, options, cb) => {
  const sendRequest = Api.execResult({
    action: editTransactionDocumentAction,
    method: editTransactionDocument,
  });

  const config = {
    transactionId: cfg?.transactionId,
    documentId: cfg?.documentId,
    Title: cfg?.Title,
    File: cfg?.File,
    AccessToEveryone: cfg?.AccessToEveryone,
    UsersWithAccess: cfg?.UsersWithAccess,
  };

  return sendRequest(config, options, cb);
};
