import { apiServer } from 'settings/web-services/api';

export const getPropertyReferenceData = (query: { Archive?: boolean }) => {
  return apiServer.get('/propertyreferencedata/portfolio', {
    params: query,
  });
};

export const getPropertyReferenceStats = () => {
  return apiServer.get('/propertyreferencedata/portfolio-stats');
};

export const getPropertyReferenceDetails = (params) => {
  return apiServer.get('/propertyreferencedata/portfolio/details', {
    params,
  });
};

export const getPropertyReferenceNeighborhood = (queryParams) => {
  return apiServer.get(`/propertyreferencedata/portfolio/neighborhood-activity${queryParams}`);
};

export const getPropertyReferenceNeighborhoodActivities = (body) => {
  return apiServer.post('/propertyActivity/searchPropertyActivities', body);
};

export const updateClientProperty = (body: {
  clientId: number;
  addressId: number;
  isArchive: boolean;
}) => {
  const { clientId, addressId, ...rest } = body;
  return apiServer.put(`/user/client/${clientId}/address/${addressId}`, rest);
};
