import {
  getAddressNeighborhoodActivity,
  getAddressValuationDetails,
  getAddValuationHomeValue,
  getAddValuationRentalValue,
  getEditNeighborhoodRadiusValue,
} from 'api/intel';
import {
  getAddressNeighborhoodActivityAction,
  getAddressValuationDetailsAction,
  getAddValuationValueAction,
  getEditNeighborhoodRadiusAction,
  getReminderByIdAction,
} from 'store/actions/intel';
import Api from '../core/api';
import { getReminderById } from 'api/reminder';

export const requestGetAddressValuationDetailsEffect = (
  cfg,
  options?: { silent?: boolean; showError?: boolean },
  cb?: any,
) => {
  const requestParams = {
    action: getAddressValuationDetailsAction,
    method: getAddressValuationDetails,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetRemindersClientEffect = (
  cfg,
  options?: { silent?: boolean; showError?: boolean },
  cb?: any,
) => {
  const requestParams = {
    action: getReminderByIdAction,
    method: getReminderById,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetAddressNeighborhoodActivityEffect = (
  cfg,
  options: { silent?: boolean } = {},
  cb?: any,
) => {
  const requestParams = {
    action: getAddressNeighborhoodActivityAction,
    method: getAddressNeighborhoodActivity,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestEditNeighborhoodRadiusEffect = (
  cfg,
  options?: { silent?: boolean },
  cb?: any,
) => {
  const requestParams = {
    action: getEditNeighborhoodRadiusAction,
    method: getEditNeighborhoodRadiusValue,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestAddValuationHomeValueEffect = (
  cfg,
  options?: { silent?: boolean },
  cb?: any,
) => {
  const requestParams = {
    action: getAddValuationValueAction,
    method: getAddValuationHomeValue,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestAddValuationRentalValueEffect = (
  cfg,
  options?: { silent?: boolean },
  cb?: any,
) => {
  const requestParams = {
    action: getAddValuationValueAction,
    method: getAddValuationRentalValue,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};
