import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LocationService } from 'services';
import { Input, FormTitle, FormGroup, Button } from 'components';
import { loginEffect } from 'store/effects/user';
import { routes } from 'settings/navigation/routes';
import { PENDING } from 'settings/constants/apiState';
import { getUserSelector } from 'store/selectors/user';
import { getOptionalValue } from 'helpers';
import SignUpLink from '../SignUpLink';
import { ValidationSchema } from './validation';
import { MultiFactorAuthModal } from '../MultiFactorAuthModal/MultiFactorAuthModal';
import styles from './styles.module.scss';

const locationService = new LocationService();

const Form = () => {
  const user = useSelector(getUserSelector);
  const dispatch = useDispatch();
  const params = useParams();

  const [isMultiFactorAuthModalOpened, setMultiFactorAuthModalOpened] = useState(false);
  const [email, setEmail] = useState(false);
  const [authMessage, setAuthMessage] = useState(undefined);
  const [contextMessage, setContextMessage] = useState(undefined);

  const location = useLocation();

  const { redirectUrl } = locationService.setLocation(location).getQuery();
  const enableFullRedirect = location?.search?.includes?.('source=kits');
  const fullRedirectUrl = location?.search?.replace?.('?redirectUrl=', '');

  useEffect(() => {
    if (params.context === 'reset')
      setContextMessage('Your password has been reset.  Please sign-in.');
  }, []);

  const openMFAModal = () => {
    setMultiFactorAuthModalOpened(true);
  };

  const onSubmitMfa = (code) => {
    setMultiFactorAuthModalOpened(false);
    const loginParams = {
      email: formik.values.email,
      password: formik.values.password,
      code: code,
    };
    onSubmit(loginParams);
  };

  const onSubmit = (values) => {
    setAuthMessage(undefined);
    setEmail(values.email);
    dispatch(
      loginEffect(
        values,
        getOptionalValue(window.innerWidth && window.innerHeight, {
          headers: {
            'viewport-width': window.innerWidth,
            'viewport-height': window.innerHeight,
          },
        }),
        (err, resp) => {
          setContextMessage(undefined); //clear context messages if error
          if (err) {
            if (err?.response?.status === 400 && err?.response?.data?.mfaRequired === true) {
              openMFAModal(); //MFA Required
            } else if (err?.response?.status === 403) {
              if (err.response?.data?.code === 'UserDeactivated') {
                setAuthMessage(
                  'There is an issue with your account. Please contact support@mosaik.io',
                );
              } else if (values.code) {
                //Invalid MFA Code
                setAuthMessage('Your authentication code is incorrect.  Please try again.');
              } else {
                //Invalid Login/Pass
                setAuthMessage('Email or password was not recognized.');
              }
            } else if (
              err?.response?.status === 400 &&
              !err?.response?.data?.mfaRequired === true
            ) {
              //Show MFA Error
              setAuthMessage(
                'Your account is locked. Please try again in 30 minutes or contact support@mosaik.io',
              );
            } else {
              setAuthMessage(
                'Mosaik is experiencing unusually high traffic.  Please try to sign in later.',
              );
            }
          }
        },
        enableFullRedirect && redirectUrl ? fullRedirectUrl : redirectUrl,
      ),
    );
  };

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit,
  });

  const isPending = user.state === PENDING;

  return (
    <FormGroup testid="login_form" className={styles.login}>
      <FormTitle className={styles.title}>Sign in</FormTitle>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Input
            type="text"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="Email Address"
            error={formik.touched.email ? formik.errors.email : ''}
            disabled={isPending}
            testid="email"
            variant={Input.LIGHT}
            className={styles.inputWrapper}
            replacePlaceholderWithLabelWhenHasValue
          />
          <Input
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder="Password"
            error={formik.touched.password ? formik.errors.password : ''}
            disabled={isPending}
            testid="password"
            variant={Input.LIGHT}
            className={styles.inputWrapper}
            replacePlaceholderWithLabelWhenHasValue
          />
        </div>
        <Link
          testid="forgot_password"
          className={styles.forgotPasswordLink}
          to={routes.forgotPassword}
        >
          Forgot password
        </Link>
        {authMessage && <p className={styles.authMessage}>{authMessage}</p>}
        {contextMessage && <p className={styles.contextMessage}>{contextMessage}</p>}
        <Button
          testid="login"
          type="submit"
          className={styles.submit}
          title="Sign in"
          isPending={isPending}
          loaderClassName={styles.loader}
        />
        {/* Hide Signup Link (/signup) for launch
        <SignUpLink className={styles.signup} />
        */}
      </form>
      <MultiFactorAuthModal
        isMultiFactorAuthModalOpened={isMultiFactorAuthModalOpened}
        email={email}
        onCloseModal={() => {
          setMultiFactorAuthModalOpened(false);
        }}
        onCodeSubmitted={async (code) => {
          onSubmitMfa(code);
        }}
      />
    </FormGroup>
  );
};

export default Form;
