import React, { ComponentType, useEffect } from 'react';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { featureFlags } from 'utils/featureFlags';
import { MapDraw } from 'components/Icons/MapDraw';
import { Divider } from 'components-antd';
import { Action } from 'redux';
import Undo from 'components/Icons/Undo';
import Close from './CloseIcon';
interface MapDrawControlButtonWithTextProps {
  onClick: () => void;
  iconComponent: ComponentType<any>;
  text: string;
}

const MapDrawControlButtonWithText: React.FC<MapDrawControlButtonWithTextProps> = (props) => {
  const Icon = props.iconComponent;
  return (
    <a onClick={props.onClick} className={styles.buttonWithText}>
      <div className={styles.iconWrapper}>
        <Icon className={styles.icon} />
      </div>
      <span className={styles.textWrapper}>{props.text}</span>
    </a>
  );
};

const MapDrawControlButtonWithTextAndHint: React.FC<
  MapDrawControlButtonWithTextProps & { hintText: string; reverseIcon: boolean }
> = (props) => {
  const Icon = props.iconComponent;
  return (
    <a onClick={props.onClick} className={styles.buttonWithText}>
      <span className={styles.textWrapper}>{props.hintText}</span>

      {props.hintText && <Divider type="vertical" className={styles.divider} />}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: props.reverseIcon ? 'row-reverse' : 'row',
          gap: '10px',
        }}
      >
        <div className={styles.iconWrapper}>
          <Icon className={styles.icon} />
        </div>
        <span className={styles.textWrapper}>{props.text}</span>
      </div>
    </a>
  );
};

interface MapDrawControlsProps {
  multipleDraw: boolean;
  setPolygonLocations: any;
  polygonLocations: any;
  polygonsUpdateFn?: any;
  getDrawnPolygonSelectorFn: (state: any) => object;
  getIsMapDrawingModeSelectorFn: (state: any) => boolean;
  toggleMapDrawingModeDispatchFn: (newValue?: boolean) => Action<unknown>;
  setDrawnPolygonDispatchFn: (polygon: object) => Action<unknown>;
  cleanDrawnPolygonDispatchFn: () => Action<unknown>;
  showDrawHint?: boolean;
  setShowDrawHint?: any;
}

export const MapDrawControls: React.FC<MapDrawControlsProps> = (props) => {
  const {
    getIsMapDrawingModeSelectorFn,
    toggleMapDrawingModeDispatchFn,
    polygonsUpdateFn,
    setPolygonLocations,
    polygonLocations,
    multipleDraw,
    showDrawHint,
    setShowDrawHint,
  } = props;
  const dispatch = useDispatch();
  const isDrawingMode = useSelector(getIsMapDrawingModeSelectorFn);

  const handleOpenChange = () => {
    if (!isDrawingMode) setShowDrawHint(true);
    dispatch(toggleMapDrawingModeDispatchFn());
  };

  const handleDeletePolygon = (locations = []) => {
    setPolygonLocations([...locations]);
    if (polygonsUpdateFn) polygonsUpdateFn([...locations]);
  };

  useEffect(() => {
    return () => {
      dispatch(toggleMapDrawingModeDispatchFn(false));
    };
  }, []);

  if (!featureFlags.customSearchPolygonDrawing) {
    return null;
  }

  return (
    <div className={styles.buttonsWrapper}>
      {!!polygonLocations?.length && (
        <>
          <MapDrawControlButtonWithText
            onClick={() => handleDeletePolygon(polygonLocations.slice(0, -1))}
            iconComponent={Undo}
            text="Remove Boundary"
          />
          {multipleDraw && (
            <MapDrawControlButtonWithText
              onClick={() => handleDeletePolygon()}
              iconComponent={Close}
              text="Clear All"
            />
          )}
        </>
      )}
      {isDrawingMode ? (
        <MapDrawControlButtonWithTextAndHint
          onClick={handleOpenChange}
          iconComponent={Close}
          reverseIcon={true}
          hintText={showDrawHint ? 'Click to draw boundaries.' : ''}
          text="Exit"
        />
      ) : polygonLocations?.length && !multipleDraw ? null : (
        <MapDrawControlButtonWithText
          onClick={handleOpenChange}
          iconComponent={MapDraw}
          text={polygonLocations?.length ? 'Draw Another' : 'Draw'}
        />
      )}
    </div>
  );
};
