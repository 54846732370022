import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { getSearchDrawerCommuteIdSelector } from 'store/selectors/searchResults';
import { Radio } from 'components-antd';

import {
  Locations,
  MinMax,
  YearMinMax,
  PropertyType,
  Input,
  Select,
  LotSizeMinMax,
  Checkbox,
  Button,
} from 'components';
import { sortStrings, sortLocations, sortStatus } from 'helpers';
import {
  propertySoldOptions,
  propertyStatusOptionsWithoutClosed,
} from 'settings/constants/properties';
import { ValidationSchema } from './validation';
import { mergeInitialValues, softCriteriaPrefill } from './helpers';

import styles from './styles.module.scss';
import { LocationType } from 'types';
import {
  criteriaTypes,
  rentOptions,
  defaultPropertyTypeSelectionForRent,
  defaultStatus,
  soldStatus,
} from '../constants';
import { useEffect, useState } from 'react';
import {
  LocationAttributes,
  PropertyAttributes,
} from 'components/ClientsDrawer/InviteClient/StageParts/InviteClientWishList/components';
import Icons from 'components/ClientsDrawer/InviteClient/StageParts/InviteClientWishList/Icons/Icons';
import { getSearchInstancesFormattedSelector } from 'store/selectors/mySearches';
import { getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { selectedSearchAction } from 'store/actions/feedv3';
import { setCriteriaTypeAction } from 'store/actions/propertyCriteria';
import { useSearchInstanceV3Effect } from 'pages/Properties/ClientFeed/hooks/useSearchInstanceV3Effect';
import { PREF_TYPE, prefsIds } from 'settings/constants/preferences';
import DoubleHome from 'components/Icons/SaveIcon';
import { isEqual } from 'lodash-es';
import { getUserRolesMapSelector } from 'store/selectors/user';

const Criteria = ({ onSubmit, isPending, search, setSoftCriteria, softCriteria, isEditMode }) => {
  const { isClient } = useSelector(getUserRolesMapSelector);
  const commuteId = useSelector(getSearchDrawerCommuteIdSelector);
  const dispatch = useDispatch();
  const [cachedStatus, setCachedStatus] = useState(null);
  const [cachedPropertyType, setCachedPropertyType] = useState(null);
  const [cachedPrice, setCachedPrice] = useState({ Min: undefined, Max: undefined });

  const formik = useFormik({
    initialValues: mergeInitialValues(search),
    validationSchema: ValidationSchema,
    onSubmit(values) {
      onSubmit(values);
    },
  });

  const [criteriaType, setCriteriaType] = useState(criteriaTypes.ForSale);

  useEffect(() => {
    formik.setValues(mergeInitialValues(search));
    const isRentHomeType = rentOptions.some((option) => search?.HomeType?.includes(option.value));
    setCachedStatus(isClosed(formik.values.Status) ? defaultStatus : formik.values.Status);
    setCachedPropertyType(formik.values.HomeType);
    setCachedPrice({ Min: formik.values.PriceRange.Min, Max: formik.values.PriceRange.Max });
    setSoftCriteria(softCriteriaPrefill(search || {}));
    let newCriteriaType = isEqual(search?.Status, ['Closed'])
      ? criteriaTypes.Sold
      : isRentHomeType
      ? criteriaTypes.ForRent
      : criteriaTypes.ForSale;
    setCriteriaType(newCriteriaType);
  }, [search]);

  // const parkingOptions = (
  //   <div className={styles.parkingSpaces}>
  //     <div className={styles.row}>
  //       <Checkbox
  //         label="Garage Parking Preferred"
  //         direction={Checkbox.DIRECTION_RIGHT}
  //         checked={formik?.values?.GarageOnly}
  //         onChange={(e) => formik.setFieldValue('GarageOnly', e.target.checked)}
  //       />
  //       <Checkbox
  //         label="RV Parking"
  //         direction={Checkbox.DIRECTION_RIGHT}
  //         // checked={rvParking}
  //         // onChange={(e) => setRVParking(e.target.checked)}
  //       />
  //     </div>
  //     {formik?.values?.GarageOnly && (
  //       <Checkbox
  //         label="Attached Garage Preferred"
  //         direction={Checkbox.DIRECTION_RIGHT}
  //         // checked={attachedGarage}
  //         // onChange={(e) => setAttachedGarage(e.target.checked)}
  //       />
  //     )}
  //   </div>
  // );

  const flatValue = (propName) => formik?.values?.[propName];

  if (commuteId) {
    return null;
  }

  const { data: SearchInstancesFormatted, isLoading } = useSelector(
    getSearchInstancesFormattedSelector,
  );
  const ActiveSavedSearches = SearchInstancesFormatted.filter(({ status }) => status != 'Inactive');

  const { selectedSearch } = useSelector(getFeedMetaSelectorV3);

  const addSoftCriteria = (type, id, value, data, checkboxRemove, allAddtionalOptionsModify) => {
    let criteria = { type, value };
    if (data) criteria = { ...criteria, data };
    const oldCriteria = { ...softCriteria };
    if (allAddtionalOptionsModify?.length) {
      // Update all additional options at once
      let updatedsoftCriteria = {};
      allAddtionalOptionsModify?.forEach((item) => {
        updatedsoftCriteria = {
          ...updatedsoftCriteria,
          ...(id === prefsIds.waterFront ? { [id]: { type, value } } : {}),
          [item]: { type, value },
        };
      });
      setSoftCriteria({ ...oldCriteria, ...updatedsoftCriteria });
      return;
    }

    if (checkboxRemove?.length) {
      checkboxRemove.map((id) => delete oldCriteria[id]);
      setSoftCriteria(oldCriteria);
    }

    if (
      type === prefsIds.commutes ||
      type === prefsIds.proximityToImportantLocations ||
      type === prefsIds.school ||
      type === PREF_TYPE.keywordPrefs
    ) {
      setSoftCriteria({
        ...oldCriteria,
        [type]: data,
      });
    } else if (id === prefsIds.parking) {
      let payload = {};
      if (value != 'N/A') {
        payload = {
          value: value,
          data: { ...oldCriteria?.[id]?.data, ...data },
          type: type,
        };
      } else {
        delete oldCriteria[prefsIds.rvParking];
      }
      setSoftCriteria({
        ...oldCriteria,
        [id]: payload,
      });
    } else if (criteria?.data) {
      const { data, ...restCriteria } = criteria;
      data.map(({ id }) => {
        setSoftCriteria({
          ...oldCriteria,
          [id]: restCriteria,
        });
      });
    } else if (
      id &&
      id !== prefsIds.kitchenCabinetColor &&
      id !== prefsIds.heaterSource &&
      id !== prefsIds.waterSource &&
      id !== prefsIds.waste
    ) {
      setSoftCriteria({
        ...oldCriteria,
        [id]: criteria,
      });
    }
  };
  const isClosed = (status) => {
    return isEqual([{ name: 'Closed', value: 'Closed' }], status);
  };

  const handleCriteriaChange = (e) => {
    const value = e.target.value;
    if (!isEqual(value, criteriaType)) {
      setCriteriaType(value);
      dispatch(setCriteriaTypeAction(value));
    }
    const status = isClosed(cachedStatus) ? defaultStatus : cachedStatus;
    switch (value) {
      case criteriaTypes.ForSale:
        formik.setFieldValue('PriceRange.Min', cachedPrice.Min);
        formik.setFieldValue('PriceRange.Max', cachedPrice.Max);
        formik.setFieldValue('Status', status);
        formik.setFieldValue('HomeType', cachedPropertyType);
        break;

      case criteriaTypes.ForRent:
        setCachedPrice({ Min: formik.values.PriceRange.Min, Max: formik.values.PriceRange.Max });
        formik.setFieldValue('Status', []);
        formik.setFieldValue('PriceRange.Min', undefined);
        formik.setFieldValue('PriceRange.Max', undefined);
        if (!rentOptions.some((option) => search?.HomeType?.includes(option.value))) {
          formik.setFieldValue('HomeType', defaultPropertyTypeSelectionForRent);
        }
        break;

      case criteriaTypes.Sold:
        formik.setFieldValue('PriceRange.Min', cachedPrice.Min);
        formik.setFieldValue('PriceRange.Max', cachedPrice.Max);
        formik.setFieldValue('Status', soldStatus);
        formik.setFieldValue('HomeType', cachedPropertyType);
        break;

      default:
        break;
    }
  };

  const SizeComp = (
    <>
      <MinMax
        className={styles.field}
        label="Beds"
        nameMin="NumBedroomsRange.Min"
        nameMax="NumBedroomsRange.Max"
        onChangeMin={formik.handleChange}
        onChangeMax={formik.handleChange}
        valueMin={formik.values?.NumBedroomsRange?.Min}
        valueMax={formik.values?.NumBedroomsRange?.Max}
        placeholderMin="No min"
        placeholderMax="No max"
        variant={Input.LIGHT_ROUND}
        error={formik.errors.NumBedroomsRange}
      />
      <MinMax
        className={styles.field}
        label="Baths"
        nameMin="NumBathroomsRange.Min"
        nameMax="NumBathroomsRange.Max"
        onChangeMin={formik.handleChange}
        onChangeMax={formik.handleChange}
        valueMin={formik.values?.NumBathroomsRange?.Min}
        valueMax={formik.values?.NumBathroomsRange?.Max}
        placeholderMin="No min"
        placeholderMax="No max"
        variant={Input.LIGHT_ROUND}
        error={formik.errors.NumBathroomsRange}
      />
      <MinMax
        className={styles.field}
        label="Property Size"
        nameMin="SquareFeetRange.Min"
        nameMax="SquareFeetRange.Max"
        onChangeMin={formik.handleChange}
        onChangeMax={formik.handleChange}
        valueMin={formik.values?.SquareFeetRange?.Min}
        valueMax={formik.values?.SquareFeetRange?.Max}
        placeholderMin="No min"
        placeholderMax="No max"
        disabled={isPending}
        variant={Input.LIGHT_ROUND}
        icon={'sqft'}
        error={formik.errors.SquareFeetRange}
      />
      <LotSizeMinMax
        isReset
        className={styles.field}
        label="Lot Size"
        nameMin="LotSizeRange.Min"
        nameMax="LotSizeRange.Max"
        placeholderMin="No min"
        placeholderMax="No max"
        onChangeMin={(val) =>
          formik.setFieldValue('LotSizeRange', {
            ...flatValue('LotSizeRange'),
            Min: val,
          })
        }
        onChangeMax={(val) =>
          formik.setFieldValue('LotSizeRange', {
            ...flatValue('LotSizeRange'),
            Max: val,
          })
        }
        valueMin={formik.values?.LotSizeRange?.Min}
        valueMax={formik.values?.LotSizeRange?.Max}
        disabled={isPending}
        variant={Input.LIGHT_ROUND}
        selectClassName={styles.selectWrapper}
      />
    </>
  );

  const getSearchInstanceV3 = useSearchInstanceV3Effect();

  const handleSearchInstanceChange = (item) => {
    dispatch(selectedSearchAction({ selectedSearch: item }));
    dispatch(getSearchInstanceV3(item));
  };

  return (
    <div>
      <form id="Criteria" onSubmit={formik.handleSubmit}>
        <div className={styles.content}>
          {isEditMode && isClient ? (
            <>
              <Select
                options={ActiveSavedSearches.map((item) => ({ ...item, value: item?.id }))}
                value={selectedSearch?.id}
                onSelect={(target, val) => {
                  handleSearchInstanceChange(val);
                }}
                variant={Select.LIGHT_ROUND}
                className={{ wrapper: styles.savedSearchSelect }}
                customIcon={<DoubleHome />}
              />
              <div className={styles.divider} />
            </>
          ) : null}
          <Radio.Group
            className={styles.radioGroupCustom}
            value={criteriaType}
            name='input[type="radio"]'
            optionType="button"
            onChange={handleCriteriaChange}
            size="large"
          >
            {Object.values(criteriaTypes).map((value) => (
              <Radio.Button value={value}>{value}</Radio.Button>
            ))}
          </Radio.Group>
          <Locations
            shouldFilterStateLevelResults={true}
            className={styles.field}
            placeholderClassName={styles.border}
            searchWrapperClassName={styles.border}
            valuesWrapperClassName={styles.border}
            name="Locations"
            label="Locations"
            allowedSmartyAddress={true}
            onResult={(result, preparedData) => {
              if (preparedData?.length) preparedData.sort(sortLocations);
              formik.setFieldValue('Locations', preparedData);
            }}
            error={formik.touched.Locations ? formik.errors.Locations : ''}
            disabled={isPending}
            value={formik.values.Locations?.filter(
              (loc) => loc.Type !== LocationType.ViewportCoordinates,
            )}
            variant={Locations.ROUND}
            rounded={true}
          />
          {criteriaType === criteriaTypes.Sold ? (
            <Select
              name="SoldDateRange"
              label="Sold Within"
              className={{ wrapper: classNames(styles.field, styles.statusField) }}
              options={propertySoldOptions}
              value={formik.values.SoldDateRange}
              onSelect={(_, { value }) => {
                formik.setFieldValue('SoldDateRange', value);
              }}
              disabled={isPending}
              variant={Select.LIGHT_ROUND}
            />
          ) : (
            <Select
              name="Status"
              label="Status"
              multiple={true}
              closeOnSelect={false}
              className={{ wrapper: classNames(styles.field, styles.statusField) }}
              options={propertyStatusOptionsWithoutClosed}
              value={formik.values.Status}
              onSelect={(_, val) => {
                if (val?.length) val.sort(sortStatus);
                formik.setFieldValue('Status', val);
                if (!isClosed(val)) setCachedStatus(val);
              }}
              disabled={isPending}
              variant={Select.LIGHT_ROUND}
            />
          )}
          <MinMax
            className={styles.field}
            label="Price"
            nameMin="PriceRange.Min"
            nameMax="PriceRange.Max"
            onChangeMin={formik.handleChange}
            onChangeMax={formik.handleChange}
            valueMin={formik.values?.PriceRange?.Min}
            valueMax={formik.values?.PriceRange?.Max}
            placeholderMin="No min"
            placeholderMax="No max"
            prefix="$"
            disabled={isPending}
            testidMin="price_min"
            testidMax="price_max"
            variant={Input.LIGHT_ROUND}
            error={formik.errors.PriceRange}
          />
          <PropertyType
            type={'dropdown'}
            className={classNames(styles.field, styles.propertyWrapper)}
            label="Property Type"
            onChange={(type) => {
              if (type?.length) type.sort(sortStrings);
              formik.setFieldValue('HomeType', type);
              if (criteriaType !== criteriaTypes.ForRent) setCachedPropertyType(type);
            }}
            disabled={isPending}
            error={formik.touched.HomeType ? formik.errors.HomeType : ''}
            value={formik.values?.HomeType}
            propertyTypeWrapperClassName={styles.filterPropertyWrapper}
            itemClassName={styles.filterItem}
            criteriaType={criteriaType}
          />
          {criteriaType === criteriaTypes.Sold && (
            <>
              {SizeComp}
              {/* <MinMax
                className={styles.field}
                label="Parking"
                nameMin="ParkingSpacesRange.Min"
                nameMax="ParkingSpacesRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.ParkingSpacesRange?.Min}
                valueMax={formik.values?.ParkingSpacesRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                disabled={isPending}
                variant={Input.LIGHT_ROUND}
              /> */}
              {/* {parkingOptions} */}
              <YearMinMax
                isReset
                className={styles.field}
                label="Year Build"
                placeholderMin="No min"
                placeholderMax="No max"
                onChangeMin={(val) =>
                  formik.setFieldValue('YearBuiltRange', {
                    ...flatValue('YearBuiltRange'),
                    Min: val,
                  })
                }
                onChangeMax={(val) =>
                  formik.setFieldValue('YearBuiltRange', {
                    ...flatValue('YearBuiltRange'),
                    Max: val,
                  })
                }
                valueMin={formik.values?.YearBuiltRange?.Min}
                valueMax={formik.values?.YearBuiltRange?.Max}
                disabled={isPending}
                variant={Input.LIGHT_ROUND}
              />
            </>
          )}

          <div className={styles.hoaContainer}>
            {formik.values?.HOARange?.Max !== 0 && (
              <MinMax
                className={styles.field}
                label="HOA"
                nameMin="HOARange.Min"
                nameMax="HOARange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.HOARange?.Min}
                valueMax={formik.values?.HOARange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                variant={Input.LIGHT_ROUND}
                error={formik.errors.HOARange}
              />
            )}
            <div
              className={classNames(styles.checkboxContainer, {
                [styles.padding]: formik.values?.HOARange?.Max !== 0,
              })}
            >
              <Checkbox
                checked={formik.values?.HOARange?.Max === 0}
                onChange={(e) => {
                  const checked = e.target.checked;

                  if (checked) {
                    formik.setFieldValue('NoHOA', true);
                    formik.setFieldValue('HOARange.Min', '');
                    formik.setFieldValue('HOARange.Max', 0);
                  } else {
                    formik.setFieldValue('HOARange.Min', '');
                    formik.setFieldValue('HOARange.Max', '');
                    formik.setFieldValue('NoHOA', false);
                  }
                }}
                label="No HOA"
                direction="right"
                labelTextClassName={styles.label}
              />
            </div>
          </div>

          {criteriaType === criteriaTypes.Sold && (
            <>
              <MinMax
                className={styles.field}
                thousandSeparator={false}
                label="Stories"
                nameMin="StoriesRange.Min"
                nameMax="StoriesRange.Max"
                onChangeMin={formik.handleChange}
                onChangeMax={formik.handleChange}
                valueMin={formik.values?.StoriesRange?.Min}
                valueMax={formik.values?.StoriesRange?.Max}
                placeholderMin="No min"
                placeholderMax="No max"
                disabled={isPending}
                variant={Input.LIGHT_ROUND}
                error={formik.errors.StoriesRange}
              />
              {/* <Input
                name="MaxDaysOnMarket"
                className={styles.field}
                placeholder="No max"
                isNumericString
                isNumberFormat
                label="Days on Market"
                onChange={formik.handleChange}
                value={formik.values.MaxDaysOnMarket}
                disabled={isPending}
                variant={Input.LIGHT_ROUND}
              /> */}
            </>
          )}
          {criteriaType !== criteriaTypes.Sold && (
            <div>
              <PropertyAttributes
                softCriteria={softCriteria}
                addSoftCriteria={addSoftCriteria}
                className={styles.attributes}
                additionalSections={[
                  {
                    key: 'size',
                    title: 'Size',
                    icon: Icons.SIZE,
                    component: <>{SizeComp}</>,
                  },
                ]}
                hideKeywords={true}
                showCount
                scrollToTop
              />
              <LocationAttributes
                softCriteria={softCriteria}
                addSoftCriteria={addSoftCriteria}
                variant={LocationAttributes.COLLAPSIBLE}
                hideKeywords={true}
                scrollToTop
              />
              <PropertyAttributes
                softCriteria={softCriteria}
                addSoftCriteria={addSoftCriteria}
                className={styles.attributes}
                keywordsOnly={true}
                showCount
                scrollToTop
              />
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <p
            className={styles.reset}
            onClick={() => {
              formik.setValues(criteriaType === criteriaTypes.Sold ? { Status: ['Closed'] } : {});
              setSoftCriteria({});
            }}
          >
            Reset
          </p>
          <Button
            isPending={isPending}
            form="Criteria"
            className={styles.submitButton}
            type="submit"
            title={isEditMode ? 'Save' : 'Search'}
            disabled={!formik.isValid || !formik.values?.Locations?.length}
          />
        </div>
      </form>
    </div>
  );
};

Criteria.initialValues = {
  Locations: [],
  ParkingSpacesRange: {
    Min: '',
    Max: '',
  },
  GarageOnly: false,
  Status: [],
  MaxDaysOnMarket: '',
  HomeType: [],
  HOARange: { Max: '' },
  NoHOA: false,
  PriceRange: {
    Min: '',
    Max: '',
  },
  NumBedroomsRange: {
    Min: '',
    Max: '',
  },
  NumBathroomsRange: {
    Min: '',
    Max: '',
  },
  SquareFeetRange: {
    Min: '',
    Max: '',
  },
  LotSizeRange: {
    Min: '',
    Max: '',
  },
  YearBuiltRange: {
    Min: '',
    Max: '',
  },
  StoriesRange: {
    Min: '',
    Max: '',
  },
};

Criteria.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  onSubmit: PropTypes.func,
};

Criteria.defaultProps = {
  className: '',
  isPending: false,
  onSubmit: () => {},
};

export default Criteria;
