/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { groupBy, template } from 'lodash-es';
import moment from 'moment';

import * as mosaikUtils from 'helpers';
import {
  NewListingNotificationsTypes,
  notificationTypes,
  NotificationTypesForGrouping,
} from 'settings/constants/notifications';
import {
  getNotificationMessagesMapSelector,
  getNotificationPluralMessagesMapSelector,
} from 'store/selectors/app';
import {
  Task,
  NewMessage,
  QuoteRequest,
  QuoteResponse,
  Offer,
  AgentClienStatus,
  Property,
  Transaction,
  Showing,
  WorkshopForm,
} from './Types';
import { InAppNotificationsTemplates } from 'settings/constants/inAppNotificationsTemplates';
import { geNotificationsDrawerParamsSelector } from 'store/selectors/drawers/notifications';
import { getUserRolesMapSelector } from 'store/selectors/user';

import styles from './styles.module.scss';

const Notification = (props) => {
  const {
    className,
    Id,
    Type,
    IsRead,
    CreatedDate,
    Payload,
    groupType,
    groupCount,
    groupIds,
    SeverityLevel,
    UserName,
    groupedNotifications,
  } = props;

  const notificationTemplatesMap = useSelector(getNotificationMessagesMapSelector);
  const notificationPluralTemplatesMap = useSelector(getNotificationPluralMessagesMapSelector);

  const drawerParams = useSelector(geNotificationsDrawerParamsSelector);
  const { isClient, isAgent } = useSelector(getUserRolesMapSelector);
  const getNewListingGroupedPayload = (payload) => {
    const properties = groupedNotifications
      ? groupedNotifications.map((notific) => notific.Payload?.Properties).flat(1)
      : payload?.Properties;

    if (properties?.length) {
      if (
        //INFO: For agent
        NewListingNotificationsTypes.Agent.includes(Type)
      ) {
        const groupByClient = groupBy(properties, (item) => {
          return [
            ...new Set(
              item.ClientListWithMatchPercentage.map((list) =>
                UserName === `${list.ClientFirstName} ${list.ClientLastName}`
                  ? UserName
                  : drawerParams?.filter?.severityLevel
                  ? `${list.ClientFirstName} ${list.ClientLastName}`
                  : false,
              ).filter((name) => name),
            ),
          ];
        });

        for (let key in groupByClient) {
          if (key === '') {
            delete groupByClient[key];
          }
        }

        return Object.keys(groupByClient).map((key) => {
          const matchScores = groupByClient[key]
            ?.map((group) => {
              const matchScoresObj = group.ClientListWithMatchPercentage.map((list) => {
                return key.includes(`${list.ClientFirstName} ${list.ClientLastName}`)
                  ? list.MatchingScore
                  : null;
              }).filter((score) => score);
              return [...new Set(matchScoresObj)];
            })
            .flat(1);

          const maxMatchScore =
            matchScores.length &&
            matchScores.reduce((prev, current) => {
              return prev > current ? prev : current;
            });
          const minMatchScore =
            matchScores.length &&
            matchScores.reduce((prev, current) => {
              return prev < current ? prev : current;
            });

          return {
            MinMatchScore: minMatchScore,
            MaxMatchScore: maxMatchScore,
            ClientName: key?.replace(',', ', '),
            PropertyCount: groupByClient[key]?.length,
            ...payload,
          };
        });
      } else {
        //INFO: For client
        const matchScores = properties
          .map((prop) => prop.ClientListWithMatchPercentage.map((list) => list.MatchingScore))
          .flat(1)
          .filter((score) => score);

        const maxMatchScore =
          matchScores.length &&
          matchScores.reduce((prev, current) => {
            return prev > current ? prev : current;
          });
        const minMatchScore =
          matchScores.length &&
          matchScores.reduce((prev, current) => {
            return prev < current ? prev : current;
          });

        return {
          MinMatchScore: minMatchScore,
          MaxMatchScore: maxMatchScore,
          PropertyCount: properties?.length,
          ...payload,
        };
      }
    }
  };

  if (isAgent && NewListingNotificationsTypes.Agent.includes(Type)) {
    // INFO: For agent based on client matchscore
    notificationTemplatesMap[Type] = InAppNotificationsTemplates[Type].TextMarkup(
      getNewListingGroupedPayload(Payload)?.[0] || Payload || {},
    );
  } else if (
    isClient &&
    Object.values(NotificationTypesForGrouping).flat(1).includes(Type) &&
    InAppNotificationsTemplates[Type]
  ) {
    if (NewListingNotificationsTypes.Client.includes(Type)) {
      notificationTemplatesMap[Type] = InAppNotificationsTemplates[Type].TextMarkup(
        getNewListingGroupedPayload(Payload) || {},
      );
    } else {
      notificationTemplatesMap[Type] = InAppNotificationsTemplates[Type].TextMarkup({
        ...Payload,
        Count: groupCount,
      });
    }
  } else if (InAppNotificationsTemplates[Type]) {
    notificationTemplatesMap[Type] = InAppNotificationsTemplates[Type].TextMarkup(Payload);
  }

  const compileTemplate = (templateString = '', payload) => {
    try {
      if (
        [...NewListingNotificationsTypes.Agent, ...NewListingNotificationsTypes.Client].includes(
          Type,
        ) ||
        (isClient && Object.values(NotificationTypesForGrouping).flat(1).includes(Type)) ||
        InAppNotificationsTemplates[Type]
      ) {
        return templateString;
      } else {
        const compiledTemplate = template(templateString, {
          interpolate: /{{([\s\S]+?)}}/g,
        });
        return compiledTemplate({
          p: payload || Payload,
          utils: {
            ...mosaikUtils,
            getDateTime: () => {
              const momentDate = moment(Payload?.CreatedDate);
              const formattedDate = momentDate.format('M/D/YY');
              const formattedTime = momentDate.format('h:mm A');

              return `${formattedDate} at ${formattedTime}`;
            },
          },
        });
      }
    } catch (e) {
      return templateString;
    }
  };

  const commonProps = {
    Ids: groupIds?.length ? groupIds : [+Id],
    Payload,
    IsRead,
    CreatedDate,
    groupType,
    SeverityLevel,
    Type,
    title: compileTemplate(
      Payload.UsePluralTemplate
        ? notificationPluralTemplatesMap?.[Type]
        : notificationTemplatesMap?.[Type],
    ),
  };

  const getNotification = () => {
    switch (Type) {
      case notificationTypes.NewTaskCommentTagged: {
        // component NewTaskComment satisfies the UI for NewTaskCommentTagged notification as well
        return <Task.NewTaskComment {...commonProps} />;
      }
      case notificationTypes.TaskNotificationSummary: {
        return <Task.TaskNotificationSummary {...commonProps} />;
      }
      case notificationTypes.NewTaskComment: {
        return <Task.NewTaskComment {...commonProps} />;
      }
      case notificationTypes.TaskStuck: {
        return <Task.TaskStuck {...commonProps} />;
      }
      case notificationTypes.TaskStuckCC: {
        // component TaskStuck satisfies the UI for TaskStuckCC notification as well
        return <Task.TaskStuck {...commonProps} />;
      }
      case notificationTypes.TaskStuckSelfAssignee: {
        // component TaskStuck satisfies the UI for TaskStuckSelfAssignee notification as well
        return <Task.TaskStuck {...commonProps} />;
      }
      case notificationTypes.TaskStuckSelfAssignor: {
        // component TaskStuck satisfies the UI for TaskStuckSelfAssignor notification as well
        return <Task.TaskStuck {...commonProps} />;
      }
      case notificationTypes.TaskCompletedCC: {
        // component TaskCompleted satisfies the UI for TaskCompletedCC notification as well
        return <Task.TaskCompleted {...commonProps} />;
      }
      case notificationTypes.TaskCompletedSelfAssignor: {
        // component TaskCompleted satisfies the UI for TaskCompletedSelfAssignor notification as well
        return <Task.TaskCompleted {...commonProps} />;
      }
      case notificationTypes.TaskCompletedSelfAssignee: {
        // component TaskCompleted satisfies the UI for TaskCompletedSelfAssignee notification as well
        return <Task.TaskCompleted {...commonProps} />;
      }
      case notificationTypes.NewTaskCC: {
        // component NewTask satisfies the UI for NewTask notification as well
        return <Task.NewTask {...commonProps} />;
      }
      case notificationTypes.NewTaskSelf: {
        // component NewTaskSelf satisfies the UI for NewTask notification as well
        return <Task.NewTask {...commonProps} />;
      }
      case notificationTypes.InvitationAccepted: {
        return <AgentClienStatus.InvitationAccepted {...commonProps} />;
      }
      case notificationTypes.NewListing: {
        return <Property.NewListing {...commonProps} />;
      }
      case notificationTypes.NewAgentRecommendation: {
        return <Property.NewAgentRecommendation {...commonProps} />;
      }
      case notificationTypes.PropertyStatusChange: {
        return <Property.PropertyStatusChange {...commonProps} />;
      }
      case notificationTypes.NewComment: {
        return <Property.NewComment {...commonProps} />;
      }
      case notificationTypes.TourRequested: {
        return <Property.TourRequested {...commonProps} />;
      }
      case notificationTypes.TourConfirmed: {
        return <Property.TourConfirmed {...commonProps} />;
      }
      case notificationTypes.TourAssigned: {
        return <Property.TourAssigned {...commonProps} />;
      }
      case notificationTypes.TourCancelledAgent: {
        return <Property.TourCancelledAgent {...commonProps} />;
      }
      case notificationTypes.TourCancelledClient: {
        return <Property.TourCancelledClient {...commonProps} />;
      }
      case notificationTypes.TourUpcomingAgent: {
        return <Property.TourUpcomingAgent {...commonProps} />;
      }
      case notificationTypes.TourUpcomingClient: {
        return <Property.TourUpcomingClient {...commonProps} />;
      }
      case notificationTypes.TourRequestCancelledAgent: {
        return <Property.TourRequestCancelledAgent {...commonProps} />;
      }
      case notificationTypes.TourRequestCancelledClient: {
        return <Property.TourRequestCancelledClient {...commonProps} />;
      }
      case notificationTypes.ClientPreferencesUpdated: {
        return <AgentClienStatus.ClientPreferencesUpdated {...commonProps} />;
      }
      case notificationTypes.ClientSearchReactivated: {
        // component ClientPreferencesUpdated satisfies the UI for ClientSearchReactivated notification as well
        return <AgentClienStatus.ClientPreferencesUpdated {...commonProps} />;
      }
      case notificationTypes.ClientSearchDeactivated: {
        // component ClientPreferencesUpdated satisfies the UI for ClientSearchDeactivated notification as well
        return <AgentClienStatus.ClientPreferencesUpdated {...commonProps} />;
      }
      case notificationTypes.ClientSearchCriteriaChange: {
        // component ClientPreferencesUpdated satisfies the UI for ClientSearchCriteriaChange notification as well
        return <AgentClienStatus.ClientPreferencesUpdated {...commonProps} />;
      }
      case notificationTypes.ClientSearchNew: {
        // component ClientPreferencesUpdated satisfies the UI for ClientSearchNew notification as well
        return <AgentClienStatus.ClientPreferencesUpdated {...commonProps} />;
      }
      case notificationTypes.NewMessage: {
        return <NewMessage {...commonProps} />;
      }
      case notificationTypes.NewTask: {
        return <Task.NewTask {...commonProps} />;
      }
      case notificationTypes.TaskCompleted: {
        return <Task.TaskCompleted {...commonProps} />;
      }
      case notificationTypes.UpcomingDueDate: {
        return <Task.UpcomingDueDate {...commonProps} />;
      }
      case notificationTypes.UpcomingDueDateCC: {
        // component UpcomingDueDate satisfies the UI for UpcomingDueDateCC notification as well
        return <Task.UpcomingDueDate {...commonProps} />;
      }
      case notificationTypes.UpcomingDueDateSelfAssignee: {
        // component UpcomingDueDate satisfies the UI for UpcomingDueDateSelfAssignee notification as well
        return <Task.UpcomingDueDate {...commonProps} />;
      }
      case notificationTypes.UpcomingDueDateSelfAssignor: {
        // component UpcomingDueDate satisfies the UI for UpcomingDueDateSelfAssignor notification as well
        return <Task.UpcomingDueDate {...commonProps} />;
      }
      case notificationTypes.OverdueTask: {
        return <Task.OverdueTask {...commonProps} />;
      }
      case notificationTypes.OverdueTaskCC: {
        // component OverdueTask satisfies the UI for OverdueTaskCC notification as well
        return <Task.OverdueTask {...commonProps} />;
      }
      case notificationTypes.OverdueTaskSelfAssignee: {
        // component OverdueTask satisfies the UI for OverdueTaskSelfAssignee notification as well
        return <Task.OverdueTask {...commonProps} />;
      }
      case notificationTypes.OverdueTaskSelfAssignor: {
        // component OverdueTask satisfies the UI for OverdueTaskSelfAssignee notification as well
        return <Task.OverdueTask {...commonProps} />;
      }
      case notificationTypes.MilestoneAdded: {
        return <Transaction.MilestoneAdded {...commonProps} />;
      }
      case notificationTypes.MilestoneCompleted: {
        return <Transaction.MilestoneCompleted {...commonProps} />;
      }
      case notificationTypes.MilestoneDeadlineUpcoming: {
        // component MilestoneCompleted satisfies the UI for MilestoneDeadlineUpcoming notification as well
        return <Transaction.MilestoneCompleted {...commonProps} />;
      }
      case notificationTypes.MilestoneMissed: {
        return <Transaction.MilestoneMissed {...commonProps} />;
      }
      case notificationTypes.TransactionStatusUpdated: {
        return <Transaction.TransactionStatusUpdated {...commonProps} />;
      }
      case notificationTypes.DocumentUploaded: {
        return <Transaction.DocumentUploaded {...commonProps} />;
      }
      case notificationTypes.NewClientFavorite: {
        return <Property.NewClientFavorite {...commonProps} />;
      }
      case notificationTypes.NewListingAgent: {
        // component NewClientFavorite satisfies the UI for NewListingAgent notification as well
        return <Property.NewClientFavorite {...commonProps} />;
      }
      case notificationTypes.NewListingClient: {
        // component NewClientFavorite satisfies the UI for NewListingClient notification as well
        return <Property.NewClientFavorite {...commonProps} />;
      }
      case notificationTypes.NewListingWithHighMatchscoreAgent: {
        // component NewClientFavorite satisfies the UI for NewListingWithHighMatchscoreAgent notification as well
        return <Property.NewClientFavorite {...commonProps} />;
      }
      case notificationTypes.NewListingWithCustomMatchscoreClient: {
        // component NewClientFavorite satisfies the UI for NewListingWithCustomMatchscoreClient notification as well
        return <Property.NewClientFavorite {...commonProps} />;
      }
      case notificationTypes.NewPreApproval: {
        return <Property.NewPreApproval {...commonProps} />;
      }
      case notificationTypes.QuoteRequest: {
        return <QuoteRequest {...commonProps} />;
      }
      case notificationTypes.QuoteResponse: {
        return <QuoteResponse {...commonProps} />;
      }
      case notificationTypes.QuoteResponseNewAttachment: {
        return <QuoteResponse {...commonProps} />;
      }
      case notificationTypes.TransactionInvitation: {
        return <Transaction.TransactionInvitation {...commonProps} />;
      }
      case notificationTypes.OfferStatusUpdated: {
        return <Offer.OfferStatusUpdated {...commonProps} />;
      }
      case notificationTypes.OfferEdited: {
        return <Offer.OfferEdited {...commonProps} />;
      }
      case notificationTypes.OfferDeadlinePassed: {
        return <Offer.OfferDeadlinePassed {...commonProps} />;
      }
      case notificationTypes.OfferDeadlineUpcoming: {
        return <Offer.OfferDeadlineUpcoming {...commonProps} />;
      }
      case notificationTypes.OfferWithdrawn: {
        return <Offer.OfferWithdrawn {...commonProps} />;
      }
      case notificationTypes.OfferExpirationUpcoming: {
        return <Offer.OfferExpirationUpcoming {...commonProps} />;
      }
      case notificationTypes.OfferExpired: {
        return <Offer.OfferExpired {...commonProps} />;
      }
      case notificationTypes.OfferReceived: {
        return <Offer.OfferReceived {...commonProps} />;
      }
      case notificationTypes.CounterAccepted: {
        return <Offer.CounterAccepted {...commonProps} />;
      }
      case notificationTypes.CounterEdited: {
        return <Offer.CounterEdited {...commonProps} />;
      }
      case notificationTypes.CounterExpired: {
        return <Offer.CounterExpired {...commonProps} />;
      }
      case notificationTypes.CounterReceived: {
        return <Offer.CounterReceived {...commonProps} />;
      }
      case notificationTypes.CounterRejected: {
        return <Offer.CounterRejected {...commonProps} />;
      }
      case notificationTypes.CounterExpirationUpcoming: {
        return <Offer.CounterExpirationUpcoming {...commonProps} />;
      }
      case notificationTypes.CounterViewed: {
        return <Offer.CounterViewed {...commonProps} />;
      }
      case notificationTypes.CounterWithdrawn: {
        return <Offer.CounterWithdrawn {...commonProps} />;
      }
      case notificationTypes.OfferSubmittedExternal: {
        // component OfferReceived satisfies the UI for OfferSubmittedExternal notification as well
        return <Offer.OfferReceived {...commonProps} />;
      }
      case notificationTypes.OfferViewedExternal: {
        // component OfferReceived satisfies the UI for OfferViewedExternal notification as well
        return <Offer.OfferReceived {...commonProps} />;
      }
      case notificationTypes.OfferRejectedExternal: {
        // component OfferReceived satisfies the UI for OfferRejectedExternal notification as well
        return <Offer.OfferReceived {...commonProps} />;
      }
      case notificationTypes.OfferAcceptedExternal: {
        // component OfferReceived satisfies the UI for OfferAcceptedExternal notification as well
        return <Offer.OfferReceived {...commonProps} />;
      }
      case notificationTypes.OfferEditsSubmittedExternal: {
        // component OfferReceived satisfies the UI for OfferEditsSubmittedExternal notification as well
        return <Offer.OfferReceived {...commonProps} />;
      }
      case notificationTypes.OfferExpirationUpcomingExternal: {
        // component OfferReceived satisfies the UI for OfferExpirationUpcomingExternal notification as well
        return <Offer.OfferReceived {...commonProps} />;
      }
      case notificationTypes.OfferExpiredExternal: {
        // component OfferReceived satisfies the UI for OfferExpiredExternal notification as well
        return <Offer.OfferReceived {...commonProps} />;
      }
      case notificationTypes.OfferWithdrawnExternal: {
        // component OfferReceived satisfies the UI for OfferWithdrawnExternal notification as well
        return <Offer.OfferReceived {...commonProps} />;
      }
      case notificationTypes.OfferDeadlineUpcomingExternal: {
        // component OfferReceived satisfies the UI for OfferDeadlineUpcomingExternal notification as well
        return <Offer.OfferReceived {...commonProps} />;
      }
      case notificationTypes.OfferDeadlinePassedExternal: {
        // component OfferReceived satisfies the UI for OfferDeadlinePassedExternal notification as well
        return <Offer.OfferReceived {...commonProps} />;
      }
      case notificationTypes.CounterSubmittedExternal: {
        // component CounterWithdrawn satisfies the UI for CounterSubmittedExternal notification as well
        return <Offer.CounterWithdrawn {...commonProps} />;
      }
      case notificationTypes.CounterViewedExternal: {
        // component CounterWithdrawn satisfies the UI for CounterViewedExternal notification as well
        return <Offer.CounterWithdrawn {...commonProps} />;
      }
      case notificationTypes.CounterReceivedExternal: {
        // component CounterWithdrawn satisfies the UI for CounterReceivedExternal notification as well
        return <Offer.CounterWithdrawn {...commonProps} />;
      }
      case notificationTypes.CounterRejectedExternal: {
        // component CounterWithdrawn satisfies the UI for CounterRejectedExternal notification as well
        return <Offer.CounterWithdrawn {...commonProps} />;
      }
      case notificationTypes.CounterAcceptedExternal: {
        // component CounterWithdrawn satisfies the UI for CounterAcceptedExternal notification as well
        return <Offer.CounterWithdrawn {...commonProps} />;
      }
      case notificationTypes.CounterEditsSubmittedExternal: {
        // component CounterWithdrawn satisfies the UI for CounterEditsSubmittedExternal notification as well
        return <Offer.CounterWithdrawn {...commonProps} />;
      }
      case notificationTypes.CounterExpirationUpcomingExternal: {
        // component CounterWithdrawn satisfies the UI for CounterExpirationUpcomingExternal notification as well
        return <Offer.CounterWithdrawn {...commonProps} />;
      }
      case notificationTypes.CounterExpiredExternal: {
        // component CounterWithdrawn satisfies the UI for CounterExpiredExternal notification as well
        return <Offer.CounterWithdrawn {...commonProps} />;
      }
      case notificationTypes.CounterWithdrawnExternal: {
        // component CounterWithdrawn satisfies the UI for CounterWithdrawnExternal notification as well
        return <Offer.CounterWithdrawn {...commonProps} />;
      }
      case notificationTypes.MegaphoneOffersNotification: {
        return <Offer.MegaphoneOffersNotification {...commonProps} />;
      }
      case notificationTypes.ShowingAppointmentApprovalSelf: {
        return <Showing.ShowingAppointmentApprovalSelf {...commonProps} />;
      }
      case notificationTypes.ShowingAppointmentApprovalOther: {
        // component ShowingAppointmentApprovalSelf satisfies the UI for ShowingAppointmentApprovalOther notification as well
        return <Showing.ShowingAppointmentApprovalSelf {...commonProps} />;
      }
      case notificationTypes.ConfirmedShowingAppointmentNotification: {
        return <Showing.ConfirmedShowingAppointmentNotification {...commonProps} />;
      }
      case notificationTypes.ConfirmedShowingAppointmentExternalNotification: {
        return <Showing.ConfirmedShowingAppointmentNotification {...commonProps} />;
      }
      case notificationTypes.DeclinedShowingAppointmentNotification: {
        return <Showing.DeclinedShowingAppointment {...commonProps} />;
      }
      case notificationTypes.DeclinedShowingAppointmentExternalNotification: {
        return <Showing.DeclinedShowingAppointment {...commonProps} />;
      }
      case notificationTypes.CancelledShowingAppointmentNotification: {
        return <Showing.CancelledShowingAppointment {...commonProps} />;
      }
      case notificationTypes.CancelledShowingAppointmentExternalNotification: {
        // component CancelledShowingAppointment satisfies the UI for CancelledShowingAppointmentExternalNotification notification as well
        return <Showing.CancelledShowingAppointment {...commonProps} />;
      }
      case notificationTypes.RescheduledShowingAppointmentNotification: {
        // component DeclinedShowingAppointment satisfies the UI for RescheduledShowingAppointment notification as well
        return <Showing.DeclinedShowingAppointment {...commonProps} />;
      }
      case notificationTypes.RescheduledShowingAppointmentExternalNotification: {
        // component DeclinedShowingAppointment satisfies the UI for RescheduledShowingAppointmentExternalNotification notification as well
        return <Showing.DeclinedShowingAppointment {...commonProps} />;
      }
      case notificationTypes.ShowingFeedbackSubmittedNotification: {
        // component DeclinedShowingAppointment satisfies the UI for RescheduledShowingAppointment notification as well
        return <Showing.DeclinedShowingAppointment {...commonProps} />;
      }
      case notificationTypes.UpcomingShowingAppointmentReminderNotification: {
        // component ShowingAppointmentApprovalSelf satisfies the UI for UpcomingShowingAppointmentReminder notification as well
        return <Showing.ShowingAppointmentApprovalSelf {...commonProps} />;
      }
      case notificationTypes.UpcomingShowingAppointmentExternalReminderNotification: {
        // component ShowingAppointmentApprovalSelf satisfies the UI for UpcomingShowingAppointmentExternalReminderNotification notification as well
        return <Showing.ShowingAppointmentApprovalSelf {...commonProps} />;
      }
      case notificationTypes.ShowingAppointmentApprovalReminderSelf: {
        // component ShowingAppointmentApprovalSelf satisfies the UI for ShowingAppointmentApprovalReminderSelf notification as well
        return <Showing.ShowingAppointmentApprovalSelf {...commonProps} />;
      }
      case notificationTypes.ShowingAppointmentApprovalReminderOther: {
        // component ShowingAppointmentApprovalSelf satisfies the UI for ShowingAppointmentApprovalReminderOther notification as well
        return <Showing.ShowingAppointmentApprovalSelf {...commonProps} />;
      }
      case notificationTypes.PendingShowingAppointmentNotification: {
        // component ShowingAppointmentApprovalSelf satisfies the UI for PendingShowingAppointmentNotification notification as well
        return <Showing.ShowingAppointmentApprovalSelf {...commonProps} />;
      }
      case notificationTypes.PendingShowingAppointmentExternalNotification: {
        // component ShowingAppointmentApprovalSelf satisfies the UI for PendingShowingAppointmentExternalNotification notification as well
        return <Showing.ShowingAppointmentApprovalSelf {...commonProps} />;
      }
      case notificationTypes.CollaborateDocument: {
        return <WorkshopForm.CollaborateDocument {...commonProps} />;
      }
      case notificationTypes.FormDocumentSignedByEveryone: {
        // component CollaborateDocument satisfies the UI for FormDocumentSignedByEveryone notification as well
        return <WorkshopForm.CollaborateDocument {...commonProps} />;
      }
      case notificationTypes.CancelledMessage: {
        // component CollaborateDocument satisfies the UI for CancelledMessage notification as well
        return <WorkshopForm.CollaborateDocument {...commonProps} />;
      }
      case notificationTypes.GenericMessage: {
        // component CollaborateDocument satisfies the UI for GenericMessage notification as well
        return <WorkshopForm.CollaborateDocument {...commonProps} />;
      }
      case notificationTypes.SignDocumentRequest: {
        // component CollaborateDocument satisfies the UI for SignDocumentRequest notification as well
        return <WorkshopForm.CollaborateDocument {...commonProps} />;
      }
      case notificationTypes.DocumentEditingComplete: {
        // component CollaborateDocument satisfies the UI for DocumentEditingComplete notification as well
        return <WorkshopForm.CollaborateDocument {...commonProps} />;
      }
      case notificationTypes.DocumentReadyForSignature: {
        // component CollaborateDocument satisfies the UI for DocumentReadyForSignature notification as well
        return <WorkshopForm.CollaborateDocument {...commonProps} />;
      }
      case notificationTypes.DocumentSigned: {
        // component CollaborateDocument satisfies the UI for DocumentSigned notification as well
        return <WorkshopForm.CollaborateDocument {...commonProps} />;
      }
      case notificationTypes.SignatureRequestDeclined: {
        // component CollaborateDocument satisfies the UI for SignatureRequestDeclined notification as well
        return <WorkshopForm.CollaborateDocument {...commonProps} />;
      }
      case notificationTypes.CollaborateRequestOpened: {
        return <WorkshopForm.CollaborateRequestOpened {...commonProps} />;
      }
      case notificationTypes.SignDocumentRequestOpened: {
        // component CollaborateRequestOpened satisfies the UI for SignDocumentRequestOpened notification as well
        return <WorkshopForm.CollaborateRequestOpened {...commonProps} />;
      }
      case notificationTypes.CollaborateRequestPending: {
        // component CollaborateRequestOpened satisfies the UI for CollaborateRequestPending notification as well
        return <WorkshopForm.CollaborateRequestOpened {...commonProps} />;
      }
      case notificationTypes.CollaborateRequestSent: {
        // component CollaborateRequestOpened satisfies the UI for CollaborateRequestSent notification as well
        return <WorkshopForm.CollaborateRequestOpened {...commonProps} />;
      }
      case notificationTypes.SignDocumentRequestPending: {
        // component CollaborateRequestOpened satisfies the UI for SignDocumentRequestPending notification as well
        return <WorkshopForm.CollaborateRequestOpened {...commonProps} />;
      }
      case notificationTypes.CommentAdded: {
        // component CollaborateRequestOpened satisfies the UI for CommentAdded notification as well
        return <WorkshopForm.CollaborateRequestOpened {...commonProps} />;
      }
      case notificationTypes.CommentReplied: {
        // component CollaborateRequestOpened satisfies the UI for CommentReplied notification as well
        return <WorkshopForm.CollaborateRequestOpened {...commonProps} />;
      }
      case notificationTypes.CommentResolved: {
        // component CollaborateRequestOpened satisfies the UI for CommentResolved notification as well
        return <WorkshopForm.CollaborateRequestOpened {...commonProps} />;
      }
      case notificationTypes.CommentTagged: {
        // component CollaborateRequestOpened satisfies the UI for CommentTagged notification as well
        return <WorkshopForm.CollaborateRequestOpened {...commonProps} />;
      }
      case notificationTypes.TransactionInvitationOther: {
        // component TransactionParticipantInvited satisfies the UI for TransactionInvitationOther notification as well
        return <Transaction.TransactionParticipantInvited {...commonProps} />;
      }
      case notificationTypes.AgentTransactionInvitation: {
        // component TransactionParticipantInvited satisfies the UI for AgentTransactionInvitation notification as well
        return <Transaction.TransactionParticipantInvited {...commonProps} />;
      }
      case notificationTypes.VoidDocument: {
        // component TransactionParticipantInvited satisfies the UI for VoidDocument notification as well
        return <WorkshopForm.CollaborateDocument {...commonProps} />;
      }
      case notificationTypes.DocumentUpdated: {
        return <Transaction.DocumentUpdated {...commonProps} />;
      }

      default: {
        return 'Unknown notification type';
      }
    }
  };

  return <div className={classNames(styles.notification, className)}>{getNotification()}</div>;
};

Notification.propTypes = {
  Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  Type: PropTypes.string.isRequired,
  Payload: PropTypes.shape({
    CreatedDate: PropTypes.string,
  }),
  IsRead: PropTypes.bool.isRequired,
  CreatedDate: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
};

Notification.defaultProps = {
  className: '',
  Payload: {},
};

export default Notification;
