import { routes } from 'settings/navigation/routes';
import {
  CLIENT,
  AGENT,
  THIRD_PARTY,
  BROKERAGE_ADMIN,
  SUPER_USER,
  TEAM_OWNER,
} from 'settings/constants/roles';
import {
  Notifications,
  Profile,
  Password,
  ThirdPartyIcon,
  CoBuyer,
  PartnerProfile,
  PlanShield,
  Share,
  Organization,
} from 'components/Icons';
import { featureFlags } from 'utils/featureFlags';

import { getLinksByRoles } from './common';
import { AGENT_TYPE } from 'settings/constants/drawers';
import Display from 'components/Icons/Display';

export const settingsLinks = ({ isPartner, agentType, agentRole, showBilling = false }) => ({
  [CLIENT]: [
    { link: routes.settings, title: 'Profile', icon: Profile },
    { link: routes.settingsPassword, title: 'Security', icon: Password },
    { link: routes.settingsNotifications, title: 'Notifications', icon: Notifications },
    // { link: routes.settingsCoBuyer, title: 'Co-Buyer', icon: CoBuyer },
    // { link: routes.settingsPreApproval, title: 'Pre-Approval', icon: Approve },
  ],
  [AGENT]: [
    { link: routes.settings, title: 'Profile', icon: Profile },
    ...(agentRole === TEAM_OWNER
      ? [
          {
            link: routes.settingsDisplay,
            title: 'Display',
            icon: ({ className }) => (
              <div className={className}>
                <Display />
              </div>
            ),
          },
        ]
      : []),
    { link: routes.settingsPassword, title: 'Security', icon: Password },
    { link: routes.settingsNotifications, title: 'Notifications', icon: Notifications },
    ...(featureFlags.paywall &&
    showBilling &&
    (agentType === AGENT_TYPE.Individual ||
      (agentType === AGENT_TYPE.Team && agentRole === TEAM_OWNER))
      ? [
          {
            link: routes.settingsSubscription,
            title: 'Billing',
            icon: ({ className }) => (
              <div className={className}>
                <PlanShield />
              </div>
            ),
          },
        ]
      : []),
    {
      link: routes.settingsThirdPartyIntegrations,
      title: 'Integrations',
      icon: ThirdPartyIcon,
    },
    {
      link: routes.settingsExport,
      title: 'Export',
      icon: ({ className }) => (
        <div className={className}>
          <Share />
        </div>
      ),
    },
  ],
  [THIRD_PARTY]: [
    { link: routes.settings, title: isPartner ? 'Profile' : 'Account', icon: Profile },
    ...(isPartner
      ? [{ link: routes.settingsPartner, title: 'Organization', icon: Organization }]
      : []),
    { link: routes.settingsPassword, title: 'Security', icon: Password },
    { link: routes.settingsNotifications, title: 'Notifications', icon: Notifications },
    // { link: routes.settingsTemplates, title: 'Templates', icon: Template },
  ],
  [BROKERAGE_ADMIN]: [], // TODO:
  [SUPER_USER]: [], // TODO:
});

/**
 * @param  {} roles = []
 * @returns array of header links [{ link, title }]
 */
export const getSettingsLinksByRoles = getLinksByRoles(settingsLinks);
