import styles from './styles.module.scss';
import { AddPhoto, Button, Wrapper } from 'components';
import Photo from 'components/AddPhoto';
import { useEffect, useState } from 'react';
import { requestGetTeamAvatarForAgent, requestPutTeamAvatarForAgent } from 'api/admin/users';
import UploadIcon from './UploadIcon';
import { TooltipIcon } from 'components/Icons';
import ResetIcon from './ResetIcon';
import Logo from 'images/logo/shortLogo.svg';
import { useSelector } from 'react-redux';
import { getUserId } from 'store/selectors/user';
import { showErrorMessage, showSuccessMessage } from 'helpers';

const SettingsDisplay = () => {
  const [avatar, setAvatar] = useState<any>(Logo);
  const [file, setFile] = useState<any>(null);
  const [isPending, setIsPending] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const agentId = useSelector(getUserId);

  const getTeamAvatar = async () => {
    const {
      data: { success, result },
    } = await requestGetTeamAvatarForAgent(agentId);
    if (success && result) {
      setAvatar(result);
    }
    setIsPending(false);
  };

  const handleSave = async () => {
    setIsUploading(true);
    const cfg = new FormData();
    cfg.append('file', file);
    try {
      const response = await requestPutTeamAvatarForAgent(cfg, agentId);
      const {
        data: { success },
      } = response;
      if (success) {
        setFile(null);
        showSuccessMessage('Logo Updated Successfully');
      }
      setIsUploading(false);
    } catch (error) {
      showErrorMessage((error as any).response.data.message);
      setIsUploading(false);
    }
  };

  const handleReset = async () => {
    const {
      data: { success },
    } = await requestPutTeamAvatarForAgent({ reset: true }, agentId);
    if (success) {
      setFile(null);
      setAvatar(null);
      setTimeout(() => setAvatar(Logo), 0);
    }
  };

  useEffect(() => {
    getTeamAvatar();
  }, []);

  return (
    <div>
      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>Display</h2>
      </div>
      <Wrapper isPending={isPending} className={styles.container}>
        <div className={styles.avatarContainer}>
          <AddPhoto
            avatarWrapperClassName={styles.avatarWrapper}
            avatarClassName={styles.avatarClassName}
            onChange={(_, files) => {
              setFile(files[0]);
            }}
            variant={Photo.FULL}
            value={avatar}
            accept="image/jpeg, image/png"
          >
            <div className={styles.upload}>
              <UploadIcon />
              Upload
            </div>
          </AddPhoto>
        </div>
        <div className={styles.infoContainer}>
          <TooltipIcon />
          The uploaded logo will be displayed on client accounts (after they log in) in the top left
          corner.
        </div>
        <div className={styles.buttonContainer}>
          <Button
            title={'Reset'}
            className={styles.reset}
            icon={<ResetIcon />}
            onClick={handleReset}
          />
          <Button
            title={'Publish'}
            onClick={handleSave}
            disabled={!file}
            isPending={isUploading}
            className={styles.publish}
          />
        </div>
      </Wrapper>
    </div>
  );
};

export default SettingsDisplay;
