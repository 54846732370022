function addSpacesBeforeCapitals(inputString) {
  if (/[a-z]/.test(inputString) && /[A-Z]/.test(inputString)) {
    let stringWithSpaces = inputString.replace(/([a-z])([A-Z])/g, '$1 $2');
    stringWithSpaces = stringWithSpaces.trim();
    return stringWithSpaces;
  } else {
    return inputString;
  }
}

export default addSpacesBeforeCapitals;
