import React from 'react';
import { link } from 'settings/navigation/link';
import { useHistory } from 'react-router-dom';

import { Icons, IconVariant } from '../../../icons';

import styles from './styles.module.scss';
import classNames from 'classnames';
import moment from 'moment';

type Props = {
  variant?: 'large';
  data: any;
  states?: any;
};

const sanitizeHTML = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Remove potentially dangerous elements
  const forbiddenTags = ['script', 'style', 'iframe', 'object', 'embed', 'link'];
  forbiddenTags.forEach((tag) => {
    const elements = doc.querySelectorAll(tag);
    elements.forEach((el) => el.remove());
  });

  // Optionally remove event handler attributes (e.g., onclick, onerror)
  const elementsWithAttributes = doc.querySelectorAll('*');
  elementsWithAttributes.forEach((el) => {
    Array.from(el.attributes).forEach((attr) => {
      if (attr.name.startsWith('on')) {
        el.removeAttribute(attr.name);
      }
    });
  });

  return doc.body.innerHTML;
};

export const ReminderCard: React.FC<Props> = ({ variant, data: reminders, states }) => {
  const history = useHistory();

  const navigateToRemindersPage = () => {
    history.push(link.toIntelReminders(), states);
  };

  return (
    <div
      className={classNames(styles.remindersCard, {
        [styles.large]: variant === 'large',
      })}
    >
      <div className={styles.header}>
        <div className={styles.heading}>Reminders</div>
        {variant !== 'large' && (
          <div className={styles.viewAll} onClick={navigateToRemindersPage}>
            View All
          </div>
        )}
      </div>
      {reminders?.map(({ Text, CreatedDate }) => (
        <div className={styles.reminderItem}>
          <div className={styles.days}>
            <Icons variant={IconVariant.BELL} />
          </div>
          <div
            className={classNames(styles.description, {
              [styles.large]: variant === 'large',
            })}
          >
            <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(Text) }} />
            <div className={styles.date}>
              {variant === 'large' && <>{moment(CreatedDate).format('MM/DD/YYYY')}</>}
            </div>
          </div>
          {/* <div className={styles.menu}>
            <DropdownMenu id="test" options={[{ label: 'Edit' }]} />
          </div> */}
        </div>
      ))}
    </div>
  );
};
