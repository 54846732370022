import { Row, Col } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { postClarityFinancialData } from 'api/clarity';
import { getAgentTeamIdSelector, getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { getAgentTeamStatsSelector, getTimePeriodSelector } from 'store/selectors/clarity';
import { AreaGraphTemplate } from 'pages/ClarityRevamped/Components/Graphs/AreaGraphTemplate';
import { GeneralLedgerKeys } from '../../../ContentTypes/helper';
import { Wrapper as PendingWrapper } from 'components';
import { TEAM_BASIC } from 'settings/constants/roles';
import { getUserId } from 'store/selectors/user';
import { colors, hoverColors } from '../helper';

import styles from '../styles.module.scss';

export const OtherSplits = ({ aggregationType }) => {
  const timePeriod = useSelector(getTimePeriodSelector);
  const teamId = useSelector(getAgentTeamIdSelector);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const userId = useSelector(getUserId);
  const [summary, setSummary] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [isPending, setIsPending] = useState(false);
  const [keys, setKeys] = useState<string[]>([]);

  const filterOutMainChartsData = (data) => {
    const filteredList = data.Summary.filter(
      (summary) => summary.LedgerAccount === GeneralLedgerKeys.CustomAccountPayment,
    ).filter((summary) => summary.SummaryColumn !== GeneralLedgerKeys.CustomAccountPayment);

    setSummary(filteredList);
    const filteredKeys = filteredList.map((item) => item.SummaryColumn);
    setKeys(filteredKeys);

    const filteredData = {};
    Object.entries(data.Data).map(([key, value]) => {
      if (filteredKeys.includes(key)) filteredData[key] = value;
    });
    setData(filteredData);
  };

  const fetchFeesChartStats = async () => {
    setIsPending(true);
    const { data, status } = await postClarityFinancialData({
      aggregationType,
      summaryType: 'CommissionSplits',
      teamId,
      timePeriod,
      aggregateDataFormat: 'Series',
      financialOptions: ['ActualOnly'],
      optionalColumns: [],
      ...(agentRole === TEAM_BASIC && { transactionAgentUserId: userId }),
      ...(teamStats &&
        teamStats.itemValue !== 'Team' && { transactionAgentUserId: teamStats.userId }),
    });
    if (status === 200) {
      filterOutMainChartsData(data);
    }
    setIsPending(false);
  };

  useEffect(() => {
    fetchFeesChartStats();
  }, []);

  return (
    <PendingWrapper isPending={isPending} className={styles.mainContentWrapper}>
      <Row gutter={[24, 24]}>
        {!!keys.length &&
          keys.map((key, index) => {
            return (
              <Col
                span={8}
                xs={{ span: 24, order: index + 1 }}
                sm={{ span: 12, order: index + 1 }}
                lg={{ span: 8, order: index + 1 }}
                className="gutter-row"
                key={index}
              >
                <AreaGraphTemplate
                  title={key}
                  color={colors[index % 5]}
                  hoverColor={hoverColors[index % 5]}
                  summary={summary.filter((s) => s.SummaryColumn === key)?.[0] || {}}
                  data={data?.[key] || []}
                  isPending={isPending}
                  isExpandableChart={false}
                />
              </Col>
            );
          })}
      </Row>
    </PendingWrapper>
  );
};
