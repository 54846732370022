import { useEffect, useMemo, useRef, useState } from 'react';

import { Col, ConfirmationModal, Row } from 'components-antd';
import { FormPill } from 'pages/FormProcess/components';
import { useSelector } from 'react-redux';
import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';
import classNames from 'classnames';
import { dynamicManager } from '../DynamicManager';
import { SmartFormIcons } from 'components';
import { FORM_PROCESS_SCREEN } from 'app-constants';

import styles from './styles.module.scss';
import { DocumentsPopover } from './components/DocumentsPopover';
import { DownloadButton } from './components/DownloadButton/DownloadButton';
import { useLocation } from 'react-router-dom';
import { Close } from 'components/Icons';

export const DynamicHeader = ({ isMultipleDocument, isSendDocument, pdfDocRefs }) => {
  const {
    meta: { clientName, address, documentName, link } = {},
    isCanceled,
    isCompleted,
    showUnlock,
    isLoading,
    allowRegress,
    screen,
    dynamicFormDocuments,
    bundleDocumentsDetails,
  } = useSelector(getDynamicFormSelector);

  const { state: formState } = useLocation<{ bundleName: string; isWebView?: boolean }>();
  const state = useMemo(() => formState, []);

  const windowState = window.history.state;
  const isWebView = state?.isWebView || windowState?.isWebView;

  const wrapper = useRef<HTMLDivElement>(null);
  const [sticky, setSticky] = useState(false);

  const isDeclined = screen === FORM_PROCESS_SCREEN.DeclineSign;
  const isCompleteOrCanceledOrDeclined = isCanceled || isCompleted || isDeclined;

  const [confirmation, setConfirmation] = useState<
    | {
        text: string;
        handleOk: () => void;
      }
    | undefined
  >(undefined);

  const [activeScrolledDocumentId, setScrolledDocumentId] = useState(
    isMultipleDocument ? dynamicFormDocuments?.[0].Id || -1 : -1,
  );

  useEffect(() => {
    const handleScroll = () => {
      if (wrapper.current !== null) {
        wrapper.current.offsetTop > 80 ? setSticky(true) : setSticky(false);
      }

      if (isMultipleDocument) {
        let currentDocId = -1;
        Object.values(pdfDocRefs.current).forEach((docItem: any) => {
          const rect = docItem?.getBoundingClientRect();
          if (rect.top <= 90 && rect.bottom >= 90) {
            const documentIndex = docItem.id.split('pdf-doc-')[1];

            currentDocId = dynamicFormDocuments?.[documentIndex].Id || -1;
          }
        });

        setScrolledDocumentId(currentDocId);
      }
    };

    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const renderLeftHeader = () => {
    if (
      (allowRegress && screen === FORM_PROCESS_SCREEN.Sign) ||
      screen === FORM_PROCESS_SCREEN.SendDocument
    ) {
      return (
        <div
          className={classNames(styles.editForm, styles.navigationButton)}
          onClick={dynamicManager.gotBackToPreviousScreen}
        >
          <SmartFormIcons variant="pencil" />
          Edit
        </div>
      );
    }

    return <></>;
  };

  const getForms = () => {
    return dynamicFormDocuments || [{ link }];
  };

  const renderRightHeader = () => {
    if (isCanceled || isDeclined) {
      return (
        <span className={styles.closeIcon}>
          <Close color={'#fff'} onClick={() => dynamicManager.handleFormExit()} />
        </span>
      );
    } else if (isCompleted) {
      return (
        <div className={styles.completedRightHeader}>
          <span className={styles.downloadButton}>
            <DownloadButton
              documents={getForms()}
              formProcessPublicId={dynamicManager.getFormProcessPublicId()}
              anonymousUserDetails={dynamicManager.getAnonymousDetails() || undefined}
              isCompleted={true}
              downloadedFileName={state?.bundleName || documentName}
            />
          </span>
          <span className={styles.closeIcon}>
            <Close color={'#fff'} onClick={() => dynamicManager.handleFormExit()} />
          </span>
        </div>
      );
    } else {
      return (
        <div className={classNames(styles.navigationIcons, styles.sideControls)}>
          <div
            className={classNames(styles.closeNavigation, styles.navigationButton)}
            onClick={() => dynamicManager.handleFormExit()}
          >
            <SmartFormIcons variant="cancel" />
          </div>
        </div>
      );
    }
  };

  const renderCenterHeader = () => {
    if (isCompleteOrCanceledOrDeclined) {
      return (
        <div className={styles.previewTitle}>
          <span>
            {isCompleted
              ? 'This document is complete and signed by all parties.'
              : isDeclined
              ? 'This document is declined.'
              : 'This document is voided.'}
          </span>
        </div>
      );
    } else if (isMultipleDocument) {
      if (isSendDocument) return <></>;
      return (
        <Col className={styles.documentPopoverColumn}>
          <DocumentsPopover
            allDocuments={dynamicFormDocuments}
            scrolledDocumentId={activeScrolledDocumentId}
          />
        </Col>
      );
    } else {
      return (
        <FormPill
          clientAddress={address}
          clientName={clientName}
          pdfName={documentName}
          formPillClass={styles.formPill}
        />
      );
    }
  };

  return (
    <>
      {confirmation && (
        <ConfirmationModal
          confirmText={confirmation.text}
          onOk={confirmation.handleOk}
          onCancel={() => setConfirmation(undefined)}
          open={!!confirmation}
          confirmLoading={isLoading}
        />
      )}

      <div
        ref={wrapper}
        className={classNames(styles.headerRow, {
          [styles.sticky]: sticky,
          [styles.completedHeader]: isCompleteOrCanceledOrDeclined,
        })}
      >
        <Row
          className={classNames(styles.headerContentRow, {
            [styles.isCompleted]: isCompleteOrCanceledOrDeclined,
            [styles.showBorder]: sticky && screen !== FORM_PROCESS_SCREEN.SendDocument,
            [styles.webViewHeaderContentRow]: isWebView,
          })}
        >
          <Col>{renderLeftHeader()}</Col>
          {renderCenterHeader()}
          <Col>{renderRightHeader()}</Col>
        </Row>
      </div>
    </>
  );
};
