import NumberFormat from 'react-number-format';
import styles from './styles.module.scss';
import Icon from 'pages/Properties/Feed/Icons';
import { link } from 'settings/navigation/link';
import { openNewTab } from 'services/newTab';
import { PropertyImage } from 'components/Icons';
import { chunk } from 'lodash';
import { useMemo } from 'react';
import { constructMatchScoreLabels } from 'helpers/matchscore';
import classNames from 'classnames';
import { Tooltip } from 'components-antd';

export const MatchPercentageBadge = ({ matchPercentage }) => {
  return <div className={styles.matchPercentage}>{matchPercentage} Match</div>;
};

export const AgentHighlightsBadge = ({ agentHighlights }) => {
  return (
    <Tooltip
      placement="top"
      overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
      title={<p>{agentHighlights}</p>}
    >
      <div className={styles.overFlowText}>
        <div className={styles.agentHighlights}>{agentHighlights}</div>
      </div>
    </Tooltip>
  );
};

export const OpenHousesBadge = ({ openHouses }) => {
  return (
    <Tooltip
      placement="top"
      overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
      title={<p>{openHouses}</p>}
    >
      <div className={styles.overFlowText}>
        <div className={styles.openHouses}>{openHouses}</div>
      </div>
    </Tooltip>
  );
};

const ArrowRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
      <path
        d="M5 13H18M18 13L13 8M18 13L13 18"
        stroke="#262626"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PriceReductionBadge = ({ priceReduction }) => {
  return (
    <Tooltip
      placement="top"
      overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
      title={
        <div className={styles.flexTooltip}>
          <NumberFormat
            displayType="text"
            thousandSeparator
            value={priceReduction?.from || 0}
            prefix="$"
            renderText={(val) => <p className={styles.grayText}>{val}</p>}
          />
          <span className={styles.whiteArrow}>
            <ArrowRight />
          </span>
          <NumberFormat
            displayType="text"
            thousandSeparator
            value={priceReduction?.to || 0}
            prefix="$"
            renderText={(val) => <p>{val}</p>}
          />
        </div>
      }
    >
      <div className={styles.overFlowText}>
        <div className={styles.priceReductions}>
          <div className={styles.flex}>
            <NumberFormat
              displayType="text"
              thousandSeparator
              value={priceReduction?.from || 0}
              prefix="$"
              renderText={(val) => <p className={styles.grayText}>{val}</p>}
            />
            <span className={styles.grayText}>
              <ArrowRight />
            </span>
            <NumberFormat
              displayType="text"
              thousandSeparator
              value={priceReduction?.to || 0}
              prefix="$"
              renderText={(val) => <p>{val}</p>}
            />
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export const BackOnMarketBadge = ({ backOnMarket }) => {
  return (
    <Tooltip
      placement="top"
      overlayClassName={classNames(styles.compareOfferTooltip, 'mosaikTooltip')}
      title={
        <div className={styles.flexTooltip}>
          <p>{backOnMarket?.PreviousStatus}</p>
          <p className={styles.whiteArrowTop}>
            <ArrowRight />
          </p>
          <p>{backOnMarket?.ActivityStatus}</p>
        </div>
      }
    >
      <div className={styles.priceReductions}>
        <div className={styles.flex}>
          <p className={classNames(styles.overFlowText, styles.position)}>
            <span className={styles.grayText}>{backOnMarket?.PreviousStatus}</span>
            <span className={styles.arrow}>
              <ArrowRight />
            </span>
            <span className={styles.black}>{backOnMarket?.ActivityStatus}</span>
          </p>
        </div>
      </div>
    </Tooltip>
  );
};

export const CommentsCountBadge = ({ commentsCount }) => {
  return (
    <div className={styles.overFlowText}>
      <div className={styles.commentWrapper}>
        <Icon className={styles.icon} testid="comment_icon" variant={Icon.COMMENT} />
        <span className={styles.count}>{commentsCount} Comments</span>
      </div>
    </div>
  );
};

export const PropertyCard = ({
  id,
  image,
  address,
  subAddress,
  matchPercentage,
  agentHighlights,
  openHouses,
  priceReduction,
  commentsCount,
  matchedCriteria,
  backOnMarket,
  instanceId,
}) => {
  // Redirect to listing details page
  const handleRedirectToListingDetails = () => {
    let searchPath = link.toSearchListingDetails(
      `${id}${instanceId ? `?searchId=${instanceId}` : ''}`,
    );
    openNewTab(searchPath);
  };

  const matchScoreHintProps = matchedCriteria ? { 'data-rh': true } : {};
  const formattedMatchedCriteria = useMemo(() => {
    if (!matchedCriteria) {
      return [];
    }
    return chunk(matchedCriteria, 2);
  }, [matchedCriteria]);

  return (
    <div className={styles.propertyCard} onClick={handleRedirectToListingDetails}>
      <div className={styles.propertyImageWrapper}>
        {image ? (
          <img src={image} alt="Property" className={styles.propertyImage} />
        ) : (
          <PropertyImage height={104} width={198} />
        )}
      </div>
      <div className={styles.propertyDetails}>
        <div className={styles.addressMain}>{address}</div>
        {subAddress && <div className={styles.addressSecondary}>{subAddress}</div>}
        {matchPercentage !== undefined && (
          <div
            {...matchScoreHintProps}
            data-match={JSON.stringify({
              matchedCriterias: formattedMatchedCriteria || [],
              matchScore: matchPercentage.replace('%', ''),
            })}
          >
            <MatchPercentageBadge matchPercentage={matchPercentage} />
          </div>
        )}
        {agentHighlights !== undefined && (
          <AgentHighlightsBadge agentHighlights={agentHighlights} />
        )}
        {openHouses !== undefined && <OpenHousesBadge openHouses={openHouses} />}
        {priceReduction !== undefined && <PriceReductionBadge priceReduction={priceReduction} />}
        {backOnMarket !== undefined && <BackOnMarketBadge backOnMarket={backOnMarket} />}
        {commentsCount !== undefined && <CommentsCountBadge commentsCount={commentsCount} />}
      </div>
    </div>
  );
};

export default PropertyCard;
