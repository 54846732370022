import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './styles.module.scss';
import { ClientCASSAddress } from 'app-constants';
import { Button, Locations } from 'components';
import { ConfettiIcon } from './ConfettiIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { userFullUpdateDataEffect } from 'store/effects';
import { ClientDashboardEligibility } from 'pages/Main/ClientDashboard/ClientDashboard';
import { getClientDashboardEligibility } from 'api/dashboard';
import { getCurrentContextSelector } from 'store/selectors/context';

type Props = {
  wrapperClassname?: string;
  error?: string;
  addresses: ClientCASSAddress[];
  setShowAddAddressFlow: React.Dispatch<React.SetStateAction<boolean>>;
  setDashboardEligibility: Dispatch<SetStateAction<ClientDashboardEligibility>>;
};

export const AddAddress: React.FC<Props> = ({
  wrapperClassname,
  error,
  addresses = [],
  setShowAddAddressFlow,
  setDashboardEligibility,
}) => {
  const dispatch = useDispatch();
  const { isAgent, isThirdParty, isClient } = useSelector(getUserRolesMapSelector);
  const selectedContext = useSelector(getCurrentContextSelector);
  const clientId = useSelector(getUserId);
  const [locations, setLocations] = useState<ClientCASSAddress[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addressDisabled = useMemo(() => !locations[0]?.address?.delivery_line_1, [locations]);

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        userFullUpdateDataEffect(
          { isAgent, isThirdParty, isClient, values, isPartner: false },
          async (err) => {
            if (!err) {
              const stats = await getClientDashboardEligibility({
                clientId,
                contextId: selectedContext.ContextKey,
              });
              setDashboardEligibility(stats?.data);
              setIsLoading(false);
              setShowAddAddressFlow(false);
            }
            setIsLoading(false);
          },
        ),
      );
    },
    [dispatch, isAgent, isThirdParty, isClient],
  );

  const handleAddAddress = () => {
    setIsLoading(true);
    if (addressDisabled || isLoading) {
      return;
    }

    //combine addresses and locations while removing duplicates
    const updatedAddresses = [...addresses, ...locations];

    onSubmit({ address: updatedAddresses });
  };

  return (
    <div className={classNames(styles.addAddressFlowCard, wrapperClassname)}>
      <div>
        <ConfettiIcon />
      </div>
      <div className={styles.title}>
        Add an address to start tracking home values, neighborhood intel, etc.
      </div>
      <div className={styles.address}>
        <Locations
          multiple={false}
          types={['address']}
          variant={Locations.ROUND}
          placeholderClassName={styles.border}
          searchWrapperClassName={styles.border}
          valuesWrapperClassName={styles.border}
          placeholder="Enter address"
          onResult={(_, preparedData) => {
            setLocations(
              preparedData?.length
                ? [{ address: preparedData[0], neighbourhoodRadius: 2, isPrimary: false }]
                : [],
            );
          }}
          value={locations?.[0]?.address ? [locations[0].address] : []}
          error={error}
          testid="address"
          showPrefixIcon={false}
          popupClassName={styles.popup}
          allowedSmartyAddress={true}
          showOnlyCassAddresses={true}
          setFullCassAddress={true}
        />
      </div>
      <Button
        testid="continue"
        type="button"
        className={styles.continue}
        title="Add"
        disabled={isLoading || addressDisabled}
        onClick={handleAddAddress}
        isPending={isLoading}
      />
    </div>
  );
};
