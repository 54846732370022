import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash-es';

import styles from './styles.module.scss';

import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  QuestionCircleFilled,
} from '@ant-design/icons';

const statusIcons = {
  correct: <CheckCircleFilled className={styles.correctIcon} />,
  mismatch: <ExclamationCircleFilled className={styles.mismatchIcon} />,
  missing: <QuestionCircleFilled className={styles.missingIcon} />,
};

const QAReportTable = ({ items, className, itemsClassName }) => {
  const mismatches = items.filter((item) => item.status === 'mismatch');
  const missing = items.filter((item) => item.status === 'missing');
  const correct = items.filter((item) => item.status === 'correct');

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.header}>
        <div className={styles.headerItem}>
          <p className={styles.bold}>These are the results:</p>
          <p>
            <span>{statusIcons['missing']}</span> {missing.length} missing fields
          </p>
          <p>
            <span>{statusIcons['mismatch']}</span> {mismatches.length} mismatches
          </p>
          <p>
            <span>{statusIcons['correct']}</span> {correct.length} correct field
          </p>
        </div>
      </div>
      {map(items, ({ title, value, status }) => (
        <div
          data-testid="row_item"
          className={classNames(styles.item, itemsClassName)}
          key={title + JSON.stringify(value)}
        >
          <div data-testid="row_title" className={styles.bold}>
            <span data-testid="row_status" className={styles.statusIcon}>
              {statusIcons[status] || null}
            </span>
            {title}
          </div>
          <div data-testid="row_value" className={classNames(styles.value, styles[status])}>
            {Array.isArray(value)
              ? map(value, (val, index) => {
                  // Split the value by the colon and return the key in bold and the value in normal
                  const [key, value] = val.split(':');
                  return (
                    <div key={index} className={styles.subValue}>
                      <span className={styles.bold}>{key}</span>: {value}
                    </div>
                  );
                })
              : value}
          </div>
        </div>
      ))}
    </div>
  );
};

QAReportTable.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
      status: PropTypes.oneOf(['correct', 'mismatch', 'missing']).isRequired,
    }),
  ).isRequired,
  itemsClassName: PropTypes.string,
};

QAReportTable.defaultProps = {
  className: '',
  itemsClassName: '',
};

export default QAReportTable;
