import React, { Fragment } from 'react';

import type { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import moment from 'moment';

import { ShowingsArchiveStatus, ShowingsStatus } from 'app-constants';
import { getLocaleDate } from 'helpers';
import { Icons } from 'pages/Workshop/Icons';
import { ArrowDownFull } from 'components/Icons';
import { Button, Tooltip } from 'components-antd';
import { getFirstNameLastInitial } from 'utils';
import { ApprovalAction } from 'types/showingAppointment';
import { Status } from './../Status';

import styles from './styles.module.scss';

interface ColumnsDataType {
  key: React.Key;
  ShowingDetails: any;
  client: string;
  dateTime: string;
  requestedBy: string;
  CreatedDate: string;
  AppointmentStatus: ShowingsStatus;
  BuyerFeedback: any;
  IsNewFeedback: boolean;
  UUID: number;
}

export function tableColumns(
  isArchive,
  processAction,
  setData,
  getPendingApproverNames,
  isTransactionRoom = false,
): ColumnsType<ColumnsDataType> {
  const ListingColumn = ({ row }) => {
    const address = row?.ShowingDetails.PropertyTransaction?.Property?.Address;

    return (
      <div className={styles.listingCol}>
        <Icons variant={Icons.LOCATION} className={styles.condoIcon} />
        <div className={styles.flexCol}>
          <span className={styles.bold}>{address?.Line1}</span>
          <span className={styles.line2}>
            {address?.City + `, ` + address?.State + ` ` + address?.Zip}
          </span>
        </div>
      </div>
    );
  };

  const DateTimeColumn = ({ row }) => {
    const date = row.AppointmentDate;

    return (
      <div className={styles.inlineRowContent}>
        <Icons variant={Icons.CALENDAR} />
        <div className={styles.dateTime}>
          <span className={styles.bold}>{moment(getLocaleDate(date)).format('M/D/YYYY')}</span>
          <span>{moment(getLocaleDate(row.AppointmentStartTime)).format('h:mm A')}</span>
        </div>
      </div>
    );
  };

  const RequestedByColumn = ({ row }) => {
    const agentName = row?.BuyingAgentName;

    return (
      <div className={!isTransactionRoom ? styles.requestedByCol : styles.flexCol}>
        <span>{agentName}</span>
        <span className={styles.line2}>{row.BuyingAgentBrokerage}</span>
      </div>
    );
  };

  const RequestedColumn = ({ createdDate }) => {
    return (
      <div className={styles.flexCol}>
        <span>{moment(getLocaleDate(createdDate)).format('M/D/YYYY')}</span>
        <span className={styles.line2}>{moment(getLocaleDate(createdDate)).format('h:mm A')}</span>
      </div>
    );
  };

  const ListingColumnItem = {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(({ column }) => column.key === 'listing');
      const sortAsc = sortedColumn?.order === 'ascend';
      return (
        <div className={styles.sortedColumnTitle}>
          Listing
          <ArrowDownFull
            className={classNames(styles.sorterIcon, { [styles.sorterUp]: sortAsc })}
          />
        </div>
      );
    },
    dataIndex: 'ShowingDetails',
    key: 'listing',
    width: 400,
    render: (_, row) => <ListingColumn row={row} />,
    sorter: (a, b) => {
      const addressA = a?.ShowingDetails.PropertyTransaction?.Property?.Address?.Line1 || '';
      const addressB = b?.ShowingDetails.PropertyTransaction?.Property?.Address?.Line1 || '';
      return addressA.localeCompare(addressB);
    },
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend', 'descend'],
  };

  const columns: ColumnsType<ColumnsDataType> = [
    {
      title: 'Date & Time',
      dataIndex: 'dateTime',
      key: 'AppointmentDate',
      render: (_, row) => <DateTimeColumn row={row} />,
    },
    {
      title: 'Status',
      dataIndex: 'AppointmentStatus',
      width: 200,
      key: 'AppointmentStatus',
      render: (_, row) => <Status status={row?.AppointmentStatus} />,
    },
    {
      title: 'Requested By',
      dataIndex: 'requestedBy',
      key: 'BuyingAgentName',
      render: (text, row) => <RequestedByColumn row={row} />,
    },
    {
      title: 'Requested',
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      render: (createdDate) => <RequestedColumn createdDate={createdDate} />,
    },
    {
      title: '',
      dataIndex: 'AppointmentStatus',
      width: 150,
      key: 'AppointmentStatus',
      render: (_, row) => {
        var approvers = getPendingApproverNames(row);
        const pendingApprovers = getPendingApproverNames(row);

        if (approvers.includes('You')) {
          approvers = approvers.filter((a) => a !== 'You');
          approvers.unshift('You');
        }

        const approversStr = approvers.join(', ');
        const amIApprover = approversStr.includes('You');
        const otherApprovers = approversStr.replace('You', '');

        const status = isArchive && row?.AppointmentStatus;
        const correctedStatus = status === 'Canceled' ? ShowingsArchiveStatus.Canceled : status;

        return (
          <>
            <div>
              {isArchive && status === ShowingsArchiveStatus.Past && (
                <span className={styles.iconWrapper}>
                  <span>
                    <Icons
                      variant={Icons.NEW_FEEDBACK}
                      className={styles.newFeedbackIcon}
                      color={row?.BuyerFeedback?.length ? '#FF576D' : '#D6D6D7'}
                    />
                  </span>
                </span>
              )}
            </div>
            {!isArchive && row.AppointmentStatus === ShowingsStatus.Upcoming && (
              <span className={styles.upcoming}>
                {/* Upcoming */}
                <div className={styles.buttonContainer}>
                  <span className={styles.upcomingActions}>
                    <Button
                      variant="default"
                      className={styles.reject}
                      onClick={(e) => {
                        e.stopPropagation();
                        setData(row);
                        processAction(ApprovalAction.Cancel);
                      }}
                    >
                      <Icons variant={Icons.CLOSE} />
                    </Button>
                  </span>
                </div>
              </span>
            )}
            {!isArchive && row.AppointmentStatus === ShowingsStatus.Pending && (
              <span className={styles.pending}>
                {/* Pending */}
                {pendingApprovers && pendingApprovers[0] === 'You' && (
                  <span className={styles.pendingActions}>
                    <Button
                      variant="default"
                      className={styles.approve}
                      onClick={(e) => {
                        e.stopPropagation();
                        setData(row);
                        processAction(ApprovalAction.Approve);
                      }}
                    >
                      <Icons variant={Icons.GREEN_LIGHT_CHECKMARK} />
                    </Button>
                    <Button
                      variant="default"
                      className={styles.reject}
                      onClick={(e) => {
                        e.stopPropagation();
                        setData(row);
                        processAction(ApprovalAction.Decline);
                      }}
                    >
                      <Icons variant={Icons.CLOSE} />
                    </Button>
                  </span>
                )}
                <Tooltip
                  color="black"
                  trigger="hover"
                  placement="topRight"
                  overlayClassName={styles.pendingTooltipContainer}
                  title={
                    <div>
                      <p className={classNames(styles.tooltipTitle)}>
                        <Icons variant={Icons.PENDING_APPROVAL} className={styles.pendingIcon} />
                        <div>
                          <span>Pending Approval:</span>
                          <br />
                          {amIApprover && (
                            <>
                              <span>You{otherApprovers !== '' ? ',' : ''}&nbsp;</span>
                            </>
                          )}
                          <span>{otherApprovers}</span>
                        </div>
                      </p>
                    </div>
                  }
                >
                  <div>
                    <Icons variant={Icons.PENDING_APPROVAL_BG} className={styles.pendingIcon} />
                  </div>
                </Tooltip>
              </span>
            )}
            {!isArchive && row.IsNewFeedback && (
              <span className={styles.Pending}>
                {/* New Feedback */}
                <span className={styles.feedBackbuttonContainer}>
                  <Icons
                    variant={Icons.NEW_FEEDBACK}
                    className={styles.newFeedbackIcon}
                    color={row?.BuyerFeedback?.length ? '#FF576D' : '#D6D6D7'}
                  />
                </span>
              </span>
            )}
          </>
        );
      },
    },
  ];
  const aggregatePageColumns: any = [
    ...columns.slice(0, 2),
    ListingColumnItem,
    ...columns.slice(2),
  ];
  return isTransactionRoom ? columns : aggregatePageColumns;
  // return columns;
}
