import { getDateOnly } from 'helpers';
import moment from 'moment';

export const validateControlOperatorOffset = (
  e,
  value,
  callback,
  index?,
  isTemplate?,
  isDateNumerical?,
  onlyDateNumerical?,
  isCampaignTask?,
) => {
  const val = value?.toUpperCase();

  const allowAllRegex =
    /^(T|T[+-]|T[+-]\d+|A|A[+-]|A[+-]\d+|O|O[+-]|O[+-]\d+|L|LE|LE[+-]|LE[+-]\d+|C|CL|CL[+-]|CL[+-]\d+|CD|CD[+-]|CD[+-]\d+|K|KO|KO[+-]|KO[+-]\d+|D|DL|DL[+-]|DL[+-]\d+|TL|TL[+-]|TL[+-]\d+|M|MD|MD[+-]|MD[+-]\d+)$/;

  const dateRegex = /^(\d*\/?\d*\/?\d*|)$/;

  const templateRegex =
    /^(A|A[+-]|A[+-]\d+|O|O[+-]|O[+-]\d+|L|LE|LE[+-]|LE[+-]\d+|C|CL|CL[+-]|CL[+-]\d+|CD|CD[+-]|CD[+-]\d+|K|KO|KO[+-]|KO[+-]\d+|D|DL|DL[+-]|DL[+-]\d+|TL|TL[+-]|TL[+-]\d+|T|T[+-]|T[+-]\d+|M|MD|MD[+-]|MD[+-]\d+)$/;

  const campaignTemplateRegex = /^(A|A[+-]\d*|C|CL|CL[+-]\d*)$/;

  const nonTemplateRegex =
    /^(T|T[+-]|T[+-]\d+|O|O[+-]|O[+-]\d+|L|LE|LE[+-]|LE[+-]\d+|C|CL|CL[+-]|CL[+-]\d+|CD|CD[+-]|CD[+-]\d+|K|KO|KO[+-]|KO[+-]\d+|D|DL|DL[+-]|DL[+-]\d+|TL|TL[+-]|TL[+-]\d+|M|MD|MD[+-]|MD[+-]\d+)$/;

  let isValid: boolean;
  if (isTemplate === undefined) {
    isValid = isDateNumerical
      ? allowAllRegex.test(val) || dateRegex.test(val)
      : allowAllRegex.test(val);
  } else if (isTemplate && isCampaignTask) {
    isValid = isDateNumerical
      ? campaignTemplateRegex.test(val) || dateRegex.test(val)
      : campaignTemplateRegex.test(val);
  } else if (isTemplate) {
    isValid = isDateNumerical
      ? templateRegex.test(val) || dateRegex.test(val)
      : templateRegex.test(val);
  } else {
    if (onlyDateNumerical) {
      isValid = dateRegex.test(val);
    } else {
      isValid = isDateNumerical
        ? nonTemplateRegex.test(val) || dateRegex.test(val)
        : nonTemplateRegex.test(val);
    }
  }

  if (isValid) {
    callback(
      {
        ControlOperatorOffset: val,
      },
      index,
    );
  }
  return isValid;
};

export const convertToInitialOffset = (val, callback, index?, shouldReturnValue?) => {
  if (validateControlOperatorOffset(null, val, callback)) {
    const split = val.includes('+') ? val.split('+') : val.split('-');
    const dateControl = split[0];
    const operator = val.includes('+') ? '+' : '-';
    const offset = split[1];

    callback(
      {
        Initial: dateControl,
        Operator: operator,
        DueDate: +offset,
      },
      index,
    );

    if (shouldReturnValue) {
      return {
        Initial: dateControl,
        Operator: operator,
        DueDate: +offset,
      };
    }
  } else {
    callback(
      {
        Initial: null,
        Operator: null,
        DueDate: null,
        ControlOperatorOffset: '',
      },
      index,
    );

    if (shouldReturnValue) {
      return {
        Initial: null,
        Operator: null,
        DueDate: null,
        ControlOperatorOffset: '',
      };
    }
  }
};

export const convertToDateTime = (val, dateControls, callback, index?, isDateNumerical?) => {
  // If the date is in the format MM/DD/YY|YYYY
  const dateRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/((19|20)\d{2}|([0-9]{2}))$/;

  if (isDateNumerical && dateRegex.test(val)) {
    callback({ DueDate: new Date(val) }, index);
    return;
  }

  // If the date is in the format A+2/CT+2/T+7 (Control Offset)
  if (validateControlOperatorOffset(null, val, callback, index, undefined, isDateNumerical)) {
    const split = val.includes('+') ? val.split('+') : val.split('-');
    let dateControlKey = split[0];

    if (dateControlKey === 'KO') {
      dateControlKey = !dateControls.KO ? 'CD' : 'KO';
    } else if (dateControlKey === 'DL') {
      dateControlKey = !dateControls.DL ? 'CL' : 'DL';
    }

    const offset = split[1];
    const dateControl = getDateOnly(dateControls[dateControlKey]);
    if (dateControl) {
      const dueDate = val.includes('+')
        ? getDateOnly(moment(dateControl).add(offset, 'days'))
        : getDateOnly(moment(dateControl).subtract(offset, 'days'));
      callback(
        {
          DueDate: dueDate,
        },
        index,
      );
    }
  }
};

export const convertToDateTimeForAllFormats = (
  val,
  dateControls,
  callback,
  index?,
  isDateNumerical?,
) => {
  const dateRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/((19|20)\d{2}|([0-9]{2}))$/;
  const dateRegexYearWise = /^(\d{4})\/(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])$/;
  if (isDateNumerical && (dateRegex.test(val) || dateRegexYearWise.test(val))) {
    callback({ DueDate: new Date(val) }, index);
    return;
  }

  // If the date is in the format A+2/CT+2/T+7 (Control Offset)
  if (validateControlOperatorOffset(null, val, callback, index, undefined, isDateNumerical)) {
    const split = val.includes('+') ? val.split('+') : val.split('-');
    let dateControlKey = split[0];
    if (dateControlKey === 'KO') {
      dateControlKey = 'CD';
    } else if (dateControlKey === 'DL') {
      dateControlKey = 'CL';
    }
    const offset = split[1];
    const dateControl = getDateOnly(dateControls[dateControlKey]);
    if (dateControl) {
      const dueDate = val.includes('+')
        ? getDateOnly(moment(dateControl).add(offset, 'days'))
        : getDateOnly(moment(dateControl).subtract(offset, 'days'));
      callback(
        {
          DueDate: dueDate,
        },
        index,
      );
    }
  }
};
