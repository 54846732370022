import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { cloneDeep } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';

import {
  getCreateTransactionOrProjectsQuestions,
  LISTED_STATUS,
  transactionPreFormQuestionsIds,
  TransactionUserRole,
} from 'settings/constants/transaction';
import { transactionRoles } from 'settings/constants/roles';
import { getRepresentValueFor } from 'pages/Workshop/Transactions/TransactionCreate/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTransactionEffect_,
  getTransactionEffect,
  resetTransactionCreateTemplateEffect,
} from 'store/effects/transactions';
import { userGetDataOnBackgroundEffect } from 'store/effects';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';
import { getIsClientDrawerSelector } from 'store/selectors/app';
import { getTransactionSelector } from 'store/selectors/transaction';
import { setTransactionPreFormQuestionsEffect } from 'store/effects/transactions';
import { link } from 'settings/navigation/link';
import { routes } from 'settings/navigation/routes';

import { ContentWrapper } from '..';
import ChangeQuestion from './ChangeQuestion';
import { Controls } from './Controls';

import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';

const PreForm = (props) => {
  const { className } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { preForm } = useSelector(getTransactionPreFormSelector);
  const { transaction } = useSelector(getTransactionSelector);
  const isClientsDrawerOpen = useSelector(getIsClientDrawerSelector);
  const isProject = useIsProjectRoute();

  const [currentQuestionId, setCurrentQuestionId] = useState(
    isProject
      ? transactionPreFormQuestionsIds.projectCategory
      : transactionPreFormQuestionsIds.whoAreYouRepresenting,
  );
  const [previousQuestionId, setPreviousQuestionId] = useState();
  const [prevIds, setPrevIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [questionsList, setQuestionsList] = useState(
    getCreateTransactionOrProjectsQuestions(isProject ? 'Project' : ''),
  );
  let _preForm = preForm?.[transactionPreFormQuestionsIds.creatorRole]?.value;
  const status = preForm?.[transactionPreFormQuestionsIds.listedStatus];

  const getFilteredQuestions = (questions) => {
    let allQuestions = [...questions];
    let _questions = [];
    if (_preForm) {
      if (
        [
          TransactionUserRole.AdministrativeAssistant,
          TransactionUserRole.Operations,
          TransactionUserRole.Brokerage,
          TransactionUserRole.Finance,
        ].includes(_preForm)
      ) {
        _questions = questions;
      } else if (
        _preForm === TransactionUserRole.BuyerAgent ||
        _preForm === TransactionUserRole.ListingAgent
      ) {
        _questions = Object.values(allQuestions).filter(
          (question) => question.id !== transactionPreFormQuestionsIds.agents,
        );
      } else {
        _questions = Object.values(allQuestions).filter(
          (question) => question.id !== transactionPreFormQuestionsIds.coordinators,
        );
      }
    } else {
      _questions = questions;
    }

    return _questions;
  };

  useEffect(() => {
    if (isProject) return;
    if (
      getRepresentValueFor(preForm, transactionRoles.BUYER) &&
      getRepresentValueFor(preForm, transactionRoles.SELLER)
    ) {
      let questions = getFilteredQuestions(
        getCreateTransactionOrProjectsQuestions(transactionRoles.BUYER),
      );
      return setQuestionsList((prevState) => {
        const [firstQuestion] = prevState;
        return [firstQuestion, ...questions];
      });
    }

    if (getRepresentValueFor(preForm, transactionRoles.BUYER)) {
      let questions = getFilteredQuestions(
        getCreateTransactionOrProjectsQuestions(transactionRoles.BUYER),
      );
      return setQuestionsList((prevState) => {
        const [firstQuestion] = prevState;
        return [firstQuestion, ...questions];
      });
    }

    if (getRepresentValueFor(preForm, transactionRoles.SELLER)) {
      let questions = getFilteredQuestions(
        getCreateTransactionOrProjectsQuestions(transactionRoles.SELLER),
      );
      return setQuestionsList((prevState) => {
        const [firstQuestion] = prevState;
        return [firstQuestion, ...questions];
      });
    }

    if (
      !getRepresentValueFor(preForm, transactionRoles.BUYER) &&
      !getRepresentValueFor(preForm, transactionRoles.SELLER)
    ) {
      return setQuestionsList(getCreateTransactionOrProjectsQuestions());
    }
  }, [
    getRepresentValueFor(preForm, transactionRoles.BUYER),
    getRepresentValueFor(preForm, transactionRoles.SELLER),
    preForm?.[transactionPreFormQuestionsIds.creatorRole],
  ]);

  const setNext = (event, id) => {
    if (id) {
      setPrevIds((prevState) => [...prevState, currentQuestionId]);
      return setCurrentQuestionId(id);
    }

    const currentQuestionIndex = questionsList.findIndex(
      (question) => question.id === currentQuestionId,
    );
    if (questionsList[currentQuestionIndex + 1]) {
      const nextQuestionId = questionsList[currentQuestionIndex + 1].id;
      setPrevIds((prevState) => [...prevState, currentQuestionId]);
      setPreviousQuestionId(currentQuestionId);
      setCurrentQuestionId(nextQuestionId);
    }
  };

  const setPrevious = () => {
    if (prevIds.length) {
      const copyPrevIds = cloneDeep(prevIds);
      const prevId = copyPrevIds.pop();
      if (currentQuestionId === transactionPreFormQuestionsIds.timeline) {
        dispatch(resetTransactionCreateTemplateEffect());
      }
      setPreviousQuestionId(currentQuestionId);
      setCurrentQuestionId(prevId);
      setPrevIds(copyPrevIds);
    }
  };

  const removeQuestions = (questions, deletions) =>
    questions.filter((question) => !deletions.includes(question.id));

  const updateQuestions = (deletions, role) => {
    const allQuestionsForRole = getCreateTransactionOrProjectsQuestions(role);
    setQuestionsList((prevState) => {
      const [firstQuestion] = prevState;
      return [firstQuestion, ...removeQuestions(allQuestionsForRole, deletions)];
    });
  };

  const isSellerFlow = () =>
    preForm?.[transactionPreFormQuestionsIds.whoAreYouRepresenting]?.[transactionRoles.SELLER] &&
    !preForm?.[transactionPreFormQuestionsIds.whoAreYouRepresenting]?.[transactionRoles.BUYER];

  const onAnswer = (answer) => {
    if (
      currentQuestionId === transactionPreFormQuestionsIds.isPropertyUnderContract &&
      isSellerFlow()
    ) {
      dispatch(
        setTransactionPreFormQuestionsEffect({
          [transactionPreFormQuestionsIds.listingExpireDate]: undefined,
          [transactionPreFormQuestionsIds.validUntil]: undefined,
          [transactionPreFormQuestionsIds.liveDate]: undefined,
          [transactionPreFormQuestionsIds.closingDate]: undefined,
        }),
      );
      const {
        listingPrice,
        listingEffectiveDate,
        purchasePrice,
        closingDate,
        validUntil,
        liveDate,
      } = transactionPreFormQuestionsIds;
      if (answer) {
        updateQuestions(
          [listingPrice, listingEffectiveDate, validUntil, liveDate],
          transactionRoles.SELLER,
        );
        setNext(null, transactionPreFormQuestionsIds.purchasePrice);
      } else {
        let updateQuestionsArray;
        if (status === LISTED_STATUS.ActiveListing) {
          updateQuestionsArray = [purchasePrice, closingDate, liveDate];
        } else {
          updateQuestionsArray = [purchasePrice, closingDate];
        }
        updateQuestions(updateQuestionsArray, transactionRoles.SELLER);
        setNext(null, transactionPreFormQuestionsIds.listingPrice);
      }
    }
  };

  const showPostCreateFlow = () =>
    preForm?.[transactionPreFormQuestionsIds.isPropertyUnderContract] === false && isSellerFlow();

  const onSubmit = () => {
    if (currentQuestionId === transactionPreFormQuestionsIds.files) {
      setLoading(true);
      dispatch(
        createTransactionEffect_({}, {}, (err, response) => {
          if (!err) {
            if (showPostCreateFlow()) {
              dispatch(
                getTransactionEffect({ id: response.data.result.Id }, {}, () => {
                  history.push(routes.transactionPostCreate);
                  setLoading(false);
                }),
              );
            } else {
              setNext();
              setLoading(false);
            }

            dispatch(userGetDataOnBackgroundEffect());
          } else {
            history.push(routes.transactions);
            setLoading(false);
          }
        }),
      );
    }
    if (currentQuestionId === transactionPreFormQuestionsIds.complete) {
      if (isProject) {
        history.push(link.toProjectOverview(transaction?.Id));
      } else {
        history.push(link.toTransactionOverview(transaction?.Id));
      }
    }
  };

  const getTransactionBreadcrumb = (role) => {
    if (role === 'Seller') {
      return 'New Listing';
    } else if (role === 'Buyer') {
      return 'New Purchase';
    } else if (role === 'Buyer/Seller') {
      return 'Dual Agency Transaction';
    }

    return 'New Transaction';
  };

  const roleCreation = useMemo(() => {
    // T3-1: BUYER, SELLER replaced with CLIENT
    const { [transactionRoles.BUYER]: buyer, [transactionRoles.SELLER]: seller } =
      preForm?.[transactionPreFormQuestionsIds.whoAreYouRepresenting] || {};

    const creationRoles = [
      buyer ? transactionRoles.BUYER : undefined,
      seller ? transactionRoles.SELLER : undefined,
    ];

    return creationRoles.filter((role) => role).join('/');
  }, [preForm]);

  const breadcrumbsList = useMemo(
    () => [
      { label: 'Transactions', link: routes.transactions },
      { label: getTransactionBreadcrumb(roleCreation), link: location.pathname },
    ],
    [location.pathname, roleCreation],
  );

  return (
    <div testid="pre_form" className={classNames(styles.preForm, className)}>
      <div testid="transaction_create_header" className={styles.head}>
        <Controls
          onPrev={setPrevious}
          onAnswer={onAnswer}
          onSubmit={onSubmit}
          currentQuestionId={currentQuestionId}
        />
      </div>
      <ContentWrapper className={styles.preFormWrapper}>
        {questionsList.map(({ id, Component, props }) => (
          <ChangeQuestion show={currentQuestionId === id} key={id}>
            <Component
              onAnswer={onAnswer}
              onPrev={setPrevious}
              onNext={setNext}
              onPreFormSubmit={onSubmit}
              currentQuestionId={currentQuestionId}
              previousQuestionId={previousQuestionId}
              setPreviousQuestionId={setPreviousQuestionId}
              setCurrentQuestionId={setCurrentQuestionId}
              loading={loading}
              {...props}
            />
          </ChangeQuestion>
        ))}
      </ContentWrapper>
    </div>
  );
};

PreForm.propTypes = {
  className: PropTypes.string,
};

PreForm.defaultProps = {
  className: '',
};

export default PreForm;
