import { SourceDetailsBox } from './SourceDetailsBox';

export const SourceBreakdown = ({ summary, data, handleClick }) => {
  return (
    <div>
      {data.map((source, index) => (
        <div onClick={() => handleClick(source, index)}>
          <SourceDetailsBox source={source} />
        </div>
      ))}
    </div>
  );
};
