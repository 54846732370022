import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Avatar } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { trim } from 'lodash-es';
import Space from 'antd/lib/space';

import { Icons } from 'pages/Quotes/Icons';
import { Alert, Paragraph, Title } from 'components-antd';
import { Button } from 'components';
import { ArrowLeft, FavoriteIcon } from 'components/Icons';
import { AGENT, CLIENT } from 'settings/constants/roles';
import { MessageIcon } from 'pages/ServicesCategory/components/AgentPartnersList/icons';
import { quoteRequestStatus, quoteRequestStatusDisplayMap } from 'settings/constants/quotes';
import { getQuoteDetailsSelector } from 'store/selectors/quoteDetails';
import { archiveQuotesEffect } from 'store/effects/quotes';
import { ArchiveModal } from 'pages/Quotes/components';
import { getUserRolesMapSelector } from 'store/selectors/user';

import styles from './styles.module.scss';

export const Header = ({
  status,
  onModalClose,
  isProfileView,
  partnerData,
  categories,
  userRole,
  showRecommendedBy,
  recommendedBy,
  showRecommended,
  showRecommend,
  setFullProfile,
  setConfirmChangeRecommendation,
  handleMessageButtonClick,
}: any) => {
  const { quote } = useSelector(getQuoteDetailsSelector);
  const { isThirdParty } = useSelector(getUserRolesMapSelector);
  const dispatch = useDispatch();

  const [archiveQuoteId, setArchiveQuoteId] = useState(null);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [isPendingArchiveRequest, setIsPendingArchiveRequest] = useState(false);

  const onArchive = () => {
    if (!quote?.IsArchived) {
      setArchiveQuoteId(quote?.Id);
      setOpenArchiveModal(true);
    } else {
      dispatch(archiveQuotesEffect({ ids: [quote?.Id], newArchivedState: false }));
    }
  };
  const onCloseArchiveModal = () => {
    setOpenArchiveModal(false);
    setArchiveQuoteId(null);
  };

  const onSubmitArchive = (status) => {
    setIsPendingArchiveRequest(true);
    dispatch(
      archiveQuotesEffect({ ids: [archiveQuoteId], status }, {}, (err) => {
        if (!err) {
          setOpenArchiveModal(false);
        }
        setIsPendingArchiveRequest(false);
      }),
    );
  };
  return (
    <>
      {isProfileView ? (
        <div className={styles.modalHeader}>
          <Avatar
            className={styles.avatarWrap}
            avatarClassName={styles.avatar}
            src={partnerData?.LogoUrl}
            placeholder={convertNameToAvatarPlaceholder(trim(partnerData?.BusinessName))}
          />

          <div className={styles.goBackButton} onClick={() => setFullProfile(false)}>
            <ArrowLeft />
          </div>

          <Title level={3} className={styles.businessName}>
            {partnerData?.BusinessName}
          </Title>
          <Paragraph
            className={classNames(styles.categoriesParagraph, {
              [styles.isClient]: userRole === CLIENT,
            })}
          >
            {categories}
          </Paragraph>

          <Space direction="horizontal">
            <>
              {userRole === CLIENT || userRole === AGENT ? (
                <Button
                  className={styles.messageBtn}
                  onClick={handleMessageButtonClick}
                  title="Message"
                  iconLeft={<MessageIcon />}
                />
              ) : null}
            </>
          </Space>
          {showRecommendedBy() ? (
            <Alert
              className={styles.recommendedBar}
              message={
                <>
                  <FavoriteIcon className={styles.recommendedIcon} />
                  {'Recommended by '}
                  <span>{recommendedBy}</span>
                </>
              }
              type="error"
            />
          ) : showRecommended() ? (
            <Alert
              className={classNames(styles.recommendedBar, styles.clickable)}
              message={
                <>
                  <FavoriteIcon className={styles.recommendedIcon} />
                  Recommended
                </>
              }
              onClick={() => setConfirmChangeRecommendation(true)}
              type="error"
            />
          ) : showRecommend() ? (
            <Alert
              className={styles.unRecommendedBar}
              message={
                <>
                  <FavoriteIcon className={styles.unRecommendedIcon} active={false} />
                  Recommend
                </>
              }
              onClick={() => setConfirmChangeRecommendation(true)}
            />
          ) : null}
        </div>
      ) : (
        <div className={styles.header}>
          <div className={styles.heading}>
            <div className={styles.headingLeft}>
              <span className={styles.initHeading}>Initial Quote</span>
              <br />

              <span className={styles.offer}>{`${isThirdParty ? 'Quote Request' : 'Quote'}`} </span>
            </div>
            <div className={styles.actions}>
              <div
                className={classNames(
                  styles.status,
                  {
                    [styles.pending]: status === quoteRequestStatus.PendingAnswer,
                  },
                  {
                    [styles.pending]: status === quoteRequestStatus.New,
                  },
                  {
                    [styles.responded]: status === quoteRequestStatus.Responded,
                  },
                )}
              >
                <span>{quoteRequestStatusDisplayMap[status]}</span>
              </div>
              {isThirdParty && (
                <Icons className={styles.icon} variant={Icons.ARCHIVE_BG} onClick={onArchive} />
              )}
              <Icons
                className={styles.closeIcon}
                color={'#000'}
                variant={Icons.CLOSE}
                onClick={onModalClose}
              />
            </div>
          </div>
          {isThirdParty && (
            <ArchiveModal
              isOpen={openArchiveModal}
              onClose={onCloseArchiveModal}
              onSubmit={onSubmitArchive}
              isPending={isPendingArchiveRequest}
              archivedStatus={quote?.ArchivedStatus}
            />
          )}
        </div>
      )}
    </>
  );
};
