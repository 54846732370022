import React, { useEffect, useState } from 'react';
import moment from 'moment';
import type { ColumnsType } from 'antd/es/table';
import { getClientNamesForAggregateTables, getFirstNameLastInitial } from 'utils';
import classNames from 'classnames';
import { convertNameToAvatarPlaceholder, timeDifferenceFromNow } from 'helpers';
import { Checkbox } from 'components-antd';

import { ArrowDownFull } from 'components/Icons';
import { Icons } from 'pages/Workshop/Icons';
import { Avatar } from 'components';

import styles from './styles.module.scss';
import { Status } from '../Status';
import { useSelector } from 'react-redux';
import { getSelectedOffersForComparison } from 'store/selectors/offers';
import { getOfferTitle } from './helper';

interface DataType {
  key: React.Key;
  Clients: { FirstName: string; LastName: string }[];
  TransactionClients: string[];
  Property: any;
  Amount: number;
  BuyingAgent: { Brokerage: string; Name: string };
  ReceivingDate: number;
  OfferExpiryEndDate: string;
  PropertyId: number;
  OfferStatus: string;
  OfferCount: number;
  LatestOfferIteration: any;
  isChild?: boolean;
}

export function tableColumns(
  isTransactionRoom,
  showSelection = false,
  setOffersSelected,
  isRejectedColumnData = false,
): ColumnsType<DataType> {
  const MultiselectContainer = ({
    row,
    setOffersSelected,
  }: {
    row: any;
    setOffersSelected?: Function;
  }) => {
    const [offers, setOffers] = useState([]);
    const selectedOffers = useSelector(getSelectedOffersForComparison);

    useEffect(() => {
      if (Object.entries(selectedOffers.selectedOffers).length > 0) {
        let offers: any = [];
        Object.entries(selectedOffers.selectedOffers).forEach((entry: any) => {
          const [key, value] = entry;
          offers.push(value);
        });
        setOffers(offers);
      }
    }, [selectedOffers]);

    function isChecked() {
      if (!row?.isChild) {
        const offer = offers?.find(
          (item: any) => item?.LatestOfferIteration?.Id === row?.LatestOfferIteration?.Id,
        );
        return !!offer;
      } else {
        const offer = offers?.find((item: any) => item?.key === row?.key);
        return !!offer;
      }
    }

    return (
      <div className={styles.actionSelectContainer}>
        <div className={styles.multiSelect}>
          {showSelection && (
            <Checkbox
              className={styles.checkbox}
              checked={isChecked()}
              onClick={(e: any) => {
                e.stopPropagation();
                if (setOffersSelected) {
                  setOffersSelected(row, !isChecked());
                }
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const transactionColumnItem = {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(({ column }) => column.key === 'transaction');
      const sortAsc = sortedColumn?.order === 'ascend';
      return (
        <div className={styles.sortedColumnTitle}>
          Transaction
          <ArrowDownFull
            className={classNames(styles.sorterIcon, { [styles.sorterUp]: sortAsc })}
          />
        </div>
      );
    },
    key: 'transaction',
    width: 380,
    dataIndex: 'Property',
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (text, { Property }) => {
      const address1: string = Property.Address.Line1;
      const address2: string = Property.Address.Line1;
      return (
        <div className={styles.transactionContentWrapper}>
          <div>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="16" r="16" fill="#D6D6D7" fillOpacity="0.25" />
              <path
                d="M17.6665 8.5V11.8333C17.6665 12.0543 17.7543 12.2663 17.9106 12.4226C18.0669 12.5789 18.2788 12.6667 18.4998 12.6667H21.8332"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.1665 23.5H11.8332C11.3911 23.5 10.9672 23.3244 10.6547 23.0118C10.3421 22.6993 10.1665 22.2754 10.1665 21.8333V10.1667C10.1665 9.72464 10.3421 9.30072 10.6547 8.98816C10.9672 8.67559 11.3911 8.5 11.8332 8.5H17.6665L21.8332 12.6667V21.8333C21.8332 22.2754 21.6576 22.6993 21.345 23.0118C21.0325 23.3244 20.6085 23.5 20.1665 23.5Z"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5 13.5H14.3333"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5 16.834H18.5"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.5 20.166H18.5"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={styles.transactionContent}>
            <span className={styles.transactionContentFirstRow}> {address1} </span>
            <br />
            <span className={styles.contentSecondRow}>
              {`${Property.Address.City}, ${Property.Address.State} ${Property.Address.Zip}`}
            </span>
          </div>
        </div>
      );
    },
    sorter: (a, b) => {
      const aAddress = a.Property.Address.Line1.toLowerCase();
      const bAddress = b.Property.Address.Line1.toLowerCase();
      if (aAddress > bAddress) return 1;
      if (aAddress < bAddress) return -1;
      return 0;
    },
  };

  const offerColumnItem = {
    title: 'Offer',
    key: 'OfferCount',
    sorter: (a, b) => a.OfferCount - b.OfferCount,
    width: 300,
    render: (row) => {
      if (row?.isChild) {
        return (
          <span className={classNames(styles.offerColumn, styles.childColumn)}>
            <div className={styles.offerTreeLine} />
            <Icons variant={Icons.OFFER_3} className={styles.icon} />
            <span>{row?.title}</span>
          </span>
        );
      }

      return (
        <span className={styles.offerColumn}>
          {!row?.children && <Icons variant={Icons.OFFER_2} className={styles.icon} />}
          <span>{getOfferTitle(row)}</span>
        </span>
      );
    },
  };

  const clientColumnItem = {
    title: 'Client',
    key: 'client',
    width: 200,
    dataIndex: 'Clients',
    render: (_, { TransactionClients }) => {
      if (!TransactionClients)
        return (
          <div className={styles.avatarRow}>
            <Avatar
              className={styles.avatarWrapper}
              avatarClassName={styles.avatar}
              src={null}
              placeholder={undefined}
            />
            <span className={styles.assigneeName}></span>
          </div>
        );

      const isNullOrWhitespace = (input) => !input || !input.trim();
      return (
        <div className={styles.avatarRow}>
          <Avatar
            className={styles.avatarWrapper}
            avatarClassName={styles.avatar}
            src={null}
            placeholder={
              !isNullOrWhitespace(TransactionClients[0]) ? (
                <div className={styles.avatarPlaceholder}>
                  {convertNameToAvatarPlaceholder(`${TransactionClients[0]}`)}
                </div>
              ) : undefined
            }
          />
          <span className={styles.assigneeName}>
            {TransactionClients[0] ?? ''}&nbsp;
            {TransactionClients?.length > 1 ? ` + ${TransactionClients.length - 1}` : ''}
          </span>
        </div>
      );
    },
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'OfferStatus',
      sorter: (a, b) => {
        const statusTextA = a.LatestOfferIteration?.IsViewed ? a.OfferStatus : 'New';
        const statusTextB = b.LatestOfferIteration?.IsViewed ? b.OfferStatus : 'New';
        return statusTextA.localeCompare(statusTextB);
      },
      width: 180,
      render: (text, row) => {
        if (row?.isChild) return <></>;
        const statusText = row?.LatestOfferIteration?.IsViewed ? text : 'New';
        return <Status status={statusText} />;
      },
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'Amount',
      width: 300,
      sorter: (a, b) => Number(a.Amount) - Number(b.Amount),
      render: (text) => {
        {
          return Number(text).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
          });
        }
      },
    },
    {
      title: 'Submitted By',
      key: 'submittedBy',
      width: 180,
      dataIndex: 'BuyingAgent',
      sorter: (a, b) => a.BuyingAgent.Name.localeCompare(b.BuyingAgent.Name),
      render: (_, { BuyingAgent }) => {
        const agentName = getFirstNameLastInitial(BuyingAgent.Name);
        return (
          <div className={styles.submittedBy}>
            <span>{agentName}</span>
            <br />
            <span className={styles.contentSecondRow}>{BuyingAgent.Brokerage}</span>
          </div>
        );
      },
    },
    {
      title: 'Received',
      key: 'ReceivingDate',
      dataIndex: 'ReceivingDate',
      width: 265,
      sorter: (a, b) => moment(a.ReceivingDate).unix() - moment(b.ReceivingDate).unix(),
      defaultSortOrder: 'descend',
      render: (text) => {
        const date = moment(text);
        if (date.isValid()) {
          const timeAgo = timeDifferenceFromNow(date);
          return (
            <div className={styles.received}>
              <span>{timeAgo}</span>
            </div>
          );
        } else {
          return <div></div>;
        }
      },
    },
    {
      title: isRejectedColumnData ? 'Rejected' : 'Expires',
      key: 'OfferExpiryEndDate',
      dataIndex: 'OfferExpiryEndDate',
      sorter: (a, b) => moment(a.OfferExpiryEndDate).unix() - moment(b.OfferExpiryEndDate).unix(),
      render: (text, row) => {
        if (row?.isChild) return null;
        const date = moment(text);
        if (date.isValid()) {
          const timeAgo = timeDifferenceFromNow(date);
          return (
            <div className={styles.expiredText}>
              <span>{timeAgo}</span>
            </div>
          );
        } else {
          return <div>-</div>;
        }
      },
    },

    ...(isTransactionRoom
      ? [
          {
            title: '',
            dataIndex: '',
            key: 'selection',
            width: 0,
            render: (_, row) => {
              const multiselectionProps = {
                row,
                setOffersSelected: setOffersSelected,
              };

              return <MultiselectContainer {...multiselectionProps} />;
            },
          },
        ]
      : []),
  ];

  const aggregatePageColumns: any = [transactionColumnItem, clientColumnItem, ...columns];
  const transactionPageColumns: any = [offerColumnItem, ...columns];
  return isTransactionRoom ? transactionPageColumns : aggregatePageColumns;
}
