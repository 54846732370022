import React, { useEffect } from 'react';

import { PageHeader, Wrapper } from 'components';

import parentStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import { ReminderCard } from '../components/PropertyTab/components';
import { useDispatch, useSelector } from 'react-redux';
import { getRemindersByIdSelector } from 'store/selectors/intel';
import { useHistory, useLocation } from 'react-router-dom';
import { requestGetRemindersClientEffect } from 'store/effects/intel';
import { Icons, IconVariant } from '../components/icons';

export const Reminders: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const state = location.state;
  const { data, isPending } = useSelector(getRemindersByIdSelector);

  useEffect(() => {
    dispatch(requestGetRemindersClientEffect(state));
  }, []);

  const splitByDate = (data) => {
    const now = new Date(); // Current date and time
    const upcoming: any = [];
    const past: any = [];

    data.forEach((item) => {
      const createdDate = new Date(item.CreatedDate);
      if (createdDate > now) {
        upcoming.push(item);
      } else {
        past.push(item);
      }
    });

    return { upcoming, past };
  };

  const { upcoming, past } = splitByDate(data || []);

  return (
    <Wrapper className={parentStyles.pageWrapper} isPending={isPending}>
      <PageHeader title={`Reminders`}>
        <div
          className={styles.back}
          onClick={() => {
            history.push('/intel');
          }}
        >
          <Icons variant={IconVariant.BACK} />
        </div>
      </PageHeader>
      <div className={styles.pageWrapper}>
        <div className={styles.section}>
          <div className={styles.heading}>
            Upcoming <span>({upcoming?.length || 0})</span>
          </div>
          {upcoming?.length >= 1 && <ReminderCard variant="large" data={upcoming} />}
        </div>
        <div className={styles.section}>
          <div className={styles.heading}>
            Past <span>({past?.length || 0})</span>
          </div>
          {past?.length >= 1 && <ReminderCard variant="large" data={past} />}
        </div>
      </div>
    </Wrapper>
  );
};
