import React from 'react';
import {
  Modal,
  Avatar,
  ModalProps,
  AvatarGroup,
  UserAvatar,
  UserAvatarProps,
} from 'components-antd';
import styles from './styles.module.scss';
import CondoSmall from './Condo-small.svg';
import { Button } from 'components-antd';

interface AgentInvitationModalProps extends ModalProps {
  avatarProps: UserAvatarProps;
  agentId: number;
  agentName: string;
  searchInstance: any;
  transactionInstance: any;
  onSubmit: any;
}

export const AgentInvitationModal = ({
  avatarProps,
  agentId,
  agentName,
  searchInstance,
  transactionInstance,
  onSubmit,
  ...props
}: AgentInvitationModalProps) => {
  const city = searchInstance?.DefaultPropertySearchPreferences?.Locations?.[0]?.City;
  const address = transactionInstance?.[0]?.Address;

  return (
    <Modal
      width={675}
      data-testid="agent-invitation-modal"
      className={styles.agentInvitationModal}
      {...props}
    >
      <div>
        <AvatarGroup>
          <UserAvatar {...avatarProps} />
          <Avatar className={styles.defaultAvatar} src={CondoSmall} size={56} />
        </AvatarGroup>
        <h1>You&apos;ve been Invited</h1>
        <h3>
          <span className={styles.bold}>{agentName}</span> has invited you to work together on
          {searchInstance ? ` your home search` : ''}
          {city ? ` in ` : ''}
          {city ? <span className={styles.bold}>{city}</span> : ''}
          {searchInstance && transactionInstance?.length ? ' and' : ''}
          {address ? ` your home sale at ` : ''}
          {address ? <span className={styles.bold}>{address.PlaceName}</span> : ''}.
        </h3>
        <br></br>
        <h3>
          If you accept this invite, you can still work with other agents on the Mosaik platform.
          Your data remains private and is separated by agent.
        </h3>

        <Button
          className={styles.acceptBtn}
          variant="secondary"
          onClick={() => onSubmit(agentId, 'Accepted')}
        >
          Accept Invite
        </Button>
        <br></br>
        <Button
          className={styles.declineBtn}
          variant="secondary-inverted"
          onClick={() => onSubmit(agentId, 'Declined')}
        >
          Decline Invite
        </Button>
      </div>
    </Modal>
  );
};
