import moment from 'moment';
import { get, groupBy, uniqBy } from 'lodash-es';
import { createSelector } from 'reselect';

import { splitFirstOccurrence } from 'utils';
import { getUserRoleSelector } from 'store/selectors/user';
import { IDLE, PENDING } from 'settings/constants/apiState';
import {
  transactionStatuses,
  LISTED_STATUS,
  listedStatusesArray,
} from 'settings/constants/transactionStatus';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';
import PropertyImagePlaceholder from 'images/property-image-placeholder.png';
import {
  getTransactionStatusCount,
  getTransactionEndDate,
  getTransactionPrice,
  getTransactionEffectiveDate,
  getTransactionClosingDate,
} from 'utils';
import { getDateOnly } from 'helpers';

const localState = ({ transactions }) => transactions;

export const getTransactionsDataSelector = createSelector(localState, ({ data }) => data);

export const getTransactionsSearchSelector = createSelector(localState, ({ search }) => search);

export const getTransactionsIsArchiveSelector = createSelector(
  localState,
  ({ isArchive }) => isArchive,
);

export const getTransactionsFiltersAppliedSelector = createSelector(
  localState,
  ({ filters }) => filters,
);

export const getTransactionsIsFilterSelector = createSelector(
  localState,
  ({ isFiltersApplied }) => isFiltersApplied,
);

export const getProjectFilterSelector = createSelector(
  localState,
  ({ projectFilter }) => projectFilter,
);

export const getTransactionsSelector = createSelector(localState, ({ state, meta, ...rest }) => ({
  isIdle: state === IDLE,
  isPending: state === PENDING,
  isData: !!rest?.data,
  ...rest,
}));

const getTransactionCardTitle = (role, transaction) => {
  if ([CLIENT, THIRD_PARTY].includes(role)) {
    return get(transaction, 'Property.Address.Line1') || 'Private Address';
  }
  const RepresentingRoles = transaction?.RepresentingRoles?.join('');

  if (RepresentingRoles === 'Buyer') {
    return (
      `${get(transaction, 'Buyer.User.FirstName') || get(transaction, 'Buyer.User.Email') || ''} ${
        get(transaction, 'Buyer.User.LastName') || ''
      }`.trim() ||
      transaction?.TransactionClients?.[0] ||
      'No Buyer'
    );
  } else {
    return (
      `${
        get(transaction, 'Seller.User.FirstName') || get(transaction, 'Seller.User.Email') || ''
      } ${get(transaction, 'Seller.User.LastName') || ''}`.trim() ||
      transaction?.TransactionClients?.[0] ||
      'No Seller'
    );
  }
};

const getTransactionCardSubtitle = (role, transaction) => {
  if ([CLIENT, THIRD_PARTY].includes(role)) {
    const city = get(transaction, 'Property.Address.City') || '';
    const state = get(transaction, 'Property.Address.State') || '';
    const zip = get(transaction, 'Property.Address.Zip') || '';

    return `${city ? `${city}, ` : ''} ${state} ${zip}`.trim() || 'Private Address';
  }

  return '';
};

const getTransactionCardImageUrl = (role, transaction) => {
  if ([CLIENT, THIRD_PARTY].includes(role)) {
    return get(transaction, 'Property.ImageUrl');
  }

  return get(transaction, 'Buyer.User.AvatarUrl') || get(transaction, 'Seller.User.AvatarUrl');
};

const getTransactionImagePlaceholder = (role, transaction) => {
  if ([CLIENT, THIRD_PARTY].includes(role)) {
    return PropertyImagePlaceholder;
  }

  const firstName =
    get(transaction, 'Buyer.User.FirstName') || get(transaction, 'Seller.User.FirstName');
  const lastName =
    get(transaction, 'Buyer.User.LastName') || get(transaction, 'Seller.User.LastName');

  const firstUninvitedClient = transaction?.TransactionClients?.[0];
  const { first, remainder } = splitFirstOccurrence(firstUninvitedClient, ' ');

  return firstName
    ? `${firstName?.[0]?.toUpperCase() || ''}${lastName?.[0]?.toUpperCase() || ''}`
    : `${first?.[0]?.toUpperCase() || ''}${remainder?.[0]?.toUpperCase() || ''}`;
};

export const getSearchTransactions = ({ data, search, teamTransactions }, role) => {
  let result = (data || [])
    .filter((transaction) => {
      if (search) {
        const formattedSearch = search.toLowerCase();

        const city = get(transaction, 'Property.Address.City') || '';
        const line1 = get(transaction, 'Property.Address.Line1') || '';
        const state = get(transaction, 'Property.Address.State') || '';
        const zip = get(transaction, 'Property.Address.Zip') || '';
        const address = `${line1 ? `${line1},` : ''} ${
          city ? `${city},` : ''
        } ${state} ${zip}`.trim();
        const matchAddressWithSpace = `${line1 ? `${line1} ` : ''} ${
          city ? `${city},` : ''
        } ${state} ${zip}`.trim();
        const matchAddressWithoutAnyCharacter = `${line1 ? `${line1}` : ''} ${
          city ? `${city},` : ''
        } ${state} ${zip}`.trim();

        const isPropertyAddressMatch =
          address.toLowerCase().includes(formattedSearch) ||
          matchAddressWithSpace.toLowerCase().includes(formattedSearch) ||
          matchAddressWithoutAnyCharacter.toLowerCase().includes(formattedSearch);

        const buyer = transaction?.Buyer?.User;
        const buyerName = buyer ? `${buyer.FirstName} ${buyer.LastName}` : '';
        const seller = transaction?.Seller?.User;
        const sellerName = seller ? `${seller.FirstName} ${seller.LastName}` : '';
        const creator = transaction?.Creator;
        const creatorName = creator ? `${creator.FirstName} ${creator.LastName}` : '';
        const name = transaction?.Name ? transaction?.Name : '';
        const status = transaction?.Status || '';
        let clients = [];
        try {
          const regexPattern = formattedSearch
            // eslint-disable-next-line no-useless-escape
            .replace(/[\(\)\[\]\{\}]/g, '\\$&')
            .replace(/\\/g, '\\\\'); // Escape backslashes

          const regex = new RegExp(regexPattern, 'i');
          clients = (transaction?.TransactionClients || []).filter((item) => regex.test(item));
        } catch (err) {
          clients = [];
        }

        if (role === AGENT) {
          return (
            isPropertyAddressMatch ||
            sellerName.toLowerCase().includes(formattedSearch) ||
            buyerName.toLowerCase().includes(formattedSearch) ||
            name.toLowerCase().includes(formattedSearch) ||
            creatorName.toLowerCase().includes(formattedSearch) ||
            status.toLowerCase().includes(formattedSearch) ||
            clients.length > 0
          );
        }

        if (role === THIRD_PARTY) {
          return (
            isPropertyAddressMatch ||
            buyerName.toLowerCase().includes(formattedSearch) ||
            sellerName.toLowerCase().includes(formattedSearch) ||
            name.toLowerCase().includes(formattedSearch) ||
            creatorName.toLowerCase().includes(formattedSearch) ||
            status.toLowerCase().includes(formattedSearch) ||
            clients.length > 0
          );
        }

        return isPropertyAddressMatch;
      }

      return true;
    })
    .map((transaction) => {
      const keyDatesPrices =
        get(transaction, 'PropertyTransactionKeyDatesPrices') ||
        get(transaction, 'ProjectKeyDates');

      const date = getTransactionClosingDate(keyDatesPrices, transaction?.IsProject);
      const formattedDate = date ? moment(date).format('MM/DD') : '';

      const transactionInfo = {
        id: get(transaction, 'Id'),
        title: getTransactionCardTitle(role, transaction),
        subtitle: getTransactionCardSubtitle(role, transaction),
        imageUrl: getTransactionCardImageUrl(role, transaction),
        imagePlaceholder: getTransactionImagePlaceholder(role, transaction),
        data: transaction,
        tasksAmount: transaction?.Tasks?.length || 0,
        date: formattedDate,
        isMyTransaction: get(transaction, 'IsMyTransaction'),
        isTeamTransaction: get(transaction, 'IsTeamTransaction'),
      };

      return transactionInfo;
    });

  return result;
};

export const getAllTransactionsSelector = createSelector(
  localState,
  getUserRoleSelector,
  ({ data, search, teamTransactions, meta }, role) => {
    const transactions = getSearchTransactions({ data, search, teamTransactions }, role);
    return transactions.map((transaction) => {
      const city = get(transaction.data, 'Property.Address.City') || '';
      const line1 = get(transaction.data, 'Property.Address.Line1') || '';
      const state = get(transaction.data, 'Property.Address.State') || '';
      const zip = get(transaction.data, 'Property.Address.Zip') || '';
      const address = `${line1 ? `${line1},` : ''} ${
        city ? `${city},` : ''
      } ${state} ${zip}`.trim();
      const status = transaction?.data?.Status;
      const keyDatesPrices =
        transaction?.data?.PropertyTransactionKeyDatesPrices || transaction?.data?.ProjectKeyDates;
      const endDate = getTransactionEndDate(status, keyDatesPrices);
      const { overdueTasksCount, totalTasks } = getTransactionStatusCount(transaction?.data?.Tasks);
      const price = getTransactionPrice(status, keyDatesPrices);
      const effectiveDate = getTransactionEffectiveDate(keyDatesPrices, transaction?.IsProject);
      const listingExpireDate = keyDatesPrices?.ListingExpireDate;
      const closeDate = keyDatesPrices?.CloseDate;
      return {
        ...transaction,
        closeDate,
        overdueTasksCount,
        totalTasks,
        status,
        price,
        effectiveDate,
        listingExpireDate,
        address: address,
        closingDate: endDate ? getDateOnly(endDate, 'M/D/YYYY') : 'N/A',
        isPending: state !== 'ready',
      };
    });
  },
);

export const getAggregatePageTransactionsSelector = createSelector(
  localState,
  getUserRoleSelector,
  ({ data, search, teamTransactions, meta, state }, role) => {
    const transactions = getSearchTransactions({ data, search, teamTransactions }, role);
    return {
      transactions: transactions.map((transaction) => {
        const transactionData = transaction.data;
        const city = get(transactionData, 'Property.Address.City') || '';
        const line1 = get(transactionData, 'Property.Address.Line1') || '';
        const state = get(transactionData, 'Property.Address.State') || '';
        const zip = get(transactionData, 'Property.Address.Zip') || '';
        const address = `${line1 ? `${line1},` : ''} ${
          city ? `${city},` : ''
        } ${state} ${zip}`.trim();

        const status = transactionData?.Status;
        const keyDatesPrices =
          transactionData?.PropertyTransactionKeyDatesPrices || transactionData?.ProjectKeyDates;
        const endDate = getTransactionEndDate(status, keyDatesPrices);
        const price = getTransactionPrice(status, keyDatesPrices);

        // Return enriched transaction
        return {
          ...transactionData,
          status,
          price,
          address: address,
          closingDate: endDate ? getDateOnly(endDate, 'M/D/YYYY') : 'N/A',
        };
      }),
      isPending: state !== 'ready',
    };
  },
);

export const getGroupedTransactionsSelector = createSelector(
  localState,
  getUserRoleSelector,
  ({ data, search, teamTransactions }, role) => {
    const filteredTransactions = (data || []).filter(
      (t) => (!teamTransactions && t?.IsMyTransaction) || teamTransactions,
    );

    let result = filteredTransactions
      .filter((transaction) => {
        if (search) {
          const formattedSearch = search.toLowerCase();

          const city = get(transaction, 'Property.Address.City') || '';
          const line1 = get(transaction, 'Property.Address.Line1') || '';
          const state = get(transaction, 'Property.Address.State') || '';
          const zip = get(transaction, 'Property.Address.Zip') || '';
          const address = `${line1 ? `${line1},` : ''} ${
            city ? `${city},` : ''
          } ${state} ${zip}`.trim();

          const isPropertyAddressMatch = address.toLowerCase().includes(formattedSearch);

          const buyer = transaction?.Buyer?.User;
          const buyerName = buyer ? `${buyer.FirstName} ${buyer.LastName}` : '';
          const seller = transaction?.Seller?.User;
          const sellerName = seller ? `${seller.FirstName} ${seller.LastName}` : '';

          if (role === AGENT) {
            return (
              isPropertyAddressMatch ||
              sellerName.toLowerCase().includes(formattedSearch) ||
              buyerName.toLowerCase().includes(formattedSearch)
            );
          }

          if (role === THIRD_PARTY) {
            return (
              isPropertyAddressMatch ||
              buyerName.toLowerCase().includes(formattedSearch) ||
              sellerName.toLowerCase().includes(formattedSearch)
            );
          }

          return isPropertyAddressMatch;
        }

        return true;
      })
      .map((transaction) => {
        const keyDatesPrices =
          transaction?.data?.PropertyTransactionKeyDatesPrices ||
          transaction?.data?.ProjectKeyDates;
        const status = get(transaction, 'Status');
        const endDate = getTransactionEndDate(status, keyDatesPrices);
        const effectiveDate = getTransactionEffectiveDate(keyDatesPrices, transaction?.IsProject);
        const transactionInfo = {
          id: get(transaction, 'Id'),
          title: getTransactionCardTitle(role, transaction),
          subtitle: getTransactionCardSubtitle(role, transaction),
          imageUrl: getTransactionCardImageUrl(role, transaction),
          imagePlaceholder: getTransactionImagePlaceholder(role, transaction),
          data: transaction,
          tasksAmount: transaction?.Tasks?.length || 0,
          date: endDate,
          isMyTransaction: get(transaction, 'IsMyTransaction'),
          isTeamTransaction: get(transaction, 'IsTeamTransaction'),
          name: get(transaction, 'Name'),
          category: get(transaction, 'TransactionCategory.Category'),
          owner: `${get(transaction, 'Creator.FirstName')} ${get(transaction, 'Creator.LastName')}`,
          effectiveDate: effectiveDate ? moment(effectiveDate).format('M/DD') : '',
          closingDate: endDate ? moment(endDate).format('M/DD') : '',
        };

        return transactionInfo;
      });

    result = groupBy(result, 'data.Status');

    if (role === CLIENT) {
      result.Active = [
        ...(result[LISTED_STATUS.Active] || []),
        ...(result[LISTED_STATUS.ActiveListing] || []),
        ...(result[LISTED_STATUS.ComingSoon] || []),
        ...(result[LISTED_STATUS.OfficeExclusive] || []),
        ...(result[LISTED_STATUS.Pending] || []),
        ...(result[LISTED_STATUS.PreListing] || []),
        ...(result[LISTED_STATUS.OnHold] || []),
        ...(result[transactionStatuses.Offers] || []),
        ...(result[transactionStatuses.UnderContract] || []),
        ...(result[transactionStatuses.ClearToClose] || []),
        ...(result[transactionStatuses.InProgress] || []),
        ...(result[transactionStatuses.Upcoming] || []),
        ...(result[transactionStatuses.OnHold] || []),
        ...(result[transactionStatuses.AtRisk] || []),
      ];
    }

    return result;
  },
);

export const getTeamTransactionsSelector = createSelector(
  localState,
  ({ teamTransactions }) => teamTransactions,
);

export const getTaskAggregateTransactionsSelector = createSelector(localState, ({ data }) => {
  const transactions = data?.map((transaction) => ({
    Id: transaction.Id,
    label: transaction.Property?.Address?.Line1,
  }));
  return uniqBy(transactions, 'Id');
});

export const getAggregateMilestoneTransactionsSelector = createSelector(localState, ({ data }) => {
  const applicableTransactions = data?.filter((transaction) =>
    [
      ...listedStatusesArray,
      transactionStatuses.UnderContract,
      transactionStatuses.ClearToClose,
    ].includes(transaction.Status),
  );

  const transactions = applicableTransactions?.map((transaction) => {
    const keyDatesPrices =
      get(transaction, 'PropertyTransactionKeyDatesPrices') || get(transaction, 'ProjectKeyDates');
    const ClosingDate = getTransactionClosingDate(keyDatesPrices, transaction?.IsProject);
    return {
      Id: transaction.Id,
      label: transaction.Property?.Address?.Line1,
      Milestones: transaction.Milestones,
      ClosingDate,
      CreatedDate: transaction.CreatedDate,
    };
  });
  return uniqBy(transactions, 'Id');
});

export const getAggregateMilestoneTransactionsWithParticipantsSelector = createSelector(
  localState,
  ({ data }) => {
    const applicableTransactions = data?.filter((transaction) =>
      [
        ...listedStatusesArray,
        transactionStatuses.UnderContract,
        transactionStatuses.ClearToClose,
      ].includes(transaction.Status),
    );

    const transactions = applicableTransactions?.map((transaction) => {
      const keyDatesPrices =
        get(transaction, 'PropertyTransactionKeyDatesPrices') ||
        get(transaction, 'ProjectKeyDates');
      const ClosingDate = getTransactionClosingDate(keyDatesPrices, transaction?.IsProject);
      return {
        Id: transaction.Id,
        label: transaction.Property?.Address?.Line1,
        Milestones: transaction.Milestones,
        CreatedDate: transaction.CreatedDate,
        Participants: transaction.Participants,
        TransactionAccess: transaction?.TransactionAccess,
        ClosingDate,
      };
    });
    return uniqBy(transactions, 'Id');
  },
);

export const getAllTransactionContactsSelector = createSelector(
  localState,
  ({ allTransactionContacts }) => {
    return allTransactionContacts;
  },
);

export const getTransactionsPersons = createSelector(localState, ({ transactionsPersons }) => {
  return transactionsPersons;
});
