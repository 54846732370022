import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
  getClientDrawerSendingInvite,
  isCriteriaSectionInviteFlowOPened,
  getActiveClientSearchInstance,
} from 'store/selectors/app';
import { PENDING } from 'settings/constants/apiState';
import { InviteHeader } from '../../../Headers';
import { FormTitle } from 'components';
import Footer from '../../../Footer';
import { BuyerProfile } from './BuyerProfile';
import { schema } from './validation';

import styles from './styles.module.scss';
import { inviteClientSteps } from 'types/inviteClient';
import { appSetActiveClientSearchInstanceAction } from 'store/actions/app';
import { getSearchDrawnPolygonSelector } from 'store/selectors/search';

const InviteClientSearchInstance = ({ onNext, stageIndex, onPrev }) => {
  const dispatch = useDispatch();
  const customLocation = useSelector(getSearchDrawnPolygonSelector);
  const { state } = useSelector(getClientDrawerSendingInvite);
  const isCriteriaSectionOpened = useSelector(isCriteriaSectionInviteFlowOPened);
  const activeClientSearchInstance = useSelector(getActiveClientSearchInstance);

  const formik = useFormik({
    initialValues:
      activeClientSearchInstance && Object.keys(activeClientSearchInstance).length > 0
        ? activeClientSearchInstance
        : InviteClientSearchInstance.initialValues,
    validateOnMount: true,
    validationSchema: schema,
    onSubmit(values) {
      if (customLocation !== null) {
        const newVal = { ...values, locations: [{ Polygon: customLocation, Type: 'Polygon' }] };
        dispatch(appSetActiveClientSearchInstanceAction(newVal));
        onNext(inviteClientSteps.ClientWishList);
      } else {
        dispatch(appSetActiveClientSearchInstanceAction(values));
        onNext(inviteClientSteps.ClientWishList);
      }
    },
  });
  const isPending = state === PENDING;

  return (
    <form
      testid="invite_buyer_form"
      onSubmit={formik.handleSubmit}
      className={styles.searchInstanceForm}
    >
      <InviteHeader
        disabled={isPending}
        stageIndex={stageIndex}
        onPrev={onPrev}
        isValid={formik.isValid}
      />
      <div
        className={classNames(styles.formContainer, {
          [styles.isCriteriaOpened]: isCriteriaSectionOpened,
        })}
      >
        {!isCriteriaSectionOpened && (
          <FormTitle className={styles.title}>Search Criteria</FormTitle>
        )}
        <div className={styles.outerModalContainer}>
          <BuyerProfile formik={formik} disabled={isPending} />
        </div>
      </div>
      {!isCriteriaSectionOpened && (
        <Footer
          disabled={isPending}
          buttonType="submit"
          buttonTitle="Continue to Wish List"
          stageIndex={stageIndex}
          onPrev={onPrev}
          isValid={formik.values.locations.length == 0 && !customLocation ? false : formik.isValid}
          className={styles.footer}
          testid="invite_buyer_footer"
        />
      )}
    </form>
  );
};

InviteClientSearchInstance.initialValues = {
  locations: '',
  priceMin: '',
  priceMax: '',
  bedsMin: '',
  bedsMax: '',
  bathsMin: '',
  bathsMax: '',
  propertyType: '',
  squareFeetMin: '',
  squareFeetMax: '',
  lotSizeMin: '',
  lotSizeMax: '',
};

InviteClientSearchInstance.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  stageIndex: PropTypes.number,
};

InviteClientSearchInstance.defaultProps = {
  onNext: () => {},
  onPrev: () => {},
  stageIndex: 1,
};

export default InviteClientSearchInstance;
