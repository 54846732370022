import { AllowedAnonEnum, FORM_PROCESS_USER } from 'app-constants';
import { FormIdsType, FormProcessDataType, FormProcessUserType } from 'types';

export const GET_PUBLISHED_FORMS = ({ search, order, orderBy }) => {
  let url = '/fillDocument/getpublishedforms';
  if (search) url = `${url}?searchString=${search.toLowerCase()}`;
  if (order) url = `${url}${search ? '&' : '?'}order=${order}&orderBy=${orderBy}`;

  return url;
};

export const GET_FORM_PROCESS_META = '/fillDocument/getformmeta';

export const GET_SMART_FORM_DATA = ({ formId, versionId }: FormIdsType) =>
  `/fillDocument/forms/getsmartformdata/${formId}/${versionId}`;

export const GET_AGENT_FORM_DATA = ({ formId, versionId }: FormIdsType) =>
  `/fillDocument/forms/getagentformdata/${formId}/${versionId}`;

export const GET_AGENT_FORM_ROLES = 'fillDocument/dynamicForm/getformroles';

export const GET_BUNDLE_FORM_ROLES = (bundleId) => `fillDocument/getFormBundleTemplate/${bundleId}`;

export const GET_BUNDLE_TEMPLATE = (bundleId) => `fillDocument/getFormBundleTemplate/${bundleId}`;

export const GET_FORM_TEMPLATE = ({ limit = 5, page }) =>
  `/fillDocument/formTemplate?limit=${limit}&page=${page}`;

export const CREATE_FORM_TEMPLATE = '/fillDocument/formTemplate';

export const UPDATE_FORM_TEMPLATE = (templateId) => `/fillDocument/formTemplate/${templateId}`;

export const UPDATE_BUNDLE_TEMPLATE = (templateBundleId) =>
  `/fillDocument/dynamicForm/${templateBundleId}/editBundleTemplate`;

export const GET_FORM_RECENT_UPLOADS = `/fillDocument/recentUploads`;

export const CREATE_UPDATE_FORM = '/fillDocument/forms';

export const CREATE_UPDATE_DYNAMIC_FORM = '/fillDocument/dynamicForm';

export const CREATE_UPDATE_DYNAMIC_BUNDLE_FORM = '/fillDocument/createformbundle';

export const ADD_FORM_DYNAMIC_VIEW = '/fillDocument/dynamicForm/addNewDocuments';
export const ADD_TEMPLATE_DYNAMIC_VIEW = (templateId) =>
  `/fillDocument/dynamicForm/addNewTemplates/${templateId}`;

export const REORDER_BUNDLE_DOCUMENT = (bundleId) => `/fillDocument/bundle/${bundleId}/reorder`;

export const CREATE_FORM_COPY = (formProcessId) => `/formProcess/${formProcessId}/copy`;

export const FORM_PROCESS_DOCUMENT = ({
  formProcessPublicId,
  formDocumentPublicId,
}: FormProcessDataType) =>
  `/fillDocument/forms/getformdocument/${formProcessPublicId}/${formDocumentPublicId}`;

export const GET_ANON_FORM_PROCESS = ({ type, token }) => {
  if (type === 'editor') {
    return `/fillDocument/forms/getformdocumentexternaleditor/${token}`;
  } else if (type === 'signatory') {
    return `/signDocument/forms/getformdocumentexternalsignatory/${token}`;
  } else if (type === 'complete') {
    return `/signDocument/forms/getformdocumentexternalcomplete/${token}`;
  } else {
    throw new Error('Invalid type');
  }
};

export const GET_ANON_DYNAMIC_FORM_PROCESS = ({ type, token }) => {
  if (type === 'editor') {
    return `/fillDocument/dynamicForm/getFormDocumentExternalEditor/${token}`;
  } else if (type === 'signatory') {
    return `/signDocument/forms/getformdocumentexternalsignatory/${token}`;
  } else if (type === 'complete') {
    return `/signDocument/forms/getformdocumentexternalcomplete/${token}`;
  } else {
    throw new Error('Invalid type');
  }
};

export const GET_TEMPLATE_DYNAMIC_FORM_PROCESS = ({ templateId }) =>
  `/fillDocument/formTemplate/${templateId}`;

export const FILL_FORM_DOCUMENT_AUTH = ({ formProcessPublicId, formDocumentPublicId }) =>
  `/fillDocument/forms/${formProcessPublicId}/${formDocumentPublicId}/fill`;

export const FILL_FORM_DOCUMENT_ANON = (type: FormProcessUserType) => {
  if (type === FORM_PROCESS_USER.Editor) {
    return `/fillDocument/forms/fillexternaleditor`;
  } else if (type === FORM_PROCESS_USER.Signatory) {
    return `/signdocument/forms/fillexternalsignatory`;
  } else {
    throw new Error('Invalid type');
  }
};

export const ACCEPT_TERMS_AND_CONDITIONS_AUTH = ({
  formProcessPublicId,
  formDocumentPublicId,
}: Omit<FormProcessDataType, 'url'>) =>
  `/fillDocument/forms/${formProcessPublicId}/accepttermsandconditions`;

export const ACCEPT_TERMS_AND_CONDITIONS_ANON = (type: FormProcessUserType) => {
  if (type === FORM_PROCESS_USER.Editor) {
    return `/fillDocument/forms/accepttermsandconditiosnexternaleditor`;
  } else if (type === FORM_PROCESS_USER.Signatory) {
    return `/signDocument/forms/accepttermsandconditionsexternalsignatory`;
  } else {
    throw new Error('Invalid type');
  }
};

export const DECLINE_SIGNATURE_AUTH = ({
  formProcessPublicId,
  formDocumentPublicId,
}: Omit<FormProcessDataType, 'url'>) =>
  `/fillDocument/forms/${formProcessPublicId}/${formDocumentPublicId}/declinesignature`;

export const DECLINE_SIGNATURE_ANON = (type: FormProcessUserType, token: string) => {
  if (type === FORM_PROCESS_USER.Editor) {
    return `/fillDocument/forms/declinesignatureexternaleditor/${token}`;
  } else if (type === FORM_PROCESS_USER.Signatory) {
    return `/signDocument/forms/declinesignatureexternalsignatory/${token}`;
  } else {
    throw new Error('Invalid type');
  }
};

export const UNLOCK_FORM_DOCUMENT_AUTH = ({
  formProcessPublicId,
  formDocumentPublicId,
}: Omit<FormProcessDataType, 'url'>) =>
  `/fillDocument/forms/${formProcessPublicId}/${formDocumentPublicId}/unlockform`;

export const DYNAMIC_UNLOCK_FORM_DOCUMENT_AUTH = ({
  formProcessPublicId,
  formDocumentPublicId,
}: Omit<FormProcessDataType, 'url'>) =>
  `/fillDocument/dynamicForm/${formProcessPublicId}/${formDocumentPublicId}/unlockForm`;

export const UNLOCK_FORM_DOCUMENT_ANON = (type: FormProcessUserType) => {
  if (type === FORM_PROCESS_USER.Editor) {
    return '/fillDocument/forms/unlockformexternaleditor';
  } else if (type === FORM_PROCESS_USER.Signatory) {
    return '/signDocument/forms/unlockformexternalsignatory';
  } else {
    throw new Error('Invalid type');
  }
};

export const PROGRESS_FORM_DOCUMENT_AUTH = ({ formProcessPublicId, formDocumentPublicId }) =>
  `fillDocument/forms/${formProcessPublicId}/${formDocumentPublicId}/progressformdocument`;

export const PROGRESS_FORM_DOCUMENT_ANON = ({ type }) => {
  if (type === 'editor') {
    return `/fillDocument/forms/progressformdocumentexternaleditor`;
  } else if (type === 'signatory') {
    return `/signDocument/forms/progressformdocumentexternalsignatory`;
  } else {
    throw new Error('Invalid type');
  }
};

export const SEND_FORM_PROCESS = ({ formProcessPublicId, formDocumentPublicId }) =>
  `/fillDocument/forms/${formProcessPublicId}/${formDocumentPublicId}/sendformdocument`;

export const GET_POST_FORM_DATA = ({ formProcessPublicId, formDocumentPublicId }) =>
  `/fillDocument/forms/${formProcessPublicId}/${formDocumentPublicId}/getpostformdata`;

export const SEND_GENERIC_MESSAGE = '/formProcess/formdetails/sendmessage';

export const GET_ALL_FORM_PROCESS = '/formProcess/workshop/getallformprocess';
export const GET_ALL_UNLINK_FORM_PROCESS = '/formProcess/workshop/getallunlinkformprocess';

export const GET_ALL_TRANSACTION_FORM_PROCESS = (transactionId: string) =>
  `formProcess/workshop/transaction/${transactionId}/getalltransactionformprocess`;

export const GET_ARCHIVE_FORMS = '/formProcess/workshop/getarchiveforms';

export const CANCEL_FORM_DOCUMENT = ({
  formProcessPublicId,
  formDocumentPublicId,
}: Omit<FormProcessDataType, 'url'>) =>
  `/fillDocument/forms/${formProcessPublicId}/${formDocumentPublicId}/cancelform`;

export const VOID_FORM_DOCUMENT = (formProcessId: number) => `/formProcess/${formProcessId}/void`;

export const GET_DRAFT_FORMS = '/formProcess/formdetails/getdraftforms';

export const DELETE_DRAFT_FORM = '/formProcess/formdetails/deletedraftform';

export const GET_FORM_DETAILS = (formProcessId: number) =>
  `/formProcess/formdetails/getformdetails/${formProcessId}`;

export const GET_TEAM_AGENT_FORM_DETAILS = (agentId: number, formProcessId: number) =>
  `/formProcess/formdetails/getteamagentformdetails/${agentId}/${formProcessId}`;

export const RESEND_EMAIL = '/formProcess/workshop/formdetails/resendemail';

export const REPLACE_EDITOR = '/formProcess/workshop/formdetails/replaceeditor';

export const REPLACE_SIGNATORY = '/formProcess/workshop/formdetails/replacesignatory';

export const REMOVE_EDITOR = '/formProcess/workshop/formdetails/removeeditor';

export const DEFER_QUESTION_AUTH = ({
  formProcessPublicId,
  formDocumentPublicId,
  deferQuestionId,
}: {
  formProcessPublicId?: string;
  formDocumentPublicId?: string;
  deferQuestionId?: number;
}) =>
  `/fillDocument/forms${
    formProcessPublicId && formDocumentPublicId
      ? `/${formProcessPublicId}/${formDocumentPublicId}`
      : ''
  }/deferquestion${deferQuestionId ? `/${deferQuestionId}` : ''}`;

export const UNDO_DEFER_QUESTION_AUTH = ({
  formProcessPublicId,
  formDocumentPublicId,
  deferQuestionId,
}: {
  formProcessPublicId?: string;
  formDocumentPublicId?: string;
  deferQuestionId?: number;
}) =>
  `/fillDocument/forms${
    formProcessPublicId && formDocumentPublicId
      ? `/${formProcessPublicId}/${formDocumentPublicId}`
      : ''
  }/undodeferquestion${deferQuestionId ? `/${deferQuestionId}` : ''}`;

export const FORM_PROCESS_SIGNATURE = '/fillDocument/forms/signature';

export const FORM_PROCESS_SIGNATURE_ANON = (token?: string) =>
  `/signDocument/forms/signature/externalUser${token ? `/${token}` : ''}`;

export const UPDATE_SIGNATURE_SEQUENCE = ({ formProcessPublicId, formDocumentPublicId }) =>
  `/fillDocument/forms/${formProcessPublicId}/${formDocumentPublicId}/updatesignaturesequence`;

export const DEFER_QUESTION_ANON = ({ type }) => {
  if (type === 'editor') {
    return `/fillDocument/forms/deferquestionexternaleditor`;
  } else {
    throw new Error('Invalid type');
  }
};

export const UNDO_DEFER_QUESTION_ANON = ({ type }) => {
  if (type === 'editor') {
    return `/fillDocument/forms/undodeferquestionexternaleditor`;
  } else {
    throw new Error('Invalid type');
  }
};

export const GET_PUBLISHED_FORM = ({ formId }) => `/fillDocument/getpublishedform/${formId}`;

export const FORM_COMMENT_USERS_AUTH = (
  formProcessPublicId: string,
  formDocumentPublicId: string,
) => `/formComment/commentUsers/${formProcessPublicId}/${formDocumentPublicId}`;

export const FORM_COMMENT_AUTH = (formProcessPublicId: string, formDocumentPublicId: string) =>
  `/formComment/${formProcessPublicId}/${formDocumentPublicId}`;

export const FORM_COMMENT_THREAD_RESOLVE_AUTH = (
  formProcessPublicId: string,
  formDocumentPublicId: string,
) => `/formComment/resolve/${formProcessPublicId}/${formDocumentPublicId}`;

export const FORM_COMMENT_USERS_ANON = (type: AllowedAnonEnum, token: string) => {
  if (type === AllowedAnonEnum.EDITOR) {
    return `/fillDocument/forms/getformcommentusersexternaleditor/${token}`;
  } else if (type === AllowedAnonEnum.SIGNATORY) {
    return `/signDocument/forms/getformcommentusersexternalsignatory/${token}`;
  } else {
    throw new Error('Invalid type');
  }
};

export const FORM_COMMENT_ANON = (type: AllowedAnonEnum, token: string) => {
  if (type === AllowedAnonEnum.EDITOR) {
    return `/fillDocument/forms/formcommentexternaleditor/${token}`;
  } else if (type === AllowedAnonEnum.SIGNATORY) {
    return `/signDocument/forms/formcommentexternalsignatory/${token}`;
  } else {
    throw new Error('Invalid type');
  }
};

export const FORM_COMMENT_THREAD_RESOLVE_ANON = (type: AllowedAnonEnum, token: string) => {
  if (type === AllowedAnonEnum.EDITOR) {
    return `/fillDocument/forms/toggleresolvethreadexternaleditor/${token}`;
  } else if (type === AllowedAnonEnum.SIGNATORY) {
    return `/signDocument/forms/toggleresolvethreadexternalsignatory/${token}`;
  } else {
    throw new Error('Invalid type');
  }
};

export const CREATE_BUNDLE_FORM = '/fillDocument/createformbundle';

export const CREATE_BUNDLE_FORM_TEMPLATE = '/fillDocument/createNewFormBundleTemplate';

export const PREVIEW_DRAFT_FORM = (formId, versionId) =>
  `/formProcess/workshop/formdetails/preview/${formId}/${versionId}`;

export const PREVIEW_BUNDLE_FORM = (bundleId) => `/formProcess/${bundleId}/preview`;

export const UPDATE_STRIKE_THROUGH_AUTH = ({ formProcessPublicId, formDocumentPublicId }) =>
  `/fillDocument/forms/updateStrikeThrough/${formProcessPublicId}/${formDocumentPublicId}`;

export const UPDATE_STRIKE_THROUGH_TEMPLATE = ({ templateId }) =>
  `/fillDocument/forms/updateFormTemplateStrikeThrough/${templateId}`;

export const UPDATE_STRIKE_THROUGH_ANON = (type: AllowedAnonEnum, token: string) => {
  if (type === AllowedAnonEnum.EDITOR) {
    return `/fillDocument/forms/updateStrikeThroughExternalEditor/${token}`;
  } else if (type === AllowedAnonEnum.SIGNATORY) {
    return `/signDocument/forms/updateStrikeThroughExternalEditor/${token}`;
  } else {
    throw new Error('Invalid type');
  }
};

export const DYNAMIC_FORM_DOCUMENT_AUTH = ({
  formProcessPublicId,
  formDocumentPublicId,
}: FormProcessDataType) =>
  `/fillDocument/dynamicForm/formDocument/${formProcessPublicId}/${formDocumentPublicId}`;

export const DYNAMIC_FORM_DOCUMENT_QUESTION = ({
  formProcessPublicId,
  formDocumentPublicId,
}: FormProcessDataType) =>
  `/fillDocument/dynamicForm/updateQuestion/${formProcessPublicId}/${formDocumentPublicId}`;

export const PROGRESS_DYNAMIC_FORM_DOCUMENT_AUTH = ({
  formProcessPublicId,
  formDocumentPublicId,
}) => `fillDocument/dynamicForm/${formProcessPublicId}/progressformdocument`;

export const REGRESS_DYNAMIC_FORM_DOCUMENT_AUTH = ({ formProcessPublicId, formDocumentPublicId }) =>
  `fillDocument/dynamicForm/${formProcessPublicId}/${formDocumentPublicId}/regressFormDocument`;

export const GET_DYNAMIC_POST_FORM_DATA = ({ formProcessPublicId, formDocumentPublicId }) =>
  `/fillDocument/dynamicForm/${formProcessPublicId}/${formDocumentPublicId}/getPostFormData`;

export const GET_SIGNATORIES = ({ formProcessPublicId, formDocumentPublicId }) =>
  `fillDocument/dynamicForm/${formProcessPublicId}/getSignatories`;

export const UPDATE_SIGNATORIES = ({ formProcessPublicId }) =>
  `fillDocument/dynamicForm/editSignatories/${formProcessPublicId}`;

export const UPDATE_TEMPLATE_SIGNATORIES = (templateId) =>
  `fillDocument/formTemplate/editFormRoles/${templateId}`;

export const TEMPLATE_FORM_QUESTION = ({ templateId }) =>
  `fillDocument/formTemplate/updateQuestion/${templateId}`;

export const PROGRESS_SAVE_AS_TEMPLATE_FORM = ({ formProcessPublicId, formDocumentPublicId }) =>
  `fillDocument/dynamicForm/saveAsTemplate/${formProcessPublicId}/${formDocumentPublicId}`;

export const SAVE_BUNDLE_TEMPLATE = ({ formProcessPublicId }) =>
  `fillDocument/saveFormBundleAsTemplate/${formProcessPublicId}`;

export const DELETE_FORM_DOCUMENT = (documentId) =>
  `/fillDocument/dynamicForm/deleteDocumentFromFormBundle/${documentId}/remove`;

export const DELETE_TEMPLATE_FROM_BUNDLE = (templateId) =>
  `/fillDocument/dynamicForm/deleteTemplateFromTemplateBundle/${templateId}/remove`;

export const DELETE_TEMPLATE_FORM = '/fillDocument/formTemplates/delete';

export const LINK_TO_FORM_PROCESS = `/fillDocument/dynamicForm/link`;
export const UNLINK_FORM_PROCESS = `/fillDocument/dynamicForm/unlink`;

export const PRVEVIEW_COMBINED_FORMS = (formProcessId) =>
  `/formProcess/${formProcessId}/preview/combined`;

export const PRVEVIEW_EXTERNAL_COMBINED_FORMS = (token) =>
  `/signDocument/forms/externalcombinedpreview/${token}`;
