import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash-es';

import { DateRangeFilter } from 'pages/ClarityRevamped/Components/Filters/DateRangeFilter';
import { TableViewFilter } from 'pages/ClarityRevamped/Components/Filters/TableViewFilter';
import { Wrapper as PendingWrapper } from 'components';
import { useEffect, useState } from 'react';
import { ModalHeader } from '../../Components/ModalHeader/ModalHeader';
import { AggregateResultHeader } from '../../Components/ModalHeader';
import { getAgentTeamStatsSelector, getTimePeriodSelector } from 'store/selectors/clarity';
import { ClarityMultiLineChart } from 'components/ClarityMultiLineChart';
import { LineChart, BarChart } from 'components/Icons';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { useFetchLeadSourceStats } from 'pages/ClarityRevamped/hooks/useFetchLeadSourceStats';
import { ClarityPieChart } from 'components/ClarityPieChart';
import { LeadSourcesBreakdownMenu } from 'pages/ClarityRevamped/Components/BreakdownMenu/LeadSources';
import { LeadSourcesTableView } from '../../Components/TableView/LeadSourcesTableView/LeadSourcesTableView';
import { hideBreakdownMenu } from '../helper';
import { ClarityMultiBarChart } from 'components/ClarityMultiBarChart';

import styles from '../../styles.module.scss';

export const LeadSources = () => {
  const [isGraphView, setIsGraphView] = useState<boolean>(true);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openCompareModal, setOpenCompareModal] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [toggleGraph, setToggleGraph] = useState(false);
  const [breakdownMenuIsPending, setBreakdownMenuIsPending] = useState(false);
  const [summary, setSummary] = useState<any>({});
  const [data, setData] = useState([]);
  const [breakdownTotal, setBreakdownTotal] = useState<any>();
  const [breakdownSummary, setBreakdownSummary] = useState<any>([]);
  const [breakdownList, setBreakdownList] = useState<any>([]);
  const [selectedAgent, setSelectedAgent] = useState<any>(null);
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [teamSummary, setTeamSummary] = useState<any>([]);
  const [selectedColors, setSelectedColors] = useState<any>([]);
  const [breakdownOption, setBreakdownOption] = useState('Sources');
  const timePeriod = useSelector(getTimePeriodSelector);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const color = '#51BFE1';
  const getStats = useFetchLeadSourceStats();

  useEffect(() => {
    getStats({
      setSummary,
      setData,
      setIsPending,
      aggregateDataFormat: isGraphView ? 'Series' : 'Tabular',
      transactionAgentUserId: selectedAgent?.TransactionAgentUserId,
      transactionAgentContactId: selectedAgent?.TransactionAgentContactId,
      sourceSummary: isGraphView && !selectedSources.length,
      sourceList: selectedSources,
    });
  }, [timePeriod, isGraphView, selectedAgent, selectedSources]);

  useEffect(() => {
    if ('TotalVolume' in summary && teamStats.itemValue === 'Team' && !selectedAgent)
      setTeamSummary(summary);
  }, [summary, timePeriod]);

  useEffect(() => {
    if (firstRender && breakdownList?.length) {
      setOpenMenu(true);
      setFirstRender(false);
    } else if (!breakdownList?.length) setOpenMenu(false);
  }, [breakdownList]);

  useEffect(() => {
    if (selectedAgent) {
      getStats({
        setSummary: setTeamSummary,
        setData: () => {},
        setIsPending,
        aggregateDataFormat: isGraphView ? 'Series' : 'Tabular',
      });
    }
    if (hideBreakdownMenu(agentRole, teamStats)) {
      getStats({
        setSummary: setBreakdownTotal,
        setData: setBreakdownSummary,
        setIsPending: setBreakdownMenuIsPending,
        aggregateDataFormat: 'Series',
        ...(breakdownOption === 'Agents' && { agentSummary: true }),
        ...(breakdownOption === 'Sources' && { sourceSummary: true }),
      });
    }
  }, [timePeriod, breakdownOption]);

  useEffect(() => {
    if (breakdownSummary.length && 'TotalVolume' in teamSummary) {
      if (breakdownOption === 'Sources') {
        if (selectedSources.length) {
          const tempList = cloneDeep(breakdownSummary);
          for (const source of selectedSources) {
            const index = breakdownSummary.findIndex((row) => row.LeadSource === source);
            if (index !== -1) tempList[index] = { ...tempList[index], selected: true };
          }
          setBreakdownList([
            {
              LeadSource: 'All Sources',
              TotalVolume: breakdownTotal?.TotalVolume || 0,
              NumberTransactions: breakdownTotal?.NumberTransactions || 0,
              color: '#928CDA',
            },
            ...tempList,
          ]);
        } else {
          setBreakdownList([
            {
              LeadSource: 'All Sources',
              TotalVolume: breakdownTotal?.TotalVolume || 0,
              NumberTransactions: breakdownTotal?.NumberTransactions || 0,
              selected: true,
              color: '#928CDA',
            },
            ...breakdownSummary,
          ]);
        }
      } else {
        let selectedAgentIndex = 0;
        if (selectedAgent) {
          const index = breakdownSummary.findIndex(
            (row) => row.TransactionAgent === selectedAgent.TransactionAgent,
          );
          if (index === -1) {
            setSelectedAgent(null);
            selectedAgentIndex = 0;
          } else {
            selectedAgentIndex = index + 1;
          }
          selectedAgentIndex = index === -1 ? 0 : index + 1;
        }

        const newList = cloneDeep([
          {
            TransactionAgent: 'Team',
            Total: teamSummary?.TotalVolume || 0,
            NumberLeadSources: teamSummary?.NumberLeadSources || 0,
            Percentage: 100,
          },
          ...breakdownSummary,
        ]);

        const selected = newList[selectedAgentIndex];
        newList[selectedAgentIndex] = { ...selected, selected: true };
        setBreakdownList(newList);
      }
    }
  }, [breakdownSummary, teamSummary]);

  const getGraph = () => {
    return toggleGraph || timePeriod === 'MTD' || timePeriod === 'WEEK' ? (
      <ClarityMultiBarChart
        attributes={selectedSources}
        seriesData={data}
        colors={selectedColors}
        selectedAgent={selectedAgent}
        timePeriod={timePeriod}
      />
    ) : (
      <ClarityMultiLineChart
        attributes={selectedSources}
        seriesData={data}
        colors={selectedColors}
        selectedAgent={selectedAgent}
        timePeriod={timePeriod}
      />
    );
  };

  const resetSourceSelection = (list) => {
    setBreakdownList([
      {
        LeadSource: 'All Sources',
        TotalVolume: breakdownTotal?.TotalVolume || 0,
        NumberLeadSources: breakdownTotal?.NumberLeadSources || 0,
        selected: true,
        color: '#928CDA',
      },
      ...breakdownSummary,
    ]);
  };
  const handleSourceClick = (source, index) => {
    let newList = cloneDeep(breakdownList);
    let currentColorSelection = selectedColors;
    if (source.LeadSource === 'All Sources') {
      setSelectedSources([]);
      resetSourceSelection(newList);
      return;
    }
    let selectedSourceList = cloneDeep(selectedSources);

    if (newList[0]?.selected) delete newList[0].selected;

    if (source?.selected === true) {
      const filteredSelectedSources = selectedSourceList.filter((val) => val !== source.LeadSource);
      setSelectedSources(filteredSelectedSources);
      delete newList[index].selected;
      if (!filteredSelectedSources.length) {
        resetSourceSelection(newList);
        return;
      }
    } else {
      setSelectedSources([...selectedSourceList, source.LeadSource]);
      setSelectedColors([...currentColorSelection, source.color]);
      newList[index] = { ...source, selected: true };
    }
    setBreakdownList(newList);
  };

  const handleAgentClick = (agent, index) => {
    let newList;
    if (agent?.TransactionAgent === 'Team') {
      setSelectedAgent(null);
      newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.TotalVolume || 0,
          NumberLeadSources: teamSummary?.NumberLeadSources || 0,
          Percentage: 100,
          selected: true,
        },
        ...breakdownSummary,
      ]);
    } else {
      setSelectedAgent(agent);
      newList = cloneDeep([
        {
          TransactionAgent: 'Team',
          Total: teamSummary?.TotalVolume || 0,
          NumberLeadSources: teamSummary?.NumberLeadSources || 0,
          Percentage: 100,
        },
        ...breakdownSummary,
      ]);
      newList[index] = { ...agent, selected: true };
    }
    setBreakdownList(newList);
  };

  return (
    <PendingWrapper isPending={false} className={styles.clarityContentWrapper}>
      <div className={styles.headerRows}>
        <ModalHeader title={'Lead Sources'} />
        <div className={styles.infoRow}>
          <AggregateResultHeader
            totalAmount={summary?.NumberLeadSources || 0}
            totalRequests={0}
            textColor={{ color: color }}
          />
          <div className={styles.filtersContainer}>
            <DateRangeFilter
              defaultOption={timePeriod}
              wrapperClassname={styles.dateRangeFilterWrapper}
            />
            {hideBreakdownMenu(agentRole, teamStats) ? (
              <div
                className={classnames(styles.breakdownBtn, { [styles.openMenu]: openMenu })}
                onClick={() => setOpenMenu(!openMenu)}
              >
                Breakdown
              </div>
            ) : (
              <></>
            )}
            {/* <StatusFilter options={['All', ...dropdownList]} cb={() => {}} /> */}
            <TableViewFilter
              setIsGraphView={setIsGraphView}
              openCompareModal={() => setOpenCompareModal(true)}
              setIsPending={setIsPending}
            />
          </div>
        </div>
      </div>
      <div className={styles.bodyRow}>
        <PendingWrapper
          isPending={isPending}
          className={classnames(
            styles.graphContainer,
            { [styles.openMenu]: isGraphView && openMenu },
            { [styles.tableContainer]: !isGraphView && openMenu },
          )}
        >
          {isGraphView && (breakdownOption === 'Agents' || !selectedSources.length) && (
            <ClarityPieChart data={data} selectedAgent={selectedAgent} />
          )}
          {breakdownOption === 'Sources' && selectedSources.length && isGraphView ? (
            <div className={styles.graphContent}>
              {(timePeriod === 'YTD' || timePeriod === 'QTD') && (
                <div className={styles.graphToggleContainer}>
                  <div
                    className={styles.grpahToggleBtn}
                    onClick={() => setToggleGraph(!toggleGraph)}
                  >
                    {toggleGraph ? <LineChart /> : <BarChart />}
                  </div>
                </div>
              )}
              {getGraph()}
            </div>
          ) : (
            <></>
          )}
          {!isGraphView && (
            <LeadSourcesTableView
              open={openMenu}
              data={data}
              selectedAgent={selectedAgent}
              sourceList={selectedSources}
            />
          )}
        </PendingWrapper>
        {hideBreakdownMenu(agentRole, teamStats) ? (
          <div className={classnames(styles.breakdownMenu, { [styles.openMenu]: openMenu })}>
            <LeadSourcesBreakdownMenu
              open={openMenu}
              list={breakdownList}
              handleSourceClick={handleSourceClick}
              handleAgentClick={handleAgentClick}
              color={color}
              onClose={() => setOpenMenu(false)}
              selectedAgent={selectedAgent}
              setSelectedAgent={setSelectedAgent}
              breakdownTotal={breakdownTotal}
              dropDownSelectedOption={breakdownOption}
              setOption={setBreakdownOption}
              isPending={breakdownMenuIsPending}
              resetSourceList={() => setSelectedSources([])}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </PendingWrapper>
  );
};
