import { useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { getOpenedChatThreadInfo } from 'store/selectors/sockets/threads';
import { updateThreadEffect } from 'store/effects/sockets/threads';
import { getMessagesDrawerParams } from 'store/selectors/drawers/messages';
import { changeMessagesDrawerTypeAction } from 'store/actions/drawers/messages';
import { getUserRoleSelector, getUserId } from 'store/selectors/user';

import { DRAWER_MESSAGES_TYPES } from 'settings/constants/drawers';
import { AGENT, THIRD_PARTY } from 'settings/constants/roles';

import { Avatars } from 'components';
import DrawerHeaderControlls from 'components/Drawers/MessagesDrawer/components/DrawerHeaderControlls';

import {
  getViewContactsOption,
  getViewAttachmentsOption,
  getViewProfileOption,
  getArchiveThreadOption,
  getAddParticipantThreadOption,
  getQuoteActivityDrawerOption,
} from 'components/Drawers/MessagesDrawer/helpers/threadActions';

import styles from './styles.module.scss';

const MessagesDrawerChatHeader = () => {
  const ENTER_KEY_CODE = 13;
  const MAX_SUBJECT_CHARACTER_LENGTH = 50;

  const dispatch = useDispatch();
  const userRole = useSelector(getUserRoleSelector);
  const userId = useSelector(getUserId);
  const { clientId, listingId, partnerId, transactionTopicName } =
    useSelector(getMessagesDrawerParams);
  const {
    names,
    subTitle,
    type,
    isOffer,
    avatars,
    thread,
    recipientRole,
    recipientLastOnline,
    isGroupChat,
    BusinessName,
  } = useSelector(getOpenedChatThreadInfo);

  const subjectElement = useRef(null);

  const getActions = useCallback(
    () => [
      ...[
        ...(thread.Participants.length > 2
          ? getViewContactsOption(dispatch, thread)
          : getViewProfileOption(dispatch, thread, userId)),
      ],
      ...getQuoteActivityDrawerOption(dispatch, thread, userId),
      ...getViewAttachmentsOption(dispatch, { threadId: thread?.Id }),
      ...getAddParticipantThreadOption(dispatch, {
        info: subTitle,
        type,
      }),
      ...getArchiveThreadOption(dispatch, thread),
    ],
    [dispatch, thread, subTitle, type],
  );

  const shouldShowSubject = () => {
    return thread.Participants.length > 2;
  };

  // Note: we can use this function to control which user see the online status in chat.
  const shouldShowLastOnlineStatus = () => {
    return !isGroupChat && userRole === AGENT;
  };

  const getOnlineStatus = () => {
    let status = <></>;
    if (recipientLastOnline === 'Online') {
      status = (
        <div className={styles.chatHeaderOnlineStatus}>
          <div></div>
        </div>
      );
    }

    return status;
  };

  const isRecipientThirdParty = () => {
    return recipientRole === THIRD_PARTY;
  };

  const getEmptySubjectLine = () => '+ Add Subject';

  const disableNewlines = (event) => {
    const keyCode = event.keyCode || event.which;

    if (
      keyCode === ENTER_KEY_CODE ||
      event.currentTarget?.textContent?.length > MAX_SUBJECT_CHARACTER_LENGTH
    ) {
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const validateAllowedSubjectLength = (event) => {
    const clipboardText = event.clipboardData.getData('text/plain');
    if (
      (event.currentTarget?.textContent?.length || 0) + (clipboardText?.length || 0) <
      MAX_SUBJECT_CHARACTER_LENGTH
    ) {
      subjectElement.current.textContent += clipboardText;
    }
    event?.preventDefault();
  };

  const updateThreadSubject = ({ currentTarget }) => {
    const { textContent } = currentTarget;
    if (textContent && textContent !== getEmptySubjectLine() && textContent !== subTitle) {
      dispatch(updateThreadEffect({ threadId: thread.Id, topicName: textContent }));
    }
  };

  const getAllMessagesDrawerType = useCallback(() => {
    if (clientId) {
      // If clientId is set and current user is a partner, then we on Quotes tab
      if (userRole === THIRD_PARTY) {
        return DRAWER_MESSAGES_TYPES.PARTNER;
      }
      return DRAWER_MESSAGES_TYPES.CLIENT;
    }
    if (listingId) {
      return DRAWER_MESSAGES_TYPES.LISTING;
    }
    if (partnerId) {
      return DRAWER_MESSAGES_TYPES.SERVICE;
    }

    if (transactionTopicName) {
      return DRAWER_MESSAGES_TYPES.TRANSACTION;
    }

    return thread?.IsArchived ? DRAWER_MESSAGES_TYPES.ARCHIVED : DRAWER_MESSAGES_TYPES.INIT;
  }, [userRole, listingId, partnerId, clientId, thread?.IsArchived, transactionTopicName]);

  const toAllMessagesHandler = useCallback(
    () =>
      dispatch(
        changeMessagesDrawerTypeAction({
          type: getAllMessagesDrawerType(),
          params: {
            clientId,
            listingId,
            partnerId,
            archived: thread?.IsArchived,
            transactionTopicName,
          },
        }),
      ),
    [
      dispatch,
      listingId,
      partnerId,
      clientId,
      thread?.IsArchived,
      getAllMessagesDrawerType,
      transactionTopicName,
    ],
  );

  if (!thread) return null;

  const handleClickSubtitle = () => {
    if (!subTitle) {
      subjectElement.current.textContent = '';
    }
  };

  return (
    <div testid="chat_header" className={styles.wrapper}>
      <DrawerHeaderControlls
        actions={getActions()}
        archived={thread.IsArchived}
        onBack={toAllMessagesHandler}
      />
      <div className={styles.chatHeaderInfo}>
        <div className={styles.chatHeaderAvatars}>
          <Avatars
            className={styles.avatars}
            avatarWrapperClassName={styles.avatarWrapper}
            avatarClassName={styles.avatar}
            isNotSingleClassName={styles.isNotSingle}
            overflowClassName={styles.overflow}
            avatars={avatars}
          />

          {getOnlineStatus()}
        </div>
        <div className={styles.chatHeaderTitles}>
          <div testid="header_title" className={styles.chatHeaderTitle}>
            <p>{isRecipientThirdParty() ? BusinessName : names}</p>
            {!isGroupChat && (
              <p className={styles.chatHeaderUserRole}>
                {isRecipientThirdParty() ? subTitle : recipientRole}
              </p>
            )}
          </div>
          <div className={styles.chatHeaderSubTitle}>
            {shouldShowSubject() &&
              (subTitle ? (
                <span
                  testid="header_subtitle"
                  className={styles.chatHeaderTitleSpan}
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                  onBlur={updateThreadSubject}
                  onKeyPress={disableNewlines}
                  onPaste={validateAllowedSubjectLength}
                  onClick={handleClickSubtitle}
                  ref={subjectElement}
                >
                  {subTitle}
                </span>
              ) : (
                <span
                  className={styles.chatHeaderEmptyTitleSpan}
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                  onBlur={updateThreadSubject}
                  onKeyPress={disableNewlines}
                  onPaste={validateAllowedSubjectLength}
                  onClick={handleClickSubtitle}
                  ref={subjectElement}
                >
                  {getEmptySubjectLine()}
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesDrawerChatHeader;
