import { renderLastActivity, renderStatus } from 'pages/Workshop/Forms/components/FormsTable';
import { DateTimeColumn, DocumentNameColumn } from './TableColumns';
import { Options } from '../Options';
import { AgentDocumentTypes } from 'types';

const docType = AgentDocumentTypes.PendingForms;

export const pendingFormsColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 275,
    render: (title) => <DocumentNameColumn name={title} docIconType="Ungrouped" />,
    sorter: (a, b) => a.title?.localeCompare(b.title),
  },
  {
    key: 'status',
    title: 'Status',
    width: 200,
    render: (row) => (row?.pendingMeta?.status ? renderStatus(row?.pendingMeta?.status) : ''),
  },
  {
    key: 'address',
    title: 'Address',
    width: 350,
    render: (row) => <span style={{ fontSize: 16 }}>{row?.pendingMeta?.address || '-'}</span>,
    sorter: (a, b) => {
      if (!a?.pendingMeta?.address) return 1;
      if (!b?.pendingMeta?.address) return -1;

      return a?.pendingMeta?.address?.localeCompare(b?.draftMeta?.address);
    },
  },
  {
    key: 'lastActivity',
    title: 'Last Activity',
    render: (row) => renderLastActivity(row?.pendingMeta),
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docType} optionUtils={optionUtils} />,
  },
];
