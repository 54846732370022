import React from 'react';

import classNames from 'classnames';
import { useScreen } from 'hooks';
import { map } from 'lodash-es';
import styles from './styles.module.scss';
import { PageHeaderProps, PageTabProps } from './types';

export const PageHeader: React.FC<PageHeaderProps> = ({ showTabs = true, title, ...props }) => {
  return (
    <header className={styles.dashboardHeader}>
      <div className={styles.headerInner}>
        <div className={styles.headerNameWrapper}>
          {title && <span>{title}</span>}
          {props?.children || null}
        </div>
      </div>
      {showTabs && <Tabs {...props} />}
    </header>
  );
};

const Tabs: React.FC<PageTabProps> = ({ activeKey, links, setActiveTab, children, className }) => {
  const { screen } = useScreen();

  return (
    <div className={classNames(styles.links, styles.linksHorizontal)}>
      {map(links, ({ value, title, icon: Icon }) => (
        <div
          key={value}
          className={classNames(styles.link, {
            [styles.active]: activeKey === value,
          })}
        >
          <div
            className={className}
            title={title}
            onClick={() => setActiveTab && setActiveTab(value)}
          >
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              {!screen.mobileWidth && Icon && (
                <Icon className={styles.icon} color={activeKey === value ? '#FF576D' : undefined} />
              )}
              {title}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
