import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { getPropertyReferenceNeighborhoodActivitiesAction } from 'store/actions/radarPortfolio';
import { IPropertyReferenceNeighborhoodActivitiesState } from './types';
const initialState: IPropertyReferenceNeighborhoodActivitiesState = {
  state: IDLE,
  data: null,
  meta: {},
};
export default handleActions(
  {
    [getPropertyReferenceNeighborhoodActivitiesAction as any]: (state, { payload }) => ({
      state: get(payload, 'state'),
      data: get(payload, 'data', null),
      meta: get(payload, 'meta'),
    }),
  },
  initialState,
);
