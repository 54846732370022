import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateRangePicker } from 'components/Form/DateRangePicker';
import useIsProjectRoute from 'hooks/use-is-project-route';
import moment, { Moment } from 'moment';
import { FilterDrawer, FilterSection } from 'pages/Workshop/common';
import { GroupCheckBoxesSection } from 'pages/Workshop/common/GroupCheckBoxesSection';
import { MultiSelectSection } from 'pages/Workshop/common/MultiSelectSection';
import { LocationService } from 'services';
import {
  requestGetTransactionsEffect,
  setTransactionFiltersAppliedEffect,
  setTransactionsFiltersEffect,
} from 'store/effects';
import {
  getAllTransactionsSelector,
  getTransactionsFiltersAppliedSelector,
  getTransactionsIsArchiveSelector,
  getTransactionsIsFilterSelector,
  getTransactionsPersons,
  getTransactionsSelector,
} from 'store/selectors/transactions';
import { addIsProjectFilterForProjectRoutes } from 'utils/addIsProjectFilterForProjectRoutes';
import { getUserRolesMapSelector } from 'store/selectors/user';
import {
  projectStatusesOptions,
  transactionStatuses,
  transactionStatusesOptions,
} from 'settings/constants/transaction';
import { getProjectCategoriesDataArray } from 'store/selectors/projects';

import styles from './styles.module.scss';

let defaultFilters = {};

export const FilterSections = () => {
  const dispatch = useDispatch();
  const locationSrv = new LocationService();
  locationSrv.setLocation(location);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const projectCategories = useSelector(getProjectCategoriesDataArray);
  const transactionsPersons = useSelector(getTransactionsPersons);
  const isArchive = useSelector(getTransactionsIsArchiveSelector);
  const filtersApplied = useSelector(getTransactionsFiltersAppliedSelector);

  const clientsData = transactionsPersons.clientsArray.map((item) => ({
    value: item,
    label: item,
  }));
  const agentsOrOwnersData = transactionsPersons.agentsOrOwnersArray.map((item) => ({
    value: item,
    label: item,
  }));
  const coordinatorsData = transactionsPersons.coordinatorsArray.map((item) => ({
    value: item,
    label: item,
  }));

  const query = locationSrv.getQuery();
  const isProject = useIsProjectRoute();
  const { isIdle } = useSelector(getTransactionsSelector);
  const transactionsData = useSelector(getAllTransactionsSelector);
  const statusesData = useMemo(() => {
    const statuses = isProject ? [...projectStatusesOptions] : [...transactionStatusesOptions];
    if (!isArchive) {
      return statuses.filter(
        (status) =>
          !(
            status.value === transactionStatuses.Closed ||
            status.value === transactionStatuses.Canceled
          ),
      );
    }
    return statuses;
  }, [isProject, isArchive]);
  const [isFiltersAppliedLocal, setIsFiltersAppliedLocal] = useState<boolean>(false);
  const isFiltersApplied = useSelector(getTransactionsIsFilterSelector);
  const [dueFrom, setDueFrom] = useState<Moment | undefined>(
    query.dueToday ? moment() : query.dueWeek ? moment().startOf('week') : undefined,
  );
  const [dueTo, setDueTo] = useState<Moment | undefined>(
    query.dueToday ? moment() : query.dueWeek ? moment().endOf('week') : undefined,
  );
  const [effectiveDateFrom, setEffectiveDateFrom] = useState<Moment>();
  const [effectiveDateTo, setEffectiveDateTo] = useState<Moment>();
  const [resetDates, setResetDates] = useState(false);
  const [categoryIds, setCategoryIds] = useState<string[]>([]);
  const [cachedCategoryIds, setCachedCategoryIds] = useState<any[]>([]);
  const [cachedTransactions, setCachedTransactions] = useState<any[]>([]);
  const [transactions, setTransactions] = useState<string[]>([]);
  const [clients, setClients] = useState<string[]>([]);
  const [agentsOrOwners, setAgentsOrOwners] = useState<string[]>([]);
  const [coordinators, setCoordinators] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>(
    query.statuses ? query.statuses.split(',') : [],
  );
  const [previousFilters, setPreviousFilters] = useState({});
  const [firstRender, setFirstRender] = useState(0);

  const getFilters = () => ({
    dueFrom,
    dueTo,
    transactions,
    clients,
    statuses,
    categoryIds,
    agentsOrOwners,
    coordinators,
  });

  useEffect(() => {
    if (firstRender >= 2) {
      setIsFiltersAppliedLocal(false);
      setPreviousFilters({
        dueFrom: undefined,
        dueTo: undefined,
        transactions: [],
        clients: [],
        statuses: [],
        categoryIds: [],
        agentsOrOwners: [],
        coordinators: [],
      });
    }
    setFirstRender(firstRender + 1);
  }, [isArchive]);

  useEffect(() => {
    defaultFilters = getFilters();
    setPreviousFilters(defaultFilters);
  }, []);

  useEffect(() => {
    if (!isFiltersAppliedLocal || 'transactions' in query) {
      setCachedTransactions(transactionsData);
      setCachedCategoryIds(projectCategories);
    }
  }, [isFiltersAppliedLocal, transactionsData]);

  useEffect(() => {
    if (!isFiltersApplied) {
      setIsFiltersAppliedLocal(false);
      setTransactions([]);
      setClients([]);
      setStatuses([]);
      setCategoryIds([]);
      setAgentsOrOwners([]);
      setCoordinators([]);
      setDueFrom(undefined);
      setDueTo(undefined);
      setEffectiveDateFrom(undefined);
      setEffectiveDateTo(undefined);
    }
  }, [isFiltersApplied]);

  useEffect(() => {
    // Apply last applied filters when navigating from transaction room
    if (Object.keys(filtersApplied)?.length > 0) {
      setTransactions(filtersApplied?.transactions || []);
      setAgentsOrOwners(filtersApplied?.agentsOrOwners || []);
      setStatuses(filtersApplied?.statuses || []);
      setClients(filtersApplied?.clients || []);
      setCoordinators(filtersApplied?.coordinators || []);
      setDueTo(filtersApplied?.dueTo || undefined);
      setDueFrom(filtersApplied?.dueFrom || undefined);
      setCategoryIds(filtersApplied?.categoryIds || undefined);
    }
  }, [filtersApplied]);

  useEffect(() => {
    setIsFiltersAppliedLocal(
      Boolean(
        effectiveDateFrom ||
          effectiveDateTo ||
          dueFrom ||
          dueTo ||
          statuses.length ||
          transactions.length ||
          clients.length ||
          categoryIds.length ||
          agentsOrOwners.length ||
          coordinators.length,
      ),
    );
  }, [dueFrom, dueTo, transactions, clients, statuses, categoryIds, agentsOrOwners, coordinators]);

  useEffect(() => {
    if (query?.transactions) {
      const dashboardTransactions = query.transactions
        ? isNaN(query.transactions)
          ? query.transactions.split(',').map((trxId) => +trxId)
          : [query.transactions]
        : [];
      setTransactions(dashboardTransactions);
      dispatch(
        requestGetTransactionsEffect(
          {
            filters: addIsProjectFilterForProjectRoutes(isProject, {
              transactions: dashboardTransactions,
              statuses,
              clients,
              dueTo,
              dueFrom,
              effectiveDateFrom,
              effectiveDateTo,
              categoryIds,
              agentsOrOwners,
              coordinators,
            }),
          },
          {},
          () => {
            dispatch(setTransactionFiltersAppliedEffect(true));
          },
        ),
      );
      setPreviousFilters({
        dueFrom,
        dueTo,
        transactions: dashboardTransactions,
        clients,
        statuses,
        categoryIds,
        agentsOrOwners,
        coordinators,
      });
    }
    if (!query?.isArchive) setFirstRender(2);
  }, []);

  const submitHandler = () => {
    dispatch(
      requestGetTransactionsEffect(
        {
          filters: addIsProjectFilterForProjectRoutes(isProject, {
            transactions,
            statuses,
            clients,
            dueTo,
            dueFrom,
            effectiveDateFrom,
            effectiveDateTo,
            categoryIds,
            agentsOrOwners,
            coordinators,
          }),
        },
        {},
        () => {
          dispatch(setTransactionFiltersAppliedEffect(true));
          dispatch(
            setTransactionsFiltersEffect({
              transactions,
              statuses,
              clients,
              dueTo,
              dueFrom,
              effectiveDateFrom,
              effectiveDateTo,
              categoryIds,
              agentsOrOwners,
              coordinators,
            }),
          );
        },
      ),
    );
    setPreviousFilters(getFilters());
  };

  const resetHandler = () => {
    setTransactions([]);
    setClients([]);
    setStatuses([]);
    setCategoryIds([]);
    setAgentsOrOwners([]);
    setCoordinators([]);
    setDueFrom(undefined);
    setDueTo(undefined);
    setEffectiveDateFrom(undefined);
    setEffectiveDateTo(undefined);
    dispatch(
      requestGetTransactionsEffect(
        { filters: addIsProjectFilterForProjectRoutes(isProject, {}) },
        { silent: !isIdle },
      ),
    );
    dispatch(setTransactionFiltersAppliedEffect(false));
    setPreviousFilters(defaultFilters);
  };

  const discardHandler = () => {
    if (JSON.stringify(previousFilters) === JSON.stringify(getFilters())) {
      return;
    }

    const { dueFrom, dueTo, transactions, clients, statuses, categoryIds } = previousFilters as any;

    if (transactions) setTransactions(transactions);
    if (statuses) setStatuses(statuses);
    if (categoryIds) setCategoryIds(categoryIds);
    if (clients) setClients(clients);
    if (dueTo) setDueTo(dueTo);
    if (dueFrom) setDueFrom(dueFrom);
  };

  return (
    <FilterDrawer
      className={styles.filterBtn}
      isFiltersApplied={isFiltersAppliedLocal}
      onDone={submitHandler}
      onReset={resetHandler}
      onDiscard={discardHandler}
    >
      <div className={styles.filterSections}>
        <FilterSection
          title={isProject ? 'Projects' : 'Transactions'}
          count={transactions?.length + statuses?.length}
        >
          <MultiSelectSection
            placeholder={`Search by ${isProject ? 'Projects' : 'Transactions'}...`}
            entityList={cachedTransactions?.map(({ address, id, data: { Name } }) => ({
              value: id,
              label: isProject ? Name : address,
            }))}
            entity={transactions}
            setEntity={setTransactions}
            locationPrefix
          />
          <GroupCheckBoxesSection
            title={'Statuses'}
            entityList={statusesData}
            entityValues={statuses}
            setEntity={setStatuses}
          />
        </FilterSection>
        {isAgent && !isProject ? (
          <>
            <FilterSection title="Client" count={clients.length}>
              <MultiSelectSection
                placeholder={'Search by Clients...'}
                entityList={clientsData}
                entity={clients}
                setEntity={setClients}
                avatarPrefix
              />
            </FilterSection>

            <FilterSection title="Agent" count={agentsOrOwners.length}>
              <MultiSelectSection
                placeholder={'Search by Agents...'}
                entityList={agentsOrOwnersData}
                entity={agentsOrOwners}
                setEntity={setAgentsOrOwners}
                avatarPrefix
              />
            </FilterSection>

            <FilterSection title="Coordinator" count={coordinators.length}>
              <MultiSelectSection
                placeholder={'Search by Coordinators...'}
                entityList={coordinatorsData}
                entity={coordinators}
                setEntity={setCoordinators}
                avatarPrefix
              />
            </FilterSection>
          </>
        ) : (
          <FilterSection title="Owner" count={agentsOrOwners.length}>
            <MultiSelectSection
              placeholder={'Search by Owners...'}
              entityList={agentsOrOwnersData}
              entity={agentsOrOwners}
              setEntity={setAgentsOrOwners}
              avatarPrefix
            />
          </FilterSection>
        )}
        {isProject && (
          <FilterSection title="Category" count={categoryIds?.length}>
            <MultiSelectSection
              placeholder={'Search by Categories...'}
              entityList={cachedCategoryIds.map(({ Id, Category }) => ({
                label: Category,
                value: Id,
              }))}
              entity={categoryIds}
              setEntity={setCategoryIds}
            />
          </FilterSection>
        )}

        <FilterSection title={isProject ? 'Deadline' : 'Closing/Expires'}>
          <div className={styles.datePickerContainer}>
            <DateRangePicker
              span={13}
              title={''}
              onDatesChange={(from, to) => {
                resetDates && setResetDates(false);
                setDueFrom(from);
                setDueTo(to);
              }}
              fromDateValue={dueFrom}
              toDateValue={dueTo}
              dateRangePickerWrapperClass={styles.dateRangePicker}
              resetDates={resetDates}
              futureDisabled={false}
            />
          </div>
        </FilterSection>
        {isProject && (
          <FilterSection title="Kickoff">
            <div className={styles.datePickerContainer}>
              <DateRangePicker
                span={13}
                title={''}
                onDatesChange={(from, to) => {
                  resetDates && setResetDates(false);
                  setEffectiveDateFrom(from);
                  setEffectiveDateTo(to);
                }}
                fromDateValue={effectiveDateFrom}
                toDateValue={effectiveDateTo}
                dateRangePickerWrapperClass={styles.dateRangePicker}
                resetDates={resetDates}
                futureDisabled={false}
              />
            </div>
          </FilterSection>
        )}
      </div>
    </FilterDrawer>
  );
};
