import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { priceConverter } from 'helpers';
import classNames from 'classnames';
import { useEffect } from 'react';

import styles from './styles.module.scss';

export const BarChart = ({
  seriesName,
  data,
  numberTransactions,
  categories,
  dates,
  colors,
  onClickDataPoint,
  className,
  open,
}) => {
  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: (event, chartContext, { dataPointIndex }) => {
          onClickDataPoint(dataPointIndex);
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: false,
      },
    },
    colors: colors,
    fill: {
      opacity: 0.7,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const total = data[dataPointIndex];
        const numberTransaction = numberTransactions[dataPointIndex];
        const category = categories[dataPointIndex];
        const date = dates[dataPointIndex];

        return (
          '<div style="width: 172px; height: 79px; border-radius: 16px; background: #FFF; padding: 15px 16px;">' +
          '<div style="display: flex; margin-bottom: 11px;">' +
          `<span style="color: #262626; font-feature-settings: 'liga' off, 'clig' off; font-family: Inter; font-size: 18px; font-style: normal; font-weight: 600; line-height: 20px; letter-spacing: -0.18px;">${priceConverter(
            total,
          )}</span>` +
          `<span style="color: #515151; margin-left: 2px; margin-top: 1px; font-feature-settings: 'liga' off, 'clig' off; font-family: Inter; font-size: 12px; font-style: normal; font-weight: 600; line-height: 20px; letter-spacing: -0.18px;">(${numberTransaction})</span>` +
          '</div>' +
          `<span style="color: #AAABAB; text-align: center; font-feature-settings: 'liga' off, 'clig' off; font-family: Inter; font-size: 14px; font-style: normal; font-weight: 500; line-height: 16px; letter-spacing: -0.33px;">${date}</span>` +
          '</div>'
        );
      },
      marker: {
        show: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
          value: 1,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `${priceConverter(value)}`;
        },
      },
    },
  };

  const chartSeries = [
    {
      name: seriesName,
      data: data,
    },
  ];

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [open]);

  return (
    <div className={classNames(styles.chartContainer, className)}>
      <Chart options={chartOptions} series={chartSeries} type="bar" height={450} />
    </div>
  );
};
