import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { map } from 'lodash-es';

import { getSettingsLinksByRoles } from 'settings/navigation/linksByRoles';
import {
  getUserSelector,
  getUserRolesSelector,
  isPartnerSelector,
  getAgentTypeSelector,
  getUserDataSelector,
} from 'store/selectors/user';
import { hideFromDemoAgent } from 'helpers/demo';

import TitleWithButtons from '../TitleWithButtons';

import styles from './styles.module.scss';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

const LeftNavigate = () => {
  const roles = useSelector(getUserRolesSelector);
  const isPartner = useSelector(isPartnerSelector);
  const agentType = useSelector(getAgentTypeSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const userData = useSelector(getUserDataSelector);

  const isSwtichedToComplimentary =
    userData?.SubscriptionCustomer?.SubscriptionStatus === 'canceled' &&
    userData?.SubscriptionCustomer?.IsComplimentaryPlan === true;

  const isComplimentaryPlan =
    userData?.SubscriptionCustomer?.ExternalPlanId === null &&
    userData?.SubscriptionCustomer?.IsComplimentaryPlan === true;

  const showBilling =
    userData?.SubscriptionCustomer !== null &&
    userData?.SubscriptionCustomer?.InternalPlanName !== null &&
    !isSwtichedToComplimentary &&
    !isComplimentaryPlan;

  let links = useMemo(
    () =>
      getSettingsLinksByRoles(roles, {
        isPartner,
        agentType,
        agentRole,
        showBilling,
      }),
    [roles, isPartner, agentRole, showBilling],
  );

  const user = useSelector(getUserSelector);
  //Remove Integrations menu item for any demo agents
  //These accounts may be shared externally and we do not want to expose available MLS systems
  if (hideFromDemoAgent(user)) {
    links = links.filter(function (link) {
      return link.title !== 'Integrations';
    });
  }

  return (
    <div className={styles.wrapper}>
      <TitleWithButtons className={styles.title} title="Settings" />
      <ul className={styles.menu}>
        {map(links, ({ link, title, icon: Icon }) => (
          <li key={link}>
            <NavLink
              testid="settings_link"
              exact
              className={styles.link}
              activeClassName={styles.linkActive}
              to={link}
            >
              <Icon className={styles.linkIcon} />
              {title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftNavigate;
