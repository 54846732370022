import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FC, useEffect, useMemo, useState } from 'react';
import { CollapsibleTable, WorkshopTableWrapper } from 'pages/Workshop/common';
import { tableColumns } from './tableColumns';
import { Role, TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { link } from 'settings/navigation/link';
import { openNewTab } from 'services/newTab';
import { useHistory } from 'react-router-dom';
import { getTransactionEndDate } from 'utils';
import {
  getAggregatePageTransactionsSelector,
  getSearchTransactions,
} from 'store/selectors/transactions';
import { getDateOnly } from 'helpers';
import { Button, DropdownCheck, Wrapper } from 'components';
import { requestGetTransactionsEffect } from 'store/effects';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { Archive } from 'components/Icons';
import classNames from 'classnames';
import { TRANSACTION_STATUSES } from 'tests/ui/constants/ui_const.mjs';
import { getUserRoleSelector } from 'store/selectors/user';
import { transactionStatuses } from 'settings/constants/transactionStatus';
import {
  closeCancelProjectStatusOptions,
  projectStatusesOptions,
} from 'settings/constants/transaction';

interface IProjects {
  userId: string;
  isActive?: boolean;
}

enum filterType {
  All = 'All',
}

export const Projects: FC<IProjects> = ({ userId, isActive }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filter, setFilter] = useState<string>(filterType.All);
  const [showArchived, setShowArchived] = useState(false);
  const { transactions, isPending } = useSelector(getAggregatePageTransactionsSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const userRole = useSelector(getUserRoleSelector);

  useEffect(() => {
    if (isActive) {
      filter !== filterType.All && setFilter(filterType.All);
      dispatch(
        requestGetTransactionsEffect({
          'participantIds[]': userId,
          isProject: true,
        }),
      );
    }
  }, [isActive]);

  const projectsData = getSearchTransactions(
    { data: transactions, search: '', teamTransactions: null },
    Role.Client,
  )
    .map((project) => {
      const projectData = project.data;
      const status = projectData?.Status;
      const keyDatesPrices =
        projectData?.PropertyTransactionKeyDatesPrices || projectData?.ProjectKeyDates;
      const endDate = getTransactionEndDate(status, keyDatesPrices);

      // Return enriched transaction
      return {
        ...projectData,
        status,
        closingDate: endDate ? getDateOnly(endDate, 'M/D/YYYY') : 'N/A',
        isAlertVisible:
          ([TEAM_OWNER, TEAM_ADMIN].includes(agentRole) && projectData.IsTeamTransaction) ||
          projectData.TransactionAccess,
      };
    })
    .filter((project) => {
      const isClosedOrCancelled = [
        TRANSACTION_STATUSES.CLOSED,
        TRANSACTION_STATUSES.CANCELED,
      ].includes(project.status);

      return (
        (filter !== filterType.All ? filter === project.status : true) &&
        (showArchived ? isClosedOrCancelled : !isClosedOrCancelled)
      );
    });

  const tableProps = {
    columns: tableColumns(showArchived, userRole),
    onRow: (record) => {
      return {
        onClick: (e) => {
          const path = link.toProjectOverview(record.Id);
          if (e.ctrlKey || e.metaKey) {
            openNewTab(path);
          } else {
            history.push(path);
          }
        },
      };
    },
    tableClassName: styles.projectsTable,
  };

  const options = useMemo(() => {
    let statuses = [...projectStatusesOptions];

    if (!showArchived) {
      statuses = statuses.filter(
        (status) =>
          !(
            status.value === transactionStatuses.Closed ||
            status.value === transactionStatuses.Canceled
          ),
      );
    } else {
      statuses = closeCancelProjectStatusOptions;
    }

    statuses = [{ name: 'All', value: filterType.All }, ...statuses];

    return statuses.map((item, index) => ({ id: index, label: item.name, value: item.value }));
  }, [showArchived]);

  const handleStatusChange = (status) => {
    setFilter(status);
  };

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.heading}>Projects</p>
          <div className={styles.row}>
            <DropdownCheck
              key={filter}
              className={styles.button}
              dropdownFields={options}
              onChange={handleStatusChange}
              defaultValue={filter}
            />

            <Button
              icon={
                <Archive color={showArchived ? '#fff' : '#252d44'} size={'24'} strokeWidth={'2'} />
              }
              className={classNames(styles.archive, { [styles.active]: showArchived })}
              onClick={() => {
                setFilter(filterType.All);
                setShowArchived((prev) => !prev);
              }}
            />
          </div>
        </div>

        <Wrapper className={styles.pageWrapper} isPending={isPending}>
          <WorkshopTableWrapper data={[projectsData]} emptyText="No Projects.">
            <CollapsibleTable
              title={''}
              dataSource={projectsData}
              disableCollapse
              {...tableProps}
            />
          </WorkshopTableWrapper>
        </Wrapper>
      </div>
    </div>
  );
};
