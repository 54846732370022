import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { Modal } from 'components-antd';
import { Col, Row } from 'antd';
import Icons from '../Icons';
import { Icons as WorkshopIcons } from 'pages/Workshop/Icons';

import { useEmailVerificationWall } from 'features/emailVerification/useEmailVerificationWall';
import { ActionToInterrupt } from 'features/emailVerification/constants';
import { appSetShowInviteClientAction } from 'store/actions/app';
import { getUserDataSelector } from 'store/selectors/user';
import { routes } from 'settings/navigation/routes';

import styles from './styles.module.scss';

const ActionMenuItem = ({ text, icon, color, actionHandler }) => {
  return (
    <div className={classNames(styles.quickActionItem, styles[color])} onClick={actionHandler}>
      <Icons variant={icon} />
      <p className={styles.actionTitle}>{text}</p>
    </div>
  );
};

export const ActionMenu = () => {
  const dispatch = useDispatch();
  const [openActionModal, setActionModal] = useState(false);
  const user = useSelector(getUserDataSelector);
  const history = useHistory();

  const inviteClientOrRequireEmailVerification = useEmailVerificationWall(
    ActionToInterrupt.INVITE_CLIENT,
    (state) => {
      dispatch(appSetShowInviteClientAction(state));
    },
  );

  const setShowInviteConnection = useCallback(
    (state) => {
      inviteClientOrRequireEmailVerification(state);
    },
    [dispatch, user?.IsEmailVerified],
  );

  const closeActionModal = () => setActionModal(false);

  const ACTION_MENU_ITEMS = [
    {
      text: 'Invite a client',
      icon: Icons.INVITE_CLIENT,
      color: 'orange',
      actionHandler: () => {
        setShowInviteConnection(true);
        closeActionModal();
      },
    },
    {
      text: 'Send for signature',
      icon: Icons.FORM,
      color: 'confettiBlue',
      actionHandler: () => history.push(routes.workshopForms, { openNewFormModal: true }),
    },
    {
      text: 'Start a new property search',
      icon: Icons.SEARCH,
      color: 'lightBlue',
      actionHandler: () => history.push(routes.feed, { openNewSearch: true }),
    },
    {
      text: 'See my tasks',
      icon: Icons.TASKS_QUICK_ACTION,
      color: 'purple',
      actionHandler: () => history.push(routes.tasks),
    },
    {
      text: 'See my transactions',
      icon: Icons.TRANSACTIONS,
      color: 'green',
      actionHandler: () => history.push(routes.transactions),
    },
  ];

  return (
    <div className={styles.actionMenu}>
      {!openActionModal && (
        <div className={styles.actionMenuBtn} onClick={() => setActionModal(true)}>
          <WorkshopIcons variant={WorkshopIcons.PLUS} className={styles.actionPlusIcon} />
        </div>
      )}

      {/* Quick Actions Modal */}

      <Modal
        open={openActionModal}
        onCancel={closeActionModal}
        width={720}
        footer={null}
        className={styles.quickActionsModal}
        maskStyle={{
          backdropFilter: 'blur(6px)',
        }}
      >
        <p className={styles.header}>Quick Actions</p>
        <div className={styles.actionsContent}>
          <Row gutter={20}>
            {ACTION_MENU_ITEMS?.map((item, idx) => (
              <Col key={idx} span={12}>
                <ActionMenuItem {...item} />
              </Col>
            ))}
          </Row>
        </div>
      </Modal>
    </div>
  );
};
