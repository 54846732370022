import { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getAgentTeamIsActiveSelector } from 'store/selectors/agentTeamDetail';
import { Select, Option } from 'components-antd';
import { updateTransactionParticipantAccessEffect } from 'store/effects/transactions';
import { showSuccessMessage } from 'helpers';
import { TransactionAccessType } from 'settings/constants/transaction';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import { getTeamMemberExistance } from 'store/selectors/teamList';
import { requestGetTeamListEffect } from 'store/effects';
import { getUserId } from 'store/selectors/user';

import styles from './styles.module.scss';
import { Icons } from 'pages/Workshop/Icons';

export const TransactionAccess = (props) => {
  const { className, participant, participantsInContacts } = props;
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const isTeamAgentActive = useSelector(getAgentTeamIsActiveSelector);
  const userId = useSelector(getUserId);

  const { Id, TransactionAccess } = participant;
  const { isParticipantTeamMember } = useSelector(getTeamMemberExistance(Id));

  const dispatch = useDispatch();

  const onChangeAccess = (val) => {
    dispatch(
      updateTransactionParticipantAccessEffect(
        {
          participantId: Id,
          TransactionAccess: val === TransactionAccessType.Full,
        },
        null,
        (err) => {
          if (!err) showSuccessMessage(`${val} access granted!`);
        },
      ),
    );
  };

  const getTeamMembers = () => dispatch(requestGetTeamListEffect({ Status: 'Accepted' }));

  useEffect(() => {
    if (fullAccess && !participantsInContacts) getTeamMembers();
  }, []);

  return (
    <>
      {fullAccess && isTeamAgentActive && isParticipantTeamMember && Id !== userId ? (
        <div className={classNames(styles.transactionAccess, className)}>
          <Select
            className={styles.accessSelect}
            defaultValue={
              TransactionAccess ? TransactionAccessType.Full : TransactionAccessType.Limited
            }
            dropdownStyle={{ minWidth: 150 }}
            popupClassName={styles.transactionAccessSelect}
            bordered={false}
            onChange={onChangeAccess}
          >
            <Option value={TransactionAccessType.Full}>
              <div className={styles.dropdownLabel}>
                <span>Full Access</span>
                <Icons variant={Icons.GREEN_LIGHT_CHECKMARK} className={styles.icon} />
              </div>
            </Option>
            <Option value={TransactionAccessType.Limited}>
              <div className={styles.dropdownLabel}>
                <span>Limited Access</span>
                <Icons variant={Icons.GREEN_LIGHT_CHECKMARK} className={styles.icon} />
              </div>
            </Option>
          </Select>
        </div>
      ) : (
        <span className={styles.accessText}>
          {TransactionAccess ? 'Full Access' : 'Limited Access'}
        </span>
      )}
    </>
  );
};
