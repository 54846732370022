import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Input, Spinner } from 'components';
import { ArrowRight } from 'components/Icons';
import { getInitials } from 'utils';

import styles from './styles.module.scss';
import { getClientsSearchInstancesEffect } from 'store/effects/clientSearches';
import { getClientsSearchInstancesSelector } from 'store/selectors/clientSearches';
import { setSelectedClientSavedSearchAction } from 'store/actions/feedv3';

const ClientFavItem = ({ name = '', favortiesCount = 0, avatarUrl, onClick }) => (
  <div className={styles.clientFavItemWrapper} onClick={onClick}>
    <div className={styles.container}>
      <Avatar avatarClassName={styles.avatar} placeholder={getInitials(name)} src={avatarUrl} />
      <div className={styles.textInfo}>
        <span className={styles.name}>{name}</span>
        <span className={styles.count}>
          <span className={styles.bold}>{favortiesCount}&nbsp;</span>
          Active Search{favortiesCount > 1 ? 'es' : ''}
        </span>
      </div>
    </div>
    <ArrowRight className={styles.arrowRight} />
  </div>
);

export const ClientSearches = () => {
  const dispatch = useDispatch();

  const { data, isLoading } = useSelector(getClientsSearchInstancesSelector);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    dispatch(getClientsSearchInstancesEffect());
  }, []);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const searchedData = data.filter((client) => {
    const matchesSearch = `${client.FirstName} ${client.LastName}`
      .toLowerCase()
      .includes(searchInput.toLowerCase());

    return matchesSearch;
  });

  return (
    <div className={styles.clientFavorites}>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Spinner loaderClassName={styles.loader} />
        </div>
      ) : (
        <div className={styles.clientFavoritesContainer}>
          <Input
            className={styles.searchInput}
            placeholder="Search clients..."
            type="search"
            variant={Input.LIGHT_ROUNDED}
            onChange={handleSearchChange}
          />
          {searchedData?.map((client, idx) => {
            const { ActiveInstances, FirstName, LastName, AvatarUrl } = client;
            return searchInput !== '' || ActiveInstances >= 1 ? (
              <ClientFavItem
                key={idx}
                name={`${FirstName} ${LastName}`}
                favortiesCount={ActiveInstances}
                avatarUrl={AvatarUrl}
                onClick={() =>
                  dispatch(
                    setSelectedClientSavedSearchAction({ selectedClientSavedSearch: client }),
                  )
                }
              />
            ) : null;
          })}
        </div>
      )}
    </div>
  );
};
