import { FC, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { AntCard } from 'components-antd';
import { DropdownCheck, Map, Wrapper } from 'components';
import { Icons } from '../../Icons';
import {
  NeighborhoodFilterTypes,
  NeighborhoodCategoryTypes,
  NeighborhoodDayRangeTypes,
  NeighborhoodFiltersChangeHandler,
  NeighborhoodStatusTypes,
  NeighborhoodViewTypes,
} from 'pages/Radar/types';
import { IPropertyReferenceNeighborhoodActivities } from 'store/reducers/radarPortfolio/types';
import moment from 'moment';
import classNames from 'classnames';

interface INeighborhoodActivityCard {
  neighborhoodActivitiesData: IPropertyReferenceNeighborhoodActivities | null;
  isPending: boolean;
  onFiltersChange: NeighborhoodFiltersChangeHandler;
}

export const NeighborhoodActivityCard: FC<INeighborhoodActivityCard> = ({
  neighborhoodActivitiesData,
  isPending,
  onFiltersChange,
}) => {
  const [filter, setFilter] = useState<NeighborhoodFilterTypes>(NeighborhoodFilterTypes.All);
  const [dayRange, setDayRange] = useState<NeighborhoodDayRangeTypes>(
    NeighborhoodDayRangeTypes.ThisMonth,
  );
  const [view, setView] = useState<NeighborhoodViewTypes>(NeighborhoodViewTypes.List);
  const [selectedActivity, setSelectedActivity] = useState<number | null>(null);

  const formatPrice = (value: string) =>
    Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    }).format(Number(value));

  const markers = useMemo(() => {
    const result = {};
    const seenExternalId = new Set(); // To track unique coordinates
    (neighborhoodActivitiesData?.data?.results || [])?.forEach((item: any) => {
      const longitude = item.Address?.Geolocation?.Long;
      const latitude = item.Address?.Geolocation?.Lat;
      if (longitude !== undefined && latitude !== undefined) {
        const key = item.ExternalId;
        // Skip duplicates
        if (seenExternalId.has(key)) {
          return;
        }
        seenExternalId.add(key);
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push({
          id: item.Id,
          priceToDisplay: formatPrice(item.ListPrice),
          neighborhoodCategory: item.Category,
          status: item.ActivityStatus,
          geometry: {
            coordinates: {
              lng: longitude,
              lat: latitude,
            },
          },
        });
      }
    });
    return result;
  }, [neighborhoodActivitiesData]);

  const listData = useMemo(() => {
    if (!neighborhoodActivitiesData) {
      return [];
    }

    const capitalizeString = (str: string) =>
      str
        ?.split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');

    return neighborhoodActivitiesData?.data?.results.map((activity) => ({
      id: activity.Id,
      image: activity.PhotoUrls[0],
      category: activity.Category as NeighborhoodCategoryTypes,
      status: activity.ActivityStatus as NeighborhoodStatusTypes,
      price: formatPrice(activity.ListPrice),
      previousPrice: formatPrice(activity.PreviousListPrice ? activity.PreviousListPrice : '0'),
      address: capitalizeString(activity.Address.Line1),
      addressSecondary: `${capitalizeString(activity.Address.City)}, ${activity.Address.State} ${
        activity.Address.Zip
      }`,
      time: moment(activity.Updated).fromNow(),
      distance: activity?.AddressDistance?.toFixed(1) + ' mi',
    }));
  }, [neighborhoodActivitiesData]);

  const toggleView = () => {
    setView((prev) =>
      prev === NeighborhoodViewTypes.List ? NeighborhoodViewTypes.Map : NeighborhoodViewTypes.List,
    );
    setSelectedActivity(null);
  };

  const mapCategoryAndStatusToIcon = (
    category: NeighborhoodCategoryTypes,
    status: NeighborhoodStatusTypes,
  ) => {
    switch (category) {
      case NeighborhoodCategoryTypes.New:
        return <Icons variant={Icons.STATUS_NEW} />;
      case NeighborhoodCategoryTypes.StatusChanges:
        if (status === NeighborhoodStatusTypes.Sold) {
          return <Icons variant={Icons.STATUS_SOLD} />;
        }
        return <Icons variant={Icons.STATUS_CHANGE} />;
      case NeighborhoodCategoryTypes.PriceReductions:
        return <Icons variant={Icons.STATUS_PRICE_REDUCTIONS} />;
      case NeighborhoodCategoryTypes.PriceIncrease:
        return <Icons variant={Icons.STATUS_PRICE_INCREASE} />;
      case NeighborhoodCategoryTypes.BackOnMarket:
        return <Icons variant={Icons.STATUS_CHANGE} />;
      case NeighborhoodCategoryTypes.OpenHouses:
        return <Icons variant={Icons.STATUS_CHANGE} />;
      default:
        return null;
    }
  };

  const mapCategoryAndStatusToText = (
    category: NeighborhoodCategoryTypes,
    status: NeighborhoodStatusTypes,
  ) => {
    switch (category) {
      case NeighborhoodCategoryTypes.New:
        return 'New Listing';
      case NeighborhoodCategoryTypes.StatusChanges:
        switch (status) {
          case NeighborhoodStatusTypes.ActiveUnderContract:
            return 'Under Contract';
          case NeighborhoodStatusTypes.ComingSoon:
            return 'Coming Soon';
          case NeighborhoodStatusTypes.AcceptingBackups:
            return 'Accepting Backups';
          default:
            return status;
        }
      case NeighborhoodCategoryTypes.BackOnMarket:
        return 'Back on the Market';
      default:
        return category;
    }
  };

  const onClickMarker = (id: number) => {
    setSelectedActivity(id);
  };

  const renderPrice = (
    category: NeighborhoodCategoryTypes,
    price: string,
    previousPrice: string,
  ) => {
    if (
      category === NeighborhoodCategoryTypes.PriceReductions ||
      category === NeighborhoodCategoryTypes.PriceIncrease
    ) {
      return (
        <p className={styles.price}>
          <span className={styles.previousPrice}>{previousPrice}</span>
          <span className={styles.arrow}>&rarr;</span>
          <span>{price}</span>
        </p>
      );
    }

    return <p className={styles.price}>{price}</p>;
  };

  const renderItem = ({
    image,
    category,
    status,
    price,
    previousPrice,
    address,
    addressSecondary,
    time,
    distance,
  }) => (
    <div className={styles.listItem}>
      <img src={image} alt={image} />
      <div className={styles.listItemContent}>
        <div className={styles.headerTitle}>
          <div className={styles.statusContainer}>
            {mapCategoryAndStatusToIcon(category, status)}
            <p
              className={classNames(styles.status, {
                [styles.statusNew]: category === NeighborhoodCategoryTypes.New,
                [styles.statusSold]: status === NeighborhoodStatusTypes.Sold,
                [styles.statusStatusChanges]:
                  category === NeighborhoodCategoryTypes.StatusChanges &&
                  status !== NeighborhoodStatusTypes.Sold,
                [styles.statusPriceReductions]:
                  category === NeighborhoodCategoryTypes.PriceReductions,
                [styles.statusPriceIncrease]: category === NeighborhoodCategoryTypes.PriceIncrease,
                [styles.statusBackOnMarket]: category === NeighborhoodCategoryTypes.BackOnMarket,
                [styles.statusOpenHouses]: category === NeighborhoodCategoryTypes.OpenHouses,
              })}
            >
              {mapCategoryAndStatusToText(category, status)}
            </p>
          </div>
          <p className={styles.time}>{time}</p>
        </div>
        <div className={styles.details}>
          {renderPrice(category, price, previousPrice)}
          <div className={styles.subDetails}>
            <div>
              <p className={styles.address}>{address}</p>
              <p className={styles.addressSecondary}>{addressSecondary}</p>
            </div>
            <div className={styles.distanceContainer}>
              <Icons variant={Icons.MARKER} />
              <p className={styles.distance}>{distance}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderView = (view: NeighborhoodViewTypes) => {
    switch (view) {
      case NeighborhoodViewTypes.List:
        return <div className={styles.list}>{listData.map(renderItem)}</div>;
      case NeighborhoodViewTypes.Map:
        return (
          <div className={styles.map}>
            <Map
              popupClassName={styles.popupClassName}
              markersMap={markers}
              onClickMarker={onClickMarker}
            />
            {selectedActivity && (
              <div className={styles.activity}>
                {listData.filter(({ id }) => id === selectedActivity).map(renderItem)}
              </div>
            )}
          </div>
        );

      default:
        break;
    }
  };

  const getCategoryAndStatusFromFilter = (nextFilter: NeighborhoodFilterTypes) => {
    if (nextFilter === NeighborhoodFilterTypes.All) {
      return { category: undefined, activityStatus: undefined };
    }

    if (nextFilter === NeighborhoodFilterTypes.Sold) {
      return {
        category: [NeighborhoodCategoryTypes.StatusChanges],
        activityStatus: [NeighborhoodStatusTypes.Sold],
      };
    }

    let category: NeighborhoodCategoryTypes | undefined;
    switch (nextFilter) {
      case NeighborhoodFilterTypes.New:
        category = NeighborhoodCategoryTypes.New;
        break;
      case NeighborhoodFilterTypes.StatusChanges:
        category = NeighborhoodCategoryTypes.StatusChanges;
        break;
      case NeighborhoodFilterTypes.PriceReductions:
        category = NeighborhoodCategoryTypes.PriceReductions;
        break;
      case NeighborhoodFilterTypes.PriceIncrease:
        category = NeighborhoodCategoryTypes.PriceIncrease;
        break;
      case NeighborhoodFilterTypes.BackOnMarket:
        category = NeighborhoodCategoryTypes.BackOnMarket;
        break;
      case NeighborhoodFilterTypes.OpenHouses:
        category = NeighborhoodCategoryTypes.OpenHouses;
        break;
    }

    return {
      category: [category as NeighborhoodCategoryTypes],
      activityStatus: undefined,
    };
  };

  const getDaysFromDayRange = (nextDayRange: NeighborhoodDayRangeTypes): number | undefined => {
    if (nextDayRange === NeighborhoodDayRangeTypes.ThisMonth) {
      return 30;
    }

    return undefined;
  };

  const handleFilterChange = (value: string): void => {
    const nextFilter = value as NeighborhoodFilterTypes;
    setFilter(nextFilter);
    setSelectedActivity(null);
    onFiltersChange({
      ...getCategoryAndStatusFromFilter(nextFilter),
      dayRange: getDaysFromDayRange(dayRange),
    });
  };

  const handleDayRangeChange = (value: string): void => {
    const nextDayRange = value as NeighborhoodDayRangeTypes;
    setDayRange(nextDayRange);

    onFiltersChange({
      ...getCategoryAndStatusFromFilter(filter),
      dayRange: getDaysFromDayRange(nextDayRange),
    });
  };

  return (
    <AntCard className={styles.card}>
      <Wrapper isPending={isPending}>
        <p className={styles.title}>Neighborhood Activity</p>
        <div className={styles.header}>
          <div className={styles.row}>
            <DropdownCheck
              className={styles.dropdown}
              dropdownFields={[
                { label: 'All', value: NeighborhoodFilterTypes.All },
                { label: 'New Listing', value: NeighborhoodFilterTypes.New },
                { label: 'Status Change', value: NeighborhoodFilterTypes.StatusChanges },
                {
                  label: 'Price Reductions',
                  value: NeighborhoodFilterTypes.PriceReductions,
                },
                {
                  label: 'Price Increase',
                  value: NeighborhoodFilterTypes.PriceIncrease,
                },
                {
                  label: 'Back on the Market',
                  value: NeighborhoodFilterTypes.BackOnMarket,
                },
                { label: 'Open Houses', value: NeighborhoodFilterTypes.OpenHouses },
                { label: 'Sold', value: NeighborhoodFilterTypes.Sold },
              ]}
              placement="bottomLeft"
              defaultValue={filter}
              onChange={handleFilterChange}
              disableCheck={isPending}
            />
            <DropdownCheck
              className={styles.dropdown}
              dropdownFields={[{ label: 'This Month', value: NeighborhoodDayRangeTypes.ThisMonth }]}
              placement="bottomLeft"
              defaultValue={dayRange}
              onChange={handleDayRangeChange}
              disableCheck={isPending}
            />
          </div>
          <div className={styles.toggle} onClick={toggleView}>
            <Icons variant={view === NeighborhoodViewTypes.Map ? Icons.LIST : Icons.MAP} />
          </div>
        </div>
        {renderView(view)}
      </Wrapper>
    </AntCard>
  );
};
