import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import IconButton from './IconButton';
import { Menu } from './Menu';

import styles from './styles.module.scss';
import { Header } from './Header';
import { Popover } from 'components-antd';
import { useState } from 'react';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { MenuDots, Messages, Close as CloseIcon } from 'components/Icons';
import HomeActionsAddToFavorites from '../HomeActions/AddToFavorites';
import { setSharePropertyEffect } from 'store/effects';
import { openCommentsDrawerEffect } from 'store/effects/drawers/comments';
import { VirtualTourSelector } from './VirtualTourSelector';
import PhotosView from '../PhotosView';
import MapModal from '../MapModal';
import StreetView from '../StreetView';
import VirtualTour from '../VirtualTour';

const Tabs = ({
  tabValue,
  modalOpenImage,
  photos,
  onScheduleTour,
  isTourScheduled,
  virtualTourValue,
  closeModal,
}) => {
  switch (tabValue) {
    case 'Photos':
      return (
        <PhotosView
          imageId={modalOpenImage}
          photos={photos}
          onScheduleTour={onScheduleTour}
          isTourScheduled={isTourScheduled}
        />
      );
    case 'Map':
      return <MapModal />;
    case 'Street View':
      return <StreetView />;
    case 'Virtual Tour':
      if (
        virtualTourValue &&
        /https:\/\/.*(vimeo|starlighthomes|drive|distinctiveimagedesigns|app.brivity|dropbox|wingis)/i.test(
          virtualTourValue,
        )
      ) {
        // Open the link in a new tab
        window.open(virtualTourValue, '_blank');
        // Close the modal if open
        closeModal();
        return null;
      } else {
        return <VirtualTour virtualTourValue={virtualTourValue} />;
      }

    default:
      return null;
  }
};

const PhotosViewModal = ({
  isTourScheduled,
  modalOpenImage,
  onScheduleTour,
  closeModal,
  isOpen,
  className,
  onClose,
  setTab,
  tabValue,
  setShowFormLibraryModal,
  propertyInfo,
  virtualTourVisibility,
  virtualTours,
  photosTab,
  setVirtualTourValue,
  virtualTourValue,
  recommendedTo,
  photos,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { isAgent, isClient } = useSelector(getUserRolesMapSelector);
  const dispatch = useDispatch();

  const handleOpenChange = () => {
    setOpenMenu((prev) => !prev);
  };

  return createPortal(
    <div
      testid="photos_view_modal"
      className={classNames(styles.modal, { [styles.isOpen]: isOpen }, className)}
    >
      <div className={styles.opacityLayer} />
      <div className={styles.content}>
        <div className={styles.headerContainer}>
          <Header
            setTab={setTab}
            tabValue={tabValue}
            virtualTour={virtualTourVisibility}
            photosTab={photosTab}
          />
          <div className={styles.buttonsContainer}>
            {tabValue === 'Virtual Tour' && virtualTours.length > 1 ? (
              <VirtualTourSelector
                virtualTours={virtualTours}
                setVirtualTourValue={setVirtualTourValue}
                virtualTourValue={virtualTourValue}
              />
            ) : null}
            {isClient ? (
              <>
                <HomeActionsAddToFavorites variant="modal" />
                <IconButton
                  Icon={Messages}
                  onClick={() => {
                    dispatch(setSharePropertyEffect(propertyInfo));
                    dispatch(openCommentsDrawerEffect({ open: true }));
                  }}
                  variant="hollow"
                />
              </>
            ) : null}

            {isAgent ? (
              <Popover
                content={
                  <Menu
                    propertyInfo={propertyInfo}
                    setShowFormLibraryModal={setShowFormLibraryModal}
                    setOpenMenu={setOpenMenu}
                    recommendedTo={recommendedTo}
                  />
                }
                open={openMenu}
                placement="bottomLeft"
                getPopupContainer={(triggerNode) => triggerNode}
              >
                <span onClick={handleOpenChange}>
                  <IconButton Icon={MenuDots} color="#303030" />
                </span>
              </Popover>
            ) : null}

            <IconButton
              Icon={CloseIcon}
              onClick={() => {
                setOpenMenu(false);
                onClose();
              }}
            />
          </div>
        </div>
        <Tabs
          tabValue={tabValue}
          modalOpenImage={modalOpenImage}
          photos={photos}
          onScheduleTour={onScheduleTour}
          isTourScheduled={isTourScheduled}
          virtualTourValue={virtualTourValue}
          propertyInfo={propertyInfo}
          closeModal={closeModal}
        />
      </div>
    </div>,
    document.body,
  );
};

export default PhotosViewModal;
