import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Row, Col } from 'components-antd';
import { Header } from './components/Header';
import { ListedWidget } from './components/AreaGraphWidgets/ListedWidget';
import { UnderContractWidget } from './components/AreaGraphWidgets/UnderContractWidget';
import { ClientWidget } from './components/AreaGraphWidgets/ClientWidget';
import { ExpiringListingWidget } from './components/AreaGraphWidgets/ExpiringListingWidget';
import { ClosingWidget } from './components/AreaGraphWidgets/ClosingWidget';
import { ClosedWidget } from './components/AreaGraphWidgets/ClosedWidget';
import { ScheduleWidget } from './components/ScheduleWidget';
import { NeedsAttentionWidget } from './components/NeedsAttentionWidget';
import { ActionMenu } from './components/ActionMenu';
import {
  requestGetAgentDashboardGraphsEffect,
  requestGetAgentDashboardNeedsAttentionEffect,
  requestGetAgentDashboardScheduleEffect,
} from 'store/effects';
import {
  getLoadingGraphsSelector,
  getUpdatedClientStateFlagSelector,
} from 'store/selectors/agentDashboard';
import { TEAM_BASIC } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

import styles from './styles.module.scss';
import { requestUpdateClientStateFlag } from 'store/actions/agentDashboard';

export const Pulse = () => {
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const updatedClientStateFlag = useSelector(getUpdatedClientStateFlagSelector);
  const [teamStats, setTeamStats] = useState<boolean | null>(null);
  const toggleTeamStats = (flag: boolean = false) => {
    setTeamStats(flag);
  };
  const dispatch = useDispatch();
  const { isIdle } = useSelector(getLoadingGraphsSelector);

  const fetchStats = async () => {
    dispatch(requestGetAgentDashboardGraphsEffect({ teamStats }));
    dispatch(requestGetAgentDashboardNeedsAttentionEffect({ teamStats }));
    dispatch(requestGetAgentDashboardScheduleEffect({ teamStats }));
  };

  useEffect(() => {
    if (agentRole) setTeamStats(agentRole !== TEAM_BASIC);
  }, [agentRole]);

  useEffect(() => {
    if (teamStats !== null) fetchStats();
  }, [teamStats]);

  useEffect(() => {
    if (updatedClientStateFlag && teamStats !== null) {
      dispatch(requestGetAgentDashboardGraphsEffect({ teamStats }));
      dispatch(requestUpdateClientStateFlag(false));
    }
  }, [updatedClientStateFlag]);
  return (
    <div>
      <Header teamStats={teamStats} toggleTeamStats={toggleTeamStats} />

      <div className={styles.mainContentWrapper}>
        <Row className={styles.graphContainer} justify="space-around" align="top" gutter={[16, 16]}>
          <Col
            span={8}
            xs={{ span: 24, order: 1 }}
            sm={{ span: 12, order: 1 }}
            lg={{ span: 8, order: 1 }}
            className="gutter-row"
          >
            <ListedWidget teamStats={teamStats} />
          </Col>
          <Col
            span={8}
            xs={{ span: 24, order: 2 }}
            sm={{ span: 12, order: 2 }}
            lg={{ span: 8, order: 2 }}
            className="gutter-row"
          >
            <UnderContractWidget teamStats={teamStats} />
          </Col>
          <Col
            span={8}
            xs={{ span: 24, order: 3 }}
            sm={{ span: 12, order: 3 }}
            lg={{ span: 8, order: 3 }}
            className="gutter-row"
          >
            <ClientWidget teamStats={teamStats} />
          </Col>

          <Col
            span={8}
            xs={{ span: 24, order: 4 }}
            sm={{ span: 12, order: 4 }}
            lg={{ span: 8, order: 4 }}
            className="gutter-row"
          >
            <ExpiringListingWidget teamStats={teamStats} />
          </Col>
          <Col
            span={8}
            xs={{ span: 24, order: 5 }}
            sm={{ span: 12, order: 5 }}
            lg={{ span: 8, order: 5 }}
            className="gutter-row"
          >
            <ClosingWidget teamStats={teamStats} />
          </Col>
          <Col
            span={8}
            xs={{ span: 24, order: 6 }}
            sm={{ span: 12, order: 6 }}
            lg={{ span: 8, order: 6 }}
            className="gutter-row"
          >
            <ClosedWidget teamStats={teamStats} />
          </Col>
        </Row>

        <Row className={styles.graphContainer} justify="space-around" align="top" gutter={[16, 16]}>
          <Col
            span={18}
            xs={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 17, order: 1 }}
            xl={{ span: 18, order: 1 }}
            className="gutter-row"
          >
            <NeedsAttentionWidget teamStats={teamStats} />
          </Col>
          <Col
            span={6}
            xs={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            lg={{ span: 7, order: 2 }}
            xl={{ span: 6, order: 2 }}
            className="gutter-row"
          >
            <ScheduleWidget teamStats={teamStats} />
          </Col>
        </Row>
        <ActionMenu />
      </div>
    </div>
  );
};
