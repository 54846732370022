import React from 'react';
import PropTypes from 'prop-types';

const SavedIcon = ({ className, size = '36', color = '#FF576D' }) => (
  <div className={className}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 6H22.5C23.2956 6 24.0587 6.31607 24.6213 6.87868C25.1839 7.44129 25.5 8.20435 25.5 9V30L18 25.5L10.5 30V9C10.5 8.20435 10.8161 7.44129 11.3787 6.87868C11.9413 6.31607 12.7044 6 13.5 6Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

SavedIcon.propTypes = {
  className: PropTypes.string,
};

SavedIcon.defaultProps = {
  className: '',
};

export default SavedIcon;
