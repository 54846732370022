import {
  DYNAMIC_QUESTION_TYPE,
  FORM_PROCESS_SCREEN,
  FORM_STATUS_TYPE,
  FORM_TOKEN,
} from 'app-constants';
import { PdfCache } from 'components';
import { successNotification } from 'components-antd';
import moment from 'moment';
import { RefObject } from 'react';
import { routes } from 'settings/navigation/routes';

import store from 'store';
import {
  acceptTermsAndConditionsAnonEffect,
  acceptTermsAndConditionsAuthEffect,
  declineSignatureAnonEffect,
  declineSignatureAuthEffect,
  fillSmartFormAnonEffect,
  fillSmartFormAuthEffect,
  getDynamicFormDocumentAnonEffect,
  getDynamicFormDocumentAuthEffect,
  getDynamicFormDocumentBundleEffect,
  getDynamicTemplateDocumentEffect,
  getSignatureAnonEffect,
  getSignatureAuthEffect,
  progressDynamicFormEffect,
  progressSaveAsTemplateFormEffect,
  progressSmartFormAnonEffect,
  regressFormDocumentEffect,
  saveBundleAsTemplateEffect,
  saveSignatureAnonEffect,
  saveSignatureAuthEffect,
  unlockDynamicFormDocumentAuthEffect,
  updateDynamicFormDocumentQuestionEffect,
  updateDynamicFormEditorConfigEffect,
  updateDynamicFormEffect,
  updateDynamicFormRequestEffect,
  updateDynamicFormResponseEffect,
  updateDynamicRequiredQuestionEffect,
  updateStrikeThroughAuthEffect,
  updateTemplateFormDocumentQuestionEffect,
  updateTemplateStrikeThroughEffect,
} from 'store/effects/formProcess';
import {
  AnonDataType,
  AuthDataType,
  BundleDataType,
  BundleDocumentsType,
  BundleTemplate,
  DynamicFormMeta,
  DynamicFormType,
  DynamicSignatureConfig,
  FormBundleDocument,
  FormDocumentAnswersType,
  FormDocumentQuestionsType,
  FormProcessScreenType,
  FormTemplateWizardParamType,
  PDFEditorConfig,
  RequiredQuestion,
  SaveAsTemplateType,
  StrikeThroughType,
} from 'types';
import { link } from 'settings/navigation/link';
import { patchFormVersionStatusEffect } from 'store/effects/formBuilder';
import { v4 as uuidv4 } from 'uuid';
import { modifyQuestionsForTemplate, prepareQuestions } from './helper';
import {
  getCohesiveFieldAnswers,
  getCohesiveFieldQuestions,
} from '../Containers/DynamicViewer/components/FieldRenderer/Fields/helper';
import _ from 'lodash';
import { prepareQuestionsWithStrikeThrough } from 'store/reducers/requestFormProcess/helper';
import { sortDocuments } from '../helper';
import { CLIENT } from 'settings/constants/roles';

type EffectType = ((arg0: any) => Promise<void>) | ((dispatch: any) => void);

const NonRequiredQuestionType = [DYNAMIC_QUESTION_TYPE.CheckBox];

export class DynamicManager {
  static instance;

  private authData: AuthDataType | undefined;
  private anonData: AnonDataType | undefined;
  private templateData:
    | (FormTemplateWizardParamType & {
        editMode?: boolean;
        updateTemplate?: boolean;
        templateForm?: boolean;
        allTemplates?: BundleTemplate[];
        templateBundle?: boolean;
        templateBundleId?: number;
      })
    | undefined;
  private bundleData: BundleDataType | undefined;
  private saveAsTemplateData: SaveAsTemplateType | undefined;
  private history;
  private containerRef: RefObject<HTMLDivElement> | undefined;
  private pageContainerRef: RefObject<HTMLDivElement> | undefined;
  private redirectionLink: string | undefined;

  constructor() {
    if (!DynamicManager.instance) {
      DynamicManager.instance = this;
    }

    return DynamicManager.instance;
  }

  loadDynamicManager({
    authData,
    anonData,
    templateData,
    bundleData,
    history,
    containerRef,
    saveAsTemplateData,
    redirectionLink,
  }) {
    this.anonData = anonData;
    this.authData = authData;
    this.templateData = templateData;
    this.bundleData = bundleData;
    this.history = history;
    this.containerRef = containerRef;
    this.saveAsTemplateData = saveAsTemplateData;
    this.redirectionLink = redirectionLink || '';
  }

  updateBundleDocumentsData(documents) {
    if (this.bundleData) {
      this.bundleData.allDocuments = documents;
    }
  }

  private dispatch(effect: EffectType) {
    effect(store.dispatch);
  }

  private getFormData() {
    const updatedState = store?.getState?.();

    return updatedState?.requestFormProcess?.dynamicForm ?? ({} as DynamicFormType);
  }

  private getActiveDocument() {
    const dynamicFormDocuments = this.getDynamicDocuments();
    return dynamicFormDocuments?.[0] || ({} as FormBundleDocument);
  }

  private isClientUser() {
    const state = store?.getState?.();
    return state?.user?.data?.Roles?.includes(CLIENT);
  }

  getDynamicDocuments() {
    return this.getFormData()?.dynamicFormDocuments || ([] as FormBundleDocument[]);
  }

  getActiveDocumentPublicId() {
    return this.getActiveDocument()?.PublicId;
  }

  getBundleDocumentsDetails() {
    return this.getFormData()?.bundleDocumentsDetails || ([] as BundleDocumentsType[]);
  }

  getTemplateBundleId() {
    return this.templateData?.templateBundleId;
  }

  setTemplateBundleId(bundleId: number) {
    if (this.templateData) {
      this.templateData.templateBundleId = bundleId;
    }
  }

  getTemplateId() {
    return this.templateData?.templateId;
  }

  getSingleTemplateId() {
    if (this.templateData) {
      const { allTemplates, templateId } = this.templateData;

      return allTemplates ? allTemplates[0].Id : templateId;
    }
  }

  getAnonymousDetails() {
    if (this.anonData)
      return {
        token: this.anonData.token,
        type: FORM_TOKEN[this.anonData.type],
      };

    return;
  }

  isBundleForm() {
    const dynamicFormDocuments = this.getDynamicDocuments();
    return dynamicFormDocuments.length > 1 || this.bundleData?.formBundle;
  }

  hasBundleData() {
    return !!this.bundleData;
  }

  isBundleTemplate() {
    const dynamicFormDocuments = this.getDynamicDocuments();
    return (
      (this.templateData && dynamicFormDocuments.length > 1) || this.templateData?.templateBundle
    );
  }

  isSaveAsTemplate() {
    return this.saveAsTemplateData?.saveAsTemplate;
  }

  isEditMode() {
    return this.templateData?.editMode;
  }

  canAddRoles() {
    return !this.saveAsTemplateData?.saveAsTemplate;
  }

  isTemplate() {
    return this.templateData?.templateId;
  }

  isTemplateBundle() {
    return this.templateData?.templateBundle;
  }

  isTemplateForm() {
    return this.templateData?.templateForm;
  }

  getAuthDocumentPublicId() {
    return this.authData?.formDocumentPublicId || '';
  }

  getFormProcessPublicId() {
    if (this.authData || this.bundleData) {
      const { formProcessPublicId } = this.authData ||
        this.bundleData || { formProcessPublicId: '' };

      return formProcessPublicId;
    }

    return '';
  }

  private getScreen() {
    return this.getFormData()?.screen;
  }

  private getFormMeta() {
    return this.getFormData()?.meta ?? ({} as DynamicFormMeta);
  }

  private getEditorConfig() {
    return this.getFormData()?.editorConfig || ({} as PDFEditorConfig);
  }

  private getSignatureConfig() {
    return this.getFormData()?.signatureConfig || ({} as DynamicSignatureConfig);
  }

  getSignatureAnswer() {
    if (this.isBundleForm()) {
      const bundleDocumentsDetails = this.getBundleDocumentsDetails();
      const answers = getCohesiveFieldAnswers(bundleDocumentsDetails);

      return answers;
    }

    return this.getSignatureConfig()?.responses || ({} as FormDocumentAnswersType);
  }

  getQuestions() {
    if (this.isBundleForm()) {
      const bundleDocumentsDetails = this.getBundleDocumentsDetails();
      const questions = getCohesiveFieldQuestions(bundleDocumentsDetails);

      return questions;
    }

    return this.getSignatureConfig()?.activeQuestions || [];
  }

  private getUnansweredRequiredQuestions() {
    const responses = this.getSignatureAnswer();

    return this.getQuestions().filter(
      (question) =>
        !!question.Required &&
        !responses?.[question.UUID]?.Answer?.length &&
        !NonRequiredQuestionType.includes(question.Type as DYNAMIC_QUESTION_TYPE),
    ) as FormDocumentQuestionsType;
  }

  private requireQuestions(questions: FormDocumentQuestionsType) {
    const requiredConfig: RequiredQuestion[] = [];

    questions.forEach((question) => {
      requiredConfig.push({ questionUUID: question.UUID });
    });

    this.dispatch(
      updateDynamicRequiredQuestionEffect({
        requiredQuestion: requiredConfig,
      }),
    );
  }

  private async handleDocumentLoad(setLoader?, documentLink?, cb?) {
    const screen = this.getScreen();
    const link = documentLink || this.getFormMeta()?.link;

    setLoader?.(true);

    if (
      screen === FORM_PROCESS_SCREEN.Sign &&
      (this.authData || this.bundleData || this.anonData)
    ) {
      if (this.authData || this.bundleData) {
        this.dispatch(getSignatureAuthEffect());
      } else if (this.anonData) {
        const { token } = this.anonData;
        this.dispatch(getSignatureAnonEffect({ token }));
      }
    }

    if (link && screen !== FORM_PROCESS_SCREEN.OnlyPreview) {
      let templateRequestPayload = {};

      if (this.templateData) {
        const { allTemplates, templateId, editMode } = this.templateData;

        templateRequestPayload = {
          templateId: allTemplates ? allTemplates[0].Id : templateId,
          ...(editMode && { editMode }),
        };
      }

      await PdfCache.loadDocuments(
        link,
        this.anonData
          ? {
              token: this.anonData.token,
              type: FORM_TOKEN[this.anonData.type],
            }
          : this.templateData
          ? templateRequestPayload
          : undefined,
      );

      if (setLoader) {
        setTimeout(() => {
          setLoader?.(false);
        }, 0);
      }
    } else {
      setLoader?.(false);
    }

    this.setFormLoading(false);
    cb?.();
  }

  async proceedtoSignMode(setLoader, cb) {
    await this.progressForm({}, setLoader);
    await this.getDocument(setLoader, cb);
  }

  async getCohesiveDocuments(allDocuments, cb) {
    let bundleDocumentsDetails: any[] = [];

    bundleDocumentsDetails = await Promise.allSettled(
      allDocuments.map((item) => {
        return new Promise((res, rej) => {
          const callback = (error, response) => {
            if (!error) {
              if (!this.templateData?.templateForm) {
                response.data.value.PublicId = item.PublicId;
              }
              res(response);
            } else {
              rej(error);
            }
          };

          if (this.bundleData || this.authData) {
            const { formProcessPublicId } = this.bundleData ||
              this.authData || { formProcessPublicId: '' };

            this.dispatch(
              getDynamicFormDocumentBundleEffect(
                {
                  formProcessPublicId,
                  formDocumentPublicId: item.PublicId,
                },
                callback,
              ),
            );
          } else if (this.templateData) {
            this.dispatch(getDynamicTemplateDocumentEffect({ templateId: item.Id }, callback));
          }
        });
      }),
    );

    if (bundleDocumentsDetails.length === allDocuments.length) {
      const documentsDetails = bundleDocumentsDetails.map(({ value: { data } }) => {
        if (this.templateData?.templateForm) {
          return { templateId: data.value.formTemplate.Id, ...data.value.formTemplate };
        }

        return data.value;
      });

      documentsDetails.forEach((data) => {
        data.questions = prepareQuestionsWithStrikeThrough(data);
      });

      this.dispatch(
        updateDynamicFormEffect({
          bundleDocumentsDetails: documentsDetails,
        }),
      );

      cb();
    }
  }

  async fetchAllDocuments(fetchCB?) {
    if (this.bundleData) {
      const dynamicDocuments = this.getDynamicDocuments();
      const { allDocuments: bundleDocuments } = this.bundleData;

      const allDocuments = dynamicDocuments?.length ? dynamicDocuments : bundleDocuments;

      if (allDocuments?.length) {
        await this.getCohesiveDocuments(allDocuments, fetchCB);
      } else {
        fetchCB?.();
      }
    }
  }

  async getAnonDocument(callBack) {
    this.dispatch(
      getDynamicFormDocumentAnonEffect(this.anonData, (error, response) => {
        if (!error) {
          const anonymousDocuments = response.data.value;

          if (anonymousDocuments?.length) {
            const result: any = {
              data: { value: anonymousDocuments[0] },
            };

            this.dispatch(updateDynamicFormRequestEffect(result));

            if (anonymousDocuments.length > 1) {
              setTimeout(() => {
                this.dispatch(
                  updateDynamicFormEffect({
                    bundleDocumentsDetails: anonymousDocuments,
                  }),
                );

                callBack(null);
              }, 300);
            } else {
              callBack(null);
            }
          } else {
            callBack(error, response);
          }
        }
      }),
    );
  }

  async getAuthDocument(callBack) {
    this.dispatch(
      getDynamicFormDocumentAuthEffect(this.authData, (error, response) => {
        if (!error) {
          const { documents } = response.data.value;

          if (documents?.length > 1) {
            this.getCohesiveDocuments(documents, callBack);
          } else {
            if (this.getBundleDocumentsDetails().length === 1) {
              this.dispatch(
                updateDynamicFormEffect({
                  bundleDocumentsDetails: [response.data.value],
                }),
              );
            }

            callBack(null);
          }
        } else {
          callBack(error, response);
        }
      }),
    );
  }

  private moveToTemplateSuccess() {
    this.history?.push({
      pathname: routes.workshopTemplateFormsSuccess,
      state: {
        templateSuccess: true,
        editMode: this.templateData?.updateTemplate,
        templateBundle: this.templateData?.templateBundle,
      },
    });
  }

  private getBundleDocument(callBack) {
    this.fetchAllDocuments(() => {
      const documentDetails = this.getBundleDocumentsDetails();

      this.dispatch(updateDynamicFormRequestEffect({ data: { value: documentDetails[0] } } as any));
      callBack();
    });
  }

  async getDocument(setLoader?, cb?) {
    return new Promise((res, rej) => {
      const callBack = async (err) => {
        if (!err) {
          this.handleDocumentLoad(setLoader, '', () => {
            res(null);
            cb?.();
          });
        } else {
          rej(err);
        }
      };

      if (this.authData) {
        this.getAuthDocument(callBack);
      } else if (this.anonData) {
        this.getAnonDocument(callBack);
      } else if (this.templateData) {
        this.moveToTemplateSuccess();
      } else if (this.bundleData) {
        this.getBundleDocument(callBack);
      }
    });
  }

  async getTemplateDocument(setLoader?, cb?) {
    return new Promise((res, rej) => {
      const callBack = async (err, response?) => {
        if (!err) {
          this.handleDocumentLoad(setLoader, this.getFormMeta().link, () => {
            res(null);
            cb?.();
          });
        } else {
          rej(err);
        }
      };

      if (this.templateData) {
        const { allTemplates, templateId, templateBundle } = this.templateData;

        if (templateBundle) {
          const dynamicDocuments = this.getDynamicDocuments();

          const allDocumentTemplates = dynamicDocuments?.length ? dynamicDocuments : allTemplates;

          this.getCohesiveDocuments(allDocumentTemplates, callBack);
        } else {
          this.dispatch(
            getDynamicTemplateDocumentEffect(
              { templateId: templateId || allTemplates?.[0]?.Id },
              (error, response) => {
                if (!error) {
                  const { formTemplate } = response.data.value;

                  if (formTemplate.bundleTemplates?.length > 1) {
                    const sortedTemplates = sortDocuments(formTemplate.bundleTemplates, 'Order');
                    this.getCohesiveDocuments(sortedTemplates, callBack);
                  } else {
                    if (this.getBundleDocumentsDetails().length === 1) {
                      this.dispatch(
                        updateDynamicFormEffect({
                          bundleDocumentsDetails: [response.data.value],
                        }),
                      );
                    }

                    callBack(null);
                  }
                } else {
                  callBack(error, response);
                }
              },
            ),
          );
        }
      } else {
        rej('invalid identification');
      }
    });
  }

  private redirectToFormsPage(useSearch = false) {
    if (this.isClientUser()) {
      this.history.push('/');
    } else if (this.authData || this.bundleData) {
      const { formProcessPublicId } = this.authData ||
        this.bundleData || { formProcessPublicId: '' };

      this.history?.push({
        pathname: routes.workshopForms,
        ...(useSearch ? { search: `?from=signature&formId=${formProcessPublicId}` } : {}),
      });
    } else {
      this.history?.push({
        pathname: routes.workshopForms,
      });
    }
  }

  private setFormLoading(isLoading: boolean) {
    this.dispatch(updateDynamicFormEffect({ isLoading }));
  }

  private setFormScreen(screen: FormProcessScreenType) {
    this.dispatch(updateDynamicFormEffect({ screen }));
  }

  private hideFooter(hideFooter) {
    this.dispatch(updateDynamicFormEffect({ hideFooter }));
  }

  private getPageItem(dataId: string) {
    return this.containerRef?.current?.querySelector(`[itemid="${dataId}"]`);
  }

  updateLocationState(updatedState = {}) {
    this.history.replace(this.history.location.pathname, {
      ...this.history.location.state,
      ...updatedState,
    });
  }

  progressForm = (params = {}, setLoader?) => {
    return new Promise((res, rej) => {
      setLoader?.(true);

      const callBack = (err, resp?) => {
        this.setFormLoading(false);
        setLoader?.(false);
        if (!err) {
          res(true);
        }
        res(false);
      };

      if (this.authData) {
        this.dispatch(
          progressDynamicFormEffect(
            {
              ...this.authData,
              ...params,
            },
            callBack,
          ),
        );
      } else if (this.anonData) {
        this.dispatch(
          progressSmartFormAnonEffect(
            {
              Token: this.anonData.token,
              type: this.anonData.type,
              ...params,
            },
            callBack,
          ),
        );
      } else if (this.templateData) {
        const { updateTemplate } = this.templateData;

        if (updateTemplate || this.isBundleTemplate()) {
          callBack(false, {});
        } else {
          const meta = this.getFormMeta();
          const dynamicDocuments = this.getDynamicDocuments();

          this.dispatch(
            patchFormVersionStatusEffect(
              {
                formId: dynamicDocuments.length ? dynamicDocuments[0].FormId : meta.formId,
                formVersionId: dynamicDocuments.length
                  ? dynamicDocuments[0].FormVersionId
                  : meta.formVersionId,
                status: FORM_STATUS_TYPE.Active,
              },
              (error, response) => {
                if (!error) {
                  callBack(error, response);
                } else {
                  this.setFormLoading(false);
                  setLoader?.(false);
                  rej(error);
                }
              },
            ),
          );
        }
      } else if (this.bundleData) {
        const { formProcessPublicId } = this.bundleData;
        this.dispatch(
          progressDynamicFormEffect(
            {
              formProcessPublicId,
              ...params,
            },
            callBack,
          ),
        );
      } else {
        rej('invalid identification');
      }
    });
  };

  handleTemplateBundleSave(setLoader?) {
    return new Promise((res, rej) => {
      if (this.isBundleForm()) {
        const { bundleName } = this.bundleData || {};
        const { categories, publishToTeam, templateForms } = this.saveAsTemplateData || {};
        const formProcessPublicId = this.getFormProcessPublicId();
        const formName = this.getFormMeta().documentName || '';

        this.dispatch(
          saveBundleAsTemplateEffect(
            {
              formProcessPublicId,
              publishToTeam: publishToTeam || false,
              categories,
              forms: templateForms?.map(({ Order, ...rest }) => rest) || [],
              bundleName: bundleName || formName || '',
            },
            (error, response) => {
              if (!error) {
                setLoader?.(false);
                this.setFormLoading(false);

                res(true);

                if (this.bundleData?.saveAsTemplate || this.saveAsTemplateData?.saveAsTemplate) {
                  if (this.bundleData?.saveAsTemplate) {
                    this.bundleData.saveAsTemplate = false;
                  }

                  if (this.saveAsTemplateData?.saveAsTemplate) {
                    this.saveAsTemplateData = undefined;
                  }

                  this.updateLocationState({ saveAsTemplate: false });
                }
              } else {
                rej(error);
              }
            },
          ),
        );
      }
    });
  }

  prepareStrikeThroughDetails = (questions) => {
    const strikeThrough: any = {};

    questions.forEach((item) => {
      const { pageNumber, x, y, width, strokeWidth, strokeColor } = item;

      const details = {
        id: item.UUID,
        start: { x, y },
        end: { x: x + width, y },
        strokeWidth,
        color: strokeColor,
      };

      if (strikeThrough[pageNumber]) {
        strikeThrough[pageNumber].push(details);
      } else {
        strikeThrough[pageNumber] = [details];
      }
    });

    return strikeThrough;
  };

  async executeBundlePromises(res, rej, promises, saveAsTemplate, templateForms) {
    if (promises.length) {
      if (saveAsTemplate) {
        if (this.saveAsTemplateData) {
          this.saveAsTemplateData.templateForms = templateForms;
        }
      }

      try {
        await Promise.all(promises);
        res(true);
      } catch (error) {
        rej(error);
      }
    } else {
      res(true);
    }
  }

  getStrikeThoughEffect = (
    strikeThrough: any,
    {
      templateItemId,
      formProcessPublicId,
      formDocumentPublicId,
    }: { templateItemId?: number; formProcessPublicId?: string; formDocumentPublicId?: string },
    cb?: any,
  ) => {
    if (this.templateData) {
      const { templateId: Id, templateBundle } = this.templateData;
      const templateId = templateBundle ? templateItemId : Id;

      return updateTemplateStrikeThroughEffect({ templateId, strikeThrough }, cb);
    } else if (this.isBundleForm()) {
      return updateStrikeThroughAuthEffect(
        { formProcessPublicId, formDocumentPublicId, strikeThrough },
        cb,
      );
    } else {
      return updateStrikeThroughAuthEffect({ ...this.authData, strikeThrough }, cb);
    }
  };

  private updateStrikeThroughPromise = (strikeThrough, processPublicId, item) => {
    return new Promise((resolve, reject) => {
      const strikeThroughEffect = this.getStrikeThoughEffect(
        strikeThrough,
        {
          templateItemId: item.Id,
          formProcessPublicId: processPublicId,
          formDocumentPublicId: item.PublicId,
        },
        (err, resp) => {
          if (err) {
            reject(err);
          } else {
            resolve(resp);
          }
        },
      );

      this.dispatch(strikeThroughEffect);
    });
  };

  private updateTemplateQuestion = (questions, templateId, callback) => {
    this.dispatch(
      updateTemplateFormDocumentQuestionEffect(
        {
          questions,
          templateId,
        },
        callback,
      ),
    );
  };

  private updateTemplateQuestionPromise = (questions, templateId) => {
    return new Promise((resolve, reject) => {
      this.updateTemplateQuestion(questions, templateId, (err, resp) => {
        !err && resolve(resp);
        err && reject(err);
      });
    });
  };

  private updateQuestionPromise(questions, processPublicId, documentPublicId) {
    return new Promise((resolve, reject) => {
      this.dispatch(
        updateDynamicFormDocumentQuestionEffect(
          {
            questions,
            formProcessPublicId: processPublicId,
            formDocumentPublicId: documentPublicId,
          },
          (err, resp) => {
            if (err) {
              reject(err);
            } else {
              resolve(resp);
            }
          },
        ),
      );
    });
  }

  updateEditorConfig = (cfg) => {
    this.dispatch(updateDynamicFormEditorConfigEffect(cfg));
  };

  handleEditorSave = async (setDisabled?, draftMode = false, callback?) => {
    const { lastSave, lastUpdated, selectedRole, selectedField } = this.getEditorConfig();

    if (lastUpdated) {
      if (!lastSave || (lastSave && moment(lastSave).isBefore(moment(lastUpdated)))) {
        setDisabled?.(true);
        const success = await this.handlePDFEditorQuestionSave();

        if (success) {
          if (this.isTemplateForm() && draftMode) {
            await this.progressForm({});
            callback?.();
          } else if (draftMode) {
            this.handleSaveFormAsTemplate(setDisabled);
            callback?.();
          } else {
            if (this.templateData) {
              await this.getTemplateDocument();

              if (!this.templateData.editMode) {
                this.templateData.editMode = true;
                this.updateLocationState({ editMode: true });
              }
            } else await this.getDocument();

            this.handleSaveFormAsTemplate(setDisabled);

            this.updateEditorConfig({
              selectedRole,
              selectedField,
            });
            setDisabled?.(false);
          }
        }
      }
    } else {
      callback?.();
    }
  };

  private prepareTemplateFormDetails(roleIds, questions, { PublicId, Order }) {
    const editorConfig = this.getEditorConfig();
    let templateRoleIds = roleIds.map((roleId) => ({ Id: +roleId }));

    const details = {
      documentPublicId: PublicId,
      templateRoleIds,
      questions: questions.length
        ? modifyQuestionsForTemplate(questions, editorConfig.formRoles)
        : [],
      Order,
    };

    return details;
  }

  async handleSaveFormAsTemplate(setDisabled) {
    if (this.isBundleForm() && this.saveAsTemplateData?.saveAsTemplate) {
      await this.handleTemplateBundleSave(setDisabled);
    }

    if (this.saveAsTemplateData?.saveAsTemplate) {
      const { formProcessPublicId, formDocumentPublicId } = this.authData as AuthDataType;
      const { templateRoleIds: roleIds, questions, ...restTemplateData } = this.saveAsTemplateData;

      let templateRoleIds = roleIds.map((roleId) => ({ Id: +roleId }));

      this.dispatch(
        progressSaveAsTemplateFormEffect(
          {
            formProcessPublicId,
            formDocumentPublicId,
            templateRoleIds,
            questions: questions || [],
            ...restTemplateData,
          },
          (error, response) => {
            if (!error) {
              this.saveAsTemplateData = undefined;

              this.updateLocationState({ saveAsTemplate: false });

              setDisabled?.(false);
              this.setFormLoading(false);
            }
          },
        ),
      );
    }
  }

  async handleBundleQuestionsSave(res, rej) {
    if (this.isBundleForm() || this.isBundleTemplate()) {
      const { dynamicFormDocuments: bundleDocuments } = this.getFormData();
      const promises: any = [];
      const strikeThroughPromises: any = [];
      let templateForms: any = [];

      const { saveAsTemplate = false, templateRoleIds: roleIds = [] } =
        this.saveAsTemplateData || {};

      const { formProcessPublicId = '' } = this.bundleData || this.authData || {};

      const filteredDocuments: any = bundleDocuments?.filter(({ questions }) => questions);

      if (this.saveAsTemplateData?.saveAsTemplate) {
        const emptyQuestionDocuments: any = bundleDocuments?.filter(({ questions }) => !questions);
        emptyQuestionDocuments?.forEach((item) => {
          const details = this.prepareTemplateFormDetails(roleIds, [], item);
          templateForms.push(details);
        });

        this.saveAsTemplateData.templateForms = templateForms;
      }

      if (!filteredDocuments || !filteredDocuments?.length) {
        if (templateForms.length && this.saveAsTemplateData?.saveAsTemplate) {
          this.saveAsTemplateData.templateForms = sortDocuments(templateForms, 'Order');
        }
        res(true);
      }

      filteredDocuments?.forEach((item) => {
        const { questions, strikeThroughQuestions } = prepareQuestions(item.questions);
        if (strikeThroughQuestions.length && (formProcessPublicId || this.isBundleTemplate())) {
          const nonDeletedQuestions = strikeThroughQuestions.filter(({ isDeleted }) => !isDeleted);

          const strikeThrough = this.prepareStrikeThroughDetails(nonDeletedQuestions);

          strikeThroughPromises.push(
            this.updateStrikeThroughPromise(strikeThrough, formProcessPublicId, item),
          );
        }

        if (questions.length) {
          let questionPromise;
          if (this.templateData) {
            questionPromise = this.updateTemplateQuestionPromise(questions, item.Id);
          } else {
            questionPromise = this.updateQuestionPromise(
              questions,
              formProcessPublicId,
              item.PublicId,
            );
          }

          promises.push(questionPromise);
        }

        if (saveAsTemplate && !this.templateData) {
          const details = this.prepareTemplateFormDetails(roleIds, questions, item);

          templateForms.push(details);
        }
      });

      if (saveAsTemplate && !this.templateData) {
        templateForms = sortDocuments(templateForms, 'Order');
      }

      if (!promises.length && !strikeThroughPromises.length) res(true);

      if (strikeThroughPromises.length) {
        try {
          await Promise.all(strikeThroughPromises);
          this.executeBundlePromises(res, rej, promises, saveAsTemplate, templateForms);
        } catch (err) {
          rej(err);
        }
      } else {
        this.executeBundlePromises(res, rej, promises, saveAsTemplate, templateForms);
      }
    } else {
      res(true);
    }
  }

  handlePDFEditorQuestionSave() {
    return new Promise((res, rej) => {
      if (this.isBundleForm() || this.isBundleTemplate()) {
        this.handleBundleQuestionsSave(res, rej);
      } else {
        const editorConfig = this.getEditorConfig();

        if (editorConfig?.questions) {
          const allQuestions = editorConfig?.questions;

          const { questions, strikeThroughQuestions } = prepareQuestions(allQuestions);

          if (strikeThroughQuestions.length) {
            const nonDeletedQuestions = strikeThroughQuestions.filter(
              ({ isDeleted }) => !isDeleted,
            );

            const strikeThrough = this.prepareStrikeThroughDetails(nonDeletedQuestions);

            this.dispatch(this.getStrikeThoughEffect(strikeThrough, {}));
          }

          const callback = (err) => {
            if (err) {
              res(false);
            } else {
              res(true);
            }
          };

          if (questions?.length) {
            if (this.templateData) {
              this.updateTemplateQuestion(questions, this.templateData.templateId, callback);
            } else {
              if (this.saveAsTemplateData?.saveAsTemplate) {
                const formRoles = editorConfig?.formRoles;

                this.saveAsTemplateData.questions = questions.map(
                  ({ UUID, roleName, ...restItem }) => ({
                    ...restItem,
                    UUID: uuidv4(),
                    roleName: formRoles
                      .find(({ roleId }) => roleId === roleName)
                      ?.formRole?.toString(),
                  }),
                );
              }

              this.dispatch(
                updateDynamicFormDocumentQuestionEffect(
                  {
                    questions,
                    ...this.authData,
                  },
                  callback,
                ),
              );
            }
          } else {
            if (
              this.saveAsTemplateData?.saveAsTemplate &&
              this.saveAsTemplateData?.questions?.length
            ) {
              this.saveAsTemplateData.questions = [];
            }
            res(true);
          }
        } else {
          res(true);
        }
      }
    });
  }

  async handlePDFEditorContinue(setDisabled?) {
    setDisabled?.(true);

    this.setFormLoading(true);

    const success = await this.handlePDFEditorQuestionSave();

    if (success) {
      await this.progressForm({}, setDisabled);
      this.setFormLoading(true);

      await this.getDocument(setDisabled);
      this.handleSaveFormAsTemplate(setDisabled);
    } else {
      setDisabled?.(false);
      this.setFormLoading(false);
    }
  }

  async signFormDocument(setLoader?) {
    const signatureConfig = this.getSignatureConfig();

    this.setFormLoading(true);
    await this.progressForm();

    if (!this.anonData) {
      successNotification({
        message: 'Form has been signed.',
        placement: 'top',
      });
    }

    if (signatureConfig?.closeOnProgress) {
      if (this.authData || this.bundleData) {
        this.redirectToFormsPage(true);
      } else if (this.anonData) {
        this.history.push({
          pathname: routes.workshopDynamicFormSuccess,
          state: { dynamicFormData: this.getFormData() },
        });
      }
    } else if (this.anonData) {
      this.history.push({
        pathname: routes.workshopDynamicFormSuccess,
        state: { dynamicFormData: this.getFormData() },
      });
    }
    this.hideFooter(true);
    this.setFormScreen(FORM_PROCESS_SCREEN.SignPreview);
    this.setFormLoading(false);
  }

  private focusOnQuestion(questionUUID: string) {
    if (questionUUID) {
      const question = this.getPageItem(`question-${questionUUID}`);
      if (question) {
        question.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  async handleSignContinue(setLoader?) {
    const questions = this.getUnansweredRequiredQuestions();

    if (questions?.length) {
      this.focusOnQuestion(questions[0]?.UUID);
      setTimeout(() => this.requireQuestions(questions), 600);
    } else {
      const signatureConfig = this.getSignatureConfig();

      if (signatureConfig.showSendDocumentScreen) {
        this.setFormScreen(FORM_PROCESS_SCREEN.SendDocument);
      } else {
        setLoader?.(true);
        await this.signFormDocument(setLoader);
      }
    }
  }

  async handleSendDocument(setLoader?) {
    setLoader?.(true);

    this.setFormLoading(true);
    const message = (this.getPageItem('send-form-message') as HTMLTextAreaElement)?.value?.trim();

    await this.progressForm(message ? { message } : {});

    successNotification({
      message: 'Form has been sent.',
      placement: 'top',
    });

    this.setFormLoading(false);
    this.redirectToFormsPage(true);
    setLoader?.(false);
  }

  private declineAnonDocument = (message, setLoader?) => {
    return new Promise((resolve, reject) => {
      setLoader?.(true);
      this.dispatch(
        declineSignatureAnonEffect({ ...this.anonData, message }, (err) => {
          if (!err) {
            resolve(true);
          } else {
            setLoader?.(false);
            reject(err);
          }
        }),
      );
    });
  };

  private declineAuthDocument = (message, setLoader?) => {
    return new Promise((resolve, reject) => {
      setLoader?.(true);

      this.dispatch(
        declineSignatureAuthEffect(
          {
            ...(this.authData || {
              formProcessPublicId: this.bundleData?.formProcessPublicId,
              formDocumentPublicId: this.getActiveDocument()?.PublicId,
            }),
            message,
          },
          (err) => {
            if (!err) {
              resolve(true);
            } else {
              setLoader?.(false);
              reject(err);
            }
          },
        ),
      );
    });
  };

  async handleDeclineDocument(message, setLoader?, modalCB?) {
    if (this.authData || this.bundleData) {
      const declinedAuthSuccess = await this.declineAuthDocument(message, setLoader);

      if (declinedAuthSuccess) {
        successNotification({
          message: 'Form has been declined.',
          placement: 'top',
        });

        this.redirectToFormsPage(true);
      }
    } else if (this.anonData) {
      const declinedAnonsuccess = await this.declineAnonDocument(message, setLoader);
      if (declinedAnonsuccess) {
        setLoader?.(false);
        modalCB?.();
        this.hideFooter(true);
        this.setFormScreen(FORM_PROCESS_SCREEN.DeclineSign);
      }
    }
  }

  getAuthData = () => {
    if (!this.authData) {
      throw new Error('Auth data does not exist');
    }
    return this.authData;
  };

  getBundleData = () => {
    if (!this.bundleData) {
      throw new Error('Bundle data does not exist');
    }
    return this.bundleData;
  };

  handleFormExit() {
    if (this.redirectionLink) {
      this.history.replace(this.redirectionLink);
    } else {
      this.redirectToFormsPage();
    }
  }

  private handleSendDocumentGoBack = () => {
    this.setFormLoading(true);
    setTimeout(() => {
      this.setFormScreen(FORM_PROCESS_SCREEN.Sign);
      this.setFormLoading(false);
    }, 0);
  };

  private handleSignGoBack = () => {
    if (this.authData || this.bundleData) {
      const signatureConfig = this.getSignatureConfig();

      this.setFormLoading(true);

      setTimeout(() => {
        this.dispatch(
          updateDynamicFormEffect({
            signatureConfig: { ...signatureConfig, showWizard: false },
          }),
        );

        this.dispatch(
          regressFormDocumentEffect(
            {
              ...(this.authData || {
                formProcessPublicId: this.bundleData?.formProcessPublicId,
                formDocumentPublicId: this.getActiveDocument()?.PublicId,
              }),
            },
            async () => {
              await this.getDocument(undefined, undefined);
              this.setFormLoading(false);
            },
          ),
        );
      }, 0);
    }
  };

  gotBackToPreviousScreen = () => {
    const screen = this.getScreen();
    switch (screen) {
      case FORM_PROCESS_SCREEN.SendDocument:
        this.handleSendDocumentGoBack();
        break;
      case FORM_PROCESS_SCREEN.Sign:
        this.handleSignGoBack();
        break;

      default:
        break;
    }
  };

  handleUnlockForm = () => {
    if (this.authData) {
      this.setFormLoading(true);

      this.dispatch(
        unlockDynamicFormDocumentAuthEffect(
          {
            ...this.authData,
          },
          (err, resp) => {
            if (!err) {
              const { formProcessPublicId, formDocumentPublicId } = resp.data.value;
              const locationData = {
                formProcessPublicId,
                formDocumentPublicId,
              };

              this.history.push(link.toWorkshopDynamicFormDocument(locationData));

              window.location.reload();
            }
            this.setFormLoading(false);
          },
        ),
      );
    } else if (this.bundleData) {
      this.setFormLoading(true);

      const { formProcessPublicId } = this.bundleData;
      const { PublicId: activeDocumentId } = this.getActiveDocument();

      const bundleDocumentsDetails = this.getBundleDocumentsDetails();
      const document = bundleDocumentsDetails.find(({ showUnlock }) => showUnlock);

      this.dispatch(
        unlockDynamicFormDocumentAuthEffect(
          {
            formProcessPublicId,
            formDocumentPublicId: document ? document.PublicId : activeDocumentId,
          },
          (err, unlockResponse) => {
            if (!err) {
              const { formProcessPublicId, formDocumentPublicId } = unlockResponse.data.value;
              const publicIds = {
                formProcessPublicId,
                formDocumentPublicId,
              };

              this.dispatch(
                getDynamicFormDocumentAuthEffect(publicIds, (error, authResponse) => {
                  if (!error) {
                    const { documents } = authResponse.data.value;
                    const dynamicDocuments = sortDocuments(documents, 'Order');
                    this.updateLocationState({ allDocuments: dynamicDocuments });
                    window.location.reload();
                  }
                }),
              );
            }
          },
        ),
      );
    }
  };

  handleContinue(setLoader?) {
    const screen = this.getScreen();

    switch (screen) {
      case FORM_PROCESS_SCREEN.PDFEditor:
        this.handlePDFEditorContinue(setLoader);
        break;
      case FORM_PROCESS_SCREEN.Sign:
        this.handleSignContinue(setLoader);
        break;
      case FORM_PROCESS_SCREEN.SendDocument:
        this.handleSendDocument(setLoader);
        break;

      default:
        break;
    }
  }

  getQuestionFillEffect(updatedResponse, documentIndex?) {
    if (this.isBundleForm() && (this.bundleData || this.authData)) {
      const { formProcessPublicId } = this.bundleData ||
        this.authData || { formProcessPublicId: '' };

      const { PublicId } = this.getActiveDocument();
      const bundleDocumentsDetails = this.getBundleDocumentsDetails();

      const isCohesiveFlow = bundleDocumentsDetails.length > 0 && documentIndex >= 0;

      if (formProcessPublicId) {
        return fillSmartFormAuthEffect({
          formProcessPublicId,
          formDocumentPublicId: isCohesiveFlow
            ? bundleDocumentsDetails[documentIndex].PublicId
            : PublicId,
          ...updatedResponse,
        });
      }
    } else if (this.authData) {
      return fillSmartFormAuthEffect({
        ...this.authData,
        ...updatedResponse,
      });
    } else if (this.anonData) {
      return fillSmartFormAnonEffect({
        Token: this.anonData.token,
        type: this.anonData.type,
        ...updatedResponse,
      });
    }
  }

  handleQuestionResponse(
    questionUUID,
    answer,
    documentIndex,
    fontSize = 12,
    isCheckboxAnswer = false,
  ) {
    const answers = this.getSignatureAnswer();

    if (answers?.[questionUUID]?.Answer != null && !isCheckboxAnswer) {
      if (answers[questionUUID].Answer === answer) return;
    }

    const updatedResponse = {
      UUID: questionUUID,
      answer: answer,
      fontSize,
    };

    this.dispatch(updateDynamicFormResponseEffect({ ...updatedResponse, documentIndex }));

    const questionFillEffect: any = this.getQuestionFillEffect(updatedResponse, documentIndex);

    this.dispatch(questionFillEffect);
  }

  handleSaveSignature(signature, cb?) {
    if (this.authData || this.bundleData) {
      this.dispatch(
        saveSignatureAuthEffect(signature, (err) => {
          if (!err) {
            this.dispatch(getSignatureAuthEffect(cb));
          }
        }),
      );
    } else if (this.anonData) {
      const { token } = this.anonData;
      this.dispatch(
        saveSignatureAnonEffect({ token, ...signature }, (err) => {
          if (!err) {
            this.dispatch(getSignatureAnonEffect({ token }, cb));
          }
        }),
      );
    }
  }

  handleAgree(setAgreeBtnLoading?) {
    setAgreeBtnLoading?.(true);

    const callBack = async (err) => {
      if (!err) {
        await this.getDocument();
      }
      this.authData;
      setAgreeBtnLoading?.(false);
    };

    if (this.authData) {
      this.dispatch(acceptTermsAndConditionsAuthEffect({ ...this.authData }, callBack));
    } else if (this.anonData) {
      this.dispatch(
        acceptTermsAndConditionsAnonEffect(
          { Token: this.anonData.token, type: this.anonData.type },
          callBack,
        ),
      );
    } else if (this.bundleData) {
      this.dispatch(acceptTermsAndConditionsAuthEffect({ ...this.bundleData }, callBack));
    }
  }

  getPageContainerRef() {
    return this.pageContainerRef;
  }

  getPageWrapper() {
    return this.getPageItem('main-page-content');
  }

  getPageQuestion(questionUUID: string) {
    return this.getPageItem(`question-${questionUUID}`);
  }
}

export const dynamicManager = new DynamicManager();
