import { routes } from 'settings/navigation/routes';
import {
  CLIENT,
  AGENT,
  THIRD_PARTY,
  BROKERAGE_ADMIN,
  SUPER_USER,
  TEAM_OWNER,
  TEAM_ADMIN,
} from 'settings/constants/roles';

import { getLinksByRoles } from './common';

export const headerLinks = {
  [CLIENT]: [
    { link: routes.clientDashboard, title: 'Dashboard' },
    { link: routes.feed, title: 'Pinpoint' },
    { link: routes.services, title: 'Concierge' },
    // { link: routes.transactions, title: 'Workshop' },
    { link: routes.vault, title: 'Vault' },
    // { link: routes.intel, title: 'Intel' },
  ],
  [AGENT]: [
    { link: routes.index, title: 'Pulse' },
    { link: routes.feed, title: 'Pinpoint' },
    { link: routes.services, title: 'Concierge' },
    { link: routes.transactions, title: 'Workshop' },
    // { link: routes.radar, title: 'Radar' },
    { link: routes.clarity, title: 'Clarity' },
  ],
  [THIRD_PARTY]: [
    { link: routes.index, title: 'Dashboard' },
    {
      link: routes.servicesQuotes,
      title: 'Quotes',
      useLink: ({ user }) => user?.ThirdParty?.IsPartner,
    },
    { link: routes.transactions, title: 'Workshop' },
  ],
  [BROKERAGE_ADMIN]: [],
  [SUPER_USER]: [{ link: routes.dashboard, title: 'Dashboard' }],
  [TEAM_OWNER]: [{ link: routes.symphony, title: 'Symphony' }],
  [TEAM_ADMIN]: [{ link: routes.symphony, title: 'Symphony' }],
};

/**
 * @param  {} roles = []
 * @returns array of header links [{ link, title }]
 */
export const getHeaderLinksByRoles = getLinksByRoles(headerLinks);
