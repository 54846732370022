import { split, get, join, flatMap, upperCase, omit, toUpper, isArray } from 'lodash-es';
import moment from 'moment';

import { shortenNumber } from './numbers';
import { WorkshopType } from 'app-constants';
import { element } from 'prop-types';

export const formatUsd = (value, params = {}) => {
  if (!isNaN(value)) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: value % 1 === 0 || params?.maximumFractionDigits < 2 ? 0 : 2,
      ...params,
    }).format(value);
  }
  {
    return value;
  }
};

export const formatNumber = (value, params = {}) =>
  value && new Intl.NumberFormat('en-US', params).format(value);

export const formatArea = (value, params = {}) => {
  if (typeof value === 'string') {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      return value;
    }
    value = numericValue;
  }

  // Check if value is a valid number
  if (typeof value === 'number' && !isNaN(value)) {
    return new Intl.NumberFormat('en-US', params).format(value);
  }

  return ''; // or a default value
};

export const formatStringWithNumber = (
  value,
  splitBy,
  roundOff = true,
  maxDigitsAfterDecimal = 2,
) => {
  if (!isNaN(value)) {
    return (
      formatNumber(
        roundOff
          ? Math.round(Number(value)).toFixed(maxDigitsAfterDecimal)
          : Number(value).toFixed(maxDigitsAfterDecimal),
      ) || value.toString()
    );
  }
  const splittedArray = value?.split(splitBy);
  if (!splittedArray && !isArray(splittedArray)) {
    return value;
  }
  const regex = /^\d+(\.\d+)?$/;
  const formattedArray = splittedArray.map((element) => {
    if (regex.test(element)) {
      return formatNumber(
        roundOff ? Math.round(Number(element).toFixed(maxDigitsAfterDecimal)) : Number(element),
      )
        ? formatNumber(
            roundOff ? Math.round(Number(element).toFixed(maxDigitsAfterDecimal)) : Number(element),
          )
        : element;
    }
    return element;
  });
  return formattedArray?.join(splitBy);
};

export const formatUnwantedString = (originalString, stringToRemove) => {
  const regexPattern = new RegExp(stringToRemove, 'g');
  const matches = originalString.match(regexPattern);
  const numOccurrences = matches ? matches.length : 0;
  return numOccurrences > 0 ? originalString.replace(regexPattern, '') : originalString;
};

export const formatNameToAvatarLetters = (name) => {
  const words = split(name, /\s/);
  return `${words[0] ? words[0][0] : '?'}${words[1] ? words[1][0] : ''}`;
};

export const formatAreaUnits = (value) => {
  if (value === 'Square Feet') {
    return 'sq. ft.';
  }
  return value;
};

const getMinMaxString = (data, path, postfix = '', prefix = '') => {
  const min = get(data, [path, 'Min']);
  const max = get(data, [path, 'Max']);

  return [
    ...(min && max ? [`${prefix}${min} to ${prefix}${max} ${postfix}`] : []),
    ...(!(min && max) && max ? [`<${prefix}${max} ${postfix}`] : []),
    ...(!(min && max) && min ? [`${prefix}${min}+ ${postfix}`] : []),
  ];
};

export const getSearchCriteriaFormattedString = (searchCriteria) => {
  const priceMin = get(searchCriteria, 'PriceRange.Min');
  const priceMax = get(searchCriteria, 'PriceRange.Max');

  const showedFields = [
    { field: 'YearBuiltRange', postfix: 'years' },
    { field: 'SquareFeetRange', postfix: 'sqft' },
    { field: 'LotSizeRange', postfix: 'lot' },
    { field: 'NumBedroomsRange', postfix: 'beds' },
    { field: 'NumBathroomsRange', postfix: 'baths' },
  ];

  const res = [
    ...(priceMax && priceMin ? [`$${shortenNumber(priceMin)} to $${shortenNumber(priceMax)}`] : []),
    ...(!(priceMax && priceMin) && priceMin ? [`$${shortenNumber(priceMin)}+`] : []),
    ...(!(priceMax && priceMin) && priceMax ? [`<$${shortenNumber(priceMax)}`] : []),
    ...flatMap(showedFields, ({ field, postfix }) =>
      getMinMaxString(searchCriteria, field, postfix),
    ),
  ];

  const moreFilters = omit(searchCriteria, [
    'Locations',
    'PriceRange',
    ...showedFields.map(({ field }) => field),
  ]);

  const FriendlyNames = {
    Locations: 'locations',
    HomeType: 'home type',
    MaxDaysOnMarket: 'days on market',
    HOARange: 'HOA',
    MinParkingSpaces: 'parking',
    GarageOnly: 'garage',
    Status: 'status',
    AmenitiesPrefs: 'amenities',
    HomePrefs: 'home',
    NeighborhoodPrefs: 'neighborhood',
    OutdoorPrefs: 'outdoor',
    Parking: 'parking',
    CommutePrefs: 'commute',
    Schools: 'school',
    ViewPrefs: 'view preferences',
    InteriorStylesPrefs: 'interior styles',
  };

  const moreInfo = Object.entries(moreFilters).reduce((acc, [key, val]) => {
    const count = val?.length;
    if (count) {
      acc.push(`${count} ${FriendlyNames[key]}`);
    }
    return acc;
  }, []);

  const resLength = res.length;
  const remainingNeeded = 4 - resLength;
  const itemsToShow = remainingNeeded > 0 ? moreInfo.slice(0, remainingNeeded) : [];
  const remainingMoreNumber = moreInfo.length - itemsToShow.length;

  return {
    info: join(
      [...res, ...itemsToShow, ...(remainingMoreNumber ? [`+${remainingMoreNumber} more`] : [])],
      ', ',
    ),
    fullInfo: join([...res, ...moreInfo], ', '),
  };
};

export const formatMinutes = (value) => {
  if (!value) {
    return '-';
  }

  if (value > 60) {
    return moment
      .utc(moment.duration(value, 'minutes').asMilliseconds())
      .format('H [hours] [and] m [minutes]');
  }

  return `${value} minutes`;
};

export function getMessageTime(date, today = 'h:mm A', notToday = 'M/D/YY') {
  if (!date) {
    return '';
  }

  const momentDate = moment(date);
  return momentDate.isSame(new Date(), 'day')
    ? momentDate.format(today)
    : momentDate.format(notToday);
}

export function getWidgetMessageTime(date, today = 'h:mm A', notToday = 'MM/DD') {
  if (!date) {
    return '';
  }

  const momentDate = moment(date);
  return momentDate.isSame(new Date(), 'day')
    ? momentDate.format(today)
    : momentDate.format(notToday);
}

export function getDate(date, format = 'M/DD/YYYY') {
  return moment(date).format(format);
}

export function getDateTime(date, separator = 'at', format = 'M/D/YY') {
  if (!date) return '-';
  const momentDate = moment(date);
  const formattedDate = momentDate.format(format);
  const formattedTime = momentDate.format('h:mm A');

  return `${formattedDate} ${separator} ${formattedTime}`;
}

export function getOneLineAddress(address) {
  return join(
    [
      ...(address?.Line1 ? [address?.Line1] : []),
      ...(address?.Line2 ? [address?.Line2] : []),
      ...(address?.City ? [address?.City] : []),
      ...(address?.State ? [address?.State] : []),
      ...(address?.Zip ? [address?.Zip] : []),
    ],
    ', ',
  );
}

export function getPropertyAddress(address, noPrivate = false) {
  return join(
    [
      // eslint-disable-next-line no-nested-ternary
      ...(address?.Line1 ? [address?.Line1] : noPrivate ? [] : ['Private Address']),
      // ...(address?.City ? [address?.City] : []),
      // ...(address?.State ? [address?.State] : []),
    ],
    ', ',
  );
}

export const getType = (transaction) => {
  if (transaction?.IsProject) return WorkshopType.Project;
  return WorkshopType.Transaction;
};

export const getAddressOrProject = (transaction) => {
  if (transaction?.IsProject) return transaction?.Name;
  return getPropertyAddress(transaction?.Address);
};

export function getPropertyTwoLinesAddress(address, noPrivate = false) {
  return [
    join(
      [
        // eslint-disable-next-line no-nested-ternary
        ...(address?.Line1 ? [address?.Line1] : noPrivate ? [] : ['Private Address']),
        ...(address?.Line2 ? [address?.Line2] : []),
      ],
      ', ',
    ),
    join(
      [
        ...(address?.City ? [address?.City] : []),
        join(
          [...(address?.State ? [address?.State] : []), ...(address?.Zip ? [address?.Zip] : [])],
          ' ',
        ),
      ],
      ', ',
    ),
  ];
}

// INFO: This is written to add # in start of unit number replacing with ,
export const getPropertyAddressWithHash = ({ address, noPrivate = false }) => {
  const [line1, line2] = getPropertyTwoLinesAddress(address, noPrivate);
  const unitNum = line1?.split(', ')?.[1] ? `#${line1?.split(', ')?.[1]}` : '';

  return { Line1: `${line1?.split(', ')?.[0]} ${unitNum}`, Line2: line2 };
};

export function getShortedChatParticipantsNames(names = [], show = 2, noNames = 'No name') {
  if (!names?.length) {
    return noNames;
  }
  if (names.length < show) {
    return names[0];
  }

  const overflowCountNames = names.length - show;
  const showOverflowCount = names.length > show;

  const abbreviatedNames = names
    .slice(0, show)
    .map((name) => {
      const [firstPart, secondPart] = name.split(' ');

      const capitalizedFirstLetter = `${firstPart.charAt(0).toUpperCase()}${firstPart.slice(1)}`;
      const capitalizedSecondLetter = secondPart ? secondPart.charAt(0).toUpperCase() : '';

      return `${capitalizedFirstLetter} ${capitalizedSecondLetter}`;
    })
    .join(', ');

  return `${abbreviatedNames} ${showOverflowCount ? ` +${overflowCountNames} others` : ''}`;
}

export const convertNameToAvatarPlaceholder = (fullName) => {
  const [first, second] = split(fullName, ' ', 2);
  return upperCase(`${first?.[0] || ''}${second?.[0] || ''}`);
};

export function getDayFromDate(date) {
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const today = new Date(date);
  return weekday[today.getDay()];
}

export function getMonthFromDate(date) {
  return moment(date).month();
}

export function getYearFromDate(date) {
  return moment(date).year();
}

export function getTimeFromDate(dataD) {
  const data = new Date(dataD);
  let hrs = data.getHours();
  let mins = data.getMinutes();
  if (hrs <= 9) hrs = 0 + hrs;
  if (mins < 10) mins = 0 + mins;
  const postTime = hrs + ':' + mins;
  return postTime;
}

export function getTimeStampFromDate(date) {
  return moment(date).unix(date);
}

export function getLocaleDate(date) {
  const timezone = moment.tz.guess();
  const dateTime = moment(date);
  return dateTime.clone().tz(timezone);
}

export function getFormattedTime(date, timeZone = false) {
  const data = moment(date);
  let format = 'h:mm A';
  if (timeZone) format += ' (z)';
  return moment(data).format(format);
}

export function getDateDifferenceFromToday(date) {
  const today = moment(new Date());
  return today.diff(date, 'days');
}

export function getTimeDifference(EndTime, StartTime) {
  return moment(EndTime).diff(moment(StartTime));
}

export function getTimeFormatMonthName(date) {
  var monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  date = new Date(date);
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return monthNames[monthIndex] + ' ' + day + ', ' + year;
}

export function getTimeFormatMonthYear(date) {
  var monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  date = new Date(date);
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return monthNames[monthIndex] + ' ' + year;
}

export function getTimeZone(type) {
  const name = moment.tz.guess();
  return type === 'ZONE_NAME' ? name : moment.tz(name).zoneAbbr();
}

export function getMergedDateTime(date, time) {
  const aDate = moment(date);
  const atime = moment(time).format('HH:mm:ss').split(':');
  aDate.set({ hour: atime[0], minute: atime[1], second: atime[2] });
  return aDate;
}

export const subtractTimeZone = (dateTime, format) => {
  if (!dateTime) return dateTime;
  const date = new Date(dateTime);
  const seconds = date.getSeconds();
  const minutes = date.getMinutes();
  const hour = date.getHours();
  if (hour === 0 && minutes === 0 && seconds === 0) {
    return format ? moment(date).format(format) : date;
  }

  const updatedDate = moment(date).subtract(moment().utcOffset(), 'minutes').toISOString();
  return format ? moment(updatedDate).format(format) : updatedDate;
};

export function validateMinimumNotice(date, time, minimumNotice) {
  const currentTime = moment();
  const endTime = getMergedDateTime(date, time);
  const duration = moment.duration(endTime.diff(currentTime));
  const totalMinutes = Math.round(duration.asMinutes());
  const minimumMinutes = minimumNotice * 60;
  return totalMinutes > minimumMinutes ? false : true;
}

export function limitTextLength(text, textLength) {
  return text?.length > textLength ? text.substring(0, textLength - 3) + ' ...' : text;
}

export function seperateWords(str) {
  return str?.length > 4 ? str.replace(/[A-Z]/g, ' $&').trim() : str;
}

export function formatPrice(price) {
  const n = String(price),
    p = n.indexOf('.');
  return n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) => (p < 0 || i < p ? `${m},` : m));
}

export function formatPhoneNumber(phone) {
  var cleaned = ('' + phone).replace(/\D/g, '');
  cleaned = cleaned.length > 10 ? cleaned.slice(1) : cleaned;
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export function addressToSentenceCase(inputString) {
  if (inputString == null) return '';

  const sentenceCasedString = inputString
    .toLowerCase()
    .replace(/\b\w/g, (match) => match.toUpperCase());

  const directionSuffixes = ['NE', 'NW', 'SE', 'SW'];

  const modifiedAddress = sentenceCasedString.replace(
    new RegExp(`\\b(${directionSuffixes.join('|')})\\b`, 'gi'),
    (match) => match.toUpperCase(),
  );

  return modifiedAddress;
}

export function toDecimalPlaces(val, decimalPlaces) {
  if (val && !isNaN(Number(val))) {
    return Number(val).toFixed(decimalPlaces);
  } else return val;
}

export function sizeToDecimalPlaces(val, decimalPlaces) {
  if (val && val.split('X').length == 2) {
    const split = val.split('X');
    return `${
      Number.isInteger(Number(split[0])) ? split[0] : toDecimalPlaces(split[0], decimalPlaces)
    } X ${
      Number.isInteger(Number(split[1])) ? split[1] : toDecimalPlaces(split[1], decimalPlaces)
    }`;
  } else return val;
}

export function appendTypetoValue(type, val) {
  if (!val) return '';

  if (type === '$') {
    return `${formatUsd(val)}`;
  }
  if (val.includes('$')) {
    if (val.includes('-')) return val.split('-')?.[0];
    return `${formatUsd(val.replace(/\$/g, ''))}`;
  }
  if (
    type &&
    (type.toLowerCase() === 'pct' || type === '%' || type.toLowerCase() === 'percent') &&
    !val.includes('%')
  ) {
    return `${val} %`;
  }
  return val;
}
