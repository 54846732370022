import React, { useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useScreen } from 'hooks';
import DefaultHeader from './Header';
import { anyDriverOpenSelector } from './selectors';

import styles from './styles.module.scss';

const Drawer = (props) => {
  const {
    testid,
    isOpen,
    children,
    className,
    position,
    onClose,
    header,
    shouldDisableScroll,
    contentStyleClassName,
  } = props;
  const { opacityLayerClassName, hideOpacityLayer, zIndex } = props;
  const anyDrawerOpen = useSelector(anyDriverOpenSelector);

  const { screen } = useScreen();

  const disableScroll = useCallback(() => {
    document.body.style.overflow = 'hidden';
    const offsetPx = `${screen.scrollbarWidth}px`;

    if (position === Drawer.RIGHT) {
      document.body.style.marginRight = offsetPx;
    }

    if (position === Drawer.LEFT) {
      document.body.style.marginLeft = offsetPx;
    }
  }, [position, screen.scrollbarWidth]);

  const enableScroll = useCallback(() => {
    document.body.style.overflow = null;
    document.body.style.marginRight = null;
    document.body.style.marginLeft = null;
  }, []);

  useEffect(() => {
    if (!shouldDisableScroll) return null;

    if (isOpen) {
      disableScroll();
      setTimeout(disableScroll, 300);
    } else if (!anyDrawerOpen) {
      setTimeout(enableScroll, 300);
    }

    return () => enableScroll();
  }, [isOpen]); // eslint-disable-line

  return createPortal(
    <div
      testid={testid}
      style={{ zIndex: zIndex || undefined }}
      className={classNames(styles.drawer, { [styles.isOpen]: isOpen }, className)}
    >
      <div
        onClick={onClose}
        className={classNames(
          styles.opacityLayer,
          { [styles.hideOpacityLayer]: hideOpacityLayer },
          opacityLayerClassName,
        )}
      />
      <div className={classNames(styles.content, contentStyleClassName, styles[position])}>
        {isOpen && (
          <>
            {header || <DefaultHeader onClose={onClose} />}
            {children}
          </>
        )}
      </div>
    </div>,
    document.body,
  );
};

Drawer.RIGHT = 'right';
Drawer.LEFT = 'left';

Drawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  contentStyleClassName: PropTypes.string,
  opacityLayerClassName: PropTypes.string,
  position: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  refs: PropTypes.arrayOf(PropTypes.any),
  onClose: PropTypes.func,
  testid: PropTypes.string,
  shouldDisableScroll: PropTypes.bool,
  hideOpacityLayer: PropTypes.bool,
  zIndex: PropTypes.number,
};

Drawer.defaultProps = {
  className: '',
  contentStyleClassName: '',
  opacityLayerClassName: '',
  position: Drawer.RIGHT,
  refs: [],
  onClose: () => {},
  testid: undefined,
  shouldDisableScroll: true,
  hideOpacityLayer: false,
  zIndex: undefined,
};

export default Drawer;
