import { useEffect, useState } from 'react';
import classNames from 'classnames';
import prettyBytes from 'pretty-bytes';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Input } from 'components';
import {
  getTransactionParticipantSelector,
  getTransactionParticipantsFormattedList,
} from 'store/selectors/transaction';
import { truncateFilename } from 'helpers';
import { Row, Col, OptionType } from 'components-antd';
import { TagInput } from 'components/Transactions';
import { PermissionSelector, selectAllOption, selectJustMeOption } from '../../PermissionSelector';
import Icon from 'pages/Workshop/Transactions/TransactionCreate/Icons';
import { getTransactionPreFormSelector } from 'store/selectors/transactions';

import styles from './styles.module.scss';
import { hasEmoji } from 'pages/FormProcess/DynamicForm/Containers/DynamicViewer/components/FieldRenderer/Fields/helper';

interface ItemWithCategoryAndPermissionsProps {
  className: string;
  documentName: string;
  filename: string;
  size: number;
  category: string | object;
  customTag: string;
  onDelete: (...args) => void;
  onBlurTag: (...args) => void;
  onChangeTag: (...args) => void;
  onChangeReviewers: (...args) => void;
  onChangeDocumentName: (...args) => void;
}

const ItemWithCategoryAndPermissions = ({
  className,
  documentName,
  filename,
  size = 0,
  category,
  customTag,
  onDelete,
  onBlurTag,
  onChangeTag,
  onChangeReviewers,
  onChangeDocumentName,
}: ItemWithCategoryAndPermissionsProps) => {
  const location = useLocation();
  location.pathname.includes('/undercontract');
  const uploader = useSelector(getTransactionParticipantSelector);
  const participants = useSelector(getTransactionParticipantsFormattedList);
  const { preForm } = useSelector(getTransactionPreFormSelector);
  const [permissionOptions, setPermissionOptions] = useState<OptionType[]>([]);
  const [permittedUsers, setPermittedUsers] = useState<string[]>([]);

  useEffect(() => {
    const participantIds = permissionOptions.map((user) => user.key);
    setPermittedUsers([...participantIds, selectJustMeOption.value, selectAllOption.value]);
  }, [permissionOptions]);

  const getParList = () => {
    const preformParticipants = [
      ...(preForm.agents || []),
      ...(preForm.assignParticipantsToRoles || []),
      ...(preForm.clients || []),
      ...(preForm.coordinators || []),
      ...(preForm.participants || []),
    ].filter((participant) => participant.invite);

    const _allParticipants = [...preformParticipants, ...(participants || [])];

    if (
      location.pathname.includes('/workshop/transactions/create') ||
      location.pathname.includes('/workshop/projects/create')
    ) {
      return preformParticipants;
    } else if (location.pathname.includes('/undercontract')) {
      return _allParticipants;
    } else {
      return participants || [];
    }
  };

  const getParticipantsWithUniqueEmails = () => {
    const uniqueEmails = new Set();
    const participantsWithUniqueEmails = getParList()?.filter((obj) => {
      if (!uniqueEmails.has(obj.customEmail || obj.email)) {
        uniqueEmails.add(obj.customEmail || obj.email);
        return true;
      }
      return false;
    });

    return participantsWithUniqueEmails;
  };

  useEffect(() => {
    if (getParList()?.length) {
      setPermissionOptions(
        getParticipantsWithUniqueEmails()?.map((participant) => ({
          key: participant.id || participant.customEmail || participant.email,
          value: participant.id || participant.customEmail || participant.email,
          label:
            participant.firstName === '' && participant.name !== ''
              ? `${participant.name}`
              : `${participant.firstName} ${participant.lastName}`,
        })),
      );
    }
  }, []);

  useEffect(() => {
    let accessToEveryone: boolean = false;
    let updatedParticipants: {
      id?: number;
      email?: any;
      customEmail?: any;
      AccessToEveryone?: boolean;
    }[] = [];
    const allParticipants = getParticipantsWithUniqueEmails()?.map((participant) => ({
      id: participant.id,
      email: participant.customEmail || participant.email,
    }));

    if (permittedUsers.includes('*')) {
      updatedParticipants = [];
      accessToEveryone = true;
    } else if (permittedUsers.length) {
      updatedParticipants = allParticipants.filter(
        (participant) =>
          permittedUsers.includes(participant.id) ||
          permittedUsers.includes(participant.customEmail || participant.email),
      );
      accessToEveryone = false;
    } else if (!permittedUsers.length) {
      updatedParticipants = [];
      accessToEveryone = false;
    }

    let updatedParticipantsData: (number | string | object)[] = [];
    for (let participant of updatedParticipants) {
      if (participant && typeof participant.id === 'number') {
        updatedParticipantsData.push(participant.id);
      } else if (participant.email) {
        updatedParticipantsData.push(participant.email);
      }
    }

    updatedParticipantsData.push({ accessToEveryone });
    onChangeReviewers(updatedParticipantsData);
  }, [permittedUsers]);

  return (
    <div testid="upload_item" className={classNames(styles.item, className)}>
      <div className={styles.contentWrapper}>
        <div className={styles.fileHeader}>
          <div className={styles.left}>
            <Icon className={styles.icon} variant={Icon.FILE_SIMPLE} />
            <div className={styles.filename}>
              <span testid="file_name">{truncateFilename(filename, 15)}</span>
              <span testid="size" className={styles.fileSize}>
                ({prettyBytes(size)})
              </span>
            </div>
          </div>
          <Icon
            testid="delete_icon"
            onClick={onDelete}
            className={styles.iconDelete}
            variant={Icon.DELETE}
          />
        </div>

        <Row className={styles.form} gutter={[40, 24]}>
          <Col xs={24} sm={12}>
            <p className={styles.label}>File Name</p>
            <Input
              variant={Input.LIGHT_ROUNDED}
              placeholder="Document name"
              onChange={onChangeDocumentName}
              value={documentName}
              className={styles.input}
              testid="document_name"
              maxLength={50}
              inputClassName={styles.filenameInput}
              restrictEmoji
            />
          </Col>
          <Col xs={24} sm={12}>
            <p className={styles.label}>Tags</p>
            <TagInput
              label=""
              onSelect={onChangeTag}
              onSelectCustomTag={onBlurTag}
              value={{ Category: category, customTag }}
              selectClassName={styles.tagSelect}
            />
          </Col>
          <Col xs={24}>
            <p className={styles.label}>Permission</p>
            <PermissionSelector
              permissionOptions={permissionOptions}
              permittedUsers={permittedUsers}
              setPermittedUsers={setPermittedUsers}
              permissionSelectClassName={styles.permissionSelect}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ItemWithCategoryAndPermissions;
