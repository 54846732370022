import { get } from 'lodash-es';
import { isString } from 'lodash';
import {
  locationsMap,
  NEIGHBORHOOD,
  POSTAL_CODE,
  LOCALITY,
  STATE,
  STREET_ADDRESS,
  PREMISE,
  STREET_NUMBER,
  ROUTE,
  CASS_ADDRESS,
  COUNTY,
} from 'settings/constants/locations';

export const showConsole = false;

export const getLocationContext = (location, types) => {
  let longName = '';
  let shortName = '';
  if (!location) return longName;

  // If types is a string, convert to array to keep the logic consistent
  if (isString(types) === 'string') types = [types];

  location?.address_components.some((component) => {
    if (component?.types?.some((type) => types.includes(type))) {
      longName = component?.long_name;
      shortName = component?.short_name;
      return true;
    }
    return false;
  });
  if (types.includes(LOCALITY)) return longName;
  else return shortName;
};

export const getPlaceComponent = (location, type, additionalType) => {
  if (showConsole) console.log(`getPlaceComponent ${type} ${additionalType}`, location);
  let placeType = null;
  let name = null;
  let shortName = null;

  location?.address_components.some((component) => {
    if (
      component?.types?.includes(type) ||
      (additionalType && component?.types?.includes(additionalType))
    ) {
      placeType = type;
      name = component?.long_name;
      shortName = component?.short_name;
      return true;
    }
    return false;
  });

  if (showConsole) console.log(`selected name ${name} type ${placeType} shortName ${shortName}`);

  return {
    name,
    type: placeType,
    shortName,
  };
};

/* This function gets the location in order of specificity.  Google
   Places Geocoder returns an array of address_components with each location.
   The address_components define geo types that the location is 
   part of with differing levels of specificity.  In the order of most
   specific to least specific:
   - street_number
   - postal_code
   - locality (city)
   - neighborhood
   - county (administrative_area_level_2)
   - state

   Updated to include county search functionality.
*/
export const getLocationData = (location, allowStates) => {
  if (showConsole) console.log(`getLocationData`, location);
  const result = location?.result?.[0];
  const placeName = location?.placeName;
  const center = location?.coordinates;
  if (getPlaceComponent(result, STREET_NUMBER).type || getPlaceComponent(result, ROUTE).type) {
    if (showConsole) console.log(`Returned STREET_NUMBER OR ROUTE`);
    const streetNumber = getLocationContext(result, STREET_NUMBER);
    const route = getLocationContext(result, ROUTE);

    return {
      Type: locationsMap[STREET_NUMBER],
      ProviderPlaceId: result.place_id,
      PlaceName: placeName,
      Street: `${streetNumber ? `${streetNumber} ` : ''}${route}`,
      City: getLocationContext(result, LOCALITY),
      State: getLocationContext(result, STATE),
      Center: center,
    };
  }

  if (getPlaceComponent(result, STREET_ADDRESS).type || getPlaceComponent(result, PREMISE).type) {
    if (showConsole) console.log(`Returned STREET_ADDRESS OR PREMISE`);
    let northEast = result?.geometry?.viewport?.getNorthEast()?.toJSON();
    let southWest = result.geometry?.viewport?.getSouthWest()?.toJSON();

    if (result?.geometry?.bounds) {
      northEast = result?.geometry?.bounds?.getNorthEast()?.toJSON();
      southWest = result.geometry.bounds?.getSouthWest().toJSON();
    }

    return {
      Type: locationsMap[STREET_ADDRESS],
      ProviderPlaceId: result.place_id,
      PlaceName: placeName,
      State: getLocationContext(result, STATE),
      Box: {
        NorthEast: {
          Lat: northEast?.lat,
          Long: northEast?.lng,
        },
        SouthWest: {
          Lat: southWest?.lat,
          Long: southWest?.lng,
        },
      },
      Center: center,
    };
  }

  /*
    If the location explicity encodes a locality
    then return locality before neighborhood.  
    The user explicitly chose a city therefore
    we should return a city.
  */
  if (result.types && result.types.includes(LOCALITY)) {
    if (showConsole) console.log(`Returned LOCALITY`);
    if (getPlaceComponent(result, LOCALITY).type) {
      const { type } = getPlaceComponent(result, LOCALITY);
      return {
        Type: locationsMap[type],
        ProviderPlaceId: result.place_id,
        State: getLocationContext(result, STATE),
        City: getLocationContext(result, LOCALITY),
        PlaceName: placeName,
        Center: center,
      };
    }
  }

  /*
    Assuming that with phase 1 code, NEIGHBORHOOD was placed
    before LOCALITY (City) to increase the search scope when 
    a city is selected.
     
    20230831 Added constraint to only return NEIGHBORHOOD when it is 
    explicitly selected
  */
  if (result.types && result.types.includes(NEIGHBORHOOD)) {
    if (showConsole) console.log(`Returned NEIGHBORHOOD`);
    if (getPlaceComponent(result, NEIGHBORHOOD).type) {
      const { type, name } = getPlaceComponent(result, NEIGHBORHOOD);
      return {
        Type: locationsMap[type],
        ProviderPlaceId: result.place_id,
        NeighborhoodName: name,
        State: getLocationContext(result, STATE),
        City: getLocationContext(result, LOCALITY),
        PlaceName: placeName,
        Center: center,
      };
    }
  }

  if (getPlaceComponent(result, POSTAL_CODE).type) {
    if (showConsole) console.log(`Returned POSTAL CODE`);
    const { type } = getPlaceComponent(result, POSTAL_CODE);
    return {
      Type: locationsMap[type],
      ProviderPlaceId: result.place_id,
      Zipcode: getLocationContext(result, POSTAL_CODE),
      PlaceName: placeName,
      City: getLocationContext(result, LOCALITY),
      State: getLocationContext(result, STATE),
      Center: center,
    };
  }

  /*
    Finally, if a more precise selection is not identified, try
    to return LOCALITY
  */
  if (getPlaceComponent(result, LOCALITY).type) {
    if (showConsole) console.log(`Returned DEFAULT LOCALITY`);
    const { type } = getPlaceComponent(result, LOCALITY);
    return {
      Type: locationsMap[type],
      ProviderPlaceId: result.place_id,
      State: getLocationContext(result, STATE),
      City: getLocationContext(result, LOCALITY),
      PlaceName: placeName,
      Center: center,
    };
  }

  if (allowStates && getPlaceComponent(result, STATE).type) {
    const { type } = getPlaceComponent(result, STATE);
    return {
      Type: locationsMap[type],
      ProviderPlaceId: result.place_id,
      State: getLocationContext(result, STATE),
      PlaceName: placeName,
      Center: center,
    };
  }

  // Add County (administrative_area_level_2) functionality without removing other options
  if (getPlaceComponent(result, 'administrative_area_level_2').type) {
    if (showConsole) console.log(`Returned COUNTY (administrative_area_level_2)`);
    const county = getLocationContext(result, 'administrative_area_level_2').replace(' County', '');
    const state = getLocationContext(result, STATE);

    return {
      Type: locationsMap[COUNTY],
      ProviderPlaceId: result.place_id,
      County: county,
      State: state,
      PlaceName: placeName,
      Center: center,
    };
  }
};

export const getCassAddressData = (data) => {
  return {
    Type: CASS_ADDRESS,
    deliveryLine1: data?.result?.delivery_line_1,
    LastLine: data?.result?.last_line,
    StreetNumber: data?.result?.components?.primary_number,
    StreetName: data?.result?.components?.street_name,
    StreetSuffix: data?.result?.components?.street_suffix,
    ZipCode: data?.result?.components?.zipcode,
    Latitude: data?.result?.metadata?.latitude,
    Longitude: data?.result?.metadata?.longitude,
    StateOrProvince: data?.result?.components?.state_abbreviation,
    SecondaryNumber: data?.result?.components?.secondary_number,
    PostalCodePlus4: data?.result?.components?.plus4_code,
    County: data?.result?.metadata?.county_name,
    State: data?.placeInfo?.state,
    City: data?.result?.components?.city_name,
    PlaceName: data?.placeInfo?.street_line,
    ProviderPlaceId: data?.placeInfo?.street_line,
    StreetPredirection: data?.result?.components?.street_predirection,
    StreetPostdirection: data?.result?.components?.street_postdirection,
  };
};
export const getLocationState = (location) => {
  const result = get(location, 'result.0');
  if (result) return getLocationContext(result, STATE);
  else return undefined;
};

export const getLocationAddress = (result) => {
  const location = result?.result?.[0];
  if (location) {
    const city = getLocationContext(location, LOCALITY);
    const state = getLocationContext(location, STATE);
    const zip = getLocationContext(location, POSTAL_CODE);
    const street = getPlaceComponent(location, ROUTE);
    const streetNumber = getLocationContext(location, STREET_NUMBER);

    return {
      City: city,
      State: state,
      Zip: zip,
      Line1: [streetNumber, street?.name].filter((i) => !!i).join(' '),
      ProviderPlaceId: location.place_id,
      PlaceName: result?.placeName,
    };
  }
};

export const getFormattedLocations = (locations) =>
  locations?.map((item) => ({
    UUID: item.UUID,
    miles: item.Radius || item.miles || 0,
    areasOfOperation: item.AreasOfOperation || item.areasOfOperation,
  }));
