export const AGENT_CONNECTION_STATUSES = {
  ACTIVE: 'Accepted',
  RETAINED: 'Retained',
  INACTIVE: 'Inactive',
  PENDING: 'Pending',
  DECLINED: 'Declined',
};

export const MATCH_LEVEL = {
  FULL: 'FULL',
  PARTIAL: 'PARTIAL',
  NONE: 'NONE',
  UNKNOWN: 'UNKNOWN',
};

export const FILE_TYPES = {
  PDF: 'application/pdf',
  OCTET_STREAM: 'application/octet-stream',
};

export const FILE_EXTENSIONS = {
  PDF: '.pdf',
  CSV: '.csv',
  JPEG: '.jpeg',
  JPG: '.jpg',
  GIF: '.gif',
  PNG: '.png',
  SVG: '.svg',
  TIFF: '.tiff',
  BMP: '.bmp',
  TXT: '.txt',
  XLS: '.xls',
  XLSX: '.xlsx',
  ODS: '.ods',
  PPT: '.ppt',
  PPS: '.pps',
  ODP: '.odp',
  PPTX: '.pptx',
  DOC: '.doc',
  DOCX: '.docx',
  ODT: '.odt',
  RTF: '.rtf',
  MP4: '.mp4',
  WEBM: '.webm',
  AVI: '.avi',
  MOV: '.mov',
  MKV: '.mkv',
  MP3: '.mp3',
  WAV: '.wav',
  OGG: '.ogg',
  AAC: '.aac',
  FLAC: '.flac',
  M4A: '.m4a',
  ZIP: '.zip',
};

export const getAllAcceptedFileExtensions = () => {
  return Object.values(FILE_EXTENSIONS);
};

export const getFileExtension = (fileName) => {
  const fileExtension = Object.values(FILE_EXTENSIONS).find((ext) => fileName?.endsWith(ext));

  return fileExtension || FILE_EXTENSIONS.PDF;
};

export const uploadFilePopupModes = {
  uploading: 'uploading',
  uploaded: 'uploaded',
};

export const noRanges = {
  noMin: 'noMin',
  noMax: 'noMax',
};

export const FORMATES = {
  ONLY_DATE: 'MM-DD-YYYY',
};
