export const isValidAgentName = (agent) => agent === ' ' || agent === '' || agent === null;

export const formatAgentUserContact = (data) =>
  data.map((agent) => {
    return { ...agent, Agent: agent.AgentUser || agent.AgentContact || agent.TransactionAgentUser };
  });

const randomColorAssignor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);

export const assignRandomColor = (data) =>
  data.map((source) => {
    return { ...source, color: randomColorAssignor() };
  });
