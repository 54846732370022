import React from 'react';
import classNames from 'classnames';

import { Close } from 'components/Icons';
import { LeadSourcesDropdown } from './LeadSourcesDropdown';
import { SourceBreakdown } from './Sources/SourceBreakdown';
import { AgentBreakdown } from './Agents';
import { Wrapper as PendingWrapper } from 'components';

import styles from './styles.module.scss';

type BreakdownMenuProps = {
  open: boolean;
  list: any[];
  handleSourceClick: (source, rowIndex) => void;
  handleAgentClick: (agent, rowIndex) => void;
  color: string;
  onClose: (agent, index) => void;
  selectedAgent?: any | null;
  setSelectedAgent: (agent) => void;
  breakdownTotal: any;
  dropDownSelectedOption: string;
  setOption: (option) => void;
  isPending: boolean;
  resetSourceList: () => void;
};

export const LeadSourcesBreakdownMenu: React.FC<BreakdownMenuProps> = ({
  open,
  list,
  handleSourceClick,
  handleAgentClick,
  color,
  onClose,
  selectedAgent = null,
  setSelectedAgent,
  breakdownTotal,
  dropDownSelectedOption,
  setOption,
  isPending,
  resetSourceList,
}) => {
  return (
    <div className={styles.breakdownWrapper}>
      <div className={styles.breakdownHeader}>
        <span>Breakdown</span>
        <LeadSourcesDropdown
          value={dropDownSelectedOption}
          setValue={setOption}
          onSelectSourcesOption={() => setSelectedAgent(null)}
          resetSourceList={resetSourceList}
        />
        <Close onClick={onClose} className={styles.closeBtn} />
      </div>
      <div className={styles.customLine} />
      <PendingWrapper
        isPending={isPending}
        className={classNames(styles.breakdownContent, { [styles.openMenuContent]: open })}
      >
        {list.length ? (
          <div>
            {dropDownSelectedOption === 'Sources' ? (
              <SourceBreakdown
                summary={breakdownTotal}
                data={list}
                handleClick={handleSourceClick}
              />
            ) : (
              <AgentBreakdown
                list={list}
                handleClick={handleAgentClick}
                color={color}
                selectedAgent={selectedAgent}
              />
            )}
          </div>
        ) : (
          <div className={styles.placeholder}>No Breakdown Data</div>
        )}
      </PendingWrapper>
    </div>
  );
};
