import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import { Wrapper as PendingWrapper } from 'components';
import { AntCard } from 'components-antd';
import classNames from 'classnames';
import { Icons } from 'pages/Radar/Icons';
import { Area, AreaConfig } from '@ant-design/plots';
import { priceConverter } from 'helpers';

interface WidgetProps {
  title?: string;
  color?: string;
  data?: any;
  count: string;
  subCount?: string;
  isLoading?: boolean;
  formatNumber?: boolean;
}

export const Widget: FC<WidgetProps> = ({
  title,
  color = '',
  data,
  count,
  subCount,
  isLoading = false,
  formatNumber = false,
}) => {
  const dispatch = useDispatch();

  const handleBack = useCallback(() => {}, [dispatch]);

  const colors = {
    lightBlue: '#51bfe1',
    purple: '#928cda',
    yellow: '#FB913A',
  };

  const chartColor = colors?.[color] ? colors?.[color] : '#4673D1';

  const config = {
    data,
    xField: 'month',
    yField: 'total',
    color: chartColor,
    responsive: true,
    autoFit: true,
    height: 80,
    padding: [20, 0],
    smooth: true,
    areaStyle: {
      fill: `l(270) 0:${chartColor}00 1:${chartColor}`,
    },
    xAxis: false,
    yAxis: false,
    tooltip: false,
  } as AreaConfig;

  return (
    <AntCard className={styles.card}>
      <PendingWrapper spinnerClassname={styles.centerSpinner} isPending={isLoading}>
        <header className={styles.widgetHeader}>
          <p className={styles.heading}>{title}</p>
        </header>

        <div className={styles.countContainer}>
          {formatNumber ? (
            <span className={classNames(styles.count, styles[color])}>
              {count && Number(count) ? priceConverter(count) : '-'}
            </span>
          ) : (
            <span className={classNames(styles.count, styles[color])}>{count || '-'}</span>
          )}

          {subCount && count && Number(count) ? (
            <div className={styles.subtext}>
              {Number(subCount) > 0 && <Icons variant={Icons.UP_ARROW} />}
              <p className={styles.subCount}>{`+${subCount}`}</p>
            </div>
          ) : null}
          {data && (
            <div>
              <Area {...config}></Area>
            </div>
          )}
        </div>
      </PendingWrapper>
    </AntCard>
  );
};
