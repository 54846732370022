import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';
const Icons = (props) => {
  const { className, variant, onClick, testid, stroke } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.CALENDAR: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.8326 4.22656H6.16861C5.09497 4.22656 4.22461 5.09692 4.22461 6.17056V17.8346C4.22461 18.9082 5.09497 19.7786 6.16861 19.7786H17.8326C18.9063 19.7786 19.7766 18.9082 19.7766 17.8346V6.17056C19.7766 5.09692 18.9063 4.22656 17.8326 4.22656Z"
              stroke={stroke || '#AAABAB'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.8887 2.92578V5.51778"
              stroke={stroke || '#AAABAB'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.11133 2.92578V5.51778"
              stroke={stroke || '#AAABAB'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.22461 9.40625H19.7766"
              stroke={stroke || '#AAABAB'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.LIST: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_43841_198933)">
              <path
                d="M11 6H20"
                stroke={stroke || '#AAABAB'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 12H20"
                stroke={stroke || '#AAABAB'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 18H20"
                stroke={stroke || '#AAABAB'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="5.5" cy="6" r="1.5" stroke={stroke || '#AAABAB'} strokeWidth="2" />
              <circle cx="5.5" cy="12" r="1.5" stroke={stroke || '#AAABAB'} strokeWidth="2" />
              <circle cx="5.5" cy="18" r="1.5" stroke={stroke || '#AAABAB'} strokeWidth="2" />
            </g>
            <defs>
              <clipPath id="clip0_43841_198933">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.ARROWS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.16602 7.83464H17.8327M17.8327 7.83464L14.916 4.91797M17.8327 7.83464L14.916 10.7513"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.833 16.1667H6.16634M6.16634 16.1667L9.08301 13.25M6.16634 16.1667L9.08301 19.0833"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.MESSAGES: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_43647_137283)">
              <path
                d="M19.167 18.3346V8.85547C19.167 8.27531 18.9365 7.71891 18.5263 7.30867C18.1161 6.89844 17.5597 6.66797 16.9795 6.66797H9.68783C9.10766 6.66797 8.55126 6.89844 8.14103 7.30867C7.73079 7.71891 7.50033 8.27531 7.50033 8.85547V13.2305C7.50033 13.8106 7.73079 14.367 8.14103 14.7773C8.55126 15.1875 9.10766 15.418 9.68783 15.418H16.2503L19.167 18.3346Z"
                fill="white"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.833008 14.168V4.01172C0.833008 3.39012 1.07994 2.79398 1.51948 2.35444C1.95901 1.9149 2.55516 1.66797 3.17676 1.66797H10.9893C11.6109 1.66797 12.207 1.9149 12.6465 2.35444C13.0861 2.79398 13.333 3.39012 13.333 4.01172V8.69922C13.333 9.32082 13.0861 9.91696 12.6465 10.3565C12.207 10.796 11.6109 11.043 10.9893 11.043H3.95801L0.833008 14.168Z"
                fill="white"
                stroke="#AAABAB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_43647_137283">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.TASKS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_43647_138561)">
              <path
                d="M3.5 5.5L5 7L7.5 4.5"
                stroke="#4673D1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.5 11.5L5 13L7.5 10.5"
                stroke="#4673D1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.5 17.5L5 19L7.5 16.5"
                stroke="#4673D1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 6H20"
                stroke="#4673D1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 12H20"
                stroke="#4673D1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 18H20"
                stroke="#4673D1"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_43647_138561">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.DOCUMENT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.USERS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 8C12.5 10.2091 10.7091 12 8.5 12C6.29086 12 4.5 10.2091 4.5 8C4.5 5.79086 6.29086 4 8.5 4C10.7091 4 12.5 5.79086 12.5 8Z"
              stroke="#51BFE1"
              strokeWidth="2"
            />
            <path
              d="M15.5 12C17.7091 12 19.5 10.2091 19.5 8C19.5 5.79086 17.7091 4 15.5 4"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M2.5 19.5C2.87394 17.6303 4.59315 16 6.49988 16H10.5C12.4067 16 14.1261 17.6303 14.5 19.5"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.5 16C19.4067 16 21.1261 17.6303 21.5 19.5"
              stroke="#51BFE1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TEXT_DOCUMENT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 9H10"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 13H15"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 17H15"
              stroke="#4673D1"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.MESSAGES_2: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.18229 16.0581L6.10236 16.4498L6.30022 15.9851L6.04365 15.5501L5.18229 16.0581ZM3.54135 19.912L2.62128 19.5203H2.62128L3.54135 19.912ZM4.18134 20.5744L4.54126 21.5074H4.54126L4.18134 20.5744ZM8.22693 19.0137L8.70902 18.1376L8.30125 17.9132L7.86701 18.0807L8.22693 19.0137ZM19.0781 12C19.0781 15.866 15.9441 19 12.0781 19V21C17.0487 21 21.0781 16.9706 21.0781 12H19.0781ZM12.0781 5C15.9441 5 19.0781 8.13401 19.0781 12H21.0781C21.0781 7.02944 17.0487 3 12.0781 3V5ZM5.07811 12C5.07811 8.13401 8.21212 5 12.0781 5V3C7.10755 3 3.07811 7.02944 3.07811 12H5.07811ZM6.04365 15.5501C5.43029 14.51 5.07811 13.2977 5.07811 12H3.07811C3.07811 13.6647 3.53101 15.2267 4.32093 16.5661L6.04365 15.5501ZM4.46142 20.3038L6.10236 16.4498L4.26222 15.6663L2.62128 19.5203L4.46142 20.3038ZM3.82142 19.6414C4.23373 19.4824 4.63454 19.8972 4.46142 20.3038L2.62128 19.5203C2.10191 20.7401 3.30435 21.9846 4.54126 21.5074L3.82142 19.6414ZM7.86701 18.0807L3.82142 19.6414L4.54126 21.5074L8.58685 19.9467L7.86701 18.0807ZM12.0781 19C10.8548 19 9.70754 18.687 8.70902 18.1376L7.74483 19.8898C9.03123 20.5977 10.5092 21 12.0781 21V19Z"
              fill="#928CDA"
            />
          </svg>
        );
      }
      case Icons.BULB: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 12H4M12 3V4M20 12H21M5.6 5.6L6.3 6.3M18.4 5.6L17.7 6.3M9.7 17H14.3M9 16C8.16047 15.3704 7.54033 14.4925 7.22743 13.4908C6.91453 12.4892 6.92473 11.4144 7.25658 10.4189C7.58844 9.4233 8.22512 8.55739 9.07645 7.94379C9.92778 7.33019 10.9506 7 12 7C13.0494 7 14.0722 7.33019 14.9236 7.94379C15.7749 8.55739 16.4116 9.4233 16.7434 10.4189C17.0753 11.4144 17.0855 12.4892 16.7726 13.4908C16.4597 14.4925 15.8395 15.3704 15 16C14.6096 16.3865 14.3156 16.8594 14.1419 17.3806C13.9681 17.9018 13.9195 18.4566 14 19C14 19.5304 13.7893 20.0391 13.4142 20.4142C13.0391 20.7893 12.5304 21 12 21C11.4696 21 10.9609 20.7893 10.5858 20.4142C10.2107 20.0391 10 19.5304 10 19C10.0805 18.4566 10.0319 17.9018 9.85813 17.3806C9.6844 16.8594 9.39043 16.3865 9 16Z"
              stroke="#FB913A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.USER: {
        return (
          <svg
            width="24"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.75 6.25C13.75 8.32107 12.0711 10 10 10C7.92893 10 6.25 8.32107 6.25 6.25C6.25 4.17893 7.92893 2.5 10 2.5C12.0711 2.5 13.75 4.17893 13.75 6.25Z"
              stroke="#515151"
              strokeWidth="2"
            />
            <path
              d="M4.375 17.1875C4.72557 15.1843 6.33733 13.4375 8.12489 13.4375H11.875C13.6626 13.4375 15.2744 15.1843 15.625 17.1875"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.INVITE_CLIENT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 7.5C13.5 9.98528 11.4853 12 9 12C6.51472 12 4.5 9.98528 4.5 7.5C4.5 5.01472 6.51472 3 9 3C11.4853 3 13.5 5.01472 13.5 7.5Z"
              stroke="#FB913A"
              strokeWidth="2.5"
            />
            <path
              d="M2.25 20.625C2.67068 18.2211 4.6048 16.125 6.74986 16.125H11.25C13.3951 16.125 15.3293 18.2211 15.75 20.625"
              stroke="#FB913A"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 12H22.5M19.5 9V15"
              stroke="#FB913A"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.FORM: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.66602 4.83203C4.66602 3.72746 5.56145 2.83203 6.66602 2.83203H14.023C14.2921 2.83203 14.5498 2.9405 14.738 3.13291L19.0477 7.54056C19.2304 7.72741 19.3327 7.97835 19.3327 8.23968V19.1654C19.3327 20.2699 18.4373 21.1654 17.3327 21.1654H6.66602C5.56145 21.1654 4.66602 20.2699 4.66602 19.1654V4.83203Z"
              stroke="#4673D1"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.834 2.83203V6.33203C13.834 7.4366 14.7294 8.33203 15.834 8.33203H19.334"
              stroke="#4673D1"
              strokeWidth="2.5"
            />
            <path
              d="M8.33398 12L15.6673 12"
              stroke="#4673D1"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.33398 15.668L12.9173 15.668"
              stroke="#4673D1"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.SEARCH: {
        return (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.16667 15.5833C12.7105 15.5833 15.5833 12.7105 15.5833 9.16667C15.5833 5.62284 12.7105 2.75 9.16667 2.75C5.62284 2.75 2.75 5.62284 2.75 9.16667C2.75 12.7105 5.62284 15.5833 9.16667 15.5833Z"
              stroke="#51BFE1"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.25 19.25L13.75 13.75"
              stroke="#51BFE1"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TASKS_QUICK_ACTION: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.5 5.5L5 7L7.5 4.5"
              stroke="#928CDA"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 11.5L5 13L7.5 10.5"
              stroke="#928CDA"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.5 17.5L5 19L7.5 16.5"
              stroke="#928CDA"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 6H20"
              stroke="#928CDA"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 12H20"
              stroke="#928CDA"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 18H20"
              stroke="#928CDA"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TRANSACTIONS: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 6.75L19.5 6.75M19.5 6.75L15.75 3M19.5 6.75L15.75 10.5"
              stroke="#04A451"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.5 17.25H4.5M4.5 17.25L8.25 13.5M4.5 17.25L8.25 21"
              stroke="#04A451"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div testid={testid} onClick={onClick} className={classNames(styles.icon, className)}>
      {getIcons()}
    </div>
  );
};

Icons.CALENDAR = 'calendar';
Icons.LIST = 'list';
Icons.ARROWS = 'arrows';
Icons.MESSAGES = 'messages';
Icons.TASKS = 'tasks';
Icons.DOCUMENT = 'document';
Icons.USERS = 'users';
Icons.TEXT_DOCUMENT = 'text_document';
Icons.MESSAGES_2 = 'messages_2';
Icons.BULB = 'bulb';
Icons.INVITE_CLIENT = 'inviteClient';
Icons.FORM = 'form';
Icons.SEARCH = 'search';
Icons.TASKS_QUICK_ACTION = 'tasksQuickAction';
Icons.TRANSACTIONS = 'transactions';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  testid: PropTypes.string,
  onClick: PropTypes.func,
  stroke: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
  stroke: '',
};

export default Icons;
