import moment from 'moment';
import { AgentDocumentTypes } from 'types';
import { AvatarColumn, DateTimeColumn, DocumentNameColumn } from './TableColumns';
import { Options } from '../Options';

const docType = AgentDocumentTypes.Transactions;
export const transactionColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 375,
    render: (title) => (
      <DocumentNameColumn name={title} docIconType={AgentDocumentTypes.PreApprovals} />
    ),
    sorter: (a, b) => a.title?.localeCompare(b.title),
  },
  {
    key: 'uploadedBy',
    title: 'Uploaded By',
    width: 350,
    render: (row) => <AvatarColumn name={row?.transactionMeta?.uploadedBy} imgSrc={null} />,
    sorter: (a, b) => a?.transactionMeta?.uploadedBy?.localeCompare(b?.transactionMeta?.uploadedBy),
  },
  {
    key: 'uploadedAt',
    title: 'Created',
    render: (row) => <DateTimeColumn date={row?.transactionMeta?.uploadedAt} />,
    sorter: (a, b) => {
      if (!a?.transactionMeta?.uploadedAt) return 1;
      if (!b?.transactionMeta?.uploadedAt) return -1;

      return moment(a?.transactionMeta?.uploadedAt).diff(
        moment(b?.transactionMeta?.uploadedAt),
        'minutes',
      );
    },
  },
  {
    key: 'updatedDate',
    title: 'Last Edited',
    render: (row) => <DateTimeColumn date={row?.transactionMeta?.updatedDate} />,
    sorter: (a, b) => {
      if (!a?.transactionMeta?.updatedDate) return 1;
      if (!b?.transactionMeta?.updatedDate) return -1;

      return moment(a?.transactionMeta?.updatedDate).diff(
        moment(b?.transactionMeta?.updatedDate),
        'minutes',
      );
    },
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docType} optionUtils={optionUtils} />,
  },
];
