import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { routes } from 'settings/navigation/routes';
import { PageWrapper } from 'components-antd';
import {
  LocalHeader,
  DocumentsContent,
  DocumentOptions,
  DateTimeColumn,
  AvatarColumn,
  DocumentNameColumn,
  EmptyState,
} from '../components';
import { Icons } from 'pages/Vault/Icons';
import { DocsContentView } from '../constants';
import { Wrapper } from 'components';

import { getCurrentContextSelector } from 'store/selectors/context';
import { getClientContextsStateSelector } from 'store/selectors/contexts';
import { getMetaVaultDocuments, getPreApprovalVaultDocumentsList } from 'store/selectors/vault';
import { getPreapprovalDocumentsEffect, getVaultDocumentsMetaEffect } from 'store/effects';
import { PENDING, READY } from 'settings/constants/apiState';

import styles from './styles.module.scss';

export const PreApprovals = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchContext = useSelector(getCurrentContextSelector);
  const contextsState = useSelector(getClientContextsStateSelector);
  const selectedAgentId = searchContext?.Agents?.[0]?.Id;
  const { data, isPending } = useSelector(getPreApprovalVaultDocumentsList);
  const { data: metaData } = useSelector(getMetaVaultDocuments);
  const { preApprovalDocuments } = data || {};
  const [viewType, setViewType] = useState(DocsContentView.GRID_VIEW);

  const fetchPreApprovalDocuments = () => {
    dispatch(
      getPreapprovalDocumentsEffect({
        id: selectedAgentId,
      }),
    );
  };

  useEffect(() => {
    if (contextsState.state === READY && selectedAgentId) {
      fetchPreApprovalDocuments();
      if (!metaData) {
        dispatch(
          getVaultDocumentsMetaEffect({
            id: selectedAgentId,
          }),
        );
      }
    }
  }, [contextsState.state, selectedAgentId]);

  const handleBackBtn = () => history.push(routes.vault);

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (_, { Title, Filename, FormName }) => {
        const name = Title || Filename || FormName;
        return <DocumentNameColumn name={name} docIconType={Icons.DOCUMENT_1} />;
      },
      sorter: (a, b) => {
        const aName = a?.Title || a?.Filename || a?.FormName;
        const bName = b?.Title || b?.Filename || b?.FormName;
        return aName?.localeCompare(bName);
      },
    },
    {
      title: 'Uploaded By',
      key: 'uploadedBy',
      render: (_, { UploaderFirstName, UploaderLastName, AvatarUrl }) => {
        return (
          <AvatarColumn name={`${UploaderFirstName} ${UploaderLastName}`} imgSrc={AvatarUrl} />
        );
      },
      sorter: (a, b) => {
        const aName = `${a?.UploaderFirstName} ${a?.UploaderLastName}`;
        const bName = `${b?.UploaderFirstName} ${b?.UploaderLastName}`;
        return aName?.localeCompare(bName);
      },
    },
    {
      title: 'Uploaded',
      key: 'uploaded',
      dataIndex: 'uploaded',
      render: (_, { CreatedDate }) => {
        return <DateTimeColumn date={CreatedDate} />;
      },
      sorter: (a, b) => a?.CreatedDate?.localeCompare(b?.CreatedDate),
    },
    {
      title: '',
      key: '',
      render: (_, file) => {
        return (
          <div className={styles.optionsColumn}>
            <DocumentOptions
              className={styles.bottomOffset}
              file={file}
              Property={null}
              isPreapprovals
              refetch={fetchPreApprovalDocuments}
            />
          </div>
        );
      },
    },
  ];

  return (
    <PageWrapper bgGray mainPageContentStyle={styles.pagecontentStyles} version={2}>
      <LocalHeader
        title="Pre-Approvals"
        handleBackBtn={handleBackBtn}
        viewType={viewType}
        setViewType={setViewType}
        uploadCallback={fetchPreApprovalDocuments}
        isPreapprovals
      />
      <div className={styles.preApprovalsPage}>
        <Wrapper isPending={isPending || contextsState?.state === PENDING}>
          {preApprovalDocuments?.length ? (
            <DocumentsContent
              title="Total"
              totalCount={preApprovalDocuments?.length}
              columns={columns}
              data={preApprovalDocuments}
              viewType={viewType}
              isPreapprovals
              refetch={fetchPreApprovalDocuments}
            />
          ) : (
            <EmptyState />
          )}
        </Wrapper>
      </div>
    </PageWrapper>
  );
};
