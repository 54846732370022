import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAgentClientModeAction,
  setCriteriaAction,
  setFilterAction,
  setSelectedClientSavedSearchAction,
} from 'store/actions/feedv3';
import { getAgentPropertiesV3Effect } from 'store/effects/feedv3';
import { getSubFilterTypeSelector } from 'store/selectors/feed';
import { getFeedSortSelector } from 'store/selectors/feed';
import { setMlsAccessValue } from 'store/actions/mlsAccess';
import { cleanSearchQueryObj } from 'store/effects/search/helpers';
import { LocalStorage } from 'services';
import { omitBy } from 'lodash-es';

export const useAgentSearchInstance = () => {
  const dispatch = useDispatch();
  const sort = useSelector(getFeedSortSelector);
  const subFilterType = useSelector(getSubFilterTypeSelector);

  const DEFAULT_PAGE_SIZE = 350;

  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        (abortControllerRef.current as AbortController).abort();
      }
    };
  }, []);

  const getAgentSearchInstanceV3 = (
    item?: any,
    cb?: (err: any, resp: any) => void,
    user = null,
    isClientMatches = false,
    comments = false,
  ) => {
    // Abort previous request if it exists
    if (abortControllerRef.current) {
      (abortControllerRef.current as AbortController).abort();
    }

    // Create a new AbortController
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const cleanedCriteria = omitBy(cleanSearchQueryObj(item?.criterias), (value) => !value);

    const feedTypeParams = isClientMatches
      ? { feedType: 'ClientsMatched' }
      : comments
      ? { feedType: 'UnreadComments' }
      : {};

    const cfg = {
      queryParams: {
        sortField: sort?.fields?.[0] ?? 'DaysOnMarket',
        sortOrder: sort?.order,
        first: DEFAULT_PAGE_SIZE,
        loadTotalCount: true,
        subFilterType: subFilterType,
        ...feedTypeParams,
      },
      body: {
        searchQuery: {
          ...cleanedCriteria,
        },
      },
    };

    dispatch(setCriteriaAction({ criteria: cleanedCriteria }));

    // Pass the signal to the options parameter
    const options = {
      signal: abortController.signal,
    };

    dispatch(
      getAgentPropertiesV3Effect(cfg, options, (err, resp) => {
        // Remove the controller reference after the request completes
        abortControllerRef.current = null;

        if (!err) {
          dispatch(setMlsAccessValue(true));
        }

        if (err?.response?.data?.code === 'NO_MLS_ACCESS') {
          dispatch(setMlsAccessValue(false));
          return;
        }

        dispatch(
          setAgentClientModeAction({
            agentClientMode: { value: Boolean(user), user: user },
          }),
        );
        if (!user) {
          dispatch(setSelectedClientSavedSearchAction({ selectedClientSavedSearch: null }));
        }
        dispatch(setFilterAction({ filter: {} }));
        LocalStorage.setSearchCriteria(cleanedCriteria);
        if (item?.id) LocalStorage.setSearchInstanceId(item?.id);
        if (cb) cb(err, resp);
      }),
    );
  };

  return getAgentSearchInstanceV3;
};
