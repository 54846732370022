import React, { useState, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getAttachmentType } from 'helpers';
import { FILE_TYPES } from 'settings/constants/common';

import fileDownload from 'js-file-download';
import { PdfViewModal } from 'components';
import File from './File';
import { previewDocumentEffect } from 'store/effects/transactions';

import styles from './styles.module.scss';

import { useOutsideClick } from 'hooks';

import { useDispatch } from 'react-redux';
import { showErrorMessage } from 'helpers/errors';
import { openNotification } from 'components-antd';
import notificationStyles from '../../../../../../components-antd/NotificationPopup/styles.module.scss';

const Files = (props) => {
  const { className, fileClassName, attachments, isMessageSavedOnServer, isOwnMessage } = props;
  const [openedFile, setOpenedFile] = useState(null);

  const onCloseModal = useCallback(() => setOpenedFile(null), [setOpenedFile]);
  const dispatch = useDispatch();
  const dotsRef = useRef();
  const optionsRef = useRef();
  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState(null);

  useOutsideClick([dotsRef, optionsRef], () => setOpen(false));

  const files = useMemo(
    () => (attachments || []).filter((attachment) => getAttachmentType(attachment) !== 'image'),
    [attachments],
  );

  const onPreview = () => {
    setOpenedFile(true);
    setOpen(false);
  };

  const onDownloadFile = useCallback(() => {
    if (isMessageSavedOnServer) {
      dispatch(
        previewDocumentEffect(
          { DocumentVaultUUID: attachments.DocumentVaultUUID },
          {},
          (err, response) => {
            if (err) {
              return showErrorMessage(err);
            }
            fileDownload(response.data, response.headers['file-name']);
          },
        ),
      );

      setOpen(false);
    }
  }, [isMessageSavedOnServer]);

  const getOnClickFile = useCallback(
    (file) => () => {
      if (file?.ContentType === FILE_TYPES.PDF) {
        onPreview();
        dispatch(
          previewDocumentEffect(
            { DocumentVaultUUID: file.DocumentVaultUUID },
            {},
            (err, response) => {
              if (err) {
                return showErrorMessage(err);
              }
              setDocument({
                DocumentBuffer: response,
                Filename: response.headers['file-name'],
              });
            },
          ),
        );
      } else {
        if (file.DocumentVaultUUID) {
          openNotification({
            message: <span className={notificationStyles.notificationTitle}>File download</span>,
            description: (
              <span className={notificationStyles.notificationDescription}>
                File download started
              </span>
            ),
            placement: 'bottomRight',
          });
          dispatch(
            previewDocumentEffect(
              { DocumentVaultUUID: file.DocumentVaultUUID },
              {},
              (err, response) => {
                if (err) {
                  return showErrorMessage(err);
                }
                openNotification({
                  message: (
                    <span className={notificationStyles.notificationTitle}>File download</span>
                  ),
                  description: (
                    <span className={notificationStyles.notificationDescription}>
                      File downloaded successfully
                    </span>
                  ),
                  placement: 'bottomRight',
                });
                fileDownload(response.data, response.headers['file-name']);
              },
            ),
          );
        } else {
          onDownloadFile(file?.Url, file?.Filename);
        }
      }
    },
    [onDownloadFile, setOpenedFile],
  );

  return (
    <div className={classNames(styles.files, className)}>
      {files.map((file, index) => (
        <File
          onClick={getOnClickFile(file)}
          key={file.Url || index}
          className={fileClassName}
          file={file}
          isMessageSavedOnServer={isMessageSavedOnServer}
          isOwnMessage={isOwnMessage}
        />
      ))}
      <PdfViewModal isOpen={!!openedFile} file={document} onClose={onCloseModal} />
    </div>
  );
};

Files.propTypes = {
  className: PropTypes.string,
  fileClassName: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMessageSavedOnServer: PropTypes.bool.isRequired,
  isOwnMessage: PropTypes.bool.isRequired,
};

Files.defaultProps = {
  className: '',
  fileClassName: '',
};

export default Files;
