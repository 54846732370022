import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'components-antd';
import { Participants } from './../TransactionOverview/components';
import { ContactsView } from '../components/ContactsView';
import { getTransactionEffect, getKeyDatesEffect } from 'store/effects/transactions';
import {
  getAllTransactionParticipantsSelector,
  getTransactionAccessSelector,
  getTransactionSelector,
} from 'store/selectors/transaction';
import { PageWrapper, AsideNavigation, Content } from 'components/Transactions';
import { TransactionParticipantDrawer } from 'components';

import styles from './styles.module.scss';
import { requestGetTeamListEffect } from 'store/effects';

export const TransactionDetails = () => {
  const dispatch = useDispatch();
  const params: { id?: string } = useParams();

  const { isError, status, isIdle, isPending } = useSelector(getTransactionSelector);
  const allTransactionParticipants = useSelector(getAllTransactionParticipantsSelector);
  const { fullAccess } = useSelector(getTransactionAccessSelector);

  useEffect(() => {
    dispatch(getTransactionEffect({ id: params?.id }, { silent: !isIdle }));
    dispatch(getKeyDatesEffect({ transactionId: params?.id }, { silent: true }));
  }, []);

  useEffect(() => {
    if (fullAccess) {
      dispatch(requestGetTeamListEffect({ Status: 'Accepted' }));
    }
  }, [fullAccess]);

  return (
    <div>
      <PageWrapper notFound={isError && status === 404} unAuthorized={isError && status === 401}>
        <AsideNavigation />
        <Content>
          <Row className={styles.rowContent}>
            <Col xs={24} md={12} className={styles.pageDivider}>
              <ContactsView isPending={isPending} />
            </Col>
            <Col xs={24} md={12}>
              <Participants
                participants={allTransactionParticipants}
                isPending={isPending}
                participantsInContact={true}
              />
            </Col>
          </Row>
        </Content>
      </PageWrapper>
      <TransactionParticipantDrawer />
    </div>
  );
};
