/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

import { PasswordIcon, SearchIcon, MinusRounded } from 'components/Icons';
import { isFloatStr } from 'helpers';

import styles from './styles.module.scss';
import { hasEmoji } from 'pages/FormProcess/DynamicForm/Containers/DynamicViewer/components/FieldRenderer/Fields/helper';

const Input = (props) => {
  const {
    id,
    name,
    type,
    label,
    value,
    onChange,
    className,
    removeHandler,
    testid,
    maxLength,
    restrictEmoji,
  } = props;

  const {
    placeholder,
    disabled,
    error,
    variant,
    altLabel,
    icon,
    labelRightIcon,
    iconLeft,
    autofocus,
    classAltLabel,
    removeBtnClassName,
    errorClassName,
    iconClassName,
    textArea,
  } = props;
  const {
    isNumberFormat,
    prefix,
    suffix,
    thousandSeparator,
    numberFormatOptions,
    inputClassName,
    removeIcon,
    replacePlaceholderWithLabelWhenHasValue,
    allowEmptyFormatting,
  } = props;
  const {
    inputHolderClassName,
    onKeyPress,
    onFocus,
    onBlur,
    maxNumber,
    minNumber,
    numberInputClassName,
    saveOnChange,
    allowNegative,
    decimalScale,
    allowLeadingZeros,
  } = props;
  const [inputValue, setInputValue] = useState(value);
  const [typeValue, setTypeValue] = useState(type);

  useEffect(() => setInputValue(value), [value]);

  const onChangeHandler = useCallback(
    (event) => {
      const { value: inputVal } = event.target;

      if (typeValue === 'number' && !isFloatStr(inputVal) && inputVal !== '') {
        return;
      }

      if (!restrictEmoji || (restrictEmoji && !hasEmoji(inputVal))) {
        saveOnChange && setInputValue(inputVal);
        onChange(event, inputVal);
      }
    },
    [onChange, typeValue],
  );

  const onBlurHandler = useCallback(
    (event) => {
      const { value: inputVal } = event.target;

      if (typeValue === 'number' && !isFloatStr(inputVal) && inputVal !== '') {
        return;
      }

      saveOnChange && setInputValue(inputVal);
      onBlur(event, inputVal);
    },
    [onBlur, typeValue],
  );

  const onChangePasswordMode = useCallback(() => {
    if (typeValue === 'text') {
      setTypeValue('password');
    }

    if (typeValue === 'password') {
      setTypeValue('text');
    }
  }, [typeValue]);

  const isAllowed = (values) => {
    if (!maxNumber && !minNumber) return true;

    const { value: val, floatValue } = values;

    if (val === '') return true;

    if (minNumber && maxNumber) {
      return floatValue >= minNumber && floatValue <= maxNumber;
    }
    if (minNumber) {
      return floatValue >= minNumber;
    }
    if (maxNumber) {
      return floatValue <= maxNumber;
    }
    return true;
  };

  const hasLabel =
    (label && !replacePlaceholderWithLabelWhenHasValue) ||
    ((label || placeholder) && replacePlaceholderWithLabelWhenHasValue && !!value);

  return (
    <div
      testid="wrapper"
      className={classNames(styles.inputWrapper, styles[variant], className, {
        [styles.textArea]: textArea,
      })}
    >
      {hasLabel && (
        <label htmlFor={id}>
          <span>{label || placeholder}</span>
          {altLabel && (
            <span className={classNames(styles.altLabel, classAltLabel)}>{altLabel}</span>
          )}
          {labelRightIcon && labelRightIcon}
        </label>
      )}
      <div className={classNames(styles.inputHolder, inputHolderClassName)}>
        {iconLeft && iconLeft}
        {isNumberFormat ? (
          <NumberFormat
            value={inputValue}
            prefix={prefix}
            suffix={suffix}
            thousandSeparator={thousandSeparator}
            placeholder={placeholder}
            onValueChange={(val) => {
              onChangeHandler({ target: { value: val.value, name } }, val.value, val);
            }}
            disabled={disabled}
            testid={testid}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            onBlur={onBlur}
            {...numberFormatOptions}
            isAllowed={isAllowed}
            className={numberInputClassName}
            autoFocus={autofocus}
            allowEmptyFormatting={allowEmptyFormatting}
            allowNegative={allowNegative}
            decimalScale={decimalScale}
            allowLeadingZeros={allowLeadingZeros}
          />
        ) : textArea ? (
          <textarea
            testid={testid}
            id={id}
            rows={4}
            name={name}
            className={classNames(type === 'search' ? styles.searchInput : '', inputClassName)}
            disabled={disabled}
            type={typeValue === 'number' ? 'text' : typeValue}
            value={inputValue}
            onChange={onChangeHandler}
            placeholder={placeholder}
            autoFocus={autofocus}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            onBlur={onBlurHandler}
            maxLength={maxLength}
          />
        ) : (
          <input
            testid={testid}
            id={id}
            name={name}
            className={classNames(type === 'search' ? styles.searchInput : '', inputClassName)}
            disabled={disabled}
            type={typeValue === 'number' ? 'text' : typeValue}
            value={inputValue}
            onChange={onChangeHandler}
            placeholder={placeholder}
            autoFocus={autofocus}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            onBlur={onBlurHandler}
            maxLength={maxLength}
          />
        )}
        {type === 'password' && (
          <PasswordIcon
            onClick={onChangePasswordMode}
            className={classNames(styles.passwordIcon, styles[typeValue])}
            mode={typeValue === 'text' ? PasswordIcon.ON : PasswordIcon.OFF}
          />
        )}
        {type === 'search' && <SearchIcon className={styles.searchIcon} />}
        {icon && <div className={classNames(styles.icon, iconClassName)}>{icon}</div>}
        {!!removeHandler && (
          <button
            testid="input_remove"
            className={classNames(styles.removeBtn, removeBtnClassName)}
            onClick={removeHandler}
            type="button"
          >
            {removeIcon || <MinusRounded />}
          </button>
        )}
      </div>
      {error && (
        <div testid="validation" className={classNames(styles.error, errorClassName)}>
          {error}
        </div>
      )}
    </div>
  );
};

Input.LIGHT = 'light';
Input.LIGHT_FULL = 'lightFull';
Input.LIGHT_ROUND = 'lightRound';
Input.LIGHT_ROUNDED = 'lightRounded';
Input.FULL = 'full';

Input.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  inputHolderClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  removeBtnClassName: PropTypes.string,
  numberInputClassName: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  replacePlaceholderWithLabelWhenHasValue: PropTypes.bool,
  error: PropTypes.string,
  variant: PropTypes.string,
  altLabel: PropTypes.string,
  icon: PropTypes.node,
  labelRightIcon: PropTypes.node,
  iconLeft: PropTypes.node,
  removeIcon: PropTypes.node,
  autofocus: PropTypes.bool,
  removeHandler: PropTypes.func,
  classAltLabel: PropTypes.string,
  testid: PropTypes.string,
  isNumberFormat: PropTypes.bool,
  allowEmptyFormatting: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  thousandSeparator: PropTypes.bool,
  numberFormatOptions: PropTypes.shape({}),
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  maxNumber: PropTypes.number,
  minNumber: PropTypes.number,
  maxLength: PropTypes.number,
  saveOnChange: PropTypes.bool,
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.number,
  allowLeadingZeros: PropTypes.bool,
  errorClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  textArea: PropTypes.bool,
  restrictEmoji: PropTypes.bool,
};

Input.defaultProps = {
  id: undefined,
  name: undefined,
  placeholder: undefined,
  className: '',
  inputHolderClassName: '',
  numberInputClassName: '',
  iconClassName: '',
  removeBtnClassName: '',
  inputClassName: '',
  classAltLabel: '',
  errorClassName: '',
  type: 'text',
  label: undefined,
  labelRightIcon: undefined,
  onChange: () => {},
  value: '',
  disabled: false,
  replacePlaceholderWithLabelWhenHasValue: false,
  error: undefined,
  variant: Input.FULL,
  altLabel: undefined,
  icon: undefined,
  iconLeft: undefined,
  removeIcon: undefined,
  autofocus: undefined,
  removeHandler: undefined,
  testid: undefined,
  isNumberFormat: false,
  allowEmptyFormatting: false,
  prefix: undefined,
  suffix: undefined,
  thousandSeparator: true,
  numberFormatOptions: {},
  onKeyPress: () => {},
  onFocus: () => {},
  onBlur: () => {},
  maxNumber: undefined,
  minNumber: undefined,
  saveOnChange: true,
  allowNegative: false,
  decimalScale: 2,
  allowLeadingZeros: false,
  textArea: false,
  restrictEmoji: false,
};

export default Input;
