import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownFull } from 'components/Icons';

import { Header } from '..';
import Actions from './Actions';
import DateTime from './DateTime';
import { PdfViewModal, Wrapper } from 'components';
import { showErrorMessage } from 'helpers';
import { Table, Space } from 'components-antd';
import { previewDocumentEffect } from 'store/effects/transactions';
import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';
import { getTransactionAccessSelector } from 'store/selectors/transaction';
import {
  getTransactionDocumentsFilterSelector,
  getTransactionDocumentsSelector,
} from 'store/selectors/transactionDocuments';
import {
  getTransactionDocumentsFilterEffect,
  setTransactionDocumentsPageEffect,
} from 'store/effects/transactions';
import { Avatar } from 'components';

import styles from './styles.module.scss';
import { getTransactionFolderSelector } from 'store/selectors/transactionFolder';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { FILE_TYPES } from 'settings/constants/common';

const DocumentsContent = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { fullAccess } = useSelector(getTransactionAccessSelector);
  const { folders } = useSelector(getTransactionFolderSelector) || [];
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isFolderOpen, setIsFolderOpen] = useState(false);
  const [folderData, setFolderData] = useState([]);
  const [document, setDocument] = useState(null);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [folder, setFolder] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { filter, isPending } = useSelector(getTransactionDocumentsFilterSelector);
  const { isClient } = useSelector(getUserRolesMapSelector);

  const { className, loading } = props;

  const { documents, count, countPerPage, page } = useSelector(getTransactionDocumentsSelector);

  const handleBackClick = () => {
    setIsFolderOpen(false);
    setFolder(null);
  };

  useEffect(() => {
    if (isFolderOpen) {
      setFolderData(documents?.filter((doc) => doc.FolderId === folder.Id));
    }
  }, [documents, isFolderOpen]);

  const getDocumentsData = useMemo(
    () => (isClient ? documents : documents?.filter((doc) => doc.FolderId === null)),
    [documents],
  );

  const mappingFolders = useMemo(
    () =>
      folders?.map((folder) => {
        return {
          ...folder,
          UploaderUserId: folder.OwnerId,
          DateUploaded: folder.CreatedDate,
          Uploader: folder.Owner,
          isFolder: true,
          UpdatedDate: null,
        };
      }),
    [folders],
  );

  const folderAndDocuments =
    isFilterApplied || filter?.name
      ? [...(documents || [])]
      : [...(mappingFolders || []), ...(getDocumentsData || [])];

  const onPreview = (DocumentVaultUUID, Title) => {
    setLoadingPreview(true);
    dispatch(
      previewDocumentEffect({ DocumentVaultUUID }, {}, (err, response) => {
        setLoadingPreview(false);
        if (err) {
          return showErrorMessage(err);
        }
        setIsPreviewOpen(true);
        setDocument({
          DocumentBuffer: response,
          Filename: Title || response.headers['file-name'],
        });
      }),
    );
  };
  const openFolder = (data) => {
    setIsFolderOpen(true);
    setFolderData(documents.filter((doc) => doc.FolderId === data.Id));
    setFolder(data);
  };

  const sortableColumnTitle =
    (titleLabel, key) =>
    ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(({ column }) => column.key === key);
      const sortAsc = sortedColumn?.order === 'ascend';
      return (
        <div className={styles.sortedColumnTitle}>
          {titleLabel}
          {sortedColumn?.order && (
            <ArrowDownFull
              className={classNames(styles.sorterIcon, { [styles.sorterUp]: sortAsc })}
            />
          )}
        </div>
      );
    };

  const columns = [
    {
      title: sortableColumnTitle('Name', 'name'),
      key: 'name',
      dataIndex: 'Title',
      sorter: (a, b) => {
        if (a.Title < b.Title) return -1;
        if (a.Title > b.Title) return 1;
        return 0;
      },
      width: 350,
      render: (_, { Title, isFolder }) => (
        <Space size="small" className={styles.documentName}>
          {isFolder ? <Icon variant={Icon.FOLDER} /> : <Icon variant={Icon.FILE} />}
          <strong>{Title}</strong>
        </Space>
      ),
    },
    {
      title: sortableColumnTitle('Owner', 'owners'),
      key: 'owners',
      dataIndex: 'Uploader',
      sorter: (a, b) => {
        if (a.Uploader.FirstName < b.Uploader.FirstName) return -1;
        if (a.Uploader.FirstName > b.Uploader.FirstName) return 1;
        return 0;
      },
      render: (Uploader) => {
        return (
          <div className={styles.clientField}>
            <Avatar
              avatarClassName={styles.avatar}
              placeholder={
                <p
                  className={styles.avatarText}
                >{`${Uploader.FirstName[0]} ${Uploader.LastName[0]}`}</p>
              }
            />
            <span>{`${Uploader.FirstName} ${Uploader.LastName}`}</span>
          </div>
        );
      },
    },
    {
      title: sortableColumnTitle('Created', 'created'),
      key: 'created',
      dataIndex: 'DateUploaded',
      sorter: (a, b) => moment(a.DateUploaded).unix() - moment(b.DateUploaded).unix(),
      render: (_, { DateUploaded }) => <DateTime value={DateUploaded} />,
    },
    {
      title: sortableColumnTitle('Last Edited', 'edited'),
      key: 'edited',
      dataIndex: 'UpdatedDate',
      sorter: (a, b) =>
        (a.UpdatedDate, b.UpdatedDate)
          ? moment(a.UpdatedDate).unix() - moment(b.UpdatedDate).unix()
          : 0,
      render: (_, { UpdatedDate }) =>
        UpdatedDate ? <DateTime value={UpdatedDate} /> : <span> - </span>,
    },
    {
      title: sortableColumnTitle('Tags', 'tags'),
      key: 'tags',
      dataIndex: ['Category', 'Name'],
      sorter: (a, b) => {
        if (a.Category?.Name < b.Category?.Name) return -1;
        if (a.Category?.Name > b.Category?.Name) return 1;
      },
      render: (_, { Category }) => (Category?.Name ? Category.Name : <span>-</span>),
    },
    {
      title: '',
      key: 'actions',
      render: (_, data) => (
        <Actions
          id={data.Id}
          data={data}
          DocumentVaultUUID={data.DocumentVaultUUID}
          auditLog={data?.Meta?.FormMeta?.auditLogDocumentVaultUUID}
          fullAccess={fullAccess}
          setIsPreviewOpen={setIsPreviewOpen}
          onPreview={onPreview}
          folders={folders}
          openFolder={openFolder}
          isFolderOpen={isFolderOpen}
        />
      ),
    },
  ];

  const handlePageChange = (newPage) => {
    dispatch(setTransactionDocumentsPageEffect({ page: newPage - 1 }));
    dispatch(getTransactionDocumentsFilterEffect({ transactionId: params?.Id }));
  };
  return (
    <>
      {isPreviewOpen && (
        <PdfViewModal
          isOpen={isPreviewOpen}
          file={document}
          onClose={() => setIsPreviewOpen(false)}
          showInCohesiveFlow={true}
        />
      )}

      <div
        testid="transaction_documents"
        className={classNames(styles.documentsContent, className)}
      >
        <Header
          setIsFilterApplied={setIsFilterApplied}
          folderData={folder}
          handleBackClick={handleBackClick}
        />

        <Wrapper isPending={isPending || loading}>
          <div className={styles.container}>
            <div className={styles.tableContainer}>
              <Table
                rowClassName={styles.rowClick}
                onRow={(record) => ({
                  onClick: () => {
                    if (!record.isFolder) {
                      (record.Meta?.MimeType === FILE_TYPES.PDF ||
                        (record.Meta?.MimeType === FILE_TYPES.OCTET_STREAM &&
                          !record.Meta?.Filename?.endsWith('.zip'))) &&
                        onPreview(record.DocumentVaultUUID, record?.Title);
                    } else {
                      openFolder(record);
                    }
                  },
                })}
                loading={loadingPreview}
                pagination={false}
                className={styles.documentsTable}
                columns={columns}
                dataSource={isFolderOpen ? folderData : folderAndDocuments}
              />
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

DocumentsContent.propTypes = {
  className: PropTypes.string,
};

DocumentsContent.defaultProps = {
  className: '',
};

export default DocumentsContent;
