import React, { useState } from 'react';
import classNames from 'classnames';
import { AxiosError } from 'axios';

import { Button } from 'components-antd';
import { FormattedPhone } from 'components';
import { verifyPhoneNumberWithCode } from 'api/user';
import { showErrorMessage } from 'helpers';

import styles from './styles.module.scss';

interface VerifyCodeStepProps {
  phoneNumber?: string;
  remainingSecondsToResendCode: number | null;
  onResendCodeClick: () => void;
  onEditPhoneNumberClick: () => void;
  onCodeVerfied: (codeVerifiedResponse: any) => Promise<void>;
}

const CODE_LENGTH = 4;

export const VerifyCodeStep: React.FC<VerifyCodeStepProps> = (props) => {
  const [otp, setOtp] = useState<string[]>(Array(CODE_LENGTH).fill(''));
  const [errorText, setErrorText] = useState<string | null>(null);

  const handleInputChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return; // Allow only digits
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically move to the next input if a digit is entered
    if (value && index < CODE_LENGTH - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      nextInput?.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      const prevInput = document.getElementById(`otp-${index - 1}`);
      prevInput?.focus();
    }
  };

  const getResendText = () => {
    if (props.remainingSecondsToResendCode === null) {
      return null;
    }

    if (props.remainingSecondsToResendCode > 0) {
      return <span>Resend it in {props.remainingSecondsToResendCode} seconds</span>;
    }

    return (
      <span>
        <a
          onClick={() => {
            setErrorText(null);
            setOtp(Array(CODE_LENGTH).fill(''));
            props.onResendCodeClick();
          }}
          href="#"
        >
          Resend
        </a>
      </span>
    );
  };

  function handleVerifyCodeError(err: any) {
    const axiosError = err as AxiosError;

    if (!axiosError.isAxiosError) {
      showErrorMessage('Unknown error occured while verifying the phone number');
    }

    const errorMessageMapping = {
      INVALID_CODE: 'Sorry, this passcode is incorrect. Please try again.',
      CODE_EXPIRED:
        'This passcode is expired. Please try requesting new code via the "Resend" button',
      MAX_ATTEMPTS_TO_VERIFY:
        'Maximum attempts reached. Please request a new code via the “Resend” button.',
    };

    const data = axiosError.response?.data;
    if (data?.code in errorMessageMapping) {
      setErrorText(errorMessageMapping[data.code]);
    } else {
      showErrorMessage(data?.message || 'Unknown error occurred while verifying passcode');
    }

    setOtp(Array(CODE_LENGTH).fill(''));
  }

  const handleContinue = async () => {
    try {
      setErrorText(null);
      const enteredCode = otp.join('');
      const response = await verifyPhoneNumberWithCode({ code: enteredCode });
      props.onCodeVerfied(response);
    } catch (err) {
      handleVerifyCodeError(err);
    }
  };

  return (
    <div className={styles.layout}>
      <h2 className={styles.stepHeader}>Enter passcode</h2>
      <div className={styles.text}>
        We sent you a {CODE_LENGTH}-digit passcode to{' '}
        <FormattedPhone className={styles.inlinedPhoneNumber}>{props.phoneNumber}</FormattedPhone>
      </div>
      {props.remainingSecondsToResendCode !== null && (
        <div className={styles.text}>
          Didn’t receive it? {getResendText()}
          <span> or </span>
          <a onClick={props.onEditPhoneNumberClick} href="#">
            Edit Phone #
          </a>
        </div>
      )}
      <div className={styles.inputOtpContainer}>
        {otp.map((digit, index) => (
          <input
            key={index}
            id={`otp-${index}`}
            type="text"
            maxLength={1}
            value={digit}
            className={styles.input}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        ))}
      </div>
      {errorText && (
        <div className={styles.errorTextWrapper}>
          <div className={styles.invalidCodeText}>{errorText}</div>
        </div>
      )}
      <div>
        <Button
          disabled={otp.some((digit) => !digit)} // Ensure all fields are filled
          onClick={handleContinue}
          variant={'secondary'}
          className={styles.continueButton}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
