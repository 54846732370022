import React from 'react';

const NeighborhoodIcon = ({ color = '#747475' }: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M15.5 20.75V4.25H4.25V20.75H15.5ZM15.5 20.75H21.4982V9.5L15.4982 8"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 20.75V17H11.75V20.75"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 7.45C7 7.20147 7.19188 7 7.42857 7H8.28571C8.52241 7 8.71429 7.20147 8.71429 7.45V8.35C8.71429 8.59853 8.52241 8.8 8.28571 8.8H7.42857C7.19188 8.8 7 8.59853 7 8.35V7.45Z"
      fill={color}
    />
    <path
      d="M7 10.45C7 10.2015 7.19188 10 7.42857 10H8.28571C8.52241 10 8.71429 10.2015 8.71429 10.45V11.35C8.71429 11.5985 8.52241 11.8 8.28571 11.8H7.42857C7.19188 11.8 7 11.5985 7 11.35V10.45Z"
      fill={color}
    />
    <path
      d="M7 13.45C7 13.2015 7.19188 13 7.42857 13H8.28571C8.52241 13 8.71429 13.2015 8.71429 13.45V14.35C8.71429 14.5985 8.52241 14.8 8.28571 14.8H7.42857C7.19188 14.8 7 14.5985 7 14.35V13.45Z"
      fill={color}
    />
    <path
      d="M11 7.45C11 7.20147 11.1919 7 11.4286 7H12.2857C12.5224 7 12.7143 7.20147 12.7143 7.45V8.35C12.7143 8.59853 12.5224 8.8 12.2857 8.8H11.4286C11.1919 8.8 11 8.59853 11 8.35V7.45Z"
      fill={color}
    />
    <path
      d="M11 10.45C11 10.2015 11.1919 10 11.4286 10H12.2857C12.5224 10 12.7143 10.2015 12.7143 10.45V11.35C12.7143 11.5985 12.5224 11.8 12.2857 11.8H11.4286C11.1919 11.8 11 11.5985 11 11.35V10.45Z"
      fill={color}
    />
    <path
      d="M11 13.45C11 13.2015 11.1919 13 11.4286 13H12.2857C12.5224 13 12.7143 13.2015 12.7143 13.45V14.35C12.7143 14.5985 12.5224 14.8 12.2857 14.8H11.4286C11.1919 14.8 11 14.5985 11 14.35V13.45Z"
      fill={color}
    />
    <path
      d="M17.5 11.45C17.5 11.2015 17.6919 11 17.9286 11H18.7857C19.0224 11 19.2143 11.2015 19.2143 11.45V12.35C19.2143 12.5985 19.0224 12.8 18.7857 12.8H17.9286C17.6919 12.8 17.5 12.5985 17.5 12.35V11.45Z"
      fill={color}
    />
    <path
      d="M17.5 14.45C17.5 14.2015 17.6919 14 17.9286 14H18.7857C19.0224 14 19.2143 14.2015 19.2143 14.45V15.35C19.2143 15.5985 19.0224 15.8 18.7857 15.8H17.9286C17.6919 15.8 17.5 15.5985 17.5 15.35V14.45Z"
      fill={color}
    />
    <path
      d="M17.5 17.45C17.5 17.2015 17.6919 17 17.9286 17H18.7857C19.0224 17 19.2143 17.2015 19.2143 17.45V18.35C19.2143 18.5985 19.0224 18.8 18.7857 18.8H17.9286C17.6919 18.8 17.5 18.5985 17.5 18.35V17.45Z"
      fill={color}
    />
  </svg>
);

export default NeighborhoodIcon;
