import PropTypes from 'prop-types';

export const Icons = ({ testid, className, variant, onClick }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.BOOKMARK:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 7V19L12 16L17 19V7C17 5.89543 16.1046 5 15 5H9C7.89543 5 7 5.89543 7 7Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.BOOKMARK_DARK:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 7.5V21L12 17.5714L18 21V7.5C18 6.11929 16.8807 5 15.5 5H8.5C7.11929 5 6 6.11929 6 7.5Z"
              fill="#252D44"
              stroke="#252D44"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.SETTINGS:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.375 17.25C12.375 16.2145 11.5355 15.375 10.5 15.375C9.46447 15.375 8.625 16.2145 8.625 17.25C8.625 18.2855 9.46447 19.125 10.5 19.125C11.5355 19.125 12.375 18.2855 12.375 17.25Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.5 17.25H8.625"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.375 17.25L19.5 17.25"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.375 12C18.375 10.9645 17.5355 10.125 16.5 10.125C15.4645 10.125 14.625 10.9645 14.625 12C14.625 13.0355 15.4645 13.875 16.5 13.875C17.5355 13.875 18.375 13.0355 18.375 12Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.5 12L14.625 12"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.375 12H19.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.375 6.75C9.375 5.71447 8.53553 4.875 7.5 4.875C6.46447 4.875 5.625 5.71447 5.625 6.75C5.625 7.78553 6.46447 8.625 7.5 8.625C8.53553 8.625 9.375 7.78553 9.375 6.75Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.5 6.75H5.625"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.75 6.75L19.5002 6.75"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.HOUSE:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 12V19C10 19.5523 10.4477 20 11 20H21C21.5523 20 22 19.5523 22 19V12C22 11.6852 21.8518 11.3889 21.6 11.2L16.6 7.45C16.2444 7.18333 15.7556 7.18333 15.4 7.45L10.4 11.2C10.1482 11.3889 10 11.6852 10 12Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.5 18.5V18.5C6.94772 18.5 6.5 18.0523 6.5 17.5V10.5C6.5 10.1852 6.64819 9.88885 6.9 9.7L11.9 5.95C12.2556 5.68333 12.7444 5.68333 13.1 5.95L13.25 6.0625"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 17V17C3.44772 17 3 16.5523 3 16V9C3 8.68524 3.14819 8.38885 3.4 8.2L8.4 4.45C8.75556 4.18333 9.24444 4.18333 9.6 4.45L9.75 4.5625"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 20V15.5H18V20"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.RECOMMENDED:
        return (
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5014 16.793L7.35802 19.4971L8.34052 13.7696L4.17969 9.7138L9.92969 8.8788L12.5014 3.66797L15.073 8.8788L20.823 9.7138L16.6622 13.7696L17.6447 19.4971L12.5014 16.793Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.STAR:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 4C15 8 14 9 10 10C14 11 15 12 16 16C17 12 18 11 22 10C18 9 17 8 16 4Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.5 13C4.8 15.45 4.45 15.8 2 16.5C4.45 17.2 4.8 17.55 5.5 20C6.2 17.55 6.55 17.2 9 16.5C6.55 15.8 6.2 15.45 5.5 13Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.OPEN_HOUSES:
        return (
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 19.1978V4.80217C5.5 4.33347 5.82553 3.92766 6.28307 3.82598L13.2831 2.27043C13.9076 2.13165 14.5 2.60687 14.5 3.24662V20.7534C14.5 21.3931 13.9076 21.8684 13.2831 21.7296L6.28307 20.174C5.82553 20.0723 5.5 19.6665 5.5 19.1978Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 5H17.5C18.0523 5 18.5 5.44772 18.5 6V18C18.5 18.5523 18.0523 19 17.5 19H14.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="12" cy="12" r="1.5" fill="#515151" />
          </svg>
        );
      case Icons.PRICE_REDUCTIONS:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 7L7.79301 11.7934C8.18354 12.1839 8.81675 12.1839 9.20728 11.7934L11.7929 9.20757C12.1834 8.81702 12.8166 8.81701 13.2071 9.20754L21 17.0004M21 17.0004V11.0004M21 17.0004H15"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.BACK_ON_MARKET:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 19H18C18.5523 19 19 18.5523 19 18V9.01462C19 8.69167 18.844 8.3886 18.5812 8.20088L12.6098 3.93557C12.2484 3.67741 11.76 3.68858 11.4107 3.96299L5.38218 8.69972C5.14089 8.8893 5 9.17917 5 9.48604V18M11 19L14 16M11 19L14 22"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.STATUS_CHANGES:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 21V13.5M7 13.5L4 16.5M7 13.5L10 16.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 3V10.5M17 10.5L14 7.5M17 10.5L20 7.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect x="4" y="3" width="6" height="6" rx="1" stroke="#515151" strokeWidth="2" />
            <rect
              width="6"
              height="6"
              rx="1"
              transform="matrix(1 0 0 -1 14 21)"
              stroke="#515151"
              strokeWidth="2"
            />
          </svg>
        );
      case Icons.TOURS:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4008 4.80078H6.60078C5.60667 4.80078 4.80078 5.60667 4.80078 6.60078V17.4008C4.80078 18.3949 5.60667 19.2008 6.60078 19.2008H17.4008C18.3949 19.2008 19.2008 18.3949 19.2008 17.4008V6.60078C19.2008 5.60667 18.3949 4.80078 17.4008 4.80078Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.6016 3.59961V5.99961"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.39844 3.59961V5.99961"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.80078 9.59961H19.2008"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.1992 14.4004L13.7992 14.4004"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.FAVOURITES_DROPWDOWN:
        return (
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.21234 13.2743C4.35837 12.3958 3.8335 11.2029 3.8335 9.88889C3.8335 7.18883 6.04969 5 8.7835 5C10.4576 5 11.9377 5.82084 12.8335 7.07726C13.7293 5.82084 15.2094 5 16.8835 5C19.6173 5 21.8335 7.18883 21.8335 9.88889C21.8335 11.2674 21.2559 12.5126 20.3266 13.4013L14.2573 19.5561C13.4742 20.3503 12.1927 20.3504 11.4094 19.5564L5.21234 13.2743Z"
              stroke="#AAABAB"
              strokeWidth="2"
            />
          </svg>
        );
      case Icons.FAVOURITES:
        return (
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.21234 13.2743C4.35837 12.3958 3.8335 11.2029 3.8335 9.88889C3.8335 7.18883 6.04969 5 8.7835 5C10.4576 5 11.9377 5.82084 12.8335 7.07726C13.7293 5.82084 15.2094 5 16.8835 5C19.6173 5 21.8335 7.18883 21.8335 9.88889C21.8335 11.2674 21.2559 12.5126 20.3266 13.4013L14.2573 19.5561C13.4742 20.3503 12.1927 20.3504 11.4094 19.5564L5.21234 13.2743Z"
              stroke="#515151"
              strokeWidth="2"
            />
          </svg>
        );
      case Icons.NEW_SEARCH:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9987 19.0007L14.332 14.334"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.CHECKMARK:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_10454_159766)">
              <path
                d="M4 10L8 14L16 6"
                stroke="#04A451"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_10454_159766">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case Icons.COMMENT:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.7506 15.7212L6.67067 16.113L6.86853 15.6483L6.61196 15.2132L5.7506 15.7212ZM4.285 19.1633L3.36493 18.7716H3.36493L4.285 19.1633ZM4.925 19.8257L4.56508 18.8927H4.56507L4.925 19.8257ZM8.54151 18.4305L9.02361 17.5544L8.61583 17.33L8.18159 17.4976L8.54151 18.4305ZM18.4051 12.0013C18.4051 15.4991 15.5696 18.3346 12.0718 18.3346V20.3346C16.6741 20.3346 20.4051 16.6037 20.4051 12.0013H18.4051ZM12.0718 5.66797C15.5696 5.66797 18.4051 8.5035 18.4051 12.0013H20.4051C20.4051 7.39893 16.6741 3.66797 12.0718 3.66797V5.66797ZM5.73843 12.0013C5.73843 8.5035 8.57396 5.66797 12.0718 5.66797V3.66797C7.46939 3.66797 3.73843 7.39893 3.73843 12.0013H5.73843ZM6.61196 15.2132C6.05706 14.2723 5.73843 13.1755 5.73843 12.0013H3.73843C3.73843 13.5426 4.15778 14.9889 4.88923 16.2292L6.61196 15.2132ZM5.20507 19.5551L6.67067 16.113L4.83052 15.3295L3.36493 18.7716L5.20507 19.5551ZM4.56507 18.8927C4.97738 18.7337 5.37819 19.1485 5.20507 19.5551L3.36493 18.7716C2.84556 19.9914 4.048 21.2359 5.28492 20.7587L4.56507 18.8927ZM8.18159 17.4976L4.56508 18.8927L5.28492 20.7587L8.90143 19.3635L8.18159 17.4976ZM12.0718 18.3346C10.9648 18.3346 9.92692 18.0515 9.02361 17.5544L8.05942 19.3067C9.25061 19.9621 10.6192 20.3346 12.0718 20.3346V18.3346Z"
              fill="#AAABAB"
            />
            <circle cx="19" cy="5" r="4" fill="#AAABAB" stroke="white" strokeWidth="2" />
          </svg>
        );
      case Icons.CARET_DOWN:
        return (
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.625 4.5L6 7.875L9.375 4.5"
              stroke="#181d2c"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.CLOSE_ROUND:
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="1" width="42" height="42" rx="21" stroke="#E8E9EA" strokeWidth="2" />
            <path d="M27 27L17 17" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M17 27L27 17" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case Icons.PLUS:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path d="M12 6V18" stroke="#AAABAB" strokeWidth="2" strokeLinecap="round" />
            <path d="M6 12L18 12" stroke="#AAABAB" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case Icons.MATCHES:
        return (
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.75 8.25C13.75 10.3211 12.0711 12 10 12C7.92893 12 6.25 10.3211 6.25 8.25C6.25 6.17893 7.92893 4.5 10 4.5C12.0711 4.5 13.75 6.17893 13.75 8.25Z"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path
              d="M15.625 4.55078C17.3986 4.84833 18.75 6.39081 18.75 8.24893C18.75 10.1071 17.3986 11.6495 15.625 11.9471"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M4.375 19.1875C4.72557 17.1843 6.33733 15.4375 8.12489 15.4375H11.875C13.6626 15.4375 15.2744 17.1843 15.625 19.1875"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.875 15.4375C18.6626 15.4375 20.2744 17.1843 20.625 19.1875"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.MAP:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5 19L19.5 17V5L14.5 7M14.5 19L9.5 17M14.5 19V7M9.5 17L4.5 19V7L9.5 5M9.5 17V5M9.5 5L14.5 7"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.CLIENT_SEARCHES:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 16C11.5376 16 14 13.5376 14 10.5C14 7.46243 11.5376 5 8.5 5C5.46243 5 3 7.46243 3 10.5C3 13.5376 5.46243 16 8.5 16Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 19L12.5 14.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.0002 18.9977L17.5015 14.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.1331 14.6292C17.8609 13.9889 18.4065 13.1676 18.7146 12.2486C19.0228 11.3295 19.0826 10.3454 18.888 9.39577C18.6934 8.44616 18.2513 7.56486 17.6064 6.84113C16.9616 6.1174 16.1369 5.57695 15.2159 5.27453"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.STAR_2:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_49157_178695)">
              <path
                d="M12.2343 17.8723C12.0886 17.7957 11.9146 17.7957 11.7689 17.8723L6.72641 20.5235C6.35959 20.7164 5.93086 20.4049 6.00093 19.9964L6.96418 14.3811C6.99201 14.2189 6.93823 14.0534 6.82039 13.9385L2.74118 9.96224C2.44441 9.67296 2.60819 9.16895 3.01833 9.10939L8.65546 8.29078C8.81833 8.26713 8.95914 8.16484 9.03197 8.01725L11.5533 2.90851C11.7367 2.53688 12.2666 2.53688 12.45 2.90851L14.9713 8.01725C15.0441 8.16484 15.1849 8.26713 15.3478 8.29078L20.9849 9.10939C21.3951 9.16895 21.5589 9.67296 21.2621 9.96224L17.1829 13.9385C17.065 14.0534 17.0112 14.2189 17.0391 14.3811L18.0023 19.9964C18.0724 20.4049 17.6437 20.7164 17.2768 20.5235L12.2343 17.8723Z"
                stroke="#515151"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_49157_178695">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case Icons.COMMENTS:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 4.72616C12.696 4.68776 12.3862 4.66797 12.0718 4.66797C8.02168 4.66797 4.73843 7.95121 4.73843 12.0013C4.73843 13.3591 5.10742 14.6306 5.7506 15.7212L4.285 19.1633C4.11188 19.5699 4.51269 19.9848 4.925 19.8257L8.54151 18.4305C9.58876 19.0068 10.792 19.3346 12.0718 19.3346C16.1219 19.3346 19.4051 16.0514 19.4051 12.0013C19.4051 11.6623 19.3821 11.3287 19.3376 11.002"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <circle cx="19" cy="5" r="3" fill="#515151" />
          </svg>
        );
      case Icons.MATCH_FAV:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 8C12 9.65685 10.6569 11 9 11C7.34315 11 6 9.65685 6 8C6 6.34315 7.34315 5 9 5C10.6569 5 12 6.34315 12 8Z"
              stroke="#262626"
              strokeWidth="2"
            />
            <path
              d="M4 17C4.29915 15.3974 5.67452 14 7.1999 14H8"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.6894 17.1371C11.2624 16.6979 11 16.1015 11 15.4444C11 14.0944 12.1081 13 13.475 13C14.3121 13 15.0521 13.4104 15.5 14.0386C15.9479 13.4104 16.6879 13 17.525 13C18.8919 13 20 14.0944 20 15.4444C20 16.1337 19.7112 16.7563 19.2465 17.2007L16.9238 19.5561C16.1407 20.3503 14.8592 20.3504 14.0759 19.5564L11.6894 17.1371Z"
              stroke="#262626"
              strokeWidth="2"
            />
          </svg>
        );

      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className} style={{ display: 'flex' }}>
      {renderIcon()}
    </div>
  );
};

Icons.BOOKMARK = 'Bookmark';
Icons.BOOKMARK_DARK = 'BookmarkDark';
Icons.SETTINGS = 'Settings';
Icons.HOUSE = 'House';
Icons.RECOMMENDED = 'Recommended';
Icons.STAR = 'Star';
Icons.OPEN_HOUSES = 'OpenHouses';
Icons.PRICE_REDUCTIONS = 'PriceReductions';
Icons.BACK_ON_MARKET = 'BackOnMarket';
Icons.STATUS_CHANGES = 'StatusChanges';
Icons.TOURS = 'Tours';
Icons.FAVOURITES = 'Favourites';
Icons.FAVOURITES_DROPWDOWN = 'FavouritesDropdown';
Icons.NEW_SEARCH = 'NewSearch';
Icons.CHECKMARK = 'Checkmark';
Icons.COMMENT = 'Comment';
Icons.COMMENTS = 'Comments';
Icons.CARET_DOWN = 'CaretDown';
Icons.CLOSE_ROUND = 'CloseRound';
Icons.PLUS = 'Plus';
Icons.MATCHES = 'Matches';
Icons.MAP = 'Map';
Icons.STAR_2 = 'Star_2';
Icons.CLIENT_SEARCHES = 'ClientSearches';
Icons.MATCH_FAV = 'MatchFav';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  testid: undefined,
  onClick: () => {},
};
