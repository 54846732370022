import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCtaData } from 'api/kits';
import { Wrapper } from 'components';
import { PageWrapper } from 'pages/Workshop/PageWrapper';
import { TransactionsAndProjectsTable } from 'pages/Workshop/Transactions/components/TransactionsAndProjectsTable';
import {
  requestGetTransactionsEffect,
  setTransactionFiltersAppliedEffect,
  resetTransactionsEffect,
  setTransactionsIsArchiveEffect,
} from 'store/effects/transactions/all';
import {
  getTransactionsSelector,
  getTransactionsFiltersAppliedSelector,
} from 'store/selectors/transactions';
import { Footer } from 'components-antd';
import { resetTransactionEffect } from 'store/effects/transactions';
import { getViewModeSelector } from 'store/selectors/workshop';
import { ViewModes } from 'app-constants';
import { updateViewModeAction } from 'store/actions/workshop';
import { LocationService } from 'services';
import { getUserRolesMapSelector } from 'store/selectors/user';
import styles from './styles.module.scss';
import useIsProjectRoute from 'hooks/use-is-project-route';
import { addIsProjectFilterForProjectRoutes } from 'utils/addIsProjectFilterForProjectRoutes';
import moment from 'moment';

const Transactions = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const viewMode = useSelector(getViewModeSelector);
  const { isIdle, isPending, isArchive, transactionFilter, isFiltersApplied, projectFilter } =
    useSelector(getTransactionsSelector);
  const locationSrv = new LocationService();
  const location = useLocation();
  const isProject = useIsProjectRoute();
  const queryParams = new URLSearchParams(location.search);
  const kitUuidParam = queryParams.get('kitUuid');
  const { isClient } = useSelector(getUserRolesMapSelector);
  const filtersApplied = useSelector(getTransactionsFiltersAppliedSelector);
  const performKitCta = () => {
    if (kitUuidParam) {
      getCtaData(kitUuidParam);
    }
  };

  useEffect(() => {
    if (isClient) performKitCta();
  }, []);

  useEffect(() => {
    dispatch(resetTransactionsEffect());
  }, []);

  locationSrv.setLocation(location);
  const query = locationSrv.getQuery();

  useEffect(() => {
    if (
      query?.statuses ||
      query?.dueToday ||
      query?.dueWeek ||
      query?.transactions ||
      location?.state?.fromTransactionRoom
    ) {
      if (query?.isArchive) dispatch(setTransactionsIsArchiveEffect(true));
      if (Object.keys(filtersApplied)?.length > 0)
        dispatch(setTransactionFiltersAppliedEffect(true));
    } else {
      dispatch(resetTransactionEffect());
    }
    dispatch(
      requestGetTransactionsEffect(
        {
          filters: location?.state?.fromTransactionRoom
            ? addIsProjectFilterForProjectRoutes(isProject, filtersApplied) // filters that were applied previously
            : addIsProjectFilterForProjectRoutes(isProject, {
                ...(query?.statuses && {
                  statuses: query.statuses.split(','),
                }),
                ...((query?.dueToday || query?.dueWeek) && {
                  dueFrom: query.dueToday
                    ? moment()
                    : query.dueWeek
                    ? moment().startOf('week')
                    : undefined,
                  dueTo: query.dueToday
                    ? moment()
                    : query.dueWeek
                    ? moment().endOf('week')
                    : undefined,
                }),
                ...(query?.transactions && {
                  transactions: isNaN(query.transactions)
                    ? query.transactions.split(',').map((trxId) => +trxId)
                    : [query.transactions],
                }),
              }),
        },
        { silent: true },
      ),
    );

    return () => dispatch(updateViewModeAction(ViewModes.List));
  }, []);

  return (
    <PageWrapper className={className} testId="transactions_page">
      <Wrapper isPending={isPending}>
        <TransactionsAndProjectsTable
          isFiltersApplied={isFiltersApplied}
          isArchive={isArchive}
          transactionFilter={transactionFilter}
          projectFilter={projectFilter}
        />
      </Wrapper>
    </PageWrapper>
  );
};

Transactions.propTypes = {
  className: PropTypes.string,
};

Transactions.defaultProps = {
  className: '',
};

export default Transactions;
