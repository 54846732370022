import moment from 'moment';
import { renderStatus } from 'pages/Workshop/Forms/components/FormsTable';
import { DateTimeColumn, DocumentNameColumn } from './TableColumns';
import { Options } from '../Options';
import { AgentDocumentTypes } from 'types';

const docType = AgentDocumentTypes.Drafts;

export const draftColumns = (optionUtils) => [
  {
    key: 'title',
    title: 'Name',
    dataIndex: 'title',
    width: 275,
    render: (title) => <DocumentNameColumn name={title} docIconType={docType} />,
    sorter: (a, b) => a.title?.localeCompare(b.title),
  },
  {
    key: 'status',
    title: 'Status',
    width: 200,
    render: () => renderStatus('Draft'),
  },
  {
    key: 'address',
    title: 'Transaction',
    width: 350,
    render: (row) => <span style={{ fontSize: 16 }}>{row?.draftMeta?.address || '-'}</span>,
    sorter: (a, b) => a?.draftMeta?.address?.localeCompare(b?.draftMeta?.address),
  },
  {
    key: 'lastEdited',
    title: 'Last Edited',
    render: (row) => <DateTimeColumn date={row?.draftMeta?.lastEdit} />,
    sorter: (a, b) => {
      if (!a?.draftMeta?.lastEdit) return 1;
      if (!b?.draftMeta?.lastEdit) return -1;

      return moment(a?.draftMeta?.lastEdit).diff(moment(b?.draftMeta?.lastEdit), 'minutes');
    },
  },
  {
    key: 'options',
    title: '',
    render: (row) => <Options document_={row} type={docType} optionUtils={optionUtils} />,
  },
];
