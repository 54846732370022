import moment from 'moment';
import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';
import { setTimePeriodAction } from 'store/actions/clarity';

import styles from './styles.module.scss';

type Props = {
  defaultOption?: string;
  wrapperClassname?: string;
};
export const DateRangeFilter = ({ ...props }: Props) => {
  const dispatch = useDispatch();
  const dropdownFields = ['This Week', 'MTD', 'QTD', 'YTD'];

  const getDefaultOption = () => {
    if (props.defaultOption)
      return props.defaultOption === 'WEEK' ? 'This Week' : props.defaultOption;
    return 'YTD';
  };

  const [value, setValue] = useState(getDefaultOption());

  const onChange = (e) => {
    const selectedOption = e.target.value;
    setValue(selectedOption);
    const value = selectedOption === 'This Week' ? 'WEEK' : selectedOption;
    dispatch(setTimePeriodAction({ value }));
  };

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={styles.optionsItems}
      defaultValue={value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => {
          return (
            <MenuItem key={idx}>
              <Radio value={item} key={idx}>
                <div className={styles.option}>
                  <span className={styles.optionValue}>{item}</span>
                </div>
                {value === item && <Check className={styles.checkIcon} />}
              </Radio>
              {/* {idx === 3 && <div className={styles.customLine} />} */}
            </MenuItem>
          );
        })}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.clarityFilterWrap, props.wrapperClassname)}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.optionValue}>{value}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} size={16} />
        </a>
      </Dropdown>
    </div>
  );
};
