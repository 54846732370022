export enum RadarPortfolioViewTypes {
  ViewByClient = 'ViewByClient',
  ViewByProperty = 'ViewByProperty',
  ViewByAgent = 'ViewByAgent',
}

export enum NeighborhoodCategoryTypes {
  New = 'New',
  StatusChanges = 'Status Changes',
  PriceReductions = 'Price Reductions',
  PriceIncrease = 'Price Increase',
  BackOnMarket = 'Back on Market',
  OpenHouses = 'Open Houses',
}

export enum NeighborhoodStatusTypes {
  Sold = 'Sold',
  ActiveUnderContract = 'ActiveUnderContract',
  ComingSoon = 'ComingSoon',
  AcceptingBackups = 'AcceptingBackups',
}

export enum NeighborhoodFilterTypes {
  All = 'All',
  New = 'New',
  StatusChanges = 'Status Changes',
  PriceReductions = 'Price Reductions',
  PriceIncrease = 'Price Increase',
  BackOnMarket = 'Back on Market',
  OpenHouses = 'Open Houses',
  Sold = 'Sold',
}

export enum NeighborhoodDayRangeTypes {
  ThisMonth = 'ThisMonth',
}

export enum NeighborhoodViewTypes {
  List = 'List',
  Map = 'Map',
}

export type NeighborhoodFiltersChangeHandler = (params: {
  category?: NeighborhoodCategoryTypes[];
  activityStatus?: NeighborhoodStatusTypes[];
  dayRange?: number;
}) => void;
