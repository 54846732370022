import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { Col, Row } from 'components-antd';
import { Card } from '../../Card';
import { priceConverter } from 'helpers';
import {
  getLoadingGraphsSelector,
  getFilteredGraphsSelector,
} from 'store/selectors/agentDashboard';
import { Wrapper as PendingWrapper } from 'components';
import { AreaConfigData, statPoint } from '../helper';
import { PulseAreaGraph } from '../../PulseAreaGraph';

import styles from './styles.module.scss';

export const ListedWidget = ({ teamStats }) => {
  const history = useHistory();
  const [totalRequests, setTotalRequests] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<string>('0');
  const [data, setData] = useState<AreaConfigData[]>([] as AreaConfigData[]);
  const [currentStats, setCurrentStats] = useState<statPoint[]>();

  const { isPending } = useSelector(getLoadingGraphsSelector);
  const stats = useSelector(getFilteredGraphsSelector('listed', teamStats));
  useEffect(() => {
    !isPending && setCurrentStats(stats);
  }, [isPending, teamStats]);

  useEffect(() => {
    if (currentStats) {
      const stats =
        currentStats.map(({ date, sum, count }) => ({
          day: moment(date.slice(0, 10)).format('DD MMM YYYY'),
          value: sum,
          count,
        })) || [];

      setData(stats);
      const lastStatPoint = currentStats[currentStats.length - 1];
      setTotalAmount(priceConverter(lastStatPoint?.sum || 0, 2));
      setTotalRequests(lastStatPoint?.count || 0);
    }
  }, [currentStats]);

  const redirectToWorkshop = () => {
    if (currentStats?.length && totalRequests) {
      const Ids = currentStats[currentStats.length - 1].transactionIds;
      history.push(`/workshop/transactions?transactions=${Ids.map((Id) => `${Id}`)}`);
    }
  };

  return (
    <Card
      cardTitle={'Listed'}
      showHeader={false}
      cardWrapperClassName={styles.cardWrapper}
      cardHeaderClassName={styles.cardHeader}
      cardBodyClassName={styles.cardBody}
    >
      <PendingWrapper
        className={styles.pendingWrapper}
        spinnerClassname={styles.centerSpinner}
        isPending={isPending}
        loaderClassName={styles.loader}
      >
        <header className={styles.widgetHeader}>
          <div>
            <h3
              className={classNames({ [styles.headerText]: totalRequests !== 0 })}
              onClick={redirectToWorkshop}
            >
              {'Listed'}
            </h3>
          </div>
        </header>

        <Row
          className={classNames(styles.countContainer, {
            [styles.clickable]: totalRequests !== 0,
          })}
        >
          <span className={styles.totalAmount} onClick={redirectToWorkshop}>
            {totalAmount}
          </span>
          {+totalRequests !== 0 && (
            <span
              className={styles.count}
              onClick={redirectToWorkshop}
            >{`(${totalRequests})`}</span>
          )}
        </Row>

        <Row align="middle">
          <Col xs={24} sm={24}>
            <PulseAreaGraph
              data={data}
              xField="day"
              yField="value"
              color="#928CDA"
              areaFillColor="l(270) 0:#928CDA00 1:#928CDA"
            />
          </Col>
        </Row>
      </PendingWrapper>
    </Card>
  );
};
