import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useOutsideClick } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { Modal } from 'pages/Workshop/Transactions/TransactionCreate/components';

import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';
import { FILE_TYPES, getFileExtension } from 'settings/constants/common';
import { openTransactionDocumentDrawerEffect } from 'store/effects/drawers/transactionDocument';
import {
  deleteTransactionDocumentEffect,
  getTransactionDocumentsFilterEffect,
  previewDocumentEffect,
} from 'store/effects/transactions';
import { getUserId } from 'store/selectors/user';
import { DocumentTypes } from 'types';
import { showErrorMessage, showSuccessMessage } from 'helpers';

import styles from './styles.module.scss';
import {
  deleteTransactionFolderEffect,
  editTransactionFolderEffect,
  moveTransactionDocumentEffect,
} from 'store/effects/transactions/folders/update';
import { getTransactionFolderEffect } from 'store/effects/transactions/folders/read';
import { getTransactionSelector } from 'store/selectors/transaction';
import { Button, ConfirmModal } from 'components';
import MoveFilesItem from '../../moveFilesItem';
import { InputSelectModal } from 'components-antd';
import { getFileNameWithExtension } from 'components/Messages/MessagesView/components/Download/helper';

const Actions = (props) => {
  const {
    className,
    id,
    data,
    DocumentVaultUUID,
    auditLog,
    onPreview,
    openFolder,
    folders,
    isFolderOpen,
    fullAccess,
  } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState({
    deleteModal: false,
    moveModal: false,
    editModal: false,
  });
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [loading, setLoading] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [currentFolderId, setCurrentFolderId] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const userId = useSelector(getUserId);
  const { transaction } = useSelector(getTransactionSelector);

  const dotsRef = useRef();
  const optionsRef = useRef();

  const isOwner = data?.Uploader?.Id === userId || props.data.OwnerId === userId;

  const fileType = data.Type === DocumentTypes.File || data.isFolder;

  useOutsideClick([dotsRef, optionsRef], () => setOpen(false));

  const onClick = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const onDownload = (vaultId, title) => {
    dispatch(
      previewDocumentEffect({ DocumentVaultUUID: vaultId }, {}, (err, response) => {
        if (err) {
          return showErrorMessage(err);
        }
        const fileName = response.headers['file-name'];

        const fileExtension = getFileExtension(fileName);
        let fileTitle = title
          ? title.endsWith(fileExtension)
            ? title
            : `${title}${fileExtension}`
          : '';

        fileDownload(
          response.data,
          fileTitle || getFileNameWithExtension(response.headers['file-name']),
        );
      }),
    );

    setOpen(false);
  };

  const onEdit = (event) => {
    event.stopPropagation();
    dispatch(
      openTransactionDocumentDrawerEffect({
        open: true,
        params: { documentId: data?.Id },
      }),
    );
    setOpen(false);
  };

  const handleDeleteModal = (e) => {
    e.stopPropagation();
    setShowModal({ ...showModal, deleteModal: true });
  };

  const moveDocuments = (e) => {
    e.stopPropagation();
    setLoading(true);
    setSelectedFolderId('');
    dispatch(
      moveTransactionDocumentEffect(
        { folderId: selectedFolderId, documentId: [data?.Id] },
        {},
        (err, response) => {
          if (!err) {
            dispatch(getTransactionDocumentsFilterEffect());
            showSuccessMessage(
              selectedFolderId
                ? `File Moved to Folder Successfully`
                : `File Moved to Main Page Successfully`,
            );
          } else {
            showErrorMessage(
              selectedFolderId ? `Failed to Move File to Folder` : `Failed to Move File`,
            );
          }
        },
      ),
    );
    setShowModal({ ...showModal, moveModal: false });
    setLoading(false);
    setOpen(false);
  };

  const handleEditFolder = async (setLoading, values) => {
    let { entityName } = values;
    if (entityName) {
      entityName = entityName.replace(/\s+/g, ' ');
      entityName = entityName.trim();
      setLoading(true);
      dispatch(
        editTransactionFolderEffect({ title: entityName, folderId: id }, {}, (err, response) => {
          if (!err) {
            dispatch(getTransactionFolderEffect(transaction, {}));
            setLoading(false);
            setShowModal({ ...showModal, editModal: false });
            showSuccessMessage('Folder Edited Successfully');
          }
        }),
      );
      setLoading(false);
    }
  };

  const onDelete = (event) => {
    setLoading(true);
    event.stopPropagation();
    if (id !== undefined) {
      if (data?.isFolder) {
        dispatch(
          deleteTransactionFolderEffect({ id }, {}, (err, response) => {
            if (!err) {
              dispatch(getTransactionFolderEffect(transaction, {}));
              showSuccessMessage('Folder deleted');
            }
          }),
        );
      } else {
        dispatch(
          deleteTransactionDocumentEffect({ id }, {}, (err, response) => {
            if (!err) {
              dispatch(getTransactionDocumentsFilterEffect());
              showSuccessMessage('File deleted');
            }
          }),
        );
      }
      setLoading(false);
      setOpen(false);
      setShowModal({ ...showModal, deleteModal: false });
    }
  };

  const onDocumentPreview = (event, Title) => {
    if (DocumentVaultUUID) {
      onPreview(DocumentVaultUUID, Title);
      setOpen(false);
    }
  };

  const handleEditModalCancel = () => {
    setShowModal({ ...showModal, editModal: false });
    setFolderName('');
  };

  return (
    <div className={classNames(styles.actions, className)}>
      <div
        ref={dotsRef}
        onClick={onClick}
        className={classNames(styles.actionsWrapper, { [styles.active]: open })}
        testid="actions"
      >
        <Icon variant={Icon.DOTS} color="#303030" />
      </div>
      {open && (
        <div className={styles.options} ref={optionsRef} onClick={(e) => e.stopPropagation()}>
          <ul testid="actions_list">
            {(data?.Meta?.MimeType === FILE_TYPES.PDF || data.isFolder) && (
              <li
                testid="open"
                onClick={(event) => {
                  data.isFolder ? openFolder(data) : onDocumentPreview(event, data.Title);
                }}
                className={styles.item}
              >
                <Icon className={styles.icon} variant={Icon.OPEN} />
                <div>Open</div>
              </li>
            )}
            {(!data.isFolder || !data?.Meta?.MimeType === FILE_TYPES.PDF) && (
              <li
                testid="download"
                onClick={(e) => onDownload(DocumentVaultUUID, data?.Title)}
                className={styles.item}
              >
                <Icon className={styles.icon} variant={Icon.DOWNLOAD} />
                <div>Download</div>
              </li>
            )}
            {auditLog && (
              <li
                testid="download_auditLog"
                onClick={(e) => onDownload(auditLog, data?.Title)}
                className={styles.item}
              >
                <Icon className={styles.icon} variant={Icon.DOWNLOAD} />
                <div>Download Audit Log</div>
              </li>
            )}
            {(isOwner || fullAccess) && !data.isFolder && (
              <li
                testid="move"
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentFolderId(data?.FolderId);
                  setShowModal({ ...showModal, moveModal: true });
                  setOpen(false);
                }}
                className={styles.item}
              >
                <Icon className={styles.icon} variant={Icon.MOVE} />
                <div>Move</div>
              </li>
            )}
            {(isOwner || fullAccess) && fileType && (
              <li
                testid="edit"
                onClick={
                  data.isFolder
                    ? (e) => {
                        setFolderName(data.Title);
                        setShowModal({ ...showModal, editModal: true });
                        setOpen(false);
                      }
                    : onEdit
                }
                className={styles.item}
              >
                <Icon className={styles.icon} variant={Icon.EDIT} />
                <div>Edit</div>
              </li>
            )}
            {(isOwner || fullAccess) && fileType && (
              <li
                testid="delete"
                onClick={(e) => {
                  handleDeleteModal(e);
                  setOpen(false);
                }}
                className={styles.item}
              >
                <Icon className={styles.icon} variant={Icon.DELETE} />
                <div>Delete</div>
              </li>
            )}
          </ul>
        </div>
      )}
      <div onClick={(e) => e.stopPropagation()}>
        <ConfirmModal
          title={`Are you sure you want to delete ${data.Title}?`}
          isOpen={showModal.deleteModal}
          onClose={() => setShowModal({ ...showModal, deleteModal: false })}
          onSubmit={onDelete}
          isPending={loading}
          icon
        />
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <Modal
          className={styles.documentsModal}
          isOpen={showModal.moveModal}
          onClose={(e) => {
            setSelectedFolderId('');
            setShowModal({ ...showModal, moveModal: false });
          }}
          title={`Move ${data.Title}`}
          testid="move_modal"
        >
          {isFolderOpen && (
            <MoveFilesItem
              selectedFolderId={selectedFolderId}
              onChange={(e) => {
                setSelectedFolderId(null);
                setIsChecked((prevState) => prevState || e.target.checked);
              }}
              title={'Files'}
              id={null}
              subtitle={'Main Page'}
            />
          )}

          {!folders?.length && (
            <div className={styles.noFolderFound}>
              <Icon className={styles.icon} variant={Icon.FOLDER} />
              <p>No folder found!</p>
            </div>
          )}

          <div className={styles.multiFoldersWrapper}>
            {folders?.map(
              (item, key) =>
                item?.Id !== currentFolderId && (
                  <div key={key} className={isFolderOpen && styles.allFoldersWrapper}>
                    <MoveFilesItem
                      selectedFolderId={selectedFolderId}
                      onChange={(e) => {
                        setSelectedFolderId(item.Id);
                        setIsChecked((prevState) => prevState || e.target.checked);
                      }}
                      title={item.Title}
                      key={key}
                      id={item.Id}
                    />
                  </div>
                ),
            )}
          </div>
          <div className={styles.moveButton}>
            <Button
              onClick={moveDocuments}
              title={'Done'}
              isPending={loading}
              disabled={!isChecked}
            />
          </div>
        </Modal>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <InputSelectModal
          open={showModal.editModal}
          title="Edit Folder"
          okText="Save"
          submit={handleEditFolder}
          onCancel={handleEditModalCancel}
          entityNameRequired={true}
          selectedItemRequired={true}
          entityNameMaxLength={64}
          destroyOnClose={true}
          titleClassName={styles.newFolderModalTitle}
          entityName={folderName}
        />
      </div>
    </div>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  onPreview: PropTypes.func,
  data: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    Uploader: PropTypes.shape({
      Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    Meta: PropTypes.shape({
      ContentType: PropTypes.string,
      Filename: PropTypes.string,
      Url: PropTypes.string,
    }),
    DocumentVaultUUID: PropTypes.number,
  }),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Actions.defaultProps = {
  className: '',
  data: null,
  DocumentVaultUUID: undefined,
  auditLog: undefined,
};

export default Actions;
