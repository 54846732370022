import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { Checkbox, Col, Popover, Row } from 'components-antd';
import { ArrowDown, ArrowUp } from 'components/Icons';
import { Card } from '../../Card';
import {
  getLoadingGraphsSelector,
  getFilteredGraphsSelector,
} from 'store/selectors/agentDashboard';
import { Wrapper as PendingWrapper } from 'components';
import { AreaConfigData, expiringStats, setStats } from '../helper';
import { PulseAreaGraph } from '../../PulseAreaGraph';

import styles from './styles.module.scss';

export const ExpiringListingWidget = ({ teamStats }) => {
  const history = useHistory();
  const filterValues = ['Today', 'This Week', 'This Month'];
  const [filter, setFilter] = useState<string>('This Week');
  const [open, setOpen] = useState(false);
  const [totalRequests, setTotalRequests] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<string>('0');
  const [data, setData] = useState<AreaConfigData[]>([] as AreaConfigData[]);
  const [currentStats, setCurrentStats] = useState<expiringStats>();

  const { isPending } = useSelector(getLoadingGraphsSelector);
  const stats = useSelector(getFilteredGraphsSelector('expiring', teamStats));

  useEffect(() => {
    !isPending && setCurrentStats(stats);
  }, [isPending, teamStats]);

  useEffect(() => {
    setStats(currentStats, getFilterOption(filter), setData, setTotalAmount, setTotalRequests);
  }, [currentStats]);

  const getFilterOption = (option) => {
    if (option === 'Today') {
      return 'dueToday';
    } else if (option === 'This Week') {
      return 'dueThisWeek';
    } else {
      return 'dueThisMonth';
    }
  };

  const handleChange = (checkedValues) => {
    setOpen(false);
    const filterOption = checkedValues.target.value;
    const currentValue = getFilterOption(filterOption);

    setFilter(filterOption);
    setStats(currentStats, currentValue, setData, setTotalAmount, setTotalRequests);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const popoverContent = () => (
    <ul className={styles.filterOptions}>
      {filterValues.map((value, index) => {
        return (
          <li>
            <Checkbox
              className={styles.sortCheckbox}
              key={index}
              onChange={handleChange}
              checked={value == filter}
              value={value}
            >
              <div className={styles.checkboxHeading}>{value}</div>
            </Checkbox>
          </li>
        );
      })}
    </ul>
  );

  const redirectToWorkshop = () => {
    const option = getFilterOption(filter);
    const data = currentStats?.[option];
    if (data?.length && totalRequests) {
      history.push(
        `/workshop/transactions?transactions=${data[data.length - 1].transactionIds.map(
          (Id) => `${Id}`,
        )}`,
      );
    }
  };

  return (
    <Card
      cardTitle={'Expiring Listings'}
      showHeader={false}
      cardWrapperClassName={styles.cardWrapper}
      cardHeaderClassName={styles.cardHeader}
      cardBodyClassName={styles.cardBody}
    >
      <PendingWrapper
        className={styles.pendingWrapper}
        spinnerClassname={styles.centerSpinner}
        isPending={isPending}
        loaderClassName={styles.loader}
      >
        <header className={styles.widgetHeader}>
          <div>
            <h3
              className={classNames({ [styles.headerText]: totalRequests !== 0 })}
              onClick={redirectToWorkshop}
            >
              {'Expiring Listings'}
            </h3>
          </div>
          <div>
            <Popover
              content={popoverContent}
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
              overlayClassName={styles.filterDropdown}
              placement="bottomRight"
              className={styles.dropdown}
            >
              <span className={styles.switchButton} onClick={(e) => e.preventDefault()}>
                {filter}
              </span>
              {open ? (
                <ArrowUp className={styles.icon} />
              ) : (
                <ArrowDown className={styles.icon} color={'#747475'} />
              )}
            </Popover>
          </div>
        </header>

        <Row
          className={classNames(styles.countContainer, {
            [styles.clickable]: totalRequests !== 0,
          })}
        >
          <span className={styles.totalAmount} onClick={redirectToWorkshop}>
            {totalAmount}
          </span>
          {+totalRequests !== 0 && (
            <span
              className={styles.count}
              onClick={redirectToWorkshop}
            >{`(${totalRequests})`}</span>
          )}
        </Row>

        <Row align="middle">
          <Col xs={24} sm={24}>
            <PulseAreaGraph
              data={data}
              xField="day"
              yField="value"
              color="#FF576D"
              areaFillColor="l(270) 0:#FF576D00 1:#FF576D"
            />
          </Col>
        </Row>
      </PendingWrapper>
    </Card>
  );
};
