export default {
  radar: '/radar',
  radarLogs: '/radar/logs',
  radarSignals: '/radar/signals',
  radarPortfolio: '/radar/portfolio',
  radarProperty: '/radar/property',
  radarKits: '/radar/kits',
  radarNewSequence: '/radar/kits/new-sequence',
  radarViewSequence: '/radar/kits/sequence/:id',
  radarSequenceAssignedClients: '/radar/kits/sequence/:id/assigned-clients',
  radarSequenceAssignedClientView: '/radar/kits/sequence/:id/assigned-clients/:clientId',
};
