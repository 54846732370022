import classNames from 'classnames';

import { Menu, MenuItem, Dropdown, Radio } from 'components-antd';
import { ArrowDown, Check } from 'components/Icons';

import styles from './styles.module.scss';

type Props = {
  defaultOption?: string;
  wrapperClassname?: string;
  setValue: (val) => void;
  value: string;
  onSelectSourcesOption: () => void;
  resetSourceList: () => void;
};
export const LeadSourcesDropdown = ({ ...props }: Props) => {
  const dropdownFields = ['Sources', 'Agents'];

  const onChange = (e) => {
    const selectedOption = e.target.value;
    if (selectedOption === 'Sources') props.onSelectSourcesOption();
    props.setValue(selectedOption);
    props.resetSourceList();
  };

  const menu = (
    <Radio.Group
      onChange={onChange}
      value={props.value}
      className={styles.optionsItems}
      defaultValue={props.value}
    >
      <Menu>
        {dropdownFields.map((item, idx) => {
          return (
            <MenuItem key={idx}>
              <Radio value={item} key={idx}>
                <div className={styles.option}>
                  <span className={styles.optionValue}>{item}</span>
                </div>
                {props.value === item && <Check className={styles.checkIcon} />}
              </Radio>
            </MenuItem>
          );
        })}
      </Menu>
    </Radio.Group>
  );

  return (
    <div className={classNames(styles.clarityFilterWrap, props.wrapperClassname)}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        getPopupContainer={(triggerNode) => triggerNode}
        trigger={['click']}
        overlayClassName={styles.teamFilterDropdown}
      >
        <a
          onClick={(e) => e.preventDefault()}
          className={classNames(styles.filterButton, 'mosaikDropdownButton')}
        >
          <span className={styles.optionValue}>{props.value}</span>
          <ArrowDown className={classNames(styles.icon, { [styles.open]: open })} size={16} />
        </a>
      </Dropdown>
    </div>
  );
};
