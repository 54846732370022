import React, { useState } from 'react';
import { Table, TableProps } from 'antd';
import classNames from 'classnames';

import Name from 'components/ClientsDrawer/ProfileClient/Name';
import { priceConverter } from 'helpers';
// import { TableModal } from '../Stats/Content/Components/TableView/TableModal/TableModal';

import styles from './styles.module.scss';

interface DataType {
  key: number;
  TransactionAgent: string;
  Total: string;
  TotalVolume: string;
  NumberTransactions: number;
  NumberLeadSources: number;
  Percentage: number;
  selected: boolean;
}

type BreakdownMenuProps = {
  // open: boolean;
  list: any[];
  handleClick: (record, rowIndex) => void;
  color: string;
  // onClose: (agent, index) => void;
  // title: string;
  aggregationType?: string;
  // summaryType: string;
  summaryKey?: string;
  columnType?: string;
  ledgerAccountId?: number | null;
  memo?: string | null;
  selectedAgent?: any | null;
};

export const AgentBreakdown: React.FC<BreakdownMenuProps> = ({
  list,
  handleClick,
  color,
  // onClose,
  aggregationType,
  summaryKey,
  columnType,
  ledgerAccountId = null,
  memo = null,
  selectedAgent = null,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Sources');
  const dropdownOptions = ['Sources', 'Agents'];
  const selectedRowStyles = {
    border: `1.5px solid ${color}`,
    'background-color': `${color}14`,
    'border-radius': '4px',
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'TransactionAgent',
      key: 'TransactionAgent',
      width: '55%',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.TransactionAgent;
          const b = record2.TransactionAgent;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (TransactionAgent, record) => {
        return (
          <Name
            className={classNames(styles.agentName, { [styles.selectedAgent]: record?.selected })}
            name={`${TransactionAgent}`}
          />
        );
      },
    },
    {
      title: 'Total',
      dataIndex: 'Total',
      key: 'Total',
      width: '25%',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Total;
          const b = record2.Total;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (Total, record) => {
        return (
          <div className={classNames(styles.amount, { [styles.selectedAgent]: record?.selected })}>
            {priceConverter(Total || record.TotalVolume || 0, 1)}
          </div>
        );
      },
    },
    {
      title: 'Count',
      dataIndex: 'NumberLeadSources',
      key: 'NumberLeadSources',
      width: '20%',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.NumberLeadSources;
          const b = record2.NumberLeadSources;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      // render: (NumberLeadSources, record) => {
      //   return (
      //     <div
      //       className={classNames(styles.requests, { [styles.selectedAgent]: record?.selected })}
      //       onClick={() => setOpenModal(true)}
      //     >
      //       <span className={styles.requestsText}> {NumberLeadSources}</span>
      //     </div>
      //   );
      // },
    },
    // {
    //   title: 'Percentage',
    //   dataIndex: 'Percentage',
    //   key: 'Percentage',
    //   width: '22%',
    //   sorter: {
    //     compare: (record1, record2) => {
    //       const a = record1.Percentage;
    //       const b = record2.Percentage;
    //       return a > b ? -1 : b > a ? 1 : 0;
    //     },
    //   },
    //   render: (Percentage, record) => {
    //     return (
    //       <div
    //         className={classNames(styles.percentage, { [styles.selectedAgent]: record?.selected })}
    //       >{`${Percentage || 0}%`}</div>
    //     );
    //   },
    // },
  ];

  return (
    <Table
      className={styles.BreakdownTable}
      onRow={(record, rowIndex) => {
        return {
          onClick: (e) => {
            handleClick(record, rowIndex);
          },
          style: record?.selected ? selectedRowStyles : undefined,
        };
      }}
      columns={columns}
      dataSource={list}
      pagination={false}
      sticky={true}
    />
  );
};
{
  /* {openModal && (
        <TableModal
          title={title}
          aggregationType={aggregationType}
          summaryType={summaryType}
          summaryKey={summaryKey}
          columnType={columnType}
          ledgerAccountId={ledgerAccountId}
          memo={memo}
          open={openModal}
          onCancel={() => setOpenModal(false)}
          selectedAgent={selectedAgent}
          clarityType={clarityType}
        />
      )} */
}
