import { Table, TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Modal } from 'components-antd';
import { getAgentTeamStatsSelector, getTimePeriodSelector } from 'store/selectors/clarity';
import { Wrapper as PendingWrapper } from 'components';
import { useFetchLeadSourceStats } from 'pages/ClarityRevamped/hooks';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { hideBreakdownMenu } from '../../../../ContentTypes/helper';
import { ExportCSV } from 'pages/ClarityRevamped/Components/ExportCSV';

import styles from '../styles.module.scss';

interface DataType {
  Date: string;
  Transaction: string;
  TotalVolume: number;
  PropertyTransactionId: number;
  RepresentingSide: string;
  LeadSource: string;
  TransactionAgentUser: string;
  Agent: string;
}

export const LeadSourceTableModal = ({
  year,
  month,
  isoWeek,
  date,
  open,
  onCancel,
  selectedAgent,
  sourceList,
}) => {
  const timePeriod = useSelector(getTimePeriodSelector);
  const [data, setData] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const teamStats = useSelector(getAgentTeamStatsSelector);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  const getStats = useFetchLeadSourceStats();

  const leadSourceColumns: TableProps<DataType>['columns'] = [
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
      sorter: {
        compare: (record1, record2) => {
          const a = moment(record1.Date);
          const b = moment(record2.Date);
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value) {
        return <span className={styles.dateColumn}>{value}</span>;
      },
    },
    {
      title: 'Transaction',
      dataIndex: 'Transaction',
      key: 'Transaction',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Transaction;
          const b = record2.Transaction;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render(value, record) {
        return (
          <a
            target="_blank"
            className={styles.linkToTransaction}
            href={`/workshop/transactions/${record.PropertyTransactionId}/overview`}
            rel="noreferrer"
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'TotalVolume',
      key: 'TotalVolume',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.TotalVolume;
          const b = record2.TotalVolume;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
      render: (TotalVolume) => {
        return TotalVolume > 0
          ? `$${TotalVolume.toLocaleString()}`
          : `-$${Math.abs(TotalVolume).toLocaleString()}`;
      },
    },
    {
      title: 'Lead Source',
      dataIndex: 'LeadSource',
      key: 'LeadSource',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.LeadSource;
          const b = record2.LeadSource;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Representing',
      dataIndex: 'RepresentingSide',
      key: 'RepresentingSide',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.RepresentingSide;
          const b = record2.RepresentingSide;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
  ];

  if (hideBreakdownMenu(agentRole, teamStats)) {
    leadSourceColumns.push({
      title: 'Agent',
      dataIndex: 'Agent',
      key: 'Agent',
      sorter: {
        compare: (record1, record2) => {
          const a = record1.Agent;
          const b = record2.Agent;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    });
  }

  const formatCSVData = (data) => {
    const formattedData = data?.map((row) => {
      return {
        Date: row.Date,
        Transaction: `"${row.Transaction}"`,
        Amount: `"$${row.TotalVolume.toLocaleString()}"`,
        LeadSource: row.LeadSource,
        RepresentingRole: row.RepresentingSide,
        ...(hideBreakdownMenu(agentRole, teamStats) && { Agent: row.Agent }),
      };
    });
    return formattedData.map((row) => ({
      Date: row.Date,
      Transaction: row.Transaction,
      Amount: row.Amount,
      LeadSource: row.LeadSource,
      RepresentingRole: row.RepresentingRole,
      ...(hideBreakdownMenu(agentRole, teamStats) && { Agent: row.Agent }),
    }));
  };

  useEffect(() => {
    getStats({
      setSummary: () => {},
      setData,
      setIsPending,
      aggregateDataFormat: 'Tabular',
      year: year,
      quarter: null,
      month: timePeriod === 'YTD' ? month : null,
      isoWeek: timePeriod === 'QTD' ? isoWeek : null,
      date: timePeriod === 'MTD' || timePeriod === 'WEEK' ? date : null,
      transactionAgentUserId: selectedAgent?.TransactionAgentUserId,
      transactionAgentContactId: selectedAgent?.TransactionAgentContactId,
      sourceList: sourceList,
      expand: true,
    });
  }, [timePeriod, year, month, isoWeek]);

  return (
    <Modal
      width={1000}
      className={styles.clarityTableModal}
      open={open}
      footer={null}
      onCancel={onCancel}
    >
      <header className={styles.tableHeader}>
        <h2 className={styles.modalHeader}>{'Lead Sources'}</h2>
        <ExportCSV data={formatCSVData(data)} />
      </header>
      <PendingWrapper isPending={isPending} className={styles.tableContainer}>
        <Table
          className={styles.statsTable}
          columns={leadSourceColumns}
          dataSource={data}
          pagination={false}
        />
      </PendingWrapper>
    </Modal>
  );
};
