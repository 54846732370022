import { useState } from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash-es';
import { ArrowRight } from 'components/Icons';
import { Modal } from 'components-antd';

import { getListingDetailOpenHouseInfo } from 'store/selectors/listingDetail';

import styles from './styles.module.scss';

const ListingOpenHouse = ({ addressLine1, addressLine2, photo }) => {
  const [showOpenHouseModal, setShowOpenHouseModal] = useState(false);

  const dates = useSelector(getListingDetailOpenHouseInfo).filter((date) => date.isActual);
  const slicedDates = dates.slice(0, 3);

  return (
    <>
      <Modal
        centered
        open={showOpenHouseModal}
        width={1000}
        footer={null}
        onOk={() => setShowOpenHouseModal(false)}
        onCancel={() => setShowOpenHouseModal(false)}
      >
        <p className={styles.title}>Open Houses</p>
        <div className={styles.addressContainer}>
          <img src={photo} />
          <div className={styles.address}>
            <p className={styles.lineOne}>{addressLine1}</p>
            <p className={styles.lineTwo}>{addressLine2}</p>
          </div>
        </div>
        <div className={styles.container}>
          {map(dates, ({ date, time }) => (
            <div key={date + time} className={styles.wrapperModal}>
              <b>{date}</b>
              {!!time && <span>{time}</span>}
            </div>
          ))}
        </div>
      </Modal>
      <div className={styles.container}>
        {map(slicedDates, ({ date, time }) => (
          <div key={date + time} className={styles.wrapper}>
            <b>{`Open ${date}`}</b>
            {!!time && <span>{`, ${time}`}</span>}
          </div>
        ))}
        {dates?.length >= 4 && (
          <div
            className={styles.wrapperMore}
            onClick={() => {
              setShowOpenHouseModal(true);
            }}
          >
            <b>View more</b>
            <ArrowRight color={'#fff'} />
          </div>
        )}
      </div>
    </>
  );
};

export default ListingOpenHouse;
