import { useState, useEffect } from 'react';
import { Button } from 'components-antd';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { ShowingModal } from 'pages/Workshop/Transactions/TransactionShowings/ShowingModal';
import { MegaPhoneModal } from '../MegaPhoneModal';
import { announcementType } from 'settings/constants/showings';
import { Icons } from '../Icons';
import { getShowingIsArchiveSelector } from 'store/selectors/showingAggregate';
import { getUserId } from 'store/selectors/user';
import {
  getShowingAggregateByUserIdEffect,
  setShowingsArchiveIdEffect,
  setShowingsIsArchiveEffect,
  resetShowingAggregateByUserIdSearchEffect,
  searchShowingAggregateByUserIdEffect,
  resetShowingAggregateEffect,
} from 'store/effects/showingAggregate';
import { getUserRolesSelector } from 'store/selectors/user';
import { CLIENT } from 'settings/constants/roles';

import styles from './styles.module.scss';
import { PageWrapper } from 'pages/Workshop/PageWrapper';
import { ShowingsTable } from 'pages/Workshop/Showings/components/ShowingsTable';
import { SearchFieldWithDebounce } from 'components';
import { showSuccessMessage } from 'helpers';

export const Showings = ({ transaction, fullAccess }) => {
  const dispatch = useDispatch();
  const { isArchive } = useSelector(getShowingIsArchiveSelector);
  const userId = useSelector(getUserId);
  const loggedinUserRoles = useSelector(getUserRolesSelector);

  const { search } = useLocation();
  const { Showing } = transaction;
  const Id = Showing?.Id ? Number(Showing.Id) : null;
  const [showingModal, setShowingModal] = useState(false);
  const [megPhoneModal, setMegaPhoneModal] = useState(false);
  const transactionIdFilter = { PropertyTransactionId: transaction.Id };

  const onClose = () => setShowingModal(false);

  const onArchiveModeChange = () => dispatch(setShowingsIsArchiveEffect(!isArchive));

  const copyLink = () => {
    navigator.clipboard.writeText(Showing?.ShowingLink);
    showSuccessMessage('Link Copied');
  };

  const onDestroy = () => {
    dispatch(setShowingsIsArchiveEffect(null));
    dispatch(setShowingsArchiveIdEffect(null));
    dispatch(resetShowingAggregateEffect());
  };

  const renderActions = () => {
    return !loggedinUserRoles?.includes(CLIENT) && fullAccess ? (
      <div className={styles.actions}>
        {Showing?.Id ? (
          <>
            {Showing?.EnableShowingLink && (
              <Button
                variant="hollow-bordered"
                className={classnames(styles.actionBtn, styles.copyBtn)}
                onClick={copyLink}
              >
                Copy Link
              </Button>
            )}
            <Icons
              variant={Icons.MEGA_PHONE}
              className={styles.actionBtn}
              onClick={() => setMegaPhoneModal(true)}
            />
            <Icons
              variant={Icons.SETTINGS}
              className={styles.actionBtn}
              onClick={() => setShowingModal(true)}
            />
          </>
        ) : (
          <Button
            variant="hollow-bordered"
            className={classnames(styles.actionBtn, styles.enableBtn)}
            onClick={() => setShowingModal(true)}
          >
            Enable
          </Button>
        )}
      </div>
    ) : (
      <></>
    );
  };

  useEffect(() => {
    if (search?.includes('isOpen=true')) setShowingModal(true);
  }, [search]);

  useEffect(() => {
    if (Id) {
      dispatch(
        getShowingAggregateByUserIdEffect({
          id: userId,
          filters: {
            IsArchive: isArchive,
            id: Id,
            ...transactionIdFilter,
          },
        }),
      );
    } else {
      onDestroy();
    }
  }, [Id, isArchive]);

  useEffect(() => {
    if (Id) dispatch(setShowingsArchiveIdEffect({ showingId: Id }));

    return () => {
      onDestroy();
    };
  }, [Id]);

  return (
    <div className={styles.showings}>
      <h2 className={styles.title}>Showings</h2>
      {renderActions()}
      <PageWrapper isBgWhite isContainer={false} isHideFooter>
        <div className={styles.header}>
          <div className={styles.searchWrapper}>
            <SearchFieldWithDebounce
              className={styles.inputWrap}
              classNameInput={styles.searchInput}
              iconClassName={styles.icon}
              resetAction={() =>
                resetShowingAggregateByUserIdSearchEffect(userId, {
                  ...transactionIdFilter,
                  isArchive,
                })
              }
              sendAction={({ search }) =>
                searchShowingAggregateByUserIdEffect({
                  search,
                  userId,
                  filters: {
                    ...transactionIdFilter,
                    isArchive,
                  },
                })
              }
              isLoading={false}
              placeholder="Search"
              testid="search"
              isTransactionAggregate={true}
              isExpandedVersion
              otherSearchParams={{ transactionIdFilter, isArchive }}
            />
          </div>
          <div className={styles.header}>
            <div className="seperator" />
            <div
              className={classnames(styles.btn, { [styles.selected]: isArchive })}
              onClick={onArchiveModeChange}
            >
              <Icons
                className={styles.btnIcon}
                variant={Icons.ARCHIVE}
                color={isArchive ? '#FFFFFF' : ''}
              />
            </div>
          </div>
        </div>
        <ShowingsTable
          isTransactionRoom={true}
          transactionId={transaction?.Id}
          showingTableClass={styles.transactionShowingsTable}
        />
      </PageWrapper>

      <MegaPhoneModal
        showingId={Id}
        isOpen={megPhoneModal}
        onClose={() => setMegaPhoneModal(false)}
        type={announcementType.PUBLIC}
      />
      <ShowingModal
        isOpen={showingModal}
        onClose={onClose}
        transaction={transaction}
        fullAccess={fullAccess}
      ></ShowingModal>
    </div>
  );
};
