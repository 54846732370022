import { Fragment, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Row, Col, Button } from 'components-antd';
import { SplitTabTypes, SplitTypes } from '../..';
import { Card, CardTypes, DisableSectionCover, NameAutocomplete, Separator } from '..';

import { addFeeLineItem, getFeeNames } from 'api/financials';
import { Select } from 'components';
import { FeeItem } from './components';
import CheckboxComponent from 'components/Form/Checkbox';
import { CommissionField } from 'pages/Workshop/Transactions/TransactionCreate/components/PreForm/Questions/FinancingDetails/components';
import { Icons } from 'pages/Workshop/Icons';

import { LedgerAccountId, LedgerTransactionSideId } from 'settings/constants/ledger';
import { getFeeItems, getLedgerId, isFeeTypePercentage } from '../../helpers';
import { getUserId } from 'store/selectors/user';

import styles from './styles.module.scss';
import { convertToDecimalIfNotWhole } from 'helpers';

export interface FeeData {
  FeeTitle: string;
  FeeValue: number;
  FeePercentage: number;
  IsFeeRestricted: boolean;
  SplitTitle?: string;
  Id: number;
  SplitId?: number;
  isPercentage?: boolean;
  IsFeeTypePercentage: boolean;
  FeesSplit?: any;
  DeductFromAccountId?: number;
  ReferenceDeductFrom?: string;
  ReferenceAddToAccount?: string;
  AddToAccountId?: number;
  AddToAccountIdLineItemId?: number;
}

export const Fees = ({
  fetchFinancials,
  splitList,
  splitTabType,
  isTransactionAdminOrOwner,
  ledgers,
  ledgerLineItems,
  gciPrice,
  transactionPrice,
}) => {
  const [editFieldKey, setEditFieldKey] = useState(null);
  const [addTitle, setAddTitle] = useState('');
  const [isAddCase, setIsAddCase] = useState(false);
  const [isRestrictedAdd, setIsRestrictedAdd] = useState(false);
  const [isValidateAdd, setValidateAdd] = useState(false);
  const [addAmountObj, setAddAmountObj] = useState<any>({
    isAddPercentage: false,
    feeAmount: '',
    feePercent: '',
  });
  const [splitDropdownOption, setSplitDropdownOption] = useState<any>(null);
  const [addToAccount, setAddToAccount] = useState<any>({
    name: 'OutBound',
    accountId: LedgerAccountId.OutboundPayment,
    value: 0,
  });
  const [selectedSplit, setSelectedSplit] = useState({
    name: '',
    value: null,
    amount: 0,
    splitId: null,
    splitType: '',
  });

  const [feeListing, setFeeListing] = useState<FeeData[]>([]);
  const [isAddFeeLoading, setAddFeeLoading] = useState(false);
  const [sumValues, setSumValues] = useState({ feeValuesSum: 0, feePercentagesSum: 0 });
  const [splitInfo, setSplitInfo] = useState<any[]>([]);
  const [addToOptions, setAddToOptions] = useState<any[]>([]);
  const isDisableField = (editKey) => editFieldKey !== null && editFieldKey !== editKey;

  const [autocompleteFeeNames, setAutocompleteFeeNames] = useState<any>(null);
  const [ledgerId, setLedgerId] = useState(null);
  const loggedInUserId = useSelector(getUserId);

  const fetchAutocompleteFeeNames = async () => {
    let autocompleteFeeNames = await getFeeNames();
    setAutocompleteFeeNames(autocompleteFeeNames?.FeeTitles);
  };

  useEffect(() => {
    fetchAutocompleteFeeNames();
  }, []);

  function setRestrictedDefaultState(item) {
    const isRestrictedCheck = item?.name === 'Team Split' || item?.name === 'Brokerage Split';
    setIsRestrictedAdd(isTransactionAdminOrOwner && isRestrictedCheck);
    setSelectedSplit(item);
  }

  useEffect(() => {
    let transformedData =
      splitList &&
      splitList
        ?.filter((item) => item?.LedgerLineItemId !== null) // Filter out items with null LedgerLineItemId
        .map((item, idx) => ({
          name: item?.SplitType?.SplitTitle,
          value: idx,
          splitType: item?.SplitType?.SplitType,
          ledgerLineItemId: item?.LedgerLineItemId,
          accountId: item?.LedgerAccountId,
          comparePrice: item?.SplitValue,
        }));

    setAddToOptions([
      { name: 'Outbound', accountId: LedgerAccountId.OutboundPayment, value: 0 },
      ...(transformedData?.map((item, idx) => ({
        ...item,
        value: idx + 1,
        name: item?.splitType ? item?.name?.replace('Split', 'Net') : item?.name,
      })) || []),
    ]);

    transformedData &&
      transformedData.push({
        name: 'Client Fees',
        accountId: LedgerAccountId.ClientFeeIncome,
        value: transformedData?.length,
        comparePrice: transactionPrice,
      });

    transformedData &&
      transformedData.push({
        name: 'GCI',
        accountId: LedgerAccountId.CommissionIncome,
        value: transformedData?.length + 1,
        comparePrice: gciPrice,
      });
    setSplitInfo(transformedData);

    setSplitDropdownOption(
      transformedData && {
        name: transformedData[0]?.name,
        value: transformedData[0].value,
        accountId: transformedData[0]?.accountId,
        ledgerLineItemId: transformedData[0]?.ledgerLineItemId,
        comparePrice: transformedData[0]?.comparePrice,
      },
    );
    setRestrictedDefaultState(transformedData?.[0]);
  }, [splitList]);

  useEffect(() => {
    const ledgerId = getLedgerId(
      ledgers,
      splitTabType === SplitTabTypes.LISTING
        ? LedgerTransactionSideId.Listing
        : LedgerTransactionSideId.Purchase,
    );
    setLedgerId(ledgerId);
    if (ledgers && ledgerLineItems && splitInfo) {
      const data = getFeeItems(ledgerLineItems, ledgerId);
      const formattedData = data
        ?.filter((item) => {
          // Restricted fee should be visible to Transaction owner or admin only
          if (item?.IsRestricted) return isTransactionAdminOrOwner;
          return true;
        })
        ?.map((item) => ({
          Id: item?.Id,
          FeeTitle: item?.Memo,
          FeeValue: item?.Amount,
          FeePercentage: item?.Percent,
          IsFeeTypePercentage: isFeeTypePercentage(item),
          IsFeeRestricted: item?.IsRestricted,
          DeductFromAccountId: item?.LedgerAccountId,
          ReferenceDeductFrom: item?.Reference,
          ReferenceAddToAccount: item?.AddToAccountReference,
          AddToAccountId: item?.AddToAccountId,
          AddToAccountIdLineItemId: item?.AddToAccountIdLineItemId,
        }));
      setFeeListing(formattedData || []);
    }
  }, [ledgers, ledgerLineItems, splitTabType, splitInfo, addToOptions]);

  useEffect(() => {
    const { totalFeeValue, totalFeePercentage } =
      feeListing?.reduce(
        (acc, item) => {
          acc.totalFeeValue += item.FeeValue || 0;
          acc.totalFeePercentage += item.FeePercentage || 0;
          return acc;
        },
        { totalFeeValue: 0, totalFeePercentage: 0 },
      ) || {};
    setSumValues({ feeValuesSum: totalFeeValue || 0, feePercentagesSum: totalFeePercentage || 0 });
  }, [feeListing]);

  const onAdd = () => {
    setIsAddCase(true);
  };

  const onCancelAdd = () => {
    setIsAddCase(false);
    setValidateAdd(false);
    setAddAmountObj({
      isAddPercentage: false,
      feeAmount: '',
      feePercent: '',
    });
    setAddTitle('');
  };

  const handleSelect = (e, selectedOption) => {
    // Update percent/amount of the item based on deduct to specific compare price.
    setAddAmountObj({
      ...addAmountObj,
      ...(addAmountObj?.isAddPercentage
        ? {
            feeAmount: calculateGCI(addAmountObj?.feePercent, selectedOption?.comparePrice),
          }
        : { feePercent: calculateRate(addAmountObj?.feeAmount, selectedOption?.comparePrice) }),
    });

    setRestrictedDefaultState(selectedOption);
    setSplitDropdownOption(selectedOption);
  };

  const handleAddToAccount = (e, selectedOption) => {
    setAddToAccount(selectedOption);
  };

  const getAddFeeDisable = () => {
    if (
      addAmountObj?.feeAmount === null ||
      addAmountObj?.feeAmount === 0 ||
      addTitle === '' ||
      splitDropdownOption?.value === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onAddFee = async () => {
    try {
      setAddFeeLoading(true);

      let _payload: any = {
        LedgerId: ledgerId,
        DeductFromLedgerAccountId: splitDropdownOption?.accountId,
        ...(splitDropdownOption?.ledgerLineItemId
          ? { DeductFromLedgerLineItemId: splitDropdownOption?.ledgerLineItemId }
          : {}), // For Splits, passing the LedgerLineItemId
        AddToLedgerAccountId: addToAccount?.accountId,
        ...(addToAccount?.ledgerLineItemId
          ? { AddToLedgerLineItemId: addToAccount?.ledgerLineItemId }
          : {}), // For Splits, passing the LedgerLineItemId
        ...(addAmountObj?.isAddPercentage
          ? {
              Percent: addAmountObj?.feePercent,
            }
          : { Amount: addAmountObj?.feeAmount }),
        Memo: addTitle,
        IsRestricted: isRestrictedAdd,
        UpdatedBy: loggedInUserId,
        ...(splitDropdownOption?.value === LedgerAccountId.CustomAccountPayment ||
        addToAccount === LedgerAccountId.CustomAccountPayment
          ? {
              Reference: 'Custom Account',
            }
          : {}),
      };
      await addFeeLineItem(_payload);
      fetchFinancials();
    } catch (err) {
      setAddFeeLoading(false);
    } finally {
      setAddFeeLoading(false);
      setIsAddCase(false);
    }
  };

  const isPriceWithinRange = (compareprice, sum, _updatedObject) => {
    let calculatedValue = 0;
    let _sum = 0;
    if (_updatedObject?.isAddPercentage) {
      let _data = _updatedObject?.feeAmount / 100;

      calculatedValue = (_updatedObject?.feeAmount / 100) * compareprice;
    } else {
      calculatedValue = _updatedObject?.feeAmount;
    }

    _sum = +calculatedValue + sum;

    if (_sum === compareprice || _sum < compareprice) {
      setValidateAdd(false);
    } else {
      setValidateAdd(true);
    }
  };

  const transformedSplitInfo = useMemo(() => {
    if (isTransactionAdminOrOwner) return splitInfo;
    return splitInfo?.filter(
      (item) => item?.splitType !== SplitTypes.BROKERAGE && item?.splitType !== SplitTypes.TEAM,
    );
  }, [splitInfo]);

  const calculateGCI = (rate, comparePrice = null) => {
    const priceToCompare = comparePrice || splitDropdownOption?.comparePrice;

    return (rate / 100) * priceToCompare;
  };

  const calculateRate = (GCI, comparePrice = null) => {
    const priceToCompare = comparePrice || splitDropdownOption?.comparePrice;

    return convertToDecimalIfNotWhole((GCI * 100) / priceToCompare);
  };

  const getAddSectionFields = () => {
    return (
      <div>
        <Row gutter={12}>
          <Col lg={12}>
            <NameAutocomplete
              label="Fee"
              allNames={autocompleteFeeNames?.map((item, idx) => ({ label: item, value: idx + 1 }))}
              value={addTitle}
              onChange={(value) => setAddTitle(value?.label)}
              onSelect={(value) => setAddTitle(value?.label)}
            />
          </Col>
          <Col lg={12}>
            <CommissionField
              label="Amount"
              value={
                addAmountObj?.isAddPercentage ? addAmountObj?.feePercent : addAmountObj?.feeAmount
              }
              update={(object) => {
                const isToggled = object?.IsFeeTypePercentage !== addAmountObj?.isAddPercentage;
                const value = object?.Value === '' || null ? null : parseFloat(object?.Value);
                // Handle toggling between $ / % button
                if (
                  isToggled ||
                  (object?.IsFeeTypePercentage && value == addAmountObj?.feePercent) ||
                  (!object?.IsFeeTypePercentage && value == addAmountObj?.feeAmount)
                ) {
                  const updatedObject = {
                    ...addAmountObj,
                    isAddPercentage: object?.IsFeeTypePercentage,
                  };
                  setAddAmountObj(updatedObject);
                } else {
                  const updatedObject = {
                    isAddPercentage: object?.IsFeeTypePercentage,
                    ...(object?.IsFeeTypePercentage
                      ? {
                          feePercent: value,
                          feeAmount: calculateGCI(value),
                        }
                      : {
                          feeAmount: value,
                          feePercent: calculateRate(value),
                        }),
                  };
                  let _selectedSplitData =
                    splitInfo &&
                    splitInfo.filter((data) => {
                      return data?.value === splitDropdownOption?.value;
                    });
                  let _selectedFeeListingData =
                    feeListing &&
                    feeListing.filter((_data) => {
                      return _data?.SplitId === splitDropdownOption?.value;
                    });

                  let sum =
                    _selectedFeeListingData?.reduce((acc, obj) => acc + obj?.FeeValue, 0) || 0;
                  if (splitDropdownOption?.value !== 0) {
                    isPriceWithinRange(
                      _selectedSplitData && _selectedSplitData[0].amount,
                      sum,
                      updatedObject,
                    );
                  }

                  setAddAmountObj(updatedObject);
                }
              }}
              IsFeeTypePercentage={addAmountObj?.isAddPercentage}
            />
            {/* {isValidateAdd && (
              <ErrorMessage
                message={'Fee amount can never be greater than its relevant split price'}
              />
            )} */}
          </Col>
          <Col lg={12} className={styles.marginTop}>
            <p className={styles.applyLabel}>{'Deduct From'}</p>
            <Select
              options={transformedSplitInfo}
              onSelect={handleSelect}
              isArrowIcon={true}
              variant={Select.LIGHT_ROUND}
              value={splitDropdownOption?.value}
              className={{
                input: styles.inputHeight,
              }}
            />
          </Col>
          <Col lg={12} className={styles.marginTop}>
            <p className={styles.applyLabel}>{'Add To'}</p>
            <Select
              options={addToOptions}
              onSelect={handleAddToAccount}
              isArrowIcon={true}
              variant={Select.LIGHT_ROUND}
              className={{
                input: styles.inputHeight,
              }}
              value={addToAccount?.value}
            />
          </Col>
          <Col lg={12}>
            <div className={styles.buttonsContainer}>
              <Button
                variant="secondary"
                size="large"
                onClick={onAddFee}
                loading={isAddFeeLoading}
                disabled={getAddFeeDisable()}
              >
                Add
              </Button>
              <Button variant="hollow-bordered" size="large" onClick={onCancelAdd}>
                Cancel
              </Button>
            </div>
          </Col>
          {isTransactionAdminOrOwner && (
            <Col lg={12} className={styles.checkboxCont}>
              <CheckboxComponent
                id="restricted-add"
                label="Restricted"
                checked={isRestrictedAdd}
                onChange={(e, v, checked) => setIsRestrictedAdd(checked)}
                direction={CheckboxComponent.DIRECTION_RIGHT}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  };

  return (
    <Card variant={CardTypes.LIGHT_GRAY}>
      <div className={classNames(styles.feesContainer, { [styles.disabledSection]: isAddCase })}>
        {!ledgerId && <DisableSectionCover />}
        <div className={classNames(styles.headerCon, { [styles.dullSection]: !ledgerId })}>
          <p className={styles.title}>Fees</p>
          <div className={styles.addFeeCon} onClick={onAdd}>
            <Icons variant={Icons.PLUS} />
            <p className={styles.addFee}>Add Fee</p>
          </div>
        </div>
        <div className={classNames(styles.content, { [styles.dullSection]: !ledgerId })}>
          {feeListing &&
            feeListing.map((item, index) => (
              <Fragment key={item?.Id}>
                <FeeItem
                  label={item?.FeeTitle}
                  amount={item?.FeeValue}
                  percentage={item?.FeePercentage}
                  isPercentage={item?.IsFeeTypePercentage}
                  isRestricted={item?.IsFeeRestricted}
                  addToAccount={item?.AddToAccountId}
                  addToAccountIdLineItemId={item?.AddToAccountIdLineItemId}
                  deductFrom={item?.DeductFromAccountId || 0}
                  ReferenceDeductFrom={item?.ReferenceDeductFrom}
                  ReferenceAddToAccount={item?.ReferenceAddToAccount}
                  fieldKey={item?.FeeTitle}
                  feeId={item?.Id}
                  setEditField={setEditFieldKey}
                  isDisableField={isDisableField}
                  splitInfo={transformedSplitInfo}
                  fetchFinancials={fetchFinancials}
                  feeArray={feeListing}
                  isTransactionAdminOrOwner={isTransactionAdminOrOwner}
                  autocompleteFeeNames={autocompleteFeeNames}
                  addToOptions={addToOptions}
                  ledgerId={ledgerId}
                />
                {index < feeListing?.length - 1 && <Separator />}
              </Fragment>
            ))}
        </div>
        {!isAddCase && (
          <div className={classNames(styles.footer, { [styles.dullSection]: !ledgerId })}>
            <div className={styles.fadeContainer} />
            <Separator />
            <FeeItem
              label="Total Fees"
              amount={sumValues?.feeValuesSum}
              percentage={null}
              labelClassName={styles.bold}
              notEditable
              fieldKey="totalFee"
              isDisableField={isDisableField}
              hidePriceHighlighter
            />
          </div>
        )}
      </div>
      {isAddCase && <div className={styles.addContainer}>{getAddSectionFields()}</div>}
    </Card>
  );
};
