import React from 'react';
import { useSelector } from 'react-redux';

import { getListingDetailFinancesData } from 'store/selectors/listingDetail';

import { Accordion, TitleValueTable } from 'components';
import InfoBlockList from '../InfoBlockList';

import PriceHistory from './PriceHistory';
import styles from './styles.module.scss';

const FinancesTab = () => {
  const { cost, priceHistory, salesHistory, taxHistory, financials, taxes } = useSelector(
    getListingDetailFinancesData,
  );

  return (
    <div className={styles.financesTabContainer}>
      {!!cost?.length && (
        <Accordion testid="costs_block" className={styles.accordion} title="Costs" open>
          <div className={styles.content}>
            <TitleValueTable items={cost} />
          </div>
        </Accordion>
      )}
      {!!financials?.length && (
        <Accordion testid="financials_info_block" className={styles.accordion} title="Financials">
          <InfoBlockList list={financials} />
        </Accordion>
      )}
      {!!taxes?.length && (
        <Accordion testid="taxes_info_block" className={styles.accordion} title="Taxes">
          <InfoBlockList list={taxes} />
        </Accordion>
      )}
      {!!priceHistory?.length && (
        <Accordion testid="price_history_block" className={styles.accordion} title="Price History">
          <div className={styles.content}>
            <PriceHistory items={priceHistory} />
          </div>
        </Accordion>
      )}
      {!!salesHistory?.length && (
        <Accordion testid="sales_history_block" className={styles.accordion} title="Sales History">
          <div className={styles.content}>
            <TitleValueTable items={salesHistory} />
          </div>
        </Accordion>
      )}
      {!!taxHistory?.length && (
        <Accordion testid="tax_history_block" className={styles.accordion} title="Tax History">
          <div className={styles.content}>
            <TitleValueTable items={taxHistory} />
          </div>
        </Accordion>
      )}
    </div>
  );
};

export default FinancesTab;
