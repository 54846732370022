import { Fragment, useEffect, useState } from 'react';

import { Input, Table } from 'components-antd';
import SearchGlass from 'components/Icons/SearchGlass';
import { DocumentIcons } from './../../Icons';
import {
  draftColumns,
  preapprovalColumns,
  transactionColumns,
  pendingFormsColumns,
  miscElectronicSignColumns,
  miscUploadsColumns,
  messageAttachmentsColumns,
} from './TableColumns';

import { AgentDocumentTypes, DocumentMetaKey, TransactionFormTitle } from 'types';

import styles from './styles.module.scss';
import classNames from 'classnames';

const DocumentsTable = ({ title, tableData, columns, collapsible = false, className = '' }) => {
  const [collapsed, setIsCollapsed] = useState(false);

  return (
    <Fragment>
      <div
        className={classNames(
          styles.title,
          styles.marginBottom,
          { [styles.cursorPointer]: collapsible },
          className,
        )}
        onClick={() => collapsible && setIsCollapsed(!collapsed)}
      >
        <p>{title}</p>
        <span className={styles.count}>({tableData?.length})</span>
      </div>
      {!collapsed && (
        <Table
          className={styles.linkFormsTable}
          columns={columns}
          dataSource={tableData?.map((item, key) => ({
            ...item,
            key,
          }))} // Insert unique 'key' in every row
          pagination={false}
        />
      )}
    </Fragment>
  );
};

export const DocumentsModal = ({ onClose, details, optionUtils }) => {
  const { title, count } = details || {};
  const [tableData, setTableData] = useState<any>([]);
  const [searchText, setSearchText] = useState('');
  const isMiscTableType = title === AgentDocumentTypes.Misc;

  useEffect(() => {
    if (details?.data) {
      setTableData(details?.data);
      setSearchText('');
    }
  }, [details]);

  const getColumns = () => {
    switch (title) {
      case AgentDocumentTypes.Drafts:
        return draftColumns(optionUtils);
      case AgentDocumentTypes.PreApprovals:
        return preapprovalColumns(optionUtils);
      case AgentDocumentTypes.PendingForms:
        return pendingFormsColumns(optionUtils);
      case AgentDocumentTypes.MessageAttachments:
        return messageAttachmentsColumns(optionUtils);
      default:
        return transactionColumns(optionUtils);
    }
  };

  const onSearch = (search: string) => {
    if (!isMiscTableType) {
      setSearchText(search);
      let filteredResults: any[] = [];

      if (search !== '') {
        filteredResults = details?.data?.filter((item) =>
          item?.title?.toLowerCase().includes(search.toLowerCase()),
        );
      } else filteredResults = details?.data;
      setTableData(filteredResults);
    } else {
      setSearchText(search);

      let filteredElectronicSignatures = details?.data?.ElectronicSignatures || [];
      let filteredUploads = details?.data?.Uploads || [];

      if (search !== '') {
        filteredElectronicSignatures = filteredElectronicSignatures.filter((item) =>
          item?.title?.toLowerCase().includes(search.toLowerCase()),
        );

        filteredUploads = filteredUploads.filter((item) =>
          item?.title?.toLowerCase().includes(search.toLowerCase()),
        );
      }

      setTableData({
        ...(details?.data?.ElectronicSignatures
          ? { ElectronicSignatures: filteredElectronicSignatures }
          : {}),
        ...(details?.data?.Uploads ? { Uploads: filteredUploads } : {}),
      });
    }
  };

  const isDraftORPending =
    title === AgentDocumentTypes.Drafts || title === AgentDocumentTypes.PendingForms;

  const isTransaction = title === AgentDocumentTypes.Transactions;

  return (
    <div className={styles.documentsModal}>
      <div className={styles.documentsContainer}>
        <div className={styles.modalCloseIcon} onClick={onClose}>
          <DocumentIcons type={DocumentIcons.MODAL_CLOSE} />
        </div>
        <div className={styles.header}>
          <div className={styles.title}>
            <p className={styles.marginZero}>{title}</p>
            {!isMiscTableType && <span className={styles.count}>({tableData?.length})</span>}
          </div>
          <Input
            size="large"
            value={searchText}
            onChange={(event) => {
              const { value } = event.target;
              onSearch(value);
            }}
            className={styles.searchInput}
            placeholder="Search"
            prefix={<SearchGlass color="#515151" className={styles.icon} />}
            allowClear={true}
            variant=""
          />
        </div>
        <div className={styles.tableContainer}>
          {!isMiscTableType ? (
            <Table
              className={classNames({
                [styles.cursorPointer]: isDraftORPending || isTransaction,
              })}
              columns={getColumns()}
              dataSource={tableData.map((item, key) => ({ ...item, key }))} // Insert unique 'key' in every row
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    const { openFormDetailsModal } = optionUtils;
                    const metaKey = DocumentMetaKey?.[title];
                    const formProcessId = record?.[metaKey]?.formProcessId;

                    if (isDraftORPending || (isTransaction && record[metaKey].status)) {
                      let formTitle = isTransaction
                        ? TransactionFormTitle[record[metaKey].status]
                        : title;

                      formTitle =
                        formTitle === AgentDocumentTypes.PendingForms ? 'Pending' : formTitle;

                      formProcessId && openFormDetailsModal(formProcessId, formTitle);
                    }
                  },
                };
              }}
            />
          ) : (
            <Fragment>
              <div className={styles.seperator} />
              {tableData?.ElectronicSignatures && (
                <DocumentsTable
                  title="Electronic Signatures"
                  tableData={tableData?.ElectronicSignatures}
                  columns={miscElectronicSignColumns(optionUtils)}
                  collapsible
                />
              )}
              {tableData?.Uploads && (
                <DocumentsTable
                  title="Uploads"
                  tableData={tableData?.Uploads}
                  columns={miscUploadsColumns(optionUtils)}
                  className={styles.marginTop}
                  collapsible
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
