import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { BarChart } from 'components/BarChart';
import { TableModal } from 'pages/ClarityRevamped/Components/Stats/Content/Components/TableView/TableModal/TableModal';
import { EmptyChartPlaceholder } from 'components/EmptyChartPlaceholder';

import styles from './styles.module.scss';

type ChartDataType = {
  data: number[];
  numberTransactions: number[];
  categories: string[];
  dates: string[];
  year: number[];
  month: number[];
  isoWeek: number[];
  date: string[];
};

type ChartPoint = {
  year: number | null;
  month: number | null;
  isoWeek: number | null;
  date: string | null;
};

type ClarityBarChartProps = {
  data: any[];
  timePeriod: string;
  colors: string[];
  title: string;
  clarityType: string;
  summaryType: string;
  open: boolean;
  aggregationType?: string;
  summaryKey?: string;
  columnType?: string;
  ledgerAccountId?: number;
  memo?: string;
  selectedAgent?: any;
};

export const ClarityBarChart: React.FC<ClarityBarChartProps> = ({
  data,
  timePeriod,
  colors,
  title,
  aggregationType,
  summaryType,
  summaryKey,
  columnType,
  ledgerAccountId,
  memo,
  selectedAgent,
  clarityType,
  open,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [chartData, setChartData] = useState<ChartDataType>({
    data: [],
    numberTransactions: [],
    categories: [],
    dates: [],
    year: [],
    month: [],
    isoWeek: [],
    date: [],
  });

  const [chartPoint, setChartPoint] = useState<ChartPoint>({
    year: null,
    month: null,
    isoWeek: null,
    date: null,
  });

  useEffect(() => {
    if (data?.length) {
      const values = {
        data: data?.map((item) =>
          clarityType === 'Financials' ? item?.TotalValue : item.TotalVolume,
        ),
        numberTransactions: data?.map((item) => item?.NumberTransactions),
        year: data?.map((item) => item?.Year),
        month: data?.map((item) => item?.MonthNumber),
        isoWeek: data?.map((item) => item?.ISOWeek),
        date: data?.map((item) => item?.Date),
      };
      if (timePeriod === 'YTD') {
        setChartData({
          ...values,
          categories: data?.map((item) => item?.Month),
          dates: data?.map((item) => moment(`${item?.Month} 1, 2024`).format('MMMM')),
        });
      } else if (timePeriod === 'QTD') {
        setChartData({
          ...values,
          categories: data?.map((item) => item?.Week),
          dates: data?.map((item) => item?.Week),
        });
      } else if (timePeriod === 'MTD' || timePeriod === 'WEEK') {
        setChartData({
          ...values,
          categories: data?.map((item) => item?.Date),
          dates: data?.map((item) => moment(item?.Date).format('MMMM DD')),
        });
      }
    }
  }, [data]);

  const configProps = {
    seriesName: 'Total Value',
    colors,
    data: chartData?.data,
    numberTransactions: chartData?.numberTransactions,
    categories: chartData?.categories,
    dates: chartData?.dates,
    className: styles.smallCardAreaChart,
    open: open,
    onClickDataPoint: (index) => {
      if (chartData?.data[index] !== 0) {
        setChartPoint({
          year: chartData?.year[index],
          month: chartData?.month[index],
          isoWeek: chartData?.isoWeek[index],
          date: chartData?.date[index],
        });
        setOpenModal(true);
      }
    },
  };

  return (
    <>
      {data?.length ? <BarChart {...configProps} /> : <EmptyChartPlaceholder />}
      {openModal && (
        <TableModal
          title={title}
          aggregationType={aggregationType}
          summaryType={summaryType}
          summaryKey={summaryKey}
          columnType={columnType}
          ledgerAccountId={ledgerAccountId}
          memo={memo}
          year={chartPoint.year}
          month={chartPoint.month}
          isoWeek={chartPoint.isoWeek}
          date={chartPoint.date}
          open={openModal}
          onCancel={() => setOpenModal(false)}
          selectedAgent={selectedAgent}
          clarityType={clarityType}
        />
      )}
    </>
  );
};
